import styled from 'styled-components'

export const LineBreaker = styled.br`
  @media screen and (max-width: 768px) {
    display: none;
    margin: 0 2px 0 2px;
  }
`

export const DisplayClock = styled.div`
  display: flex;
  gap: 0.5em;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
`
