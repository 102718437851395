import React, { useEffect, useMemo, useState } from 'react'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import styled from 'styled-components'

import {
  forLeaseMarks,
  forSaleMarks,
  invertMarkValueConvert,
  marksMax,
  markValueConvert,
} from './RangeSelectorMarks'
import { ModalLayer } from '../../modal-layer'

// Styles
const SliderInput = styled.div`
  border: 1px solid var(--primary-soft-color);
  border-radius: 8px;
  height: 48px;
  cursor: text;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
`

export type RangeSelectorValueType = {
  min: number
  max: number
}
export type RangeSelectorProps = {
  type?: 'lease' | 'sale' | string
  value?: Partial<RangeSelectorValueType>
  range: RangeSelectorValueType
  onChange?: (value: RangeSelectorValueType) => void
}

export const RangeSelector = ({
  type,
  value = {},
  range = { min: 0, max: 100 },
  onChange,
}: RangeSelectorProps) => {
  const marks = type === 'lease' ? forLeaseMarks : forSaleMarks
  const max = marksMax(marks)
  const min = 0
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState<RangeSelectorValueType>(range)
  const valueMax: number = value.max || state.max || range.max
  const valueMin: number = value.min || state.min || range.min

  const onChangeRange = (v: any) => {
    const priceRange = {
      min: markValueConvert(v[0], marks),
      max: markValueConvert(v[1], marks),
    } as RangeSelectorValueType
    setState(priceRange)
    onChange && priceRange.min !== undefined && priceRange.max !== undefined && onChange(priceRange)
  }
  useEffect(() => {
    onChangeRange(marks)
  }, [marks])

  useEffect(() => {
    const priceRange = {
      min: value.min,
      max: value.max,
    } as RangeSelectorValueType
    setState(priceRange)
  }, [value])

  const valueMark = useMemo(
    () =>
      ({
        min: invertMarkValueConvert(valueMin, marks),
        max: invertMarkValueConvert(valueMax, marks),
      } as RangeSelectorValueType),
    [marks, value, state, valueMin, range],
  )

  const showSlider = () => {
    setIsOpen(true)
    const priceRange = {
      min: valueMin,
      max: valueMax,
    } as RangeSelectorValueType
    setState(priceRange)
  }
  const onOutsideClick = () => {
    setIsOpen(false)
  }
  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>, valueType: 'min' | 'max') => {
    e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)
    showSlider()
  }
  const onInputChange = (e: React.FormEvent<HTMLInputElement>, valueType: 'min' | 'max') => {
    const idx = e.currentTarget.value.indexOf('Price')
    const v = idx > 0 ? e.currentTarget.value.substring(idx + 5) : e.currentTarget.value
    const priceRange = {
      min: valueType === 'min' ? Number(v) : valueMin,
      max: valueType === 'max' ? Number(v) : valueMax,
    } as RangeSelectorValueType
    setState(priceRange)
    onChange && onChange(priceRange)
  }

  return (
    <>
      {/* <SliderInput
        className="focus:ring-0 focus:outline-none text-default-color italic text-center w-full"
        type="text"
        name="priceRange"
        placeholder="Min. Price - Max. Price"
        value={`${
          valueMin === undefined || valueMin === 0
            ? 'Min. Price'
            : `$${CurrencyHelper.toCurrencyShort(valueMin)}`
        } - ${
          (forSaleMarks && valueMax === 20000000) ||
          (forLeaseMarks && valueMax === 25000) ||
          valueMax === undefined
            ? 'Max. Price'
            : `$${CurrencyHelper.toCurrencyShort(valueMax)}`
        }`}
        onClick={showSlider}
      /> */}
      <SliderInput onKeyDown={showSlider}>
        <input
          value={valueMin === 0 || value.min === null ? 'Min. Price' : valueMin}
          defaultValue={valueMin}
          onFocus={(e) => onInputFocus(e, 'min')}
          onInput={(e) => onInputChange(e, 'min')}
          className="rounded-lg appearance-none p-2 w-full focus:ring-0 focus:outline-none text-default-color italic text-right"
        />
        <span>-</span>
        <input
          value={
            (type === 'sale' && valueMax === 20000000) ||
            (type === 'lease' && valueMax === 25000) ||
            value.max === null
              ? 'Max. Price'
              : valueMax
          }
          defaultValue={valueMax}
          onFocus={(e) => onInputFocus(e, 'max')}
          onInput={(e) => onInputChange(e, 'max')}
          className="rounded-lg appearance-none p-2 w-full focus:ring-0 focus:outline-none text-default-color italic text-left"
        />
      </SliderInput>
      <ModalLayer
        show={isOpen}
        onOutsideClick={onOutsideClick}
        responsive={{
          small: 'pinned',
        }}
      >
        <div className="absolute w-72 sm:w-96 top-1 lg:-left-0 box-content shadow-lg pt-5 pb-10 px-5 border border-primary-soft-color rounded-lg bg-white z-50">
          <div className="relative w-72 sm:w-96">
            <Slider
              allowCross
              onChange={(v: number | number[]) => onChangeRange(v)}
              range
              marks={marks}
              value={[valueMark.min, valueMark.max]}
              min={min}
              max={max}
              defaultValue={[min, max]}
              trackStyle={{ backgroundColor: 'var(--primary-color)', height: 8 }}
              dotStyle={{ display: 'none' }}
              handleStyle={{
                borderColor: 'var(--primary-color)',
                height: 24,
                width: 24,
                marginTop: -8,
                backgroundColor: 'white',
              }}
              railStyle={{
                backgroundColor: 'var(--primary-soft-color)',
                height: 8,
              }}
            />
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
