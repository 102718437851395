import * as React from 'react'

function SvgWaitlist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.141.574l-.389.574.193.245c.119.152.192.384.192.61 0 .316-.075.446-.55.96-.304.326-.6.683-.659.794-.195.365-.28 1.073-.182 1.531.106.502.541 1.239.819 1.388.168.09.23.041.555-.44l.366-.54-.196-.263a1.006 1.006 0 01-.186-.646c.008-.338.074-.446.549-.91.645-.631.888-1.145.888-1.878 0-.616-.296-1.312-.739-1.738L7.53 0 7.14.574zm3.491.047l-.383.579.222.258c.405.472.287.868-.477 1.61-.774.75-1.007 1.781-.604 2.675.177.392.698 1.033.84 1.033.066 0 .744-.99.744-1.087 0-.031-.084-.165-.187-.296a.95.95 0 01-.082-.986c.058-.108.328-.415.601-.682.834-.817.996-1.858.447-2.875-.13-.24-.348-.519-.486-.622l-.252-.186-.383.579zm3.529-.047l-.39.574.193.245c.12.152.192.384.192.61 0 .316-.075.446-.55.96-.303.326-.6.683-.658.794-.196.365-.28 1.073-.183 1.531.106.502.541 1.239.82 1.388.167.09.23.041.554-.44l.366-.54-.196-.263a1.006 1.006 0 01-.186-.646c.009-.338.074-.446.549-.91.645-.631.888-1.145.888-1.878 0-.616-.296-1.312-.739-1.738L14.55 0l-.388.574zM1.456 10.929c.034 1.768.09 2.643.195 3.096.49 2.095 1.739 4.052 3.377 5.289l.675.509h9.72l.373-.248c.205-.137.605-.448.89-.692l.517-.444 1.448-.047c1.586-.052 1.974-.145 2.915-.698.97-.57 1.753-1.541 2.183-2.705.146-.397.179-.83.212-2.793L24 9.873h-4.228V8.465H1.408l.048 2.464zm21.122 1.69c-.001 1.777-.21 2.452-1.015 3.293-.484.505-.954.78-1.651.967-.443.119-1.544.179-1.544.084 0-.024.166-.373.37-.774.688-1.36 1.034-2.77 1.034-4.21v-.604h2.807v1.244zM.063 21.626c.148.928.79 1.782 1.632 2.172C2.13 24 2.13 24 10.6 24c8.468 0 8.47 0 8.905-.202.842-.39 1.483-1.244 1.632-2.172l.063-.395H0l.063.395z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgWaitlist = React.memo(SvgWaitlist)
export default MemoSvgWaitlist
