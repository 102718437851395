import { atom, useAtom } from 'jotai'
import { stateValidationStore, useStateValidation } from 'utilities/hooks/validationState'
import { MarkerResponse, PropertyResponse, SearchPropertyFilter } from 'types/Property'

const filterFormStoreInitial = {
  topLeft: [],
  bottomRight: [],
  showActive: null as number | null,
  showSold: null as number | null,
  address: null as string | null,
  saleType: 'sale' as string,
  minPrice: null as number | null,
  maxPrice: null as number | null,
  minsqft: null as string | null,
  maxsqft: null as string | null,
  minLotFront: null as number | null,
  minLotDepth: null as number | null,
  bath: null as string | null,
  bed: 0 as number,
  types: null as string[] | null,
  parking: null as string | null,
  dom: null as number | null,
  openHouse: false,
} as SearchPropertyFilter

const store = {
  filter: stateValidationStore(filterFormStoreInitial),
}

const propertiesData = atom<PropertyResponse | null>(null)
const markersData = atom<MarkerResponse | null>(null)
const imageBaseUrl = atom<string | null>(null)
const lastSearchType = atom<string | null>(null)
const enabledFiltersData = atom<string[]>([])
const numberOfPropertiesFoundData = atom<number | null>(null)

export const useHomeSearchStore = () => {
  const [propertiesState, setPropertiesState] = useAtom(propertiesData)
  const [markersState, setMarkersState] = useAtom(markersData)
  const [imageBaseUrlState, setImageBaseUrlState] = useAtom(imageBaseUrl)
  const [lastSearchTypeState, setLastSearchTypeState] = useAtom(lastSearchType)
  const [enabledFiltersState, setEnabledFiltersState] = useAtom(enabledFiltersData)
  const [numberOfPropertiesFound, setNumberOfPropertiesFound] = useAtom(numberOfPropertiesFoundData)

  const filterForm = useStateValidation({
    store: store.filter,
    validation: {
      topLeft: [],
      bottomRight: [],
      showActive: [],
      showSold: [],
      address: [],
      saleType: [],
      minPrice: [],
      maxPrice: [],
      bed: [],
      types: [],
      dom: [],
      minsqft: [],
      maxsqft: [],
      minLotFront: [],
      minLotDepth: [],
      bath: [],
      parking: [],
      openHouse: [],
    },
  })

  return {
    filterForm,
    propertiesState,
    markersState,
    setMarkersState,
    imageBaseUrlState,
    setImageBaseUrlState,
    lastSearchTypeState,
    setLastSearchTypeState,
    enabledFiltersState,
    setPropertiesState,
    numberOfPropertiesFound,
    setNumberOfPropertiesFound,
  }
}
