import React from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import debounce from 'debounce'
import { Config } from 'config'
import { Location, Marker, MarkerResponse, SearchArea } from 'types/Property'
import { CurrencyHelper } from 'utilities'
import { searchMapOptions } from './searchMapOptions'
import {
  clusterIcons,
  markerIcon,
  mixedClusterIcons,
  soldClusterIcons,
  textSizes,
} from './markerIcons'

export class SearchMapController {
  private static singleton: SearchMapController

  public static instance(): SearchMapController {
    if (!SearchMapController.singleton) {
      SearchMapController.singleton = new SearchMapController()
    }
    return SearchMapController.singleton as SearchMapController
  }

  public map: google.maps.Map | null = null

  private mapContainer: HTMLDivElement = document.createElement('div')

  private mapContainerParent: HTMLElement | null = null

  private gMarkers: { [key: string]: google.maps.Marker } = {}

  private loader = new Loader({
    apiKey: Config.getGMapKey(),
    version: 'weekly',
    libraries: ['places'],
  })

  private constructor() {
    this.loader.load().then(() => {
      // construct map
      this.map = new google.maps.Map(this.mapContainer, searchMapOptions)
      // geolocation button
      const locationButton = document.createElement('button')
      locationButton.innerHTML = '<img src="/assets/geolocation-button.svg" alt="Location" />'
      locationButton.classList.add('geolocation-button')
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton)
      locationButton.addEventListener('click', () => {
        this.onGeolocationCenterCallback && this.onGeolocationCenterCallback()
      })
      // Re-center button
      const centerButton = document.createElement('button')
      centerButton.innerHTML = '<button>Centre Map</button>'
      centerButton.classList.add('center-button')
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerButton)
      centerButton.addEventListener('click', () => {
        searchMapOptions.center && this.centerMapTo(searchMapOptions.center)
      })
      // bound change
      this.map.addListener('bounds_changed', debounce(this.boundsChanged.bind(this), 500))
      this.mapLoaded()
    })
  }

  private onLabelPropertyClickCallback: ((neighbourhood: Marker) => void) | null = null

  public onLabelPropertyClick(callback: (neighbourhood: Marker) => void) {
    this.onLabelPropertyClickCallback = callback
    return this
  }

  private onGeolocationCenterCallback: (() => void) | null = null

  public onGeolocationCenter(callback: () => void) {
    this.onGeolocationCenterCallback = callback
    return this
  }

  public onBoundsChangeCallback: ((area: SearchArea) => void) | null = null

  public onBoundsChange(callback: (area: SearchArea) => void) {
    this.onBoundsChangeCallback = callback
    return this
  }

  public centerMapTo(position: google.maps.LatLng | google.maps.LatLngLiteral) {
    if (this.map) {
      this.map.setCenter(position)
      this.map.setZoom(14)
    }
    return this
  }

  public getBounds(): SearchArea {
    const bounds = this.map?.getBounds()
    const northEast = bounds?.getNorthEast()
    const southWest = bounds?.getSouthWest()

    return {
      topLeft: [northEast?.lat() || 0, southWest?.lng() || 0],
      bottomRight: [southWest?.lat() || 0, northEast?.lng() || 0],
    }
  }

  private appendContainer() {
    if (!this.mapContainerParent) return
    // append mapContainer to parent
    while (this.mapContainerParent.firstChild) {
      this.mapContainerParent.removeChild(this.mapContainerParent.firstChild)
    }
    this.mapContainerParent.appendChild(this.mapContainer)
  }

  private mapLoaded() {
    this.appendContainer()
  }

  private getPosition(loc: Location) {
    return { lat: loc.lat, lng: loc.lon }
  }

  public getZoom() {
    return this.map?.getZoom()
  }

  public getCenterPosition() {
    return this.map?.getCenter()?.toJSON()
  }

  public createGoogleMarker(marker: Marker) {
    const existingGMarker = this.gMarkers[marker.mid]
    if (existingGMarker) {
      existingGMarker.set('label', this.createLabel(marker))
      existingGMarker.set('icon', this.createIcon(marker))
      google.maps.event.clearListeners(existingGMarker, 'click')
      return existingGMarker
    }

    const newMarker = new google.maps.Marker({
      position: this.getPosition(marker.location),
      label: this.createLabel(marker),
      icon: this.createIcon(marker),
      map: this.map,
    })
    newMarker.setValues({ mid: marker.mid })
    return newMarker
  }

  public setMarker(marker: Marker) {
    const gMarker = this.createGoogleMarker(marker)
    if (marker.type === 'marker') {
      gMarker.addListener('click', () => {
        this.onLabelPropertyClickCallback && this.onLabelPropertyClickCallback(marker)
      })
    }
    if (marker.type === 'cluster' || marker.type === 'normal') {
      gMarker.addListener('click', () => {
        const position = this.getPosition(marker.location)
        const zoom = this.map?.getZoom()

        if (!zoom) return
        if (marker.count >= 20 && marker.count !== marker.ids.length) {
          const centerMarker = this.map?.panTo(position)
          this.map?.setZoom(zoom + 3) && centerMarker
        } else {
          this.onLabelPropertyClickCallback && this.onLabelPropertyClickCallback(marker)
        }
      })
    }
    return gMarker
  }

  public setMarkers(markerDetails: MarkerResponse) {
    const newGMarkers = markerDetails.markers.map((marker) => this.setMarker(marker))
    const newGMarkersKeys = newGMarkers.map((g) => g.get('mid'))

    // remove the markers from the map.
    Object.keys(this.gMarkers)
      .filter((key) => !newGMarkersKeys.includes(key))
      .forEach((key) => {
        const gMarker = this.gMarkers[key]
        google.maps.event.clearListeners(gMarker, 'click')
        gMarker.setMap(null)
      })
    this.gMarkers = newGMarkers.reduce((o, m) => ({ ...o, [m.get('mid') as string]: m }), {})
  }

  public setCenter(loc: Location, zoom: number) {
    const position = this.getPosition(loc)
    const centerMarker = this.map?.panTo(position)
    this.map?.setZoom(zoom) && centerMarker
  }

  public fitBounds(bounds: google.maps.LatLngBounds) {
    this.map?.fitBounds(bounds)
  }

  public appendTo(node: HTMLElement) {
    this.mapContainerParent = node
    if (this.map) this.appendContainer()
    return this
  }

  private boundsChanged() {
    if (this.onBoundsChangeCallback) {
      this.onBoundsChangeCallback(this.getBounds())
    }
  }

  private createLabel(marker: Marker): google.maps.MarkerLabel {
    const text = marker.count.toString()
    return {
      text: marker.price ? CurrencyHelper.toRoundedShort(marker.price) : text,
      color: 'rgba(255,255,255,255)',
      fontFamily:
        'Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeight: 'bold',
      fontSize: `${textSizes[text.length - 1]}px`,
    } as google.maps.MarkerLabel
  }

  private createIcon(marker: Marker, color?: string) {
    let icon: string
    const count = marker.count.toString()

    if (marker.type === 'cluster') {
      const index = count.length - 1
      if (marker.marker === 'active') icon = clusterIcons(color)[index]
      else if (marker.marker === 'normal') icon = mixedClusterIcons(color)[index]
      else icon = soldClusterIcons(color)[index]
    } else {
      icon = markerIcon(marker.marker, color)
    }
    return {
      url: `data:image/svg+xml;base64,${icon}`,
    }
  }

  public setIconColor(marker: Marker, color?: string) {
    SearchMapController.instance().gMarkers[marker.mid].set('icon', this.createIcon(marker, color))
  }
}
