import React from 'react'
import { CheckCircleIcon, ExclamationIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { alertsState, AlertType, AlertInfo } from './state'

type AlertTypeClass = {
  color: string
  icon: JSX.Element
}

export const AlertBanner = () => {
  const alerts = useRecoilValue(alertsState)
  const setAlerts = useSetRecoilState(alertsState)

  const removeAlert = async (alert: AlertInfo) => {
    const newAlerts = alerts.filter((el) => alert.id !== el.id)
    await setAlerts(newAlerts)
  }

  const AlertItem = ({ id, type, message }: AlertInfo) => {
    const alertTypeClass = {
      [AlertType.success]: {
        color: 'green',
        icon: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
      } as AlertTypeClass,
      [AlertType.warning]: {
        color: 'yellow',
        icon: <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
      } as AlertTypeClass,
      [AlertType.error]: {
        color: 'red',
        icon: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
      } as AlertTypeClass,
    }

    return (
      <div className={`rounded-md bg-${alertTypeClass[type].color}-50 p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">{alertTypeClass[type].icon}</div>
          <div className="ml-3">
            <p className={`text-sm font-medium text-${alertTypeClass[type].color}-800`}>
              {message}
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex bg-${alertTypeClass[type].color}-50 rounded-md p-1.5 text-${alertTypeClass[type].color}-500 hover:bg-${alertTypeClass[type].color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertTypeClass[type].color}-50 focus:ring-${alertTypeClass[type].color}-600`}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon
                  className="h-5 w-5"
                  onClick={() => removeAlert({ id, type, message })}
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="absolute z-10 w-full">
      {alerts.map((alert) => (
        <AlertItem key={alert.type} id={alert.id} type={alert.type} message={alert.message} />
      ))}
    </div>
  )
}
