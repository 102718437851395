import * as React from 'react'

function SvgLoading(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M14.493 13.5c3.024 2.167 3.428 4.333 3.481 6.5.014.552-.439 1-.991 1H6.957c-.528 0-.97-.41-.95-.938C6.111 17.339 7.38 13.426 12 12c4.522-1.395 5.833-5.681 5.985-8.081.032-.52-.402-.919-.923-.919H7.017c-.552 0-1.008.447-.982.999.086 1.793.584 4.432 3.472 6.501"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgLoading = React.memo(SvgLoading)
export default MemoSvgLoading
