/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Title } from 'views/shared/components/ui-form'

export type DashboardHeaderProps = {
  title: string
}
export const DashboardHeader = ({ title }: DashboardHeaderProps) => (
  <>
    <div className="mx-5 xl:mx-0">
      <div className="text-sm text-default-color ">Your Dashboard</div>
      <Title>{title}</Title>
    </div>
  </>
)
