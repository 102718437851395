import styled, { keyframes } from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateX(200px);
    opacity: 0
  }
  to {
    transform: translateX(0);
    opacity: 1
  }
`

export const ContainerDetails = styled.div`
  background: #ffffff;
  box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.15), -1px 0px 0px rgba(0, 0, 0, 0.15);
  padding: 40px 10px;
  width: 100%;
  animation: ${slideIn} 200ms ease-out forwards;
`

export const Subtitle = styled.h3<{
  fontWeight?: number
}>`
  font-weight: ${(p) => p.fontWeight || 600};
  font-size: 20px;
  line-height: 150%;
  color: var(--accent-color);
`
export const Label = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--secondary-color);
`
export const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  &::focus {
    outline: none;
  }
  &:disabled {
    color: var(--alpha-dark-color);
    cursor: auto;
  }
`
export const FormGroup = styled.div<{
  backgroundColor?: string
}>`
  padding: 4px 12px;
  min-height: 56px;
  background-color: ${(p) => p.backgroundColor || '#ffffff'};
  border-radius: 8px;
  box-sizing: border-box;
`

export const GrayContainer = styled.div`
  background: #f4f6f6;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  padding: 14px 20px;
  margin-bottom: 32px;
`
export const Item = styled.div`
  padding: 16px;
  font-size: 16px;
  background-color: var(--alpha-color);
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const DateField = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: var(--default-color);
`
export const NotesButton = styled.button`
  color: var(--primary-color);
  display: inline-block;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  &:disabled {
    color: var(--alpha-dark-color);
    cursor: auto;
  }
`
