import { API, Storage } from 'aws-amplify'

import { MonthlyNeighbourhoodSales, MonthStr, Neighbourhood } from 'types'
import { DateHelper } from 'utilities'

const neighbourhoodsUrl = 'neighbours.json'

interface AwsStorageJsonFileType {
  Body: {
    text(): Promise<string>
  }
}
interface JsonNeighbourhood {
  id: string
  name: string
  community: string

  coordinates: [number, number][][]

  condoSales?: number
  detachedSales?: number
  semiDetachedSales?: number
}

const convertToNeighbourhood = ({
  id,
  name,
  community,
  coordinates,
  condoSales,
  detachedSales,
  semiDetachedSales,
}: JsonNeighbourhood) =>
  ({
    id,
    name,
    community,
    coordinates,
    condoSales,
    detachedSales,
    semiDetachedSales,
  } as Neighbourhood)

export module NeighbourhoodServices {
  export const getNeighbourhoods = async (): Promise<Neighbourhood[]> => {
    try {
      const data = (await Storage.get(neighbourhoodsUrl, {
        download: true,
      })) as AwsStorageJsonFileType

      const json = JSON.parse(await data.Body.text())

      return json?.map((v: JsonNeighbourhood) => convertToNeighbourhood(v)) ?? []
    } catch (error) {
      return []
    }
  }

  export const getNeighbourhoodsSalesData = async (time?: MonthStr) => {
    try {
      const init = {
        headers: {},
        response: true,
        queryStringParameters: {
          time: time || DateHelper.getLastMonthStr(),
        },
      }
      const { data } = await API.get('property', '/neighbourhoods', init)
      return data as MonthlyNeighbourhoodSales
    } catch (error) {
      return {} as MonthlyNeighbourhoodSales
    }
  }
}
