import { API } from 'aws-amplify'
import { ClientFeedback } from 'types'

export module FeedbackServices {
  const apiName = 'user'
  export const AddFeedback = async (data: ClientFeedback) => {
    const responseData = (
      await API.post(apiName, `/users/feedback`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }
}
