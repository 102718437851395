import { atom } from 'recoil'

export const AlertType = {
  success: 'success',
  error: 'error',
  warning: 'warning',
}

export interface AlertInfo {
  id: string
  type: string
  message: string
  open?: boolean
}

export const alertsState = atom<AlertInfo[]>({
  key: 'alertBanner.alerts',
  default: [],
})
