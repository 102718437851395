import { useCallback, useState } from 'react'

import type { GMapCoordinate } from 'types'

export type GeolocationServiceData = {
  location?: GMapCoordinate
  errorCode?: number
}

export const useGeolocation = (): [GeolocationServiceData | null, () => void] => {
  const [geolocation, setGeolocation] = useState<GeolocationServiceData | null>(null)
  const askGeolocationPermission = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (pos: GeolocationPosition) => {
        setGeolocation({
          location: {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          },
        })
      },
      (e) => {
        setGeolocation({
          errorCode: e.code,
        })
      },
      {
        enableHighAccuracy: true,
      },
    )
  }, [setGeolocation])
  return [geolocation, askGeolocationPermission]
}
