import { SoldReport } from './pages'
import { paths } from './paths'

export const routes = {
  SoldReport: {
    path: paths.soldReport(),
    exact: false,
    component: SoldReport,
  },
}
