import * as React from 'react'

function SvgGrowthArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.749 1.127c.158.12.251.306.251.505v5.052a.632.632 0 01-.914.565l-2.05-1.025-6.632 9.044a.631.631 0 01-.888.132l-4.552-3.414L1.91 21.21h21.458a.632.632 0 010 1.264H.632a.632.632 0 01-.502-1.015l8.21-10.737a.632.632 0 01.881-.122l4.542 3.406 6.57-8.958a.632.632 0 01.792-.192l1.612.806V2.47L19.12 3.502a.632.632 0 11-.347-1.215l4.42-1.263a.632.632 0 01.555.103z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgGrowthArrow = React.memo(SvgGrowthArrow)
export default MemoSvgGrowthArrow
