import styled from 'styled-components'

export const UnderlinedPrimaryButton = styled.button<{ fontSize?: string }>`
  appearance: none;
  color: var(--primary-color);
  font-size: ${(p) => p.fontSize || '16'}px;
  font-weight: 600;
  text-decoration: underline;
  border: none;
  cursor: pointer;
`

export const PrimaryButton = styled.button<{
  fontSize?: string
}>`
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--secondary-color);
  font-size: ${(p) => p.fontSize || '14'}px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--on-secondary-color);
  width: 100%;
  padding: 13px;
  height: 48px;
  border-radius: 8px;
  &:hover {
    background-color: #fff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
  }
  &:disabled {
    cursor: default;
    background-color: var(--disabled-color);
    color: var(--on-disabled-color);
    border: none;
  }
`
export const PrimaryNegativeButton = styled.button<{
  fontSize?: string
}>`
  cursor: pointer;
  box-sizing: border-box;
  font-size: ${(p) => p.fontSize || '14'}px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 13px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  text-transform: capitalize;
  &:hover {
    background-color: var(--secondary-color);
    color: var(--on-secondary-color);
  }
  &:disabled {
    cursor: default;
    border: 1px solid var(--disabled-color);
    color: var(--on-disabled-color);
    border: none;
  }
`
export const SecondaryButton = styled.button<{
  height?: string
  fontSize?: string
  padding?: string
  borderRadius?: string
  color?: string
  fontColor?: string
}>`
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${(p) => p.color || 'var(--primary-color)'};
  color: ${(p) => p.fontColor || 'var(--on-primary-color)'};
  font-size: ${(p) => p.fontSize || '14'}px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding: ${(p) => p.padding || '13'}px;
  height: ${(p) => p.height || '48'}px;
  border-radius: ${(p) => p.borderRadius || '8'}px;
  text-transform: capitalize;
  &:hover {
    background-color: ${(p) => p.fontColor || '#fff'};
    color: ${(p) => p.color || 'var(--primary-color)'};
    border: 1px solid ${(p) => p.color || 'var(--on-primary-color)'};
    border-radius: 8px;
  }
  &:disabled {
    cursor: default;
    background-color: var(--disabled-color);
    color: var(--on-disabled-color);
    border: none;
  }
`

export const SecondaryNegativeButton = styled.button<{
  fontSize?: string
}>`
  cursor: pointer;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: ${(p) => p.fontSize || '14'}px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding: 13px;
  height: 48px;
  border-radius: 8px;
  text-transform: capitalize;
  &:hover {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
  }
  &:disabled {
    cursor: default;
    border: 1px solid var(--disabled-color);
    color: var(--on-disabled-color);
    border: none;
  }
`
export const WarningButton = styled.button<{
  fontSize?: string
}>`
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--warning-color);
  border: 1px solid transparent;
  color: var(--on-warning-color);
  font-size: ${(p) => p.fontSize || '14'}px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding: 13px;
  height: 48px;
  border-radius: 8px;
  text-transform: capitalize;
`

export const UploadPhotoStyled = styled.div`
  background-color: var(--primary-color);
  color: var(--on-primary-color);
  padding: 20px 16px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-radius: 24px;
`
export const FormGroup = styled.div<{
  backgroundColor?: string
}>`
  padding: 4px 12px;
  min-height: 56px;
  background-color: ${(p) => p.backgroundColor || '#ffffff'};
  border-radius: 8px;
  box-sizing: border-box;
`
export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--secondary-color);
`
export const Input = styled.input`
  width: 100%;
  padding: 6px 12px;
  color: var(--secondary-color);
  box-sizing: border-box;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  line-height: 125%;
  border: 1px solid var(--primary-soft-color);
  &::placeholder {
    color: var(--default-color);
    font-style: italic;
  }
  &:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: none;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: var(--primary-color);
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 8px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
export const Textarea = styled.textarea<{
  height?: number
}>`
  width: 100%;
  padding: 16px;
  color: var(--secondary-color);
  background: #fff;
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 8px;
  height: ${(p) => p.height || 72}px;
  font-size: 16px;
  line-height: 140%;
  resize: none;
  &::placeholder {
    font-style: italic;
    color: var(--default-color);
  }
  &:focus {
    outline: none;
    border-color: var(--primary-soft-color);
    box-shadow: none;
  }
`
export const Select = styled.select`
  width: 100%;
  padding: 6px 36px 6px 12px;
  height: 48px;
  line-height: 125%;
  cursor: pointer;
  color: var(--default-color);
  font-style: italic;
  background: transparent;
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  &:focus {
    outline: none !important;
    border-color: var(--primary-color);
    box-shadow: none;
  }
`
export const ListSelector = styled.ul`
  display: flex;
  flex-direction: row;
  width: max-content;
  box-sizing: border-box;
  padding: 4px 6px;
  height: 48px;
  background: transparent;
  border: 1px solid var(--primary-soft-color);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  & > li {
    padding: 10px 0px;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: var(--default-color);
      color: var(--on-default-color);
      border-radius: 8px;
    }
  }
`
export const WarningNegativeButton = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid var(--warning-color);
  color: var(--warning-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding: 13px;
  height: 48px;
  border-radius: 8px;
`
export const ErrorList = styled.ul`
  font-size: 14px;
  color: var(--warning-color);
  list-style: disc;
  padding: 20px;
`
