import { HomeValuationAnnualReport } from 'types'
import { PdfHelper } from 'utilities'
import { footer } from '../footer'
import { header } from '../header'
import { chartBody } from './chartBody'
import { chartHeader } from './chartHeader'

export const addChart = async (
  doc: PDFKit.PDFDocument,
  data: HomeValuationAnnualReport.Valuation,
) => {
  doc.addPage()
  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">

  ${header(data)}
  ${chartHeader(data)}
      ${await chartBody(data)}
      ${await footer(data)}
    </svg>
  `
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
