import * as React from 'react'

function SvgParking(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M23.998 15.015c-.166.343-.454.497-.872.527-.801.059-1.598.161-2.397.235-.149.013-.209.065-.257.191-.367.945-1.337 1.576-2.42 1.592-1.108.017-2.09-.576-2.495-1.52-.058-.133-.12-.18-.283-.178-2.436.006-4.875.004-7.31 0-.15 0-.214.04-.267.167-.399.931-1.359 1.53-2.441 1.53-1.085.003-2.052-.59-2.446-1.524-.067-.156-.158-.18-.31-.173-.882.037-1.62-.23-2.111-.912-.178-.248-.262-.55-.389-.828v-4.24c.185-.385.506-.559.967-.506.197.021.334-.057.483-.161C3.286 7.916 5.362 7.152 7.702 7.04c1.05-.05 2.107-.039 3.16-.041 2.115-.002 4.01.582 5.65 1.791 1.423 1.05 3.031 1.588 4.867 1.607.845.009 1.529.348 2.06.945.304.345.446.751.561 1.168-.002.836-.002 1.67-.002 2.504zm-7.644-4.588c-1.745-1.475-3.778-2.237-6.212-2.048v2.048h6.212zM5.624 8.744c0 .275-.008.543.006.81.003.056.075.132.135.158.734.315 2.035.615 2.841.662.005-.026.017-.054.017-.08V8.357a10.748 10.748 0 00-3 .387z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgParkingFilters = React.memo(SvgParking)
export default MemoSvgParkingFilters
