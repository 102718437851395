import * as React from 'react'

function SvgKey(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#key_svg__clip0_402_9)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.22 15.652h6.22l.297.423c.571.816 1.595 1.555 2.78 2.008.97.37 2.528.466 3.567.218 3.293-.785 5.477-4.23 4.79-7.556-.513-2.478-2.413-4.48-4.79-5.046-1.04-.248-2.598-.153-3.567.218-1.185.453-2.209 1.192-2.78 2.008l-.296.423H0v7.304h6.22zm.813-4.325h2.814v1.346H4.22v-1.346h2.813zm7.573-3.284c.947-.733 1.84-1.04 3.024-1.04.466 0 1.026.064 1.32.153 2.112.636 3.65 2.677 3.65 4.844 0 2.167-1.538 4.208-3.65 4.844-.294.088-.854.154-1.32.154-1.183 0-2.077-.308-3.024-1.04l-.446-.345V8.387l.446-.344z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="key_svg__clip0_402_9">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgKey = React.memo(SvgKey)
export default MemoSvgKey
