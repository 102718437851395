import React, { useContext } from 'react'
import { AuthContext } from 'views/authentication'
import { useFollows } from 'views/follows/hooks'

import { Star } from '../../../shared/components/icons'
import './FollowNeighbourhood.scss'

export type FollowNeighbourhoodProps = React.PropsWithChildren<{
  neighbourhoodId: string
}>

export const FollowNeighbourhood = ({ neighbourhoodId }: FollowNeighbourhoodProps) => {
  const { isNeighbourhoodFollowed, toggleFollowedNeighbourhood } = useFollows()
  const { authUser } = useContext(AuthContext)
  if (!authUser) return <></>

  if (!neighbourhoodId) return <></>
  const checked = isNeighbourhoodFollowed(neighbourhoodId)

  return (
    <>
      <div className="follow-neighbourhood relative">
        <div className="flex flex-col items-center w-full pr-3">
          <button type="button" onClick={() => toggleFollowedNeighbourhood(neighbourhoodId)}>
            <Star
              style={{ fontSize: '24px' }}
              fill={checked ? `var(--primary-color)` : 'none'}
              color="var(--primary-color)"
            />
          </button>
          {checked ? (
            <p className="follow-check text-primary-color text-xs text-center">
              <span className="following">Following</span>
              <span className="unfollow">Unfollow</span>
            </p>
          ) : (
            <p className="text-primary-color text-xs text-center">Follow</p>
          )}
        </div>
        {!checked ? (
          <div className="card z-10 text-accent-color text-xs font-semibold min-w-max px-6 py-3">
            Add to your follow list
            <br /> to retrieve it quickly.
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
