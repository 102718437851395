import * as React from 'react'

function SvgArrowleft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04 1.4a1.58 1.58 0 010 2.237L3.824 8.844 9.04 14.05a1.58 1.58 0 010 2.236 1.586 1.586 0 01-2.24 0L.464 9.962a1.58 1.58 0 010-2.236L6.8 1.4a1.586 1.586 0 012.24 0z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgArrowleft = React.memo(SvgArrowleft)
export default MemoSvgArrowleft
