import React, { useContext, useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { Pagination } from 'views/shared/components/pagination/Pagination'
import { DashboardHeader } from 'views/dashboard/components/header'
import { Docs, People, Profile, Spinner } from 'views/shared/components/icons'
import { useUserListState } from 'store'
import { dashboardPaths } from 'views/dashboard'
import { UserTypes } from 'services/user/UserTypes'
import { AuthContext } from 'views/authentication'
import { StackPageType } from 'utilities/hooks'
import { DropdownField, Input } from 'views/shared/components/ui-form'
import { UserRole } from 'constants/UserConstants'
import { UserServices } from 'services/user'
import { ListWrap, SortingButton } from './userList.styled'
import { UserListitem } from './userListItem'

const idToQuery = (id?: string) =>
  id ? (JSON.parse(atob(id)) as Partial<UserTypes.GetUsersParametersType>) : undefined
export const UserListPage = ({ id }: StackPageType) => {
  const { isLoggedIn, authUser } = useContext(AuthContext)
  const { userList, userListStatus, userListQuery, fetchUserList } = useUserListState()
  const idQuery = idToQuery(id)
  const [search, setSearch] = useState(idQuery && idQuery.q ? idQuery.q : '')
  const history = useHistory()
  const loading = userListStatus.status === 'loading'

  const isAgent = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.AGENT) > -1
  const isAdmin = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.ADMIN) > -1

  useEffect(() => {
    const agentId = isAgent ? authUser.sub : undefined
    if (idQuery) {
      fetchUserList(idQuery, agentId)
    } else {
      fetchUserList(
        { q: undefined, roles: undefined, from: 0, sort: 'averageSessionDuration.desc' },
        agentId,
      )
    }
  }, [id])
  const queryNavigation = (query: Partial<UserTypes.GetUsersParametersType>) =>
    history.replace(dashboardPaths.users(query))

  const userRoleFilterHandler: (
    role: UserTypes.UserRoleType,
  ) => React.MouseEventHandler<HTMLButtonElement> = (roles) => () => {
    queryNavigation({ roles, sort: idQuery?.sort })
  }

  const searchHandler: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (search === '') {
      queryNavigation({ q: undefined, from: 0, roles: undefined })
    } else if (e.key === 'Enter') {
      queryNavigation({ q: search, from: 0, roles: undefined })
    }
  }
  const searchBlurHandler: React.FocusEventHandler<HTMLInputElement> = () => {
    queryNavigation({ q: search, from: 0, roles: undefined })
  }

  const userItemClickHandler = (userId: string, roles: any) => () => {
    roles && roles.includes('agent')
      ? history.push(dashboardPaths.agent(userId, idToQuery(id)))
      : history.push(dashboardPaths.profile(userId, idToQuery(id)))
  }

  // const userItemClickHandler = (userId: string, roles: any) => () => {
  //   if (!userId || userId === 'undefined' || userId.indexOf('@') > -1) {
  //     alert("This user didn't accept your invitation yet.")
  //     roles && roles.includes('agent')
  //       ? history.push(dashboardPaths.agent(userId, idToQuery(id)))
  //       : history.push(dashboardPaths.profile(userId, idToQuery(id)))
  //   } else {
  //     roles && roles.includes('agent')
  //       ? history.push(dashboardPaths.agent(userId, idToQuery(id)))
  //       : history.push(dashboardPaths.profile(userId, idToQuery(id)))
  //   }
  // }
  const paginationHandler = (from: number) => {
    queryNavigation({ ...idQuery, from })
  }

  const sortHandler: (key: string) => void = (key: string) => {
    queryNavigation({ ...idQuery, sort: key as UserTypes.UserSortType, from: 0 })
  }

  const inviteUser = () => {
    history.push(dashboardPaths.inviteUser(idToQuery(id)))
  }
  const importContacts = () => {
    history.push(dashboardPaths.importContacts())
  }

  const exportWaitList = async () => {
    await UserServices.exportWaitlist()
      .then((filename) =>
        Storage.get(filename, {
          download: false,
          contentType: 'text/csv',
        }),
      )
      .then((link) => {
        const aTag = document.createElement('a')
        aTag.setAttribute('href', link)
        aTag.setAttribute('download', 'waitlist.csv')
        aTag.style.display = 'none'
        document.body.appendChild(aTag)
        aTag.click()
        document.body.removeChild(aTag)
      })
  }

  return (
    <>
      <div className="relative flex flex-col bg-white  h-full w-full">
        {loading && (
          <div className="absolute h-full w-full opacity-50 bg-white flex items-center align-middle justify-center">
            <Spinner style={{ fontSize: '80px' }} color="var(--accent-color)" />
          </div>
        )}
        {isLoggedIn && isAdmin && (
          <div className="mb-4">
            <div className="md:flex md:space-x-5 mb-5">
              <DashboardHeader title="Users" />
              <div className="mx-5 flex items-center space-x-3 flex-1">
                <button
                  onClick={inviteUser}
                  type="button"
                  className="flex items-center bg-secondary-color text-white h-12 px-2.5 rounded-lg cursor-pointer"
                >
                  <span className="text-3xl">+</span>
                  <Profile style={{ fontSize: '30px' }} color="white" />
                </button>
                <button
                  onClick={importContacts}
                  type="button"
                  className="flex items-center bg-primary-color text-white h-12 px-2.5 rounded-lg cursor-pointer"
                >
                  <span className="text-3xl">+</span>
                  <People style={{ fontSize: '30px' }} color="white" />
                </button>
                <button
                  onClick={exportWaitList}
                  type="button"
                  className="flex items-center bg-primary-color text-white h-12 px-2.5 rounded-lg cursor-pointer"
                >
                  Export Waitlist
                </button>

                <div className="w-full md:w-1/2">
                  <Input
                    value={search}
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    onBlur={searchBlurHandler}
                    onKeyUp={searchHandler}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Search all users"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-start sm:space-x-10 mx-5">
              <div className="flex flex-row items-center space-x-3 mb-3 md:mb-0">
                <SortingButton
                  className={userListQuery.roles === 'agent' ? '--selected' : ''}
                  type="button"
                  onClick={userRoleFilterHandler('agent')}
                >
                  Agents
                </SortingButton>
                <SortingButton
                  className={userListQuery.roles === null ? '--selected' : ''}
                  type="button"
                  onClick={userRoleFilterHandler(null)}
                >
                  Contacts
                </SortingButton>
                <SortingButton
                  className={userListQuery.roles === 'admin' ? '--selected' : ''}
                  type="button"
                  onClick={userRoleFilterHandler('admin')}
                >
                  Admins
                </SortingButton>
              </div>
              <div className="sm:w-72">
                <DropdownField
                  value={userListQuery.sort}
                  onChange={sortHandler}
                  options={[
                    { key: 'firstName', label: 'First name' },
                    { key: 'lastName', label: 'Last name' },
                    { key: 'email', label: 'Email' },
                    { key: 'phone', label: 'Phone' },
                    { key: 'logins.desc', label: 'Number of Logins' },
                    { key: 'averageSessionDuration.desc', label: 'Average time on site' },
                    { key: 'totalReportOpen.desc', label: 'Number of sold reports open' },
                    { key: 'lastLoginAt.desc', label: 'Last login' },
                  ]}
                  name="sortUsers"
                />
              </div>
            </div>
          </div>
        )}
        {/* agent */}
        {isLoggedIn && isAgent && (
          <div className=" mb-5 w-full">
            <div className="flex mb-5">
              <DashboardHeader title="Contacts" />

              <div className="hidden items-center space-x-3 pt-3 mx-5">
                <button
                  onClick={inviteUser}
                  type="button"
                  className="flex items-center bg-secondary-color text-white h-12 px-2.5 rounded-lg cursor-pointer"
                >
                  <span className="text-3xl">+</span>
                  <Profile style={{ fontSize: '30px' }} color="white" />
                </button>

                <button
                  type="button"
                  className="flex items-center bg-primary-color text-white h-12 px-2.5 rounded-lg cursor-pointer"
                  onClick={importContacts}
                >
                  <span className="text-3xl">+</span>
                  <Docs style={{ fontSize: '30px' }} color="white" />
                </button>
              </div>
            </div>
            <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-10 mx-5">
              <div className="sm:w-72">
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  onBlur={searchBlurHandler}
                  onKeyUp={searchHandler}
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Search all users"
                />
              </div>
              <div className="sm:w-72">
                <DropdownField
                  value={userListQuery.sort}
                  onChange={sortHandler}
                  options={[
                    { key: 'firstName', label: 'First name' },
                    { key: 'lastName', label: 'Last name' },
                    { key: 'email', label: 'Email' },
                    { key: 'phone', label: 'Phone' },
                    { key: 'logins.desc', label: 'Number of Logins' },
                    { key: 'averageSessionDuration.desc', label: 'Average time on site' },
                    { key: 'totalReportOpen.desc', label: 'Number of sold reports open' },
                    { key: 'lastLoginAt.desc', label: 'Last login' },
                  ]}
                  name="sortUsers"
                />
              </div>
            </div>
          </div>
        )}
        <ListWrap className="flex-grow bg-alpha-color overflow-y-auto scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
          {' '}
          {userList.hits
            // .filter((u) => u.id && u.id.indexOf('@') === -1 && u.id !== 'undefined')
            .map((item) => (
              <UserListitem
                key={item.id}
                aid={isAgent ? authUser.sub : undefined}
                onClick={userItemClickHandler(item.id, item.roles)}
                details={item.details}
                role={item.roles}
                lastLoginAt={item.lastLoginAt}
                totalSessionDuration={item.totalSessionDuration}
                averageSessionDuration={item.averageSessionDuration}
                followUp={item.followUp}
                ownershipStats={item.details.ownershipStats}
                purchaseDate={item.details.purchaseDate}
                isSignedUp={item.PK.substring(0, 2) !== 'I#'}
              />
            ))}
        </ListWrap>
        <Pagination
          from={userListQuery.from}
          pageSize={userListQuery.size}
          total={userList.total}
          onChange={paginationHandler}
        />
      </div>
    </>
  )
}
