import * as React from 'react'

function SvgBath(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M23.954 12.289H5.07V5.436c0-.605.394-1.063.975-1.16.547-.091 1.067.229 1.27.781.064.179.12.36.193.536.056.13.027.205-.079.303-.712.666-.933 1.472-.679 2.4.106.386.437.581.823.473 1.109-.308 2.213-.623 3.317-.947.377-.11.545-.442.441-.816-.27-.973-1.166-1.676-2.198-1.723-.379-.017-.379-.017-.506-.383a7.826 7.826 0 00-.147-.395c-.216-.53-.573-.943-1.09-1.202-.27-.134-.573-.204-.86-.303h-.603c-.02.01-.036.024-.058.028-1.346.28-2.09 1.171-2.09 2.511v6.75H.003c0 .458-.008.905.002 1.351.05 2.5 1.18 4.364 3.417 5.577.125.068.185.134.18.28-.012.343.015.686-.007 1.027-.033.546.168.936.699 1.143h.432c.528-.214.722-.612.681-1.152-.014-.207-.002-.419-.002-.63h13.18c0 .2.012.416-.002.63-.039.538.156.938.681 1.152h.432c.53-.207.732-.598.698-1.143-.021-.334.008-.668-.007-1.001-.007-.167.06-.245.202-.322 3.026-1.637 3.597-4.26 3.365-6.912z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgBathFilters = React.memo(SvgBath)
export default MemoSvgBathFilters
