import * as React from 'react'

function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M18 6.678C18 7.5 16.873 8.113 16 7c-.5-.638-.323-2.568 2-2.529 2.323.04 2.968 1.913 3 2.845v7.553c0 4.552-3.226 5.984-4.839 6.131H8.323C4.142 21 3.033 17.763 3 16.144V8.69C3 4.256 5.968 3.05 7.452 3h7.064C12.21 4.03 7.31 6.067 7.452 11.044c.145 5.1 4.956 6.42 7.064 4.316 2.109-2.105.984-6.67-2.516-6.67"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgInstagram = React.memo(SvgInstagram)
export default MemoSvgInstagram
