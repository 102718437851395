import styled from 'styled-components'

export const BackgroundLayer = styled.div`
  position: fixed;
  top: -25vh;
  height: 150vh;
  width: 100vw;
  content: ' ';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`
export const ContainerLayer = styled.div`
  display: block;
`
