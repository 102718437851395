import React, { useEffect, useState } from 'react'
import { KeyLabel } from 'types/KeyLabel'

interface ChartPeriodSelectorProps {
  setChartPeriod: (period: string) => void
  purchaseDate?: number
}

export const ChartPeriodSelector = ({ setChartPeriod, purchaseDate }: ChartPeriodSelectorProps) => {
  const [valuationPeriod, setValuationPeriod] = useState('1y')

  useEffect(() => {
    setValuationPeriod(purchaseDate ? 'sinceSold' : '1y')
  }, [])
  const valuationHistoryPeriods = [
    {
      key: '1w',
      label: '1W',
    },
    {
      key: '1m',
      label: '1M',
    },
    {
      key: '6m',
      label: '6M',
    },
    {
      key: '1y',
      label: '1Y',
    },
    {
      key: '5y',
      label: '5Y',
    },
    {
      key: purchaseDate ? 'sinceSold' : undefined,
      label: 'Since Sold',
    },
    {
      key: 'all',
      label: 'All',
    },
  ].filter((o) => o.key) as KeyLabel[]

  const onPeriodChange = (e: any) => {
    setValuationPeriod(e.currentTarget.value)
    setChartPeriod(e.currentTarget.value)
  }

  return (
    <>
      <div className="flex justify-between border border-primary-color rounded-lg space-x-1 w-full pr-1">
        {valuationHistoryPeriods.map((term) => (
          <div key={term.key} className="flex justify-center m-1 w-full">
            <button
              type="button"
              value={term.key}
              onClick={onPeriodChange}
              className={[
                'text-xs border border-none rounded-lg font-semibold p-1 md:p-3 w-full whitespace-nowrap',
                term.key === valuationPeriod ? 'bg-gray-500 text-white' : 'text-primary-color',
              ].join(' ')}
            >
              {term.label}
            </button>
          </div>
        ))}
      </div>
    </>
  )
}
