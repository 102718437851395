import * as React from 'react'

function SvgWrite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M20 21H4l4-2L18.406 6.702a1 1 0 00-.057-1.353l-1.58-1.58a1 1 0 00-1.471.06L5 16l-1 3"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgWrite = React.memo(SvgWrite)
export default MemoSvgWrite
