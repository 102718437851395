import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Title = styled.h1<{
  fontSize?: number
  color?: string
}>`
  font-size: ${(p) => p.fontSize || 42}px;
  font-weight: 600;
  line-height: 120%;
  color: ${(p) => p.color || 'var(--secondary-color)'};
`
export const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: normal;
  line-height: 140%;
  color: var(--default-color);
`
export const TextBody = styled.p<{
  fontSize?: number
  fontWeight?: number
  color?: string
}>`
  font-size: ${(p) => p.fontSize || 16}px;
  font-weight: ${(p) => p.fontWeight || 400};
  line-height: 140%;
  color: ${(p) => p.color || 'var(--default-color)'};
`
export const TextBodyDark = styled.p<{
  fontWeight?: number
}>`
  font-weight: ${(p) => p.fontWeight || 400};
  font-size: 14px;
  line-height: 140%;
  color: var(--secondary-color);
`
export const TextBodyWarning = styled.p<{
  fontSize?: number
  fontWeight?: number
}>`
  font-weight: ${(p) => p.fontWeight || 400};
  font-weight: normal;
  line-height: 140%;
  color: var(--warning-color);
`
export const RouteLink = styled(Link)<{
  fontWeight?: number
  fontSize?: number
  color?: string
}>`
  font-weight: ${(p) => p.fontWeight || 600};
  font-size: ${(p) => p.fontSize || 16}px;
  color: ${(p) => p.color || 'var(--primary-color)'};
  line-height: 140%;
`
export const A = styled.a<{
  fontSize?: number
}>`
  font-weight: 600;
  font-size: ${(p) => p.fontSize || 16}px;
  line-height: 140%;
  color: var(--primary-color);
  cursor: pointer;
`
export const HighlightedNumbers = styled.label`
  font-size: 42px;
  font-weight: 600;
  line-height: 115%;
  color: var(--accent-color);
`
