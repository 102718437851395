import * as React from 'react'

function SvgHamburgerMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M0 2a2 2 0 012-2h20a2 2 0 110 4H2a2 2 0 01-2-2zM0 12a2 2 0 012-2h20a2 2 0 110 4H2a2 2 0 01-2-2zM0 22a2 2 0 012-2h20a2 2 0 110 4H2a2 2 0 01-2-2z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgHamburgerMenu = React.memo(SvgHamburgerMenu)
export default MemoSvgHamburgerMenu
