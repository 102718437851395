export module Config {
  export const getGMapKey = () => `${process.env.REACT_APP_GMAP_KEY}`
  export const getAwsRegion = () => `${process.env.AWS_REGION}`
  export const getAwsApiEnv = () => `${process.env.AWS_API_ENV}`
  export const getPropertyApiId = () => `${process.env.AWS_PROPERTY_API_ID}`
  export const getPhotoBucketName = () =>
    process.env.REACT_APP_S3_BUCKET_NAME_FOR_PHOTO &&
    process.env.REACT_APP_S3_BUCKET_NAME_FOR_PHOTO.trim().length > 0
      ? `${process.env.REACT_APP_S3_BUCKET_NAME_FOR_PHOTO}`
      : undefined
}
