import * as React from 'react'

function SvgPhoto(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#photo_svg__clip0_21_52)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.685 2.281A.632.632 0 018.211 2h7.579c.167 0 .328.067.446.185L17.5 3.448a.631.631 0 11-.893.893l-1.078-1.078h-6.98L6.21 6.771a.632.632 0 01-.526.282H3.158a1.895 1.895 0 00-1.895 1.894v10.106c0 1.046.848 1.894 1.895 1.894h17.684a1.895 1.895 0 001.895-1.894V8.947a1.895 1.895 0 00-1.895-1.894H15.79c-1.477 0-3.387.447-4.832 1.531-1.408 1.056-2.41 2.736-2.12 5.346.203 1.83 1.654 3.228 3.162 3.228A3.158 3.158 0 0015.158 14c0-1.381-.54-2.094-1.023-2.476a2.606 2.606 0 00-.919-.462 1.321 1.321 0 00-.052-.012l-.008-.001h.003m-.003 0a.632.632 0 01.21-1.246l-.103.623.104-.623h.002l.005.001.011.002a1.705 1.705 0 01.142.032c.089.023.21.058.352.11.283.106.66.286 1.039.585.78.616 1.503 1.69 1.503 3.467A4.421 4.421 0 0112 18.421c-2.282 0-4.158-2.02-4.417-4.351-.342-3.074.867-5.184 2.617-6.496 1.713-1.285 3.908-1.785 5.59-1.785h5.052A3.158 3.158 0 0124 8.947v10.106a3.158 3.158 0 01-3.158 3.157H3.158A3.158 3.158 0 010 19.053V8.947A3.158 3.158 0 013.158 5.79h2.188l2.339-3.508"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="photo_svg__clip0_21_52">
          <path fill={props.color || 'var(--default-icon-color, #000)'} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgPhoto = React.memo(SvgPhoto)
export default MemoSvgPhoto
