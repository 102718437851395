import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { HomeValuationServices } from 'services/homeValuation'
import { NeighbourhoodServices } from 'services/neighbourhood'
import { useValuationAnnualReportStore } from 'store/dashboard/valuationAnnualReport/useValuationAnnualReportStore'
import { useValuationsStore } from 'store/dashboard/valuations'
import { HomeValueEstimation } from 'types/HomeValuation'
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { CurrencyHelper, DateHelper } from 'utilities'
import { useSearchParams } from 'utilities/hooks/useSearchParams'
import { AuthContext } from 'views/authentication'
import { DashboardHeader } from 'views/dashboard/components/header'
import { dashboardPaths } from 'views/dashboard/paths'
import { Book, GrowthArrow } from 'views/shared/components/icons'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { Spinner } from 'views/shared/components/spinner/Spinner'
import { TextBody, UnderlinedPrimaryButton } from 'views/shared/components/ui-form'
import { EditAnnualReportForm } from '../profile/insights/EditAnnualReportForm'
import { ValuationsItem } from './components/ValuationsItem'
import { PdfAnnualReport } from './ValuationAnnualReport/pdf'

export const ValuationsPage = () => {
  const history = useHistory()
  const searchParams = useSearchParams()
  const { valuationsState, fetchValuations } = useValuationsStore()
  const { fetchAnnualReportList, annualReportListState, setAnnualReportListState } =
    useValuationAnnualReportStore()
  const [idsLoading, setIdsLoading] = useState<string[]>([])
  const [isHomeValuesLoading, setIsHomeValuesLoading] = useState<boolean>(false)
  const [isAnnualReportLoading, setIsAnnualReportLoading] = useState<boolean>(false)
  const { isAgent, isAdmin } = useContext(AuthContext)
  const [editingAnnualReport, setEditingAnnualReport] =
    useState<HomeValuationAnnualReport.Valuation | null>(null)

  const isLoading = (id: string) => idsLoading.indexOf(id) > -1
  const header = React.createRef<HTMLInputElement>()

  const scrollTop = () => {
    header?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const review = (vid?: string) => {
    scrollTop()
    history.push(dashboardPaths.confirmation(vid || ''))
  }

  const preview = async (annualReportById: string) => {
    if (isLoading(annualReportById)) return
    setIdsLoading([...idsLoading, annualReportById])
    const report = await HomeValuationServices.getAnnualReportById(annualReportById)
    const neighbourhoods = await NeighbourhoodServices.getNeighbourhoods()
    const result = await PdfAnnualReport.generate(report, neighbourhoods)
    setIdsLoading([...idsLoading].filter((id) => id !== annualReportById))
    window.open(result?.url, '_blank')
  }

  const onPrintClick = async (reportId: string) => {
    await HomeValuationServices.printAnnualReport(reportId).then((newReport) => {
      if (newReport && annualReportListState) {
        setAnnualReportListState(
          annualReportListState.map((report) => (report.id === newReport.id ? newReport : report)),
        )
      }
    })
  }

  const onEditClick = async (rep: HomeValuationAnnualReport.Valuation) => {
    setEditingAnnualReport(rep)
  }

  const afterEditingAnnualReport = async (
    newReport: HomeValuationAnnualReport.Valuation | null,
  ) => {
    if (newReport && annualReportListState) {
      setAnnualReportListState(
        annualReportListState.map((report) => (report.id === newReport.id ? newReport : report)),
      )
    }
    setEditingAnnualReport(null)
  }

  const removeAnnualReport = async (annualReportId: string) => {
    await HomeValuationServices.removeAnnualReport(annualReportId).then(() => {
      setAnnualReportListState(
        (annualReportListState || []).filter((rep) => rep.id !== annualReportId),
      )
    })
  }

  const requestList = [...(valuationsState || []).filter((item) => item.request)]

  useEffect(() => {
    const init = async () => {
      setIsHomeValuesLoading(true)
      setIsAnnualReportLoading(true)
      await Promise.all([
        fetchValuations().finally(() => setIsHomeValuesLoading(false)),
        fetchAnnualReportList(isAdmin).finally(() => setIsAnnualReportLoading(false)),
      ])
      const annualReportIdInUrl = searchParams.get('preview')
      if (annualReportIdInUrl) {
        preview(annualReportIdInUrl)
      }
      const homeValueIdInUrl = searchParams.get('review')
      if (homeValueIdInUrl) {
        review(homeValueIdInUrl)
      }
    }

    init()
    return () => {}
  }, [isAdmin])

  // --- DO NOT DELETE: Creates a grouped list when the user does NOT request to the agent a home valuation confirmation ---
  // const unRequestList = [...(valuationsState || []).filter((item) => item.request)]
  // type ValuationType = typeof unRequestList[number]
  // const unRequestGroupped = Array.from(
  //   unRequestList
  //     .reduce((map, item) => {
  //       if (!item.user || !item.user.id) return map
  //       map.set(item.user.id, [...(map.get(item.user.id) || []), item])
  //       return map
  //     }, new Map<string, ValuationType[]>())
  //     .values(),
  // ).map((valutions) => ({
  //   user: valutions[0].user,
  //   valutions,
  // }))

  const homeValueHeader = (item: HomeValueEstimation): string =>
    item.createdAt ? `created on ${DateHelper.formatDateToLong(item.createdAt)}` : ''

  const annualReportHeader = (item: HomeValuationAnnualReport.Valuation): string => {
    const created = `created on ${DateHelper.formatDateToLong(item.createdAt)}`
    const print = item.printRequestAt
      ? `, print requested on ${DateHelper.formatDateToLong(item.printRequestAt)}`
      : ''
    return `${created}${print}`
  }

  return (
    <>
      <div className="w-full h-full" ref={header}>
        <DashboardHeader title={isAdmin ? 'Annual Reports' : 'Contact Valuations'} />
        <div className="h-full mb-28 px-5 py-10">
          {isAgent && (
            <div className="py-10">
              <UnderlinedPrimaryButton
                onClick={() => history.push('/home-valuation/property-address')}
              >
                + Create New Valuation
              </UnderlinedPrimaryButton>
            </div>
          )}
          {isAgent && (
            <p className="font-semibold text-accent-color text-2xl pb-5">Confirmation Requests</p>
          )}
          {isAgent && isHomeValuesLoading && (
            <div>
              <div className="flex flex-col h-16">
                <Spinner className="h-16" alt="Loading" />
              </div>
            </div>
          )}
          {isAgent && requestList.length > 0 ? (
            <ul className="space-y-5 pb-5 max-w-5xl">
              {requestList.map(
                (item) =>
                  !item.confirmed && (
                    <li key={item.id}>
                      <ValuationsItem
                        headerIcon={
                          <GrowthArrow style={{ fontSize: '20px' }} color="var(--accent-color)" />
                        }
                        headerLabel="Valuation Confirmation Request"
                        headerDate={homeValueHeader(item)}
                        button1Label="Review"
                        onclick={() => {
                          review(item.id)
                        }}
                      >
                        <div>
                          <div>
                            <span className="text-primary-color font-semibold">
                              $
                              {item.instanceValue &&
                                CurrencyHelper.currencyFormat(item.instanceValue)}
                            </span>{' '}
                            <span className="text-secondary-color">
                              {item.displayAddress}, <span>{item.lastListing?.mls}</span>
                            </span>
                          </div>
                          <div>
                            <span className="text-default-color">From</span>{' '}
                            <span className="font-semibold text-secondary-color">
                              {item.user?.firstName} {item.user?.lastName}
                            </span>
                          </div>
                        </div>
                      </ValuationsItem>
                    </li>
                  ),
              )}
              {/* DO NOT DELETE: Valuation(s) List Added to Contact */}
              {/* {unRequestGroupped.map((item) => (
              <li key={item.user?.id}>
                <ValuationsItem
                  header1Label="New Valuation(s) Added to Contact"
                  headerIcon={
                    <GrowthArrow style={{ fontSize: '20px' }} color="var(--accent-color)" />
                  }
                >
                  <>
                    <div className="pb-2">
                      <span className="text-default-color">
                        Added to{' '}
                        <span className="text-secondary-color font-semibold">
                          {item.user?.firstName} {item.user?.lastName}{' '}
                        </span>
                        profile
                      </span>
                    </div>
                    <ul className="space-y-1">
                      {item.valutions.map((valuation) => (
                        <li key={valuation.id}>
                          <span className="font-semibold text-primary-color">
                            {valuation.instanceValue &&
                              CurrencyHelper.currencyFormat(valuation.instanceValue)}
                          </span>
                          <span> {valuation.displayAddress}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                </ValuationsItem>
              </li>
            ))} */}
            </ul>
          ) : (
            isAdmin || (
              <div className="pb-10">
                {!isHomeValuesLoading && (
                  <TextBody>No Home Valuation Confirmation Request Yet.</TextBody>
                )}
              </div>
            )
          )}
          {/* Annual Reports */}
          {isAgent && (
            <p className="font-semibold text-accent-color text-2xl pb-5 pt-10">Annual Reports</p>
          )}
          {isAnnualReportLoading && (
            <div>
              <div className="flex flex-col h-16">
                <Spinner className="h-16" alt="Loading" />
              </div>
            </div>
          )}
          {annualReportListState.length > 0 ? (
            <ul className="space-y-5 pb-10  w-full max-w-5xl">
              {annualReportListState &&
                annualReportListState.map((item) => (
                  <li key={item.id}>
                    <ValuationsItem
                      headerIcon={<Book style={{ fontSize: '20px' }} color="var(--accent-color)" />}
                      headerLabel="Valuation Annual Report Ready"
                      headerDate={annualReportHeader(item)}
                      button1Label="Edit"
                      onclick={() => onEditClick(item)}
                      button2Label={item.printRequestAt ? 'Print Requested' : 'Request Print'}
                      button2Enabled={!item.printRequestAt}
                      button2Visible={isAgent}
                      onclick2={() => onPrintClick(item.id)}
                      button3Label="Preview"
                      button3Visible={isAdmin}
                      onclick3={() => preview(item.id)}
                      isLoading={isLoading(item.id)}
                      deleteButton
                      onClickDelete={() => removeAnnualReport(item.id)}
                    >
                      <div>
                        <p className="text-secondary-color">{item.homeValue.displayAddress}</p>
                        <div>
                          <span className="text-default-color">Associated to: </span>{' '}
                          <span className="font-semibold text-secondary-color">
                            {item.user?.firstName} {item.user?.lastName}
                          </span>
                        </div>
                      </div>
                    </ValuationsItem>
                  </li>
                ))}
            </ul>
          ) : (
            <div className="pb-10">
              {!isAnnualReportLoading && <TextBody>No Annual Report Available Yet.</TextBody>}
            </div>
          )}
        </div>
      </div>
      <ModalLayer
        onOutsideClick={() => setEditingAnnualReport(null)}
        show={editingAnnualReport !== null}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        {editingAnnualReport && (
          <EditAnnualReportForm
            report={editingAnnualReport}
            closeCallback={afterEditingAnnualReport}
          />
        )}
      </ModalLayer>
    </>
  )
}
