import React, { useContext, useEffect, useState } from 'react'
import {
  PrimaryButton,
  PrimaryNegativeButton,
  SecondaryButton,
  TextBody,
  WarningButton,
  WarningNegativeButton,
  Checkbox,
} from 'views/shared/components/ui-form'
import { Subtitle } from 'views/dashboard/styles'
import { useUserStore } from 'store/dashboard/user'
import { Trash, Write } from 'views/shared/components/icons'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { useHistory } from 'react-router-dom'
import { dashboardPaths } from 'views/dashboard/paths'
import { contactPreferenceKeyLabel, homeStatusKeyLabel } from 'constants/UserConstants'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { AuthContext } from 'views/authentication'
import { ImageBox } from 'views/shared/components/image-box'
import { Storage } from 'aws-amplify'

export type UserDetailsProps = {
  userId: string
  stateIndex: 0 | 1
}

const DEFAULT_HEADSHOT = '/assets/default-profile.jpg'
const loadImagePathByFile = async (fileName?: string | null) => {
  if (fileName && fileName !== null) {
    const imagePath = await Storage.get(`uploads/${fileName.replaceAll(`uploads/`, ``)}`)
    if (imagePath) {
      return imagePath
    }
  }
  return DEFAULT_HEADSHOT
}

export type ProfileDetailsSection = 'details' | 'agent' | 'testimonial' | null
export const UserDetailsPage = ({ userId, stateIndex }: UserDetailsProps) => {
  const userStore = useUserStore(stateIndex, userId)
  const {
    detailsForm,
    agentForm,
    updateDetails,
    restoreDetails,
    userState,
    addTestimonial,
    deleteTestimonial,
    testimonialForm,
    editTestimonial,
    resendInvite,
    inviteSent,
    newMessage,
    receiveSoldReportHandler,
  } = userStore
  const [isEditing, setIsEditing] = useState<ProfileDetailsSection>(null)
  const [isImageModalOpen, setIsImageModalOpen] = useState({
    uploadHeadshot: false,
    uploadLogo: false,
  })
  const [headshotPath, setHeadshotPath] = useState<string>('')
  const [logoPath, setLogoPath] = useState<string>('')
  const history = useHistory()
  const { isLoggedIn, isAgent } = useContext(AuthContext)

  const isEditingDetails = isEditing === 'details'
  const isEditingAgent = isEditing === 'agent'
  const isEditingTestimonial = isEditing === 'testimonial'

  const [showModal, setShowModal] = useState<boolean>(false)
  const [testimonialIndex, setTestimonialIndex] = useState<number>()

  const toggleSection = (section: ProfileDetailsSection) => {
    if (isEditing === section) {
      setIsEditing(null)
    } else {
      setIsEditing(section)
    }
  }
  useEffect(() => {
    const loadImages = async () => {
      const newHeadshotPath = await loadImagePathByFile(detailsForm.state.headshot)
      const newLogoPath = await loadImagePathByFile(agentForm.state.logo)
      setHeadshotPath(newHeadshotPath)
      setLogoPath(newLogoPath)
    }
    loadImages()
  }, [detailsForm.state])

  const submit = async () => {
    detailsForm.touch()
    agentForm.touch()
    if (detailsForm.isValid() === true && isEditing === 'details') {
      setIsEditing(null)
      updateDetails()
    }
    if (agentForm.isValid() === true && isEditing === 'agent') {
      setIsEditing(null)
      updateDetails()
    }
  }
  const archiveUser = async () => {
    if (await userStore.archiveUser()) {
      history.push(dashboardPaths.users())
    }
  }
  const cancel = async () => {
    restoreDetails()
    toggleSection('details')
  }
  const invite = async () => {
    await resendInvite()
  }
  // Testimonial Actions
  const saveTestimonial = async () => {
    testimonialForm.touch()
    testimonialForm.commit()
    if (testimonialForm.isValid()) {
      setIsEditing(null)
      await addTestimonial()
    }
  }
  const closeDeleteModal = () => {
    setShowModal(false)
  }
  const openDeleteModal = (index: number) => {
    setTestimonialIndex(index)
    setShowModal(true)
  }
  const cancelTestimonial = () => {
    setIsEditing(null)
    testimonialForm.reset()
  }
  const editTestimonialForm = (index: number) => {
    editTestimonial(index)
    setIsEditing('testimonial')
  }
  const removeTestimonial = (index: number) => {
    deleteTestimonial(index)
    closeDeleteModal()
  }
  return (
    <>
      <div className="pt-5 pb-32 space-y-10 overflow-y-auto">
        <div>
          <div className="flex items-center space-x-5 pb-3">
            <Subtitle>Account details</Subtitle>
            {!isEditingDetails && (
              <button onClick={() => toggleSection('details')} type="button">
                <Write style={{ fontSize: '24px' }} color="var(--primary-color)" />
              </button>
            )}
          </div>
          <FieldsContainer>
            <InputViewField
              edit={false}
              type="readonly"
              label="Email"
              stateValidation={detailsForm}
              field="email"
              placeholder="Enter email address"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="firstName"
              edit={isEditingDetails}
              label="First Name"
              placeholder="Enter first name"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="lastName"
              edit={isEditingDetails}
              label="Last Name"
              placeholder="Enter last name"
            />
            {(userState.isCustomer || userState.isAgent) && (
              <InputViewField
                stateValidation={detailsForm}
                field="phone"
                edit={isEditingDetails}
                mask="phone"
                label="Phone"
                placeholder="XXX-XXX-XXXX"
              />
            )}
            {userState.isCustomer && (
              <>
                <InputViewField
                  stateValidation={detailsForm}
                  field="address"
                  edit={isEditingDetails}
                  label="Mailing address"
                  placeholder="Full address"
                />
                <InputViewField
                  stateValidation={detailsForm}
                  field="dob"
                  edit={isEditingDetails}
                  label="DOB"
                  mask="date"
                  placeholder="mm/dd/yyyy"
                />
                <InputViewField
                  label="Contact Preference"
                  type="dropdown"
                  stateValidation={detailsForm}
                  field="preferredContactMethod"
                  edit={isEditingDetails}
                  options={contactPreferenceKeyLabel}
                  value={detailsForm.getField('preferredContactMethod')}
                />
              </>
            )}
            {/* Headshot field here */}
            <div className="flex items-center pl-5 h-16">
              <p className="w-28 font-semibold text-secondary-color">Headshot</p>
              {!isEditingDetails && (
                <div>
                  <img className="inline-block h-10 w-10  object-cover" src={headshotPath} alt="" />
                </div>
              )}
              {isEditingDetails && (
                <div className="flex items-center space-x-5">
                  <img
                    className="inline-block h-10 w-10 filter object-cover grayscale"
                    src={headshotPath}
                    alt=""
                  />
                  <div>
                    <button
                      type="button"
                      className="text-primary-color font-semibold underline"
                      onClick={() =>
                        setIsImageModalOpen({ uploadHeadshot: true, uploadLogo: false })
                      }
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
              <ImageBox
                isOpen={isImageModalOpen.uploadHeadshot}
                value={detailsForm.state.headshot}
                onChange={(value, path) => {
                  detailsForm.state.headshot = value
                  setHeadshotPath(path)
                  setIsImageModalOpen({ uploadHeadshot: false, uploadLogo: false })
                }}
                onClose={() => setIsImageModalOpen({ uploadHeadshot: false, uploadLogo: false })}
              />
            </div>
          </FieldsContainer>
          {userState.isCustomer && (
            <div className="pt-10">
              <Subtitle className="pb-3">Home ownership</Subtitle>
              <FieldsContainer>
                <InputViewField
                  label="Status"
                  type="dropdown"
                  stateValidation={detailsForm}
                  field="ownershipStats"
                  edit={isEditingDetails}
                  options={homeStatusKeyLabel}
                  placeholder="Select"
                  value={detailsForm.getField('ownershipStats')}
                />
                <InputViewField
                  label="Purchase / Lease date"
                  stateValidation={detailsForm}
                  field="purchaseDate"
                  mask="date"
                  edit={isEditingDetails}
                  placeholder="mm/dd/yyyy"
                  value={detailsForm.getField('purchaseDate')}
                />
              </FieldsContainer>
            </div>
          )}
          {isLoggedIn && isAgent && userState.isCustomer && (
            <div className="mt-10 space-y-3">
              <Subtitle>Subscriptions</Subtitle>
              <div className="flex items-center space-x-3">
                <Checkbox
                  checked={detailsForm.getField('receiveSoldReport')}
                  onChange={(e) => receiveSoldReportHandler(e.currentTarget.checked)}
                  id="receiveSoldReport"
                  name="receiveSoldReport"
                />
                <TextBody>Monthly Sold Reports</TextBody>
              </div>
            </div>
          )}
          {isEditingDetails && (
            <div className="flex space-x-3 my-5">
              <PrimaryButton onClick={submit} type="button">
                Save
              </PrimaryButton>
              <PrimaryNegativeButton onClick={() => cancel()} type="button">
                Cancel
              </PrimaryNegativeButton>
            </div>
          )}
        </div>
        {userState.isAgent && (
          <div>
            <div className="flex items-center space-x-5 pb-3">
              <Subtitle>Agent details</Subtitle>
              {!isEditingDetails && (
                <button onClick={() => toggleSection('agent')} type="button">
                  <Write style={{ fontSize: '24px' }} color="var(--primary-color)" />
                </button>
              )}
            </div>
            <FieldsContainer>
              <InputViewField
                stateValidation={agentForm}
                field="website"
                edit={isEditingAgent}
                label="Website"
                placeholder="Enter website"
              />
              {/* Logo field here */}
              <div className="flex items-center pl-5 h-16">
                <p className="w-28 font-semibold text-secondary-color">Logo</p>
                {!isEditingAgent && (
                  <div>
                    <img className="inline-block h-10 w-10  object-cover" src={logoPath} alt="" />
                  </div>
                )}
                {isEditingAgent && (
                  <div className="flex items-center space-x-5">
                    <img
                      className="inline-block h-10 w-10 filter object-cover grayscale"
                      src={logoPath}
                      alt=""
                    />
                    <div>
                      <button
                        type="button"
                        className="text-primary-color font-semibold underline"
                        onClick={() =>
                          setIsImageModalOpen({ uploadHeadshot: false, uploadLogo: true })
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
                <ImageBox
                  isOpen={isImageModalOpen.uploadLogo}
                  value={agentForm.state.logo}
                  onChange={(value, path) => {
                    agentForm.state.logo = value
                    setLogoPath(path)
                    setIsImageModalOpen({ uploadHeadshot: false, uploadLogo: false })
                  }}
                  onClose={() => setIsImageModalOpen({ uploadHeadshot: false, uploadLogo: false })}
                />
              </div>
              <InputViewField
                stateValidation={agentForm}
                field="jobDescription"
                edit={isEditingAgent}
                label="Title"
                placeholder="Enter position"
              />
              <InputViewField
                stateValidation={agentForm}
                field="aboutMe"
                type="textarea"
                edit={isEditingAgent}
                label="Bio"
                placeholder="Enter full bio"
              />
              <InputViewField
                stateValidation={agentForm}
                field="instagram"
                edit={isEditingAgent}
                label="Instagram"
                placeholder="Instagram profile name"
              />
              <InputViewField
                stateValidation={agentForm}
                field="linkedin"
                edit={isEditingAgent}
                label="LinkedIn"
                placeholder="LinkedIn profile name"
              />
              <InputViewField
                stateValidation={agentForm}
                field="facebook"
                edit={isEditingAgent}
                label="Facebook"
                placeholder="Facebook profile name"
              />
              <InputViewField
                stateValidation={agentForm}
                field="twitter"
                edit={isEditingAgent}
                label="Twitter"
                placeholder="Twitter profile name"
              />
            </FieldsContainer>
            {isEditingAgent && (
              <div className="flex space-x-3 my-5">
                <PrimaryButton onClick={submit} type="button">
                  Save
                </PrimaryButton>
                <PrimaryNegativeButton onClick={() => toggleSection('agent')} type="button">
                  Cancel
                </PrimaryNegativeButton>
              </div>
            )}
            {userState.isAgent && (
              <>
                <div className="mt-10">
                  {userState.details?.testimonials && <Subtitle>Testimonials</Subtitle>}
                  <FieldsContainer>
                    <li>
                      <ul className="bg-white rounded-lg">
                        {(userState.details?.testimonials || []).map((testimonial, index) => (
                          <li key={testimonial} className="flex justify-between items-start p-5">
                            <TextBody>{testimonial}</TextBody>
                            <div className="flex space-x-5 items-center pl-3">
                              <button
                                className="underline text-primary-color font-semibold"
                                type="button"
                                onClick={() => {
                                  editTestimonialForm(index)
                                }}
                              >
                                <Write style={{ fontSize: '24px' }} color="var(--primary-color)" />
                              </button>
                              <button
                                className="underline text-warning-color font-semibold"
                                type="button"
                                onClick={() => openDeleteModal(index)}
                              >
                                <Trash style={{ fontSize: '24px' }} color="var(--warning-color)" />
                              </button>

                              <ModalLayer
                                onOutsideClick={closeDeleteModal}
                                show={showModal}
                                responsive={{
                                  small: 'full',
                                }}
                                outsideColor="rgba(0, 0, 0, 0.5)"
                              >
                                <div className="max-w-sm bg-white rounded-lg">
                                  <button
                                    className="text-xl font-semibold text-primary-color text-right w-full pr-2"
                                    type="button"
                                    onClick={closeDeleteModal}
                                  >
                                    x
                                  </button>
                                  <div className="px-5 pt-0 pb-5">
                                    <TextBody className="pb-5">
                                      Are you sure you want to permanently remove this testimonial?
                                    </TextBody>
                                    <WarningButton
                                      onClick={() => removeTestimonial(testimonialIndex || 0)}
                                      className="bg-white"
                                    >
                                      Delete
                                    </WarningButton>
                                  </div>
                                </div>
                              </ModalLayer>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </FieldsContainer>
                </div>
                {/* Add testimonial */}
                <div>
                  {isEditingTestimonial && (
                    <ul>
                      <InputViewField
                        stateValidation={testimonialForm}
                        field="testimonial"
                        edit
                        type="textarea"
                        placeholder="Add testimonial"
                      />
                    </ul>
                  )}
                  {isEditingTestimonial && (
                    <>
                      <div className="flex space-x-5 items-center justify-end">
                        <button
                          className="underline text-primary-color font-semibold"
                          onClick={saveTestimonial}
                          type="button"
                        >
                          Save
                        </button>
                        <button
                          className="underline text-warning-color font-semibold"
                          onClick={cancelTestimonial}
                          type="button"
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {!isEditingTestimonial && (
                  <div className="max-w-xs mx-auto">
                    <SecondaryButton
                      onClick={() => editTestimonialForm(-1)}
                      className="mt-5"
                      type="button"
                    >
                      Add Testimonial
                    </SecondaryButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className="max-w-xs mx-auto">
          {userId.indexOf('@') > -1 && (
            <PrimaryButton className="mb-3" onClick={invite}>
              Resend Invite
            </PrimaryButton>
          )}
          <WarningNegativeButton onClick={() => archiveUser()}>Archive User</WarningNegativeButton>
          <p
            className={[
              ' py-3 text-xs text-center',
              inviteSent ? 'text-accent-color' : 'text-warning-color',
            ].join(' ')}
          >
            {newMessage}
          </p>
        </div>
      </div>
    </>
  )
}
