import React from 'react'
import { DropdownField } from 'views/shared/components/ui-form'
import styled from 'styled-components'
import { KeyLabel } from 'types'

const Item = styled.button<{
  selected: boolean
}>`
  background-color: ${(p) => (p.selected ? 'var(--default-color)' : 'transparent')};
  color: ${(p) => (p.selected ? '#FFFFFF' : 'var(--primary-color)')};
  border: ${(p) =>
    p.selected ? '1px solid var(--default-color)' : '1px solid var(--primary-soft-color)'};
  padding: 9px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  &.--selected {
    color: var(--on-primary-color);
    border-color: transparent;
  }
  &.--disabled {
    background-color: transparent;
    color: var(--primary-color);
    opacity: 0.5;
    border: 0;
    cursor: default;
  }
`

export type SelectionButtonOption = {
  label: string | React.ReactNode
  value: string
  disabled?: boolean
}

export type SelectionButtonsProps = Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> & {
  options?: SelectionButtonOption[]
  value?: string
  onChange?: (value: string) => void
}
export const SelectionButtons = (props: SelectionButtonsProps) => {
  const { className, options, value, onChange } = props
  const rootProps = {
    ...props,
    options: undefined,
    className: undefined,
    value: undefined,
    onChange: undefined,
  }
  const onClick = (itemValue: string, disabled: boolean) => () => {
    if (!disabled && onChange && value !== itemValue) onChange(itemValue)
  }
  const onSelect = (itemValue: string) => {
    const isItemDisabled = options?.find((v) => v.value === itemValue)?.disabled || false
    if (!isItemDisabled && onChange && value !== itemValue) onChange(itemValue)
  }

  return (
    <>
      <div
        {...rootProps}
        className={[
          'hidden md:flex flex-row justify-between md:justify-evenly flex-wrap relative text',
          className || '',
        ].join(' ')}
      >
        {options?.map((item) => (
          <Item
            onClick={onClick(item.value, item.disabled === true)}
            className={[
              item.value === value ? `--selected` : ``,
              item.disabled === true ? `--disabled` : ``,
            ].join(' ')}
            selected={item.value === value}
            key={item.value}
          >
            {item.label}
          </Item>
        ))}
      </div>
      <div {...rootProps} className={['block md:hidden relative text', className || ''].join(' ')}>
        <DropdownField
          onChange={onSelect}
          options={options?.map(
            (v) => ({ key: v.value, label: v.label, disabled: v.disabled } as KeyLabel),
          )}
          value={value}
          name="sageReportSelection"
        />
      </div>
    </>
  )
}
