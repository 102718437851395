import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useHistory } from 'react-router-dom'

import { PrimaryButton, RouteLink, Title, InputField } from 'views/shared/components/ui-form'
import { AuthException, AuthServices } from 'services/auth'
import { paths } from '../../paths'
import { AuthLayout } from '../../components'

export const ResetPassword = () => {
  const history = useHistory()
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null)

  const onSubmit = async (username: string, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      setSubmitting(true)
      AuthServices.setLocalStorageEmail(username)
      await AuthServices.forgotPassword(username)
      history.push(paths.resetPasswordVerification())
    } catch (error) {
      setResponseErrors([(error as AuthException).message])
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values.email, setSubmitting)
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('Required'),
    }),
  })

  return (
    <AuthLayout>
      <div className="mb-10">
        <Title>
          Reset password
          <br /> by email
        </Title>
      </div>
      <form action="#" method="POST" onSubmit={formik.handleSubmit}>
        <div className="mb-10">
          <InputField name="email" label="Email address" type="text" formik={formik} />
          {responseErrors && (
            <div className="p-2">
              {responseErrors.map((message) => (
                <div key={message} className="mt-1 text-warning-color font-semibold text-sm">
                  {message}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mb-5">
          <PrimaryButton type="submit" disabled={formik.isSubmitting}>
            Reset Password
          </PrimaryButton>
        </div>
        <div>
          <RouteLink className="underline" to="/sign-in">
            Back to sign in
          </RouteLink>
        </div>
      </form>
    </AuthLayout>
  )
}
