import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  overflow-y: scroll;
  bottom: 0px;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 900;
  background-color: #fff;
  box-shadow: -6px 0px 16px rgba(0, 0, 0, 0.25);
  transition: 3s ease-in-out;
  animation: showFromBottom 0.5s ease-in-out forwards;
  @media (min-width: 640px) {
    position: fixed;
    max-width: 600px;
    max-height: 660px;
    padding: 32px;
    right: 0px;
    bottom: 45px;
    animation: showFromRight 0.5s ease-in-out;
    overflow-y: auto;
  }
  // Desktop
  @keyframes showFromRight {
    0% {
      transform: translateX(700px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  // Mobile
  @keyframes showFromBottom {
    from {
      bottom: -700px;
      transition: 0.5s ease-in-out;
    }
    to {
      bottom: 0;
      transition: 0.5s ease-in-out;
    }
  }
`
export const Button = styled.button`
  position: fixed;
  right: -20px;
  transition: 0.3s ease-in-out;
  bottom: calc(100vh - 50%);
  cursor: pointer;
  box-sizing: border-box;
  background-color: #ffffff;
  z-index: 1;
  border-top: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 54px;
  padding: 8px;
  height: 48px;
  border-radius: 8px 8px 0 0px;
  transform: rotate(-90deg);
  &:hover {
    right: -32px;
    transition: 0.3s ease-in-out;
  }
  @media (min-width: 640px) {
    right: -40px;
    padding: 8px;
    height: 48px;
    width: 100px;
  }
`
export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  float: right;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
  transform: rotate(90deg);
  @media (min-width: 640px) {
    left: 10px;
    transform: rotate(0deg);
  }
`
export const FeedbackButtons = styled.button<{
  selected?: boolean
}>`
  background-color: ${(p) => (p.selected ? 'var(--default-color)' : 'transparent')};
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
  border-radius: 8px;
  border: none;
  & path {
    stroke: ${(p) => (p.selected ? 'var(--on-default-color)' : 'var(--primary-color)')};
  }
`
