import React, { ReactElement, useCallback } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { PageFooter } from 'views/shared/components/page-footer'
import { ToggleInput } from 'views/shared/components/ui-form/toggle-input/ToggleInput'
import { SearchMap } from 'views/map/components/SearchMap'
import { paths } from 'paths'
import { Gallery, Location } from 'views/shared/components/icons'
import styled from 'styled-components'
import { Root } from './HomeSearch.styled'
import { SearchBar } from './SearchBar'
import { HomeSearchGallery } from './HomeSearchGallery'

const ToggleInputWrapper = styled.div`
  z-index: 1;
`

export const HomeSearch = (): ReactElement => {
  const history = useHistory()
  const param = useParams<{ saleType: string; mapType: string }>()
  const mapType = param.mapType || 'map'
  const saleType = param.saleType || 'sale'

  const onToggleChange = useCallback((value: string) => {
    history.replace(paths.homeSearch(value, saleType))
  }, [])

  return (
    <>
      <Root className="relative">
        <SearchBar />
        <ToggleInputWrapper className="absolute top-48 left-3 lg:top-20 lg:left-10">
          <ToggleInput
            background="#fff"
            onChange={onToggleChange}
            value={mapType}
            items={[
              {
                label: (
                  <>
                    <span className="hidden sm:block">Map</span>
                    <span className="flex items-center justify-center align-middle sm:hidden">
                      <Location color="var(--primary-color)" className="text-2xl" />
                    </span>
                  </>
                ),
                value: 'map',
              },
              {
                label: (
                  <>
                    <span className="hidden sm:block">Gallery</span>
                    <span className="flex items-center justify-center align-middle sm:hidden">
                      <Gallery color="var(--primary-color)" className="text-2xl" />
                    </span>
                  </>
                ),
                value: 'gallery',
              },
            ]}
          />
        </ToggleInputWrapper>
        <Switch>
          <Route path={paths.homeSearch('map', saleType)} component={SearchMap} />
          <Route path={paths.homeSearch('gallery', saleType)} component={HomeSearchGallery} />
        </Switch>
        <PageFooter />
      </Root>
    </>
  )
}
