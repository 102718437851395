import { CurrencyHelper } from 'utilities/helpers/currencyHelper'
import { DateHelper } from 'utilities/helpers/dateHelper'

export const getInstancePrice = (price: number | undefined) =>
  price ? `$${CurrencyHelper.currencyFormat(price)}` : 'N/A'

export const getInstanceValueDate = (timestamp: number | Date | undefined) => {
  if (!timestamp) return 'N/A'
  const fullDate = DateHelper.formatDateToFull(new Date(timestamp))
  const monthDay = fullDate.split(',')[0]
  return monthDay
}
