import pluralize from 'pluralize'

type PluralRule = [RegExp, string]
const rules: PluralRule[] = [
  [/detached$/i, 'detached'],
  [/semi-detached$/i, 'semi-detached'],
  [/townhouse$/i, 'townhouses'],
  [/condo townhouse$/i, 'condo townhouses'],
  [/condominium$/i, 'condominiums'],
  [/multi residential$/i, 'multi residentials'],
]
export module Pluralize {
  export const configure = () => {
    rules.forEach(([singular, plural]) => {
      pluralize.addPluralRule(singular, plural)
    })
  }
}
