import React from 'react'
import { Validate } from 'views/shared/components/icons'
import { TextBody, Title } from 'views/shared/components/ui-form'

import { PageFooter } from 'views/shared/components/page-footer'
import { A, H3, Item, ItemLabel } from './styles'

export const LandingPage = () => {
  const mailTo = ''
  const subject = 'Get Started Using Castl.'
  const body = `Hi,%0D%0A%0D%0AI am interested in having an account with Castl.%0D%0A%0D%0ACould you please provide me with access? %0D%0A%0D%0APlease, visit yourcastl.com in case you need more information. %0D%0A%0D%0AThank you in advance.`

  return (
    <>
      <div id="top" className="pt-10  xl:pt-20 space-y-16 sm:space-y-36">
        <div className="max-w-7xl mx-auto px-5">
          <Title fontSize={72} color="var(--accent-color)" className="text-center hidden sm:block">
            Your Home. Your Castl.
          </Title>
          <Title fontSize={36} color="var(--accent-color)" className="text-center block sm:hidden">
            Your Home. Your Castl.
          </Title>
          <div className="py-10 sm:py-20">
            <img
              className="block w-full h-full"
              alt="sage-devices-mockup"
              src="/assets/landing-page/sage-devices-mockup.png"
            />
          </div>
          <div>
            <H3 maxWidth={600} className="text-3xl sm:text-4xl pb-10">
              <span className="text-accent-color"> You Deserve</span> to Know Everything About{' '}
              <span className="text-accent-color">your Home.</span>
            </H3>
            <div className="space-y-3 text-secondary-color text-lg">
              <p>
                Homes are sacred spaces. They protect you from the outside world, keep your loved
                ones safe, and strengthen your family&#39;s financial future.
              </p>
              <p>
                At Castl., we believe that you deserve to know everything about your home and the
                neighbourhood around it. It is important to know everything ranging from the equity
                you have built to the very value of your neighbourhood, and about all the properties
                that reside within.
              </p>
              <p>
                We have built Castl. keeping all these values in mind, to provide you with the full
                lay of your land and to protect your biggest investment.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-alpha-color w-full">
          <div className="max-w-7xl mx-auto px-5 py-16 sm:py-36">
            <H3 maxWidth={600} className="text-3xl sm:text-4xl mx-auto pb-10 sm:pb-20">
              An Intuitive Experience to Help You Make Better Decisions.
            </H3>
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-5 xl:gap-16">
              <Item className="space-y-2">
                <Validate style={{ fontSize: '32px' }} color="var(--accent-color)" />
                <ItemLabel>EASE</ItemLabel>
                <TextBody color="var(--secondary-color)" fontSize={18}>
                  We built seamless experiences by delivering easy access to the crucial information
                  that you deserve to know.
                </TextBody>
              </Item>
              <Item className="space-y-2">
                <Validate style={{ fontSize: '32px' }} color="var(--accent-color)" />
                <ItemLabel>KNOWLEDGE</ItemLabel>
                <TextBody color="var(--secondary-color)" fontSize={18}>
                  We share otherwise inaccessible real estate information like current property
                  value, overall neighbourhood value, and sold-for prices.
                </TextBody>
              </Item>
              <Item className="space-y-2">
                <Validate style={{ fontSize: '32px' }} color="var(--accent-color)" />
                <ItemLabel>VALIDATION</ItemLabel>
                <TextBody color="var(--secondary-color)" fontSize={18}>
                  We ensure you feel comfortable and confident while making the best decisions with
                  your biggest assets.
                </TextBody>
              </Item>
            </ul>
          </div>
        </div>
        <div>
          <H3 className="text-3xl sm:text-4xl sm:text-center pb-10 sm:pb-20 w-full px-5">
            Castl. Helps You To:
          </H3>
          <div className="space-y-16 sm:space-y-36">
            <div
              id="homevaluation"
              className="flex flex-col sm:flex-row sm:items-center sm:justify-between  max-w-7xl mx-auto px-5"
            >
              <div className="space-y-3 max-w-lg order-2 sm:order-1 flex-1">
                <TextBody fontWeight={600} color="var(--secondary-color)" fontSize={24}>
                  Track the Value of your Home
                </TextBody>
                <TextBody fontSize={20}>
                  Track the valuation of your home through regular updates from Castl. and let this
                  work as a stock ticker for your most valuable asset.
                </TextBody>
              </div>
              <div className="max-w-xs mx-auto sm:max-w-md order-1 sm:order-2  pb-10 sm:pb-0 flex-1">
                <img
                  className="block w-full h-full"
                  alt="sage-home-valuation-mockup"
                  src="/assets/landing-page/sage-home-valuation-mockup.png"
                />
              </div>
            </div>
            <div className="bg-alpha-color py-16 sm:py-36">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between max-w-7xl mx-auto px-5">
                <div className="max-w-md mx-auto sm:max-w-2xl  pb-10 sm:pb-0 flex-1">
                  <img
                    className="block w-full h-full"
                    alt="sage-home-search-mockup-1"
                    src="/assets/landing-page/sage-home-search-mockup-1.png"
                  />
                </div>
                <div className="space-y-3 max-w-lg flex-1">
                  <TextBody fontWeight={600} color="var(--secondary-color)" fontSize={24}>
                    Learn Sold Price of your Neighbour&#39;s Home
                  </TextBody>
                  <TextBody fontSize={20}>
                    Find out the price at which homes are selling in your neighbourhood.
                  </TextBody>
                </div>
              </div>
            </div>
            <div
              id="soldreport"
              className="flex flex-col sm:flex-row sm:items-center sm:justify-between  max-w-7xl mx-auto px-5"
            >
              <div className="space-y-3 max-w-lg order-2 sm:order-1 flex-1">
                <TextBody fontWeight={600} color="var(--secondary-color)" fontSize={24}>
                  Follow the Market
                </TextBody>
                <TextBody fontSize={20}>
                  Access our monthly sold reports to stay on top of the real estate market, in and
                  around your neighbourhood.
                </TextBody>
              </div>
              <div className="flex-1 max-w-sm mx-auto sm:max-w-xl  order-1 sm:order-2 pb-10 sm:pb-0">
                <img
                  className="block w-full h-full"
                  alt="sage-sold-report-mockup"
                  src="/assets/landing-page/sage-sold-report-mockup.png"
                />
              </div>
            </div>
            <div id="homesearch" className="bg-alpha-color py-16 sm:py-36">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-x-5  max-w-7xl mx-auto px-5">
                <div className="max-w-lg mx-auto sm:max-w-4xl pb-10 sm:pb-0 flex-1">
                  <img
                    className="block w-full h-full"
                    alt="sage-home-search-mockup"
                    src="/assets/landing-page/sage-home-search-mockup-2.png"
                  />
                </div>
                <div className="space-y-3 max-w-lg">
                  <TextBody fontWeight={600} color="var(--secondary-color)" fontSize={24}>
                    Find your Perfect Home
                  </TextBody>
                  <TextBody fontSize={20}>
                    Search for the property of your dreams through Castl.
                  </TextBody>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="getstarted" className="px-5">
          <div className="space-y-3 max-w-lg mx-auto text-center pb-10">
            <H3 className="text-3xl sm:text-4xl text-center pb-5">Get Started with Castl.</H3>
            <TextBody fontSize={20}>
              Get access to Castl. through your realtor. Contact your agent now to set up an
              account.
            </TextBody>
          </div>
          <div className="w-72 mx-auto">
            <A href={`mailto:${mailTo}?subject=${subject}&body=${body}`} target="_blank">
              Request Access
            </A>
          </div>
        </div>
        <PageFooter />
      </div>
    </>
  )
}
