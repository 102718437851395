import React, { useState, useMemo, useCallback } from 'react'
import { Chart, Line } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import { SoldReportDataStatistics } from 'views/soldReport'
import { MonthStr, PropertyType } from 'types'
import { InfoCard } from 'views/shared/components/info-card/InfoCard'
import { getChartOptions } from './getChartOptions'
import { OptionChooser } from './OptionChooser'
import { DatasetLabels } from './DatasetLabels'
import { generateChartData } from './generateChartData'
import { generateAnnotations } from './generateAnnotations'
import { ChartTypeId, DataStatistic } from './types'
import './MarketChart.scss'

Chart.register(annotationPlugin)

const COLOR_GTA = '#716B68'
const COLOR_COMMUNITY = '#C2C2C2'
const COLOR_NEIGHBOURHOOD = '#018363'

const charLayers = [
  {
    chartId: 'price' as ChartTypeId,
    name: 'Price',
    type: 'line',
  },
  {
    chartId: 'dom' as ChartTypeId,
    name: 'Days On Market',
    type: 'line',
  },
  {
    chartId: 'snlr' as ChartTypeId,
    name: 'Sales-To-New Listings Rate',
    type: 'line',
  },
]

type MarketChartProps = {
  communityName: string
  neighbourhoodName: string
  propertyType: PropertyType
  neighbourhoodStatistics: SoldReportDataStatistics
  communityStatistics: SoldReportDataStatistics
  statistics: SoldReportDataStatistics
  yearMonth: MonthStr
}
const getMarketChartData =
  (datasetId: ChartTypeId) =>
  (dataStatistic: DataStatistic | undefined): number => {
    if (!dataStatistic) return 0
    const { soldPrice, dom, soldNumber, newListing } = dataStatistic
    const result = {
      price: soldPrice || 0,
      dom: dom || 0,
      snlr: newListing !== 0 ? (soldNumber / newListing) * 100 : 0,
    }
    return (result && result[datasetId]) || 0
  }

export const MarketChart = ({
  communityName,
  neighbourhoodName,
  propertyType,
  neighbourhoodStatistics,
  communityStatistics,
  statistics,
  yearMonth,
}: MarketChartProps) => {
  const charts = useMemo(
    () =>
      charLayers.map(({ chartId, name, type }) => ({
        chartId,
        name,
        type,
        ...generateChartData({
          datasets: [
            { data: statistics, label: 'GTA', color: COLOR_GTA },
            { data: communityStatistics, label: communityName, color: COLOR_COMMUNITY },
            { data: neighbourhoodStatistics, label: neighbourhoodName, color: COLOR_NEIGHBOURHOOD },
          ],
          propertyType,
          getData: getMarketChartData(chartId),
        }),
      })),
    [
      statistics,
      communityStatistics,
      neighbourhoodStatistics,
      propertyType,
      communityName,
      neighbourhoodName,
    ],
  )

  const [selectedChartId, setSelectedChartId] = useState<ChartTypeId>(charts[0].chartId)
  const selectedChart = charts.find((chart) => chart.chartId === selectedChartId)
  const datasets = selectedChart ? selectedChart.datasets : []
  const annotations = generateAnnotations({ datasets, chartId: selectedChartId })
  const onChartSelected = useCallback((selected) => {
    setSelectedChartId(selected.chartId)
  }, [])

  return (
    <>
      <div className="sold-report_chart xl:mx-10">
        <div className="xl:flex xl:items-center justify-between">
          <div className="flex items-center mb-10 xl:mb-0 lg:ml-12">
            <OptionChooser
              selected={selectedChart}
              idField="chartId"
              options={charts}
              onChange={onChartSelected}
            />
            <div className="hidden md:inline-block">
              <InfoCard
                content="In a seller&#39;s market, the sales-to-listing ratio is generally at 60% or more,
                  which translates to six or more sales for every ten new listings. In a balanced
                  market, the ratio is between 40% and 60%, and in a buyer&#39;s market, you&#39;re
                  looking at fewer than four sales for every ten new listings."
              />
            </div>
          </div>
          <div className="float-right mb-5 xl:mb-0">
            {datasets && <DatasetLabels datasets={datasets} />}
          </div>
        </div>
        <div className="mt-6 w-full">
          <Line
            className="max-w-full"
            data={selectedChart as any}
            options={getChartOptions(annotations, yearMonth) as any}
          />
        </div>
      </div>
    </>
  )
}
