import React, { useContext, useEffect, useState } from 'react'
import { AgentServices } from 'services/agents'
import { AgentProfile } from 'views/agents/types/agents'
import { AuthContext } from 'views/authentication/contexts/AuthContext'
import {
  A,
  PrimaryButton,
  SecondaryNegativeButton,
  Subtitle,
  TextBody,
  Title,
} from 'views/shared/components/ui-form'
import { Spinner } from 'views/shared/components/icons'
import { PageFooter } from 'views/shared/components/page-footer'

export const AgentDetailsPage = () => {
  const { authUser, agentLogo, agentHeadshot } = useContext(AuthContext)
  const [agent, setAgent] = useState<AgentProfile | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (authUser) {
      AgentServices.getMyAgentProfile().then(setAgent)
      setIsLoading(false)
    }
  }, [authUser])

  return (
    <>
      {isLoading && (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner style={{ fontSize: '128px' }} color="#018363" />
        </div>
      )}
      {!isLoading && agent && (
        <div className="max-w-screen-lg mx-auto w-full px-5 lg:px-20 my-10">
          <div className="hidden lg:block mb-6 relative">
            <TextBody className="italic pr-12">
              Our agents live and breathe doing what is right for their clients.
            </TextBody>
          </div>
          <div className="md:flex sm:space-x-3 lg:space-x-10 mb-10">
            <div className="flex justify-between mb-6 lg:mb-0">
              <div className="flex space-x-3">
                {agentLogo && (
                  <div className="w-24 sm:w-36">
                    <img className="w-full" src={agentLogo} alt="Logo" />
                  </div>
                )}
                {agentHeadshot && (
                  <div className="w-24 sm:w-36">
                    <img className="w-full" src={agentHeadshot} alt="Agent" />
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="mb-8">
                <Title fontSize={32} className="block sm:hidden">
                  {agent?.firstName} {agent?.lastName}
                </Title>
                <Title className="hidden sm:block">
                  {' '}
                  {agent?.firstName} {agent?.lastName}
                </Title>
                <Subtitle className="hidden sm:block">{agent?.jobDescription}</Subtitle>
                <p className="block sm:hidden capitalize text-default-color text-base">
                  {agent?.jobDescription}
                </p>
              </div>
              <div className="mb-4">
                <A fontSize={20} href={`tel:+1${agent?.phone}`}>
                  {agent?.phone}
                </A>
              </div>
              <div className="flex space-x-3 sm:max-w-xs mb-8">
                <div className="w-1/2 lg:w-36">
                  <PrimaryButton>
                    <a href={`mailto:${agent?.email}`}>Email</a>
                  </PrimaryButton>
                </div>
                <div className="w-1/2 lg:w-36">
                  {agent?.website && (
                    <SecondaryNegativeButton>
                      <a href={agent?.website} target="_blank" rel="noreferrer">
                        Website
                      </a>
                    </SecondaryNegativeButton>
                  )}
                </div>
              </div>
              <ul className="grid grid-cols-2 gap-y-3 sm:flex sm:space-x-3">
                {agent?.socialMedias?.instagram && (
                  <li>
                    <A href={agent?.socialMedias.instagram} target="_blank" className="underline">
                      Instagram
                    </A>
                  </li>
                )}
                {agent?.socialMedias?.facebook && (
                  <li>
                    <A href={agent?.socialMedias.facebook} target="_blank" className="underline">
                      Facebook
                    </A>
                  </li>
                )}
                {agent?.socialMedias?.twitter && (
                  <li>
                    <A href={agent?.socialMedias.twitter} target="_blank" className="underline">
                      Twitter
                    </A>
                  </li>
                )}
                {agent?.socialMedias?.linkedIn && (
                  <li>
                    <A href={agent?.socialMedias.linkedIn} target="_blank" className="underline">
                      LinkedIn
                    </A>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {agent?.testimonials && (
            <div className="mb-10 lg:ml-0">
              <p className="capitalize font-semibold text-secondary-color text-lg pb-5">
                Testimonials
              </p>
              <div>
                <ul className="lg:max-w-4xl">
                  {agent?.testimonials.map((item) => (
                    <li key={item} className="bg-alpha-color p-4 lg:px-10 rounded-lg mb-5">
                      <TextBody>
                        {item.split(/(\\n)+/).map((line) => (
                          <span key={line}>{line}</span>
                        ))}
                      </TextBody>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {agent?.aboutMe && (
            <div className="mb-10 lg:ml-0 lg:max-w-4xl">
              <p className="font-semibold text-secondary-color text-lg pb-5">
                More about{' '}
                <span className="capitalize">
                  {agent?.firstName} {agent?.lastName}
                </span>
              </p>
              <TextBody>{agent?.aboutMe}</TextBody>
            </div>
          )}
        </div>
      )}
      <PageFooter />
    </>
  )
}
