import React, { useState } from 'react'
import { FieldModal } from 'views/shared/components/modal-box/FieldModal'
import { PrimaryButton } from 'views/shared/components/ui-form'
import { AuthServices } from 'services/auth'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { useClientAccountProfileStore } from 'store/clientAccount/clientAccountStore'

export type UserAccountPasswordModalProps = {
  initValue?: string
  isOpen?: boolean
  onChange?: () => void
  onClose?: () => void
}

export const UserAccountPasswordModal = ({
  isOpen,
  onChange,
  onClose,
}: UserAccountPasswordModalProps) => {
  const { passwordDetails } = useClientAccountProfileStore()
  const [successMessage, setSuccessMessage] = useState('')

  const submit = async () => {
    passwordDetails.touch()
    if (!passwordDetails.isValid()) return
    await AuthServices.changePassword(
      passwordDetails.state.password,
      passwordDetails.state.newPassword,
    )
      .then((e) => {
        if (e === 'SUCCESS') {
          setSuccessMessage('Password changed successfully')
          passwordDetails.reset()
          passwordDetails.setField('password', '')
          passwordDetails.setField('newPassword', '')
          passwordDetails.commit()
          if (onChange) onChange()
        }
      })
      .catch((error) => {
        passwordDetails.addMessage('newPassword', error.message, 'error')
        passwordDetails.commit()
      })
  }

  const onCloseModal = () => {
    setSuccessMessage('')
    if (onClose) onClose()
    passwordDetails.reset()
    passwordDetails.setField('password', '')
    passwordDetails.setField('newPassword', '')
    passwordDetails.commit()
  }

  return (
    <>
      <FieldModal isOpen={isOpen} onClose={onCloseModal}>
        <FieldsContainer>
          <InputViewField
            label="Password"
            stateValidation={passwordDetails as any}
            field="password"
            placeholder="Current password"
            edit
          />
          <InputViewField
            label="New Password"
            stateValidation={passwordDetails as any}
            field="newPassword"
            placeholder="New password"
            edit
          />
        </FieldsContainer>
        <p className="text-accent-color text-xs pt-2">{successMessage}</p>
        <PrimaryButton className="mt-5" onClick={submit}>
          Save
        </PrimaryButton>
      </FieldModal>
    </>
  )
}
