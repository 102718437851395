import { ChartOptions } from 'chart.js/auto'
import { MonthStr } from 'types'
import { CurrencyHelper } from 'utilities'

const font =
  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
const monthValues: { [key: string]: number } = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCurrentMonthAndYear = (currMonth: string, year: number, index: number) => {
  let currYear = year - 1
  if (monthValues[currMonth] < index) {
    currYear += 1
  }
  return `${currMonth} ${currYear}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getChartOptions = (annotations: Record<string, any> = {}, yearMonth: MonthStr) =>
  ({
    animation: {
      duration: 0,
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'average',
        padding: 6,
        displayColors: false,
        backgroundColor: (val: any) => val.tooltip.labelColors[0].backgroundColor,
        caretSize: 10,
        bodyFont: { family: font, weight: 'bold', size: 16 },
        titleFont: {
          family: font,
          weight: 'normal',
          size: 14,
        },
        callbacks: {
          title: (val: any) => {
            const currMonth = val[0].label.replace(/[^a-z]+/gi, '')
            return getCurrentMonthAndYear(currMonth, +yearMonth.split('-')[0], val[0].dataIndex)
          },
          label: (val: any) => {
            const stats = val.dataset.data[val.dataIndex]
            if (stats > 10000) {
              return `$${CurrencyHelper.currencyFormat(stats)}`
            }
            if (stats === 0) {
              return '0'
            }
            return stats > 0 && stats < 1 ? `${stats.toFixed(2)}` : `${stats.toFixed(1)}`
          },
        },
      },
      annotation: {
        annotations,
      },
    },
    scales: {
      y: {
        stacked: false,
        ticks: {
          maxTicksLimit: 7,
          font: {
            family: font,
            size: 14,
            weight: 'bold',
          },
          callback: (val: string) => {
            const n = Number(val)
            if (n === 0) return '0'
            if (n < 1) return `${n.toFixed(2)}`
            if (n >= 100000) return `${(n / 1000000).toFixed(1)}M`.replace('.0', '')
            return `${n}`
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: font,
            size: 14,
            weight: 'bold',
          },
        },
      },
    },
  } as ChartOptions)
