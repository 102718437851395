import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { PropertyServices } from 'services/property/PropertyServices'
import { GalleryItem } from 'views/map/pages/HomeSearch/HomeSearchGallery/GalleryItem'
import { PropertyListing } from 'types/Property'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { HomeValueEstimation } from 'types'
import { Config } from 'config'
import { DateHelper } from 'utilities'
import { ExtendedDetails, HomeValuationSnapshot, ReceiveHomeValuationToggle } from './components'
import { PropertyDetailsForm } from '../property-details/PropertyDetailsForm'

interface HomeValuationItemProps {
  valuation: HomeValueEstimation
}

export const PropertyValuationItem = ({ valuation }: HomeValuationItemProps) => {
  const [expand, setExpand] = useState<boolean>(true)
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)
  const [propertyImageState, setPropertyImageState] = useState<string[] | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [similarProperties, setSimilarProperties] = useState<PropertyListing[]>()

  const handleExpand = () => setExpand((prev) => !prev)

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public`)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (valuation?.lastListing) {
      const newImages: string[] = PropertyServices.getPropertyImageURLs(
        valuation.lastListing,
        imageBaseUrl,
      )
      setPropertyImageState(newImages)
    } else {
      setPropertyImageState([])
    }
  }, [valuation, imageBaseUrl])

  return (
    <>
      <div className="pb-14 mb-14 border-b border-alpha-dark-color border-dotted md:border-none">
        <div className="flex flex-col-reverse mx-auto md:flex-row md:my-10 md:space-x-10 md:space-y-3 w-fit">
          <div className="flex flex-col justify-between">
            <div className="my-4 h-full w-full md:w-80">
              {valuation && (
                <GalleryItem
                  property={valuation.lastListing}
                  images={propertyImageState}
                  bed={valuation.request?.bedroom}
                  bath={valuation.request?.bathroom}
                  parking={valuation.request?.parking}
                  soldDate={valuation.request?.purchaseDate}
                  soldPrice={valuation.request?.purchasePrice}
                  address={valuation.displayAddress}
                  type={valuation.request?.type}
                  footage={valuation.request?.footage || valuation.request?.footageFrom}
                  page="PropertyValuation"
                />
              )}
            </div>
            <span className="text-default-color text-xs">{`created on ${DateHelper.formatDateToLong(
              valuation.createdAt,
            )}`}</span>
            <ReceiveHomeValuationToggle valuation={valuation} />
          </div>
          <HomeValuationSnapshot valuationState={valuation} />
        </div>
        <div className="my-4 md:my-0 relative border-b-2 border-dashed">
          <div className="absolute flex top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <button
              type="button"
              className="border-none px-3 text-sm text-primary-color font-semibold bg-white"
              onClick={handleExpand}
            >
              {expand ? 'See Less' : 'See More'}
            </button>
          </div>
        </div>
        {expand && valuation && (
          <ExtendedDetails
            valuation={valuation}
            setShowModal={setShowModal}
            similarProperties={similarProperties}
            setSimilarProperties={setSimilarProperties}
          />
        )}
      </div>
      <ModalLayer
        onOutsideClick={() => setShowModal(false)}
        show={showModal}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="flex">
          <div className="w-min md:py-5 xl:py-12">
            <button
              className="text-xl font-semibold text-on-primary-color text-right w-full pr-2"
              type="button"
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <div className="space-y-10 max-w-md md:max-w-3xl bg-white w-full rounded-lg border-2 border-primary-color md:border-none md:p-5 xl:p-12">
              {valuation && (
                <PropertyDetailsForm valuationId={valuation.id} setShowModal={setShowModal} />
              )}
            </div>
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
