import React from 'react'

import { DataSet } from './types'

export type OptionsChooserProps = {
  datasets: DataSet[]
}
const Circle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="10" />
  </svg>
)

export const DatasetLabels = ({ datasets }: OptionsChooserProps) => (
  <ul className="flex items-center">
    {datasets
      .map(({ label, color }) => (
        <li key={label} className="flex items-center mr-3 text-default-color text-sm sm:text-base">
          <Circle fill={color || '#000000'} className="inline w-4 h-4 mr-1" /> {label}
        </li>
      ))
      .sort((a, b) => (a > b ? 1 : -1))}
  </ul>
)
