import { HomeValuationAnnualReport } from 'types/HomeValuationAnualReport'
import { PdfHelper } from 'utilities'
import { urlHelper } from 'utilities/helpers/urlHelper'

export const addBackcover = async (
  doc: PDFKit.PDFDocument,
  data: HomeValuationAnnualReport.Valuation,
) => {
  const { agent } = data
  const date = new Date().getFullYear()
  doc.addPage()
  const img = {
    logo: await PdfHelper.getImage(agent.logo ? `logos/${agent.logo}` : '/assets/castl.svg'),
  }

  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">
    <svg  x="25" y="399">
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="10" font-style="italic" letter-spacing="0em"><tspan x="0" y="8.085">I hope this is helpful.&#10;</tspan><tspan x="0" y="25.085">Please let me know if you have any questions.</tspan><tspan x="0" y="42.085"></tspan><tspan x="0" y="59.085">Thanks, </tspan><tspan x="0" y="76.085"></tspan><tspan x="0" y="93.085">${
        agent.firstName
      }</tspan></text>
    </svg>
    <svg  x="25" y="399">
      ${img.logo(0, 158, 40, 40)}
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="10" font-weight="bold" letter-spacing="0em"><tspan x="0" y="225.585">${
        agent.firstName
      } ${agent.lastName}</tspan></text>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="10" letter-spacing="0.05em"><tspan x="0" y="241.585">${
        agent.phone
      }</tspan></text>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="10" letter-spacing="0em"><tspan x="0" y="256.585">${urlHelper.formateURL(
        agent.website,
      )}</tspan></text>
      <text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="10" font-weight="400" letter-spacing="0em"><tspan x="0" y="272.585">${
        agent.email
      }</tspan></text>
    </svg>
    <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="25" y="724">Toronto Regional Real Estate Board (TRREB) assumes no responsibility for the accuracy of any information shown. Copyright © TRREB ${date}</tspan></text>
  </svg>
  `
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
