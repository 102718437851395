import React, { forwardRef } from 'react'
import { Loading } from '../../icons'
import { Input } from '../styled'
import { InputFieldProps } from './types'

const InputFieldErrorItem = ({ value }: { value: string }) => (
  <li className="mt-1 text-warning-color text-sm font-semibold">{value}</li>
)

// eslint-disable-next-line react/display-name
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      formik,
      name,
      label,
      type,
      onChange,
      value,
      maxLength,
      autoComplete,
      placeholder,
      customErrorMessage,
      isSubmitting,
    }: InputFieldProps,
    ref,
  ) => {
    let errorsList: string[] = []
    if (formik && formik.errors !== undefined && formik.errors[name] !== undefined) {
      errorsList = errorsList.concat(formik.errors[name] as any)
    }
    const isValid = errorsList.length === 0
    const isTouched = formik?.touched[name] !== undefined
    return (
      <label
        htmlFor={name}
        className={[
          'block text-sm font-semibold',
          formik?.errors[name] && formik?.touched[name]
            ? 'text-warning-color'
            : 'text-secondary-color',
        ].join(' ')}
        style={{ position: 'relative' }}
      >
        <span>{label}</span>
        <div>
          {customErrorMessage ||
            (!isValid && isTouched && (
              <ul>
                {errorsList.map((errorItem) => (
                  <InputFieldErrorItem key={errorItem} value={errorItem} />
                ))}
              </ul>
            ))}
          {isSubmitting && (
            <Loading
              style={{
                position: 'absolute',
                float: 'right',
                left: '90%',
                marginTop: '10px',
                fontSize: '24px',
                zIndex: 1000,
              }}
            />
          )}
          <Input
            placeholder={placeholder}
            autoComplete={autoComplete ? 'on' : 'off'}
            id={name}
            name={name}
            ref={ref}
            type={type || 'text'}
            maxLength={maxLength}
            defaultValue={value}
            onChange={(e) => {
              formik && formik.handleChange(e)
              onChange && onChange(e)
            }}
            onBlur={() => formik?.setFieldTouched(name)}
            value={formik && name ? formik?.values[name] : ''}
            className={[
              'focus:ring-0',
              !isValid && isTouched ? 'border-warning-color' : 'border-primary-soft-color',
            ].join(' ')}
          />
        </div>
      </label>
    )
  },
)
