import { HomeValuationAnnualReport } from 'types/HomeValuationAnualReport'
import { DateHelper, DayMonthYearType } from 'utilities'

export type ChartProps = {
  oldSoldPrice: number
  newSoldPrice: number
  charData: number[][]
}
type ChartItem = {
  dateValue: number
  date: DayMonthYearType
  value: number
}
export module ChartHelper {
  export const chartSize = {
    w: 514,
    h: 220,
  }
  const getX = (value: number, fullValue: number, width: number = chartSize.w) =>
    (width * value) / fullValue
  const getY = (value: number, fullValue: number) => (chartSize.h * value) / fullValue
  export const getChartPositionData = (chartData: HomeValuationAnnualReport.Valuation['chart']) => {
    const rData = [...chartData].reverse()
    const limits = {
      min: rData.reduce((pv, cv) => (cv[1] <= pv ? cv[1] : pv), Number.MAX_VALUE),
      max: rData.reduce((pv, cv) => (cv[1] >= pv ? cv[1] : pv), -1),
      end: rData[rData.length - 1][0],
      begin: rData[0][0],
      size: rData[rData.length - 1][1] - rData[0][1],
    }
    const items = rData
      .map(
        (v) =>
          ({
            dateValue: v[0] - limits.begin,
            date: DateHelper.dateToYearMonthDay(v[0]),
            value: v[1],
          } as ChartItem),
      )
      .filter((v, i, a) => i === 0 || i === a.length - 1 || [1, 7, 14, 21, 28].includes(v.date.day))
    const labelsKeys = items.reduce((pv, cv, i) => {
      const label = `${cv.date.year}-${cv.date.month + 1}`
      if (pv.at(-1) === label) return pv
      return [...pv, label]
    }, [] as string[])
    const labelsPosition = labelsKeys.map((key, i, a) => {
      const d: number[] = key.split('-').map((v) => Number(v))
      const w = chartSize.w / a.length
      return {
        year: d[0],
        month: DateHelper.getMonthLabel(new Date(d[0], d[1], 1)),
        x: Math.round(getX(i, a.length - 1, chartSize.w - 10)) + 10,
        w,
        showYear: i === 0 || i === a.length - 1,
      }
    })
    const values = items.map((item) => ({
      x: getX(item.dateValue, limits.end - limits.begin),
      y: chartSize.h - getY(item.value - limits.min, limits.max - limits.min),
      value: item.value,
    }))

    return {
      labels: labelsPosition,
      values,
      limits,
    }
  }
}
