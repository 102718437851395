import React from 'react'
import { MailingAddressProp } from 'types'

import { PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'

import {
  // minEightCharsMetTextColor,
  minThreeConditionsMetTextColor,
} from './PasswordErrorMessageHelper'

export const passwordErrorMessageBlock = (
  touched: boolean | undefined,
  errors: string | string[] | undefined,
): JSX.Element => (
  <div className="mt-2 font-semibold">
    <ul>
      {/* <li
        className={[
          'text-sm text-secondary-color list-disc pl-2',
          minEightCharsMetTextColor(touched, errors),
        ].join(' ')}
      >
        {PasswordErrorMsgs.minEightCharsMet}
      </li> */}
      <li
        className={[
          'text-sm text-default-color mb-1',
          minThreeConditionsMetTextColor(touched, errors),
        ].join(' ')}
      >
        {PasswordErrorMsgs.minThreeConditionsMet}
      </li>
    </ul>
  </div>
)

export const validatePhone = (value: string) => {
  let error
  if (value.includes('_')) error = PasswordErrorMsgs.invalidPhoneNumber
  return error
}
export module ValidationHelper {
  const isNumber = (str: string): boolean => new RegExp(/\d/).test(str)
  const isChar = (str: string): boolean => new RegExp(/[a-zA-Z]/).test(str)

  export const validatePostalZipCode = async (value: MailingAddressProp) => {
    const postalZipCode: string = value.postalZipCode || ''
    let error
    const zipValidation = new RegExp('^\\d{5}(-{0,1}\\d{4})?$')
    const postalValidation = new RegExp(
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    )
    if (value) {
      if (isNumber(postalZipCode.charAt(0))) {
        if (!zipValidation.test(postalZipCode)) {
          error = PasswordErrorMsgs.invalidZipCode
        }
      } else if (isChar(postalZipCode.charAt(0))) {
        if (!postalValidation.test(postalZipCode)) {
          error = PasswordErrorMsgs.invalidPostalCode
        }
      }
    } else {
      error = PasswordErrorMsgs.required
    }
    return error
  }
}
