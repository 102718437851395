import React, { useEffect } from 'react'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValuationsLayout } from '../../components/HomeValuationLayout'
import { SideContent } from '../../components/SideContent'
import { PropertyDetailsForm } from './PropertyDetailsForm'

export const PropertyDetails = () => {
  const { valuationInfoState, setValuationInfoState, propertyState } = useHomeValuationStore()

  useEffect(() => {
    setValuationInfoState((prev: any) => ({
      ...prev,
      type: propertyState?.type,
      bedroom: propertyState?.bedroom,
      bathroom: propertyState?.bathroom,
      parking: propertyState?.parking,
      footageFrom: propertyState?.footageFrom,
    }))
  }, [])

  return (
    <>
      <HomeValuationsLayout content={<SideContent />}>
        <div className="space-y-10">{valuationInfoState && <PropertyDetailsForm />}</div>
      </HomeValuationsLayout>
    </>
  )
}
