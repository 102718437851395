import UserTypes from 'services/user/UserTypes'
import React, { useContext } from 'react'
import { DateHelper } from 'utilities'
import { ArrowRightBold, HomeOwner, Key, Time } from 'views/shared/components/icons'
import Moment from 'moment'
import { TextBody } from 'views/shared/components/ui-form'
import { UserDataDetails } from 'types/User'
import { AuthContext } from 'views/authentication'
import { UserRole } from 'constants/UserConstants'
import { Item } from './userList.styled'

export type UserListItemProps = {
  aid?: string
  details?: UserDataDetails
  role?: UserTypes.UserRoleType[] | null
  selected?: boolean
  lastLoginAt?: number
  totalSessionDuration?: number
  averageSessionDuration?: number
  logins?: number
  followUp?: UserTypes.FollowUpData | null
  ownershipStats?: 'owner' | 'lessee' | string
  purchaseDate?: string | number
  isSignedUp?: boolean
  onClick?: (userId: string) => void
} & React.LiHTMLAttributes<HTMLLIElement>

export const UserListitem = (props: UserListItemProps) => {
  const {
    details,
    selected,
    role,
    lastLoginAt,
    followUp,
    averageSessionDuration,
    ownershipStats,
    purchaseDate,
    isSignedUp,
  } = props

  const today = new Date()
  const pastDate = today.setDate(today.getDate() - 1) as Date | number | string

  const { authUser } = useContext(AuthContext)
  const isAgent = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.AGENT) > -1

  return (
    <Item onClick={props.onClick} selected={selected}>
      <div className="flex items-center w-full">
        {/* FollowUp --------------- */}
        {isAgent && role === null && followUp && followUp.date && followUp.note && (
          <p className="text-xs font-semibold flex items-center flex-shrink-0">
            <ArrowRightBold
              style={{ fontSize: '12px' }}
              color={followUp.date < pastDate ? 'var(--warning-color)' : 'var(--accent-color)'}
            />
            <span
              className={[
                'pl-1 pr-5',
                followUp.date < pastDate ? 'text-warning-color' : 'text-accent-color',
              ].join(' ')}
            >
              {DateHelper.formatDateToFull(followUp.date)}
            </span>
          </p>
        )}
        <div className="grid sm:flex sm:flex-row items-center justify-end w-full">
          {purchaseDate && (
            <p className="flex items-center space-x-1 mr-1 px-4 py-1 h-6 mt-0.5 sm:mt-0 bg-white rounded-tl-lg sm:rounded-t-none rounded-bl-lg sm:rounded-b-lg">
              {ownershipStats === 'lessee' ? (
                <Key style={{ fontSize: '20px' }} color="var(--default-color)" />
              ) : (
                <HomeOwner style={{ fontSize: '20px' }} color="var(--accent-color)" />
              )}
              <span className="text-xs font-semibold text-default-color">
                {DateHelper.formatDateStr(purchaseDate)}
              </span>
            </p>
          )}
          {(role === null || (role && role[0] === 'agent')) && lastLoginAt && (
            <p
              className={[
                'py-1 px-4 mt-0.5 sm:mt-0 rounded-tl-lg sm:rounded-t-none rounded-bl-lg text-xs h-6',
                lastLoginAt > pastDate
                  ? 'bg-accent-color text-on-accent-color'
                  : ' bg-white text-default-color',
              ].join(' ')}
            >
              <span>Last login </span>
              <span className="font-semibold">
                {lastLoginAt && DateHelper.formatDateToFull(lastLoginAt)}
              </span>
            </p>
          )}
        </div>
      </div>
      {!isSignedUp && (
        <div className="flex items-end justify-end">
          <span className="bg-secondary-color rounded-bl-lg px-4 py-1 text-on-secondary-color font-semibold text-xs">
            Unregistered user
          </span>
        </div>
      )}
      <div className="flex items-center justify-between">
        {/* className={role && role[0] === 'admin' ? 'pt-4' : 'pt-2'} */}
        <div className="pt-2">
          {role && role[0] === 'agent' && (
            <p className="text-xs text-accent-color font-semibold">SAGE agent</p>
          )}
          {role && role[0] === 'admin' && (
            <p className="text-xs text-warning-color font-semibold">Admin</p>
          )}
          <p className="text-xl font-semibold text-secondary-color">
            {`${details?.firstName} ${details?.lastName}`.trim()}
          </p>
        </div>
        {role === null && isSignedUp && (
          <p className="flex items-center flex-shrink-0 text-default-color text-xs italic px-4 pt-2">
            <Time style={{ fontStyle: '12px' }} />
            {Moment.duration(averageSessionDuration || 0)
              .locale('en')
              .humanize(false)}
          </p>
        )}
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-10 text-default-color text-base">
        {details?.phone && <TextBody>{details?.phone}</TextBody>}
        <TextBody className="whitespace-nowrap overflow-x-hidden overflow-ellipsis pr-5">
          {details?.email}
        </TextBody>
      </div>
    </Item>
  )
}
