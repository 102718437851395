import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { InputField, PrimaryButton } from 'views/shared/components/ui-form'
import { PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'

import { AuthException, AuthServices } from 'services/auth'
import { paths } from '../../paths'
import { AuthLayout } from '../../components'

export const SignUpVerification = () => {
  const history = useHistory()
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null)
  const [email] = useState<string | null>(AuthServices.getLocalStorageEmail())

  const onSubmit = async (
    verificationCode: string,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      if (!email) {
        history.push(paths.signIn())
      } else {
        setSubmitting(true)
        await AuthServices.signUpConfirm(email, verificationCode)
        history.push(paths.signIn())
      }
    } catch (error) {
      setResponseErrors([(error as AuthException).message])
    } finally {
      setSubmitting(false)
    }
  }
  const resend = async () => {
    try {
      if (email) {
        await AuthServices.signUpResendCode(email)
        setResponseErrors(['The confirmation code is sent. Please check your email inbox'])
      }
    } catch (error) {
      setResponseErrors([(error as AuthException).message])
    }
  }

  const formik = useFormik({
    initialValues: { code: '' },
    onSubmit: (values, { setSubmitting }) => onSubmit(values.code, setSubmitting),
    validationSchema: Yup.object().shape({
      code: Yup.string()
        .required(PasswordErrorMsgs.required)
        .min(4, PasswordErrorMsgs.minThreeCharsMet),
    }),
  })
  return (
    <>
      <AuthLayout>
        <div>
          <h2 className="text-3xl text-secondary-color font-semibold uppercase tracking-wider">
            Verify it&#39;s you
          </h2>
          <p className="pt-8 pb-5 text-sm text-secondary-color">
            We&#39;ve sent a verification code to <br />
            <strong>{email}</strong>.
          </p>
          <p className="text-sm text-secondary-color">
            Enter the code from the email in the field below:
          </p>
        </div>
        <div className="mt-8">
          <form method="POST" className="space-y-6" onSubmit={formik.handleSubmit}>
            <div>
              <InputField name="code" label="Verification Code" type="text" formik={formik} />
            </div>
            {responseErrors && (
              <div className="p-2">
                {responseErrors.map((message) => (
                  <div key={message} className="mt-1 text-warning-color text-sm">
                    {message}
                  </div>
                ))}
              </div>
            )}
            <div className="text-sm">
              <button
                type="button"
                onClick={() => resend()}
                className="font-bold text-accent-color cursor-pointer"
              >
                Resend code
              </button>
            </div>
            <div>
              <PrimaryButton disabled={formik.isSubmitting} type="submit">
                Confirm
              </PrimaryButton>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}
