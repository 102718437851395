import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Neighbourhood } from 'types'
import { neighbourhoodsState, communitiesState } from '../state'

export const useNeighbourhoods = () => {
  const [isLoading] = useState(false)
  const neighbourhoods = useRecoilValue<Neighbourhood[]>(neighbourhoodsState)
  // const neighbourhoodSales = useRecoilValue<MonthlyNeighbourhoodSales>(neighbourhoodsSalesState)
  const communities = useRecoilValue(communitiesState)

  return {
    isLoading,
    neighbourhoods,
    // neighbourhoodSales,
    communities,
  }
}
