import { API } from 'aws-amplify'
import {
  AdminUserAnalytics,
  AdminWebAnalytics,
  GetEmailAnalyticsResponse,
} from 'views/dashboard/types/analytics'

export module AnalyticsServices {
  const apiName = 'user'
  export const getWebAnalytics = async (month: string) => {
    const responseData = (
      await API.get(apiName, `/admin/webanalytics`, {
        response: true,
        queryStringParameters: { time: month },
      })
    ).data as AdminWebAnalytics
    return responseData
  }
  export const getUsersAnalytics = async () => {
    const responseData = (
      await API.get(apiName, `/admin/useranalytics`, {
        response: true,
      })
    ).data as AdminUserAnalytics
    return responseData
  }
  export const getEmailAnalytics = async (month: string, isAgent: boolean) => {
    const endpoint = isAgent ? `/analytics/agentemail` : `/analytics/email`
    const responseData = (
      await API.get(apiName, endpoint, {
        response: true,
        queryStringParameters: { time: month },
      })
    ).data as GetEmailAnalyticsResponse[]
    return responseData
  }
}
