import React, { useContext } from 'react'
import { links } from 'links'
import { A, RouteLink } from 'views/shared/components/ui-form'
import { paths } from 'views/footer/paths'
import { AuthContext } from 'views/authentication'
import { Facebook, Instagram } from '../icons'
import { Feedback } from '../feedback/Feedback'

export const PageFooter = () => {
  const { isLoggedIn } = useContext(AuthContext)
  return (
    <>
      {isLoggedIn ? (
        <>
          <div>
            <Feedback />
          </div>
          <div className="border-t border-alpha-darker-color box-border pb-3 px-5 sm:px-10 fixed bottom-0 left-0 right-0 w-full z-10 bg-white h-6">
            <div className="xl:flex xl:flex-row xl:justify-between xl:items-center">
              <p className="text-sm text-default-color">Your Home. Your Castl.</p>
              <div className="hidden xl:flex xl:items-center space-x-5 xl:space-x-10">
                <div>
                  <A fontSize={14} href={links.sage} target="_blank">
                    SAGE Real Estate Ltd. Brokerage
                  </A>
                </div>
                <div>
                  <RouteLink fontSize={14} fontWeight={600} to={paths.about()}>
                    About
                  </RouteLink>
                </div>
                <div>
                  <RouteLink fontSize={14} fontWeight={600} to={paths.privacy()}>
                    Privacy Policy
                  </RouteLink>
                </div>
                <div>
                  <A href={links.facebook} target="_blank">
                    <Facebook style={{ fontSize: '20px' }} color="var(--primary-color)" />
                  </A>
                </div>
                <div>
                  <A href={links.instagram} target="_blank">
                    <Instagram style={{ fontSize: '20px' }} color="var(--primary-color)" />
                  </A>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-between items-center bg-primary-color space-x-3 px-5 sm:px-10 py-2">
          <p className="text-sm text-white">Your Home. Your Castl.</p>
          <RouteLink color="white" fontSize={14} fontWeight={600} to={paths.privacy()}>
            Privacy Policy
          </RouteLink>
        </div>
      )}
    </>
  )
}
