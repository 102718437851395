import { Property } from 'types/NeighbourhoodReport'

export const MockSoldReportDetails = (length: number): Property[] => {
  const listOfDetails = new Array(length)

  const createRandomDate = (start: Date, end: Date): string => {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
    return date.toISOString().split('T')[0].toString()
  }

  const createRandomRoundedNumber = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min) + min)

  const mockAddresses = [
    '6 Mayfair Ave.',
    '664 Beresford Ave.',
    '20 Saint Patrick St.',
    '650 Queens Quay W',
    '115 Blue Jays Way',
    '78 Larkfield Dr.',
    '75 Walmer Rd.',
    '17 Farmstead Rd.',
    '2096 Gerrard St.',
    '243 Glebemount Ave.',
    '280 Wellesley St',
  ]

  for (let i = 0; i < length; i += 1) {
    listOfDetails[i] = {
      address: mockAddresses[createRandomRoundedNumber(0, 11)],
      bath: createRandomRoundedNumber(0, 9),
      bed: createRandomRoundedNumber(0, 9),
      dom: createRandomRoundedNumber(1, 99),
      footage: createRandomRoundedNumber(200, 5000),
      id: '',
      listingDate: createRandomDate(new Date(2000, 1, 1), new Date()),
      listingPrice: createRandomRoundedNumber(100000, 10000000),
      lot: `${Math.round((Math.random() * 10 + 10) * 100) / 100}x100.00`,
      mls: '',
      neighbourhoodId: '',
      soldDate: createRandomDate(new Date(2000, 1, 1), new Date()),
      soldPrice: createRandomRoundedNumber(100000, 10000000),
    }
  }
  return listOfDetails
}
