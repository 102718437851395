import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Analytics } from 'aws-amplify'
import { paths } from 'paths'
import { links } from 'links'
import { AuthContext } from 'views/authentication'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { Facebook, HamburgerMenu, Instagram } from 'views/shared/components/icons'
import { dashboardPaths } from 'views/dashboard/paths'
import { AgentServices } from 'services/agents'
import { AgentProfile } from 'views/agents/types/agents'
import { AuthServices } from 'services/auth'

import { UserRole } from 'constants/UserConstants'
import { useFiltersStore, useHomeSearchStore } from 'store/homeSearch'
import { Link as ScrollLink } from 'react-scroll'

import {
  Content,
  Root,
  MainNavigation,
  AuthMenu,
  ComingSoon,
  ProfileMenu,
  MenuModal,
  MenuList,
  MobileMenu,
  MenuListMain,
  ComingSoonAbsolute,
  MenuSocial,
  MenuSocialIcons,
  MenuFooter,
  MenuAgent,
} from './AppHeader.styled'

type MenuType = 'profile' | 'hamburger' | null

export const AppHeader = () => {
  const { filtersState } = useFiltersStore()

  const param = useParams<{ saleType: string; viewType: string }>()
  const searchType = filtersState.saleType === 'sale' ? 'sale' : 'lease'
  const urlPath = window.location.pathname

  const location = useLocation()
  const viewType =
    location.pathname === `/home-search/gallery/${searchType}`
      ? (param.viewType = 'gallery')
      : (param.viewType = 'map')

  const navigationItems: {
    label: string
    to?: string
  }[] = [
    { label: ' Home Search', to: paths.homeSearch(viewType, searchType) },
    { label: ' Neighbourhood Sold Reports', to: paths.portal() },
    { label: 'Home Valuations', to: paths.homeValuation() },
    // { label: 'Valuations Annual Report', to: paths.valuationAnnualReport() },
  ]

  const { signOut, profileImage, isLoggedIn, authUser } = useContext(AuthContext)
  const isAgent = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.AGENT) > -1
  const isAdmin = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.ADMIN) > -1
  const [showMenu, setShowMenu] = useState<MenuType>(null)
  const history = useHistory()
  const [agent, setAgent] = useState<AgentProfile | null>(null)

  useEffect(() => {
    if (authUser) {
      const fetchAgentProfile = async () => {
        await AgentServices.getMyAgentProfile()
          .then((a) => {
            setAgent(a)
            return a
          })
          .then(() => AuthServices.getIdentity())
          .then(async (identity) => {
            Analytics.record({ attributes: identity, name: 'identity' })
            return identity
          })
      }
      fetchAgentProfile()
    }
  }, [authUser])

  const openMenu = useCallback(
    (menuType: MenuType) => () => {
      setShowMenu(menuType)
    },
    [],
  )
  const closeMenu = useCallback(() => {
    setShowMenu(null)
  }, [])
  const handleSignOut = () => {
    closeMenu()
    signOut()
    history.push(paths.landingPage())
  }

  const activeLabel = (label: string) => {
    const path = window.location.pathname
    if (label === ' Home Search') {
      if (path.includes('/home-search') || path.includes('/property-details')) {
        return true
      }
    } else if (label === ' Neighbourhood Sold Reports') {
      if (path.includes('/neighbourhood') || path.includes('/sold-report')) {
        return true
      }
    } else if (label === 'Home Valuations') {
      if (path.includes('/home-valuation') || path.includes('/property-valuation')) {
        return true
      }
    }
    return false
  }

  return (
    <Root>
      <Content>
        <MobileMenu className={showMenu === 'hamburger' ? '--show' : ''}>
          <button onClick={openMenu('hamburger')} type="button">
            <HamburgerMenu height="56px" color="var(--primary-color)" />
          </button>
          <ModalLayer
            outsideColor="rgba(0,0,0,0.2)"
            onOutsideClick={closeMenu}
            show={showMenu === 'hamburger'}
            responsive={{
              small: 'pinned',
            }}
          >
            <MenuModal onClick={closeMenu} alignment="left">
              {isLoggedIn ? (
                <MenuListMain className="text-right">
                  {navigationItems.map((item) => (
                    <li key={item.label}>
                      {item.to === undefined ? (
                        <span>
                          {item.label}
                          <ComingSoon> Coming soon</ComingSoon>
                        </span>
                      ) : (
                        <NavLink onClick={closeMenu} activeClassName="--active" to={item.to}>
                          {item.label}
                        </NavLink>
                      )}
                    </li>
                  ))}
                  {isLoggedIn && isAdmin && (
                    <li>
                      <NavLink
                        onClick={closeMenu}
                        activeClassName="--active"
                        to={dashboardPaths.main()}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  )}
                  {isLoggedIn && isAgent && (
                    <li>
                      <NavLink
                        onClick={closeMenu}
                        activeClassName="--active"
                        to={dashboardPaths.emailAnalytics()}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  )}
                </MenuListMain>
              ) : (
                <MenuListMain>
                  <li>
                    {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                      <NavLink className="--primary" to={paths.landingPage()}>
                        Home Valuation
                      </NavLink>
                    ) : (
                      <ScrollLink className="cursor-pointer" smooth spy to="homevaluation">
                        Home Valuation
                      </ScrollLink>
                    )}
                  </li>
                  <li>
                    {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                      <NavLink className="--primary" to={paths.landingPage()}>
                        Neighbourhood Sold Report
                      </NavLink>
                    ) : (
                      <ScrollLink className="cursor-pointer" smooth spy to="soldreport">
                        Neighbourhood Sold Report
                      </ScrollLink>
                    )}
                  </li>
                  <li>
                    {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                      <NavLink className="--primary" to={paths.landingPage()}>
                        Home Search
                      </NavLink>
                    ) : (
                      <ScrollLink className="cursor-pointer" smooth spy to="homesearch">
                        Home Search
                      </ScrollLink>
                    )}
                  </li>
                </MenuListMain>
              )}

              {isLoggedIn && !(isAdmin || isAgent) && agent && (
                <MenuAgent className="block">
                  <p className="text-xs text-default-color">Your Agent</p>
                  <p className="font-base flex flex-col">
                    <span className="font-semibold pr-5">
                      {`${agent?.firstName || ''} ${agent?.lastName || ''}`.trim()}
                    </span>
                    <span className="text-default-color">
                      {agent?.email}
                      <br />
                      {agent?.phone && agent?.phone}
                    </span>
                  </p>
                </MenuAgent>
              )}
              {isLoggedIn && (
                <MenuSocial>
                  <MenuSocialIcons>
                    <a href={links.facebook} target="_blank" rel="noreferrer">
                      <Facebook color="var(--primary-color)" />
                    </a>
                    <a href={links.instagram} target="_blank" rel="noreferrer">
                      <Instagram color="var(--primary-color)" />
                    </a>
                  </MenuSocialIcons>
                  <MenuFooter className="space-y-3">
                    <a href={links.sage} target="_blank" rel="noreferrer">
                      SAGE Real Estate Ltd. Brokerage
                    </a>
                    <div className="flex space-x-5">
                      <NavLink to={paths.about()}>About</NavLink>
                      <NavLink to={paths.privacy()}>Privacy Policy</NavLink>
                    </div>
                  </MenuFooter>
                </MenuSocial>
              )}
            </MenuModal>
          </ModalLayer>
        </MobileMenu>
        <NavLink
          className="flex items-start space-x-1"
          activeClassName="--active"
          to={paths.landingPage()}
        >
          <img className="w-full h-9" alt="" src="/assets/castl_beta.svg" />
        </NavLink>
        <MainNavigation className="hidden xl:flex">
          {isLoggedIn ? (
            <ul>
              {navigationItems.map((item) => (
                <li key={item.label}>
                  {item.to === undefined ? (
                    <span>
                      {item.label}
                      <ComingSoonAbsolute>Coming soon</ComingSoonAbsolute>
                    </span>
                  ) : (
                    <NavLink
                      onClick={closeMenu}
                      activeClassName="--active"
                      to={item.to}
                      isActive={() => activeLabel(item.label)}
                    >
                      {item.label}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <li>
                {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                  <NavLink
                    className="--primary"
                    to={paths.landingPage()}
                    isActive={() => window.location.pathname.includes('/home-valuation')}
                  >
                    Home Valuation
                  </NavLink>
                ) : (
                  <ScrollLink
                    className="cursor-pointer"
                    smooth
                    spy
                    to="homevaluation"
                    activeClass="--active"
                    // isActive={() => window.location.pathname.includes('/home-valuation')}
                  >
                    Home Valuation
                  </ScrollLink>
                )}
              </li>
              <li>
                {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                  <NavLink className="--primary" to={paths.landingPage()}>
                    Neighbourhood Sold Report
                  </NavLink>
                ) : (
                  <ScrollLink className="cursor-pointer" smooth spy to="soldreport">
                    Neighbourhood Sold Report
                  </ScrollLink>
                )}
              </li>
              <li>
                {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                  <NavLink
                    className="--primary"
                    to={paths.landingPage()}
                    isActive={() =>
                      window.location.pathname.includes('/home-search') ||
                      window.location.pathname.includes('/property-details')
                    }
                  >
                    Home Search
                  </NavLink>
                ) : (
                  <ScrollLink
                    className="cursor-pointer"
                    smooth
                    spy
                    to="homesearch"
                    activeClass="--active"
                    // isActive={() => window.location.pathname.includes('/home-search')}
                  >
                    Home Search
                  </ScrollLink>
                )}
              </li>
            </ul>
          )}
        </MainNavigation>
        {isLoggedIn && isAdmin && (
          <MainNavigation className="hidden xl:flex">
            <ul>
              <li>
                <NavLink
                  onClick={closeMenu}
                  activeClassName="--active"
                  to={dashboardPaths.main()}
                  isActive={() => window.location.pathname.includes('/dashboard')}
                >
                  Dashboard
                </NavLink>
              </li>
            </ul>
          </MainNavigation>
        )}
        {isLoggedIn && isAgent && (
          <MainNavigation className="hidden xl:flex">
            <ul>
              <li>
                <NavLink
                  onClick={closeMenu}
                  activeClassName="--active"
                  to={dashboardPaths.emailAnalytics()}
                  isActive={() => window.location.pathname.includes('/dashboard')}
                >
                  Dashboard
                </NavLink>
              </li>
            </ul>
          </MainNavigation>
        )}
        <AuthMenu>
          {!isLoggedIn && (
            <ul>
              <li>
                <NavLink className="--primary" to={paths.signIn()}>
                  Sign In
                </NavLink>
              </li>
              <li>
                {urlPath === '/sign-in' || urlPath === '/privacy' ? (
                  <NavLink className="--primary" to={paths.landingPage()}>
                    Get Started
                  </NavLink>
                ) : (
                  <ScrollLink className="cursor-pointer" smooth spy to="getstarted">
                    <span className="text-primary-color underline">Get Started</span>
                  </ScrollLink>
                )}
              </li>
            </ul>
          )}
          {isLoggedIn && (
            <ProfileMenu>
              <button
                className={showMenu === 'profile' ? '--active' : ''}
                type="button"
                onClick={openMenu('profile')}
              >
                <img src={profileImage} alt="Profile" />
              </button>
              <ModalLayer
                onOutsideClick={closeMenu}
                show={showMenu === 'profile'}
                responsive={{
                  small: 'pinned-right',
                }}
              >
                <MenuModal alignment="right">
                  <MenuList className="text-right">
                    <li>
                      {isLoggedIn && (isAdmin || isAgent) ? (
                        <Link onClick={closeMenu} to={dashboardPaths.myProfile()}>
                          Settings
                        </Link>
                      ) : (
                        <>
                          <Link onClick={closeMenu} to={paths.profile()}>
                            Settings
                          </Link>
                          {agent && (
                            <Link onClick={closeMenu} to={paths.agentDetails()}>
                              My Agent
                            </Link>
                          )}
                        </>
                      )}
                    </li>
                    <li>
                      <button type="button" onClick={handleSignOut}>
                        Sign Out
                      </button>
                    </li>
                  </MenuList>
                </MenuModal>
              </ModalLayer>
            </ProfileMenu>
          )}
        </AuthMenu>
      </Content>
    </Root>
  )
}
