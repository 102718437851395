import React, { useEffect, useState } from 'react'
import { HomeValuationServices } from 'services/homeValuation'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValuationAnnualReport, KeyLabel } from 'types'
import { CurrencyHelper } from 'utilities/helpers/currencyHelper'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import { AddressField } from 'views/homeValuation/components/AddressField'
import { Spinner } from 'views/shared/components/icons'
import { PrimaryButton, SecondaryButton, TextBody } from 'views/shared/components/ui-form/styled'
import { ComparablePropertyItem } from './ComparablePropertyItem'

interface EditAnnualReportFormProps {
  report: HomeValuationAnnualReport.Valuation
  closeCallback: (report: HomeValuationAnnualReport.Valuation | null) => void
}

export const EditAnnualReportForm = ({ report, closeCallback }: EditAnnualReportFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<KeyLabel | null>(null)
  const { annualReportState, setAnnualReportState } = useHomeValuationStore()
  const [printRequested, setPrintRequested] = useState(false)

  const handleAddListing = () => {
    annualReportState?.id &&
      selectedOption?.key &&
      HomeValuationServices.addSimilarSoldToAnnualReport(
        annualReportState?.id,
        selectedOption?.key,
      ).then((res) => {
        try {
          setIsLoading(true)
          setAnnualReportState(res)
          !isUpdated && setIsUpdated(true)
          setSelectedOption(null)
        } catch (error) {
          console.error(error)
        }
        setIsLoading(false)
      })
  }

  const handleRemoveListing = (mls: string) => {
    annualReportState?.id &&
      HomeValuationServices.removeSimilarSoldFromAnnualReport(annualReportState?.id, mls).then(
        (res) => {
          try {
            setIsLoading(true)
            setAnnualReportState(res)
            !isUpdated && setIsUpdated(true)
          } catch (error) {
            console.error(error)
          }
          setIsLoading(false)
        },
      )
  }

  useEffect(() => {
    setAnnualReportState(report)
  }, [report])

  return (
    <>
      <div className="flex">
        <div className="relative xl:py-12">
          {!printRequested ? (
            <button
              className="absolute text-2xl font-semibold text-primary-color right-3 top-5 xl:top-12"
              type="button"
              onClick={() => closeCallback(annualReportState)}
            >
              x
            </button>
          ) : (
            <button
              className="absolute text-2xl font-semibold text-primary-color right-3 top-5  xl:top-12"
              type="button"
              onClick={() => {
                setPrintRequested(false)
                closeCallback(annualReportState)
              }}
            >
              x
            </button>
          )}
          {annualReportState && !printRequested && (
            <div className="space-y-10 max-w-sm sm:max-w-6xl mt-5 mb-10 xl:mt-0 sm:w-full box-border bg-white rounded-lg border-2 border-primary-color md:border-none p-5 xl:p-10">
              <div className="flex flex-col space-y-5">
                <div className="text-6xl font-bold my-1">Annual Report</div>
                <div className="text-lg text-accent-color">
                  The annual report has been {isUpdated ? 'updated' : 'created'}.
                </div>
                <div className="text-3xl">
                  {annualReportState.propertyDetails.summary.unit ? (
                    `#${annualReportState.propertyDetails.summary.unit}`
                  ) : (
                    <></>
                  )}
                  {UnescapeHelper.unescape(annualReportState.homeValue.displayAddress)}
                </div>
                <div className="sm:flex sm:space-x-10">
                  <div className="flex items-center space-x-3">
                    <div className="text-default-color whitespace-nowrap">Last Sold</div>
                    <div className="text-xl font-bold">
                      {annualReportState.homeValue.lastListing?.soldPrice
                        ? `$${CurrencyHelper.currencyFormat(
                            annualReportState.homeValue.lastListing?.soldPrice,
                          )}`
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="text-default-color">Valuation</div>
                    <div className="text-xl font-bold">
                      {annualReportState.homeValue.confirmedValue ? (
                        <span className="text-accent-color">
                          $
                          {CurrencyHelper.currencyFormat(
                            annualReportState.homeValue.confirmedValue!,
                          )}
                        </span>
                      ) : (
                        <span>
                          $
                          {CurrencyHelper.currencyFormat(annualReportState.homeValue.instanceValue)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="lg:flex lg:items-baseline lg:space-x-2 text-default-color">
                  <div className="text-lg lg:text-3xl">Included Comparable Sold Properties</div>
                  <div className="text-default-color">(up to 5)</div>
                </div>
                <div className="flex flex-col space-y-5 w-full">
                  {annualReportState && !isLoading ? (
                    annualReportState.similarSolds.map((property) => (
                      <ComparablePropertyItem
                        key={property.summary.mls}
                        property={property}
                        handleRemoveListing={handleRemoveListing}
                      />
                    ))
                  ) : (
                    <div className="flex w-full justify-center items-center">
                      <Spinner style={{ fontSize: '128px' }} color="#018363" />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-end w-full border-2 border-dashed border-gray-300 rounded-lg  py-3 pr-2">
                <div className="flex-1">
                  <AddressField
                    searchBy="mls"
                    placeholder="Search by MLS or Address"
                    displayAddress={selectedOption?.label || ''}
                    onSelectChanged={setSelectedOption}
                  />
                </div>
                <div className="my-2 w-full sm:w-32">
                  <SecondaryButton
                    color="rgb(229 231 235)"
                    fontColor="rgb(107 114 128)"
                    onClick={handleAddListing}
                    disabled={annualReportState?.similarSolds.length === 5}
                  >
                    Add
                  </SecondaryButton>
                </div>
              </div>
            </div>
          )}
          {printRequested && (
            <div className="shadow-md space-y-10 max-w-xs min-w-max bg-white w-full rounded-lg border-2 border-primary-color md:border-none md:p-5 xl:p-12">
              <p className="font-semibold text-4xl">Thanks!</p>
              <TextBody>
                Your request has been sent.
                <br />A member of the team will contact you shortly.
              </TextBody>
              <div>
                <PrimaryButton
                  onClick={() => {
                    setPrintRequested(false)
                    closeCallback(annualReportState)
                  }}
                >
                  Back to Dashboard
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
