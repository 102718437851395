/* eslint-disable */
import { PropertyServices } from 'services/property'
import { HomeValuationAnnualReport, KeyLabel } from 'types'
import { CurrencyHelper, PdfHelper } from 'utilities'
import { footer } from '../footer'

export const PropertyEventLabel: KeyLabel[] = [
  {
    key: 'forSale',
    label: 'For Sale',
  },
  {
    key: 'sold',
    label: 'Sold',
  },
  {
    key: 'forLease',
    label: 'For Lease',
  },
  {
    key: 'leased',
    label: 'Leased',
  },
  {
    key: 'terminated',
    label: 'Terminated',
  },
  {
    key: 'unavailable',
    label: 'Expired',
  },
  {
    key: 'suspended',
    label: 'Suspended',
  },
]
export const PropertyType: KeyLabel[] = [
  {
    key: 'detatched',
    label: 'Detached',
  },
  {
    key: 'semiDetatched',
    label: 'Semi-Detached',
  },
  {
    key: 'condoTownhouse',
    label: 'Condo Townhouse',
  },
  {
    key: 'freeholdTownhouse',
    label: 'Townhouse',
  },
  {
    key: 'condo',
    label: 'Condominium',
  },
  {
    key: 'multi',
    label: 'Multi-Residential',
  },
]

export const addPropertyDescription = async (
  doc: PDFKit.PDFDocument,
  propertyDetails:
    | HomeValuationAnnualReport.PropertyDetails
    | HomeValuationAnnualReport.SimilarSold,
) => {
  doc.fontSize(8).text(propertyDetails.description || '', 28, 240, {
    align: 'justify',
    width: 563 - 5,
    lineGap: 3,
  })
  return ``
}

export const addDetailsBlocks = async (
  propertyDetails:
    | HomeValuationAnnualReport.PropertyDetails
    | HomeValuationAnnualReport.SimilarSold
    | any,
) => {
  type DetailLabel = [label: string, value?: string | number | string[]]
  const items: DetailLabel[] = [
    [
      'Rooms',
      `${propertyDetails?.summary.bedroom} bd ${propertyDetails?.summary.bathroom} br ${propertyDetails?.summary.parking} pk`,
    ],
    [
      'Interior Size',
      `${propertyDetails?.summary.footageFrom || '0'}-${
        propertyDetails?.summary.footageTo || '0'
      }sqft`,
    ],
    [
      'Lot Size',
      `${propertyDetails?.summary.landFront || '0'} x ${
        propertyDetails?.summary.landDepth || '0'
      }ft`,
    ],
    ['Style', propertyDetails?.style],
    [
      'Taxes',
      `${propertyDetails?.summary.tax || '0'} / ${propertyDetails?.summary.taxYear || '0'}`,
    ],
    ['Days on Market', `${propertyDetails?.summary.dom || '0'}`],
    ['Basement', `${propertyDetails?.basement || ''}`],
    ['Building Age', `${propertyDetails?.buildingAge || ''}`],
    ['Heating', `${propertyDetails?.heating || ''}`],
    ['Cooling', `${propertyDetails?.cooling || ''}`],
    ['Pool', `${propertyDetails?.poolType || ''}`],
    ['Construction', `${propertyDetails?.exteriorFinish || ''}`],
    ['Features', propertyDetails?.features],
    ['Amenities', propertyDetails?.amenities],
  ].filter((item) => item[1] !== undefined && item[1].length > 0) as DetailLabel[]
  const r = PdfHelper.pageList(items, { columns: 5, w: 100, x: 0, h: 28 }, (item, x, y, i) => {
    const [label, value] = item
    const valueItems: (string | number | undefined)[] = Array.isArray(value)
      ? (value as (string | number)[])
      : [value]
    return `
    <svg x="${x}" y="${y}">
      <text fill="#28292E" style="white-space: normal" font-family="Montserrat" font-size="8" font-weight="bold" letter-spacing="0em">
        <tspan x="0" y="10">${label}</tspan>
      </text>
      <text fill="#28292E" style="white-space: normal" font-family="Montserrat" font-size="8" letter-spacing="0em">
        ${valueItems.reduce((lv, v, i) => {
          const itemY = 20 + i * 10
          return `${lv}<tspan x="0" y="${itemY}">${v}</tspan>`
        }, ``)}
      </text>
    </svg>
    `
  })
  return r[0].join('')
}

export const addPropertyDetails = async (
  doc: PDFKit.PDFDocument,
  propertyDetails:
    | HomeValuationAnnualReport.PropertyDetails
    | HomeValuationAnnualReport.SimilarSold,
  data: HomeValuationAnnualReport.Valuation,
) => {
  const pData = {
    summary: propertyDetails?.summary,
    history: propertyDetails?.histories,
    details: propertyDetails,
    homeValue: propertyDetails,
  }
  const marketValue = propertyDetails.summary.listingPrice || 0

  let imagesPaths = [] as string[]
  if (propertyDetails)
    imagesPaths = await PropertyServices.getPropertyImagePaths(
      propertyDetails.summary.mls,
      propertyDetails.summary.numberOfPic,
    )
  const mainImagePath = imagesPaths.length > 0 ? imagesPaths[0] : '../assets/default-home.svg'
  const img = {
    main: await PdfHelper.getImage(mainImagePath),
  }
  const detailsBlocks = await addDetailsBlocks(propertyDetails)
  const footerRender = await footer(data)
  doc.addPage()
  const propertyDescription = await addPropertyDescription(doc, propertyDetails)
  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">
  ${img.main(0, 0, 272, 152)}

<svg x="286" y="35" >
<text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="0" y="7">Listed for</tspan></text>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="bold" letter-spacing="0em"><tspan x="0" y="25">$${
    pData.summary?.listingPrice && CurrencyHelper.currencyFormat(pData.summary?.listingPrice)
  }</tspan></text>
<text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="164" y="7">Market Valuation</tspan></text>
<text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="bold" letter-spacing="0em"><tspan x="163" y="25">$${CurrencyHelper.currencyFormat(
    marketValue,
  )}</tspan></text>
</svg>

<svg x="286" y="80" >
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="600" letter-spacing="0em"><tspan x="0" y="10">${
    PropertyType.find((event) => event.key === pData.summary?.type)?.label
  }</tspan></text>
<text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="500" letter-spacing="0em"><tspan x="104" y="9">${
    pData.summary?.footage
  } sqft</tspan></text>
</svg>

<svg x="284" y="110" >
<text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="500" letter-spacing="0em"><tspan x="4.19688" y="9.7265">${
    pData.summary?.bedroom || 0
  }</tspan></text>
<text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="500" letter-spacing="0em"><tspan x="39.7887" y="9.7265">${
    pData.summary?.bathroom || 0
  }</tspan></text>
<text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="500" letter-spacing="0em"><tspan x="71.2559" y="9.7265">${
    pData.summary?.parking || 0
  }</tspan></text>
<path d="M60.2609 6.61928C57.3163 6.61928 54.3707 6.61928 51.4183 6.61928C51.4183 5.49356 51.4183 4.36901 51.4183 3.24329C51.4183 2.94565 51.6026 2.71981 51.8746 2.67233C52.1308 2.62716 52.3747 2.78467 52.4691 3.05683C52.4994 3.14485 52.5253 3.23403 52.5601 3.32089C52.586 3.38459 52.5725 3.42165 52.523 3.47029C52.1892 3.79804 52.0859 4.19529 52.205 4.65275C52.2544 4.84269 52.4095 4.93881 52.5905 4.88554C53.1097 4.73382 53.6267 4.57863 54.1437 4.41881C54.3201 4.36437 54.3988 4.20108 54.3504 4.01693C54.2234 3.53746 53.8042 3.19118 53.321 3.16801C53.1434 3.15991 53.1434 3.15991 53.0838 2.97924C53.0625 2.91438 53.04 2.84837 53.0153 2.78467C52.9141 2.52409 52.7467 2.32025 52.5051 2.19286C52.3781 2.12684 52.2364 2.0921 52.1016 2.04346C52.0072 2.04346 51.9139 2.04346 51.8195 2.04346C51.8105 2.04809 51.8026 2.05504 51.7925 2.05735C51.162 2.19517 50.8136 2.63411 50.8136 3.29425C50.8136 4.40259 50.8136 5.51094 50.8136 6.61928C50.2247 6.61928 49.6347 6.61928 49.0447 6.61928C49.0447 6.84512 49.0413 7.06516 49.0458 7.28521C49.0694 8.51632 49.5987 9.43472 50.6462 10.0323C50.7046 10.0659 50.7327 10.0983 50.7305 10.1701C50.7249 10.3392 50.7372 10.5083 50.7271 10.6763C50.7114 10.9449 50.8058 11.1372 51.0541 11.2391C51.1216 11.2391 51.189 11.2391 51.2564 11.2391C51.5037 11.1337 51.5947 10.938 51.5756 10.6716C51.5689 10.5697 51.5745 10.4655 51.5745 10.3612C53.6334 10.3612 55.68 10.3612 57.7468 10.3612C57.7468 10.4597 57.7524 10.5662 57.7456 10.6716C57.7277 10.9368 57.8187 11.1337 58.0648 11.2391C58.1323 11.2391 58.1997 11.2391 58.2671 11.2391C58.5155 11.1372 58.6099 10.9449 58.5942 10.6763C58.584 10.5118 58.5975 10.3473 58.5908 10.1829C58.5874 10.1007 58.6189 10.0624 58.6852 10.0242C60.1024 9.21815 60.3699 7.92566 60.2609 6.61928Z" fill="#807B7B"/>
<path d="M26.6041 10.6074C26.5328 10.7821 26.4614 10.9555 26.3003 11.0724C26.0493 11.2545 25.7762 11.2901 25.4957 11.1659C25.219 11.0441 25.0566 10.8214 25.0418 10.5151C25.0283 10.2457 25.0344 9.97632 25.0332 9.70569C25.0332 9.65525 25.0332 9.60481 25.0332 9.54454C21.2517 9.54454 19.7251 9.54454 15.9423 9.54454C15.9423 9.71922 15.9436 9.88775 15.9423 10.0563C15.9399 10.2174 15.9448 10.3786 15.9276 10.5385C15.8845 10.9506 15.5598 11.2299 15.1329 11.2335C14.7023 11.2372 14.3653 10.9543 14.3124 10.5447C14.3075 10.5028 14.305 10.4598 14.305 10.4167C14.305 8.31069 14.3038 6.20466 14.305 4.09739C14.305 3.75295 14.4514 3.48969 14.7712 3.35069C15.0923 3.21168 15.4023 3.24981 15.6766 3.47616C15.8882 3.64962 15.9448 3.8895 15.9448 4.15029C15.9448 5.2439 15.9448 6.33751 15.9448 7.42989C15.9448 7.48648 15.9448 7.54184 15.9448 7.60827C19.7226 7.60827 26.6078 7.60827 26.6078 7.60827C26.6078 7.60827 26.6041 8.90362 26.6041 10.6074Z" fill="#807B7B"/>
<path d="M22.8955 5.00764C23.78 5.00764 24.6657 5.00518 25.5502 5.00887C26.0373 5.0101 26.4088 5.25859 26.5442 5.66331C26.7754 6.35097 26.308 7.01526 25.5625 7.01895C23.7837 7.02879 22.0036 7.03002 20.2248 7.01895C19.4953 7.01403 19.0193 6.34728 19.2419 5.683C19.3637 5.3201 19.718 5.04085 20.0981 5.01502C20.2396 5.00518 20.3823 5.00764 20.5238 5.00764C21.3148 5.00764 22.1045 5.00764 22.8955 5.00764Z" fill="#807B7B"/>
<path d="M18.7989 5.88613C18.8001 6.5049 18.2982 7.01296 17.6757 7.02034C17.0582 7.02895 16.5378 6.50859 16.5415 5.88244C16.544 5.26121 17.052 4.75438 17.672 4.75684C18.2908 4.75808 18.7976 5.2649 18.7989 5.88613Z" fill="#807B7B"/>
<path d="M93.9984 9.5152C93.8926 9.74757 93.7087 9.85198 93.442 9.87257C92.9302 9.91228 92.4213 9.9814 91.911 10.0314C91.8159 10.0402 91.7776 10.0755 91.747 10.1608C91.5125 10.8006 90.8933 11.2285 90.2021 11.2388C89.494 11.2506 88.8672 10.8491 88.6082 10.2094C88.5714 10.1196 88.5316 10.0873 88.4274 10.0888C86.8718 10.0932 85.3146 10.0917 83.759 10.0888C83.664 10.0888 83.6226 10.1167 83.5889 10.202C83.3345 10.8329 82.7214 11.2388 82.0302 11.2388C81.3375 11.2403 80.7199 10.8388 80.4685 10.2064C80.4256 10.1005 80.3674 10.0844 80.2708 10.0888C79.7068 10.1138 79.2363 9.93287 78.9221 9.47108C78.8087 9.30342 78.7551 9.099 78.6738 8.91075C78.6738 7.95334 78.6738 6.99592 78.6738 6.03851C78.7918 5.7782 78.9972 5.66055 79.2915 5.69584C79.4171 5.71055 79.5045 5.6576 79.5995 5.58701C80.772 4.70755 82.0977 4.18987 83.592 4.11486C84.2633 4.08104 84.9376 4.08839 85.6104 4.08692C86.9606 4.08545 88.1714 4.48106 89.2182 5.30023C90.127 6.01057 91.1539 6.3753 92.3263 6.38853C92.8658 6.39442 93.3026 6.62384 93.6413 7.02828C93.8359 7.26212 93.9264 7.53713 93.9999 7.8195C93.9984 8.38572 93.9984 8.95046 93.9984 9.5152ZM89.117 6.40765C88.0028 5.40906 86.7047 4.89285 85.1506 5.0208C85.1506 5.47083 85.1506 5.93262 85.1506 6.40765C86.4763 6.40765 87.7974 6.40765 89.117 6.40765ZM82.2647 5.26787C82.2647 5.45465 82.2601 5.63554 82.2693 5.81644C82.2709 5.85468 82.3168 5.90615 82.3552 5.9238C82.8241 6.13705 83.6548 6.34 84.1698 6.37236C84.1728 6.35471 84.1805 6.33559 84.1805 6.31794C84.1805 5.8885 84.1805 5.45759 84.1805 5.00609C83.5199 5.00315 82.8885 5.0811 82.2647 5.26787Z" fill="#807B7B"/>
</svg>
  <svg x="25" y="175">
    <text fill="black" xml:space="preserve" style="white-space: normal" font-family="Montserrat" font-size="17" font-weight="bold" letter-spacing="0em">
      <tspan x="0" y="26.0945">${pData.summary?.unit ? '#' + pData.summary?.unit : ''} ${
    pData.summary?.address
  }</tspan>
    </text>
    <text fill="black" xml:space="preserve" style="white-space: normal" font-family="Montserrat" font-size="13" letter-spacing="0em">
      <tspan x="0" y="44.0945">${pData.summary?.area}, ${pData.summary?.county}, ${
    pData.details.postalCode
  } </tspan>
    </text>
   
  ${propertyDescription}

  <svg x="0" y="162">
    ${detailsBlocks}
  </svg>

  ${footerRender}
</svg>
  `

  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
