import { HomeValuationAnnualReport } from 'types'
import { PdfHelper } from 'utilities'
import { urlHelper } from 'utilities/helpers/urlHelper'
import type { AnnualReportDocType } from '../config'

export const addCover = async (
  doc: AnnualReportDocType,
  data: HomeValuationAnnualReport.Valuation,
) => {
  const cData = {
    address: data.homeValue.displayAddress,
    agent: data.agent,
  }

  // `logos/${data.agent.logo || '/assets/castl.svg'}`
  const img = {
    logo: await PdfHelper.getImage(
      data.agent.logo ? `logos/${data.agent.logo}` : '/assets/castl.svg',
    ),
    headshot: await PdfHelper.getImage(
      data.agent.headshot ? `headshots/${data.agent.headshot}` : '/assets/default-profile.jpg',
    ),
  }

  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="none" xmlns="http://www.w3.org/2000/svg">
  <image xlink:href="" x="25" y="40" width="70" height="70" /> 
  ${img.logo(25, 40, 'auto', 70)}
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="27" font-weight="bold" letter-spacing="0em"><tspan x="25" y="200.68">Annual Report.</tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="25" letter-spacing="0em"><tspan x="25" y="241.962">${
    cData.address
  }</tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="25" y="631.51">Prepared with care by </tspan></text>
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="20" font-weight="bold" letter-spacing="0em"><tspan x="25" y="655.17">${
    cData.agent.firstName
  } ${cData.agent.lastName}</tspan></text>
  <text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="500" letter-spacing="0em"><tspan x="25" y="696.726">${
    cData.agent.email
  }</tspan></text>
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" letter-spacing="0.05em"><tspan x="25" y="681.726">${
    cData.agent.phone
  }</tspan></text>
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="9" font-weight="bold" letter-spacing="0em"><tspan x="25" y="711.726">${urlHelper.formateURL(
    cData.agent.website,
  )}</tspan></text>
  </svg>
  `

  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
