import React from 'react'

interface NeighbourhoodListSectionHeaderProps {
  title: string
}

export const NeighbourhoodListSectionHeader = ({ title }: NeighbourhoodListSectionHeaderProps) => (
  <div className="text-5xl uppercase font-semibold text-accent-color h-20 text-left py-5">
    {title}
  </div>
)
