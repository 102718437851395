import { Labs } from './pages'
import { Files } from './pages/files'
import { UiForm } from './pages/Form'
import { NewStateForm } from './pages/newStateForm'
import { StateForm } from './pages/StateForm'
import { paths } from './paths'

export const routes = {
  uiForm: {
    path: paths.uiForm(),
    exact: false,
    component: UiForm,
  },
  stateForm: {
    path: paths.stateForm(),
    exact: false,
    component: StateForm,
  },
  newStateForm: {
    path: paths.newStateForm(),
    exact: false,
    component: NewStateForm,
  },
  files: {
    path: paths.files(),
    exact: false,
    component: Files,
  },
  form: {
    path: paths.labs(),
    exact: false,
    component: Labs,
  },
}
