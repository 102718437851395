import { paths as agentPaths } from 'views/agents/paths'
import { paths as authenticationPaths } from 'views/authentication/paths'
import { paths as dashboardPaths } from 'views/dashboard/paths'
import { paths as followsPaths } from 'views/follows/paths'
import { paths as homeValuationPaths } from 'views/homeValuation/paths'
import { paths as mapPaths } from 'views/map/paths'
import { paths as soldReportPaths } from 'views/soldReport/paths'
import { paths as footerPaths } from 'views/footer/paths'
import { paths as unsubscribePaths } from 'views/unsubscribe/paths'
import { paths as propertyDetailsPaths } from 'views/propertyDetails/paths'
import { paths as landingPagePaths } from 'views/landing-page/paths'
import { paths as valuationAnnualReportPaths } from 'views/dashboard/pages/valuations/ValuationAnnualReport/paths'

export const paths = {
  ...agentPaths,
  ...authenticationPaths,
  ...dashboardPaths,
  ...followsPaths,
  ...mapPaths,
  ...homeValuationPaths,
  ...soldReportPaths,
  ...footerPaths,
  ...unsubscribePaths,
  ...propertyDetailsPaths,
  ...landingPagePaths,
  ...valuationAnnualReportPaths,
  homepage: () => '/',
}
