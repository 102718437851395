import React, { useEffect, useState } from 'react'
import {
  bathrooms,
  interiorSizeMax,
  interiorSizeMin,
  parking,
  useFiltersStore,
} from 'store/homeSearch'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { Checkbox, DropdownField } from 'views/shared/components/ui-form'
import { CapsuleButtonComponent } from '../SearchBar/CapsuleButton'
import { PropertyTypeField } from '../Shared/PropertyTypeField'
import { FormContainer } from './FilterView.styled'

export const HomeSearchFilterView = () => {
  // const { updateFilterAttribute, updateEnabledFiltersState } = useHomeSearchStore()
  const { filtersState, setFiltersState } = useFiltersStore()

  const [error, setError] = useState<string | null>(null)

  const validateSqft = (minsqft: number | undefined, maxsqft: number | undefined) => {
    const err =
      minsqft && maxsqft && minsqft > maxsqft
        ? 'Min. sqft should not be greater than Max. sqft'
        : null
    setError(err)
    return err
  }

  const handleActiveDaysChange = (days: string | undefined) => {
    setFiltersState((oldState) => ({
      ...oldState,
      showActive: days ? Number(days) : undefined,
    }))
  }
  const handleSoldDaysChange = (days: string | undefined) => {
    setFiltersState((oldState) => ({
      ...oldState,
      showSold: days ? Number(days) : undefined,
    }))
  }

  return (
    <>
      <FormContainer className="space-y-5">
        <div className="2xl:hidden w-full">
          <PropertyTypeField isExtendedFilter />
        </div>
        <div className="flex xl:hidden">
          <div className="w-32">
            <DropdownField
              value={
                filtersState.showActive?.toString() === undefined
                  ? 'All'
                  : filtersState.showActive?.toString()
              }
              onChange={handleActiveDaysChange}
              options={[
                { key: undefined, label: 'All' },
                { key: '0', label: 'Hide' },
                { key: '1', label: '1 d' },
                { key: '3', label: '3 d' },
                { key: '7', label: '7 d' },
                { key: '30', label: '30 d' },
                { key: '60', label: '60 d' },
              ]}
              name="Active"
              CustomButton={CapsuleButtonComponent}
            />
          </div>
          <div className="w-32">
            <DropdownField
              value={
                filtersState.showSold?.toString() === undefined
                  ? 'Hide'
                  : filtersState.showSold?.toString()
              }
              onChange={handleSoldDaysChange}
              options={[
                { key: undefined, label: 'Hide' },
                { key: '1', label: '1 d' },
                { key: '7', label: '7 d' },
                { key: '30', label: '30 d' },
                { key: '60', label: '60 d' },
                { key: '91', label: '3 m' },
                { key: '182', label: '6 m' },
                { key: '365', label: '1 y' },
              ]}
              name={filtersState.saleType === 'sale' ? 'Sold' : 'Leased'}
              CustomButton={CapsuleButtonComponent}
            />
          </div>
        </div>
        <InputViewField
          label="Bathroom (min.)"
          type="count"
          labelType="block"
          labelSize={14}
          options={bathrooms}
          edit
          onChange={(value) => {
            setFiltersState((prev) => ({ ...prev, bath: value }))
          }}
          value={filtersState.bath}
        />
        <InputViewField
          label="Parking (min.)"
          type="count"
          labelType="block"
          labelSize={14}
          options={parking}
          edit
          onChange={(value) => {
            setFiltersState((prev) => ({ ...prev, parking: value }))
          }}
          value={filtersState.parking}
        />
        <div className="px-2">
          <p className="text-secondary-color text-sm font-semibold">Lot Size (ft)</p>
          <div className="bg-alpha-color rounded-lg">
            <InputViewField
              label="Front"
              labelType="flex"
              labelSize={14}
              placeholder="Min."
              edit
              value={filtersState.minLotFront}
              onChange={(value) => {
                setFiltersState((prev) => ({ ...prev, minLotFront: value }))
              }}
            />
            <InputViewField
              label="Depth"
              labelType="flex"
              labelSize={14}
              placeholder="Min."
              edit
              value={filtersState.minLotDepth}
              onChange={(value) => {
                setFiltersState((prev) => ({ ...prev, minLotDepth: value }))
              }}
            />
          </div>
        </div>
        <div>
          <p className="text-secondary-color text-sm font-semibold px-2">Interior (sqft)</p>
          <div className="flex space-x-3">
            <InputViewField
              type="dropdown"
              value={filtersState.minsqft}
              onChange={(value) => {
                const err = validateSqft(Number(value), Number(filtersState.maxsqft))
                if (err === null) {
                  setFiltersState((prev) => ({ ...prev, minsqft: value }))
                }
              }}
              options={interiorSizeMin}
              edit
            />
            <InputViewField
              type="dropdown"
              value={filtersState.maxsqft}
              onChange={(value) => {
                const err = validateSqft(Number(filtersState.minsqft), +value)
                if (err === null) {
                  setFiltersState((prev) => ({ ...prev, maxsqft: value }))
                }
              }}
              options={interiorSizeMax}
              edit
            />
          </div>
          <div className="text-warning-color">{error === null ? '' : error}</div>
        </div>
        <div className="flex items-center px-2 pb-5">
          <Checkbox
            id="openHouse"
            checked={filtersState.openHouse}
            onChange={(e) => {
              const { checked } = e.currentTarget
              setFiltersState((prev) => ({ ...prev, openHouse: checked }))
            }}
          />
          <span className="text-sm text-secondary-color">Open house</span>
        </div>
      </FormContainer>
    </>
  )
}
