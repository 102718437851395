import * as React from 'react'

function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M6 7.865V19a2 2 0 002 2h8a2 2 0 002-2V4.946h2-5V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v.946H4"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgTrash = React.memo(SvgTrash)
export default MemoSvgTrash
