/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserRole } from 'constants/UserConstants'
import React, { useContext, useState } from 'react'
import { useNotesStore } from 'store/dashboard/user/userNotesStore'
import { DateHelper } from 'utilities'
import { InputViewField } from 'views/shared/components/formView/InputViewField'

import { GrayContainer, Item, NotesButton } from 'views/dashboard/styles'
import { Trash, Write } from 'views/shared/components/icons'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { SecondaryButton, TextBody, WarningButton } from 'views/shared/components/ui-form'
import { FollowUpItem } from '../followUp'

export const Notes = () => {
  const { noteForm, notesState, editNote, newNote, saveNote, deleteNote } = useNotesStore()
  const [showNotesForm, setShowNotesForm] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [notesIndex, setNotesIndex] = useState<number>()

  const showNewNoteForm = () => {
    setShowNotesForm(true)
    newNote()
  }
  const saveNoteForm = async () => {
    noteForm.touch()
    if (!noteForm.isValid()) return
    await saveNote()
    noteForm.isValid() && setShowNotesForm(false)
  }
  const cancelNoteForm = () => {
    noteForm.reset()
    setShowNotesForm(false)
  }
  const editNoteForm = (index: number) => {
    editNote(index)
    setShowNotesForm(true)
  }
  const closeDeleteModal = () => {
    setShowModal(false)
  }
  const openDeleteModal = (index: number) => {
    setNotesIndex(index)
    setShowModal(true)
  }
  const deleteNoteForm = (index: number) => {
    deleteNote(index)
    closeDeleteModal()
  }

  return (
    <>
      <GrayContainer>
        {!showNotesForm && (
          <div className="max-w-xs mx-auto">
            <SecondaryButton onClick={showNewNoteForm}>Add new note</SecondaryButton>
          </div>
        )}
        {showNotesForm && (
          <div className="mt-0">
            <>
              <ul>
                <InputViewField
                  stateValidation={noteForm}
                  edit
                  field="note"
                  type="textarea"
                  placeholder="Add note"
                  onChange={(value) => noteForm.setField('note', value as string, true)}
                  value={noteForm.getField('note')}
                />
              </ul>
              <div className="flex items-center justify-end space-x-3">
                <NotesButton onClick={saveNoteForm} type="button">
                  Save
                </NotesButton>
                <button
                  onClick={cancelNoteForm}
                  className="text-primary-color font-semibold underline"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </>
          </div>
        )}
      </GrayContainer>
      <div className="mb-5">
        <FollowUpItem />
      </div>
      <ul>
        {notesState.notes.map((item, index) => {
          const { id } = item
          return (
            <li key={id}>
              <p className="text-default-color text-xs font-semibold mt-3">
                {DateHelper.formatDateToFull(item.updatedAt)}
              </p>
              <Item>
                <p>{item.note}</p>
                <div className="flex space-x-2 pl-3">
                  <Write
                    onClick={() => editNoteForm(index)}
                    style={{ fontSize: '24px', cursor: 'pointer' }}
                    color="var(--primary-color)"
                  />
                  <Trash
                    onClick={() => {
                      openDeleteModal(index)
                    }}
                    style={{ fontSize: '24px', cursor: 'pointer' }}
                    color="var(--warning-color)"
                  />
                </div>
              </Item>
            </li>
          )
        })}
      </ul>
      <ModalLayer
        onOutsideClick={closeDeleteModal}
        show={showModal}
        responsive={{
          small: 'full',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="max-w-sm bg-white rounded-lg">
          <button
            className="text-xl font-semibold text-primary-color text-right w-full pr-2"
            type="button"
            onClick={closeDeleteModal}
          >
            x
          </button>
          <div className="px-5 pt-0 pb-5">
            <TextBody className="pb-5">
              Are you sure you want to permanently remove this note?
            </TextBody>
            <WarningButton onClick={() => deleteNoteForm(notesIndex || 0)} className="bg-white">
              Delete
            </WarningButton>
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
