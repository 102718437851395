/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { ArrowLeft, ArrowRight } from '../icons'
import { ArrowButton, Container, Label } from './Pagination.styled'

export type PaginationProps = {
  from: number
  pageSize: number
  total: number
  onChange?: (from: number) => void
}
export const Pagination = ({ from, pageSize, total, onChange }: PaginationProps) => {
  const currentPage: number = Math.floor(from / pageSize) + 1
  const totalPages: number = Math.floor(total / pageSize) + 1
  const prevButtonDisabled = currentPage <= 1
  const nextButtonDisabled = currentPage >= totalPages
  const prevButtonHandler = useCallback(() => {
    const prevPage = currentPage > 1 ? currentPage - 1 : 1
    onChange && onChange((prevPage - 1) * pageSize)
  }, [currentPage, onChange, pageSize])

  const nextButtonHandler = useCallback(() => {
    if (currentPage >= totalPages) return
    onChange && onChange(from + pageSize)
  }, [currentPage, from, onChange, pageSize, totalPages])

  return (
    <>
      <Container className="h-20">
        <ArrowButton
          className={[prevButtonDisabled ? '--disabled' : ''].join(' ')}
          type="button"
          onClick={prevButtonHandler}
        >
          <ArrowLeft color="var(--primary-color)" />
        </ArrowButton>
        <Label>
          <div className="total">{total >= 0 ? `${total} results` : '...'}</div>
          <div className="pages">
            {currentPage} / {totalPages}
          </div>
        </Label>
        <ArrowButton
          className={[nextButtonDisabled ? '--disabled' : ''].join(' ')}
          type="button"
          onClick={nextButtonHandler}
        >
          <ArrowRight color="var(--primary-color)" />
        </ArrowButton>
      </Container>
    </>
  )
}
