import React, { useEffect, useState, useCallback } from 'react'
import { Storage } from 'aws-amplify'
import { readAndCompressImage } from 'browser-image-resizer'
import { FieldModal } from 'views/shared/components/modal-box/FieldModal'
import { PrimaryButton, PrimaryNegativeButton } from 'views/shared/components/ui-form'

const newImageHash = () => `_${Math.random().toString(36).substr(2, 9)}`
export const UPLOAD_FOLDER = 'uploads/'
const config = {
  quality: 0.7,
  width: 800,
  height: 800,
}
export type ImageBoxProps = {
  value?: string
  fallbackPath?: string
  onChangePath?: (imagePath: string) => void
  onChange?: (value: string, imagePath: string) => void
  onClose?: () => void
  isOpen?: boolean
}
export const ImageBox = ({ onChange, fallbackPath, value, isOpen, onClose }: ImageBoxProps) => {
  const [imagePath, setImagePath] = useState<string | undefined>(fallbackPath)
  const fileInput = React.createRef<HTMLInputElement>()
  const [loading, setLoading] = useState<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadImage = useCallback(async () => {
    if (value) {
      setLoading(true)
      const newImagePath = await Storage.get(value)
      if (typeof imagePath === 'string') {
        setImagePath(newImagePath)
        onChange && onChange(value, newImagePath)
        setLoading(false)
        return newImagePath
      }
    }
    return Promise.resolve(`/assets/default-profile.jpg`)
  }, [value])

  const getNewValue = () => `${UPLOAD_FOLDER}${newImageHash()}.jpg`
  const onProcessFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    if (e.target.files === null) return
    const file = e.target.files[0]
    try {
      reader.readAsDataURL(file)
    } catch (err) {
      console.warn(err)
      return
    }
    reader.onloadend = () => {
      setLoading(true)
      setImagePath(reader.result as string)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      readAndCompressImage(file, config).then((resizedImage: any) => {
        const newName = getNewValue()
        Storage.put(newName, resizedImage, {
          contentType: 'image/jpg',
        }).then(() => {
          loadImage().then((res) => {
            onChange && onChange(newName, res)
            onClose && onClose()
          })
        })
      })
    }
  }
  useEffect(() => {
    loadImage()
  }, [value])

  return (
    <FieldModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-row items-center justify-center mb-6">
        <input type="file" onChange={onProcessFile} ref={fileInput} hidden />
        {!loading && (
          <img
            style={{ height: '200px', width: '200px', objectFit: 'cover' }}
            alt="ProfileImage"
            src={imagePath as string}
          />
        )}
        {loading && (
          <img
            style={{ height: '200px', width: '200px', objectFit: 'cover' }}
            alt="ProfileImage"
            src="/assets/default-profile.jpg"
          />
        )}
      </div>
      <input type="file" onChange={onProcessFile} ref={fileInput} hidden />
      <div className="flex flex-row space-x-3">
        <PrimaryNegativeButton
          type="button"
          className="border border-transparent w-full text-secondary-color font-bold text-small leading-5 box-border py-3 px-5 focus:outline-none"
          onClick={() => {
            onChange && onChange(value || '', imagePath || '/assets/default-profile.jpg')
            onClose && onClose()
          }}
        >
          Cancel
        </PrimaryNegativeButton>
        <PrimaryButton
          type="button"
          className="border border-transparent w-full bg-secondary-color text-white font-bold text-small leading-5 box-border py-3 px-5 focus:outline-none"
          onClick={() => fileInput.current?.click()}
        >
          Upload image
        </PrimaryButton>
      </div>
    </FieldModal>
  )
}
