import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SoldReportServices } from 'services/soldReport'
import { PropertyType } from 'types'
import { PropertyListing } from 'types/Property'
import { CurrencyHelper, DateHelper } from 'utilities'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import { useNeighbourhoods } from 'views/map/hooks/useNeighbourhoods'
import { Carousel } from 'views/shared/components/carousel/Carousel'
import { Bath, Bed, House, Parking, Photo } from 'views/shared/components/icons'
import { TextBody } from 'views/shared/components/ui-form'

export type HomeSearchGalleryItemProps = {
  property?: PropertyListing | null
  images?: string[] | null
  parentPage?: string
  page?: string
  bed?: number
  bath?: number
  parking?: number
  soldDate?: number | Date
  soldPrice?: number
  address?: string
  type?: string
  footage?: number
  onClick?: (p: PropertyListing) => void
}

export const GalleryItem = ({
  property,
  images,
  parentPage,
  page,
  bed,
  bath,
  parking,
  soldDate,
  soldPrice,
  address,
  type,
  footage,
  onClick,
}: HomeSearchGalleryItemProps) => {
  const history = useHistory()

  const { neighbourhoods } = useNeighbourhoods()
  const neighbourhood = neighbourhoods.find((item) => property && item.id === property.neighbour)
  const neighbourhoodName = neighbourhood ? neighbourhood.name : ''

  const onListingClick = (p?: PropertyListing) => {
    if (p) {
      history.push(`/property-details/?=${p?.mls}`, { from: window.location.pathname })
      if (onClick) {
        onClick(p)
      }
    }
  }

  const displaySoldPrice = soldPrice || property?.soldPrice
  const displaySoldDate = soldDate || property?.soldDate
  const displayBed = bed || property?.bedroom
  const displayBath = bath || property?.bathroom
  const displayParking = parking || property?.parking
  const displayAddress = () => {
    if (address) return address
    return property?.unit ? `${property?.unit} - ${property?.address}` : property?.address
  }
  const displayType = type || property?.type
  const displayfootage = footage || property?.footage

  return (
    <>
      <div
        className={[
          'inline-block bg-white text-left overflow-y-auto shadow-xl transform transition-all mb-0 sm:align-middle',
          page === 'PropertyEstimation' || page === 'PropertyValuation'
            ? 'flex flex-row md:flex-col w-full h-full md:border md:border-primary-soft-color rounded-none md:rounded-lg'
            : 'flex flex-col h-fit rounded-lg border border-primary-soft-color',
        ].join(' ')}
      >
        <div
          className={[
            page === 'PropertyEstimation' || page === 'PropertyValuation'
              ? 'flex justify-center items-center md:h-60'
              : 'h-36',
            'relative',
          ].join(' ')}
        >
          {images && images.length > 0 ? (
            <Carousel
              images={[images[0] || '']}
              imageHeight={
                page === 'PropertyEstimation' || page === 'PropertyValuation'
                  ? 'h-40 md:h-60'
                  : undefined
              }
              imageWidth={
                page === 'PropertyEstimation' || page === 'PropertyValuation'
                  ? 'w-32 md:w-full'
                  : 'w-full'
              }
              imageRounded={
                page === 'PropertyValuation' ||
                page === 'PropertyEstimation' ||
                parentPage === 'SimilarSold'
                  ? 'rounded-t-lg'
                  : ''
              }
            />
          ) : (
            <div className="flex w-32 md:w-full h-full justify-center items-center align-middle">
              <House style={{ fontSize: '56px' }} color="var(--default-color)" />
            </div>
          )}
          <button
            type="button"
            className="flex items-center space-x-1 absolute bottom-0 right-0 cursor-pointer px-2 bg-default-color rounded-lg m-2"
            onClick={() => property && history.push(`/property/${property.mls}`)}
          >
            {images && (
              <span className="font-sm font-semibold text-white w-full">{images.length}</span>
            )}
            <Photo color="white" style={{ fontSize: '16px' }} />
          </button>
        </div>
        <div
          className={
            parentPage !== 'SimilarSold'
              ? 'p-2 appearance-none w-full text-left cursor-pointer hover:underline'
              : 'px-5 py-2 appearance-none w-full text-left cursor-pointer hover:underline'
          }
          onClick={() => property && onListingClick(property)}
          aria-hidden="true"
        >
          {displaySoldPrice && displaySoldPrice > 0 ? (
            <>
              <div
                className={['pb-1', page === 'PropertyEstimation' && 'hidden md:block'].join(' ')}
              >
                <TextBody fontSize={16}>
                  {DateHelper.formatDateToDayMonthYear(displaySoldDate)}
                </TextBody>
              </div>
              <p className={parentPage !== 'SimilarSold' ? 'text-xs' : 'text-xs mt-2'}>
                <span className="text-default-color">Listed for</span>{' '}
                <span className="text-secondary-color font-semibold">
                  ${property?.listingPrice && CurrencyHelper.currencyFormat(property.listingPrice)}
                </span>
              </p>
              <p className="text-warning-color">
                <span
                  className={
                    parentPage !== 'SimilarSold' ? 'font-semibold text-lg' : 'font-semibold text-lg'
                  }
                >
                  ${displaySoldPrice && CurrencyHelper.currencyFormat(displaySoldPrice)}
                </span>
                {property?.saleType === 'Sale' || soldPrice ? (
                  <span className="text-sm"> Sold price</span>
                ) : (
                  <span className="text-sm"> Lease price</span>
                )}
              </p>
            </>
          ) : (
            <>
              {property?.listDate && (
                <div className="pb-2">
                  <TextBody fontSize={14}>
                    {DateHelper.formatDateToDayMonthYear(property.listDate)}
                  </TextBody>
                </div>
              )}
              {property?.listingPrice && (
                <p className="font-xs">
                  <span className="text-accent-color font-semibold">
                    <span>
                      $
                      {property.listingPrice &&
                        CurrencyHelper.currencyFormat(property.listingPrice)}
                    </span>
                  </span>
                </p>
              )}
            </>
          )}
          <div className="pb-2">
            <p className="font-semibold text-sm text-secondary-color">
              {displayType && SoldReportServices.getPropertyTypeLabel(displayType as PropertyType)}
            </p>
            <p className="text-sm text-secondary-color">
              {/* {property?.unit && <span>{property.unit} - </span>} */}
              <span>{displayAddress && UnescapeHelper.unescape(displayAddress() || '')}</span>
              <br />
              <span>{neighbourhoodName}</span>
              {property?.municipality && (
                <>
                  , <span>{property.municipality}</span>
                </>
              )}
            </p>
          </div>
          <div className="pb-2">
            <ul className="flex items-baseline space-x-3">
              <li className="flex items-center space-x-2">
                <span className="text-sm text-default-color">
                  {displayBed}
                  {property?.brPlus ? `+${property.brPlus}` : ''}
                </span>
                <Bed color="var(--default-color)" style={{ fontSize: '30px' }} />{' '}
              </li>
              <li className="flex items-center space-x-2">
                <span className="text-sm text-default-color">{displayBath}</span>
                <Bath color="var(--default-color)" style={{ fontSize: '30px' }} />{' '}
              </li>
              <li className="flex items-center space-x-2">
                <span className="text-sm text-default-color">{displayParking}</span>
                <Parking color="var(--default-color)" style={{ fontSize: '30px' }} />{' '}
              </li>
            </ul>
          </div>
          <div>
            {!(
              property?.footage ||
              property?.landFront ||
              property?.landDepth ||
              property?.lotFront ||
              property?.lotDepth
            ) ? (
              <>
                {displayfootage && (
                  <p className="text-default-color text-sm whitespace-nowrap pl-2">
                    {displayfootage}sqft
                  </p>
                )}
              </>
            ) : (
              <>
                {property.type === 'condo' || property.type === 'condoTownhouse' ? (
                  <p className="text-default-color text-sm whitespace-nowrap pl-2">
                    {property.footage ? <span>{property.footage}sqft</span> : '0'}

                    {!property.footage && !(property.landFront && property.landDepth) && (
                      <span>
                        {property.lotFront} - {property.lotDepth}
                      </span>
                    )}
                    {!property.footage && !(property.lotFront && property.lotDepth) && (
                      <span>
                        {property.landFront} - {property.landDepth}
                      </span>
                    )}
                  </p>
                ) : (
                  <p className="text-default-color text-sm whitespace-nowrap">
                    {property.footage ? (
                      <span>{property.footage} sqft</span>
                    ) : (
                      <span>
                        {property.landFront} x {property.landDepth} feet
                      </span>
                    )}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
