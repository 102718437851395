import React from 'react'
import { Title } from '../ui-form'

export type PageHeaderProps = React.HTMLProps<HTMLDivElement> & {
  append?: React.ReactNode
}
export const PageHeader = (props: PageHeaderProps) => {
  const { children, append, className } = props
  const rootProps = {
    ...props,
    append: undefined,
    className: undefined,
  }
  return (
    <>
      <div {...rootProps} className={['flex flex-row items-center', className || ''].join()}>
        <Title className="flex flex-col md:flex-row md:flex-grow items-start md:items-center flex-1">
          {children}
        </Title>
        <div className="flex sm:items-center">{append}</div>
      </div>
    </>
  )
}
