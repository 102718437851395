import { ChartData, ChartOptions, ScriptableContext } from 'chart.js/auto'
import { useState } from 'react'
import { CurrencyHelper, DateHelper } from 'utilities'
import moment from 'moment-timezone'

const font =
  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

export const sortDateByYearMonth = (datasets: number[][]) => {
  const res = new Map<string, number[]>()

  datasets.forEach((data) => {
    const d = DateHelper.formatDateToDayMonthYear(data[0]).split('-')
    const label = `${d[0]}-${d[1]}`
    const prices = res.get(label) || []
    res.set(label, [...prices, data[1]])
  })
  return Array.from(res)
}

export const sortDateByMonthDay = (datasets: number[][]) => {
  const res = new Map<string, number[]>()

  datasets.forEach((data) => {
    const label = moment.tz(data[0], 'UTC').format('YYYY-MM-DD')
    const prices = res.get(label) || []
    res.set(label, [...prices, data[1]])
  })
  return Array.from(res)
}

export const getLabels = (datasets: [string, number[]][], isShortTerm: boolean) => {
  if (isShortTerm) return datasets.map((data) => moment.tz(data[0], 'UTC').format('MMM DD'))
  if (datasets.length > 13)
    return datasets.map((data) => moment.tz(data[0], 'UTC').format('MMM YYYY'))
  return datasets.map((data) => moment.tz(data[0], 'UTC').format('YYYY-MM'))
}

export const generateChartData = (
  datasets: [string, number[]][],
  isShortTerm: boolean,
): ChartData => {
  const evaluationChart = {
    labels: getLabels(datasets, isShortTerm),
    datasets: [
      {
        fill: true,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const { ctx } = context.chart
          const gradient = ctx.createLinearGradient(0, 0, 0, 200)
          gradient?.addColorStop(0, 'rgba(1, 131, 99, 0.5)')
          gradient?.addColorStop(1, 'white')
          return gradient
        },
        borderColor: 'rgba(1, 131, 99)',
        pointRadius: 2,
        data: datasets.map((data) => data[1].reduce((a, b) => a + b) / data[1].length),
      },
    ],
  }
  return evaluationChart
}

export const homeValuationChartOptions = () =>
  ({
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
        position: 'average',
        padding: 6,
        displayColors: false,
        backgroundColor: (val: any) => '#018363',
        caretSize: 10,
        bodyFont: { family: font, weight: 'bold', size: 16 },
        titleFont: {
          family: font,
          weight: 'normal',
          size: 14,
        },
        callbacks: {
          title: (val: any) => val[0].label,
          label: (val: any) => {
            const stats = val.dataset.data[val.dataIndex]
            if (stats > 10000) {
              // return stats
              return `$${CurrencyHelper.currencyFormat(stats)}`
            }
            if (stats === 0) {
              return '0'
            }
            return stats > 0 && stats < 1 ? `${stats.toFixed(2)}` : `${stats.toFixed(1)}`
          },
        },
      },
    },
    scales: {
      y: {
        stacked: false,
        ticks: {
          maxTicksLimit: 6,
          font: {
            family: font,
            size: 14,
            weight: 'bold',
          },
          callback: (val: string) => {
            const n = Number(val)
            if (n === 0) return '0'
            if (n < 1) return `${n.toFixed(2)}`
            if (n >= 1000000) return `${(n / 1000000).toFixed(2)}M`
            if (n >= 1000) return `${(n / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}K`
            return `${n}`
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 12,
          font: {
            family: font,
            size: 14,
            weight: 'bold',
          },
        },
      },
    },
  } as ChartOptions)
