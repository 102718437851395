/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Spinner } from 'views/shared/components/icons'
import { dashboardRoutes } from 'views/dashboard/routes'
import { PageFooter } from 'views/shared/components/page-footer'
import { useStackRouter } from 'utilities/hooks/useStackRouter'
import { SideNav } from './SideNav'
import { ContainerRoot, PageStack } from './styles'

type StackPageType = {
  page: string
  pageKey: string
  id?: string
}

export const Container = () => {
  const { stackPages } = useStackRouter(Object.keys(dashboardRoutes))
  const showMain = stackPages.length === 1 && stackPages[0].page === 'dashboard'
  const Main = dashboardRoutes.main
  const dashboardPages = stackPages.filter(
    (item) => item.page.toLowerCase() !== 'dashboard' && item.page !== 'agent-contacts',
  )

  return (
    <ContainerRoot className="flex flex-col xl:flex-row xl:pt-10 w-full h-full mb-14 sm:mb-0">
      <SideNav />

      <PageStack className="pt-5 xl:px-0 xl:pt-0 w-full lg:max-w-screen-2xl h-full">
        {showMain && Main !== undefined && <Main page="main" pageKey="main" />}
        {!showMain &&
          dashboardPages
            .splice(dashboardPages.length - 2 >= 0 ? dashboardPages.length - 2 : 0, 2)
            .map((item) => {
              const PageComponent = dashboardRoutes[item.page] || dashboardRoutes['not-found']
              return (
                <div className="absolute xl:relative w-full h-full" key={item.pageKey}>
                  <React.Suspense
                    fallback={
                      <div className="w-full h-screen flex items-center justify-center">
                        <Spinner style={{ fontSize: '128px' }} color="var(--accent-color)" />
                      </div>
                    }
                  >
                    <PageComponent
                      key={item.pageKey}
                      pageKey={item.pageKey}
                      page={item.page}
                      id={item.id}
                    />
                  </React.Suspense>
                </div>
              )
            })}
      </PageStack>
      <PageFooter />
    </ContainerRoot>
  )
}
