import styled from 'styled-components'

export const Root = styled.div`
  display: block;
  margin: 0;
`

export const Content = styled.div`
  display: block;
  height: calc(100vh - 152px);
  @media (min-width: 1024px) {
    height: calc(100vh - 192px);
  }
`
