import type PDFDocumentType from 'pdfkit'
import { PdfHelper } from 'utilities'

export const size = {
  w: 612,
  h: 792,
}
export type AnnualReportDocType = PDFKit.PDFDocument & Record<string, never>
declare const PDFDocument: typeof PDFDocumentType

const newDoc = async () => {
  // buffer fonts
  const fontBuffers = await PdfHelper.createFontBuffers([
    {
      name: 'Thin',
      family: 'Montserrat',
      src: '/fonts/Montserrat-Thin.ttf',
    },
    {
      name: 'Regular',
      family: 'Montserrat',
      src: '/fonts/Montserrat-Regular.ttf',
    },
    {
      name: 'Medium',
      family: 'Montserrat',
      src: '/fonts/Montserrat-Medium.ttf',
    },
    {
      name: 'SemiBold',
      family: 'Montserrat',
      src: '/fonts/Montserrat-SemiBold.ttf',
    },
    {
      name: 'Bold',
      family: 'Montserrat',
      src: '/fonts/Montserrat-Bold.ttf',
    },
    {
      name: 'ExtraBold',
      family: 'Montserrat',
      src: '/fonts/Montserrat-ExtraBold.ttf',
    },
    {
      name: 'Black',
      family: 'Montserrat',
      src: '/fonts/Montserrat-Black.ttf',
    },
  ])
  // create doc
  const doc = new PDFDocument({
    margin: 0,
    layout: 'portrait',
    size: [size.w, size.h],
    bufferPages: true,
  } as PDFKit.PDFDocumentOptions) as AnnualReportDocType
  fontBuffers.forEach((fontBuffer) => doc.registerFont(fontBuffer.name, fontBuffer.buffer))
  doc.font(fontBuffers[2].buffer)
  return doc
}
export { newDoc }
