import { useReducer } from 'react'

import { Notification } from 'types'

const maxLength = 4

enum NotificationAction {
  ADD = 'NOTIFICATION_ADD',
  REMOVE = 'NOTIFICATION_REMOVE',
  CLEAR = 'NOTIFICATION_CLEAR',
}

type State = {
  notifications: Notification[]
  nextId: number
}

type Action =
  | { type: NotificationAction.ADD; id: number; message: string; duration?: number | null }
  | { type: NotificationAction.REMOVE; id: number }
  | { type: NotificationAction.CLEAR }

const initialState = {
  notifications: [],
  nextId: 0,
}

const reducer = (state: State, action: Action) => {
  const { nextId } = state

  switch (action.type) {
    case NotificationAction.ADD:
      return {
        nextId: Math.max(action.id, nextId) + 1,
        notifications: [
          {
            id: action.id,
            message: action.message,
            duration: action.duration || null,
          },
          ...state.notifications.slice(0, maxLength - 1),
        ],
      }
    case NotificationAction.REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(({ id }) => id !== action.id),
      }

    case NotificationAction.CLEAR:
      return initialState

    default:
      return {
        ...state,
      }
  }
}

export const useNotifications = () => {
  const [{ notifications, nextId }, dispatch] = useReducer(reducer, initialState)

  const removeNotification = (id: number) => dispatch({ type: NotificationAction.REMOVE, id })

  const addNotification = (message: string, duration?: number) => {
    const id = nextId + 1
    dispatch({ type: NotificationAction.ADD, id, message, duration })
    if (duration) setTimeout(() => dispatch({ type: NotificationAction.REMOVE, id }), duration)
  }

  const clearNotifications = () => dispatch({ type: NotificationAction.CLEAR })

  return { notifications, addNotification, removeNotification, clearNotifications }
}

export interface UseNotificationType {
  notifications: Notification[]
  addNotification: (message: string, duration?: number) => void
  removeNotification: (id: number) => void
  clearNotifications: () => void
}

export const defaultUseNotifications = {
  notifications: [],
  addNotification: (message: string, duration?: number) => {
    throw Error(
      `useNotifications.addNotification not implmented! [msg:${message}][duration:${duration}]`,
    )
  },
  removeNotification: (id: number) => {
    throw Error(`useNotifications.removeNotification not implmented! [id:${id}]`)
  },
  clearNotifications: () => {
    throw Error(`useNotifications.clearNotifications not implmented!`)
  },
}
