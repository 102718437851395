export const paths = {
  signIn: () => '/sign-in',
  signInNewPassword: () => '/sign-in-new-password',
  signUp: () => '/sign-up',
  signUpVerification: () => '/sign-up-verification',
  profile: () => '/profile',
  agentDetails: () => '/agent-details',
  resetPassword: () => '/reset-password',
  resetPasswordVerification: () => '/reset-password-verification',
  signUpWaitlist: () => '/sign-up-waitlist',
  signUpWaitlistValidation: () => '/sign-up-waitlist-validation',
}
