import { atom, useAtom } from 'jotai'
import { useState } from 'react'
import { HomeValuationServices } from 'services/homeValuation'
// import { GetHomeValuationAnnualReports } from 'types'
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'

const isLoadingAtom = atom<boolean>(true)
const valuationAnnualReportListAtom = atom<HomeValuationAnnualReport.Valuation[]>([])
const valuationAnnualReportAtom = atom<HomeValuationAnnualReport.Valuation | null>(null)

export const useValuationAnnualReportStore = () => {
  const [annualReportListState, setAnnualReportListState] = useAtom(valuationAnnualReportListAtom)
  const [annualReportState, setAnnualReportState] = useAtom(valuationAnnualReportAtom)
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom)
  const [error, setError] = useState<string | null>(null)

  const createAnnualReport = async (homeValuationId: string) => {
    if (homeValuationId) {
      try {
        await HomeValuationServices.createAnnualReports(homeValuationId)
      } catch (e: any) {
        setError(e.message || 'Error while creating annual report')
      }
    }
  }

  // Get an annual report
  const fetchAnnualReport = async (reportId: string) => {
    const response = await HomeValuationServices.getAnnualReportById(reportId)
      .then((result) => {
        setAnnualReportState(result)
        setError(null)
        console.log('Annual Report result: ', result)
        return result
      })
      .catch((e) => {
        setError(e.message || 'Error to fetch annual report data')
      })
      .finally(() => {
        setIsLoading(false)
      })
    return response
  }

  // Get all annual reports
  const fetchAnnualReportList = async (onlyPrintRequested: boolean) =>
    HomeValuationServices.getAnnualReports(onlyPrintRequested)
      .then((result) => {
        setAnnualReportListState(result)
        setError(null)
        return result
      })
      .catch((e) => {
        setError(e.message || 'Error to fetch annual report list')
        setAnnualReportListState([])
      })
      .finally(() => {
        setIsLoading(false)
      })

  return {
    annualReportListState,
    setAnnualReportListState,
    annualReportState,
    fetchAnnualReport,
    fetchAnnualReportList,
    createAnnualReport,
    isLoading,
    error,
  }
}
