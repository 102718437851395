import * as React from 'react'

function SvgFilters(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.333 8.167c0-.792-.597-1.434-1.333-1.434s-1.333.642-1.333 1.434c0 .791.597 1.433 1.333 1.433s1.333-.642 1.333-1.433zm0-4.056C22.887 4.701 24 6.295 24 8.167c0 1.872-1.113 3.465-2.667 4.055V22.5c0 .792-.597 1.434-1.333 1.434s-1.333-.642-1.333-1.434V12.222C17.113 11.632 16 10.04 16 8.167c0-1.872 1.113-3.465 2.667-4.056V2.433C18.667 1.642 19.264 1 20 1s1.333.642 1.333 1.433v1.678zm-8 12.656c0-.792-.597-1.433-1.333-1.433s-1.333.641-1.333 1.433S11.264 18.2 12 18.2s1.333-.641 1.333-1.433zm0-4.055c1.554.59 2.667 2.183 2.667 4.055 0 1.872-1.113 3.465-2.667 4.055V22.5c0 .792-.597 1.434-1.333 1.434s-1.333-.642-1.333-1.434v-1.678C9.113 20.232 8 18.64 8 16.767c0-1.872 1.113-3.465 2.667-4.055V2.433C10.667 1.642 11.264 1 12 1s1.333.642 1.333 1.433v10.279zm-8-4.545c0-.792-.597-1.434-1.333-1.434s-1.333.642-1.333 1.434C2.667 8.958 3.264 9.6 4 9.6s1.333-.642 1.333-1.433zm0-4.056C6.887 4.701 8 6.295 8 8.167c0 1.872-1.113 3.465-2.667 4.055V22.5c0 .792-.597 1.434-1.333 1.434s-1.333-.642-1.333-1.434V12.222C1.113 11.632 0 10.04 0 8.167 0 6.295 1.113 4.702 2.667 4.11V2.433C2.667 1.642 3.264 1 4 1s1.333.642 1.333 1.433v1.678z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgFilters = React.memo(SvgFilters)
export default MemoSvgFilters
