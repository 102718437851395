import React, { useEffect, useRef, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { MonthlyNeighbourhoodSales, Neighbourhood } from 'types'
import { useGeolocation } from 'utilities/hooks'
import { useFollows } from 'views/follows/hooks'
import { useNeighbourhoods } from 'views/map/hooks'

import { Spinner } from 'views/shared/components/spinner'
import { NeighbourhoodServices } from 'services/neighbourhood'
import NeighbourhoodMapItem from './NeighbourhoodMapItem'
// import { neighbourhoodMapOptions } from './neighbourhoodMapOptions'
import './Map.scss'
import { MapController } from './mapController'

const geolocationErrorMessages: { [error: number]: string | undefined } = {
  1: 'The geolocation information was denied.',
  2: 'The geolocation failed because one or several internal sources of position returned an internal error.',
  3: 'Timeout',
  4: "Your browser doesn't support geolocation.",
}

export const zeroSales = {
  condo: {
    soldNumber: 0,
    averageListPrice: 0,
    dom: 0,
    averagePrice: 0,
    newListing: 0,
  },
  freeholdTownhouse: {
    soldNumber: 0,
    averageListPrice: 0,
    dom: 0,
    averagePrice: 0,
    newListing: 0,
  },
  semiDetatched: {
    soldNumber: 0,
    averageListPrice: 0,
    dom: 0,
    averagePrice: 0,
    newListing: 0,
  },
  detatched: {
    soldNumber: 0,
    averageListPrice: 0,
    dom: 0,
    averagePrice: 0,
    newListing: 0,
  },
  condoTownhouse: {
    soldNumber: 0,
    averageListPrice: 0,
    dom: 0,
    averagePrice: 0,
    newListing: 0,
  },
  multi: {
    soldNumber: 0,
    averageListPrice: 0,
    dom: 0,
    averagePrice: 0,
    newListing: 0,
  },
}

export const Map = () => {
  const mapPlaceholderRef = useRef<HTMLDivElement>(null)
  const { neighbourhoods } = useNeighbourhoods()
  const [selectedNeighbourhood, setSelectedNeighbourhood] = useState<Neighbourhood | null>(null)
  const { followedNeighbourhoods } = useFollows()
  const [geolocation, askGeolocationPermission] = useGeolocation()
  const [neighbourhoodSales, setNeighbourhoodSales] = useState<MonthlyNeighbourhoodSales | null>(
    null,
  )

  useEffect(() => {
    if (mapPlaceholderRef.current) {
      MapController.instance().appendTo(mapPlaceholderRef.current)
    }
    return () => {}
  }, [mapPlaceholderRef])

  useEffect(() => {
    const fetchData = async () => {
      const data = await NeighbourhoodServices.getNeighbourhoodsSalesData()
      setNeighbourhoodSales(data)
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error)

    return () => {}
  }, [neighbourhoods])

  useEffect(() => {
    MapController.instance()
      .setNeighbourhoods(neighbourhoods)
      .onLabelClick((r) => {
        setSelectedNeighbourhood(r)
      })
      .onPolygonClick((r) => {
        setSelectedNeighbourhood(r)
      })
      .onGeolocationCenter(() => {
        askGeolocationPermission()
      })
  }, [neighbourhoods, askGeolocationPermission])
  useEffect(() => {
    MapController.instance().setFollowing({
      neighbourhoods: followedNeighbourhoods.map((item) => item.id),
      properties: [],
    })
  }, [followedNeighbourhoods])
  useEffect(() => {
    if (geolocation && geolocation.location) {
      MapController.instance().centerMapTo(geolocation.location)
    }
  }, [geolocation])

  // Handle Error Messages
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  useEffect(() => {
    // Check if browser supports geolocation.
    if (!navigator.geolocation) {
      setErrorMessage(geolocationErrorMessages[4])
    } else if (geolocation?.errorCode) {
      setErrorMessage(geolocationErrorMessages[geolocation?.errorCode])
    }
  }, [geolocation])
  const closeHandle = () => {
    setErrorMessage(undefined)
  }
  return (
    <div>
      <NeighbourhoodMapItem
        onClose={() => setSelectedNeighbourhood(null)}
        neighbourhood={selectedNeighbourhood}
        neighbourhoodSales={
          selectedNeighbourhood &&
          neighbourhoodSales &&
          neighbourhoodSales.neighbourhoods && {
            ...zeroSales,
            ...neighbourhoodSales.neighbourhoods[selectedNeighbourhood.id],
          }
        }
        salesDate={neighbourhoodSales?.time || null}
      />
      <div
        className="overflow-x-hidden neighbourhood-map"
        ref={mapPlaceholderRef}
        style={{ width: '100%' }}
      >
        <div className="flex flex-col justify-center h-96">
          <Spinner className="h-32" alt="Loading" />
        </div>
      </div>
      {/* Display error messages */}
      {errorMessage && (
        <div className="fixed overflow-hidden h-16 bottom-0 left-0 right-0 w-full z-10">
          <div className="error-message-box bg-secondary-color text-on-secondary-color flex flex-row-reverse justify-between items-center absolute bottom-0 left-0 right-0 w-full z-10 text-base font-bold  box-border leading-6 py-3 px-5 sm:px-10">
            <button
              type="button"
              className="bg-secondary-color text-on-secondary-color focus:outline-none h-10 w-10 p-2"
              onClick={() => closeHandle()}
            >
              <span className="sr-only">Close</span>
              <XIcon className="" aria-hidden="true" />
            </button>
            <div className="text-left pr-5">{errorMessage}</div>
          </div>
        </div>
      )}
    </div>
  )
}
