import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { HomeValuationServices } from 'services/homeValuation/HomeValuationServices'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValueEstimation } from 'types'
import { AuthContext } from 'views/authentication'
import { Spinner } from 'views/shared/components/icons'
import { Pagination } from 'views/shared/components/pagination/Pagination'
import { Header } from './components/Header'
import { PropertyValuationItem } from './PropertyValuationItem'

export const PropertyValuation = () => {
  const history = useHistory()
  const param = useParams<{ id: string }>()

  const { valuationListState, setValuationListState, valuationInfoState } = useHomeValuationStore()
  const [valuationState, setValuationState] = useState<HomeValueEstimation>()
  const { isAdmin, isAgent, isLoggedIn } = useContext(AuthContext)
  const [from, setFrom] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const displayPagination = () => !window.location.pathname.includes('home-valuation-summary')

  const getHomeValuationList = () => {
    // BE Data
    setIsLoading(true)
    HomeValuationServices.getHomeValuationList().then((res) => {
      try {
        if (res.length > 0) {
          setValuationListState(res.sort((a, b) => b.createdAt - a.createdAt))
        } else {
          isLoggedIn && !(isAdmin || isAgent) && history.replace('/home-valuation/property-address')
        }
        // uncomment below to use mock Data
        // setValuationListState(HomeValuationListMock)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    if (param.id) {
      setIsLoading(true)
      HomeValuationServices.getHomeValuation(param.id).then((res) => {
        try {
          setValuationState(res)
        } catch (e) {
          console.log(e)
        } finally {
          setIsLoading(false)
        }
      })
    }

    getHomeValuationList()
  }, [])

  useEffect(() => {
    if (valuationListState && !valuationInfoState) {
      getHomeValuationList()
    }
  }, [valuationInfoState])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [from])

  const displayValuations = () => (
    <>
      <Header />
      {valuationState ? (
        <PropertyValuationItem key={valuationState.id} valuation={valuationState} />
      ) : (
        valuationListState &&
        valuationListState.map(
          (valuation, index) =>
            index >= from &&
            index < from + 10 && <PropertyValuationItem key={valuation.id} valuation={valuation} />,
        )
      )}
    </>
  )

  return (
    <>
      <div className="max-w-7xl px-5 md:mx-auto box-border">
        {!isLoading ? (
          displayValuations()
        ) : (
          <div className="relative w-full h-screen flex items-center justify-center">
            <Spinner style={{ fontSize: '128px' }} color="#018363" />
          </div>
        )}
        {valuationListState && displayPagination() && (
          <Pagination
            from={from}
            pageSize={10}
            total={valuationListState.length}
            onChange={setFrom}
          />
        )}
        <div className="w-full h-16" />
      </div>
    </>
  )
}
