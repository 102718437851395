import React, { useEffect, useState, useContext } from 'react'
import {
  contactPreferenceKeyLabel,
  homeStatusKeyLabel,
  rolesKeyLabel,
} from 'constants/UserConstants'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { Button, Subtitle } from 'views/dashboard/styles'
import {
  PrimaryButton,
  PrimaryNegativeButton,
  SecondaryButton,
  TextBody,
  TextBodyWarning,
  Title,
} from 'views/shared/components/ui-form'
import { useAgentListStore } from 'store/dashboard/user/useAgentListStore'
import { useHistory } from 'react-router-dom'
import { dashboardPaths } from 'views/dashboard/paths'
import { AuthContext } from 'views/authentication'
import { Trash } from 'views/shared/components/icons'
import { ImageBox } from 'views/shared/components/image-box'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AlertInfo, alertsState } from 'views/alertBanner/state'
import { UserServices } from 'services/user'
import { useInviteUserStore } from '../../../../store/dashboard/inviteUser/inviteUserStore'

export type ProfileSection = 'details' | 'agent' | 'testimonials' | null

export const InviteUserPage = () => {
  const [isEditing, setIsEditing] = useState<ProfileSection>(null)
  const isEditingTestimonials = isEditing === 'testimonials'
  const [isModalOpen, setIsModalOpen] = useState({ uploadHeadshot: false, uploadLogo: false })
  const [photo, setPhoto] = useState<string>()
  const [photoPath, setPhotoPath] = useState<string>()
  const [logo, setLogo] = useState<string>()
  const [logoPath, setLogoPath] = useState<string>()
  const {
    userForm,
    saveClientDetails,
    profileState,
    testimonialForm,
    addTestimonials,
    deleteTestimonial,
  } = useInviteUserStore()
  const { agentListState, fetchAgentList } = useAgentListStore()
  const [inviteError, setInviteError] = useState<string>()
  const history = useHistory()
  const { isAgent, isAdmin, authUser } = useContext(AuthContext)
  const alerts = useRecoilValue(alertsState)
  const setAlerts = useSetRecoilState(alertsState)

  useEffect(() => {
    fetchAgentList()
  }, [agentListState])

  const submit = async () => {
    userForm.touch()
    if (userForm.isValid()) {
      await UserServices.checkInvite(userForm.getField('email'))
        .then((res) => {
          setAlerts([
            ...alerts,
            {
              id: alerts.length.toString(),
              type: 'error',
              message: 'Invite already exists.',
            } as AlertInfo,
          ])
        })
        .catch((error) => {
          const SES_IDENTITY_VERIFICATION_ERROR = 'SES_IDENTITY_VERIFICATION_ERROR'
          if (error.response.status === 404) {
            ;(userForm.getField('role') === 'client'
              ? UserServices.sesIdentityVerification(userForm.getField('agent'))
              : Promise.resolve({})
            )
              .catch((e) => {
                throw new Error(SES_IDENTITY_VERIFICATION_ERROR)
              })
              .then(() => saveClientDetails())
              .then(async () => {
                history.push(dashboardPaths.users())
                setAlerts([
                  ...alerts,
                  {
                    id: alerts.length.toString(),
                    type: 'success',
                    message: 'Invite successfully sent.',
                  } as AlertInfo,
                ])
                userForm.reset()
              })
              .catch((err) => {
                // setInviteError(err.response?.data?.error)
                setAlerts([
                  ...alerts,
                  {
                    id: alerts.length.toString(),
                    type: 'error',
                    message:
                      err.message === SES_IDENTITY_VERIFICATION_ERROR
                        ? 'Invite failed. Agent email is not verified by Amazon AWS. Please check email from Amazon Web Services.'
                        : 'Invite failed.',
                  } as AlertInfo,
                ])
              })
          }
        })
    }
  }
  const cancelTestimonial = () => {
    setIsEditing(null)
    testimonialForm.reset()
  }
  const saveTestimonial = async () => {
    testimonialForm.touch()
    if (testimonialForm.isValid()) {
      await addTestimonials(testimonialForm.getField('testimonial'))
      setIsEditing(null)
      addTestimonials(testimonialForm.getField('testimonial'))
      testimonialForm.reset()
    }
  }
  if (isAgent) {
    userForm.setField('agent', authUser?.sub as string)
  }
  const cancelInvite = () => {
    userForm.reset()
    const path = isAgent ? dashboardPaths.agent() : dashboardPaths.users()
    history.push(path)
  }

  return (
    <>
      <div className="space-y-10 px-5 bg-white z-50 w-full h-full md:h-auto md:pb-20 ">
        <Title className="pb-5">Invite new user</Title>
        {isAdmin && (
          <div>
            <FieldsContainer>
              <InputViewField
                label="Role"
                type="dropdown"
                stateValidation={userForm}
                field="role"
                edit
                options={rolesKeyLabel}
                value={userForm.getField('role')}
              />
              {userForm.getField('role') === 'client' && (
                <InputViewField
                  label="Agent"
                  type="dropdown"
                  stateValidation={userForm}
                  field="agent"
                  edit
                  options={agentListState.agentList || []}
                  placeholder="Select agent"
                  value={userForm.getField('agent')}
                />
              )}
            </FieldsContainer>
          </div>
        )}
        <div>
          <Subtitle>Account details</Subtitle>
          <FieldsContainer>
            <InputViewField
              label="Email"
              stateValidation={userForm}
              field="email"
              placeholder="Enter email address"
              edit
            />
            <InputViewField
              stateValidation={userForm}
              field="firstName"
              edit
              label="First name"
              placeholder="Enter your first name"
            />
            <InputViewField
              stateValidation={userForm}
              field="lastName"
              edit
              label="Last name"
              placeholder="Enter your last name"
            />
            {userForm.getField('role') !== 'admin' && (
              <InputViewField
                stateValidation={userForm}
                field="phone"
                edit
                mask="phone"
                label="Phone"
                placeholder="XXX-XXX-XXXX"
              />
            )}
            {/* Headshot field here */}
            <li className="flex items-center pl-5 h-16">
              <p className="w-28 font-semibold text-secondary-color">Headshot</p>
              <div className="flex items-center space-x-5">
                <img
                  className="inline-block h-10 w-10 object-cover"
                  src={photoPath || `/assets/default-profile.jpg`}
                  alt="Headshot"
                />
                <div>
                  <Button
                    type="button"
                    className="text-primary-color font-semibold underline"
                    onClick={() => setIsModalOpen({ uploadHeadshot: true, uploadLogo: false })}
                  >
                    Upload
                  </Button>
                </div>
              </div>
              <ImageBox
                isOpen={isModalOpen.uploadHeadshot}
                value={photo}
                onChange={(value, path) => {
                  setPhoto(value)
                  setPhotoPath(path)
                  userForm.setField('headshot', value, true)
                  setIsModalOpen({ uploadHeadshot: false, uploadLogo: false })
                }}
                onClose={() => setIsModalOpen({ uploadHeadshot: false, uploadLogo: false })}
              />
            </li>
            {userForm.getField('role') === 'client' && (
              <>
                <InputViewField
                  label="Mailing Address"
                  stateValidation={userForm}
                  field="address"
                  edit
                  placeholder="Full mailing address"
                  value={userForm.getField('address')}
                />
                <InputViewField
                  label="DOB"
                  stateValidation={userForm}
                  field="dob"
                  mask="date"
                  edit
                  placeholder="mm/dd/yyyy"
                  value={userForm.getField('dob')}
                />
                <InputViewField
                  label="Contact Preference"
                  type="dropdown"
                  stateValidation={userForm}
                  field="preferredContactMethod"
                  edit
                  options={contactPreferenceKeyLabel}
                  value={userForm.getField('preferredContactMethod')}
                />
              </>
            )}
          </FieldsContainer>
        </div>
        {userForm.getField('role') === 'client' && (
          <div>
            <Subtitle>Home Ownership</Subtitle>
            <FieldsContainer>
              <InputViewField
                label="Status"
                type="dropdown"
                stateValidation={userForm}
                field="ownershipStats"
                edit
                options={homeStatusKeyLabel}
                placeholder="Select"
                value={userForm.getField('ownershipStats')}
              />
              <InputViewField
                label="Purchase / Lease date"
                stateValidation={userForm}
                field="purchaseDate"
                mask="date"
                edit
                placeholder="mm/dd/yyyy"
                value={userForm.getField('purchaseDate')}
              />
            </FieldsContainer>
          </div>
        )}
        {userForm.getField('role') === 'agent' && (
          <div>
            <Subtitle>Agent details</Subtitle>
            <FieldsContainer>
              <InputViewField
                stateValidation={userForm}
                field="website"
                edit
                label="Website"
                placeholder="Enter website"
              />
              {/* Logo field here */}
              <li className="flex items-center pl-5 h-16">
                <p className="w-28 font-semibold text-secondary-color">Logo</p>
                <div className="flex items-center space-x-5">
                  <img
                    className="inline-block h-10 w-10 object-cover"
                    src={logoPath || `/assets/castl.jpg`}
                    alt="Logo"
                  />
                  <div>
                    <Button
                      type="button"
                      className="text-primary-color font-semibold underline"
                      onClick={() => setIsModalOpen({ uploadHeadshot: false, uploadLogo: true })}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
                <ImageBox
                  isOpen={isModalOpen.uploadLogo}
                  value={logo}
                  onChange={(value, path) => {
                    setLogo(value)
                    setLogoPath(path)
                    userForm.setField('logo', value, true)
                    setIsModalOpen({ uploadHeadshot: false, uploadLogo: false })
                  }}
                  onClose={() => setIsModalOpen({ uploadHeadshot: false, uploadLogo: false })}
                />
              </li>
              <InputViewField
                stateValidation={userForm}
                field="jobDescription"
                edit
                label="Title"
                placeholder="Enter position"
              />
              <InputViewField
                stateValidation={userForm}
                field="aboutMe"
                type="textarea"
                edit
                label="Bio"
                placeholder="Enter full bio"
              />
              <InputViewField
                stateValidation={userForm}
                field="instagram"
                edit
                label="Instagram"
                placeholder="Enter Instagram profile name"
              />
              <InputViewField
                stateValidation={userForm}
                field="linkedin"
                edit
                label="LinkedIn"
                placeholder="Enter LinkedIn profile name"
              />
              <InputViewField
                stateValidation={userForm}
                field="facebook"
                edit
                label="Facebook"
                placeholder="Enter Facebook profile name"
              />
              <InputViewField
                stateValidation={userForm}
                field="twitter"
                edit
                label="Twitter"
                placeholder="Twitter profile name"
              />
            </FieldsContainer>
            <div className="w-full mt-10">
              <Subtitle className="pb-3">Testimonials</Subtitle>
              <FieldsContainer>
                {/* List of testimonials */}
                <li>
                  <ul className="bg-white rounded-lg">
                    {(profileState?.testimonials || []).map((testimonial, index) => (
                      <li key={testimonial} className="flex justify-between items-start p-5">
                        <TextBody>{testimonial}</TextBody>
                        <div className="flex space-x-5 items-center pl-3">
                          <button
                            className="underline text-warning-color font-semibold"
                            type="button"
                            onClick={() =>
                              window.confirm('Are you sure you want to delete it?') &&
                              deleteTestimonial(index)
                            }
                          >
                            <Trash style={{ fontSize: '24px' }} color="var(--warning-color)" />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              </FieldsContainer>
              {/* Add testimonial */}
              <div>
                {isEditingTestimonials && (
                  <ul>
                    <InputViewField
                      stateValidation={testimonialForm}
                      field="testimonial"
                      edit
                      type="textarea"
                    />
                  </ul>
                )}
                {isEditingTestimonials && (
                  <>
                    <div className="flex space-x-5 items-center justify-end">
                      <button
                        className="underline text-primary-color font-semibold"
                        onClick={saveTestimonial}
                        type="button"
                      >
                        Add
                      </button>
                      <button
                        className="underline text-warning-color font-semibold"
                        onClick={cancelTestimonial}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>

              {!isEditingTestimonials && (
                <div className="max-w-xs mx-auto">
                  <SecondaryButton
                    onClick={() => setIsEditing('testimonials')}
                    className="mt-5"
                    type="button"
                  >
                    Add Testimonial
                  </SecondaryButton>
                </div>
              )}
            </div>
          </div>
        )}
        {inviteError && <TextBodyWarning>{inviteError}</TextBodyWarning>}
        <div className="max-w-xs mx-auto space-y-3">
          <PrimaryButton onClick={submit} type="button">
            Invite
          </PrimaryButton>
          <PrimaryNegativeButton onClick={cancelInvite}>Cancel</PrimaryNegativeButton>
        </div>
      </div>
    </>
  )
}
