import React, { useEffect, useState } from 'react'
import { PropertyListing } from 'types/Property'
import { PropertyServices } from 'services/property/PropertyServices'
import { MultiCarousel } from './multi-carousel/multiCarousel'

interface SimilarHomesProps {
  similarActive: PropertyListing[]
  similarSold: PropertyListing[]
  imageBaseUrl: string
  propertyType?: string
  neighbourhood?: string
  saleType?: string
}

export const SimilarHomes = ({
  similarActive,
  similarSold,
  imageBaseUrl,
  propertyType,
  neighbourhood,
  saleType,
}: SimilarHomesProps) => {
  const [activeImages, setActiveImages] = useState<string[][]>([])
  const [soldImages, setSoldImages] = useState<string[][]>([])
  useEffect(() => {
    const fetchImages = (
      properties: PropertyListing[],
      setImages: React.Dispatch<React.SetStateAction<string[][]>>,
    ) => {
      if (properties) {
        const newImages: string[][] = similarActive.map((p) =>
          PropertyServices.getPropertyImageURLs(p, imageBaseUrl),
        )
        setImages(newImages)
      } else {
        setImages([])
      }
    }

    similarActive && fetchImages(similarActive, setActiveImages)
    similarSold && fetchImages(similarSold, setSoldImages)
  }, [similarActive, similarSold])
  return (
    <>
      {propertyType && (
        <div className="text-3xl font-semibold">
          <div className="pb-1">
            More <span className="text-accent-color">{propertyType} Homes</span>
          </div>
          <div className="pt-1">
            in <span className="text-accent-color">{neighbourhood}</span>
          </div>
        </div>
      )}
      <div className="my-4">
        <div className="text-lg text-default-color my-2 mx-12 ">
          {saleType === 'Sale' ? 'For Sale' : 'For Lease'}
        </div>
        {similarActive && similarActive.length > 0 && (
          <MultiCarousel
            properties={similarActive}
            imageBaseUrl={imageBaseUrl}
            parentPage="SimilarSold"
          />
        )}
      </div>
      <div>
        <div className="text-lg text-default-color mx-12 my-2">
          {saleType === 'Sale' ? 'Sold' : 'Leased / Comparable leased'}
        </div>
        {similarActive && similarActive.length > 0 && (
          <MultiCarousel
            properties={similarSold}
            imageBaseUrl={imageBaseUrl}
            parentPage="SimilarSold"
          />
        )}
      </div>
    </>
  )
}
