import { Auth, API, Analytics } from 'aws-amplify'
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider'
import { AuthUser, AuthUserInfo, AwsErrorType, SignUpData } from 'views/authentication/types'

export class AuthException extends Error {
  public message: string

  public code: string

  constructor({ message, code }: { message: string; code: string }) {
    super(message)
    this.message = message
    this.code = code
  }
}

export module AuthServices {
  // waitlist api
  const apiName = 'user'
  // localStorage

  // const GroupToUserRole: Record<string, UserRole> = {
  //   'admin group': UserRole.ADMIN,
  //   'agent group': UserRole.AGENT,
  // }

  export const getUserRoles = (user: AuthUser) => {
    let roles: ('admin' | 'agent' | 'client')[] = (user as any)?.signInUserSession?.accessToken
      ?.payload['cognito:groups']
    if (!roles && (user as any)?.signInUserSession?.idToken?.payload) {
      roles = ['client']
    }
    return roles || []
  }
  export const getLocalStorageEmail = () => window.localStorage.getItem('email')
  export const setLocalStorageEmail = (email: string) => window.localStorage.setItem('email', email)
  export const getLocalStorageSkipped = () => window.localStorage.getItem('skipped')
  export const setLocalStorageSkipped = (isSkipped: string) =>
    window.localStorage.setItem('skipped', isSkipped)
  export const localStorageCredentialsKey = 'localAuthCredentials'
  export const localStorageCredentialsDefault: LocalStorageCredentials = {
    email: '',
    password: '',
    rememberMe: false,
  }
  export type LocalStorageCredentials = {
    email: string
    password: string
    rememberMe: boolean
  }
  export const getLocalStorageCredentials = () => {
    const localCredentials = window.localStorage.getItem(localStorageCredentialsKey)
    if (localCredentials) {
      return JSON.parse(localCredentials) as LocalStorageCredentials
    }
    return localStorageCredentialsDefault
  }
  export const setLocalStorageCredentials = (credentials: LocalStorageCredentials) => {
    if (credentials.rememberMe) {
      window.localStorage.setItem(localStorageCredentialsKey, JSON.stringify(credentials))
    } else {
      window.localStorage.setItem(
        localStorageCredentialsKey,
        JSON.stringify(localStorageCredentialsDefault),
      )
    }
  }
  // Get Cognito Identity Id
  export const getIdentity = async () => {
    const responseData = await API.get(apiName, `/identity`, {
      response: true,
    }).then((response) => response.data)
    return responseData
  }
  // SignIn
  export const signIn = async (username: string, password: string) => {
    try {
      window.localStorage.setItem('email', username)
      const user = await Auth.signIn(username, password)
      if (user) {
        await getIdentity().then((identity) =>
          Analytics.record({ attributes: identity, name: 'identity' }),
        )
      }
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        throw new AuthException({ message: 'New Password Required', code: user.challengeName })
      }
      return {
        user,
        name: user.attributes?.name,
        email: user.attributes?.email || username,
        emailVerified: user?.attributes?.email_verified || false,
        sub: user.attributes?.sub,
        roles: getUserRoles(user),
      } as AuthUser
    } catch (error) {
      if (typeof error === 'string') {
        throw new AuthException({ message: error, code: window.btoa(error) })
      } else {
        throw new AuthException(error as AwsErrorType)
      }
    }
  }

  // Remember Device
  export const rememberDevice = async () => {
    try {
      const remember = await Auth.rememberDevice()
      console.warn('remember', remember)
    } catch (error) {
      if (typeof error === 'string') {
        throw new AuthException({ message: error, code: window.btoa(error) })
      } else {
        throw new AuthException(error as AwsErrorType)
      }
    }
  }
  // Remember Device
  export const forgetDevice = async () => {
    try {
      const forget = await Auth.forgetDevice()
      console.warn('forget', forget)
    } catch (error) {
      if (typeof error === 'string') {
        throw new AuthException({ message: error, code: window.btoa(error) })
      } else {
        throw new AuthException(error as AwsErrorType)
      }
    }
  }

  export const isWhitelist = async (email: string) => {
    const responseData = await API.get(apiName, `/invite/${email}`, {
      response: true,
    })
    return responseData.status === 204
  }

  export const isWaitlist = async (email: string) => {
    const responseData = await API.get(apiName, `/waitlist/${email}`, {
      response: true,
    })
    return responseData.status === 204
  }

  // SignInNewPassword
  export const SignInNewPassword = async (username: string, password: string) => {
    try {
      const authUser = await Auth.signIn(username, password)
      const user = await Auth.completeNewPassword(authUser, password)
      return {
        user,
        name: user.attributes?.name,
        email: user.attributes?.email,
        emailVerified: user?.attributes?.email_verified || false,
        sub: user.attributes?.sub,
        roles: getUserRoles(user),
      } as AuthUser
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  // SignOut
  export const signOut = async (global = false) => {
    try {
      await Auth.signOut({ global }).then(() => window.location.reload())
      await Analytics.stopSession()
      await Analytics.startSession()
      const email = getLocalStorageEmail()
      if (email) {
        window.localStorage.removeItem(email)
      }
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const completeNewPassword = async (username: string, password: string) => {
    try {
      window.localStorage.setItem('email', username)
      const signInResult = await Auth.completeNewPassword(username, password)
      return signInResult
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const getAuthUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      return {
        user,
        name: user.attributes.email,
        email: user.attributes.email,
        emailVerified: user.attributes.email_verified === 'true',
        sub: user.attributes.sub,
        roles: getUserRoles(user),
      } as AuthUser
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  // signUp flow
  export const signUp = async ({ email, password, name }: SignUpData) => {
    try {
      window.localStorage.setItem('email', email)
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name,
        },
      })
      return {
        email: (result.user as unknown as { username: string }).username,
        emailVerified: result.userConfirmed,
        sub: result.userSub,
      } as AuthUser
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const signUpConfirm = async (username: string, code: string) => {
    try {
      const response = await Auth.confirmSignUp(username, code)
      return response
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const signUpResendCode = async (username: string) => {
    try {
      await Auth.resendSignUp(username)
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  // forgot password
  export const forgotPassword = async (username: string) => {
    try {
      const response = (await Auth.forgotPassword(username)) as {
        AttributeName: string
        DeliveryMedium: string
        Destination: string
      }
      return response
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string,
  ) => {
    try {
      const response = await Auth.forgotPasswordSubmit(username, code, newPassword)
      return response
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }

  export const getAddress = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      return user.attributes.address
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }

  export const updateAddress = async (address: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        address,
      })
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const updateUserInfoProp = async (key: string, value: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const { attributes } = user
      attributes[key] = value
      await Auth.updateUserAttributes(user, attributes)
      return attributes as AuthUserInfo
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const updateUserInfo = async (info: AuthUserInfo) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, info)
      return info as AuthUserInfo
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const { attributes } = user
      return attributes as AuthUserInfo
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      return await Auth.changePassword(user, oldPassword, newPassword)
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  export const deleteAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      const cognitoIdentityProvider = new CognitoIdentityProvider({ region: 'ca-central-1' })
      const params = {
        AccessToken: user.signInUserSession.accessToken.jwtToken,
      }
      cognitoIdentityProvider.deleteUser(params, (err: unknown) => {
        if (err) {
          console.error(err)
        }
        Auth.signOut({ global: true })
      })
    } catch (error) {
      throw new AuthException(error as AwsErrorType)
    }
  }
  // Waitlist
  export const signUpWaitlist = async (email: string) => {
    const responseData = (
      await API.put(apiName, `/waitlist`, {
        response: true,
        body: { email },
      })
    ).data
    return responseData
  }
}
