import React from 'react'

export const InfoCardContent = () => (
  <>
    <div className="font-semibold text-2xl text-black">
      How we<div>estimate</div>
      <div>your home</div>
    </div>

    <div className="text-sm">
      <div className="my-3">
        We calculated the value of your home by using a combination of different factors:
      </div>
      <div className="mt-5 mb-1 text-accent-color font-semibold">Sold Reports</div>
      <div>Sold reports can inform us about the trends and current prices in your area.</div>
      <div className="mt-5 mb-1 text-accent-color font-semibold">Trending Neighbourhoods</div>
      <div>
        We have a large database of listings that we use to make a more accurate assessment based on
        neighbourhood demand.
      </div>
      <div className="mt-5 mb-1 text-accent-color font-semibold">Industry Stats and Figures</div>
      <div>
        We also consider inflation rates, bank interest rates, industry statistics and other
        variables.
      </div>
    </div>
  </>
)
