/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react'
import { KeyLabel } from 'types'
import { MaskHelper } from 'utilities'
import type { useStateValidation, ValidationReturn } from 'utilities/hooks/validationState'
import { DropdownField } from 'views/shared/components/ui-form'
import { CountInput } from 'views/shared/components/ui-form/count-input/CountInput'
import {
  InputAutocomplete,
  InputAutocompleteProps,
} from '../ui-form/input-autocomplete/InputAutocomplete'
import { Li, ValidationMessages } from './InputViewField.styled'

type StateValidationReturnType = ReturnType<typeof useStateValidation>
export type InputViewFieldProps<TInput extends number | string = number | string> =
  | {
      label?: string
      labelType?: 'flex' | 'block'
      placeholder?: string
      id?: string
      type?:
        | 'text'
        | 'number'
        | 'password'
        | 'email'
        | 'tel'
        | 'textarea'
        | 'dropdown'
        | 'readonly'
        | 'count'
        | 'checkbox'
        | 'autocomplete'
      edit?: boolean
      value?: TInput
      onChange?: (value: TInput) => void
      field?: string
      messages?: ValidationReturn[]
      stateValidation?: StateValidationReturnType | any
      touched?: boolean
      mask?: 'phone' | 'date'
      options?: KeyLabel<TInput>[]
      maxLength?: number
      labelSize?: number
      labelWidth?: number
      defaultValue?: string
      // search?: string
      // onPick?: (valueKey: string) => void
    } & InputAutocompleteProps<TInput | undefined>

export const InputViewField = <TInput extends string | number = string>({
  value,
  label = '',
  labelType = 'flex',
  placeholder = '',
  id,
  type = 'text',
  edit = false,
  stateValidation,
  field,
  onChange,
  touched,
  mask,
  messages = [],
  options = [],
  maxLength,
  labelSize,
  labelWidth,
  search,
  onPick,
  onSearch,
  labelParser,
}: InputViewFieldProps<TInput>) => {
  const isUsingStateValidation = stateValidation && field
  const isNumber = type === 'number'
  const currentValue = isUsingStateValidation ? stateValidation.getField(field) : value
  const currentMessages: ValidationReturn[] = isUsingStateValidation
    ? stateValidation.getMessages(field) || []
    : messages
  const isTouched = isUsingStateValidation ? stateValidation.touched[field] : touched || true
  const validationClass = (() => {
    if (isTouched && isUsingStateValidation) {
      if (stateValidation.valid[field] || stateValidation.valid[field] === undefined) {
        return '--valid'
      }
      return '--invalid'
    }
    return '--untouched'
  })()
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget) {
      if (onChange)
        onChange((isNumber ? Number(e.currentTarget.value) : e.currentTarget.value) as TInput)
      if (stateValidation && field) {
        stateValidation.setField(
          field,
          isNumber ? Number(e.currentTarget.value) : e.currentTarget.value,
          true,
        )
      }
    }
  }
  const handleSearchChange = (searchValue: string) => {
    if (searchValue !== search && onSearch) onSearch(searchValue)
  }
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(e.currentTarget.value as TInput)
    if (stateValidation && field) {
      stateValidation.setField(field, e.currentTarget.value, true)
    }
  }
  const handleDropdownChange = (newValue: TInput) => {
    if (onChange) onChange(newValue)
    if (stateValidation && field) {
      stateValidation.setField(field, newValue, true)
    }
  }
  const blurHandle = () => {
    if (stateValidation && field) {
      stateValidation.touch(field)
    }
  }
  const inputRef = React.createRef<HTMLInputElement>()
  useEffect(() => {
    if (inputRef && inputRef.current && mask) {
      const maskInput = MaskHelper[mask](inputRef.current)
      return () => {
        maskInput.destroy()
      }
    }
    return () => {}
  }, [edit])

  return (
    <Li className={`${validationClass}`}>
      {label && labelType === 'block' && (
        <span style={{ fontSize: labelSize || 16 }} className="label-block">
          {label}
        </span>
      )}
      <label>
        {label && labelType === 'flex' && (
          <span
            style={{ fontSize: labelSize || 16, maxWidth: labelWidth || 150 }}
            className="label-flex"
          >
            {label}
          </span>
        )}

        {edit && type !== 'readonly' && (
          <>
            {type === 'textarea' && (
              <textarea
                id={id}
                placeholder={placeholder}
                onBlur={blurHandle}
                value={currentValue}
                onInput={handleTextAreaChange}
                maxLength={maxLength}
              />
            )}
            {['text', 'number', 'password', 'email', 'tel'].includes(type) && (
              <input
                id={id}
                ref={inputRef}
                onBlur={blurHandle}
                value={currentValue}
                onInput={handleInputChange}
                type={type}
                placeholder={placeholder}
              />
            )}
            {type === 'dropdown' && (
              <DropdownField
                name={id || field || 'dropdown'}
                value={value}
                options={options}
                onChange={handleDropdownChange}
                placeholder={placeholder}
              />
            )}
            {type === 'count' && (
              <CountInput value={value} items={options || []} onChange={handleDropdownChange} />
            )}
            {type === 'autocomplete' && (
              <InputAutocomplete
                value={value}
                options={options}
                search={search}
                onPick={onPick}
                onSearch={handleSearchChange}
                labelParser={labelParser}
                placeholder={placeholder}
              />
            )}
          </>
        )}
        {(!edit || type === 'readonly') && <span className="currentValue">{currentValue}</span>}
      </label>
      {edit && isTouched && currentMessages && currentMessages.length > 0 && (
        <ValidationMessages>
          {currentMessages.map((m: ValidationReturn) => (
            <li className={`--${m.type}`} key={m.message}>
              {m.message}
            </li>
          ))}
        </ValidationMessages>
      )}
    </Li>
  )
}
