import { KeyLabel } from 'types'

export const interiorSizeMin = [
  {
    key: null,
    label: 'Min.',
  },
  {
    key: '500',
    label: '500',
  },
  {
    key: '1000',
    label: '1,000',
  },
  {
    key: '2000',
    label: '2,000',
  },
] as KeyLabel<string>[]
export const interiorSizeMax = [
  {
    key: null,
    label: 'Max.',
  },
  {
    key: '500',
    label: '500',
  },
  {
    key: '1000',
    label: '1,000',
  },
  {
    key: '2000',
    label: '2,000',
  },
] as KeyLabel<string>[]
export const lotSizeMin = [
  {
    key: null,
    label: 'Min.',
  },
  {
    key: '500',
    label: '500',
  },
  {
    key: '1000',
    label: '1,000',
  },
  {
    key: '2000',
    label: '2,000',
  },
] as KeyLabel<string>[]
export const lotSizeMax = [
  {
    key: null,
    label: 'Min.',
  },
  {
    key: '500',
    label: '500',
  },
  {
    key: '1000',
    label: '1,000',
  },
  {
    key: '2000',
    label: '2,000',
  },
] as KeyLabel<string>[]
export const bedrooms = [
  { key: '0', label: '0+' },
  { key: '1', label: '1+' },
  { key: '2', label: '2+' },
  { key: '3', label: '3+' },
  { key: '4', label: '4+' },
] as KeyLabel<string>[]

export const bathrooms = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
] as KeyLabel<string>[]

export const parking = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
] as KeyLabel[]

export const dom = [
  { key: null, label: 'Any' },
  { key: '1day', label: '1 day' },
  { key: '7days', label: '7 days' },
  { key: '15days', label: '15 days' },
  { key: '1month', label: '1 month' },
  { key: '3months', label: '3 months' },
  { key: '6month', label: '6 months' },
  { key: '1year', label: '1 year' },
] as KeyLabel<string>[]
