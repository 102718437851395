import React, { ReactElement, useState } from 'react'
import { PropertyServices } from 'services/property'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { PrimaryButton, SecondaryButton, TextBody } from 'views/shared/components/ui-form'
import './book-a-showing.scss'

interface BookAShowingProps {
  mls?: string
}

export const BookAShowing = ({ mls }: BookAShowingProps): ReactElement => {
  const [booked, setBooked] = useState<boolean>(false)

  const handleDiscussion = () => {
    mls && PropertyServices.bookShowing(mls).then(() => setBooked(true))
  }
  return (
    <>
      <div className="block p-5 sm:p-10 rounded-lg shadow-xl">
        <div className="pb-10 font-semibold text-xl">Visit this Home</div>
        <div className="w-full sm:max-w-xs sm:mx-auto">
          <PrimaryButton onClick={handleDiscussion}>Book a Showing</PrimaryButton>
        </div>
        {/* <form>
        <div className="form-group mb-6">
          <label htmlFor="emailInput" className="text-sm font-semibold form-label  text-gray-700">
            Email Address*
            <input
              type="email"
              className="italic form-control block w-full px-3 py-2 text-sm md:text-base font-normal 
                bg-white bg-clip-padding border border-solid border-primary-soft-color rounded transition ease-in-out m-0"
              color="var(--on-primary-soft-color)"
              id="emailInput"
              placeholder="Please enter your email"
              area-aria-describedby="emailHelp"
            />
          </label>
        </div>
        <div className="form-group mb-6">
          <label htmlFor="contactInput" className="text-sm font-semibold  form-label text-gray-700">
            Contact Number (if you want us to call you back)
            <input
              type="text"
              className="italic form-control block w-full px-3 py-2 text-sm md:text-base font-normal text-gray-700
                bg-white bg-clip-padding border border-solid border-primary-soft-color rounded transition ease-in-out m-0"
              color="var(--on-primary-soft-color)"
              id="contactInput"
              placeholder="Please enter your telephone number"
              area-aria-describedby="emailHelp"
            />
          </label>
        </div>
        <button
          type="submit"
          className="w-full px-6 py-2.5 font-semibold text-base book-a-showing-button 
                text-white font-medium text-xs leading-tight rounded shadow-md"
        >
          Book a Showing
        </button>
      </form> */}
      </div>
      <ModalLayer
        onOutsideClick={() => setBooked(false)}
        show={booked}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="flex">
          <div className="md:py-5 xl:py-12">
            <div className="shadow-md space-y-10 max-w-xs min-w-max bg-white w-full rounded-lg border-2 border-primary-color md:border-none md:p-5 xl:p-12">
              <p className="font-semibold text-4xl">Thanks!</p>
              <TextBody>
                Your request has been sent.
                <br />A member of the team will contact you shortly.
              </TextBody>
              <div>
                <PrimaryButton onClick={() => setBooked(false)}>
                  Back to Property Details
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
