import React, { useMemo, useState } from 'react'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeImprovement, HomeValuationInfo } from 'types'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { TextBody, UnderlinedPrimaryButton, WarningButton } from 'views/shared/components/ui-form'
import { DatePicker } from 'views/shared/components/ui-form/month-picker/DatePicker'
import { uuid } from 'short-uuid'

interface HomeImprovementsProps {
  propertyDetails: HomeValuationInfo | null | undefined
  setPropertyDetails: React.Dispatch<React.SetStateAction<HomeValuationInfo | null | undefined>>
}

export const HomeImprovements = ({
  propertyDetails,
  setPropertyDetails,
}: HomeImprovementsProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [improvementIndex, setImprovementIndex] = useState<number>()
  const { property } = useHomeValuationStore()

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }
  const openDeleteModal = (id: number) => {
    setImprovementIndex(id)
    setShowDeleteModal(true)
  }
  const deleteHomeImprovement = () => {
    // improvements.delete(id)
    const updateHomeImprovements = propertyDetails?.homeImprovements
    if (improvementIndex !== undefined) {
      updateHomeImprovements?.splice(improvementIndex, 1)
      setPropertyDetails((prev) => prev && { ...prev, homeImprovements: updateHomeImprovements })
    }
    closeDeleteModal()
  }
  const addHomeImprovement = () => {
    const updateHomeImprovements = propertyDetails?.homeImprovements || []
    updateHomeImprovements.push({
      randomId: uuid(),
    })
    setPropertyDetails((prev) => prev && { ...prev, homeImprovements: updateHomeImprovements })
  }
  const updateHomeImprovement = (index: number, att: keyof HomeImprovement, value: any) => {
    if (propertyDetails?.homeImprovements && propertyDetails?.homeImprovements.length > 0) {
      const updateHomeImprovements = propertyDetails.homeImprovements
      updateHomeImprovements[index][att] = value
      setPropertyDetails((prev) => prev && { ...prev, homeImprovements: updateHomeImprovements })
    }
  }

  const improvementsList = useMemo(
    () => property.form.state.improvements || [],
    [property.form.state],
  )
  return (
    <>
      <ModalLayer
        onOutsideClick={closeDeleteModal}
        show={showDeleteModal}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="max-w-sm bg-white rounded-lg border-2 shadow-lg z-50">
          <button
            className="text-xl font-semibold text-primary-color text-right w-full pr-2"
            type="button"
            onClick={closeDeleteModal}
          >
            x
          </button>
          <div className="px-5 pt-0 pb-5">
            <TextBody className="pb-5">
              Are you sure you want to permanently remove this improvement?
            </TextBody>
            <WarningButton onClick={() => deleteHomeImprovement()} className="bg-white">
              Delete
            </WarningButton>
          </div>
        </div>
      </ModalLayer>

      <ul className="space-y-5">
        {propertyDetails?.homeImprovements &&
          propertyDetails?.homeImprovements.map((improvement, index) => (
            <li
              key={improvement.randomId}
              className="relative bg-alpha-color px-2 py-5 md:p-7 mr-5 md:mr-10 rounded-tl-lg rounded-bl-lg  rounded-br-lg"
            >
              <button
                onClick={() => {
                  openDeleteModal(index)
                }}
                type="button"
                className="bg-alpha-color h-5 w-5 md:h-10 md:w-10 text-sm md:text-xl text-primary-color font-semibold rounded-tr-lg rounded-br-lg absolute -right-5 md:-right-10 top-0"
              >
                x
              </button>
              <ul>
                <div className="flex flex-row md:flex-col justify-between">
                  <div className="flex items-center font-semibold text-xs md:text-md">Area</div>
                  <InputViewField
                    labelSize={14}
                    labelWidth={50}
                    edit
                    value={improvement.area}
                    type="text"
                    onChange={(v) => {
                      updateHomeImprovement(index, 'area', v)
                    }}
                    field="area"
                  />
                </div>
              </ul>
              <ul className="relative xl:flex">
                <div className="flex flex-row md:flex-col justify-between">
                  <div className="flex items-center font-semibold text-xs md:text-md">Cost</div>
                  <InputViewField
                    labelSize={14}
                    labelWidth={50}
                    edit
                    value={improvement.cost}
                    onChange={(v) => {
                      updateHomeImprovement(index, 'cost', v)
                    }}
                    field="area"
                  />
                </div>
                <div className="flex flex-row md:flex-col justify-between w-full">
                  <div className="flex items-center font-semibold text-xs md:text-md">
                    Completion Date (approx.)
                  </div>
                  <div className="px-2 md:pb-2 md:px-0">
                    <DatePicker
                      name="completion-date"
                      styleType="inputStyle"
                      value={improvement.completionDate?.toString()}
                      onChange={(v) => {
                        updateHomeImprovement(index, 'completionDate', v)
                      }}
                    />
                  </div>
                </div>
              </ul>
            </li>
          ))}
      </ul>

      <UnderlinedPrimaryButton
        className=" flex w-full justify-center"
        type="button"
        onClick={addHomeImprovement}
      >
        <span>Add More Home Improvements</span>
      </UnderlinedPrimaryButton>
    </>
  )
}
