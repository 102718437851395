import styled from 'styled-components'

export const Item = styled.li`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
export const ItemLabel = styled.li`
  font-size: 20px;
  color: var(--accent-color);
  font-weight: 600;
  text-align: center;
`
