import styled from 'styled-components'

export const Li = styled.li`
  padding: 0 8px;
  list-style: none;
  & > span.label-block {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--secondary-color);
    display: block;
  }
  & > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 64px;
    padding: 8px 0;

    & > span.label-flex {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: var(--secondary-color);
      padding-right: 10px;
      width: 100%;
    }
    & > span.currentValue {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: var(--default-color);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & > input,
    & > textarea {
      border: 1px solid var(--primary-soft-color);
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #ffffff;
      color: var(--default-color);
      width: 100%;
      font-style: italic;
      &:focus {
        box-shadow: none;
        border-color: var(--primary-soft-color);
      }
    }
    & > input {
      height: 48px;
    }
    & > textarea {
      height: 140px;
      resize: none;
    }
    @media screen and (min-width: 920px) {
      flex-direction: row;
      align-items: center;
      justify-content: start;
      & span.label {
        padding: 0px;
        min-width: 100px;
        max-width: 120px;
      }
    }
  }
  &.--invalid {
    & input,
    & textarea {
      border-color: var(--warning-color);
    }
  }
  &.--valid {
    & input,
    & textarea {
      border-color: var(--accent-color);
    }
  }
`
export const ValidationMessages = styled.ul`
  margin: 0px;
  padding: 2px;
  background-color: transparent;
  text-align: right;
  & > li.--error {
    background-color: transparent;
    color: var(--warning-color);
    font-size: 12px;
  }
  li.--warning {
    background-color: transparent;
    color: #ffcc00;
    font-size: 12px;
  }
`
