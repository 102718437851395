import { PreferredContactMethod } from 'constants/UserConstants'
import { atom, useAtom } from 'jotai'
import { UserServices } from 'services/user'
import { UPLOAD_FOLDER } from 'views/shared/components/image-box'

import {
  isCAPhone,
  isEmail,
  isUrl,
  isDateMMddYYYY,
  isFutureDate,
  maxLength,
  minLenth,
  required,
  stateValidationStore,
  useStateValidation,
} from 'utilities/hooks/validationState'

const initial = {
  user: {
    role: `client` as string,
    agent: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    dob: '',
    preferredContactMethod: PreferredContactMethod.EMAIL,
    ownershipStats: '',
    purchaseDate: '',
    headshot: undefined as string | undefined,
    website: '',
    jobDescription: '',
    aboutMe: '',
    instagram: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    logo: undefined as string | undefined,
    testimonials: [] as string[],
  },
  testimonial: {
    testimonial: '',
    index: -1,
  },
}
const store = {
  user: stateValidationStore(initial.user),
  testimonial: stateValidationStore(initial.testimonial),
}

const inviteNewUser = atom<Partial<typeof initial.user> | null>(null)

export const useInviteUserStore = () => {
  const [profileState, setProfileState] = useAtom(inviteNewUser)

  const userForm = useStateValidation({
    store: store.user,
    validation: {
      firstName: [
        required('First name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      lastName: [
        required('Last name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      phone: [isCAPhone('Please, enter a valid canadian phone number')],
      email: [required('Email is Required'), isEmail('Please, enter a valid email address')],
      address: [minLenth(2, 'Address must have at least 2 characters')],
      dob: [
        isDateMMddYYYY('Please, enter a valid date'),
        isFutureDate('Date cannot be in the future'),
      ],
      purchaseDate: [
        isDateMMddYYYY('Please, enter a valid date'),
        isFutureDate('Date cannot be in the future'),
      ],
      website: [isUrl('Please, enter a valid url')],
      jobDescription: [minLenth(2, 'Title must have at least 2 characters')],
      aboutMe: [
        minLenth(2, 'Title must have at least 2 characters'),
        maxLength(1000, 'Bio must have at most 1000 characters'),
      ],
      instagram: [isUrl('Please, enter a valid url')],
      linkedin: [isUrl('Please, enter a valid url')],
      facebook: [isUrl('Please, enter a valid url')],
      twitter: [isUrl('Please, enter a valid url')],
    },
  })

  const testimonialForm = useStateValidation({
    store: store.testimonial,
    validation: {
      testimonial: [minLenth(10, 'Testimonial must have at least 10 characters')],
    },
  })

  const invite = async (newProfileState: typeof initial.user) => {
    if (newProfileState.role === 'client' && newProfileState.agent === '') {
      await UserServices.inviteClient({
        agent: newProfileState.agent,
        firstName: newProfileState.firstName,
        lastName: newProfileState.lastName,
        phone: newProfileState.phone,
        email: newProfileState.email,
        address: newProfileState.address,
        dob: newProfileState.dob,
        preferredContactMethod: newProfileState.preferredContactMethod,
        ownershipStats: newProfileState.ownershipStats,
        purchaseDate: newProfileState.purchaseDate,
        headshot: newProfileState.headshot?.startsWith(UPLOAD_FOLDER)
          ? newProfileState.headshot.substring(UPLOAD_FOLDER.length)
          : newProfileState.headshot,
      }).then(() => {
        setProfileState(newProfileState)
      })
    } else if (newProfileState.role === 'client' && newProfileState.agent !== '') {
      await UserServices.inviteClientForAgent(newProfileState.agent, {
        firstName: newProfileState.firstName,
        lastName: newProfileState.lastName,
        phone: newProfileState.phone,
        email: newProfileState.email,
        address: newProfileState.address,
        dob: newProfileState.dob,
        preferredContactMethod: newProfileState.preferredContactMethod,
        ownershipStats: newProfileState.ownershipStats,
        purchaseDate: newProfileState.purchaseDate,
        headshot: newProfileState.headshot?.startsWith(UPLOAD_FOLDER)
          ? newProfileState.headshot.substring(UPLOAD_FOLDER.length)
          : newProfileState.headshot,
      }).then(() => {
        setProfileState(newProfileState)
      })
    } else if (newProfileState.role === 'agent') {
      await UserServices.inviteAgent({
        firstName: newProfileState.firstName,
        lastName: newProfileState.lastName,
        phone: newProfileState.phone,
        email: newProfileState.email,
        aboutMe: newProfileState.aboutMe,
        jobDescription: newProfileState.jobDescription,
        socialMedias: {
          instagram: newProfileState.instagram,
          facebook: newProfileState.facebook,
          linkedin: newProfileState.linkedin,
          twitter: newProfileState.twitter,
        },
        website: newProfileState.website,
        headshot: newProfileState.headshot?.startsWith(UPLOAD_FOLDER)
          ? newProfileState.headshot.substring(UPLOAD_FOLDER.length)
          : newProfileState.headshot,
        logo: newProfileState.logo?.startsWith(UPLOAD_FOLDER)
          ? newProfileState.logo.substring(UPLOAD_FOLDER.length)
          : newProfileState.logo,
        testimonials: newProfileState.testimonials,
      }).then(() => {
        setProfileState(newProfileState)
      })
    } else if (newProfileState.role === 'admin') {
      await UserServices.inviteAdmin({
        firstName: newProfileState.firstName,
        lastName: newProfileState.lastName,
        email: newProfileState.email,
        headshot: newProfileState.headshot,
      }).then(() => {
        setProfileState(newProfileState)
      })
    }
  }
  const saveClientDetails = async () => {
    if (!userForm.isValid()) return
    const newProfileState = {
      ...profileState,
      agent: userForm.state.agent,
      role: userForm.state.role,
      firstName: userForm.state.firstName,
      lastName: userForm.state.lastName,
      email: userForm.state.email,
      phone: userForm.state.phone,
      address: userForm.state.address,
      dob: userForm.state.dob,
      preferredContactMethod: userForm.state.preferredContactMethod,
      ownershipStats: userForm.state.ownershipStats,
      purchaseDate: userForm.state.purchaseDate,
      headshot: userForm.state.headshot?.startsWith(UPLOAD_FOLDER)
        ? userForm.state.headshot.substring(UPLOAD_FOLDER.length)
        : userForm.state.headshot,
      website: userForm.state.website,
      jobDescription: userForm.state.jobDescription,
      aboutMe: userForm.state.aboutMe,
      instagram: userForm.state.instagram,
      linkedin: userForm.state.linkedin,
      facebook: userForm.state.facebook,
      twitter: userForm.state.twitter,
      logo: userForm.state.logo?.startsWith(UPLOAD_FOLDER)
        ? userForm.state.logo.substring(UPLOAD_FOLDER.length)
        : userForm.state.logo,
    } as typeof initial.user
    await invite(newProfileState)
  }

  const addTestimonials = async (testimonial: string) => {
    if (!testimonial) return
    setProfileState({
      ...profileState,
      ...{
        testimonials: [...(profileState?.testimonials || []), testimonial],
      },
    })
  }
  const deleteTestimonial = (index: number) => {
    const newTestimonials = [...(profileState?.testimonials || [])]
    newTestimonials.splice(index, 1)
    setProfileState({ ...profileState, ...{ testimonials: newTestimonials } })
  }

  return {
    userForm,
    saveClientDetails,
    profileState,
    addTestimonials,
    testimonialForm,
    deleteTestimonial,
  }
}
