import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PropertyServices } from 'services/property/PropertyServices'
import { SimilarSold } from 'views/homeValuation/SimilarSold'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { CurrencyHelper } from 'utilities'
import { HomeValuationsContainer } from 'views/homeValuation/components/styles'
import { GalleryItem } from 'views/map/pages/HomeSearch/HomeSearchGallery/GalleryItem'
import { ArrowDownSelect, Range } from 'views/shared/components/icons'
import { Share } from 'views/shared/components/share'
import {
  PrimaryButton,
  SecondaryButton,
  TextBody,
  UnderlinedPrimaryButton,
} from 'views/shared/components/ui-form'
import { Storage } from 'aws-amplify'
import { HomeValuationServices } from 'services/homeValuation'
import { InfoCard } from 'views/shared/components/info-card/InfoCard'
import UserTypes from 'services/user/UserTypes'
import { AuthContext } from 'views/authentication'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { Config } from 'config'
import { UserRole } from 'constants/UserConstants'
import { useAgentContactList } from 'views/dashboard/pages/profile/contactList'
import { ValuationConfirmationPage } from 'views/dashboard/pages/valuations/components/valuationConfirmation/ValuationConfirmation'
import { useValuationsStore } from 'store/dashboard/valuations'
import { InfoCardContent } from './InfoCardContent'
import { InputViewField } from '../../../shared/components/formView/InputViewField'

export const PropertyEstimation = () => {
  const history = useHistory()
  const { estimationState, propertyImageState, setPropertyImageState, setPropertyState } =
    useHomeValuationStore()
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [lastFoundSearch, setLastFoundSearch] = useState<string | undefined>(undefined)
  const { isAgent, authUser } = useContext(AuthContext)
  const { contactList } = useAgentContactList(authUser?.sub)
  const [selectedClient, setSelectedClient] = useState<
    UserTypes.GetUsersResponse['hits'][number] | undefined
  >(undefined)
  const [userOptions, setUserOptions] = useState<UserTypes.GetUsersResponse['hits'] | undefined>(
    undefined,
  )
  const [showOptions, setShowOptions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [discussed, setDiscussed] = useState<boolean>(false)

  const handleSkip = async () => {
    history.replace('/home-valuation/property-valuation')
  }

  const handleSuccess = () => {
    history.replace('/home-valuation/property-valuation')
  }

  const adjustValuation = async () => {
    if (estimationState?.id) {
      setIsConfirmationModalOpen(true)
    }
  }

  const prevState = useRef()

  useEffect(() => {
    if (search.length <= 1 || !authUser) {
      setUserOptions(undefined)
      return
    }

    const results = contactList.hits.filter(
      (contact) =>
        contact.details.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        contact.details.lastName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        contact.details.email.toLowerCase().indexOf(search.toLowerCase()) > -1,
    )
    setUserOptions(results.filter((r) => r.PK.startsWith('U#')))
  }, [search])

  const onClickItem = (item: UserTypes.GetUsersResponse['hits'][number]) => {
    setSelectedClient(item)
    setSearch(`${item.details.firstName} ${item.details.lastName}`)
    setUserOptions(undefined)
  }
  const onPickItem = (userKey: string | undefined) => {
    if (!userKey) return
    const item = (userOptions || []).find((user) => user.PK === userKey)
    if (!item) return
    setSelectedClient(item)
    setSearch(`${item.details.firstName} ${item.details.lastName}`)
    setUserOptions(undefined)
  }

  const closeModal = async () => {
    setIsOpen(false)
    setUserOptions(undefined)
    setSearch('')
  }

  // Assign Home Valuation to a Contact
  const assignToContact = async () => {
    if (!estimationState || !estimationState.id || !selectedClient) return
    setLoading(true)
    await HomeValuationServices.assignHomeValuationToAContact(estimationState.id, selectedClient.id)
      .catch(() => {})
      .then(() => {})
    handleSuccess()
    setShowOptions(false)
    closeModal()
    setLoading(false)
  }

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public`)
    }
    retrieveImageBaseUrl()
    if (!estimationState || !estimationState.id) {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    if (estimationState?.lastListing) {
      const newImages: string[] = PropertyServices.getPropertyImageURLs(
        estimationState.lastListing,
        imageBaseUrl,
      )
      setPropertyImageState(newImages)
    } else {
      setPropertyImageState([])
    }
  }, [estimationState, imageBaseUrl])

  const handleDiscussion = () => {
    estimationState?.id &&
      HomeValuationServices.discussHomeValuation(estimationState?.id).then(() => setDiscussed(true))
  }

  useEffect(() => {
    setIsConfirmationModalOpen(false)
  }, [estimationState])

  return (
    <>
      <div className="w-full h-full m-0 overflow-hidden">
        <HomeValuationsContainer className="p-5 md:p-10">
          <div className="flex mx-auto w-min md:w-full">
            <div className="flex flex-col md:flex-row justify-center md:space-x-12 w-full">
              <div className="w-full md:w-64">
                {estimationState && (
                  <GalleryItem
                    property={estimationState.lastListing}
                    images={propertyImageState}
                    bed={estimationState.request?.bedroom}
                    bath={estimationState.request?.bathroom}
                    parking={estimationState.request?.parking}
                    soldDate={estimationState.request?.purchaseDate}
                    soldPrice={estimationState.request?.purchasePrice}
                    address={estimationState.displayAddress}
                    footage={estimationState.request?.footage}
                    type={estimationState.request?.type}
                  />
                )}
              </div>
              <div className="bg-white md:rounded-lg md:flex items-center py-4 md:p-8 lg:p-12">
                <div className="flex flex-col">
                  <div className="flex justify-between items-center md:space-x-2 md:mb-14 md:mx-auto">
                    <div className="flex space-x-3">
                      <div className="font-semibold text-xl md:text-4xl lg:text-5xl xl:text-6xl whitespace-nowrap py-4 md:py-0">
                        Estimated Valuation
                      </div>
                      <div className="flex items-center">
                        <InfoCard content={<InfoCardContent />} props={{ width: 30, height: 30 }} />
                      </div>
                    </div>
                    <div className="md:pl-6 xl:pl-16">
                      <Share
                        title="Share this Property Details"
                        text=""
                        url={window.location.href}
                        files={[]}
                      />
                    </div>
                  </div>
                  <div className="block md:hidden ">
                    <div className="relative flex justify-center border bg-black rounded-full text-white text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold items-center w-min md:w-full px-8 md:px-12 py-4 mx-auto md:mx-10">
                      $
                      {estimationState?.instanceValue
                        ? CurrencyHelper.currencyFormat(estimationState?.instanceValue)
                        : 'N/A'}{' '}
                      <div className="absolute w-9 mx-auto left-0 right-0 -bottom-5">
                        <ArrowDownSelect />
                      </div>
                    </div>
                  </div>
                  {estimationState?.confirmed ? (
                    <div className="w-full">
                      <div className="flex justify-center text-md md:text-lg lg:text-xl xl:text-2xl">
                        The valuation is now confirmed at:
                      </div>
                      <div className="flex border bg-accent-color rounded-full text-white text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold items-center px-12 py-4 w-min mx-auto mt-10 mb-16">
                        $
                        {estimationState?.confirmedValue
                          ? CurrencyHelper.currencyFormat(estimationState.confirmedValue)
                          : 'N/A'}{' '}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row md:flex-col justify-between my-4">
                      <div className="flex flex-col">
                        <div className="text-lg md:text-3xl lg:text-4xl xl:text-5xl text-accent-color font-bold">
                          $
                          {estimationState?.lowerRange
                            ? CurrencyHelper.currencyFormat(estimationState?.lowerRange)
                            : 'N/A'}
                        </div>
                        <div className="text-default-color text-xs md:text-md">Lower Range</div>
                      </div>
                      <div className="mx-6 my-2">
                        <div className="flex items-center h-4 md:h-full">
                          <div className="md:mr-4 transform rotate-90 md:rotate-0 px-2 md:px-0">
                            <Range className="" height={200} />
                          </div>
                          <div className="hidden md:block">
                            <div className="flex">
                              <div className="w-0 h-0 bg-black border border-t-[10px] border-t-transparent border-r-[20px] border-r-indigo-600 border-b-[10px] border-b-transparent" />
                            </div>
                            <div className="flex border bg-black rounded-full text-white text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold items-center px-12 py-4 mx-10">
                              $
                              {estimationState?.instanceValue
                                ? CurrencyHelper.currencyFormat(estimationState?.instanceValue)
                                : 'N/A'}{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-lg md:text-3xl lg:text-4xl xl:text-5xl text-accent-color font-bold">
                          $
                          {estimationState?.upperRange
                            ? CurrencyHelper.currencyFormat(estimationState?.upperRange)
                            : 'N/A'}
                        </div>
                        <div className="text-default-color text-xs md:text-md">Upper Range</div>
                      </div>
                    </div>
                  )}
                  {isAgent ? (
                    <div className="flex flex-col items-center justify-center space-y-3">
                      <div className="w-72 font-semibold text-xl lg:text-2xl">
                        <SecondaryButton onClick={adjustValuation}>
                          Adjust Valuation
                        </SecondaryButton>
                      </div>
                      <div className="w-72 font-semibold text-xl lg:text-2xl my-4">
                        <PrimaryButton onClick={() => setIsOpen(true)}>
                          Assign to a Contact
                        </PrimaryButton>
                      </div>
                      <div className="text-center font-semibold text-xl lg:text-2xl my-4">
                        <UnderlinedPrimaryButton onClick={handleSkip}>Next</UnderlinedPrimaryButton>
                      </div>
                      <ModalLayer
                        onOutsideClick={closeModal}
                        show={isOpen}
                        responsive={{
                          small: 'full',
                          medium: 'center',
                        }}
                        outsideColor="rgba(0, 0, 0, 0.5)"
                      >
                        <div className="relative bg-white rounded-lg w-full max-w-md">
                          <button
                            className="text-xl font-semibold text-primary-color text-right w-full pr-2"
                            type="button"
                            onClick={closeModal}
                          >
                            x
                          </button>
                          <div className="px-5 pt-0 pb-5">
                            <TextBody className="pb-5">Find Contact</TextBody>
                            <div className="flex flex-1 items-center space-x-3">
                              <div className="w-full">
                                <InputViewField
                                  value={search}
                                  type="autocomplete"
                                  onSearch={(s) => {
                                    setSearch(s)
                                  }}
                                  onPick={onPickItem}
                                  search={search}
                                  id="search"
                                  edit
                                  labelType="block"
                                  placeholder="Search by name"
                                  options={
                                    userOptions &&
                                    userOptions.map((item) => ({
                                      key: item.PK,
                                      label: `${item.details.firstName} ${item.details.lastName}`,
                                    }))
                                  }
                                />
                              </div>
                              <div>
                                <PrimaryButton
                                  disabled={loading || !selectedClient}
                                  onClick={assignToContact}
                                >
                                  Assign
                                </PrimaryButton>
                              </div>
                            </div>
                          </div>
                          {!showOptions ||
                            (search !== '' &&
                              search !== undefined &&
                              userOptions &&
                              userOptions?.length === 0 && (
                                <div className="left-0 right-0 text-warning-color text-center pb-5">
                                  <p className="text-default-color">Not found</p>
                                </div>
                              ))}
                        </div>
                      </ModalLayer>

                      <ModalLayer
                        onOutsideClick={() => setIsConfirmationModalOpen(false)}
                        show={isConfirmationModalOpen}
                        responsive={{
                          small: 'full',
                          medium: 'center',
                        }}
                        outsideColor="rgba(0, 0, 0, 0.5)"
                      >
                        <div className="relative bg-white rounded-lg max-w-2xl">
                          <button
                            className="text-xl font-semibold text-primary-color text-right w-full pr-2"
                            type="button"
                            onClick={() => setIsConfirmationModalOpen(false)}
                          >
                            x
                          </button>{' '}
                          {estimationState?.id && <ValuationConfirmationPage />}
                        </div>
                      </ModalLayer>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <button
                        className="font-semibold text-primary-color underline"
                        type="button"
                        onClick={handleSkip}
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </HomeValuationsContainer>
        <SimilarSold
          property={estimationState?.summary}
          similarProperties={estimationState?.similarSolds}
        />
      </div>
      <ModalLayer
        onOutsideClick={() => setDiscussed(false)}
        show={discussed}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="flex">
          <div className="md:py-5 xl:py-12">
            <div className="shadow-md space-y-10 max-w-xs min-w-max bg-white w-full rounded-lg border-2 border-primary-color md:border-none md:p-5 xl:p-12">
              <p className="font-semibold text-4xl">Thanks!</p>
              <TextBody>
                Your request has been sent.
                <br />A member of the team will contact you shortly.
              </TextBody>
              <div>
                <PrimaryButton onClick={() => setDiscussed(false)}>Close</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
