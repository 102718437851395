import { atom, selector } from 'recoil'
import { NeighbourhoodReport } from 'types'

export const neighbourhoodReportState = atom({
  key: 'snapshot.neighbourhoodReport',
  default: undefined as NeighbourhoodReport | undefined,
})

export const avgSellingPriceDiffSelector = selector({
  key: 'snapshot.avgSellingPriceDiff',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    return report ? Math.round((report.averagePrice / report.averagePriceLastYear - 1) * 100) : 0
  },
})

export const avgSellingToListPriceSelector = selector({
  key: 'snapshot.avgSellingToListPrice',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    return report ? Math.round(report.averageSellingToListPrice * 100) : 0
  },
})

export const avgSellingToListPriceDiffSelector = selector({
  key: 'snapshot.avgSellingToListPriceDiff',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    return report
      ? Math.round((report.averageListPrice / report.averageListPriceLastYear - 1) * 100)
      : 0
  },
})

export const soldNumberDiffSelector = selector({
  key: 'snapshot.soldNumberDiff',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    return report ? Math.round((report.soldNumber / report.soldNumberLastYear) * 100) : 0
  },
})

export const daysOnMarketDiffSelector = selector({
  key: 'snapshot.daysOnMarketDiff',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    return report ? Math.round(report.dom - report.domLastYear) : 0
  },
})

export const salesToNewListingsRateSelector = selector({
  key: 'snapshot.salesToNewListingsRate',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    return report ? Math.round((report.soldNumber / report.newListing) * 100) : 0
  },
})

export const salesToNewListingsRateDiffSelector = selector({
  key: 'snapshot.salesToNewListingsRateDiff',
  get: ({ get }) => {
    const report = get(neighbourhoodReportState)
    if (!report) return 0
    const salesToNewListingsRateLastYear = Math.round(
      (report.soldNumberLastYear / report.newListingLastYear) * 100,
    )
    return Math.round(
      (get(salesToNewListingsRateSelector) / salesToNewListingsRateLastYear - 1) * 100,
    )
  },
})
