import React from 'react'
import styled from 'styled-components'
import { SecondaryButton } from '../styled'

const Display = styled.input`
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary-color);
  text-align: center;
  width: 100%;
  height: 48px;
  padding: 6px 12px;
  box-sizing: border-box;
  border: none;
  &::placeholder {
    color: var(--default-color);
  }
  &:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
`

export type NumberInputProps = {
  value?: number
  inc?: () => void
  dec?: () => void
  label?: string
}

export const NumberCounter = ({ value, label, inc, dec }: NumberInputProps) => {
  const handleDecrement = () => {
    dec && dec()
  }
  const handleIncrement = () => {
    inc && inc()
  }

  return (
    <>
      <div>
        <p className="font-semibold pb-2">{label}</p>
        <div className="flex flex-row justify-evenly">
          <div className="w-12">
            <SecondaryButton fontSize="28" onClick={handleDecrement}>
              -
            </SecondaryButton>
          </div>
          <div className="w-12">
            <Display
              name="number-input"
              className="appearance-none"
              type="text"
              readOnly
              value={value}
            />
          </div>

          <div className="w-12">
            <SecondaryButton fontSize="28" onClick={handleIncrement}>
              +
            </SecondaryButton>
          </div>
        </div>
      </div>
    </>
  )
}
