import { atom } from 'jotai'
import type { FieldState, Message, StateStore, Touched, Valid } from './types'

export const stores: {
  [id: symbol]: {
    state: any
    touched: any
    valid: any
    messages: any
  }
} = {}

export const stateValidationStore = <State extends FieldState>(initial: State) => {
  const id = Symbol('stateStore')
  stores[id] = {
    state: atom(initial),
    touched: atom({} as Touched<State>),
    messages: atom([] as Message<State>[]),
    valid: atom({} as Valid<State>),
  }
  return { id, initial } as StateStore<State>
}
