import { HomeValuationAnnualReport } from 'types/HomeValuationAnualReport'
import { CurrencyHelper } from 'utilities'
import { ChartHelper } from './chartHelper'

export type ChartHeaderProps = {
  currentValue: number
  lastYearValue: number
}
export const chartHeader = (data: HomeValuationAnnualReport.Valuation) => {
  const dataChart = [...data.chart].reverse()

  const currentValue = dataChart[dataChart.length - 1][1]
  const lastYearValue = dataChart[0][1]

  const priceDiffPercentage = ((currentValue - lastYearValue) / lastYearValue) * 100
  const priceDiff = currentValue - lastYearValue
  const positive = priceDiff > 0
  const priceDiffSign: -1 | 0 | 1 = (positive && 1) || (!positive && -1) || 0
  const priceDiffSignString =
    (priceDiffSign === -1 && '-') || (priceDiffSign === 0 && ``) || (priceDiffSign === 1 && '+')
  const increasedValue = `${priceDiffSignString}$${CurrencyHelper.currencyFormat(priceDiff)}`
  const increasedPercentage = `${priceDiffSignString}${priceDiffPercentage.toFixed(1)}`

  const template = `
  <svg>
  <text fill="black" xml:space="preserve" style="white-space: normal" font-family="Montserrat" font-size="10" letter-spacing="0em">
    <tspan x="25" y="149.585">Over the last 12 months,</tspan>
    <tspan x="25" y="164.585">the value of this property has:</tspan>
  </text>
  <text fill="#018363" xml:space="preserve" style="white-space: normal" font-family="Montserrat" font-size="10" letter-spacing="0em">
    <tspan x="25" y="179.585">${positive ? 'increased by' : 'decreased by'}</tspan>
  </text>
  <text
    text-anchor="start"
    fill="#018363"
    xml:space="preserve"
    style="white-space: pre"
    font-family="Montserrat"
    font-size="46"
    letter-spacing="0em">
    <tspan x="25" y="226"  font-weight="bold" >${CurrencyHelper.currencyFormat(
      priceDiff,
      true,
      `$`,
    )}</tspan>
    <tspan y="226">${CurrencyHelper.percentageFormat(priceDiffPercentage, true)}</tspan>
  </text>
</svg>
  `

  return template
}
