/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { AgentServices } from 'services/agents'
import blobStream from 'blob-stream'
import { AgentProfile } from 'views/agents/types/agents'
import { SoldReportData, SoldReportServices } from 'views/soldReport'
import { DateHelper } from 'utilities'
// import type PDFDocumentType from 'pdfkit'
import { MonthStr, Neighbourhood, PropertyType } from 'types'
import { Storage } from 'aws-amplify'
import { newDoc, propertyTypes } from './config'
import { addCover } from './cover'
import { addPropertyType } from './propertyType'
import { PropertyKeyLabel } from './types/types'

const DEFAULT_HEADSHOT = '/assets/default-profile.jpg'
const DEFAULT_LOGO = '/assets/castl.jpg'

export type GenerateSoldReportPdfProps = {
  soldReportData: SoldReportData
  neighbourhood: Neighbourhood
  agentProfile: AgentProfile
}
export const generateSoldReportPdf = async ({
  neighbourhood,
  soldReportData,
  agentProfile,
}: GenerateSoldReportPdfProps) => {
  const monthLabel = DateHelper.monthStrToShortMonthLongLabel(
    DateHelper.monthStrToYearAndMonth(soldReportData.time as MonthStr),
  )
  const propertyKeyLabels: PropertyKeyLabel[] = Object.keys(soldReportData.reports).map((key) => ({
    key,
    label: SoldReportServices.getPropertyTypeLabel(key as PropertyType),
    count: soldReportData.reports[key as PropertyType].soldNumber,
  }))
  // doc
  const doc = await newDoc()
  // register fonts
  const montserratBlackBuffer = await (await fetch('/fonts/Montserrat-Black.ttf')).arrayBuffer()
  const montserratRegularBuffer = await (await fetch('/fonts/Montserrat-Regular.ttf')).arrayBuffer()
  const montserratSemiBoldBuffer = await (
    await fetch('/fonts/Montserrat-SemiBold.ttf')
  ).arrayBuffer()
  doc.registerFont('Montserrat-Black', montserratBlackBuffer)
  doc.registerFont('Montserrat', montserratRegularBuffer)
  doc.registerFont('Montserrat-SemiBold', montserratSemiBoldBuffer)

  // create element in DOM to trigger download
  const a = document.createElement('a')
  Object.assign(a.style, { display: 'none' })

  // download Blob file
  const download = (blob: Blob) => {
    if (!(blob instanceof Blob)) return
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = `${neighbourhood.name}-${DateHelper.toMonthShortYear(
      soldReportData.time,
    )}-SoldReport.pdf`
    document.body.appendChild(a)
    a.click()
  }

  // stream
  const stream = doc.pipe(blobStream())
  stream.on('finish', () => {
    download(stream.toBlob('application/pdf'))
  })
  // fill data
  const s = await AgentServices.getMyAgentProfile()
  const headshot = await (agentProfile.headshot
    ? Storage.get(`headshots/${s.headshot}`)
    : Promise.resolve(DEFAULT_HEADSHOT)
  )
    .then(fetch)
    .then((r) => r.arrayBuffer())
    .then((buf) => `data:image/jpg;base64,${Buffer.from(buf).toString('base64')}`)
  const logo = await (agentProfile?.logo
    ? Storage.get(`logos/${agentProfile.logo}`)
    : Promise.resolve(DEFAULT_LOGO)
  )
    .then(fetch)
    .then((r) => r.arrayBuffer())
    .then((buf) => `data:image/jpg;base64,${Buffer.from(buf).toString('base64')}`)

  // const image = await axios.get('', { responseType: 'arraybuffer' })
  // const returnedB64 = `data:image/jpeg;base64,${Buffer.from(image.data).toString('base64')}`
  addCover(doc, {
    name: neighbourhood.name,
    monthLabel,
    agentProfile: { ...agentProfile, headshot, logo },
  })
  // property
  propertyTypes.forEach((propertyType) => {
    const neighbourhoodReport = soldReportData.reports[propertyType as PropertyType]
    neighbourhoodReport.soldNumber > 0 &&
      addPropertyType(doc, {
        soldReportData,
        neighbourhoodReport,
        neighbourhood,
        monthLabel,
        propertyKeyLabels,
      })
  })

  doc.end()
}
