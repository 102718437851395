import { paths } from './paths'
import { ValuationAnnualReport } from './ValuationAnnualReport'

export const routes = {
  valuationAnnualReport: {
    path: paths.valuationAnnualReport(),
    exact: false,
    component: ValuationAnnualReport,
  },
}
