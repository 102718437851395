import { PropertyServices } from 'services/property'
import { HomeValuationAnnualReport } from 'types'
import { PdfHelper } from 'utilities/helpers/PdfHelper'

export module PdfImageHelper {
  class PropertyImages {
    constructor(public mls: string, public numberOfPic: number, public paths: string[]) {}
  }
  export const getPropertyImagePaths = async (
    propertySummary: HomeValuationAnnualReport.Summary | { numberOfPic: number; mls: string },
  ) => {
    const { numberOfPic } = propertySummary
    const { mls } = propertySummary
    const imagesPaths = (await PropertyServices.getPropertyImagePaths(mls, numberOfPic)) || []
    return new PropertyImages(mls, numberOfPic, imagesPaths)
  }
  export const getPropertyImages = async (
    propertySummary: HomeValuationAnnualReport.Summary | { numberOfPic: number; mls: string },
  ) => {
    const propertyImages = await getPropertyImagePaths(propertySummary)
    const imagesPromises = propertyImages.paths.map((path) => PdfHelper.getImage(path))
    return Promise.all(imagesPromises)
  }
}
