import React, { useEffect, useState } from 'react'
import { dashboardPaths } from 'views/dashboard'
import { GrowthArrow, People, Picture, Profile } from 'views/shared/components/icons'
import { NavLink } from 'react-router-dom'
import { AuthServices } from 'services/auth'
import { Nav } from './styles'

export const sideNavAdmin = [
  {
    path: dashboardPaths.main(),
    icon: <Picture color="var(--primary-color)" />,
    label: 'Platform Analytics',
  },
  {
    path: dashboardPaths.users(),
    icon: <People color="var(--primary-color)" />,
    label: 'Users',
  },
  {
    path: dashboardPaths.valuations(),
    icon: <GrowthArrow color="var(--primary-color)" />,
    label: 'Annual Reports',
  },
  {
    path: dashboardPaths.myProfile(),
    icon: <Profile color="var(--primary-color)" />,
    label: 'My Profile',
  },
]
export const sideNavAgent = [
  {
    path: dashboardPaths.emailAnalytics(),
    icon: <Picture color="var(--primary-color)" />,
    label: 'Email Analytics',
  },
  {
    path: dashboardPaths.agent(),
    icon: <People color="var(--primary-color)" />,
    label: 'Contacts',
  },
  {
    path: dashboardPaths.valuations(),
    icon: <GrowthArrow color="var(--primary-color)" />,
    label: 'Contact Valuations',
  },
  {
    path: dashboardPaths.myProfile(),
    icon: <Profile color="var(--primary-color)" />,
    label: 'My Profile',
  },
]

export const SideNav = () => {
  const [authRole, setAuthRole] = useState<string | undefined>()
  useEffect(() => {
    const fetchAuthRole = async () => {
      const auth = await AuthServices.getAuthUser()
      auth.roles && setAuthRole(auth.roles[0])
    }
    fetchAuthRole()
  }, [])
  return (
    <>
      {authRole === 'admin' && (
        <Nav>
          {sideNavAdmin.map(({ icon, path, label }) => (
            <NavLink
              activeClassName="--active"
              className={['navlink text-base pb-2 lg:text-lg xl:mb-16 xl:pb-0'].join(' ')}
              to={path}
              key={path}
            >
              <span style={{ fontSize: '32px', paddingBottom: '12px' }}>{icon}</span>
              <span>{label}</span>
            </NavLink>
          ))}
        </Nav>
      )}
      {authRole === 'agent' && (
        <Nav>
          {sideNavAgent.map(({ icon, path, label }) => (
            <NavLink
              activeClassName="--active"
              className={['navlink text-base pb-2 lg:text-lg xl:mb-16 xl:pb-0'].join(' ')}
              to={path}
              key={path}
            >
              <span style={{ fontSize: '32px', paddingBottom: '12px' }}>{icon}</span>
              <span>{label}</span>
            </NavLink>
          ))}
        </Nav>
      )}
    </>
  )
}
