import * as React from 'react'

function SvgClock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.554a6.71 6.71 0 000 13.421.395.395 0 110 .79 7.5 7.5 0 114.167-1.264.395.395 0 01-.532-.086l-3.948-5.12a.395.395 0 01-.082-.24V4.316a.395.395 0 11.79 0V8.92l3.633 4.712A6.71 6.71 0 007.5 1.554z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgClock = React.memo(SvgClock)
export default MemoSvgClock
