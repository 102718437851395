import React, { ReactElement, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { UserServices } from 'services/user'
import { useSearchParams } from 'utilities/hooks/useSearchParams'

export const EmailRedirect = (): ReactElement => {
  const { pathname } = useLocation()
  const tokens = pathname.split('/')
  const emailId = tokens[2]

  const { href } = window.location
  const redirectPath = tokens.filter((v, index) => index !== 1 && index !== 2).join('/') || ''
  const idx = href.indexOf(pathname)
  const newHref = href.substring(0, idx) + redirectPath

  useEffect(() => {
    async function getToken() {
      if (emailId) {
        await UserServices.emailClick(emailId, newHref).catch(() =>
          console.warn(`error when handle email click: ${emailId}`),
        )
      }
    }
    getToken()
  }, [])

  const searchParams = useSearchParams()

  return <Redirect to={`${redirectPath}?${searchParams.toString()}`} />
}
