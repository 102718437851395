import polylabel from 'polylabel'
import { GMapCoordinate, Neighbourhood, NeighbourhoodMapDisplay, PolygonCoordinates } from 'types'

export const convertToGMapCoordinate = (lngLat: [number, number]) =>
  ({ lng: lngLat[0], lat: lngLat[1] } as GMapCoordinate)

export const convertToGMapCoordinates = (coordinates: PolygonCoordinates[]): GMapCoordinate[][] =>
  coordinates.map((polygonPaths) => polygonPaths.map((lngLat) => convertToGMapCoordinate(lngLat)))
export const convertToGMapNeighbourhoodPolygon = (
  polygonCoordinates: GMapCoordinate[],
): google.maps.Polygon =>
  new google.maps.Polygon({
    paths: polygonCoordinates,
    strokeColor: '#6B7280',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#000000',
    fillOpacity: 0,
    clickable: true,
  })
const createLabelMarker = (neighbourhood: Neighbourhood, isFavorite: boolean) => {
  const position = convertToGMapCoordinate(neighbourhood.coordinates[0][0])
  const label: google.maps.MarkerLabel & { id: string } = {
    text: /* html */ `${neighbourhood.name}`,
    fontSize: '14px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    className: `neighbourhood-marker ${isFavorite ? '--favorite' : ''}`,
    id: neighbourhood.id,
  }
  const marker = new google.maps.Marker({
    label,
    position,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0,
    },
    optimized: false,
    visible: false,
  })
  marker.setVisible(false)
  // map ?? marker.setMap(map)
  return marker
}
export const getPolygonCenter = (neighbourhoodPolygons: PolygonCoordinates) => {
  const visualCenter = polylabel([neighbourhoodPolygons])
  return new google.maps.LatLng(visualCenter[1], visualCenter[0])
}
export const convertToNeighbourhoodDisplay = (
  neighbourhood: Neighbourhood,
  isFavorite: boolean,
) => {
  const r = { ...neighbourhood } as NeighbourhoodMapDisplay
  const neighbourhoodPolygons = convertToGMapCoordinates(neighbourhood.coordinates).map(
    (polygonPaths) => convertToGMapNeighbourhoodPolygon(polygonPaths),
  )
  if (neighbourhoodPolygons.length > 0) {
    const neighbourhoodPolygon = neighbourhoodPolygons[0]
    r.polygon = neighbourhoodPolygon
    r.polygonCenter = getPolygonCenter(neighbourhood.coordinates[0])
    r.label = createLabelMarker(neighbourhood, isFavorite)
    r.label.setPosition(r.polygonCenter)
    r.label.setZIndex(2)
  }
  return r
}
