import React, { useState } from 'react'
import { PrimaryButton, Title } from 'views/shared/components/ui-form'
import { FieldModal } from 'views/shared/components/modal-box'
import { useMobileDetect } from 'utilities/hooks'
import ShareIcon from '../icons/Share'

interface ShareProps {
  className?: string

  title: string
  text: string

  url?: string
  files?: File[]

  // config
  showShareBySMS?: boolean
  textShareBySMS?: string
  notifyShareBySMS?: string

  showShareByEmail?: boolean
  textShareByEmail?: string
  notifyShareByEmail?: string

  showShareToClipboard?: boolean
  textShareToClipboard?: string
  notifyShareToClipboard?: string

  showShareByDownload?: boolean
  textShareByDownload?: string
  notifySharebyDownload?: string
}

export const Share = ({
  className = '',
  title,
  text,
  url: propsUrl,
  files = [],

  showShareBySMS = false,
  textShareBySMS = 'By SMS',
  notifyShareBySMS = 'SMS module triggered',

  showShareByEmail = true,
  textShareByEmail = 'By Email',
  notifyShareByEmail = 'Default email client opened',

  showShareToClipboard = true,
  textShareToClipboard = 'Copy Link',
  notifyShareToClipboard = 'Link copied to clipboard',

  showShareByDownload = false,
  textShareByDownload = 'Download',
  notifySharebyDownload = 'Download initiated',
}: ShareProps) => {
  const url = propsUrl || window.location.href

  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const { isMobile } = useMobileDetect()

  const onClickShare = () => {
    if (isMobile()) {
      navigator.share({
        title,
        text,
        url,
        files,
      })
    } else {
      setShowModal(true)
    }
  }

  const onClose = () => {
    setMessage('')
    setShowModal(false)
  }

  const onShareBySMS = () => {
    // console.log('share by SMS??')
    setMessage(notifyShareBySMS)
  }

  const onShareToClipboard = () => {
    navigator.clipboard.writeText(url)
    setMessage(notifyShareToClipboard)
  }

  const onShareByEmail = () => {
    window.open(
      `mailto:you@domain.com?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
        `${text}${url ? `\n\nURL:${url}` : ''}`,
      )}`,
    )
    setMessage(notifyShareByEmail)
  }

  const onShareByDownload = () => {
    // console.log('share by download??')
    setMessage(notifySharebyDownload)
  }

  return (
    <>
      <button
        className={[
          'text-primary-color font-semibold flex flex-col items-center w-14',
          className,
        ].join(' ')}
        type="button"
        onClick={onClickShare}
      >
        <ShareIcon style={{ fontSize: '24px' }} color="var(--primary-color)" />
        <span className="text-xs text-center">Share</span>
      </button>

      <FieldModal
        isOpen={showModal}
        onClose={onClose}
        message={message}
        title={title}
        renderTitle={(titleProp) => (
          <>
            <div className="pr-14">
              <Title className="hidden sm:block pb-6">{titleProp}</Title>
              <h2 className="block sm:hidden text-secondary-color text-3xl font-semibold pb-6">
                {titleProp}
              </h2>
            </div>
          </>
        )}
      >
        {showShareBySMS && (
          <PrimaryButton className="mb-3" onClick={onShareBySMS}>
            {textShareBySMS}
          </PrimaryButton>
        )}
        {showShareByEmail && (
          <PrimaryButton className="mb-3" onClick={onShareByEmail}>
            {textShareByEmail}
          </PrimaryButton>
        )}
        {showShareToClipboard && (
          <PrimaryButton className="mb-3" onClick={onShareToClipboard}>
            {textShareToClipboard}
          </PrimaryButton>
        )}
        {showShareByDownload && (
          <PrimaryButton className="mb-3" onClick={onShareByDownload}>
            {textShareByDownload}
          </PrimaryButton>
        )}
      </FieldModal>
    </>
  )
}
