import styled from 'styled-components'

export const YourValuationContainer = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
`
export const GrayContainer = styled.div`
  display: block;
  width: 100%;
  padding: 24px;
  background-color: var(--alpha-color);
`
