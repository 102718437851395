import * as React from 'react'

function SvgHomeowner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1L0 12.325l1.126 1.132h2.879V19.8c0 1.767 1.459 3.2 3.218 3.2H10v-5.6h4.587V23h2.19c1.758 0 3.217-1.433 3.217-3.2v-6.343h2.88L24 12.325 12 1z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgHomeowner = React.memo(SvgHomeowner)
export default MemoSvgHomeowner
