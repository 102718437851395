import styled from 'styled-components'

export const FavouriteButtonWrapper = styled.button`
  font-size: 10px;
  color: var('--primary-color');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
export const ClusterModalWrapper = styled.button`
  max-height: calc(100vh - 300px);
  max-width: 512px;
  margin-top: calc(100% - 48%);
  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 300px);
    margin-top: 0px;
  }
`
