import { MonthStr } from 'types'

interface SoldReportProps {
  neighbourhoodId?: string
  monthStr?: MonthStr
  propertyType?: string
}

export const paths = {
  soldReport: (props?: SoldReportProps) => {
    const { neighbourhoodId, monthStr, propertyType } = {
      ...props,
    }
    return neighbourhoodId || monthStr || propertyType
      ? `/sold-report/${neighbourhoodId}/${monthStr}/${propertyType}`
      : '/sold-report/:neighbourhoodId/:monthStr?/:propertyType?'
  },
}
