/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { AdminWebAnalytics } from 'views/dashboard/types/analytics'
import { Duration, Logins, Mobile, Pages, Rate, Visitors } from 'views/shared/components/icons'
import styled from 'styled-components'
import { AnalyticsServices } from 'services/analytics'
import { TextBody } from 'views/shared/components/ui-form/styled'
import { Subtitle } from './styles'

const Stats = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  color: var(--secondary-color);
`
type WebAnalyticsProps = {
  month: string
}

export const WebAnalytics = ({ month }: WebAnalyticsProps) => {
  const [analytics, setAnalytics] = useState<AdminWebAnalytics>()
  useEffect(() => {
    const fetchAnalytics = async () => {
      await AnalyticsServices.getWebAnalytics(month)
        .then((res) => {
          setAnalytics(res)
        })
        .catch((e) => {
          console.error(e)
        })
    }
    fetchAnalytics()
  }, [month])

  const convertSessionTimeToMin = (number: number) => {
    const min = Math.floor(number / 60000)
    return min
  }
  const avgSessionTime = (sessionTime: number, sessionNumber: number) =>
    convertSessionTimeToMin(sessionTime)
  const bounceRate = (bounce: number, sessionNumber: number) => {
    const rate = Math.round(bounce / sessionNumber) * 100
    return rate
  }

  return (
    <>
      <div>
        <div className="flex items-baseline space-x-5  pr-10 mb-6">
          <Subtitle className="flex-shrink-0">Web</Subtitle>
          <p className="h-px w-full border border-dashed border-alpha-darker-color" />
        </div>
        <ul className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-x-5 gap-y-10">
          <li className="flex flex-col items-center  text-center">
            <Visitors
              style={{ fontSize: '24px', marginBottom: '18px' }}
              color="var(--alpha-dark-color)"
            />
            <Stats>{analytics && analytics?.sessionNumber - analytics?.logins}</Stats>
            <TextBody>Visitors</TextBody>
          </li>
          <li className="flex flex-col items-center text-center">
            <Logins
              style={{ fontSize: '24px', marginBottom: '18px' }}
              color="var(--alpha-dark-color)"
            />
            <Stats className="text-xl text-secondary-color font-semibold">
              {analytics?.logins}
            </Stats>
            <TextBody>Logins</TextBody>
          </li>
          <li className="flex flex-col items-center text-center">
            <Mobile
              style={{ fontSize: '24px', marginBottom: '18px' }}
              color="var(--alpha-dark-color)"
            />
            <Stats className="text-xl text-secondary-color font-semibold">
              {analytics?.mobileSessionNumber}
              <span className="font-normal">%</span>
            </Stats>
            <TextBody>From mobile</TextBody>
          </li>
          <li className="flex flex-col items-center text-center">
            <Duration
              style={{ fontSize: '24px', marginBottom: '18px' }}
              color="var(--alpha-dark-color)"
            />
            <Stats className="text-xl text-secondary-color font-semibold">
              {analytics && avgSessionTime(analytics?.sessionTime, analytics?.sessionNumber)}
              <span className="font-normal">min</span>
            </Stats>
            <TextBody>
              Avg session <br />
              duration
            </TextBody>
          </li>
          <li className="flex flex-col items-center text-center">
            <Pages
              style={{ fontSize: '24px', marginBottom: '18px' }}
              color="var(--alpha-dark-color)"
            />
            <Stats className="text-xl text-secondary-color font-semibold">
              {analytics?.pageVisited && Math.round(analytics?.pageVisited)}
            </Stats>
            <TextBody>
              Pages visited <br />
              on avg
            </TextBody>
          </li>
          {/* <li className="flex flex-col items-center text-center">
            <Rate
              style={{ fontSize: '24px', marginBottom: '18px' }}
              color="var(--alpha-dark-color)"
            />
            <Stats className="text-xl text-secondary-color font-semibold">
              {analytics && bounceRate(analytics?.bounce, analytics?.sessionNumber)}
              <span className="font-normal">%</span>
            </Stats>
            <TextBody>Bounce rate</TextBody>
          </li> */}
        </ul>
      </div>
    </>
  )
}
