import { StackPageType } from 'utilities/hooks'
import { UserRole } from 'constants/UserConstants'
import { paths } from './paths'
import { Container } from './pages/container'

export const routes = {
  propertyDetails: {
    path: paths.propertyDetails(),
    exact: false,
    component: Container,
    requiredRoles: [UserRole.ADMIN, UserRole.AGENT, UserRole.CLIENT],
  },
}

export const PropertyDetailsRoutes: { [page: string]: React.ComponentType<StackPageType> } = {}
