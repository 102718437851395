import React, { ReactElement, useCallback, useContext } from 'react'
import { PageFooter } from 'views/shared/components/page-footer'
import { Map } from 'views/map'
import { AddressSearch } from 'views/shared/components/address-search/AddressSearch'
import { ToggleInput } from 'views/shared/components/ui-form/toggle-input/ToggleInput'
import { ToggleList, ToggleMap } from 'views/shared/components/icons'
import { Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom'
import { paths } from 'paths'
import { NeighbourhoodList } from 'views/soldReport/components/NeighbourhoodList'
import { AuthContext } from 'views/authentication/contexts/AuthContext'
import { Content, Root, PortalBar } from './Portal.styled'

export const Portal = (): ReactElement => {
  const location = useLocation()
  const history = useHistory()
  const authUser = useContext(AuthContext)
  const child = location.pathname.indexOf('/list') > -1 ? 'list' : 'map'
  const onToggleChange = useCallback(
    (value: string) => {
      history.push(paths.portal(value))
    },
    [history],
  )
  const redirectUser = () => {
    let path = paths.signIn()
    if (authUser.authUser) {
      if (authUser.reservedPath) {
        path = authUser.reservedPath
      } else {
        path = paths.portal('map')
      }
    } else {
      authUser.setReservedPath(location.pathname)
      path = paths.signIn()
    }

    // const path = authUser.authUser && authUser.reservedPath ? authUser.reservedPath : paths.signIn()
    return <Redirect to={path} />
  }

  return (
    <Root>
      <PortalBar>
        <ToggleInput
          onChange={onToggleChange}
          value={child || 'map'}
          items={[
            {
              label: (
                <>
                  <ToggleMap className="sm:mr-2" />
                  <span className="hidden sm:inline">Map</span>
                </>
              ),
              value: 'map',
            },
            {
              label: (
                <>
                  <ToggleList className="sm:mr-2" />
                  <span className="hidden sm:inline">List</span>
                </>
              ),
              value: 'list',
            },
          ]}
        />
        <div className="flex-grow px-5">
          <AddressSearch title="Search for a Neighbourhood" />
        </div>
      </PortalBar>
      <Content>
        <div />
        <Switch>
          <Route path={paths.portal('map')} exact={false} component={Map} />
          <Route path={paths.portal('list')} exact={false} component={NeighbourhoodList} />
          <Route>
            <Redirect to={paths.portal('map')} />
            {redirectUser()}
          </Route>
        </Switch>
      </Content>
      <PageFooter />
    </Root>
  )
}
