import { HomeValuationAnnualReport } from 'types'

export type HeaderProps = {
  address: string
}
export const header = (data: HomeValuationAnnualReport.Valuation) => {
  const { propertyDetails } = data
  const template = /* svg */ `
  <text fill="black" xml:space="preserve" style="white-space: normal" font-family="Montserrat" font-size="10" font-weight="bold" letter-spacing="0em">
    <tspan x="25" y="45">Annual Report.</tspan>
  </text>
  <text fill="#62727A" xml:space="preserve" style="white-space: normal" font-family="Montserrat" font-size="10" letter-spacing="0em">
    <tspan x="25" y="60">${propertyDetails.summary.address} ${
    propertyDetails.summary.unit || ''
  }</tspan>
  </text>
  `
  return template
}
