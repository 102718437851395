import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { DateHelper, PdfHelper } from 'utilities'
import { footer } from '../footer'
import { header } from '../header'

export const addAgent = async (
  doc: PDFKit.PDFDocument,
  data: HomeValuationAnnualReport.Valuation,
) => {
  const aData = {
    agent: data.agent,
    user: data.user,
    address: data.homeValue.displayAddress,
  }
  const date = DateHelper.currentDateStr()
  doc.addPage()
  const img = {
    logo: await PdfHelper.getImage(
      data.agent.logo ? `logos/${data.agent.logo}` : '/assets/castl.svgaa',
    ),
    headshot: await PdfHelper.getImage(
      data.agent.headshot ? `headshots/${data.agent.headshot}` : '/assets/default-profile.jpg',
    ),
  }

  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">
  ${header(data)}
  <!--<rect x="26" y="103" width="152" height="152" fill="#000000" />-->
  ${img.headshot(26, 103, 152, 'auto')}
  <image xlink:href="${aData.agent.headshot}"  x="26" y="103" width="152" height="152"/>
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="10" font-style="italic" letter-spacing="0em">
  <tspan x="195" y="252">${DateHelper.formatDateToLong(date)}</tspan>
  <tspan x="195" y="303">${aData.user.firstName},</tspan>
 <tspan x="195" y="337">Congratulations on the anniversary of your home purchase. </tspan>
  <tspan x="195" y="354">I created this annual report to give you insights into what </tspan>
  <tspan x="195" y="371">has happened to the value of your home over the last 12 months,</tspan>
  <tspan x="195" y="388">along with which homes have sold in your neighbourhood. </tspan>
  <tspan x="195" y="405">I have also included the change in value since you bought it.</tspan>
  <tspan x="195" y="423">To calculate how much equity you have in your home, </tspan>
  <tspan x="195" y="440">you must subtract your outstanding mortgage from that change in value.</tspan>

  <tspan x="195" y="473">I hope you find this information of value, and I would love to sit down</tspan>
  <tspan x="195" y="490">with you to discuss it in more depth.</tspan>

  <tspan x="195" y="541">Regards,</tspan>

  <tspan x="195" y="575">${aData.agent.firstName} ${aData.agent.lastName}</tspan>
</text>
  ${await footer(data)}
</svg>
    `
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
