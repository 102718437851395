import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { HomeValuationServices } from 'services/homeValuation'
import { HomeValuationChartData, HomeValueEstimation } from 'types'
import { CurrencyHelper } from 'utilities/helpers/currencyHelper'
import MemoSvgClock from 'views/shared/components/icons/Clock'
import { Share } from 'views/shared/components/share'
import * as moment from 'moment-timezone'
import { getInstancePrice } from '../shared'
import { ChartPeriodSelector } from './ChartPeriodSelector'
import {
  generateChartData,
  homeValuationChartOptions,
  sortDateByMonthDay,
} from './homeValuationChartOptions'
import { DisplayClock } from './styles'

interface HomeValuationSnapshotProps {
  valuationState: HomeValueEstimation
}

export const HomeValuationSnapshot = ({ valuationState }: HomeValuationSnapshotProps) => {
  const [period, setPeriod] = useState<string | null>(null)
  const [chartData, setChartData] = useState<any>()
  const [priceDiff, setPriceDiff] = useState<number | null>(null)
  const [percentageDiff, setPercentageDiff] = useState<number | null>(null)

  useEffect(() => {
    setPeriod(valuationState.lastPurchasePrice ? 'sinceSold' : '1y')
    if (valuationState.lastPurchasePrice && valuationState.instanceValue) {
      setPriceDiff(valuationState.instanceValue - valuationState.lastPurchasePrice)
      setPercentageDiff(
        ((valuationState.instanceValue - valuationState.lastPurchasePrice) /
          valuationState.lastPurchasePrice) *
          100,
      )
    }
  }, [])

  useEffect(() => {
    if (period === null) {
      return
    }
    const initChartData = (data: HomeValuationChartData) => {
      const isShortTerm: boolean = period ? period === '1w' || period === '1m' : false
      const sortedData = sortDateByMonthDay(data.chart.sort((a, b) => a[0] - b[0]))
      const oldPrice =
        period === 'sinceSold' && valuationState.lastPurchasePrice
          ? valuationState.lastPurchasePrice
          : sortedData[0]?.[1]?.[0]
      if (oldPrice && valuationState.instanceValue) {
        setPriceDiff(valuationState.instanceValue - oldPrice)
        setPercentageDiff(((valuationState.instanceValue - oldPrice) / oldPrice) * 100)
      }

      setChartData(generateChartData(sortedData, isShortTerm))
    }

    const calculateFromDate = (per: string) => {
      const today = moment.tz().utc()
      switch (per) {
        case '1w':
          return today.add(-8, 'days').valueOf()
        case '1m':
          return today.add(-1, 'month').valueOf()
        case '6m':
          return today.add(-6, 'months').valueOf()
        case '1y':
          return today.add(-1, 'year').valueOf()
        case '5y':
          return today.add(-5, 'years').valueOf()
        case 'sinceSold':
          if (valuationState.lastPurchaseDate !== undefined) {
            return valuationState.lastPurchaseDate
          }
          return today.add(-30, 'years').valueOf()
        default:
          return today.add(-30, 'years').valueOf()
      }
    }

    const handleChartPeriod = () => {
      const from = calculateFromDate(period)

      valuationState.id &&
        HomeValuationServices.getHomeValuationChart(valuationState.id, `${from}`, undefined).then(
          (data) => {
            try {
              initChartData(data)
            } catch (e) {
              console.log(e)
            }
          },
        )
    }

    handleChartPeriod()
  }, [period])

  const priceDiffLabelColorStyle = () => {
    let color = ''
    if (priceDiff !== null && priceDiff > 0) color = 'text-accent-color'
    if (priceDiff !== null && priceDiff < 0) color = 'text-warning-color'
    return color
  }

  const percentageString = (percent: number): string =>
    `${percent >= 0 ? '+' : ''}${percent.toFixed(1)}`

  return (
    <>
      <div className="flex flex-col w-full justify-between">
        <div className="flex items-center space-x-2 md:hidden">
          <MemoSvgClock width={13} height={13} />
          <div className="text-xs text-gray-700 whitespace-nowrap">
            {moment.tz(valuationState.instanceValueDate, 'UTC').format('MMM DD')}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-end">
            <div className="text-2xl md:text-5xl font-bold md:mr-3">
              {getInstancePrice(valuationState.instanceValue)}
            </div>
            <div className={[priceDiffLabelColorStyle(), 'flex items-end'].join(' ')}>
              <div className="text-md md:text-3xl font-bold mx-2 md:mx-4">
                {valuationState &&
                  (priceDiff ? `${CurrencyHelper.currencyFormat(priceDiff, true, '$')}` : 'N/A')}
              </div>
              <div className="text-xs md:text-xl mr-3 whitespace-nowrap">
                {valuationState &&
                  (percentageDiff ? `${percentageString(percentageDiff)}%` : 'N/A')}
              </div>
            </div>
            <DisplayClock>
              <div className="flex items-center">
                <MemoSvgClock />
              </div>
              <div className="text-gray-700 text-md whitespace-nowrap">
                {moment.tz(valuationState.instanceValueDate, 'UTC').format('MMM DD')}
              </div>
            </DisplayClock>
          </div>
          <div className="h-10">
            <Share
              title="Share this Home Valuation"
              text=""
              url={`${window.location.origin}/home-valuation-summary/${valuationState.id}`}
              files={[]}
            />
          </div>
        </div>
        {chartData && (
          <div className="my-8">
            <div className="h-52 md:h-80 w-full">
              <Line
                id="evaluationChart"
                data={chartData as any}
                options={homeValuationChartOptions() as any}
              />
            </div>
          </div>
        )}
        <ChartPeriodSelector
          setChartPeriod={setPeriod}
          purchaseDate={valuationState.lastPurchaseDate}
        />
      </div>
    </>
  )
}
