import React from 'react'
import { CapsuleButton } from './SearchBar.styled'

interface CapsuleButtonComponentProps {
  name: string
  value: any
  onClick: () => void
  onBlur: React.FocusEventHandler<HTMLButtonElement> | undefined
}

export const CapsuleButtonComponent: React.FC<CapsuleButtonComponentProps> = ({
  name,
  onClick,
  onBlur,
  value,
}) => {
  const isActive = (type: string): boolean => type === 'Active'
  return (
    <CapsuleButton
      type="button"
      onClick={onClick}
      onBlur={onBlur}
      value={value}
      color={isActive(name) ? 'var(--primary-color)' : 'black'}
      roundDirection={isActive(name) ? 'left' : 'right'}
    >
      <div className="flex justify-between items-center">
        <div className="mx-1">{name}</div>
        <div
          className={`border border-1 rounded-md px-2 py-1 ${
            isActive(name) ? 'text-primary-color' : 'text-black'
          } font-semibold text-sm bg-white`}
        >
          {value}
        </div>
      </div>
    </CapsuleButton>
  )
}
