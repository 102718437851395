import { PropertyDetails } from 'types/Property'
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { PdfHelper } from 'utilities'
import { PdfImageHelper } from '../common/images'
import { ListContext } from '../common/block'
import { footer } from '../footer'
import { header } from '../header'

export type PropertyGalleryProps = {
  property: PropertyDetails
}
export const addPropertyGallery = async (
  doc: PDFKit.PDFDocument,
  property: HomeValuationAnnualReport.PropertyDetails,
  data: HomeValuationAnnualReport.Valuation,
) => {
  const images = await PdfImageHelper.getPropertyImages(property.summary)
  doc.addPage()

  const margin = 18
  const img = { w: 256, h: 150 }
  const gallery = new ListContext(
    doc,
    images,
    { w: img.w + margin, h: img.h + margin },
    {
      x: 15,
      y: 80,
    },
  )
    .setGrid(2)
    .moveTo(15, 80)
  gallery.addRenderItem(
    (ctx, image) => `
    <rect width="256" height="150" fill="#D9D9D9"/>
    ${image(0, 0, 256, 150)}
  `,
  )
  // gallery.render()
  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">
    ${header(data)}
    ${gallery.render()}
    <!--${await footer(data)}-->
  </svg>`
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })

  return doc
}
