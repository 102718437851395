import { Unsubscribe } from '.'
import { paths } from './paths'

export const routes = {
  Unsubscribe: {
    path: paths.Unsubscribe(),
    exact: false,
    component: Unsubscribe,
  },
}
