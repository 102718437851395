/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { AdminUserAnalytics } from 'views/dashboard/types/analytics'
import { Registered, Waitlist } from 'views/shared/components/icons'
import { AnalyticsServices } from 'services/analytics'
import { Subtitle, StatsNumber, StatsTitle, StatsSubtitle } from './styles'

export const UsersAnalytics = () => {
  const [analytics, setAnalytics] = useState<AdminUserAnalytics>()

  useEffect(() => {
    const fetchAnalytics = async () => {
      await AnalyticsServices.getUsersAnalytics()
        .then((res) => {
          setAnalytics(res)
        })
        .catch((e) => {
          console.error(e)
        })
    }
    fetchAnalytics()
  }, [])
  return (
    <>
      <div className="mb-16 mt-10 space-y-5">
        <Subtitle fontWeight={400}>
          Release 1 <span className="font-semibold">Users</span>
        </Subtitle>

        <ul className="grid grid-cols-1 sm:mx-0 sm:grid-cols-2 gap-6 max-w-3xl">
          <li className="flex items-center p-5 w-full space-x-10 md:px-10 md:py-3.5 bg-accent-soft-color rounded-lg">
            <Waitlist style={{ fontSize: '40px' }} color="var(--alpha-dark-color)" />
            <div>
              <StatsNumber>{analytics?.waitingLists}</StatsNumber>
              <StatsTitle fontWeight={600}>Users on waitlist</StatsTitle>
              <StatsSubtitle>
                {analytics?.waitingListLastWeek && analytics?.waitingListLastWeek > 0
                  ? `+${analytics?.waitingListLastWeek}`
                  : `${analytics?.waitingListLastWeek}`}
                <span> last week</span>
              </StatsSubtitle>
            </div>
          </li>
          <li className="flex items-center p-5 w-full space-x-10 md:px-10 md:py-3.5 bg-accent-soft-color rounded-lg">
            <Registered style={{ fontSize: '40px' }} color="var(--alpha-dark-color)" />
            <div>
              <StatsNumber>
                {analytics && ((analytics?.registerredUsers / analytics?.invites) * 100).toFixed(0)}
                %
              </StatsNumber>
              <StatsTitle fontWeight={600}>Registered</StatsTitle>
              <StatsSubtitle>
                {analytics?.invites}
                <span> invites sent</span>
              </StatsSubtitle>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}
