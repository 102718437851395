import { atom, useAtom } from 'jotai'
import { useState } from 'react'
import { HomeValuationServices } from 'services/homeValuation'
import { PropertyServices } from 'services/property/PropertyServices'
import {
  AddressSuggestionQueryType,
  HomeValuationInfo,
  HomeValueEstimation,
  KeyLabel,
  ValuationConfirmationForm,
} from 'types'
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { PropertyListing } from 'types/Property'
import { stateValidationStore, useStateValidation } from 'utilities/hooks/validationState'

const ERROR_UNIT_NUMBER_IS_REQUIRED = 1450

type HomeImprovement = {
  id: string
  area: string | undefined
  cost: string | undefined
  completionDate: string | undefined
}

const initial = {
  propertyDetails: {
    mls: '',
    unit: '',
    soldDate: null as number | null,
    soldPrice: null as number | null,
    bedroom: null as number | null,
    bathroom: null as number | null,
    address: '',
    tax: null as number | null,
    taxYear: null as number | null,
    improvements: [] as HomeImprovement[],
  },
  improvement: {
    area: '',
    cost: '',
    completionDate: '',
  } as HomeImprovement,
}
const store = {
  propertyDetails: stateValidationStore(initial.propertyDetails),
  improvement: stateValidationStore(initial.improvement),
}
const propertyData = atom<Partial<PropertyListing> | null>(null)
const missingUnitData = atom<boolean>(false)
const propertyImage = atom<string[] | null>(null)
const valuationData = atom<HomeValuationInfo | null>(null)
const estimationData = atom<HomeValueEstimation | null>(null)
const valuationListData = atom<HomeValueEstimation[] | null>(null)
const selectedOptionData = atom<KeyLabel | null>(null)
const ValuationConfirmation = atom<ValuationConfirmationForm | null>(null)
const annualReportData = atom<HomeValuationAnnualReport.Valuation | null>(null)

export const useHomeValuationStore = () => {
  const [propertyState, setPropertyState] = useAtom(propertyData)
  const [missingUnit, setMissingUnit] = useAtom(missingUnitData)
  const [propertyImageState, setPropertyImageState] = useAtom(propertyImage)
  const [valuationInfoState, setValuationInfoState] = useAtom(valuationData)
  const [valuationListState, setValuationListState] = useAtom(valuationListData)
  const [estimationState, setEstimationState] = useAtom(estimationData)
  const [selectedOption, setSelectedOption] = useAtom(selectedOptionData)
  const [valuationConfirmationState, setValuationConfirmationState] = useAtom(ValuationConfirmation)
  const [annualReportState, setAnnualReportState] = useAtom(annualReportData)

  const [options, setOptions] = useState<KeyLabel[]>([])
  const [notFound, setNotFound] = useState<boolean>(false)

  const propertyForm = useStateValidation({
    store: store.propertyDetails,
    validation: {},
  })

  const restoreState = async () => {
    propertyForm.setFieldsData({
      mls: '',
      unit: '',
      soldDate: null as number | null,
      soldPrice: null as number | null,
      bedroom: null as number | null,
      bathroom: null as number | null,
      address: '',
      tax: null as number | null,
      taxYear: null as number | null,
      improvements: [],
    })
  }

  const fetchPropertyDetails = async (id: string, unit?: string) => {
    const propertyResults = await HomeValuationServices.getLastListing(id, unit)
      .then((result) => {
        setPropertyState(result)
        setMissingUnit(false)
        return result
      })
      .catch((e) => {
        if (e.code === ERROR_UNIT_NUMBER_IS_REQUIRED) {
          setMissingUnit(true)
          throw e
        } else if (e.code === 404) {
          setMissingUnit(false)
        } else {
          throw e
        }
      })
    return propertyResults
  }

  const findAddress = async (e: AddressSuggestionQueryType) => {
    const result = await HomeValuationServices.findAddress(e)?.then((apiResults) => {
      const suggestions = (apiResults?.suggestions || []).filter(
        (s) => s.matchLevel === 'houseNumber',
      )
      return { ...apiResults, suggestions }
    })
    if (!result || !result.suggestions) return
    if (result && result.suggestions && result.suggestions.length === 0) {
      setNotFound(true)
      setOptions([])
    } else {
      setNotFound(false)
      setOptions(
        (result.suggestions || []).map((item) => ({
          key: item.locationId,
          label: `${item.address.houseNumber || ''} ${item.address.street || ''}, ${
            item.address.district || ''
          } - ${item.address.city || ''} - ${item.address.state}`,
        })),
      )
    }
  }

  const findAddressByMls = (mls: string) => {
    PropertyServices.addressTextSearch(mls).then((res) => {
      setOptions(
        (res?.listings || []).map((item) => ({
          key: item.mls,
          label: item.address,
        })),
      )
    })
  }

  // Improvements
  const addImprovement = async () => {
    const { improvements } = propertyForm.state
    improvements.push({
      id: `${improvements.length}-${Date.now()}`,
      area: ``,
      completionDate: ``,
      cost: ``,
    })
    propertyForm.setField('improvements', improvements, true)
  }
  const updateImprovement = async () => {}

  const deleteImprovement = async (id: string) => {
    const newList = propertyForm.getField('improvements')
    propertyForm.setField(
      'improvements',
      newList.filter((item) => item.id !== id),
      true,
    )
  }

  const updateValuationConfirmation = (
    key: keyof ValuationConfirmationForm,
    value: string | number,
  ) => {
    setValuationConfirmationState((prev) => ({ ...prev, [key]: value }))
  }

  const resetValuationConfirmation = () => {
    setValuationConfirmationState(null)
  }

  return {
    valuationInfoState,
    setValuationInfoState,
    valuationListState,
    setValuationListState,
    estimationState,
    setEstimationState,
    propertyState,
    setPropertyState,
    missingUnit,
    setMissingUnit,
    propertyImageState,
    setPropertyImageState,
    fetchPropertyDetails,
    findAddress,
    findAddressByMls,
    options,
    selectedOption,
    setSelectedOption,
    restoreState,
    notFound,
    valuationConfirmationState,
    updateValuationConfirmation,
    resetValuationConfirmation,
    property: {
      form: propertyForm,
    },
    improvements: {
      add: addImprovement,
      delete: deleteImprovement,
      update: updateImprovement,
    },
    annualReportState,
    setAnnualReportState,
  }
}
