import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { KeyLabel } from 'types/KeyLabel'
import { PropertyHistory } from 'types/Property'
import { CurrencyHelper, DateHelper } from 'utilities'
import { PrimaryNegativeButton } from 'views/shared/components/ui-form'

const TH = styled.th`
  background-color: var(--alpha-color);
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`

interface ListingHistoryProps {
  histories?: PropertyHistory[]
  current?: string
}

export const PropertyEventLabel: KeyLabel[] = [
  {
    key: 'forSale',
    label: 'For Sale',
  },
  {
    key: 'sold',
    label: 'Sold',
  },
  {
    key: 'forLease',
    label: 'For Lease',
  },
  {
    key: 'leased',
    label: 'Leased',
  },
  {
    key: 'terminated',
    label: 'Terminated',
  },
  {
    key: 'unavailable',
    label: 'Expired',
  },
  {
    key: 'suspended',
    label: 'Suspended',
  },
]

export const ListingHistory = ({ histories, current }: ListingHistoryProps): ReactElement => {
  const route = useHistory()
  const [historySize, setHistorySize] = useState(5)
  const types = ['Date', 'Status', 'Price', 'Listing ID']
  const onLoadMore = () => {
    histories && historySize < histories.length
      ? setHistorySize(histories.length)
      : setHistorySize(5)
  }

  return (
    <>
      <div className="border border-primary-soft-color rounded-lg p-5 overflow-x-auto box-border">
        <div className="font-semibold text-xl text-left pb-5">Listing History</div>
        <table className="w-full py-2 text-left">
          <thead className="bg-alpha-color">
            <tr className="text-sm">
              {types.map((type) => (
                <TH key="type" scope="col" className="px-2 py-2.5 font-semibold">
                  {type}
                </TH>
              ))}
            </tr>
          </thead>
          <tbody className="font-normal whitespace-nowrap text-base text-left">
            {histories
              ?.sort((a, b) => b.date - a.date)
              ?.map(
                (history, index) =>
                  historySize > index && (
                    <tr key={history.mls} className="text-left">
                      <td className="text-default-color p-2">
                        {DateHelper.formatDateToDayMonthYear(history.date)}
                      </td>
                      <td className="text-default-color p-2">
                        {PropertyEventLabel.find((event) => event.key === history.event)?.label}
                      </td>
                      <td className="p-2 font-semibold text-secondary-color">
                        {CurrencyHelper.toRoundedShort(history.price)}
                      </td>
                      {current === history.mls && (
                        <td className="text-default-color font-semibold p-2 text-sm">
                          {' '}
                          MLS® {current}
                        </td>
                      )}
                      {current !== history.mls && (
                        <button
                          type="button"
                          className="p-2 font-semibold text-sm text-primary-color underline"
                          onClick={() => {
                            route.push(`/property-details/?=${history.mls}`, {
                              from: window.location.pathname,
                            })
                          }}
                        >
                          MLS® {history.mls}
                        </button>
                      )}
                    </tr>
                  ),
              )}
          </tbody>
        </table>
        <div className="sm:max-w-xs mx-auto">
          {histories && histories.length > 5 && (
            <PrimaryNegativeButton onClick={onLoadMore}>
              {histories && historySize < histories.length ? 'Load More' : 'Collapse'}
            </PrimaryNegativeButton>
          )}
        </div>
      </div>
    </>
  )
}
