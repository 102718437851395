import React, { Fragment, Key, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useHistory } from 'react-router-dom'
import { PropertyListing } from 'types/Property'
import { DateHelper } from 'utilities/helpers/dateHelper'
import { Bed } from 'views/shared/components/icons'
import { TextBody } from 'views/shared/components/ui-form/styled'
import { SoldReportServices } from 'services/soldReport'
import { PropertyType } from 'types'
import { PropertyServices } from 'services/property'
import Photo from 'views/shared/components/icons/Photo'
import Bath from 'views/shared/components/icons/Bath'
import Parking from 'views/shared/components/icons/Parking'
import { useNeighbourhoods } from 'views/map/hooks'
import { CurrencyHelper } from 'utilities'
import { ClusterModalWrapper } from './styles'

export type PropertyMapClusterProps = {
  properties?: PropertyListing[] | null
  onClose?: () => void
  isOpen?: boolean
  imageBaseUrl: string | null
}

export const PropertyMapCluster = ({
  properties,
  onClose,
  imageBaseUrl,
}: PropertyMapClusterProps) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const dialogRef = useRef(null)
  const [propertyImages, setPropertyImages] = useState<{ [mls: string]: string[] }>({})
  const { neighbourhoods } = useNeighbourhoods()
  const propertyTypes = [
    'detatched',
    'semiDetatched',
    'freeholdTownhouse',
    'condo',
    'condoTownhouse',
    'multi',
  ]
  const propertiesNeighbourhood = (properties || [])
    .map((p) => p.neighbour)
    .map((nId) => {
      const neighbourhood = neighbourhoods.find((item) => nId === item.id)
      const neighbourhoodName = neighbourhood ? neighbourhood.name : ''
      return neighbourhoodName
    })

  useEffect(() => {
    if (properties) {
      setOpen(true)
      properties.sort((a, b) => propertyTypes.indexOf(a.type!) - propertyTypes.indexOf(b.type!))
    } else {
      setOpen(false)
    }
  }, [properties])

  useEffect(() => {
    if (properties) {
      setPropertyImages(
        properties.reduce(
          (obj, property) => ({
            ...obj,
            [property.mls]: PropertyServices.getPropertyImageURLs(property, imageBaseUrl),
          }),
          {},
        ),
      )
    } else {
      setPropertyImages({})
    }
  }, [properties])

  const closeHandle = () => {
    setOpen(false)
    setTimeout(() => {
      if (onClose) onClose()
    }, 300)
  }
  const onListingClick = (p: string) => {
    history.push(`/property-details/?=${p}`, { from: window.location.pathname })
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto bg-transparent"
        open={open}
        onClose={closeHandle}
        initialFocus={dialogRef}
      >
        <div className="flex flex-col h-full items-center justify-center align-middle px-4 text-center sm:block sm:p-0 my-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ClusterModalWrapper className="inline-block bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all align-middle z-50 scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
              {properties && (
                <>
                  {properties.map((property, index) => (
                    <ul key={property.mls} className="p-3">
                      {property && (
                        <li
                          onClick={() => onListingClick(property?.mls)}
                          aria-hidden="true"
                          key={property.mls}
                          className="w-full lg:flex lg:items-center border border-primary-soft-color hover:border-primary-color rounded-lg shadow-xl"
                        >
                          <div className="relative">
                            <div className=" h-44 lg:w-44 ">
                              <img
                                className="block h-full w-full object-cover rounded-l-lg"
                                alt=""
                                src={
                                  propertyImages[property.mls] && propertyImages[property.mls][0]
                                    ? propertyImages[property.mls][0]
                                    : '../assets/house.svg'
                                }
                              />
                            </div>
                            <button
                              type="button"
                              className="flex items-center space-x-1 absolute bottom-0 right-0 cursor-pointer px-2 bg-default-color rounded-lg m-2"
                            >
                              <span className="font-sm font-semibold text-white">
                                {propertyImages[property.mls]
                                  ? propertyImages[property.mls].length
                                  : 0}
                              </span>
                              <Photo color="white" style={{ fontSize: '16px' }} />
                            </button>
                          </div>
                          <div className="p-2 appearance-none w-full text-left">
                            {property.opens && (
                              <>
                                <div className="pb-2">
                                  <TextBody
                                    color="var(--warning-color)"
                                    fontSize={12}
                                    fontWeight={600}
                                  >
                                    Open House
                                  </TextBody>
                                  {property.opens.map((o) => (
                                    <TextBody
                                      key={o}
                                      color="var(--warning-color)"
                                      fontSize={12}
                                      fontWeight={300}
                                    >
                                      {o}
                                    </TextBody>
                                  ))}
                                </div>
                              </>
                            )}
                            {property.soldDate ? (
                              <>
                                <div className="pb-1">
                                  <TextBody fontSize={14}>
                                    {DateHelper.formatDateToDayMonthYear(property.soldDate) || null}
                                  </TextBody>
                                </div>
                                <p className="text-xs">
                                  <span className="text-default-color">Listed for</span>{' '}
                                  <span className="text-secondary-color font-semibold">
                                    $
                                    {property.listingPrice &&
                                      CurrencyHelper.currencyFormat(property.listingPrice)}
                                  </span>
                                </p>
                                <p className="text-warning-color">
                                  <span className="font-semibold text-lg">
                                    $
                                    {property.soldPrice &&
                                      CurrencyHelper.currencyFormat(property.soldPrice)}
                                  </span>
                                  {property.saleType === 'Sale' ? (
                                    <span className="text-sm"> Sold price</span>
                                  ) : (
                                    <span className="text-sm"> Lease price</span>
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <div className="pb-2">
                                  <TextBody fontSize={14}>
                                    {DateHelper.formatDateToDayMonthYear(property.listDate)}
                                  </TextBody>
                                </div>
                                <p className="font-xs">
                                  <span className="text-accent-color font-semibold">
                                    <span>
                                      $
                                      {property.listingPrice &&
                                        CurrencyHelper.currencyFormat(property.listingPrice)}
                                    </span>
                                  </span>
                                </p>
                              </>
                            )}
                            <div className="pb-2">
                              <p className="font-semibold text-sm text-secondary-color">
                                {property.type &&
                                  SoldReportServices.getPropertyTypeLabel(
                                    property.type as PropertyType,
                                  )}
                              </p>
                              <p className="text-sm text-secondary-color">
                                <span>
                                  {property.unit
                                    ? `#${property.unit} - ${property.address}`
                                    : property.address}
                                </span>
                                <br />
                                {propertiesNeighbourhood[index] && (
                                  <span>{propertiesNeighbourhood[index]}, </span>
                                )}
                                <span>{property.municipality}</span>
                              </p>
                            </div>
                            <div className="flex items-center justify-between space-x-3">
                              <ul className="flex items-baseline space-x-3">
                                <li className="flex items-center space-x-2">
                                  <span className="text-sm text-default-color">
                                    {property.bedroom}
                                    {property.brPlus ? `+${property.brPlus}` : ''}
                                  </span>
                                  <Bed color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                                </li>
                                <li className="flex items-center space-x-2">
                                  <span className="text-sm text-default-color">
                                    {property.bathroom}
                                  </span>
                                  <Bath color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                                </li>
                                <li className="flex items-center space-x-2">
                                  <span className="text-sm text-default-color">
                                    {property.parking}
                                  </span>
                                  <Parking
                                    color="var(--default-color)"
                                    style={{ fontSize: '20px' }}
                                  />{' '}
                                </li>
                              </ul>
                              {!(
                                property.footage ||
                                property.landFront ||
                                property.landDepth ||
                                property.lotFront ||
                                property.lotDepth
                              ) ? (
                                <></>
                              ) : (
                                <>
                                  {property.type === 'condo' ||
                                  property.type === 'condoTownhouse' ? (
                                    <p className="text-default-color text-sm">
                                      {property.footage ? <span>{property.footage}sqft</span> : '0'}
                                      {!property.footage &&
                                        !(property.landFront && property.landDepth) && (
                                          <span>
                                            {property.lotFront} - {property.lotDepth}
                                          </span>
                                        )}
                                      {!property.footage &&
                                        !(property.lotFront && property.lotDepth) && (
                                          <span>
                                            {property.landFront} - {property.landDepth}
                                          </span>
                                        )}
                                    </p>
                                  ) : (
                                    <p className="text-default-color text-sm">
                                      {property.footage ? (
                                        <span>{property.footage}sqft</span>
                                      ) : (
                                        <span>
                                          {property.landFront} x {property.landDepth} feet
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  ))}
                </>
              )}
            </ClusterModalWrapper>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
