import moment from 'moment'
import { paths } from 'paths'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HomeValuationServices } from 'services/homeValuation/HomeValuationServices'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValuationInfo, HomeValueEstimation, MonthStr } from 'types'
import { CurrencyHelper, DateHelper } from 'utilities'
import { AddressField } from 'views/homeValuation/components/AddressField'
import { HomeImprovements } from 'views/homeValuation/components/HomeImprovements'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { Spinner } from 'views/shared/components/spinner'
import { DropdownField, PrimaryButton, TextBody } from 'views/shared/components/ui-form'
import { DatePicker } from 'views/shared/components/ui-form/month-picker/DatePicker'
import { NumberCounter } from 'views/shared/components/ui-form/number-counter/NumberCounter'

interface PropertyDetailsProps {
  valuationId?: string
  setShowModal?: (show: boolean) => void
}

export const PropertyDetailsForm = ({ valuationId, setShowModal }: PropertyDetailsProps) => {
  const history = useHistory()
  const [error, setError] = useState<boolean>(false)
  const [addressSelected, setAddressSelected] = useState<boolean>(false)
  const [propertyDetails, setPropertyDetails] = useState<HomeValuationInfo | null>()
  const {
    valuationInfoState,
    setValuationInfoState,
    setValuationListState,
    propertyState,
    improvements,
    setEstimationState,
  } = useHomeValuationStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    !valuationId && valuationInfoState?.locationId
      ? setAddressSelected(true)
      : setAddressSelected(false)
  }, [])

  useEffect(() => {
    if (valuationInfoState?.footageFrom) {
      valuationInfoState.footage = valuationInfoState?.footageFrom
    } else if (typeof valuationInfoState?.footage === 'string') {
      valuationInfoState.footage = (valuationInfoState?.footage as string | undefined)?.split(
        '-',
      )[0] as number | undefined
    }
    setPropertyDetails(valuationInfoState)
  }, [valuationInfoState])

  const disableAddressField = () => {
    const isStep2 = window.location.pathname.includes('/home-valuation/property-details')
    return !!valuationId || isStep2
  }

  const handleUnitChange = (value: string) => {
    setPropertyDetails((prev: any) => prev && { ...prev, unit: value })
  }

  const nextStep = () => {
    history.replace(paths.homeValuation('property-estimation'))
  }

  const filterEmptyImprovements = () => {
    const result = propertyDetails
    let updateHomeImprovements = propertyDetails?.homeImprovements
    updateHomeImprovements = updateHomeImprovements?.filter(
      (improvement) => Object.keys(improvement).length !== 0,
    )
    setPropertyDetails((prev) => prev && { ...prev, homeImprovements: updateHomeImprovements })
    if (result) result.homeImprovements = updateHomeImprovements
    return result
  }

  const onGetEvaluationClick = async () => {
    if (propertyDetails) {
      setIsLoading(true)
      await HomeValuationServices.createHomeValuation(filterEmptyImprovements()!)
        .then(setEstimationState)
        .then(() => nextStep())
        .finally(() => setIsLoading(false))
        .catch(() => setError(true))
    }
  }

  const onUpdateEvaluationClick = () => {
    if (valuationId && propertyDetails) {
      setIsLoading(true)
      HomeValuationServices.updateHomeValuation(valuationId, filterEmptyImprovements()!).then(
        () => {
          try {
            setValuationInfoState(null)
            setShowModal && setShowModal(false)
          } catch (err) {
            console.log('-next step error: ', err)
          } finally {
            setIsLoading(false)
          }
        },
      )
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    }
  }

  const incBedroom = () => {
    setPropertyDetails((prev: any) => ({ ...prev, bedroom: (prev.bedroom || 0) + 1 }))
  }

  const decBedroom = () => {
    setPropertyDetails((prev: any) => ({
      ...prev,
      bedroom: Math.max(0, (prev.bedroom || 0) - 1),
    }))
  }

  const incBathroom = () => {
    setPropertyDetails((prev: any) => ({ ...prev, bathroom: (prev.bathroom || 0) + 1 }))
  }

  const decBathroom = () => {
    setPropertyDetails((prev: any) => ({
      ...prev,
      bathroom: Math.max(0, (prev.bathroom || 0) - 1),
    }))
  }

  const incParking = () => {
    setPropertyDetails((prev: any) => ({ ...prev, parking: (prev.parking || 0) + 1 }))
  }

  const decParking = () => {
    setPropertyDetails((prev: any) => ({
      ...prev,
      parking: Math.max(0, (prev.parking || 0) - 1),
    }))
  }

  const handlePropertyTypeChange = (type: string) => {
    setPropertyDetails((prev: any) => ({ ...prev, type }))
  }

  const handleFootageChange = (value: number) => {
    setPropertyDetails((prev: any) => ({ ...prev, footage: value }))
  }

  const handlePurchasePriceChange = (value: string) => {
    setPropertyDetails((prev: any) => ({
      ...prev,
      purchasePrice: value ? Number(value) : undefined,
    }))
  }

  const handlePurchaseDateChange = (value: any) =>
    setPropertyDetails((prev: any) => ({
      ...prev,
      purchaseDate: moment.utc(value).valueOf(),
    }))

  const renderFootage = (): boolean =>
    propertyDetails?.type === 'condo' || propertyDetails?.type === 'condoTownhouse'

  const getPurchasePrice = () => {
    const purchasePrice = valuationId ? propertyDetails?.purchasePrice : propertyState?.soldPrice
    // purchasePrice ? `$${CurrencyHelper.currencyFormat(purchasePrice)}` : '$'
    return purchasePrice !== undefined ? `$${CurrencyHelper.currencyFormat(purchasePrice)}` : '$0'
  }

  const getButtonLabel = (id: string | undefined) =>
    id ? 'Update your valuation' : 'Get your valuation'

  const isFormValid = (): boolean => {
    if (!propertyDetails || !propertyDetails.bedroom || !propertyDetails.type) {
      return false
    }

    if (propertyDetails.type === 'condoTownhouse' || propertyDetails.type === 'condo') {
      const { footage } = propertyDetails
      return footage !== undefined && footage > 0
    }
    return true
  }

  return (
    <>
      <div className="max-w-sm">
        {valuationId && (
          <div className="text-default-color text-md text-md md:text-xl">
            Update property details and get a new estimate of its market value.
          </div>
        )}
        {!valuationId && (
          <div className="text-default-color text-md md:text-xl">
            Enter the property details and get an estimate of its market value today.
          </div>
        )}
      </div>
      <div className="hidden md:flex md:items-baseline items-start">
        <div className="flex-1 lg:max-w-xs xl:max-w-lg">
          <AddressField
            label={valuationId ? 'Address' : 'Enter your home address*'}
            displayAddress={(propertyDetails && propertyDetails.address) || undefined}
            edit={disableAddressField()}
          />
        </div>
        {(propertyState?.type === 'condoTownhouse' || 'condo') && (
          <div className="w-28">
            <InputViewField
              label="Unit"
              labelType="block"
              edit={!disableAddressField()}
              value={valuationInfoState?.unit}
              stateValidation={improvements}
              onChange={handleUnitChange}
            />
          </div>
        )}
      </div>
      <div className="px-2 space-y-10 sm:space-y-0 flex flex-col items-start justify-start sm:flex-row sm:items-center sm:space-x-5 sm:space-x-10">
        <div className="flex flex-col md:flex-row justify-start w-full md:space-x-5">
          <div className="flex flex-row md:flex-col justify-between">
            <div className="flex items-center font-semibold text-xs md:text-md">Bedroom(s)*</div>
            <NumberCounter
              value={propertyDetails?.bedroom || 0}
              inc={incBedroom}
              dec={decBedroom}
            />
          </div>
          <div className="flex flex-row md:flex-col justify-between">
            <div className="flex items-center font-semibold text-xs md:text-md">Bathroom(s)</div>
            <NumberCounter
              value={propertyDetails?.bathroom || 0}
              inc={incBathroom}
              dec={decBathroom}
            />
          </div>
          <div className="flex flex-row md:flex-col justify-between">
            <div className="flex items-center font-semibold text-xs md:text-md">Parking(s)</div>
            <NumberCounter
              value={propertyDetails?.parking || 0}
              inc={incParking}
              dec={decParking}
            />
          </div>
        </div>
      </div>

      <div className="px-2 space-y-10 sm:space-y-0 flex flex-col items-start justify-start sm:flex-row sm:items-center sm:space-x-1 z-10">
        <div className="flex justify-between md:justify-start md:space-x-5 w-full">
          <div className="flex flex-row md:flex-col justify-between md:justify-start w-full md:w-52">
            <div className="flex items-center text-md font-semibold text-xs md:text-md pr-2">
              Type*
            </div>
            <DropdownField
              multi={false}
              value={propertyDetails?.type}
              onChange={handlePropertyTypeChange}
              options={[
                { key: 'detatched', label: 'Detached' },
                { key: 'semiDetatched', label: 'Semi-Detached' },
                { key: 'freeholdTownhouse', label: 'Townhouse' },
                { key: 'condo', label: 'Condominium' },
                { key: 'condoTownhouse', label: 'Condo Townhouse' },
                { key: 'multi', label: 'Multi-Residential' },
              ]}
              name="propertyTypeUpdate"
            />
          </div>
        </div>
        {renderFootage() && valuationInfoState && (
          <div className="flex flex-row md:flex-col justify-between">
            <div className="flex items-center font-semibold text-xs md:text-md">Footage*</div>
            <InputViewField
              value={propertyDetails?.footage}
              onChange={handleFootageChange}
              type="number"
              labelType="block"
              edit
            />
          </div>
        )}
      </div>

      <div className="px-2 flex flex-col md:flex-row">
        <div className="flex flex-row md:flex-col justify-between">
          <div className="flex items-center font-semibold text-xs md:text-md whitespace-nowrap">
            Purchase Price
          </div>
          <InputViewField
            stateValidation={improvements}
            value={
              propertyDetails?.purchasePrice?.toString() ||
              propertyState?.soldPrice?.toString() ||
              ''
            }
            // value={getPurchasePrice()}
            onChange={handlePurchasePriceChange}
            type="number"
            edit
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between">
          <div className="flex items-center font-semibold text-xs md:text-md">Purchase Date</div>
          <div className="p-2">
            <DatePicker
              name="purchase-date"
              styleType="inputStyle"
              value={
                valuationId
                  ? (DateHelper.formatDateToDayMonthYear(propertyDetails?.purchaseDate) as MonthStr)
                  : (DateHelper.formatDateToDayMonthYear(
                      propertyState?.soldDate || propertyDetails?.purchaseDate,
                    ) as MonthStr)
              }
              onChange={(value) => value && handlePurchaseDateChange(value)}
            />
          </div>
        </div>
      </div>
      {/* Improvements */}
      <HomeImprovements propertyDetails={propertyDetails} setPropertyDetails={setPropertyDetails} />
      <div>
        <PrimaryButton
          className="flex justify-content items-center"
          onClick={valuationId ? onUpdateEvaluationClick : onGetEvaluationClick}
          disabled={isLoading || !isFormValid()}
        >
          <div className="w-full">
            {isLoading ? (
              <Spinner className="h-8 mx-auto" alt="Loading" />
            ) : (
              getButtonLabel(valuationId)
            )}
          </div>
        </PrimaryButton>
      </div>

      <ModalLayer
        onOutsideClick={() => setError(false)}
        show={error}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="flex">
          <div className="md:py-5 xl:py-12">
            <div className="shadow-md space-y-10 max-w-xs min-w-max bg-white w-full rounded-lg border-2 border-primary-color md:border-none md:p-5 xl:p-12">
              <p className="font-semibold text-4xl">Error</p>
              <TextBody>Error happened when creating your home valuation.</TextBody>
              <div>
                <PrimaryButton onClick={() => setError(false)}>Close</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
