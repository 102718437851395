import { CurrencyHelper, DateHelper, PdfHelper } from 'utilities'
import { HomeValuationAnnualReport } from 'types'
import { PropertyServices } from 'services/property'
import { header } from '../header'
import { footer } from '../footer'

type PropertyDetails = Required<HomeValuationAnnualReport.Valuation>['propertyDetails']

const getLabelValue: (propertyDetails: PropertyDetails) => [string, () => string | undefined][] = (
  d,
) => [
  ['Address', () => `${d.summary.unit || ''} ${d.summary.address}`],
  ['Community', () => d.summary.community],
  ['Type', () => d.summary.type],
  ['Style', () => d.style],
  ['Bedrooms', () => d.summary.bedroom?.toString()],
  ['Bathrooms', () => d.summary.bathroom?.toString()],
  ['Basement', () => d.basement],
  ['Heat Type', () => d.heating],
  ['A/C', () => d.cooling],
  ['Garage', () => d.garageType],
  ['Drive', () => d.driveWay],
  ['Park Spaces', () => d.summary.parking?.toString()],
  [
    'Lot Size',
    () =>
      `${d.summary.landFront || d.summary.footageFrom} x ${
        d.summary.landDepth || d.summary.footageTo
      } ft`,
  ],
  ['Approx. Sqft', () => (d.summary.estFootage ? `${d.summary.estFootage?.toFixed(2)} sqft` : '-')],
  ['Pool', () => d.poolType],
  ['Taxes', () => d.tax?.toString() || '-'],
  ['Last Status', () => d.summary.status],
  ['Sold Date', () => d.summary.soldDate && `${DateHelper.formatDateStr(d.summary.soldDate)}`],
  ['Days on Market', () => d.summary.dom?.toString()],
  ['Original Price', () => `$${CurrencyHelper.currencyFormat(d.summary.originalPrice)}`],
  ['List Price', () => `$${CurrencyHelper.currencyFormat(d.summary.listingPrice)}`],
]
const renderLabels = (propertyDetails: PropertyDetails) => {
  const renderedList = PdfHelper.pageList(
    getLabelValue(propertyDetails),
    { h: 12, x: 25, y: 210, pageSize: 100 },
    (label, x, y) => `<tspan x="${x}" y="${y}">${label[0]}</tspan>`,
  )
  return renderedList[0].join('')
}
const renderComparable = async (data: HomeValuationAnnualReport.Valuation) => {
  const p = data.propertyDetails
  if (!p) return ''

  const similarList = [data.propertyDetails, ...data.similarSolds]
  const imagesPathsPromise = (similarList || []).map(async (ps) =>
    PropertyServices.getPropertyImagePaths(
      ps.summary.mls,
      ps.summary.numberOfPic && ps.summary.numberOfPic > 1 ? 1 : ps.summary.numberOfPic,
    ),
  )
  const imagesPaths = await (
    await Promise.all(imagesPathsPromise)
  ).map((v) => (v.length > 0 ? v[0] : '/assets/default-home.jpg'))

  const imagesRender = await Promise.all(imagesPaths.map(async (path) => PdfHelper.getImage(path)))

  const renderedList = PdfHelper.pageList(
    similarList.slice(0, 6),
    { w: 72, h: 72, x: 100, y: 135, columns: 6, pageSize: 100 },
    (similarSold, x, y, index) => /* svg */ `
        <rect x="${x}" y="${y}" width="65" height="65" fill="#D9D9D9"/>
        ${imagesRender[index](x, y, 65, 65)}
        <text x="${x}" fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="6" letter-spacing="0em">
          ${getLabelValue(similarSold).map((labelValue, i) => {
            const value = labelValue[1]() || '-'
            return `<tspan x="${x}" y="${y + 75 + i * 12}">${value}</tspan>`
          })}
        </text>
    `,
  )
  return renderedList[0].join('')
}

export const addComparableSoldProperties = async (
  doc: PDFKit.PDFDocument,
  data: HomeValuationAnnualReport.Valuation,
) => {
  if (!data.propertyDetails) return doc

  doc.addPage()

  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">
  <!--${header(data)}-->
  <svg>
    <!-- title -->
    <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="17" font-weight="bold" letter-spacing="0em"><tspan x="24" y="116.094">Comparable Sold Properties</tspan></text>
    <!-- labels --> 
    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="6" font-weight="bold" letter-spacing="0em">
      ${renderLabels(data.propertyDetails)}</text>
    <!-- items -->
    ${await renderComparable(data)}
  </svg>
  ${await footer(data)}
  </svg>
  `
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
