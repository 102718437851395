import * as React from 'react'

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M9.892 21v-7.746H7V9.942h2.892c0-3.518 0-7.864 7.108-6.77V6.04c-3.077-.325-3.385.62-3.385 3.902H17l-.677 3.312h-2.708V21"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgFacebook = React.memo(SvgFacebook)
export default MemoSvgFacebook
