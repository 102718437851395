import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useDebouncedCallback } from 'use-debounce'

import { passwordErrorMessageBlock } from 'views/shared/components/validations/FormValidationHelper'
import { PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'
import { paths } from 'paths'
import {
  Checkbox,
  PrimaryButton,
  RouteLink,
  TextBodyDark,
  Title,
  TextBody,
  InputField,
  TextBodyWarning,
} from 'views/shared/components/ui-form'

import { AuthException, AuthServices } from 'services/auth'
import { useSearchParams } from 'utilities/hooks/useSearchParams'
import { Loading } from 'views/shared/components/icons'
import { useFocus } from 'utilities/hooks'
import { SignUpData } from '../../types'
import { AuthContext } from '../../contexts'
import { AuthLayout } from '../../components'
import { SideContent } from '../components'

type FormStatus =
  | 'email'
  | 'signIn'
  | 'newPassword'
  | 'signUp'
  | 'verificationCode'
  | 'waitList'
  | 'waitListAccepted'
  | 'waitListExists'

type StatusActions = {
  [key in FormStatus]: (
    values: Partial<SignUpData & { rememberMe?: boolean; verificationCode?: string }>,
  ) => void
}
type StatusValidations = {
  [key in FormStatus]: any
}

interface ResponseError {
  code: string
  message: string
}

const validationSchemas: StatusValidations = {
  email: Yup.object().shape({
    email: Yup.string().email().required(PasswordErrorMsgs.required),
  }),
  signIn: Yup.object().shape({
    email: Yup.string().email().required(PasswordErrorMsgs.required),
    password: Yup.string().required(PasswordErrorMsgs.required),
  }),
  newPassword: Yup.object().shape({
    password: Yup.string()
      .required(PasswordErrorMsgs.required)
      .min(8, PasswordErrorMsgs.minEightCharsMet)
      .matches(
        /^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]\S*)[A-Za-z\d\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]{8,}$/,
        PasswordErrorMsgs.minThreeConditionsMet,
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(PasswordErrorMsgs.required),
  }),
  signUp: Yup.object().shape({
    email: Yup.string().email().required(PasswordErrorMsgs.required),
    password: Yup.string()
      .required(PasswordErrorMsgs.required)
      .min(8, PasswordErrorMsgs.minEightCharsMet)
      .matches(
        /^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]\S*)[A-Za-z\d\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]{8,}$/,
        PasswordErrorMsgs.minThreeConditionsMet,
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(PasswordErrorMsgs.required),
  }),
  verificationCode: Yup.object().shape({
    email: Yup.string().email().required(PasswordErrorMsgs.required),
    verificationCode: Yup.string().required('Verification code is required!'),
  }),
  waitList: Yup.object().shape({
    email: Yup.string().email().required(PasswordErrorMsgs.required),
  }),
  waitListAccepted: Yup.object().shape({}),
  waitListExists: Yup.string().email().required(PasswordErrorMsgs.alreadyExists),
}

const actionButton = {
  email: 'Next',
  signIn: 'Sign In',
  newPassword: 'Change Password',
  signUp: 'Sign Up',
  verificationCode: 'Verify',
  waitList: 'Join our waitlist',
  waitListAccepted: 'Return to website',
  waitListExists: 'Joint our waitlist',
}

export const SignInGuided = () => {
  const { authUser, setAuthUserContext } = useContext(AuthContext)
  const [emailRef, setEmailFocus] = useFocus()
  const [responseErrors, setResponseErrors] = useState<ResponseError[]>([])
  const [formStatus, setFormStatus] = useState<FormStatus>('email')
  const [validationSchema, setValidationSchema] = useState<any>(() => validationSchemas[formStatus])
  const history = useHistory()
  const searchParams = useSearchParams()
  const [loading, setLoading] = useState<boolean>()

  const savedCredentials = AuthServices.getLocalStorageCredentials()
  const defaultValues = {
    name: '',
    email: savedCredentials.email,
    password: savedCredentials.password,
    passwordConfirmation: '',
    rememberMe: savedCredentials.rememberMe,
  }

  const debounceDuration = 300

  const actions: StatusActions = {
    email: useDebouncedCallback(async ({ email }: Partial<SignUpData>) => {
      setLoading(true)

      // check if user exists
      try {
        await AuthServices.signIn(email || '', '123')
      } catch (error) {
        const { code } = error as AuthException
        switch (code) {
          case 'UserNotFoundException':
            try {
              if (await AuthServices.isWhitelist(email || '')) {
                setFormStatus('signUp')
              } else {
                setFormStatus('waitListAccepted')
              }
            } catch (e) {
              await AuthServices.isWaitlist(email || '')
                .then(() => {
                  setFormStatus('waitListExists')
                })
                .catch(() => {
                  setFormStatus('waitList')
                })
            }
            break
          case 'NEW_PASSWORD_REQUIRED':
            setFormStatus('newPassword')
            break
          case 'UserNotConfirmedException':
            setFormStatus('verificationCode')
            break
          case 'NotAuthorizedException':
            setFormStatus('signIn')
            break
          default:
            break
        }
      }
      setLoading(false)
    }, debounceDuration),
    signIn: useDebouncedCallback(async ({ email, password, rememberMe }) => {
      try {
        const response = await AuthServices.signIn(email || '', password || '')
        AuthServices.setLocalStorageCredentials({
          email: email || '',
          password: password || '',
          rememberMe: rememberMe || false,
        })
        setAuthUserContext(response)
      } catch (error) {
        if ((error as AuthException).code === 'NEW_PASSWORD_REQUIRED') {
          setFormStatus('newPassword')
        } else if ((error as ResponseError).code === 'NotAuthorizedException') {
          setResponseErrors([error as ResponseError])
        }
      }
    }, debounceDuration),
    newPassword: useDebouncedCallback(async ({ email, password }) => {
      const response = await AuthServices.SignInNewPassword(email || '', password || '')
      setAuthUserContext(response || null)
    }, debounceDuration),
    signUp: useDebouncedCallback(async (values: Partial<SignUpData>) => {
      const { name, email, password } = {
        ...defaultValues,
        ...values,
      }
      AuthServices.setLocalStorageEmail(email)
      await AuthServices.signUp({
        name,
        email,
        password,
      })
      setFormStatus('verificationCode')
    }, debounceDuration),
    verificationCode: useDebouncedCallback(async ({ email, verificationCode }) => {
      await AuthServices.signUpConfirm(email || '', verificationCode || '')

      setResponseErrors([
        {
          message: 'The Verification code was confirmed. Please sign in!',
          code: 'VERIFICATION_CONFIRMED',
        } as ResponseError,
      ])
      setFormStatus('signIn')
    }, debounceDuration),
    waitList: useDebouncedCallback(async ({ email }: Partial<SignUpData>) => {
      await AuthServices.signUpWaitlist(email || '')
      setFormStatus('waitListAccepted')
    }, debounceDuration),
    waitListAccepted: () => {
      history.push('neighbourhood/map')
    },
    waitListExists: () => {},
  }

  const param = searchParams.get('e')

  // is it base64 encoded? if so decode
  const paramEmail = param?.match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/i)
    ? atob(param || '')
    : param

  const showThisIsNotMe = paramEmail !== null

  const formik = useFormik({
    initialValues: {
      ...defaultValues,
      email:
        paramEmail ||
        (savedCredentials.rememberMe
          ? savedCredentials.email || defaultValues.email
          : defaultValues.email),
      password: savedCredentials.rememberMe ? savedCredentials.password : defaultValues.password,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        await actions[formStatus](values)
      } catch (error) {
        setResponseErrors([error as ResponseError])
      } finally {
        setSubmitting(false)
      }
    },
    validationSchema,
    // validationSchema: Yup.object().shape({
    //   password: Yup.string()
    //     .required(PasswordErrorMsgs.required)
    //     .min(8, PasswordErrorMsgs.minEightCharsMet)
    //     .matches(
    //       /^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[@$!%*?&]\S*)[A-Za-z\d@$!%*?&]{8,}$/,
    //       PasswordErrorMsgs.minThreeConditionsMet,
    //     ),
    //   passwordConfirmation: Yup.string()
    //     .required(PasswordErrorMsgs.required)
    //     .oneOf([Yup.ref('password'), null], PasswordErrorMsgs.passwordsNotMatch),
    // }),
  })

  useEffect(() => {
    setValidationSchema(validationSchemas[formStatus])
  }, [formStatus])

  useEffect(() => {
    if (authUser) {
      history.replace(paths.homepage())
    }
    return () => {}
  }, [authUser, history])

  const onThisIsNotMe = () => {
    formik.setFieldValue('email', '')
    setFormStatus('email')
    setEmailFocus()
  }

  // when we change the email we reset the form to its initial state
  const onEmailChange = useCallback(async () => {
    setFormStatus('email')
    formik.setFieldValue('password', '')
    await formik.submitForm()
    setEmailFocus()
    setResponseErrors([])
  }, [])

  useEffect(() => {
    if (paramEmail || formik.values.email !== '') {
      setFormStatus('email')
      formik.submitForm()
      setEmailFocus()
    }
  }, [])

  const resendVerificationCode = async () => {
    try {
      const { email } = formik.values
      if (email) {
        await AuthServices.signUpResendCode(email)
        setResponseErrors([
          {
            message: 'The confirmation code is sent. Please check your email inbox',
            code: 'CONFIRMATION_SENT',
          } as ResponseError,
        ])
      }
    } catch (error) {
      setResponseErrors([error as ResponseError])
    }
  }

  return (
    <>
      <AuthLayout sideContent={<SideContent />}>
        <div className="space-y-4 mb-10">
          <Title>
            Sign in
            <span className="font-semibold text-xs text-accent-color pl-2">BETA</span>
          </Title>
          <TextBody>
            With your account you can see what properties sold for in your neighbourhood, favourite
            neighbourhoods you want to keep an eye on and much more...
          </TextBody>
        </div>
        <div className="space-y-10">
          <form action="#" method="POST" className="space-y-10" onSubmit={formik.handleSubmit}>
            <div className="space-y-5">
              <InputField
                name="email"
                label={
                  <>
                    Email{' '}
                    {showThisIsNotMe && (
                      <button
                        type="button"
                        className="text-xs text-primary-color font-semibold"
                        onClick={onThisIsNotMe}
                      >
                        (This is not me)
                      </button>
                    )}
                  </>
                }
                type="text"
                formik={formik}
                ref={emailRef}
                onChange={onEmailChange}
                isSubmitting={formik.isSubmitting}
              />
              {formStatus === 'signIn' && (
                <InputField name="password" label="Password" type="password" formik={formik} />
              )}

              {['signUp', 'newPassword'].includes(formStatus) && (
                <>
                  {formStatus === 'newPassword' && (
                    <TextBody fontWeight={600}>
                      For security reasons you need to create a new Password.
                    </TextBody>
                  )}
                  {formStatus === 'signUp' && (
                    <TextBody fontWeight={600}>
                      Create a password to register your account. We will send you an email with a
                      verification code.
                    </TextBody>
                  )}
                  <InputField
                    name="password"
                    label="Password"
                    type="password"
                    formik={formik}
                    customErrorMessage={passwordErrorMessageBlock(
                      formik.touched.password,
                      formik.errors.password,
                    )}
                  />
                  <InputField
                    name="passwordConfirmation"
                    label="Confirm Password"
                    type="password"
                    formik={formik}
                  />
                </>
              )}
              {formStatus === 'verificationCode' && (
                <>
                  <TextBody fontWeight={600}>
                    We&apos;ve sent you a verification code. Please check your email and enter the
                    verification code in the field below.
                  </TextBody>
                  <InputField
                    name="verificationCode"
                    label="Verification Code"
                    type="text"
                    formik={formik}
                  />
                  <div className="text-sm text-right">
                    <button
                      type="button"
                      onClick={resendVerificationCode}
                      className="font-semibold text-primary-color cursor-pointer underline"
                    >
                      Resend code
                    </button>
                  </div>
                </>
              )}

              {formStatus === 'waitList' && (
                <TextBody fontWeight={600}>
                  Join our waitlist! We will notify you as soon as our app is out of Beta.
                </TextBody>
              )}

              {formStatus === 'waitListAccepted' && (
                <>
                  <p className="font-semibold text-4xl text-accent-color">Done!</p>
                  <TextBody fontWeight={600} className="pb-5">
                    We will contact you to register for an account as soon as we&#39;re ready. In
                    the meantime, please continue browsing the site. Thanks!
                  </TextBody>
                </>
              )}
              {formStatus === 'waitListExists' && (
                <TextBodyWarning fontWeight={600}>
                  The e-mail already exists in the waitlist.
                </TextBodyWarning>
              )}

              {responseErrors &&
                formStatus !== 'verificationCode' &&
                responseErrors.map(({ code, message }) => (
                  <div
                    key={code}
                    className={[
                      code === 'VERIFICATION_CONFIRMED' || code === 'CONFIRMATION_SENT'
                        ? `text-accent-color`
                        : `text-warning-color`,
                      'mt-1 text-sm font-semibold',
                    ].join(' ')}
                  >
                    {message}
                  </div>
                ))}
              {formStatus === 'signIn' && (
                <div className="flex items-center justify-between mb-10">
                  <TextBodyDark>
                    <Checkbox
                      id="rememberMe"
                      defaultChecked={formik.values.rememberMe}
                      {...formik.getFieldProps('rememberMe')}
                    />
                    Remember me
                  </TextBodyDark>
                  <RouteLink className="underline" to="/reset-password">
                    Forgot your password?
                  </RouteLink>
                </div>
              )}
            </div>
            <PrimaryButton
              type="submit"
              disabled={formik.isSubmitting || formStatus === 'waitListExists' || loading}
            >
              {formik.isSubmitting && (
                <Loading color="#FFF" fontSize={24} style={{ float: 'left' }} />
              )}
              {actionButton[formStatus]}{' '}
            </PrimaryButton>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}
