import { useAtom } from 'jotai'
import { stores } from './stateValidationStore'
import {
  DefaultStoreState,
  FieldState,
  FieldValidationFunction,
  Message,
  StateField,
  StateValidations,
  Touched,
  UseStateValidationParameters,
  Valid,
} from './types'
import { error } from './validationReturn'

export const useStateValidation = <State extends FieldState>(
  setup: UseStateValidationParameters<State>,
) => {
  const [state, setState] = useAtom(stores[setup.store.id].state) as DefaultStoreState<State>
  let tempState: State = { ...state }
  let tempInternalMessages: Message<State>[] = []
  let tempMessages: Message<State>[] = []
  const [messages, setMessages] = useAtom(stores[setup.store.id].messages) as DefaultStoreState<
    Message<State>[]
  >
  // const [touched, setTouched] = useState({} as Touched<State>)
  const [touched, setTouched] = useAtom(stores[setup.store.id].touched) as DefaultStoreState<
    Touched<State>
  >

  let tempTouched = touched || ({} as Touched<State>)
  // const [valid, setValid] = useState({} as Valid<State>)
  const [valid, setValid] = useAtom(stores[setup.store.id].valid) as DefaultStoreState<Valid<State>>
  let tempValid: Valid<State> | any = valid || {}

  const getField = <Field extends keyof State>(fieldName: Field) =>
    (tempState[fieldName] as State[Field]) || (state[fieldName] as State[Field])

  const addInternalMessage = (
    field: keyof State | StateField,
    message: string,
    type: Message<State>['type'] = 'error',
  ) => {
    if (
      tempInternalMessages.findIndex(
        (m) => m.field === field && m.message === message && m.type === type,
      ) === -1
    ) {
      tempInternalMessages.push({ field, message, type })
    }
  }
  const addMessage = (
    field: keyof State | StateField,
    message: string,
    type: Message<State>['type'] = 'error',
  ) => {
    if (
      tempMessages.findIndex(
        (m) => m.field === field && m.message === message && m.type === type,
      ) === -1
    ) {
      tempMessages.push({ field, message, type })
    }
  }

  const validate = () => {
    tempInternalMessages = []
    const validations = setup.validation || ({} as StateValidations<State>)
    const keys = Object.keys(validations) as (keyof State | StateField)[]
    tempValid = { $state: true } as Valid<State>
    keys.forEach((field) => {
      if (field === '$state') {
        // Full State ------------
        if (!validations.$state) return
        const stateValidation = Array.isArray(validations.$state)
          ? validations.$state
          : [validations.$state]
        stateValidation.forEach((validation) => {
          let validationResponse = validation(tempState)
          if (!validationResponse) return
          if (typeof validationResponse === 'string') validationResponse = error(validationResponse)
          if (validationResponse && typeof validationResponse !== 'boolean')
            addInternalMessage('$state', validationResponse.message, validationResponse.type)
        })
      } else {
        // Field ------------
        tempValid[field] = true
        const fieldValidation = (
          Array.isArray(validations[field]) ? validations[field] : [validations[field]]
        ) as FieldValidationFunction<State, State[typeof field]>[]
        fieldValidation.forEach((validation) => {
          let validationResponse = validation(tempState[field], tempState)
          if (!validationResponse) return
          if (typeof validationResponse === 'string') validationResponse = error(validationResponse)
          if (typeof validationResponse !== 'boolean')
            addInternalMessage(field, validationResponse.message, validationResponse.type)
        })
      }
    })

    const allMessages = [...tempMessages, ...tempInternalMessages] as Message<State>[]
    allMessages.forEach((message) => {
      if (message.type === 'error') {
        tempValid[message.field] = false
        tempValid.$state = false
      }
    })
  }
  const commit = () => {
    validate()
    setState({ ...tempState })
    setMessages([...tempInternalMessages, ...tempMessages])
    setTouched({ ...tempTouched })
    setValid({ ...tempValid })
  }
  const clearMessages = (commitState = true) => {
    tempInternalMessages = []
    tempMessages = []
    tempValid = { $state: true }
    commitState && commit()
  }
  const touch = (field?: keyof State) => {
    if (field) {
      tempTouched = { ...tempTouched, [field]: true }
    } else {
      ;(Object.keys(setup.store.initial) as (keyof State)[]).forEach((fieldKey) => {
        tempTouched[fieldKey] = true
      })
    }
    commit()
  }

  const setField = <Field extends keyof State>(
    fieldName: Field,
    value: State[Field],
    commitState = false,
  ) => {
    state[fieldName] = value
    tempState[fieldName] = value
    if (commitState) {
      commit()
    }
  }
  const setFieldArray = <Field extends keyof State>(
    fieldName: Field,
    index: number,
    value: State[Field][number],
    commitState = false,
  ) => {
    tempState[fieldName][index] = value
    if (commitState) {
      commit()
    }
  }

  const setFieldsData = (data: State, commitState = true) => {
    Object.keys(data).forEach((field) => {
      setField(field, data[field])
    })
    commitState && commit()
  }

  const getMessages = (field: keyof State | StateField) => messages.filter((m) => m.field === field)
  const isValid = () => valid.$state === true
  const reset = () => {
    clearMessages()
    tempValid = {}
    tempTouched = {}
    tempState = { ...setup.store.initial }
    commit()
  }
  return {
    reset,
    touched,
    state,
    commit,
    valid,
    isValid,
    setField,
    setFieldsData,
    setFieldArray,
    getField,
    touch,
    clearMessages,
    addMessage,
    getMessages,
  }
}
