import React, { useState } from 'react'

export type TabBarItem = {
  key: string
  label: string
  content: React.ReactNode
}
export type TabBarProps = {
  children: TabBarItem[]
  onChange?: (key: string, label: string) => void
}
export const TabBar = ({ children, onChange }: TabBarProps) => {
  const [selected, setSelected] = useState<number>(0)
  const onTabBarItemClick = (tabBarIndex: number) => {
    setSelected(tabBarIndex)
    if (onChange !== undefined) onChange(children[tabBarIndex].key, children[tabBarIndex].label)
  }
  return (
    <div className="tab-bar">
      {/* tabBar items */}
      <div className="max-w-screen-2xl mx-auto">
        <nav className="flex" aria-label="Tabs">
          {children
            .filter((item) => item !== undefined)
            .map((tabBarItem, tabBarIndex) => (
              <button
                onClick={() => onTabBarItemClick(tabBarIndex)}
                type="button"
                key={tabBarItem.label}
                className={[
                  'w-1/2 py-4 px-1 text-center font-medium text-lg outline-none focus:outline-none',
                  selected === tabBarIndex
                    ? 'border-b-4 border-accent-color text-secondary-color'
                    : 'border-b-4 border-transparent text-secondary-color hover:border-alpha-dark-color',
                ].join(' ')}
              >
                {tabBarItem.label}
              </button>
            ))}
        </nav>
      </div>
      <div>
        {children.map(({ key, content }, index) => (
          <div key={key} className={index === selected ? 'block' : 'hidden'}>
            {content}
          </div>
        ))}
      </div>
    </div>
  )
}
