import * as React from 'react'

function SvgArrowup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.259 7.719A.911.911 0 01.26 6.445l5.244-5.322C6.224.392 7.094 0 7.99 0s1.767.392 2.488 1.123c.796.809 1.563 1.6 2.266 2.326v.001l.005.004c1.263 1.304 2.298 2.372 2.957 2.976a.911.911 0 01.065 1.272.875.875 0 01-1.25.066c-.705-.645-1.778-1.753-3.011-3.026l-.027-.028-.004-.004c-.703-.726-1.463-1.51-2.252-2.312-.436-.442-.871-.597-1.237-.597-.366 0-.8.155-1.236.597L1.51 7.72a.875.875 0 01-1.252-.001z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgArrowup = React.memo(SvgArrowup)
export default MemoSvgArrowup
