import { API, Cache, Storage } from 'aws-amplify'
import { Config } from 'config'
import {
  MarkerResponse,
  PropertyDetails,
  PropertyListing,
  PropertyResponse,
  SearchPropertyFilter,
} from 'types/Property'
import { QueryPropertyResultsType } from 'views/shared/components/address-search/AddressSearch'

export module PropertyServices {
  const getAreaCoordinates = (position: number[]) => `[${position[0]}, ${position[1]}]`
  const optionalPropertyFilters = [
    'showActive',
    'showSold',
    'minPrice',
    'maxPrice',
    'types',
    'address',
    'minsqft',
    'maxsqft',
    'bed',
    'bath',
    'minLotFront',
    'minLotDepth',
    'dom',
    'openHouse',
    'parking',
    'from',
    'size',
  ]

  const generateFilterQuery = (homeSearchParams: SearchPropertyFilter): string => {
    const type = homeSearchParams.saleType
      ? homeSearchParams.saleType[0].toUpperCase() + homeSearchParams.saleType.substring(1)
      : 'Sale'

    const mandatoryQuery = `?topLeft=${getAreaCoordinates(
      homeSearchParams.topLeft!,
    )}&bottomRight=${getAreaCoordinates(homeSearchParams.bottomRight!)}&saleType=${type}`
    let optionalQuery = ''
    optionalPropertyFilters
      .filter((key) => key !== 'types')
      .forEach((key: string) => {
        if (
          homeSearchParams[key as keyof SearchPropertyFilter] ||
          homeSearchParams[key as keyof SearchPropertyFilter] === 0
        ) {
          optionalQuery += `&${key}=${homeSearchParams[key as keyof SearchPropertyFilter]}`
        }
      })

    if ((homeSearchParams.types || []).filter((t) => t !== 'all').length > 0) {
      optionalQuery += `&types=${homeSearchParams.types}`
    }

    return mandatoryQuery + optionalQuery
  }

  export const getProperties = async (
    homeSearchParams: SearchPropertyFilter,
    sortBy?: string,
  ): Promise<PropertyResponse> => {
    const query = `/properties${generateFilterQuery(homeSearchParams)}${
      sortBy ? `&sort=${sortBy}` : ''
    }`

    console.log(query)
    const responseData = (
      await API.get('property', query, {
        response: true,
      })
    ).data
    return responseData
  }

  export const getMarkers = async (
    homeSearchParams: SearchPropertyFilter,
  ): Promise<MarkerResponse> => {
    const query = `/markers${generateFilterQuery(homeSearchParams)}`
    const responseData = (
      await API.get('property', query, {
        response: true,
      })
    ).data
    return responseData
  }
  let propertyImageBaseUrl: string | undefined
  export const getPropertyImageBaseUrl = async () => {
    if (propertyImageBaseUrl !== undefined) return propertyImageBaseUrl
    const imageFolderUrl = await Storage.get('p/', {
      download: false,
      bucket: Config.getPhotoBucketName(),
    })
    const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
    return `${regexResponse[1]}public`
  }
  export const getPropertyQuickView = async (propertyIds: string[]): Promise<PropertyListing[]> => {
    const objects = propertyIds.reduce(
      (obj, id) => {
        const cached = Cache.getItem(id)
        if (cached) {
          obj.cachedObjs.push(cached)
        } else {
          obj.ids.push(id)
        }
        return obj
      },
      { cachedObjs: [] as PropertyListing[], ids: [] as string[] },
    )
    const responseData =
      objects.ids.length === 0
        ? []
        : (
            await API.get('property', `/quickview?ids=${objects.ids.join(',')}`, {
              response: true,
            })
          ).data
    responseData.forEach((qv: PropertyListing) => Cache.setItem(qv.mls, qv))
    return [...objects.cachedObjs, ...responseData].sort(
      (a, b) =>
        (a.soldPrice || 0) - (b.soldPrice || 0) ||
        a.type.localeCompare(b.type) ||
        (a.listingPrice || 0) - (b.listingPrice || 0),
    )
  }
  export const getPropertyImagePaths = async (
    mls?: string,
    numberOfPic?: number,
  ): Promise<string[]> => {
    if (!mls || !mls) return []
    let baseUrl: string | undefined
    try {
      baseUrl = await getPropertyImageBaseUrl()
    } catch (error) {
      return []
    }
    return Array.from(Array(numberOfPic).keys()).map((index) => `${baseUrl}/p/${mls}/${index}.jpg`)
  }

  export const getPropertyImageURLs = (
    property: { numberOfPic: number; mls: string } | PropertyListing,
    imageBaseUrl: string | null,
  ): string[] => {
    if (!imageBaseUrl || !property.numberOfPic) {
      return []
    }
    return Array.from(Array(property.numberOfPic).keys()).map(
      (index) => `${imageBaseUrl}/p/${property.mls}/${index}.jpg`,
    )
  }

  export const addressTextSearch = async (query: string): Promise<QueryPropertyResultsType> => {
    const responseData = (
      await API.get('property', `/search?q=${query}`, {
        response: true,
      })
    ).data
    return responseData
  }

  export const getLoctionInfo = async (locationId: string): Promise<PropertyListing[]> => {
    const responseData = (
      await API.get('property', `/search/location/${locationId}`, {
        response: true,
      })
    ).data
    return responseData
  }

  export const getPropertyDetailsByMls = async (mls: string): Promise<PropertyDetails> => {
    const responseData = (
      await API.get('property', `/properties/${mls}`, {
        response: true,
      })
    ).data
    return responseData
  }

  export const bookShowing = async (mls: string) => {
    const responseData = (
      await API.post('property', `/properties/${mls}/book`, {
        response: true,
      })
    ).data
    return responseData
  }
}
