import { Neighbourhood } from 'types'

export const buildBrowseList = async (neighbourhoods: Neighbourhood[]) =>
  neighbourhoods
    // sort in alphabetical order
    .sort(({ name: nameA }, { name: nameB }) => (nameA > nameB ? 1 : -1))
    // build directory from ordered list
    .reduce(
      ({ column1: col1, column2: col2, column3: col3, count: oldCount }, neighbourhood) => {
        const { name } = neighbourhood
        const letter = name[0] || ''

        // figure out the key after the
        const count = oldCount + 1
        const inCol1 = count >= neighbourhoods.length / 3 && col1[letter] === undefined
        const inCol2 =
          count >= neighbourhoods.length / 3 &&
          count < (neighbourhoods.length * 2) / 3 &&
          col1[letter] === undefined
        const inCol3 = count >= (neighbourhoods.length * 2) / 3 && col2[letter] === undefined

        // build the columns
        const column1 = {
          ...col1,
          ...(!inCol1
            ? {
                [letter]: [...(col1[letter] ? col1[letter] : []), neighbourhood],
              }
            : {}),
        }
        const column2 = {
          ...col2,
          ...(inCol2
            ? {
                [letter]: [...(col2[letter] ? col2[letter] : []), neighbourhood],
              }
            : {}),
        }
        const column3 = {
          ...col3,
          ...(inCol3
            ? {
                [letter]: [...(col3[letter] ? col3[letter] : []), neighbourhood],
              }
            : {}),
        }

        return {
          column1,
          column2,
          column3,
          count,
        }
      },
      {
        column1: {} as Record<string, Neighbourhood[]>,
        column2: {} as Record<string, Neighbourhood[]>,
        column3: {} as Record<string, Neighbourhood[]>,
        count: 0,
      },
    )
