import { paths } from 'paths'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HomeValuationServices } from 'services/homeValuation'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { KeyLabel } from 'types'
import { PropertyListing } from 'types/Property'
import { AddressField } from 'views/homeValuation/components/AddressField'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { Spinner } from 'views/shared/components/spinner/Spinner'
import { PrimaryButton, TextBody } from 'views/shared/components/ui-form'
import { HomeValuationsLayout } from '../../components/HomeValuationLayout'
import { SideContent } from '../../components/SideContent'

const ERROR_UNIT_NUMBER_IS_REQUIRED = 1450

export const PropertyAddress = () => {
  const [missingUnit, setMissingUnit] = useState<boolean | null>(null)
  const [unit, setUnit] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [locationInfo, setLocationInfo] = useState<KeyLabel | null>(null)
  const [lastListing, setLastListing] = useState<PropertyListing | null | undefined>(null)

  const history = useHistory()
  const { setPropertyState, setValuationInfoState } = useHomeValuationStore()

  const retriveLastListing = async () => {
    setLastListing(undefined)
    if (!locationInfo) {
      return Promise.resolve(undefined)
    }
    setIsLoading(true)
    return HomeValuationServices.getLastListing(locationInfo.key, unit || undefined)
      .then((result) => {
        setMissingUnit(false)
        setLastListing(result)
        return result
      })
      .catch((e) => {
        if (e.code === ERROR_UNIT_NUMBER_IS_REQUIRED) {
          setMissingUnit(true)
          return Promise.resolve(undefined)
        }
        if (e.code === 404) {
          setMissingUnit(false)
          return Promise.resolve(null)
        }
        console.warn('API call error')
        return Promise.resolve(undefined)
      })
      .then((ll) => {
        setLastListing(ll)
        return ll
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    const handleLocationInfoChanged = async () => {
      setIsLoading(true)
      setMissingUnit(null)
      await retriveLastListing()
      setIsLoading(false)
    }
    handleLocationInfoChanged()
  }, [locationInfo])

  useEffect(() => {
    setLastListing(undefined)
  }, [unit])

  useEffect(() => {
    setPropertyState(null)
    setValuationInfoState(null)
  }, [])

  const nextStep = async () => {
    await (lastListing === undefined ? retriveLastListing() : Promise.resolve(lastListing)).then(
      (ll) => {
        if (ll !== undefined) {
          setPropertyState(ll)
          setValuationInfoState((oldState) => ({
            ...oldState,
            unit: unit || undefined,
            locationId: locationInfo?.key,
            address: locationInfo?.label,
          }))
          history.replace(paths.homeValuation('property-details'))
        }
      },
    )
  }

  return (
    <>
      <HomeValuationsLayout content={<SideContent />}>
        <div className="space-y-10">
          <div className="max-w-sm">
            <TextBody fontSize={20}>
              Enter the address and get an estimate of its market value today.
            </TextBody>
          </div>
          <div className="lg:flex lg:items-baseline items-start">
            <div className="flex-1 lg:max-w-xs xl:max-w-lg">
              <AddressField label="Address*" onSelectChanged={setLocationInfo} />
            </div>
            <div className="w-28">
              <InputViewField
                label={`Unit${missingUnit ? '*' : ''}`}
                labelType="block"
                edit
                messages={missingUnit ? [{ type: 'error', message: 'required' }] : []}
                value={unit || undefined}
                onChange={(u) => (u ? setUnit(u.trim()) : setUnit(u))}
              />
            </div>
          </div>
          <div>
            <PrimaryButton
              className="flex justify-content items-center"
              disabled={isLoading || locationInfo === null || (missingUnit !== false && !unit)}
              onClick={nextStep}
            >
              <div className="w-full">
                {isLoading ? (
                  <Spinner className="h-8 mx-auto" alt="Loading" />
                ) : (
                  'Get your valuation'
                )}
              </div>
            </PrimaryButton>
          </div>
        </div>
      </HomeValuationsLayout>
    </>
  )
}
