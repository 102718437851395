import { CurrencyHelper, DateHelper, PdfHelper } from 'utilities'
import { HomeValuationAnnualReport, Neighbourhood } from 'types'
import { header } from '../header'
import { footer } from '../footer'
import { getMapImage } from './comparableMap'
import { ListContext } from '../common/block'

const mapSize = {
  w: 563,
  h: 240,
}

export const addComparableProperties = async (
  doc: PDFKit.PDFDocument,
  data: HomeValuationAnnualReport.Valuation,
  neighbourhoods: Neighbourhood[],
) => {
  doc.addPage()
  const mapImage = await getMapImage(data, mapSize.w, mapSize.h)
  const b = new ListContext(doc, [...data.similarSolds].splice(0, 5), { h: 14 }, { x: 0, y: 0 })

  const getNeighbourhoodName = (nid: string) =>
    neighbourhoods.find((item) => item.id === nid)?.name || nid

  // table header
  b.moveTo(25, 80).addRender(
    () =>
      `
      <rect width="520" height="28" rx="8" fill="#F4F6F6"/>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="27" y="16">Address</tspan></text>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="150.000" y="16">Neighbourhoods</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="238.000" y="16">Style</tspan></text>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="295.000" y="16">Sqft</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="466.000" y="16">Price</tspan></text>
      <path d="M366.979 13.4785C364.097 13.4785 361.214 13.4785 358.324 13.4785C358.324 12.3767 358.324 11.2761 358.324 10.1743C358.324 9.883 358.505 9.66196 358.771 9.61549C359.022 9.57128 359.26 9.72544 359.353 9.99181C359.382 10.078 359.408 10.1652 359.442 10.2503C359.467 10.3126 359.454 10.3489 359.406 10.3965C359.079 10.7173 358.978 11.106 359.094 11.5538C359.143 11.7397 359.294 11.8338 359.472 11.7816C359.98 11.6331 360.486 11.4812 360.992 11.3248C361.164 11.2715 361.241 11.1117 361.194 10.9315C361.07 10.4622 360.659 10.1233 360.187 10.1006C360.013 10.0927 360.013 10.0927 359.954 9.91587C359.934 9.85239 359.912 9.78778 359.887 9.72544C359.788 9.4704 359.624 9.27091 359.388 9.14622C359.264 9.08161 359.125 9.04761 358.993 9C358.901 9 358.809 9 358.717 9C358.708 9.00453 358.7 9.01133 358.691 9.0136C358.074 9.14849 357.733 9.57809 357.733 10.2242C357.733 11.3089 357.733 12.3937 357.733 13.4785C357.156 13.4785 356.579 13.4785 356.001 13.4785C356.001 13.6995 355.998 13.9149 356.002 14.1302C356.025 15.3351 356.543 16.234 357.569 16.8189C357.626 16.8518 357.653 16.8835 357.651 16.9538C357.646 17.1193 357.658 17.2848 357.648 17.4491C357.632 17.7121 357.725 17.9003 357.968 18C358.034 18 358.1 18 358.166 18C358.408 17.8969 358.497 17.7053 358.478 17.4446C358.472 17.3448 358.477 17.2428 358.477 17.1408C360.492 17.1408 362.495 17.1408 364.518 17.1408C364.518 17.2372 364.524 17.3414 364.517 17.4446C364.499 17.7042 364.589 17.8969 364.829 18C364.895 18 364.961 18 365.027 18C365.27 17.9003 365.363 17.7121 365.347 17.4491C365.338 17.2882 365.351 17.1272 365.344 16.9662C365.341 16.8858 365.372 16.8484 365.437 16.811C366.824 16.022 367.085 14.7571 366.979 13.4785Z" fill="black"/>
      <path d="M342.269 17.1265C342.198 17.3008 342.126 17.4738 341.966 17.5904C341.715 17.772 341.443 17.8076 341.163 17.6836C340.887 17.5621 340.725 17.34 340.71 17.0345C340.697 16.7658 340.703 16.497 340.702 16.2271C340.702 16.1768 340.702 16.1265 340.702 16.0663C336.93 16.0663 335.407 16.0663 331.634 16.0663C331.634 16.2406 331.635 16.4087 331.634 16.5768C331.631 16.7376 331.636 16.8983 331.619 17.0578C331.576 17.4689 331.252 17.7474 330.826 17.7511C330.397 17.7548 330.061 17.4726 330.008 17.064C330.003 17.0222 330.001 16.9793 330.001 16.9363C330.001 14.8356 329.999 12.7349 330.001 10.6329C330.001 10.2893 330.147 10.0267 330.466 9.88808C330.786 9.74942 331.095 9.78746 331.369 10.0132C331.58 10.1863 331.636 10.4255 331.636 10.6857C331.636 11.7765 331.636 12.8674 331.636 13.957C331.636 14.0135 331.636 14.0687 331.636 14.135C335.405 14.135 342.272 14.135 342.272 14.135C342.272 14.135 342.269 15.427 342.269 17.1265Z" fill="black"/>
      <path d="M338.569 11.5407C339.452 11.5407 340.335 11.5383 341.217 11.5419C341.703 11.5432 342.074 11.791 342.209 12.1947C342.44 12.8807 341.973 13.5433 341.23 13.547C339.455 13.5568 337.68 13.558 335.905 13.547C335.178 13.5421 334.703 12.877 334.925 12.2144C335.046 11.8524 335.4 11.5738 335.779 11.5481C335.92 11.5383 336.062 11.5407 336.204 11.5407C336.993 11.5407 337.78 11.5407 338.569 11.5407Z" fill="black"/>
      <path d="M334.483 12.4171C334.484 13.0343 333.984 13.5411 333.363 13.5485C332.747 13.557 332.228 13.038 332.231 12.4134C332.234 11.7938 332.741 11.2882 333.359 11.2907C333.976 11.2919 334.482 11.7974 334.483 12.4171Z" fill="black"/>
      <path d="M394.998 16.0718C394.895 16.3317 394.715 16.4485 394.454 16.4715C393.953 16.5159 393.455 16.5932 392.955 16.6492C392.863 16.659 392.825 16.6985 392.795 16.7939C392.565 17.5095 391.96 17.9882 391.283 17.9997C390.59 18.0129 389.976 17.5638 389.723 16.8482C389.687 16.7479 389.648 16.7117 389.546 16.7133C388.024 16.7183 386.499 16.7166 384.977 16.7133C384.884 16.7133 384.843 16.7446 384.81 16.84C384.561 17.5457 383.961 17.9997 383.285 17.9997C382.607 18.0014 382.002 17.5523 381.756 16.8449C381.714 16.7265 381.658 16.7084 381.563 16.7133C381.011 16.7413 380.55 16.5389 380.243 16.0224C380.132 15.8349 380.08 15.6062 380 15.3957C380 14.3248 380 13.2538 380 12.1829C380.115 11.8918 380.317 11.7602 380.605 11.7997C380.728 11.8161 380.813 11.7569 380.906 11.6779C382.053 10.6942 383.351 10.1152 384.813 10.0313C385.47 9.99342 386.13 10.0016 386.789 10C388.111 9.99836 389.295 10.4409 390.32 11.3571C391.209 12.1517 392.215 12.5596 393.362 12.5745C393.89 12.581 394.318 12.8377 394.649 13.29C394.84 13.5516 394.928 13.8592 395 14.1751C394.998 14.8084 394.998 15.4401 394.998 16.0718ZM390.221 12.5958C389.13 11.4789 387.86 10.9015 386.339 11.0446C386.339 11.548 386.339 12.0645 386.339 12.5958C387.636 12.5958 388.93 12.5958 390.221 12.5958ZM383.514 11.321C383.514 11.5299 383.51 11.7322 383.519 11.9345C383.52 11.9773 383.565 12.0349 383.603 12.0546C384.062 12.2932 384.875 12.5202 385.379 12.5564C385.382 12.5366 385.389 12.5152 385.389 12.4955C385.389 12.0151 385.389 11.5332 385.389 11.0281C384.743 11.0248 384.125 11.112 383.514 11.321Z" fill="black"/>
      `,
  )
  //  Property Details
  b.moveTo(27, 115).addRender(
    () => /* svg */ `
      <path d="M5 2.71704L6.46946 5.6945L9.75528 6.17196L7.37764 8.48958L7.93893 11.7621L5 10.217L2.06107 11.7621L2.62236 8.48958L0.244718 6.17196L3.53054 5.6945L5 2.71704Z" fill="#018363"/>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="500" letter-spacing="0em"><tspan x="20" y="9">${
        data.propertyDetails.summary.unit ? `#${data.propertyDetails.summary.unit}` : ''
      } ${data.propertyDetails.summary.address}</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="150" y="9">${getNeighbourhoodName(
        data.propertyDetails.summary.neighbour,
      )}</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="230" y="9">${
        data.propertyDetails.style
      }</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="293" y="9">${
        (data.propertyDetails?.summary?.landFront &&
          Math.round(data.propertyDetails.summary.landFront)) ||
        (data.propertyDetails.summary.footageFrom &&
          Math.round(data.propertyDetails.summary.footageFrom)) ||
        0
      }-${
      (data.propertyDetails.summary.landFront &&
        Math.round(data.propertyDetails.summary.landFront)) ||
      (data.propertyDetails.summary.footageTo &&
        Math.round(data.propertyDetails.summary.footageTo)) ||
      0
    }</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="331" y="9">${
        data.propertyDetails.summary.bedroom || 0
      }${
      data.propertyDetails.summary.brPlus ? `+${data.propertyDetails.summary.brPlus}` : ''
    }</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="357" y="9">${
        data.propertyDetails.summary.bathroom || 0
      }</tspan></text>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="381" y="9">${
        data.propertyDetails.summary.parking || 0
      }</tspan></text>
      <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="462" y="9">${CurrencyHelper.currencyFormat(
        data.propertyDetails.summary.listingPrice,
        false,
        '$',
      )}</tspan></text>
      `,
  )
  b.moveTo(48, 132).addRender(
    () =>
      `<line x1="22" y1="6" x2="492" y2="6" stroke="#C4C4C4" stroke-linecap="round" stroke-dasharray="0.5 3"/>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="0" y="10.0095">Sold</tspan></text>
      <rect x="377" width="50" height="15" rx="7.5" fill="#F4F6F6"/>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="384.285" y="10.0095">Sold Date</tspan></text>`,
  )
  b.moveTo(25, 153)

  // Similar Sold
  b.addRenderItem((ctx, item, index) => {
    const { summary } = item
    return /* svg */ `
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="4" y="6">${
        index + 1
      }</tspan></text>
      <!--Address-->
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="500" letter-spacing="0em"><tspan x="25" y="6">${
        summary.unit ? `#${summary.unit}` : ''
      } ${summary.address}</tspan></text>
      <!--Neighbourhood-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="150" y="6">${getNeighbourhoodName(
        summary.neighbour,
      )}</tspan></text>
      <!--Style-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="230" y="6">${
        item.style
      }</tspan></text>
      <!--Sqft-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="283" y="6">
      ${
        (summary.landFront && Math.round(summary.landFront)) ||
        (summary.footageFrom && Math.round(summary.footageFrom)) ||
        0
      }-${
      (summary.landFront && Math.round(summary.landFront)) ||
      (summary.footageTo && Math.round(summary.footageTo)) ||
      0
    }
      </tspan></text>
       <!--bedrooms-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="331" y="6">${
        summary.bedroom || 0
      }${summary.brPlus ? `+${summary.brPlus}` : ''}</tspan></text>
      <!--bathrooms-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="361" y="6">${
        summary.bathroom || 0
      }</tspan></text>
      <!--parking-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="382" y="6">${
        summary.parking || 0
      }</tspan></text>
      <!--Sold Date-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="410" y="6">${
        summary.soldDate && DateHelper.formatDateToMonthYear(summary.soldDate)
      }</tspan></text>
      <!--Sold Price-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="466" y="6">$${CurrencyHelper.currencyFormat(
        summary.soldPrice,
      )}</tspan></text>
      `
  })

  // Similar Active
  const c = new ListContext(doc, [...data.similarActives].splice(0, 5), { h: 14 }, { x: 0, y: 0 })
  c.moveTo(48, 223).addRender(
    () =>
      `<line x1="56" y1="6" x2="494" y2="6" stroke="#C4C4C4" stroke-linecap="round" stroke-dasharray="0.5 3"/>
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="0" y="10">Active Listings</tspan></text>
      <rect x="369" width="65" height="15" rx="7.5" fill="#F4F6F6"/>
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="376" y="10">Days on Market</tspan></text>`,
  )
  c.addRenderItem((ctx, item) => {
    const { summary } = item
    return /* svg */ `
      <!--Address-->
      <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="500" letter-spacing="0em"><tspan x="0" y="26">${
        summary.unit ? `#${summary.unit}` : ''
      } ${summary.address}</tspan></text>
      <!--Neighbourhood-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="126" y="26">${getNeighbourhoodName(
        summary.neighbour,
      )}</tspan></text>
      <!--Style-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="207" y="26">${
        item.style
      }</tspan></text>
      <!--Sqft-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="273" y="26">${
        (summary.landFront && Math.round(summary.landFront)) ||
        (summary.footageFrom && Math.round(summary.footageFrom)) ||
        0
      }-${
      (summary.landFront && Math.round(summary.landFront)) ||
      (summary.footageTo && Math.round(summary.footageTo)) ||
      0
    }</tspan></text>
       <!--bedrooms-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="308" y="26">${
        summary.bedroom || 0
      }${summary.brPlus ? `+${summary.brPlus}` : ''}</tspan></text>
      <!--bathrooms-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="338" y="26">${
        summary.bathroom || 0
      }</tspan></text>
      <!--parking-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="361" y="26">${
        summary.parking || 0
      }</tspan></text>
      <!--DOM-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" letter-spacing="0em"><tspan x="399" y="26">${
        summary.dom || 0
      }</tspan></text>
      <!--Listing Price-->
      <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="7" font-weight="bold" letter-spacing="0em"><tspan x="444" y="26">$${CurrencyHelper.currencyFormat(
        summary.listingPrice,
      )}</tspan></text>
      `
  }).render()

  b.moveTo(0, 440)
  b.addRender(() => mapImage(0, 0, mapSize.w, mapSize.h))
  b.render()

  const template = /* svg */ `
  <svg width="563" height="750" viewBox="0 0 563 750" fill="white" xmlns="http://www.w3.org/2000/svg">
    ${header(data)}
    ${await footer(data)}
  </svg>`
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
