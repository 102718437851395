import React, { useState, useEffect } from 'react'
import { DateHelper } from 'utilities'
import { MonthStr, UseFormikType } from 'types'
import { ArrowDownSelect, ArrowLeft, ArrowRight } from '../../icons'
import {
  DayList,
  DayListItem,
  DropDownContainer,
  DropDownHeaderInput,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from './MonthPickerStyles'
import { ModalLayer } from '../../modal-layer'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1
const currentDay = new Date().getDate()

export type DatePickerProps = {
  onChange?: (selectedOption?: MonthStr) => void
  value?: MonthStr
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: UseFormikType<any>
  name: string
  styleType?: 'inputStyle' | 'textStyle'
  type?: 'future' | 'past'
}

export const DatePicker = ({
  onChange,
  value,
  formik,
  name,
  styleType,
  type = 'past',
}: DatePickerProps) => {
  const [stateOpen, setStateOpen] = useState<'day' | 'month' | 'year' | 'closed'>('closed')
  const isOpen = stateOpen !== 'closed'
  const toggling = () => setStateOpen(stateOpen === 'closed' ? 'day' : 'closed')
  const typeFuture = type === 'future'
  const typePast = type === 'past'
  const years = Array.from(new Array(12), (val, index) =>
    typeFuture ? currentYear + index : currentYear - index,
  )

  let selectedDate = DateHelper.strToYearMonthDay(value || DateHelper.currentDateStr())
  if (
    !selectedDate ||
    Number.isNaN(selectedDate.month) ||
    Number.isNaN(selectedDate.year) ||
    Number.isNaN(selectedDate.day)
  ) {
    selectedDate = DateHelper.strToYearMonthDay(DateHelper.currentDateStr())
  }
  const { month, year, day } = selectedDate
  const monthLabel = months[month - 1]
  const daysList = DateHelper.daysInMonth(year, month)

  const tryChangeTo = (newYear: number, newMonth: number, newDay: number) => {
    let newValue
    if (typePast && newYear <= currentYear) {
      newValue = `${newYear}-${
        newYear === currentYear && newMonth > currentMonth ? currentMonth : newMonth
      }-${
        newYear === currentYear && newMonth === currentMonth && newDay > currentDay
          ? currentDay
          : newDay
      }`
    }
    if (typeFuture && newYear >= currentYear) {
      newValue = `${newYear}-${
        newYear === currentYear && newMonth < currentMonth ? currentMonth : newMonth
      }-${
        newYear === currentYear && newMonth === currentMonth && newDay < currentDay
          ? currentDay
          : newDay
      }`
    }
    if (onChange) {
      if (formik) {
        formik.setFieldValue(name, newValue)
        formik.setFieldTouched(name)
      }
      onChange(newValue)
    }
    // if (newYear <= currentYear) {
    //   const newValue = `${newYear}-${
    //     newYear === currentYear && newMonth > currentMonth ? currentMonth : newMonth
    //   }-${
    //     newYear === currentYear && newMonth === currentMonth && newDay > currentDay
    //       ? currentDay
    //       : newDay
    //   }`
    //   if (onChange) {
    //     if (formik) {
    //       formik.setFieldValue(name, newValue)
    //       formik.setFieldTouched(name)
    //     }
    //     onChange(newValue)
    //   }
    // }
  }
  const onYearPick = (y: number) => {
    tryChangeTo(y, selectedDate.month, selectedDate.day)
    setStateOpen('month')
  }
  const onMonthPick = (m: number) => {
    tryChangeTo(selectedDate.year, m, selectedDate.day)
    setStateOpen('day')
  }
  const onDayPick = (d: number) => {
    tryChangeTo(selectedDate.year, selectedDate.month, d)
    setStateOpen('closed')
  }
  const onOutsideClick = () => {
    setStateOpen('closed')
  }
  const onEscape = () => {
    setStateOpen('closed')
  }
  const nextMonth = () => {
    const newYear = month === 12 ? year + 1 : year
    const newMonth = month === 12 ? 1 : month + 1
    const maxDay = DateHelper.daysCountInMonth(newYear, newMonth)
    const newDay = (() => {
      if (day >= maxDay) return maxDay
      if (typePast && DateHelper.isFuture(newYear, newMonth, day)) return currentDay
      if (typeFuture && !DateHelper.isFuture(newYear, newMonth, day)) return currentDay
      return day
    })()
    tryChangeTo(newYear, newMonth, newDay)
  }
  const prevMonth = () => {
    const newYear = month === 1 ? year - 1 : year
    const newMonth = month === 1 ? 12 : month - 1
    const maxDay = DateHelper.daysCountInMonth(newYear, newMonth)
    const newDay = day > maxDay ? maxDay : day
    tryChangeTo(newYear, newMonth, newDay)
  }
  useEffect(() => {
    const escapeCallback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setStateOpen('closed')
      }
    }
    // add escape event
    window.addEventListener('keyup', escapeCallback)
    return () => {
      // remove escape event
      window.removeEventListener('keyup', escapeCallback)
    }
  }, [])
  return (
    <div className="relative">
      {isOpen && (
        <div
          tabIndex={0}
          role="link"
          onKeyUp={(e) => {
            if (e.key === 'Escape') onEscape()
          }}
          onClick={onOutsideClick}
          className="fixed top-0 right-0 bottom-0 left-0 z-10 opacity-0 "
        >
          &nbsp;
        </div>
      )}
      <DropDownContainer>
        {styleType === 'textStyle' ? (
          <button
            type="button"
            className="max-w-min text-base ml-2 flex items-center text-primary-color font-semibold  space-x-1 border-b border-primary-color"
            onClick={toggling}
          >
            <span> {monthLabel} </span>
            <span className="">{day}</span>, <span className="pr-2">{year}</span>
            <ArrowDownSelect style={{ fontSize: '18px' }} color="var(--primary-color)" />
          </button>
        ) : (
          <DropDownHeaderInput
            className="max-w-min flex items-center  justify-between"
            onClick={toggling}
          >
            <p className="flex items-center pr-3">
              <span className="pr-4">{day}</span>
              <span>{monthLabel}</span>
              <span className="pl-4">{year}</span>
            </p>
            <ArrowDownSelect style={{ fontSize: '18px' }} color="var(--primary-color)" />
          </DropDownHeaderInput>
        )}

        <ModalLayer
          onOutsideClick={onOutsideClick}
          show={isOpen}
          responsive={{ small: 'full', medium: 'center', large: 'pinned' }}
        >
          <div className="relative float-left shadow-lg p-6 border border-primary-soft-color rounded-lg bg-white z-50">
            {/* header */}
            <div className="flex flex-row justify-between pr-6 pl-6 pt-5 z-50">
              <button
                onClick={() => prevMonth()}
                className="text-2xl font-extrabold text-primary-color"
                type="button"
              >
                <ArrowLeft style={{ fontSize: '14px' }} color="var(--primary-color)" />
              </button>
              <button
                className="flex items-center space-x-1"
                type="button"
                onClick={() => {
                  setStateOpen('month')
                }}
              >
                <span>{months[month - 1]}</span>
                <ArrowDownSelect style={{ fontSize: '10px' }} color="var(--primary-color)" />
              </button>
              <button
                className="flex items-center space-x-1"
                type="button"
                onClick={() => {
                  setStateOpen('year')
                }}
              >
                <span>{year}</span>
                <ArrowDownSelect style={{ fontSize: '10px' }} color="var(--primary-color)" />
              </button>
              <button
                onClick={() => nextMonth()}
                className={`text-2xl font-extrabold text-accent-color ${
                  year === currentYear && month === currentMonth ? 'opacity-10' : ''
                }`}
                type="button"
              >
                <ArrowRight style={{ fontSize: '12px' }} color="var(--primary-color)" />
              </button>
            </div>
            {/* years or month */}
            <div className="relative z-50">
              {/* years */}
              {stateOpen === 'year' && (
                <div>
                  <DropDownListContainer>
                    <DropDownList>
                      {years.map((y) => (
                        <ListItem
                          className={`${
                            year === y
                              ? 'text-white bg-accent-color rounded-lg'
                              : 'text-primary-color cursor-pointer'
                          }`}
                          onClick={() => onYearPick(y)}
                          key={y}
                        >
                          {y}
                        </ListItem>
                      ))}
                    </DropDownList>
                  </DropDownListContainer>
                </div>
              )}

              {/* months */}
              {stateOpen === 'month' && (
                <div>
                  <DropDownListContainer>
                    <DropDownList>
                      {months.map((m, i) => (
                        <ListItem
                          className={`${
                            month - 1 === i
                              ? 'text-white bg-accent-color rounded-lg'
                              : 'text-primary-color'
                          } ${
                            (typePast && DateHelper.isFuture(year, i + 1)) ||
                            (typeFuture && !DateHelper.isFuture(year, i + 2))
                              ? 'text-secondary-color opacity-50 cursor-default'
                              : 'cursor-pointer'
                          }`}
                          onClick={() =>
                            (typePast &&
                              !DateHelper.isFuture(year, i + 1) &&
                              onMonthPick(months.indexOf(m) + 1)) ||
                            (typeFuture &&
                              DateHelper.isFuture(year, i + 2) &&
                              onMonthPick(months.indexOf(m) + 1))
                          }
                          key={m}
                        >
                          {m}
                        </ListItem>
                      ))}
                    </DropDownList>
                  </DropDownListContainer>
                </div>
              )}

              {/* days */}
              {stateOpen === 'day' && (
                <DayList>
                  <DropDownListContainer>
                    {daysList.map((i) => {
                      const isFuture = DateHelper.isFuture(year, month, i)
                      const dayDisabled =
                        (type === 'future' && !isFuture) || (type === 'past' && isFuture)
                      return (
                        <DayListItem
                          onClick={() => !dayDisabled && onDayPick(i)}
                          className={[
                            `${dayDisabled ? '--disabled' : '--enabled'}`,
                            `${i === 1 ? '--firstDay' : ''}`,
                            `${i === day ? '--selected' : ''}`,
                            DateHelper.getDayOfWeek(year, month, i),
                          ].join(' ')}
                          key={i}
                        >
                          {i}
                        </DayListItem>
                      )
                    })}
                  </DropDownListContainer>
                </DayList>
              )}
            </div>
          </div>
        </ModalLayer>
      </DropDownContainer>
    </div>
  )
}
// <div className="relative">
//   {isOpen && (
//     <div
//       tabIndex={0}
//       role="link"
//       onKeyUp={(e) => {
//         if (e.key === 'Escape') onEscape()
//       }}
//       onClick={onOutsideClick}
//       className="fixed top-0 right-0 bottom-0 left-0 z-20 opacity-0 "
//     >
//       &nbsp;
//     </div>
//   )}
//   <DropDownContainer>
//     {styleType === 'textStyle' ? (
//       <button
//         type="button"
//         className="max-w-min text-base ml-2 flex items-center text-primary-color font-semibold  space-x-1 border-b border-primary-color"
//         onClick={toggling}
//       >
//         <span> {monthLabel} </span>
//         <span className="">{day}</span>, <span className="">{year}</span>
//       </button>
//     ) : (
//       <DropDownHeaderInput className="text-xl lg:text-4xl max-w-min" onClick={toggling}>
//         <span className="pr-4">{day}</span>
//         <span>{monthLabel}</span>
//         <span className="pl-4">{year}</span>
//       </DropDownHeaderInput>
//     )}

//     {isOpen && (
//       <div className="absolute shadow-lg rounded-lg bg-white border border-primary-soft-color mt-1 z-50 right-0 sm:-right-14">
//         {/* header */}
//         <div className="flex flex-row justify-between pr-6 pl-6 pt-5 z-50">
//           <button
//             onClick={() => prevMonth()}
//             className="text-2xl font-extrabold text-primary-color"
//             type="button"
//           >
//             <ArrowLeft style={{ fontSize: '14px' }} color="var(--primary-color)" />
//           </button>
//           <button
//             className="flex items-center space-x-1"
//             type="button"
//             onClick={() => {
//               setStateOpen('month')
//             }}
//           >
//             <span>{months[month - 1]}</span>
//             <ArrowDownSelect style={{ fontSize: '10px' }} color="var(--primary-color)" />
//           </button>
//           <button
//             className="flex items-center space-x-1"
//             type="button"
//             onClick={() => {
//               setStateOpen('year')
//             }}
//           >
//             <span>{year}</span>
//             <ArrowDownSelect style={{ fontSize: '10px' }} color="var(--primary-color)" />
//           </button>
//           <button
//             onClick={() => nextMonth()}
//             className={`text-2xl font-extrabold text-accent-color ${
//               year === currentYear && month === currentMonth ? 'opacity-10' : ''
//             }`}
//             type="button"
//           >
//             <ArrowRight style={{ fontSize: '12px' }} color="var(--primary-color)" />
//           </button>
//         </div>
//         {/* years or month */}
//         <div className="relative z-50">
//           {/* years */}
//           {stateOpen === 'year' && (
//             <div>
//               <DropDownListContainer>
//                 <DropDownList>
//                   {years.map((y) => (
//                     <ListItem
//                       className={`${
//                         year === y
//                           ? 'text-white bg-accent-color rounded-lg'
//                           : 'text-primary-color cursor-pointer'
//                       }`}
//                       onClick={() => onYearPick(y)}
//                       key={y}
//                     >
//                       {y}
//                     </ListItem>
//                   ))}
//                 </DropDownList>
//               </DropDownListContainer>
//             </div>
//           )}

//           {/* months */}
//           {stateOpen === 'month' && (
//             <div>
//               <DropDownListContainer>
//                 <DropDownList>
//                   {months.map((m, i) => (
//                     <ListItem
//                       className={`${
//                         month - 1 === i
//                           ? 'text-white bg-accent-color rounded-lg'
//                           : 'text-primary-color'
//                       } ${
//                         DateHelper.isFuture(year, i + 1)
//                           ? 'text-secondary-color opacity-50 cursor-default'
//                           : 'cursor-pointer'
//                       }`}
//                       onClick={() =>
//                         !DateHelper.isFuture(year, i + 1) && onMonthPick(months.indexOf(m) + 1)
//                       }
//                       key={m}
//                     >
//                       {m}
//                     </ListItem>
//                   ))}
//                 </DropDownList>
//               </DropDownListContainer>
//             </div>
//           )}

//           {/* days */}
//           {stateOpen === 'day' && (
//             <DayList>
//               <DropDownListContainer>
//                 {daysList.map((i) => {
//                   const isFuture = DateHelper.isFuture(year, month, i)
//                   return (
//                     <DayListItem
//                       onClick={() => !isFuture && onDayPick(i)}
//                       className={[
//                         `${isFuture ? '--disabled' : '--enabled'}`,
//                         `${i === 1 ? '--firstDay' : ''}`,
//                         `${i === day ? '--selected' : ''}`,
//                         DateHelper.getDayOfWeek(year, month, i),
//                       ].join(' ')}
//                       key={i}
//                     >
//                       {i}
//                     </DayListItem>
//                   )
//                 })}
//               </DropDownListContainer>
//             </DayList>
//           )}
//         </div>
//       </div>
//     )}
//   </DropDownContainer>
// </div>
