import { AuthContext } from 'views/authentication'
import React, { useContext } from 'react'
import { HardArrowDown } from '../icons'

export type DownloadPdfProps = {
  onClick: () => void
}
export const DownloadPdf = ({ onClick }: DownloadPdfProps) => {
  const { authUser } = useContext(AuthContext)
  return (
    <>
      {authUser ? (
        <button
          onClick={onClick}
          className="text-primary-color font-semibold flex flex-col items-center w-14"
          type="button"
        >
          <HardArrowDown style={{ fontSize: '24px' }} color="var(--primary-color)" />
          <span className="text-xs text-center">Download</span>
        </button>
      ) : (
        <></>
      )}
    </>
  )
}
