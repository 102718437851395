import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { PdfHelper } from 'utilities/helpers/PdfHelper'

export const getMapImage = async (data: HomeValuationAnnualReport.Valuation, w = 563, h = 240) => {
  const similarSolds = data.similarSolds || ([] as HomeValuationAnnualReport.PropertyDetails[])
  const mainLocation = { lat: data.homeValue.lat, lon: data.homeValue.lng }
  const size = `${w}x${h}`
  const markers = similarSolds.reduce(
    (pv, v, i) => {
      const location = {
        lat: v.summary.location?.lat,
        lon: v.summary.location?.lon,
      }
      return {
        location,
        render: `${pv.render}|&${encodeURI(
          `markers=size:mid|color:0x000000|label:${i + 1}|${location.lat},${location.lon}`,
        )}`,
      }
    },
    {
      location: { ...mainLocation },
      render: encodeURI(`&markers=size:mid|color:0x2e3a5c|${mainLocation.lat},${mainLocation.lon}`),
    },
  ).render
  const base = `http://maps.googleapis.com/maps/api/staticmap?`
  const key = `AIzaSyBskneiWeyy5OjNNe-Vm4xFNYJkJjF8TT4`
  const parameters = `auto=&maptype=terrain&scale=2&size=${size}&key=${key}`
  const path = base + parameters + markers
  return PdfHelper.getImage(path)
}
