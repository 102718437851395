import { AgentProfile } from 'views/agents/types/agents'
import { PdfHelper } from 'utilities/helpers/PdfHelper'
import { agent } from './agent'
import { logoPaths } from './logoPaths'

export type CoverProps = {
  name: string
  monthLabel: string
  agentProfile: AgentProfile
}
export const addCover = (
  doc: PDFKit.PDFDocument,
  { name, monthLabel, agentProfile }: CoverProps,
) => {
  const template = /* svg */ `
  <svg width="1338" height="1782" viewBox="0 0 1338 1782" fill="none" xmlns="http://www.w3.org/2000/svg">
    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="94" text-anchor="middle" font-weight="600" letter-spacing="0em"><tspan x="50%" y="885.395">${name}</tspan></text>
    <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="73" text-anchor="middle" font-weight="300" letter-spacing="0em"><tspan x="50%" y="973.671">${monthLabel} </tspan></text>
    <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="50" text-anchor="middle" font-weight="300" letter-spacing="0em"><tspan x="50%" y="1238.92">Neighbourhood Sold Report</tspan></text>
    <rect x="619" y="1159" width="8" height="100" transform="rotate(-90 619 1159)" fill="#018363"/>
    ${logoPaths()}
    <text text-anchor="middle" fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="26" letter-spacing="0em"><tspan x="50%" y="492.321">presents</tspan></text>
    ${agent({
      firstName: agentProfile.firstName || '---',
      lastName: agentProfile.lastName || '---',
      phone: agentProfile.phone || '(000) 000-0000',
      email: agentProfile.email || '---',
      headshot: agentProfile.headshot,
      logo: agentProfile.logo,
    })}
  </svg>
  `
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  return doc
}
