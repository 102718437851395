import UserTypes from 'services/user/UserTypes'
import { Neighbourhood } from 'types'

const findNeighbourhoodName = (neighbourhoods: Neighbourhood[], nid?: string) =>
  neighbourhoods.find((neighbourhood) => neighbourhood.id === nid)?.name || ''

export const describeUserFeed = (
  userFeed: UserTypes.UserFeed,
  neighbourhoods: Neighbourhood[],
): { title: string; desc?: string } => {
  switch (userFeed.feedType) {
    case 'AddToWaitList':
      return { title: 'Added to waiting list' }
    case 'Invited':
      return { title: 'Invited' }
    case 'ResendInvite':
      return { title: 'Resend invite' }
    case 'SignUp':
      return { title: 'Sign up' }
    case 'Deleted':
      return { title: 'account deleted' }
    case 'Archived':
      return { title: 'account archived' }
    case 'Unarchived':
      return { title: 'account unarchived' }
    case 'Follow':
      return {
        title: 'Follow neighbourhood',
        desc: findNeighbourhoodName(neighbourhoods, userFeed.nid),
      }
    case 'Unfollow':
      return {
        title: 'Unfollow neighbourhood',
        desc: findNeighbourhoodName(neighbourhoods, userFeed.nid),
      }
    case 'UpdateProfile':
      return { title: 'Update profile' }
    case 'OpenSoldReport':
      return {
        title: 'Open sold report',
        desc: `${findNeighbourhoodName(neighbourhoods, userFeed.nid)} - ${userFeed.month}`,
      }
    case 'DownloadSoldReport':
      return {
        title: 'Download sold report',
        desc: `${findNeighbourhoodName(neighbourhoods, userFeed.nid)} - ${userFeed.month}`,
      }
    case 'PageView':
      return { title: 'View page', desc: userFeed.url }
    case '_email.send':
      return { title: 'Email sent', desc: userFeed.emailtype }
    case '_email.open':
      return { title: 'Email open', desc: userFeed.emailtype }
    case '_email.click':
      return { title: 'Email link click', desc: `${userFeed.emailtype} - ${userFeed.url}` }
    case '_email.delivered':
      return { title: 'Email delivered', desc: userFeed.emailtype }
    case '_email.unsubscribe':
      return { title: 'Email unsubscribe', desc: userFeed.emailtype }
    case '_email.complaint':
      return { title: 'Email spam', desc: userFeed.emailtype }
    case '_email.hardbounce':
      return { title: 'Email not delivered', desc: userFeed.emailtype }
    case 'HomeValue.create':
      return { title: 'Home valuation created', desc: userFeed.payload?.address || '' }
    case 'HomeValue.update':
      return { title: 'Home valuation updated', desc: userFeed.payload?.address || '' }
    case 'HomeValue.delete':
      return { title: 'Home valuation deleted', desc: userFeed.payload?.address || '' }
    case 'HomeValue.updateFrequence':
      return { title: 'Home valuation frequence updated', desc: userFeed.payload?.address || '' }
    case 'HomeValue.assigned':
      return { title: 'Home valuation assigned', desc: userFeed.payload?.address || '' }
    case 'HomeValue.emailClick':
      return { title: 'Home valuation email click', desc: userFeed.payload?.address || '' }
    case 'HomeValue.view':
      return { title: 'Home valuation web view', desc: userFeed.payload?.address || '' }
    case 'HomeValue.confirmed':
      return { title: 'Home valuation confirmed', desc: userFeed.payload?.address || '' }
    default:
      return { title: userFeed.feedType }
  }
}
