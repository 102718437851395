import React from 'react'
import { PageFooter } from 'views/shared/components/page-footer'
import { TextBody, Title } from '../../../shared/components/ui-form'

export const Privacy = () => (
  <>
    <div className="max-w-screen-lg  px-5 lg:px-20 space-y-5 mb-24 mt-10">
      <Title fontSize={32} className="block sm:hidden mb-10">
        Our Privacy Policy
      </Title>
      <Title className="hidden sm:block mb-10">Our Privacy Policy</Title>
      <div>
        <p className="text-xl text-secondary-color font-semibold">
          How we share changes to our privacy policy.
        </p>
        <TextBody>
          At Castl., we believe that homes are sacred spaces that keep you and your loved ones safe.
          That&#39;s why we&#39;re also committed to keeping your personal data safe when using
          Castl.
        </TextBody>
        <TextBody>
          Our privacy policy aims to be clear about the data we collect, how we use it, and why so
          you can be as comfortable using Castl. as you are in your own home.
        </TextBody>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">The data we collect.</p>
        <TextBody>
          We may collect the following types of personal and non-personal information about you when
          using Castl. Personal information is data that could be used to identify you, while
          non-personal information is data that cannot.
        </TextBody>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 mt-5">
          <div>
            <TextBody>
              <span className="font-bold">Personal information: </span>
            </TextBody>
            <ul className="list-disc text-default-color pl-4">
              <li>
                <TextBody>Name</TextBody>
              </li>
              <li>
                <TextBody>Email address</TextBody>
              </li>
              <li>
                <TextBody>Phone number</TextBody>
              </li>
              <li>
                <TextBody>Home address</TextBody>
              </li>
              <li>
                <TextBody>Date of birth</TextBody>
              </li>
            </ul>
          </div>
          <div>
            <TextBody>
              <span className="font-bold">Non-personal information: </span>
            </TextBody>
            <ul className="list-disc text-default-color pl-4">
              <li>
                <TextBody>
                  Email stats (when you&#39;ve opened, viewed, or unsubscribed from emails)
                </TextBody>
              </li>
              <li>
                <TextBody>Login activity (the dates and times that you&#39;ve logged in)</TextBody>
              </li>
              <li>
                <TextBody>Time spent on the website</TextBody>
              </li>
              <li>
                <TextBody>Sold Reports opened</TextBody>
              </li>
              <li>
                <TextBody>Neighbourhoods followed</TextBody>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">How we collect it.</p>
        <TextBody>
          We collect your user data and authenticated in-app activity in a number of ways:
        </TextBody>
        <ul className="list-disc text-default-color pl-4">
          <li>
            <TextBody>Registration and sign in (the data you enter yourself)</TextBody>
          </li>
          <li>
            <TextBody>
              Google Analytics (the pages you view, time spent per session, email stats, and other
              Castl. activity)
            </TextBody>
          </li>
          <li>
            <TextBody>
              Hotjar heatmapping (where you click on the Castl. application and how often)
            </TextBody>
          </li>
        </ul>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">Why we collect it.</p>
        <TextBody>Your personal information helps to make Castl. great by letting us: </TextBody>
        <ul className="list-disc text-default-color pl-4">
          <li>
            <TextBody>Create your account</TextBody>
          </li>
          <li>
            <TextBody>
              Allow you to legally access the complete Sold Report data available on Castl.
            </TextBody>
          </li>
          <li>
            <TextBody>Provide the information that is most relevant to you</TextBody>
          </li>
          <li>
            <TextBody>
              Conduct research to fix errors and make the Castl. experience better
            </TextBody>
          </li>
        </ul>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">We don&#39;t share your data.</p>
        <TextBody>
          Your trust is important to us, so we don&#39;t share it with any third parties. The only
          people who can access your information are SAGE real estate agents and business operators
          to keep it as safe as possible.
        </TextBody>
        <TextBody>
          You also have the option to unsubscribe from Castl. emails or close your account at any
          time.
        </TextBody>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">How we keep your data safe.</p>
        <TextBody>
          We put effort and thought into how we keep your data protected and secure. That&#39;s why
          we chose Amazon Web Services (AWS) to host Castl. and its data.
        </TextBody>
        <TextBody className="mb-5">
          AWS covers all security compliance norms such as PCI, SCO, and HIPPA. It was also
          developed within government regulations and industry best practices, and all security
          protocols and encryptions are built into its entire infrastructure by default.
        </TextBody>
        <TextBody>Here are some of the ways AWS keeps your data safe:</TextBody>
        <ul className="list-disc text-default-color pl-4 mb-5">
          <li>
            <TextBody>
              Provides enterprise-level security protection and encryption for all data stored on
              its cloud
            </TextBody>
          </li>
          <li>
            <TextBody>
              Requires clients to install firewall and encryption software for all on-premise
              configuration
            </TextBody>
          </li>
          <li>
            <TextBody>
              Ensures continuous monitoring of cloud infrastructures for suspicious activity
            </TextBody>
          </li>
          <li>
            <TextBody>
              Allows SAGE Real Estate to also monitor our data for suspicious activity
            </TextBody>
          </li>
          <li>
            <TextBody>Actively secures all cloud data transfers through TLS encryption</TextBody>
          </li>
          <li>
            <TextBody>
              Allows clients to utilize their own virtual private clouds to have complete control
              over their virtual networking environment, including IP addresses
            </TextBody>
          </li>
        </ul>
        <TextBody>
          With the extensive security features included in AWS, we are confident that your
          information and data is protected and safe.
        </TextBody>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">
          How we share changes to our privacy policy.
        </p>
        <TextBody>
          Our goal is to be clear about how we collect and use your data now and in the future. If
          at any point there are updates to our privacy policy, we will notify all authorized users
          by email, using the email address we have stored in our system. To stay up-to-date, make
          sure to use your most current email address for your Castl. account and keep it updated
          when your email changes.
        </TextBody>
      </div>

      <div>
        <p className="text-xl text-secondary-color font-semibold">Have questions? We can help.</p>
        <TextBody className="mb-5">
          We are dedicated to being transparent with our privacy policies and the kinds of data we
          use. If you have any questions or concerns, please reach out to us by mail or phone.
        </TextBody>
        <TextBody>
          <span className="font-bold">Our address: </span>
        </TextBody>
        <TextBody className="mb-5">
          SAGE Real Estate Ltd., Brokerage
          <br />
          2010 Yonge Street, Toronto, ON
        </TextBody>
        <TextBody>
          <span className="font-bold">Our phone number: </span>
        </TextBody>
        <TextBody>416-483-8000</TextBody>
      </div>
    </div>
    <PageFooter />
  </>
)
