/* eslint-disable @typescript-eslint/no-unused-vars */
import { Storage } from 'aws-amplify'
import { readAndCompressImage } from 'browser-image-resizer'

export module FileServices {
  export type UploadImageConfig = {
    quality?: number
    width?: number
    height?: number
  }
  export const getFilePath = async (
    fileName: string,
    fallbackUrl = '/assets/default-profile.jpg',
    loading?: string,
  ) => {
    const imagePath = (await Storage.get(fileName)) as string
    return imagePath
  }
  export const uploadImage = async (
    file: File | null,
    fileName: string,
    { quality = 0.7, width = 800, height = 800 }: FileServices.UploadImageConfig = {},
  ) => {
    if (file === null) return Promise.resolve<string | null>(null)
    return new Promise<string>((resolve, reject: (error: Error) => void) => {
      const reader = new FileReader()
      try {
        reader.readAsDataURL(file)
      } catch (err) {
        reject(new Error('Fail to read the file'))
      }
      reader.addEventListener('loadend', async () => {
        try {
          const resizedImage = await readAndCompressImage(file, {
            quality,
            width,
            height,
          })
          await Storage.put(fileName, resizedImage, {
            contentType: file.type,
          })
          const path = await getFilePath(fileName)
          resolve(path)
        } catch (error) {
          reject(new Error('Error upload the image'))
        }
      })
    })
  }
  export const uploadImages = async (
    files: FileList | null,
    fileName: (i: number) => string,
    config: FileServices.UploadImageConfig = {},
  ) => {
    if (files === null) return Promise.resolve<string[]>([])
    return Promise.all(
      Array.from(Array(files.length)).map((_, i) =>
        uploadImage(files.item(i), fileName(i), config),
      ),
    )
  }
}
