/* eslint-disable max-classes-per-file */
import { PdfHelper } from 'utilities/helpers/PdfHelper'

export namespace BlockType {
  export type RenderItemAction<TData, TList extends TData & any[]> = (
    context: BlockContext<TData, TList>,
    item: TList[number],
    index: number,
    list: TList[number][],
  ) => string | void

  export type RenderListAction<TData, TList extends TData & any[]> = (
    context: BlockContext<TData, TList>,
    data: TData,
  ) => string | void

  export type RenderPageAction<TData, TList extends TData & any[]> = (
    context: BlockContext<TData, TList>,
    data: TData,
    content?: string | null,
  ) => string | void

  export type Vertex = {
    readonly x: number
    readonly y: number
  }
  export type Size = { readonly w: number; readonly h: number }
}
export abstract class BlockContext<TData, TList extends TData & Array<unknown>> {
  protected dataType: 'list' | 'simple' = 'simple'

  public setDataType(dataType: typeof this.dataType) {
    this.dataType = dataType
  }

  public getDataType() {
    return this.dataType
  }

  public readonly initialPosition: BlockType.Vertex = { x: 0, y: 0 }

  public readonly initialSize: BlockType.Size = { w: 0, h: 0 }

  public position: BlockType.Vertex = { x: 0, y: 0 }

  private listIndex = 0

  private data: TData | null = null

  // private list: TItem[] = [] as unknown as TItem[]

  protected renderItemActions: BlockType.RenderItemAction<TData, TList>[] = []

  protected renderListActions: BlockType.RenderListAction<TData, TList>[] = []

  protected renderPageAction: BlockType.RenderPageAction<TData, TList> | null = null

  private grid: {
    rows: number
    cols: number
    cellSize: BlockType.Size
  } = {
    rows: -1,
    cols: 1,
    cellSize: { w: 0, h: 0 },
  }

  public setDataList() {}

  // public constructor(
  //   doc: PDFKit.PDFDocument,
  //   cellSize: Partial<BlockType.Size>,
  //   initialPosition?: Partial<BlockType.Vertex>,
  //   cols?: number,
  //   list: TData[],
  // )
  // public constructor(
  //   doc: PDFKit.PDFDocument,
  //   item: TData,
  //   size?: Partial<BlockType.Size>,
  //   initialPosition?: Partial<BlockType.Vertex>,
  // )
  public constructor(
    private doc: PDFKit.PDFDocument,
    data: TData,
    cellSize?: Partial<BlockType.Size>,
    initialPosition?: Partial<BlockType.Vertex>,
    cols = 1,
  ) {
    this.data = data
    this.initialPosition = { ...this.initialPosition, ...initialPosition }
    this.position = { ...this.position, ...initialPosition }
    this.grid.cellSize = { ...this.grid.cellSize, ...cellSize }
    this.grid.cols = cols
    this.doc && this.doc.moveTo(this.initialPosition.x, this.initialPosition.y)
  }

  public setGrid(cols = 1, rows = -1) {
    this.grid.rows = rows
    this.grid.cols = cols
    return this
  }

  public setGridCellSize(width: number, height: number) {
    this.grid.cellSize = {
      w: width,
      h: height,
    }
    return this
  }

  public getPosition = () => this.position

  protected listPosition: BlockType.Vertex = this.getPosition()

  public moveToIndex(index: number) {
    const colIndex = index % this.grid.cols
    const rowIndex = Math.floor(index / this.grid.cols)
    // reset position
    const x = colIndex * this.grid.cellSize.w + this.listPosition.x
    const y = rowIndex * this.grid.cellSize.h + this.listPosition.y
    return this.moveTo(x, y)
  }

  public moveNext() {
    this.listIndex += 1
    return this.moveToIndex(this.listIndex)
  }

  public newPage() {
    this.render()
  }

  // public attachBlock<TNewData>(data: TNewData, cellSize?: BlockType.Size) {
  //   const fullSize = this.getFullSize()
  //   const np = {
  //     x: this.initialPosition.x + fullSize.w,
  //     y: this.initialPosition.y + fullSize.h,
  //   }
  //   const ip = this.initialPosition
  //   const create = (positionStyle: 'right' | 'bottom' | 'page' | 'block' | 'position') => {
  //     if (positionStyle === 'position') {
  //       return (position: BlockType.Vertex) => new BlockContext(this.doc, data, cellSize, position)
  //     }

  //     if (positionStyle === 'block') {
  //       return () => new BlockContext(this.doc, data, cellSize, this.initialPosition)
  //     }
  //     if (positionStyle === 'right') {
  //       return (margin = 0, size: BlockType.Size = { w: 20, h: 20 }) =>
  //         new BlockContext(this.doc, data, cellSize, {
  //           x: np.x + margin,
  //           y: ip.y,
  //         })
  //     }
  //     if (positionStyle === 'bottom') {
  //       return (margin = 0) =>
  //         new BlockContext(this.doc, data, cellSize, {
  //           x: ip.x,
  //           y: np.y + margin,
  //         })
  //     }
  //     const p = { x: 0, y: 0 }
  //     return () => new BlockContext(this.doc, data, cellSize, p)
  //   }
  //   return {
  //     toRight: create('right') as (maring: number, size: BlockType.Size) => BlockContext<TNewData>,
  //     toBottom: create('bottom') as (maring: number) => BlockContext<TNewData>,
  //     toPage: create('page'),
  //     toBlock: create('block'),
  //     toPosition: create('position'),
  //   }
  // }

  public getCellSize() {
    return this.grid.cellSize
  }

  public getDataList = (): TList => {
    if (Array.isArray(this.data) && this.dataType === 'list') {
      return this.data as TData & TList
    }
    return [this.data] as TList
  }

  get list() {
    return this.getDataList()
  }

  public getFullSize() {
    const fullWidth = this.grid.cols * this.grid.cellSize.w
    const pageRowsSize =
      this.grid.rows > 0 && Math.ceil(this.list.length / this.grid.cols) >= this.grid.rows
        ? this.grid.rows
        : Math.ceil(this.list.length / this.grid.cols)
    const fullHeight = pageRowsSize * this.grid.cellSize.h
    return {
      w: fullWidth,
      h: fullHeight,
    }
  }

  public moveTo(x: number, y: number) {
    this.position = { x, y }
    const ap = {
      x: x + this.initialPosition.x,
      y: y + this.initialPosition.y,
    }
    this.doc && this.doc.moveTo(ap.x, ap.y)
    return this
  }

  public nextPage() {
    this.position = { ...this.initialPosition }
    this.doc && this.doc.addPage()
    return this
  }

  private pageSizeV: BlockType.Size = {
    w: 563,
    h: 750,
  }

  get pageSize() {
    return this.pageSizeV
  }

  private set pageSize(value: BlockType.Size) {
    this.pageSizeV = value
  }

  public setPageSize(size: BlockType.Size) {
    this.pageSize = size
  }

  public setPageRender(action: BlockType.RenderPageAction<TData, TList>) {
    this.renderPageAction = action
    return this
  }

  // Render actions
  public svgWrap(content: string) {
    const p = { ...this.position }
    if (content.length > 0) {
      return `<svg x="${p.x}" y="${p.y}">${content}</svg>`
    }
    return ``
  }

  private renders: string[] = []

  public addRender(action: BlockType.RenderListAction<TData, TList>) {
    // const p = this.getPosition()
    // const newP =
    //   size !== null
    //     ? {
    //         x: direction === 'x' ? p.x + size.w : p.x,
    //         y: direction === 'y' ? p.y + size.h : p.y,
    //       }
    //     : p
    const r = action(this, this.list) || ``
    this.renders.push(this.svgWrap(r))
    // PdfHelper.addSVG(this.doc, this.svgWrap(r), 0, 0, {
    //   preserveAspectRatio: 'xMaxYMax slice',
    //   width: this.pageSize.w,
    //   height: this.pageSize.h,
    // })
    // this.moveTo(newP.x, newP.y)
    return this
  }

  private renderPageActionDefault(content: string) {
    const rendersStr = this.renders.reduce((pv, c) => `${pv}${c}`, content)
    this.renderPageWrap(rendersStr)
    return this
  }

  private renderPageWrap(content: string) {
    const tpl = `<svg width="${this.pageSize.w}" height="${this.pageSize.h}" viewBox="0 0 ${this.pageSize.w} ${this.pageSize.h}" xmlns="http://www.w3.org/2000/svg">${content}</svg>`
    PdfHelper.addSVG(this.doc, tpl, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  }

  abstract addRenderItem(action: BlockType.RenderItemAction<TList, TList>): typeof this

  private renderPage(pageItems: TList[number][], pageIndex: number) {
    if (pageIndex > 0) this.nextPage()
    // renders list
    let pageContent = pageItems
      .map((item, i) => {
        // this.moveTo(this.listPosition.x, this.listPosition.y)
        const itemsRendered = this.renderItemActions.map((action) => {
          this.moveToIndex(i)
          const localCtx = this as typeof this
          const rendered = action(localCtx, item, i, pageItems) || ``
          return rendered.length > 0 ? this.svgWrap(rendered) : ``
        })
        return itemsRendered
      })
      .reduce((content, render) => `${content}${render}`, ``)
    // renders simple
    pageContent = this.renders.reduce(
      (allrenders, renderItem) => `${allrenders}${renderItem}`,
      pageContent,
    )
    if (this.renderPageAction === null) {
      this.renderPageActionDefault(pageContent)
    } else {
      this.renderPageAction(this, this.list, pageContent)
    }

    return this
  }

  public render() {
    const tempItems = [...this.list]
    const pages = [] as TList[number][][]
    if (this.grid.rows > 0) {
      while (tempItems.length) {
        pages.push(tempItems.splice(0, this.grid.cols * this.grid.rows))
      }
    } else {
      pages.push(tempItems)
    }
    pages.forEach((pageItems, pageIndex) => this.renderPage(pageItems, pageIndex))
    return this
  }
}

export class ListContext<TItem, TList extends TItem[] = TItem[]> extends BlockContext<
  TList,
  TList
> {
  public constructor(
    doc: PDFKit.PDFDocument,
    list: TList,
    cellSize?: Partial<BlockType.Size>,
    initialPosition?: Partial<BlockType.Vertex>,
    cols = 1,
  ) {
    super(doc, list, cellSize, initialPosition, cols)
    super.setDataType('list')
  }

  // Render setup
  public addRenderItem(action: BlockType.RenderItemAction<TList, TList>) {
    this.listPosition = this.getPosition()
    this.renderItemActions.push(action)
    return this
  }
}
// export module BlockContext {}
