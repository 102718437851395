import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

import './FollowingNeighbourhoodList.scss'
import Star from 'views/shared/components/icons/Star'
import Chart from 'views/shared/components/icons/Chart'
import { useFollows } from 'views/follows/hooks'

export const FollowingNeighbourhoodList = (): ReactElement => {
  const { followedNeighbourhoods } = useFollows()
  const history = useHistory()

  return (
    <>
      {followedNeighbourhoods.length > 0 ? (
        <>
          <div className="flex items-center pt-10 mb-6 text-accent-color font-semibold text-xl text-left h-auto">
            Neighbourhoods You&#39;re Following
            <span className="ml-3">
              <Star
                style={{ fontSize: '24px' }}
                fill="var(--accent-color)"
                color="var(--accent-color)"
              />
            </span>
          </div>
          <div className="neighbourhood__list bg-alpha-color rounded-lg p-4">
            <div>
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {followedNeighbourhoods.map((neighbourhood) => {
                  if (!neighbourhood) return undefined
                  return (
                    <li
                      className=" rounded-lg bg-white w-full text-primary-color"
                      key={neighbourhood.id}
                    >
                      <div className="inline-block py-2 px-2 xl:px-6 w-full h-full">
                        <button
                          type="button"
                          className="flex items-center justify-between align-middle w-full h-full"
                          onClick={() => history.push(`/sold-report/${neighbourhood?.id}`)}
                        >
                          <div className="flex items-center">
                            <span className="mr-2">
                              <Star
                                style={{ fontSize: '24px' }}
                                fill="var(--primary-color)"
                                color="var(--primary-color)"
                              />
                            </span>
                            <span className="text-xl text-left leading-8 font-semibold pl-2">
                              {neighbourhood.name}
                            </span>
                          </div>
                          <div className="ml-auto mr-4">
                            <Chart
                              style={{ fontSize: '24px', fontWeight: 'lighter' }}
                              fill="var(--primary-color)"
                              color="var(--primary-color)"
                            />
                          </div>
                        </button>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="pt-10 text-accent-color font-semibold text-xl">
            You’re not following any neighbourhoods yet.
          </div>
          <div className="flex items-baseline mt-6 mb-10 py-5 px-6 bg-alpha-color rounded-xl">
            <span className="mr-3">
              <Star style={{ fontSize: '24px' }} fill="transparent" color="var(--accent-color)" />
            </span>
            <p className="text-default-color font-semibold text-base">
              Use the star to add neighbourhoods to your Follow list and to retrieve them quickly.
            </p>
          </div>
        </div>
      )}
    </>
  )
}
