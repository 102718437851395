import { API } from 'aws-amplify'
import type { MonthStr, UserData, UserDataDetails } from 'types'
import { UserTypes } from './UserTypes'

export module UserServices {
  const apiName = 'user'

  // Get My Profile
  export const getMyProfile = async () => {
    const responseData = (
      await API.get(apiName, '/myprofile', {
        response: true,
      })
    ).data as UserDataDetails
    return responseData
  }

  // Get My Profile by ID
  export const getMyProfileById = async (id: string) => {
    const responseData = (
      await API.get(apiName, `/myprofile/${id}`, {
        response: true,
      })
    ).data as UserDataDetails
    return responseData
  }

  // Update My Profile
  export const updateMyProfile = async (data: UserDataDetails) => {
    const responseData = (
      await API.put(apiName, '/myprofile', {
        response: true,
        body: data,
      })
    ).data as UserDataDetails
    return responseData
  }

  // Get Profiles by ID
  export const getProfileById = async (id: string) => {
    const responseData = (
      await API.get(apiName, `/profiles/${id}`, {
        response: true,
      })
    ).data as UserDataDetails
    return responseData
  }
  export const getUserById = async (id: string) => {
    const responseData = (
      await API.get(apiName, `/users/${id}`, {
        response: true,
      })
    ).data as UserData
    return responseData
  }
  // Get My Agent's Profile
  export const getMyAgentProfile = async () => {
    const responseData = (
      await API.get(apiName, `/profiles/myagent`, {
        response: true,
      })
    ).data as UserDataDetails
    return responseData
  }

  // Update Profile
  export const updateProfile = async (data: UserDataDetails, id: string) => {
    const responseData = (
      await API.put(apiName, `/profiles/${id}`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Add Admin Role
  export const addAdminRole = async (id: string, data: UserDataDetails) => {
    const responseData = (
      await API.put(apiName, `/users/${id}/role/admin`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Delete Admin Role
  export const deleteAdminRole = async (id: string, data: UserDataDetails) => {
    const responseData = (
      await API.del(apiName, `/users/${id}/role/admin`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Add Agent Role
  export const addAgentRole = async (id: string, data: UserDataDetails) => {
    const responseData = (
      await API.put(apiName, `/users/${id}/role/agent`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Delete Agent Role
  export const deleteAgentRole = async (id: string, data: any) => {
    const responseData = (
      await API.del(apiName, `/users/${id}/role/agent`, {
        response: true,
        body: data,
      })
    ).data as any
    return responseData
  }

  // Invite an Admin
  export const inviteAdmin = async (data: UserDataDetails) => {
    const responseData = (
      await API.post(apiName, `/invite/admin`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Invite an Agent
  export const inviteAgent = async (data: UserDataDetails) => {
    const responseData = (
      await API.post(apiName, `/invite/agent`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Invite a Client for an Agent
  export const inviteClientForAgent = async (aid: string, data: UserDataDetails) => {
    const responseData = (
      await API.post(apiName, `/invite/agent/${aid}/client`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Invite a Client without an Agent
  export const inviteClient = async (data: UserDataDetails) => {
    const responseData = (
      await API.post(apiName, `/invite/client`, {
        response: true,
        body: data,
      })
    ).data
    return responseData
  }

  // Check if invite exists
  export const checkInvite = async (email: string) => {
    const responseData = (
      await API.get(apiName, `/invite/${email}`, {
        response: true,
      })
    ).data
    return responseData
  }

  export const emailClick = async (emailId: string, path?: string) => {
    const responseData = (
      await API.post(apiName, `/email/click/${emailId}?path=${path}`, {
        response: true,
      })
    ).data
    return responseData
  }

  // Check SES email identity
  export const sesIdentityVerification = async (uid: string) => {
    const responseData = (
      await API.post(apiName, `/verifySesIdentity/${uid}`, {
        response: true,
      })
    ).data
    return responseData
  }

  // Resent Invite
  export const resendInvite = async (email: string) => {
    const responseData = (
      await API.put(apiName, `/invite/${email}/resent`, {
        response: true,
        body: email,
      })
    ).data as UserDataDetails
    return responseData
  }
  // export type GetUsersParametersType = {
  //   from?: number
  //   sort?: UserSortType
  //   roles?: UserRoleType
  //   size?: number
  // }
  // export type UserSortType = 'name' | 'email' | 'phone' | 'roles'
  // export type UserRoleType = 'agent' | 'admin' | null
  // export type GetUsersResponse = {
  //   total: number
  //   hits: UserDataDetails[]
  // }

  // Get user list
  export const getUsers = async ({
    q,
    from,
    sort,
    roles,
    size = 20,
    agentId,
  }: UserTypes.GetUsersParametersType) => {
    const responseData = (
      await API.get(apiName, `/users`, {
        response: true,
        queryStringParameters: {
          from,
          sort,
          roles: roles === null ? 'null' : roles,
          size,
          q,
          agentId,
        },
      })
    ).data as UserTypes.GetUsersResponse
    return responseData
  }

  export const getUser = async (userId: string): Promise<UserTypes.GetUserResponse> =>
    API.get(apiName, `/users/${userId}`, {
      response: true,
    }).then((result) => result.data)

  export const exportWaitlist = async (): Promise<string> =>
    API.get(apiName, '/admin/exportwaitlist', {
      response: true,
    }).then((result) => result.data.filename)

  export const getUsersByAgentId = async ({ agentId, sort }: UserTypes.GetUsersByAgentParameters) =>
    getUsers({
      agentId,
      from: 0,
      size: 1000,
      roles: null,
      sort: sort || 'averageSessionDuration.desc',
      q: '',
    })

  // export const getUsersByAgentId = async ({ agentId }: UserTypes.GetUsersByAgentParameters) => {
  //   const responseData = (
  //     await API.get(apiName, `/agent/${agentId}/users`, {
  //       response: true,
  //     })
  //   ).data as UserTypes.GetUsersResponse
  //   return responseData
  // }

  // Get Cognito Identity Id
  export const getIdentity = async () => {
    const responseData = await API.get(apiName, `/identity`, {
      response: true,
    }).then((response) => response.data)
    return responseData
  }

  // NOTES:
  // Create a note
  export const createNote = async (userId: string, noteMessage: string) => {
    const responseData = (
      await API.post(apiName, `/users/${userId}/notes`, {
        response: true,
        body: {
          note: noteMessage,
        },
      })
    ).data
    return responseData
  }
  // Get notes for a user
  export const getUserNotes = async (userId: string) => {
    const responseData = (
      await API.get(apiName, `/users/${userId}/notes`, {
        response: true,
      })
    ).data as UserTypes.GetNotesResponse
    return responseData
  }
  // Update a note
  export const updateNote = async (userId: string, noteId: string, noteMessage: string) => {
    const responseData = (
      await API.put(apiName, `/users/${userId}/notes/${noteId}`, {
        response: true,
        body: {
          note: noteMessage,
          id: noteId,
        },
      })
    ).data
    return responseData
  }
  // Delete a note
  export const deleteNote = async (userId: string, noteId: string) => {
    const responseData = (
      await API.del(apiName, `/users/${userId}/notes/${noteId}`, {
        response: true,
      })
    ).data
    return responseData
  }

  // Archive user
  export const archiveUser = async (id: string) => {
    const responseData = (
      await API.put(apiName, `/profiles/${id}/archive`, {
        response: true,
      })
    ).data
    return responseData
  }

  // Subscripions
  // the subscriptions data is in the Profile
  // export const getSubscriptions = async (userId: string) => {
  //   const responseData = (
  //     await API.get(apiName, `/mailing/${userId}`, {
  //       response: true,
  //     })
  //   ).data
  //   return responseData
  // }
  export const updateSubscriptions = async (enabled: boolean, uid?: string) => {
    const responseData = (
      await API.put(apiName, `/subscription`, {
        response: true,
        body: {
          uid,
          receiveEmail: enabled,
          receiveSoldReport: enabled,
        },
      })
    ).data
    return responseData
  }
  // --- Follow-up Notifications
  // Create a follow-up
  export const createFollowUp = async (
    userId: string,
    data: { note: string | undefined; date: number | undefined | MonthStr },
  ) => {
    const responseData = (
      await API.post(apiName, `/users/${userId}/follow-up`, {
        response: true,
        body: { ...data },
      })
    ).data as UserTypes.FollowUpData
    return responseData
  }

  export const getUserFeeds = async (
    userId: string,
    month: string,
  ): Promise<UserTypes.GetUserFeedsResponse> =>
    API.get(apiName, `/users/${userId}/feeds`, {
      response: true,
      queryStringParameters: {
        month,
      },
    }).then((result) => result.data)

  // Import list of clients
  export const importClients = async () => {
    const responseData = (
      await API.get(apiName, '/invite/contacts/import', {
        response: true,
      })
    ).data
    return responseData
  }
  // Import Clients for an Agent
  export const importClientsForAgent = async (aid: string) => {
    const responseData = (
      await API.get(apiName, `/invite/contacts/${aid}/import`, {
        response: true,
      })
    ).data
    return responseData
  }
  // Get Email Analytics
  export const getEmailAnalytics = async ({ month }: UserTypes.GetEmailAnalyticsParameters) => {
    const responseData = (
      await API.get(apiName, `/analytics/email`, {
        response: true,
        queryStringParameters: { time: month },
      })
    ).data as UserTypes.GetEmailAnalytics
    return responseData
  }
}
