import styled from 'styled-components'

export const ListWrap = styled.ul`
  background-color: white;
  overflow-x: hidden;
  @media only screen and (min-width: 1024px) {
    height: calc(100vh - 400px);
  }
`
export const Item = styled.li<{
  selected?: boolean
}>`
  background-color: ${(p) => (p.selected ? 'var(--accent-soft-color)' : 'var(--alpha-color)')};
  padding: 0px 0px 18px 24px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid var(--alpha-dark-color);
  &:first-child {
    border-top: 1px solid var(--alpha-dark-color);
  }
  &.--selected,
  &:hover {
    background-color: var(--accent-soft-color);
    color: var(--on-primary-color);
    border-color: transparent;
  }
`
export const SortingButton = styled.button<{ selected?: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--primary-color);
  padding: 13px 20px;
  width: auto;
  height: 48px;
  border-radius: 24px;
  border: 1px solid var(--primary-color);
  &.--selected {
    background-color: var(--primary-color);
    color: #ffffff;
  }
`
export const SortingDropdown = styled.select`
  right: auto;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 160px;
  height: 48px;
  background: #ffffff;
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--default-color);
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--primary-soft-color);
  }
`
export const ScrollableList = styled.ul`
  overflow-y: auto;
`
