import styled from 'styled-components'

export const ListWrap = styled.ul`
  height: calc(100vh - 530px);
  margin: 10px 5px 20px 5px;
  @media only screen and (min-width: 1024px) {
    height: calc(100vh - 400px);
  }
`
export const Item = styled.li<{
  selected?: boolean
}>`
  background-color: ${(p) => (p.selected ? 'var(--accent-soft-color)' : 'transparent')};
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid var(--alpha-dark-color);
  &:first-child {
    border-top: 1px solid var(--alpha-dark-color);
  }
  &.--selected,
  &:hover {
    background-color: var(--accent-soft-color);
    // color: var(--on-primary-color);
    border-color: transparent;
  }
`
