import React from 'react'
import { Link } from 'react-router-dom'

import { paths } from '../paths'

export const Labs = () => (
  <div className="p-4">
    <h1 className="text-xl font-bold p-4">Labs and Tests</h1>
    <ul className=" p-4">
      <li>
        <Link to={paths.uiForm()}>UI Form</Link>
      </li>
      <li>
        <Link to={paths.files()}>Files</Link>
      </li>
      <li>
        <Link to={paths.stateForm()}>StateForm</Link>
      </li>
    </ul>
  </div>
)
