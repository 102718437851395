import { UserRole } from 'constants/UserConstants'
import React, { useContext, useEffect, useState } from 'react'
import { HomeValuationServices } from 'services/homeValuation'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValuationInfo, HomeValueEstimation } from 'types'
import { PropertyListing } from 'types/Property'
import { DateHelper } from 'utilities/helpers/dateHelper'
import { AuthContext } from 'views/authentication/contexts/AuthContext'
import { SimilarSold } from 'views/homeValuation/SimilarSold'
import { Spinner } from 'views/shared/components/icons'
import Check from 'views/shared/components/icons/Check'
import { SecondaryButton, SecondaryNegativeButton } from 'views/shared/components/ui-form'
import { getInstancePrice } from '../shared'

interface ExtendedDetailsProps {
  valuation: HomeValueEstimation
  setShowModal: (showModal: boolean) => void
  similarProperties: PropertyListing[] | undefined
  setSimilarProperties: (properties: PropertyListing[]) => void
}

export const ExtendedDetails = ({
  valuation,
  setShowModal,
  similarProperties,
  setSimilarProperties,
}: ExtendedDetailsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!similarProperties)
  const { setValuationInfoState } = useHomeValuationStore()
  const { authUser } = useContext(AuthContext)

  const handleRemove = () => {
    valuation.id &&
      HomeValuationServices.removeHomeValuation(valuation.id).then(() => {
        try {
          window.location.reload()
        } catch (e) {
          console.error(e)
        }
      })
  }

  const fullName = (profile?: any): string => {
    if (!profile) {
      return 'N/A'
    }
    return `${profile.firstName} ${profile.lastName}`
  }

  const displayConfirmedBy = () => {
    if (authUser?.roles?.includes(UserRole.AGENT)) return 'you'
    return fullName(valuation.agent)
  }

  const displayValuationBy = () => {
    if (authUser?.roles?.includes(UserRole.AGENT) || authUser?.roles?.includes(UserRole.ADMIN))
      return `${fullName(valuation.user)}'s`
    return 'Your'
  }

  const handleEditPropertyDetails = () => {
    const propertyDetails: HomeValuationInfo = {
      locationId: valuation.request?.locationId,
      unit: valuation.request?.unit || valuation.lastListing?.unit,
      type: valuation.request?.type || valuation.lastListing?.type,
      footage: valuation.request?.footage || (valuation.lastListing?.footage as number | undefined),
      bedroom: valuation.request?.bedroom || valuation.lastListing?.bedroom,
      bathroom: valuation.request?.bathroom || valuation.lastListing?.bathroom,
      lotFront:
        valuation.request?.lotFront || (valuation.lastListing?.lotFront as number | undefined),
      lotDepth:
        valuation.request?.lotDepth || (valuation.lastListing?.lotDepth as number | undefined),
      parking: valuation.request?.parking || valuation.lastListing?.parking,
      purchaseDate: valuation.request?.purchaseDate || valuation.lastPurchaseDate,
      purchasePrice: valuation.request?.purchasePrice || valuation.lastPurchasePrice,
      homeImprovements: valuation.request?.homeImprovements,
      address: valuation.request?.address || valuation.lastListing?.address,
    }
    setValuationInfoState(propertyDetails)
    setShowModal(true)
  }

  useEffect(() => {
    valuation.id &&
      !similarProperties &&
      HomeValuationServices.getHomeValuationSimilarProperties(valuation.id).then((res) => {
        try {
          setSimilarProperties(res)
        } catch (e) {
          console.error(e)
        }
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <div className="flex flex-col md:flex-row my-5 md:my-8 space-x-10">
        <div className="flex flex-col">
          <div className="w-full md:w-80">
            {/* TODO: Add modal onClick */}
            <SecondaryNegativeButton onClick={() => handleEditPropertyDetails()}>
              Edit property details
            </SecondaryNegativeButton>
          </div>
          <div className="md:hidden w-full mt-3">
            {/* TODO: Add modal onClick */}
            {valuation && (
              <SecondaryButton onClick={() => handleEditPropertyDetails()}>
                Discuss with {valuation.agent?.firstName}
              </SecondaryButton>
            )}
          </div>
          <div className="mx-auto my-2 md:my-6">
            <button
              type="button"
              className="hidden md:block px-4 py-1 border border-none text-warning-color text-sm font-semibold underline"
              onClick={handleRemove}
            >
              Remove Valuation
            </button>
          </div>
        </div>

        {valuation.confirmed && (
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex items-center h-fit">
                <div className="hidden md:block flex justify-center items-center rounded-full bg-accent-color w-16 h-16">
                  <div className="w-full h-full flex justify-center items-center">
                    <Check color="white" width={36} height={36} />
                  </div>
                </div>
                <div className="md:hidden flex justify-center items-center rounded-full bg-accent-color w-6 h-6">
                  <Check color="white" width={15} height={15} />
                </div>
                <div className="flex flex-col justify-center ml-4 md:ml-8">
                  {valuation.confirmed && (
                    <div className="text-accent-color text-xl md:text-4xl font-semibold">
                      Valuation Confirmed
                    </div>
                  )}
                </div>
              </div>
              <div className="md:ml-24 my-4">
                <div className="text-xs md:text-lg">
                  {displayValuationBy()} Valuation of{' '}
                  <span className="font-semibold">
                    {getInstancePrice(valuation.confirmedValue)}
                  </span>{' '}
                  was confirmed
                  <div className="my-2">
                    by {displayConfirmedBy()} on{' '}
                    <span className="font-semibold">
                      {valuation.confirmedAt &&
                        DateHelper.formatDateToFull(new Date(valuation.confirmedAt))}
                    </span>
                  </div>
                </div>
                <div className="my-5 text-sm md:text-md">
                  <div className="flex">
                    <div className="font-semibold">Notes from {displayConfirmedBy()}</div>
                    {/* <div className="mx-3 text-default-color">May 22</div> */}
                  </div>
                  <div className="my-1 bg-gray-100 text-xs md:text-lg italic p-3 md:px-10 md:py-4 rounded-lg">
                    {valuation.agentNotes}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mx-auto">
        {isLoading ? (
          <div className="flex w-full justify-center items-center">
            <div className="relative">
              <Spinner style={{ fontSize: '128px' }} color="#018363" />
            </div>
          </div>
        ) : (
          <SimilarSold property={valuation?.summary} similarProperties={similarProperties} />
        )}
      </div>
    </>
  )
}
