import React, { useEffect, useState } from 'react'
import { TextBody } from 'views/shared/components/ui-form'
import { Duration, Location, Logins, Star } from 'views/shared/components/icons'
import styled from 'styled-components'
import { useNeighbourhoods } from 'views/map/hooks'
import Moment from 'moment'
import { Spinner } from 'views/shared/components/spinner'
import { useUserStore } from 'store/dashboard/user'
import { HomeValuationServices } from 'services/homeValuation'
import { HomeValuationAnnualReport, HomeValueEstimation } from 'types'
import { useHistory } from 'react-router-dom'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { useSearchParams } from 'utilities/hooks/useSearchParams'
import { EditAnnualReportForm } from './EditAnnualReportForm'

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--accent-color);
  margin-bottom: 8px;
`
const Item = styled.li`
  border-bottom: 1px solid var(--alpha-dark-color);
  padding: 10px 0px;
`

export type UserInsightsProps = {
  id?: string
}

export const UserInsights = () => {
  const history = useHistory()
  const searchParams = useSearchParams()
  const { neighbourhoods, isLoading: neighbourhoodIsLoading } = useNeighbourhoods()
  const [isLoadingHomeValues, setIsLoadingHomeValues] = useState<boolean>(false)
  const path = window.location.pathname
  const userIndex = path.includes('agent') && path.includes('profile') ? 1 : 0
  const { userState } = useUserStore(userIndex)
  const [valuationList, setValuationList] = useState<HomeValueEstimation[]>()
  const [annualReportList, setAnnualReportList] = useState<HomeValuationAnnualReport.Valuation[]>()
  const [editingAnnualReport, setEditingAnnualReport] =
    useState<HomeValuationAnnualReport.Valuation | null>(null)

  const findNeighbourhoodName = (nid?: string) =>
    neighbourhoods.find((neighbourhood) => neighbourhood.id === nid)?.name || ''

  const onClickView = (valuationId: string) => {
    history.push(`/home-valuation-summary/${valuationId}`)
  }

  const onClickCreate = async (index: number) => {
    if (!valuationList) {
      setEditingAnnualReport(null)
      return
    }
    const reportId = valuationList[index].id
    if (!reportId) {
      setEditingAnnualReport(null)
      return
    }

    await HomeValuationServices.createAnnualReports(reportId).then((rp) => {
      setEditingAnnualReport(rp)
      setAnnualReportList([rp, ...(annualReportList || [])])
    })
  }

  const onClickPrint = async (preport: HomeValuationAnnualReport.Valuation) => {
    await HomeValuationServices.printAnnualReport(preport.id).then((newReport) => {
      if (newReport && annualReportList) {
        setAnnualReportList(
          annualReportList.map((report) => (report.id === newReport.id ? newReport : report)),
        )
      }
    })
  }

  const onClickDelete = async (preport: HomeValuationAnnualReport.Valuation) => {
    await HomeValuationServices.removeAnnualReport(preport.id).then((newReport) => {
      if (newReport && annualReportList) {
        setAnnualReportList(annualReportList.filter((report) => report.id !== preport.id))
      }
    })
  }

  const afterEditingAnnualReport = async (
    newReport: HomeValuationAnnualReport.Valuation | null,
  ) => {
    if (newReport && annualReportList) {
      setAnnualReportList(
        annualReportList.map((report) => (report.id === newReport.id ? newReport : report)),
      )
    }
    setEditingAnnualReport(null)
  }

  const user = userState.data
  const { followedNeighbourhoods } = userState

  useEffect(() => {
    async function fetchData() {
      setIsLoadingHomeValues(true)
      await Promise.all([
        HomeValuationServices.getContactsHomeValuations(user?.id).then(setValuationList),
        HomeValuationServices.getContactsAnnualReports(user?.id).then((results) => {
          setAnnualReportList(results)
          const annualReportIdInUrl = searchParams.get('report')
          if (annualReportIdInUrl && results) {
            const rep = results.find((r) => r.id === annualReportIdInUrl)
            if (rep) {
              setEditingAnnualReport(rep)
            }
          }
        }),
      ]).finally(() => setIsLoadingHomeValues(false))
    }
    fetchData()
  }, [])

  if (neighbourhoodIsLoading || !userState.details) {
    return (
      <div>
        <div className="flex flex-col justify-center h-96">
          <Spinner className="h-32" alt="Loading" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-8 mt-5">
        <Title>Web</Title>
        <ul className="grid grid-cols-2 sm:grid-cols-3 gap-x-5">
          <li className="flex flex-col items-start">
            <Logins
              style={{ fontSize: '24px', marginBottom: '8px' }}
              color="var(--alpha-dark-color)"
            />

            <p>
              <span className="text-xl text-accent-color font-semibold">{user?.logins || 0}</span>
              <span className="text-base text-secondary-color font-semibold"> Logins</span>
            </p>
            {user?.lastLoginAt && (
              <TextBody fontSize={12}>Last {Moment(user.lastLoginAt).format('MMM DD yy')}</TextBody>
            )}
          </li>
          <li className="flex flex-col items-start">
            <Duration
              style={{ fontSize: '24px', marginBottom: '8px' }}
              color="var(--alpha-dark-color)"
            />
            <p className="text-xl text-accent-color font-semibold">
              {Moment.duration((user?.totalSessionDuration || 0) / (user?.logins || 1))
                .locale('en')
                .humanize(false)}
            </p>
            <TextBody fontSize={12}>Av. time on site</TextBody>
          </li>
          <li className="flex flex-col items-start">
            <Location
              style={{ fontSize: '24px', marginBottom: '8px' }}
              color="var(--alpha-dark-color)"
            />
            <div className="flex items-baseline space-x-2">
              <p className="text-xl text-accent-color font-semibold">
                {user?.totalReportOpen || 0}
              </p>
              <p className="text-base text-secondary-color font-semibold">Sold Reports</p>
            </div>
            <TextBody fontSize={12}>Opened</TextBody>
          </li>
        </ul>
      </div>
      <div className="mb-8 mt-5">
        <div className="flex items-center space-x-5">
          <Title>Home Valuations</Title>
        </div>
        {isLoadingHomeValues && (
          <div>
            <div className="flex flex-col justify-center h-16">
              <Spinner className="h-16" alt="Loading" />
            </div>
          </div>
        )}
        <div className="max-h-56 pr-5 overflow-y-auto  scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
          {!isLoadingHomeValues &&
            valuationList?.map((valuation, index) => (
              <div key={valuation.id} className="sm:flex sm:justify-between border-b py-2">
                <div className="p-1">{valuation.displayAddress}</div>
                <div className="flex text-primary-color text-sm underline space-x-8">
                  <button
                    type="button"
                    className="font-bold"
                    onClick={() => valuation.id && onClickView(valuation.id)}
                  >
                    Review
                  </button>
                  <button type="button" className="font-bold" onClick={() => onClickCreate(index)}>
                    Create Annual Report
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="mb-8 mt-5">
        <div className="flex items-center space-x-5">
          <Title>Annual Reports</Title>
        </div>
        {isLoadingHomeValues && (
          <div>
            <div className="flex flex-col justify-center h-16">
              <Spinner className="h-16" alt="Loading" />
            </div>
          </div>
        )}
        <div className="max-h-56  pr-5 overflow-y-auto  scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
          {!isLoadingHomeValues &&
            annualReportList?.map((areport) => (
              <div key={areport.id} className="sm:flex sm:justify-between border-b py-2">
                <div className="p-1">{areport.homeValue.displayAddress}</div>
                <div className="flex text-primary-color text-sm space-x-8">
                  <button
                    type="button"
                    className="font-bold underline"
                    onClick={() => setEditingAnnualReport(areport)}
                  >
                    Edit
                  </button>
                  {areport.printRequestAt && <div className="p-1">Print Requested</div>}
                  {!areport.printRequestAt && (
                    <button
                      type="button"
                      className="font-bold underline"
                      onClick={() => onClickPrint(areport)}
                    >
                      Request Print
                    </button>
                  )}
                  <button
                    type="button"
                    className="font-bold underline"
                    onClick={() => onClickDelete(areport)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="mb-8 mt-5">
        <div className="flex items-center space-x-5">
          <Title>Followed Neighbourhoods</Title>
          <Star
            style={{ fontSize: '20px', marginBottom: '8px' }}
            fill="var(--accent-color)"
            color="var(--accent-color)"
          />
        </div>
        {followedNeighbourhoods?.neighbourhoods &&
        followedNeighbourhoods?.neighbourhoods.length > 0 ? (
          <ul className="grid grid-cols-2 gap-x-3">
            {followedNeighbourhoods?.neighbourhoods.map((item) => (
              <Item key={item}>
                <p className="text-base font-semibold leading-6">{findNeighbourhoodName(item)}</p>
              </Item>
            ))}
          </ul>
        ) : (
          <p>No followed neighbourhoods.</p>
        )}
      </div>

      <ModalLayer
        onOutsideClick={() => setEditingAnnualReport(null)}
        show={editingAnnualReport !== null}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        {editingAnnualReport && (
          <EditAnnualReportForm
            report={editingAnnualReport}
            closeCallback={afterEditingAnnualReport}
          />
        )}
      </ModalLayer>
    </>
  )
}
