import * as React from 'react'

function SvgMessage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#Message_svg__clip0_413_137)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.263c-6.036 0-10.737 4.1-10.737 8.942 0 2.098.87 4.039 2.35 5.58a10.335 10.335 0 002.381 1.831.632.632 0 01-.62 1.1 11.6 11.6 0 01-2.672-2.056C1.024 14.913 0 12.667 0 10.205 0 4.472 5.479 0 12 0s12 4.472 12 10.205c0 5.684-5.384 10.128-11.831 10.204l-6.173 3.509a.632.632 0 11-.624-1.099l6.316-3.59a.631.631 0 01.312-.082c6.036 0 10.737-4.1 10.737-8.942 0-4.841-4.701-8.942-10.737-8.942z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="Message_svg__clip0_413_137">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgMessage = React.memo(SvgMessage)
export default MemoSvgMessage
