import { atom, useAtom } from 'jotai'
import { AgentServices } from 'services/agents'
import { UserDataDetails } from 'types'

import {
  isUrl,
  maxLength,
  minLenth,
  required,
  stateValidationStore,
  useStateValidation,
} from 'utilities/hooks/validationState'
import { AgentProfile } from 'views/agents/types/agents'

const initial = {
  agentDetails: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    headshot: undefined as string | undefined,
    website: '',
    jobDescription: '',
    aboutMe: '',
    instagram: '',
    linkedin: '',
    facebook: '',
    logo: undefined as string | undefined,
  },
  agent: {},
  testimonial: {
    testimonial: '',
    index: -1,
  },
}
const store = {
  agentDetails: stateValidationStore(initial.agentDetails),
  testimonial: stateValidationStore(initial.testimonial),
}
const agentProfile = atom<Partial<UserDataDetails> | null>(null)
const loading = atom<boolean>(false)

export const useAgentDetailsStore = () => {
  // states
  const [agentProfileState, setAgentProfileState] = useAtom(agentProfile)
  const [isLoading, setIsLoading] = useAtom(loading)

  const agentForm = useStateValidation({
    store: store.agentDetails,
    validation: {
      firstName: [
        required('First name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      lastName: [
        required('Last name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      website: [isUrl('Please, enter a valid url')],
      jobDescription: [minLenth(2, 'Title must have at least 2 characters')],
      aboutMe: [
        minLenth(20, 'Title must have at least 20 characters'),
        maxLength(1000, 'Bio must have at most 1000 characters'),
      ],
      instagram: [isUrl('Please, enter a valid url')],
      linkedin: [isUrl('Please, enter a valid url')],
      facebook: [isUrl('Please, enter a valid url')],
    },
  })

  const testimonialForm = useStateValidation({
    store: store.testimonial,
    validation: {
      testimonial: [
        required('Testimonial is required'),
        minLenth(10, 'Testimonial must have at least 10 characters'),
      ],
    },
  })

  const restoreState = async (data: Partial<AgentProfile> | null = agentProfileState) => {
    if (!data) return
    agentForm.setFieldsData({
      email: data.email || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      phone: data.phone || '',
      headshot: data.headshot,
      website: data.website || '',
      jobDescription: data.jobDescription || '',
      aboutMe: data.aboutMe || '',
      instagram: data.socialMedias?.instagram || '',
      linkedin: data.socialMedias?.linkedIn || '',
      facebook: data.socialMedias?.facebook || '',
      logo: data.logo,
    })
  }
  const fetchAgentProfile = async () => {
    if (agentProfileState) return
    setIsLoading(true)
    await AgentServices.getMyAgentProfile()
      .then((res) => {
        restoreState(res)
        setAgentProfileState(res)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    agentForm,
    testimonialForm,
    restoreState,
    isLoading,
    fetchAgentProfile,
    agentProfileState,
  }
}
