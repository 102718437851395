import React, { useCallback, useState } from 'react'
import { FileServices } from 'services/file/fileServices'

export const Files = () => {
  const [paths, setPaths] = useState<(string | null)[]>([])
  const onChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const results = await FileServices.uploadImages(e.target.files, (i) => `test${i}`)
    setPaths(results)
  }, [])
  return (
    <>
      <div>
        <>
          <input multiple accept="image/jpg" type="file" onChange={onChange} />
          {paths.map((path) =>
            path !== null ? <img key={path} alt="uploaded" src={path} /> : <></>,
          )}
        </>
      </div>
    </>
  )
}
