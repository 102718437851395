import { UserRole } from 'constants/UserConstants'
import { AuthUser } from './types'

export const userHasRequiredRoles = (user: AuthUser | null, roles: UserRole[] | undefined) => {
  if (!roles || roles.length === 0) return true
  return (
    user !== null &&
    Array.isArray(user.roles) &&
    user.roles.filter((role) => (Array.isArray(roles) ? roles.includes(role) : roles === role))
      .length > 0
  )
}
