import { UserRole } from 'constants/UserConstants'
import { ValuationConfirmationPage } from 'views/dashboard/pages/valuations/components/valuationConfirmation/ValuationConfirmation'
import { HomeValuation } from './pages/HomeValuation'
import { PropertyValuation } from './pages/property-valuation/HomeValuationPropertyValuation'
import { paths } from './paths'

export const routes = {
  homeValuation: {
    path: paths.homeValuation(':page'),
    exact: true,
    component: HomeValuation,
    requiredRoles: [UserRole.ADMIN, UserRole.AGENT, UserRole.CLIENT],
  },
  homeValuationSummary: {
    path: paths.homeValuationSummary(':id'),
    exact: false,
    component: PropertyValuation,
  },
}
