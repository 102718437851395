import React from 'react'
import { useHistory } from 'react-router-dom'
import { InfoCard } from 'views/shared/components/info-card/InfoCard'
import { UnderlinedPrimaryButton } from 'views/shared/components/ui-form'
import { InfoCardContent } from '../../property-estimation/InfoCardContent'
import { LineBreaker } from './styles'

export const Header = () => {
  const history = useHistory()
  return (
    <>
      <div className="my-5 md:my-10">
        <div className="flex space-x-3">
          <div className="text-4xl md:text-6xl font-semibold">
            Your
            <LineBreaker /> Valuations
          </div>
          <div className="flex items-end mb-1">
            <InfoCard content={<InfoCardContent />} props={{ width: 25, height: 25 }} />
          </div>
        </div>
        <UnderlinedPrimaryButton
          onClick={() => history.replace('/home-valuation/property-address')}
        >
          + Add New Valuation
        </UnderlinedPrimaryButton>
      </div>
    </>
  )
}
