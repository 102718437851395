import React from 'react'
import styled from 'styled-components'
import { PropertyListing } from 'types/Property'
import moment from 'moment-timezone'
import { TIME_ZONE_NAME, CurrencyHelper } from 'utilities'
import { SoldReportServices } from 'services/soldReport'
import { PropertyType } from 'types'
import { Bath, Bed, Parking } from 'views/shared/components/icons'

const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--default-color);
`

interface PropertyDetailsHeaderInfoProps {
  property: PropertyListing | undefined
}

export const PropertyDetailsHeaderInfo = ({ property }: PropertyDetailsHeaderInfoProps) => {
  const getDateDifference = () => {
    if (property?.soldPrice !== undefined && property?.soldPrice > 0 && property?.soldDate) {
      return `${property?.saleType === 'Lease' ? 'Leased' : 'Sold'} ${moment(
        property.soldDate,
      ).fromNow()}`
    }
    if (property?.status === 'forSale' || property?.status === 'forLease') {
      // active listing
      return `Listed ${moment.tz(property?.listDate, TIME_ZONE_NAME).from(moment().startOf('day'))}`
    }
    return `Listed ${moment.tz(property?.listDate, TIME_ZONE_NAME).fromNow()}`
  }
  const getListedPrice = () => {
    const price = property?.status === 'sold' ? property?.soldPrice : property?.listingPrice
    return CurrencyHelper.currencyFormat(price)
  }

  return (
    <>
      <div className="flex flex-col pb-5 space-y-5 md:space-y-0 md:flex-row md:items-end md:justify-between">
        <div>
          <p className="text-sm text-default-color">{getDateDifference()}</p>
          {property?.soldDate && (
            <p className="text-sm">
              <span className="text-default-color">Listed for</span>{' '}
              <span className="text-secondary-color font-semibold">
                ${property?.listingPrice && CurrencyHelper.currencyFormat(property.listingPrice)}
              </span>
            </p>
          )}
          {property && (
            <p
              className={[
                'text-4xl font-semibold',
                property?.status === 'sold' ? 'text-warning-color' : 'text-accent-color ',
              ].join(' ')}
            >{`$${getListedPrice()}`}</p>
          )}
        </div>
        <div className="flex items-center space-x-10">
          <span className="font-semibold text-secondary-color capitalize">
            {property?.type &&
              SoldReportServices.getPropertyTypeLabel(property.type as PropertyType)}{' '}
          </span>
          <ul className="flex flex-row items-center space-x-5">
            <ListItem className="space-x-2">
              <span>{property?.bedroom}</span>
              <Bed className="text-2xl" color="var(--default-color)" />
            </ListItem>
            <ListItem className="space-x-2">
              <span>{property?.bathroom}</span>
              <Bath className="text-2xl" color="var(--default-color)" />
            </ListItem>
            <ListItem className="space-x-2">
              <span>{property?.parking}</span>
              <Parking style={{ fontSize: '28px' }} color="var(--default-color)" />
            </ListItem>
          </ul>
        </div>
      </div>
    </>
  )
}
