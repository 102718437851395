import { UserServices } from 'services/user'
import { atom, useAtom } from 'jotai'
import { KeyLabel } from 'types'

type AgentListStores = {
  isLoading: boolean
  agentList: KeyLabel[] | null
}
const initial: AgentListStores = {
  isLoading: false,
  agentList: null,
}
const agentListStore = atom<AgentListStores>(initial)

export const useAgentListStore = () => {
  const [agentListState, setAgentListState] = useAtom(agentListStore)

  const fetchAgentList = async () => {
    if (agentListState.agentList !== null || agentListState.isLoading) return
    setAgentListState({ ...initial, isLoading: true })
    UserServices.getUsers({
      roles: 'agent',
      from: 0,
      size: 200,
      sort: 'firstName',
      q: undefined,
    })
      .then((response) => {
        setAgentListState({
          isLoading: false,
          agentList: response.hits.map(
            (hit) =>
              ({
                key: hit.id,
                label: `${hit.details.firstName} ${hit.details.lastName}`.trim() || hit.agent,
              } as KeyLabel),
          ),
        })
      })
      .catch(() => {
        setAgentListState({
          isLoading: false,
          agentList: null,
        })
      })
  }

  return {
    agentListState,
    fetchAgentList,
  }
}
