/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import {
  Checkbox,
  Label,
  PrimaryButton,
  PrimaryNegativeButton,
  Subtitle,
  TextBody,
  Title,
  WarningButton,
} from 'views/shared/components/ui-form'
import { Link } from 'react-router-dom'
import { PageFooter } from 'views/shared/components/page-footer'
import { AuthContext } from 'views/authentication'
import { Write } from 'views/shared/components/icons'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { ImageBox } from 'views/shared/components/image-box'
import { homeStatusKeyLabel } from 'constants/UserConstants'
import { useAgentDetailsStore } from 'store/agentDetails'
import { useClientAccountProfileStore } from 'store/clientAccount/clientAccountStore'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { UserAccountPasswordModal } from './UserAccountPassword'
import { UserAgentDetails } from './UserAgentDetails'

type UserAccountFormType = {
  password?: boolean
  delete?: boolean
}
type ProfileProps = {
  userId: string
}

export const UserAccount = ({ userId }: ProfileProps) => {
  const { authUser, signOut, profileImage, reloadProfile } = useContext(AuthContext)
  const [isModalOpen, setIsModalOpen] = useState<UserAccountFormType>({})
  const { fetchAgentProfile, agentForm, agentProfileState } = useAgentDetailsStore()
  const [isEditingDetails, setIsEditingDetails] = useState<boolean>(false)
  const { fetchMyProfile, saveDetails, restoreState, detailsForm, receiveSoldReportHandler } =
    useClientAccountProfileStore()
  const [isImageModalOpen, setIsImageModalOpen] = useState({ uploadHeadshot: false })
  const [photo, setPhoto] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  const submit = async () => {
    detailsForm.touch()
    await saveDetails()
    detailsForm.isValid() && setIsEditingDetails(false)
    await reloadProfile()
  }
  const cancel = async () => {
    await restoreState()
    setIsEditingDetails(false)
  }
  const onSignOut = () => {
    signOut()
  }
  const closeUnsubscribeModal = () => {
    setShowModal(false)
  }
  const openUnsubscribeModal = () => {
    setShowModal(true)
  }

  useEffect(() => {
    if (authUser) {
      fetchMyProfile()
      fetchAgentProfile()
      setPhoto(profileImage)
    }
  }, [authUser, userId, profileImage])

  return (
    <>
      <div className="max-w-screen-2xl mx-auto w-full px-5 lg:px-20 mt-10 mb-20">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-10">
          <div className="flex items-center justify-between sm:justify-start space-x-5 lg:space-x-10 flex-1">
            <Title>Account Details</Title>
            {!isEditingDetails && (
              <button type="button" onClick={() => setIsEditingDetails(true)}>
                <Write style={{ fontSize: '42px' }} color="var(--primary-color)" />
              </button>
            )}
          </div>
          <div className="flex justify-end mt-5 sm:mt-0">
            <div className="w-36">
              <PrimaryNegativeButton>
                <Link to="/neighbourhood/map" onClick={() => onSignOut()}>
                  Sign Out
                </Link>
              </PrimaryNegativeButton>
            </div>
          </div>
        </div>
        <div>
          <FieldsContainer>
            <InputViewField
              label="Email"
              stateValidation={detailsForm}
              field="email"
              placeholder="Enter email address"
              edit={isEditingDetails}
              type="readonly"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="firstName"
              edit={isEditingDetails}
              label="First Name"
              placeholder="Enter full name"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="lastName"
              edit={isEditingDetails}
              label="Last Name"
              placeholder="Enter full name"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="phone"
              edit={isEditingDetails}
              mask="phone"
              label="Phone Number"
              placeholder="XXX-XXX-XXXX"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="address"
              edit={isEditingDetails}
              label="Mailing Address"
              placeholder="Full mailing address"
            />
            <InputViewField
              stateValidation={detailsForm}
              field="dob"
              edit={isEditingDetails}
              label="DOB"
              mask="date"
              placeholder="mm/dd/yyyy"
            />
            <li className="flex items-center pl-5 h-16">
              <p className="w-28 font-semibold text-secondary-color">Headshot</p>
              {!isEditingDetails && (
                <div>
                  <img
                    className="inline-block h-10 w-10 filter object-cover grayscale"
                    src={photo}
                    alt=""
                  />
                </div>
              )}
              {isEditingDetails && (
                <div className="flex items-center space-x-5">
                  <img
                    className="inline-block h-10 w-10 filter object-cover grayscale"
                    src={photo}
                    alt=""
                  />
                  <div>
                    <button
                      type="button"
                      className="text-primary-color font-semibold underline"
                      onClick={() => setIsImageModalOpen({ uploadHeadshot: true })}
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
              <ImageBox
                isOpen={isImageModalOpen.uploadHeadshot}
                value={detailsForm.state.headshot}
                onChange={(value, path) => {
                  detailsForm.state.headshot = value
                  setPhoto(path)
                  setIsImageModalOpen({ uploadHeadshot: false })
                }}
                onClose={() => setIsImageModalOpen({ uploadHeadshot: false })}
              />
            </li>
            {/* <InputViewField
              label="Contact Preference"
              type="dropdown"
              stateValidation={detailsForm as any}
              field="preferredContactMethod"
              edit={isEditingDetails}
              options={contactPreferenceKeyLabel}
              value={detailsForm.getField('preferredContactMethod')}
            /> */}
            <li className="flex flex-col sm:flex-row sm:items-center px-5 py-1 h-14">
              <Label className="w-36">Password</Label>
              <div className="flex">
                <button
                  type="button"
                  className="text-primary-color font-semibold underline"
                  onClick={() => setIsModalOpen({ password: true })}
                >
                  Reset Password
                </button>
                <UserAccountPasswordModal
                  isOpen={isModalOpen.password}
                  onClose={() => setIsModalOpen({ password: false })}
                />
              </div>
            </li>
          </FieldsContainer>
          <div className="pt-10">
            <Subtitle className="pb-3">Home ownership</Subtitle>
            <FieldsContainer>
              <InputViewField
                label="Status"
                type="dropdown"
                stateValidation={detailsForm as any}
                field="ownershipStats"
                edit={isEditingDetails}
                options={homeStatusKeyLabel}
                placeholder="Select"
                value={detailsForm.getField('ownershipStats')}
              />
              <InputViewField
                label="Purchase / Lease date"
                stateValidation={detailsForm as any}
                field="purchaseDate"
                mask="date"
                edit={isEditingDetails}
                placeholder="mm/dd/yyyy"
                value={detailsForm.getField('purchaseDate')}
              />
            </FieldsContainer>
          </div>
          {isEditingDetails && (
            <div className="max-w-lg mx-auto md:mx-0 md:ml-auto space-x-3 flex pb-8 pt-2">
              <PrimaryButton onClick={submit} type="button">
                Save
              </PrimaryButton>
              <PrimaryNegativeButton onClick={cancel}>Cancel</PrimaryNegativeButton>
            </div>
          )}
        </div>
        <div className="my-10 space-y-5">
          <div>
            <Subtitle>Monthly Sold Report Newsletter</Subtitle>
          </div>
          <div className="sm:flex sm:space-x-10">
            <p className="text-base font-semibold mb-3 sm:mb-0">By Email</p>
            <div className="flex items-center space-x-3">
              <Checkbox
                checked={detailsForm.getField('receiveSoldReport')}
                onChange={(e) => {
                  if (detailsForm.state.receiveSoldReport === true) {
                    openUnsubscribeModal()
                  } else {
                    receiveSoldReportHandler(e.currentTarget.checked)
                  }
                }}
                id="receiveSoldReport"
                name="receiveSoldReport"
              />
              {detailsForm.state.receiveSoldReport === true ? (
                <TextBody>Subscribed</TextBody>
              ) : (
                <TextBody>Unsubscribed</TextBody>
              )}
            </div>
            <ModalLayer
              onOutsideClick={closeUnsubscribeModal}
              show={showModal}
              responsive={{
                small: 'full',
              }}
              outsideColor="rgba(0, 0, 0, 0.5)"
            >
              <div className="max-w-sm bg-white rounded-lg">
                <button
                  className="text-xl font-semibold text-primary-color text-right w-full pr-2"
                  type="button"
                  onClick={closeUnsubscribeModal}
                >
                  x
                </button>
                <div className="px-5 pt-0 pb-5">
                  <TextBody className="pb-5">
                    You will no longer receive the monthly sold reports newsletter. Are you sure you
                    want to unsubscribe?
                  </TextBody>
                  <WarningButton
                    onClick={() => {
                      receiveSoldReportHandler(false)
                      closeUnsubscribeModal()
                    }}
                    className="bg-white"
                  >
                    Unsubscribe
                  </WarningButton>
                </div>
              </div>
            </ModalLayer>
          </div>
        </div>
        <div className="mt-10 bg-alpha-color rounded-lg p-5 sm:p-10">
          {agentProfileState !== null ? (
            <UserAgentDetails
              name={`${agentForm.state.firstName} ${agentForm.state.lastName}`.trim()}
              phone={agentForm.state.phone}
              email={agentForm.state.email}
            />
          ) : (
            <div>
              <div className="mb-5">
                <Subtitle className="hidden sm:block">Your Agent Details</Subtitle>
              </div>
              <TextBody>An agent will be assigned to you soon.</TextBody>
            </div>
          )}
        </div>
      </div>
      <PageFooter />
    </>
  )
}
