import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'
import Moment from 'moment'
import { Neighbourhood, PropertyType, SalesDataByTypes } from 'types'
import { FollowNeighbourhood } from 'views/soldReport/components/follow-neighbourhood/FollowNeighbourhood'
import { Title, PrimaryButton, TextBody } from 'views/shared/components/ui-form'
import { SoldReportServices } from 'views/soldReport'
import './NeighbourhoodMapItem.scss'
import { AuthContext } from 'views/authentication'
import { Spinner } from 'views/shared/components/spinner'
import { CurrencyHelper } from 'utilities'

export type NeighbourhoodMapItemProps = {
  neighbourhood: Neighbourhood | null
  neighbourhoodSales: SalesDataByTypes | null
  salesDate: Date | null
  onClose?: () => void
  onOpen?: () => void
}

const propertyTypes = [
  'detatched',
  'semiDetatched',
  'freeholdTownhouse',
  'condo',
  'condoTownhouse',
  'multi',
] as PropertyType[]

export default function NeighbourhoodMapItem({
  neighbourhood,
  neighbourhoodSales,
  salesDate,
  onClose,
}: NeighbourhoodMapItemProps) {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const dialogRef = useRef(null)
  const { authUser } = useContext(AuthContext)

  useEffect(() => {
    if (neighbourhood) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [neighbourhood, neighbourhoodSales])
  const closeHandle = () => {
    setOpen(false)
    setTimeout(() => {
      if (onClose) onClose()
    }, 300)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto bg-gray-900 bg-opacity-80"
        open={open}
        onClose={closeHandle}
        initialFocus={dialogRef}
      >
        <div className="neighbourhood-map_item flex flex-col h-full justify-center align-middle px-4 text-center sm:block sm:p-0 my-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white rounded-lg p-5 text-left overflow-y-auto shadow-xl transform transition-all w-full sm:max-w-xl mb-0 sm:align-middle z-50">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-transparent text-primary-color focus:outline-none"
                  onClick={() => closeHandle()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-5">
                <div className="mt-4 text-left">
                  <div className="flex items-center mb-5">
                    {authUser
                      ? neighbourhood && <FollowNeighbourhood neighbourhoodId={neighbourhood.id} />
                      : null}
                    <Title className="hidden xl:block">{neighbourhood?.name}</Title>
                    <Title fontSize={24} className="block xl:hidden">
                      {neighbourhood?.name}
                    </Title>
                  </div>
                  {neighbourhoodSales && (
                    <div className="mt-2 leading-5 text-sm">
                      <TextBody>
                        Properties sold in{' '}
                        <span className="font-semibold text-secondary-color">
                          {salesDate && Moment(salesDate).format('MMMM, YYYY')}.
                        </span>
                      </TextBody>
                      <TextBody>
                        For more details,{' '}
                        <button
                          type="button"
                          className="text-primary-color font-semibold underline pt-1"
                          onClick={() => history.push(`/sold-report/${neighbourhood?.id}`)}
                        >
                          access the full Sold Report
                        </button>
                        .
                      </TextBody>
                    </div>
                  )}
                  {!neighbourhoodSales && (
                    <div className="flex flex-col justify-center h-96">
                      <Spinner className="h-32" alt="Loading" />
                    </div>
                  )}
                </div>
              </div>

              {neighbourhoodSales && (
                <div className="overflow-x-auto overflow-y-auto sm:overflow-x-hidden mb-5">
                  <div className="align-middle inline-block min-w-full">
                    <div className="overflow-x-hidden overflow-y-auto">
                      <table className="neighbourhood-map_item-table min-w-full whitespace-nowrap">
                        <thead className="text-center">
                          <tr className="text-secondary-color font-semibold text-sm tracking-wider">
                            <th scope="col" className="py-3">
                              {' '}
                            </th>
                            <th scope="col" className="py-3 px-2">
                              Sales
                            </th>
                            <th scope="col" className="py-3 px-2">
                              Average <br />
                              Selling Price
                            </th>
                          </tr>
                        </thead>
                        <tbody className="whitespace-nowrap">
                          {neighbourhoodSales &&
                            propertyTypes.map((key: PropertyType) => (
                              // neighbourhoodSales[key] ? (
                              <tr key={key} className="even:bg-white odd:bg-alpha-color">
                                <td className="py-2 pl-6 font-bold text-base">
                                  <TextBody>
                                    {SoldReportServices.getPropertyTypeLabel(key)}
                                  </TextBody>
                                </td>
                                <td className="py-2 text-secondary-color text-center font-semibold text-base">
                                  {neighbourhoodSales[key].soldNumber}
                                </td>
                                <td className="py-2 text-center font-semibold text-accent-color text-base">
                                  {neighbourhoodSales[key].soldPrice > 0
                                    ? `$${CurrencyHelper.currencyFormat(
                                        neighbourhoodSales[key].soldPrice,
                                      )}`
                                    : '-'}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              <PrimaryButton onClick={() => history.push(`/sold-report/${neighbourhood?.id}`)}>
                View Sold Report
              </PrimaryButton>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
