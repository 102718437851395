import styled from 'styled-components'

export const Root = styled.header`
  min-height: 56px;
  position: relative;
`
export const Content = styled.div`
  background-color: #ffffff;
  height: 56px;
  margin: 0 auto;
  padding: 0 8px 0 40px;
  @media (min-width: 1024px) {
    padding: 0 40px;
  }
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--primary-soft-color);
`
export const Logo = styled.img`
  height: 32px;
  margin-right: 24px;
`
export const ComingSoon = styled.span`
  font-family: 'Montserrat';
  font-size: 10px;
  color: var(--accent-color);
  font-weight: 600;
  display: block;
  line-height: 16px;
  margin: 0;
`
export const ComingSoonAbsolute = styled(ComingSoon)`
  text-align: right;
  position: absolute;
  bottom: 2px;
  right: 24px;
`

// Main Navigation
export const MainNavigation = styled.div`
  align-self: flex-end;

  & > ul {
    display: flex;
    flex-direction: row;
    & > li {
      & > span,
      & > a {
        position: relative;
        display: block;
        margin: auto;
        padding: 0 24px;
        padding-bottom: 14px;
        color: var(--default-color);
        font-size: 16px;
        border-bottom: 3px solid transparent;
        &.--active {
          border-bottom-color: var(--accent-color);
        }
      }
      & > span {
        font-weight: normal;
        cursor: default;
      }
      & > a {
        font-weight: bold;
        color: var(--secondary-color);
      }
    }
  }
`

// Auth and Profile
export const AuthMenu = styled.div`
  margin-left: auto;
  & > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > li {
      & > a {
        display: block;
        padding: 0 12px;
        font-family: 'Montserrat';
        text-decoration: underline;
        font-weight: 600;
        white-space: nowrap;
        &.--primary {
          color: var(--primary-color);
        }
      }
    }
  }
`
export const ProfileMenu = styled.div`
  display: block;
  position: relative;
  & > button {
    cursor: pointer;
    display: block;
    height: 56px;
    & > img {
      display: block;
      height: 40px;
      filter: saturate(0);
    }
    &:hover img,
    &.--active img {
      filter: unset;
    }
  }
`
// DropDown Menus
export const MenuModal = styled.div<{ alignment?: 'right' | 'left' }>`
  float: ${(p) => p.alignment || 'left'};
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 1px;
  margin-right: 0px;
`
export const MenuList = styled.ul`
  display: block;
  list-style: none;
  padding: 10px 0 10px 0;

  & > li {
    & > span,
    & > a,
    & > button {
      display: block;
      line-height: 24px;
      padding: 10px 28px;
      border-left: 4px solid transparent;
      font-size: 16px;
      font-family: 'Montserrat';
      font-weight: 500;
      min-width: 150px;
      text-align: left;
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.05);
        border-color: var(--primary-color);
      }
    }
    & > span {
      color: var(--default-color);
    }
  }
`
export const MenuListMain = styled(MenuList)`
  text-align: left;
  max-width: 90vw;
  width: 420px;
  & > span,
  & > a,
  & > button {
    white-space: normal;
    text-align: left;
  }
`
// Main Mobile Menu
export const MobileMenu = styled.div`
  display: block;
  height: 100%;
  width: 56px;
  position: absolute;
  left: 0;
  @media (min-width: 1280px) {
    display: none;
  }
  & > button {
    display: block;
    height: 100%;
    width: 100%;
    appearance: none;
    font-size: 24px;
    position: relative;
    left: -12px;
    transition: left ease 200ms;
  }
  &.--show > button {
    left: 4px;
  }
  &.--simple-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--primary-color);
  }
`
export const MenuSocial = styled.div`
  display: block;
  padding: 0px 32px 20px;
`
export const MenuAgent = styled.div`
  display: block;
  padding: 32px;
`
export const MenuSocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  & > a {
    display: block;
    margin-right: 32px;
    font-size: 32px;
    padding: 24px 0;
    color: var(--primary-color);
  }
`
export const MenuFooter = styled.div`
  display: block;
  font-weight: 600;
  color: var(--primary-color);
`
