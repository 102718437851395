import React, { ReactChild } from 'react'
import { SecondaryButton } from 'views/shared/components/ui-form'

type ValuationsItemProps = {
  headerIcon?: JSX.Element
  headerLabel?: string
  headerDate?: number | string
  children: ReactChild
  onclick?: () => void
  onclick2?: () => void
  onclick3?: () => void
  onClickDelete?: () => void
  button1Label?: string
  button2Label?: string
  button3Label?: string
  button1Enabled?: boolean
  button2Enabled?: boolean
  button3Enabled?: boolean
  button1Visible?: boolean
  button2Visible?: boolean
  button3Visible?: boolean
  isLoading?: boolean
  deleteButton?: boolean
}

export const ValuationsItem = ({
  headerIcon,
  headerLabel,
  headerDate,
  children,
  button1Label,
  button2Label,
  button3Label,
  button1Enabled = true,
  button2Enabled = true,
  button3Enabled = true,
  button1Visible = true,
  button2Visible = true,
  button3Visible = true,
  onclick,
  onclick2,
  onclick3,
  isLoading = false,
  deleteButton,
  onClickDelete,
}: ValuationsItemProps) => (
  <>
    <div>
      <div className="flex justify-between items-center pb-1 space-x-3 w-full">
        <div className="flex items-baseline space-x-3">
          <span className="text-xl">{headerIcon}</span>
          <span className="text-accent-color font-semibold text-sm">{headerLabel}</span>
        </div>
        <div className="space-x-3">
          <span className="text-default-color text-xs">{headerDate}</span>
          {deleteButton && (
            <button
              type="button"
              className="font-semibold text-lg text-primary-color"
              onClick={() => onClickDelete && onClickDelete()}
            >
              x
            </button>
          )}
        </div>
      </div>
      <div className="bg-alpha-color rounded-lg p-5 sm:py-5 sm:px-8 flex flex-col sm:flex-row sm:items-center sm:justify-between space-x-0 space-y-5 sm:space-y-0 sm:space-x-3">
        <div className="text-sm flex-1">{children}</div>
        <div className="flex space-x-5 items-end rounded-lg">
          {button1Label && button1Visible && (
            <div className="my-2 w-full">
              <SecondaryButton
                disabled={isLoading || !button1Enabled}
                fontSize="12"
                onClick={onclick}
                padding={button1Label.length > 10 ? '6' : '13'}
              >
                {/* {isLoading ? <span>Loading</span> : <span>{button1Label}</span>} */}
                <span>{button1Label}</span>
              </SecondaryButton>
            </div>
          )}
          {button2Label && button2Visible && (
            <div className="my-2 w-full">
              <SecondaryButton
                disabled={isLoading || !button2Enabled}
                fontSize="12"
                onClick={onclick2}
                padding={button2Label.length > 10 ? '6' : '13'}
              >
                {isLoading ? <span>Loading</span> : <span>{button2Label}</span>}
              </SecondaryButton>
            </div>
          )}
          {button3Label && button3Visible && (
            <div className="my-2 w-full">
              <SecondaryButton
                disabled={isLoading || !button3Enabled}
                fontSize="12"
                onClick={onclick3}
                padding={button3Label.length > 10 ? '6' : '13'}
              >
                {isLoading ? <span>Loading</span> : <span>{button3Label}</span>}
              </SecondaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  </>
)
