import React from 'react'

import { ArrowRightBold } from 'views/shared/components/icons'
import { DateHelper } from 'utilities'
import { useUserStore } from 'store/dashboard/user'
import { TextBody } from 'views/shared/components/ui-form'

export const FollowUpItem = () => {
  const { followUpForm } = useUserStore()

  const today = new Date()
  const pastDate = today.setDate(today.getDate() - 1) as Date | number | string
  const currentDate = DateHelper.currentDateStr()

  const hasFollowUp = followUpForm.state.note !== undefined && followUpForm.state.date !== undefined

  return (
    <>
      {hasFollowUp === true && (
        <div>
          <p className="text-default-color text-xs font-semibold mb-1">
            {DateHelper.formatDateStr(currentDate)}
          </p>
          <p className="text-xs font-semibold flex items-center flex-shrink-0">
            <ArrowRightBold
              style={{ fontSize: '12px' }}
              color={
                followUpForm.state.date && followUpForm.state.date < pastDate
                  ? 'var(--warning-color)'
                  : 'var(--primary-color)'
              }
            />
            <span className="text-secondary-color font-semibold pl-1 pr-1">
              Follow-up scheduled on{' '}
            </span>
            <span
              className={[
                '',
                followUpForm.state.date && followUpForm.state.date < pastDate
                  ? 'text-warning-color'
                  : 'text-accent-color',
              ].join(' ')}
            >
              {followUpForm.state.date && DateHelper.formatDateToFull(followUpForm.state.date)}
            </span>
          </p>
          <TextBody className="italic pl-4"> {followUpForm.state.note}</TextBody>
        </div>
      )}
    </>
  )
}
