import * as React from 'react'

function SvgBook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#Book_svg__clip0_603_102)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.842 21.008A3.158 3.158 0 0022 17.85V3.158A3.158 3.158 0 0018.842 0H6.211a3.158 3.158 0 00-3.158 3.158v13.629a.632.632 0 001.263 0V3.157c0-1.046.848-1.894 1.895-1.894h12.631c1.047 0 1.895.848 1.895 1.895V17.85a1.895 1.895 0 01-1.895 1.895H5.18a2.127 2.127 0 100 4.255h13.978a.632.632 0 000-1.263H5.18a.864.864 0 110-1.729h13.662z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="Book_svg__clip0_603_102">
          <path fill={props.color || 'var(--default-icon-color, #000)'} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgBook = React.memo(SvgBook)
export default MemoSvgBook
