import { API } from 'aws-amplify'
import debounce from 'debounce'
import {
  AddressSuggestionQueryType,
  AddressSuggestionType,
  HomeValuationChartData,
  HomeValuationInfo,
  HomeValueAssignRequest,
  HomeValueEstimation,
  ValuationConfirmationForm,
} from 'types'
import { HomeValuationAnnualReport } from 'types/HomeValuationAnualReport'
import { AmplifyAPIResponse, PropertyListing } from 'types/Property'

export module HomeValuationServices {
  const homeValuationApiName = 'homeValue'
  const propertyApiName = 'property'

  export class AmplifyAPIError extends Error {
    public message: string

    public code: number

    constructor({ message, code }: { message: string; code: number }) {
      super(message)
      this.message = message
      this.code = code
    }
  }

  export function unwrapAmplifyAPIResult<T>(result: AmplifyAPIResponse<T>): T {
    if (result.status && result.status >= 400) {
      throw new AmplifyAPIError({ message: result.message || '', code: result.status })
    }
    if (!result.result) {
      throw new AmplifyAPIError({ message: 'no result' || '', code: 404 })
    }
    return result.result
  }

  // Address Complete
  export const findAddress = debounce(async (query: AddressSuggestionQueryType) => {
    const responseData = (
      await API.get(homeValuationApiName, `/addressComplete`, {
        response: true,
        queryStringParameters: query,
      })
    ).data as {
      suggestions?: AddressSuggestionType[]
    }
    return responseData
  }, 300)

  // get last listing
  export const getLastListing = async (locationId: string, unit?: string) => {
    const responseData = await API.get(propertyApiName, `/homevalue/lastlisting`, {
      response: true,
      queryStringParameters: { locationId, unit },
    })
      .then((result) => result.data as AmplifyAPIResponse<PropertyListing>)
      .then((result) => unwrapAmplifyAPIResult<PropertyListing>(result))
    return responseData
  }

  export const createHomeValuation = async (valuationInfo: HomeValuationInfo) => {
    const responseData = (
      await API.post(propertyApiName, '/homevalue', {
        response: true,
        body: valuationInfo,
      })
    ).data
    return responseData as HomeValueEstimation
  }

  export const updateHomeValuation = async (id: string, valuationInfo: HomeValuationInfo) => {
    const responseData = (
      await API.put(propertyApiName, `/homevalue/${id}`, {
        response: true,
        body: valuationInfo,
      })
    ).data
    return responseData as HomeValueEstimation
  }

  export const discussHomeValuation = async (id: string) => {
    const responseData = (
      await API.post(propertyApiName, `/homevalue/${id}/discussion`, {
        response: true,
      })
    ).data
    return responseData
  }

  export const getHomeValuationChart = async (id: string, from?: string, to?: string) => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue/${id}/chart`, {
        response: true,
        queryStringParameters: { from, to },
      })
    ).data
    return responseData as HomeValuationChartData
  }

  export const getHomeValuationSimilarProperties = async (id: string) => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue/${id}/similarSolds`, {
        response: true,
      })
    ).data
    return responseData as PropertyListing[]
  }

  export const getHomeValuation = async (id: string) => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue/${id}`, {
        response: true,
      })
    ).data
    return responseData as HomeValueEstimation
  }

  export const getHomeValuationList = async () => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue`, {
        response: true,
      })
    ).data
    return responseData as HomeValueEstimation[]
  }

  export const updateHomeValueNotification = async (id: string, frequency: string | undefined) => {
    const responseData = (
      await API.put(propertyApiName, `/homevalue/${id}/receiveUpdates`, {
        response: true,
        body: {
          receiveUpdates: frequency,
        },
      })
    ).data
    return responseData
  }

  export const removeHomeValuation = async (id: string) => {
    const responseData = (
      await API.del(propertyApiName, `/homevalue/${id}`, {
        response: true,
      })
    ).data
    return responseData
  }

  export const createAnnualReports = async (
    homeValuationId: string,
  ): Promise<HomeValuationAnnualReport.Valuation> => {
    const responseData = (
      await API.post(propertyApiName, `/homevalue/${homeValuationId}/report`, {
        response: true,
      })
    ).data
    return responseData as HomeValuationAnnualReport.Valuation
  }

  // This is used by Agent. It will return all the annual reports of the current agent
  export const getAnnualReports = async (
    onlyPrintRequested: boolean,
  ): Promise<HomeValuationAnnualReport.Valuation[]> => {
    const responseData = (
      await API.get(
        propertyApiName,
        `/homevalue/agent/reports?onlyPrintRequested=${onlyPrintRequested}`,
        {
          response: true,
        },
      )
    ).data
    return responseData as HomeValuationAnnualReport.Valuation[]
  }

  export const getAnnualReportById = async (
    annualReportId: string,
  ): Promise<HomeValuationAnnualReport.Valuation> => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue/reports/${annualReportId}`, {
        response: true,
      })
    ).data
    return responseData as HomeValuationAnnualReport.Valuation
  }

  export const removeAnnualReport = async (
    annualReportId: string,
  ): Promise<HomeValuationAnnualReport.Valuation> => {
    const responseData = (
      await API.del(propertyApiName, `/homevalue/reports/${annualReportId}`, {
        response: true,
      })
    ).data
    return responseData as HomeValuationAnnualReport.Valuation
  }

  export const addSimilarSoldToAnnualReport = async (
    annualReportId: string,
    mls: string,
  ): Promise<HomeValuationAnnualReport.Valuation> => {
    const responseData = (
      await API.put(propertyApiName, `/homevalue/reports/${annualReportId}/similarSolds/${mls}`, {
        response: true,
      })
    ).data
    return responseData as HomeValuationAnnualReport.Valuation
  }

  export const removeSimilarSoldFromAnnualReport = async (
    annualReportId: string,
    mls: string,
  ): Promise<HomeValuationAnnualReport.Valuation> => {
    const responseData = (
      await API.del(propertyApiName, `/homevalue/reports/${annualReportId}/similarSolds/${mls}`, {
        response: true,
      })
    ).data
    return responseData as HomeValuationAnnualReport.Valuation
  }
  // Agent dashboard
  export const getContactsHomeValuations = async (userId?: string, confirmed?: boolean) => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue/agent/`, {
        response: true,
        queryStringParameters: { userId },
      })
    ).data
    return responseData
  }
  export const getContactsAnnualReports = async (
    userId?: string,
  ): Promise<HomeValuationAnnualReport.Valuation[]> => {
    const responseData = (
      await API.get(propertyApiName, `/homevalue/contact/reports`, {
        response: true,
        queryStringParameters: { userId },
      })
    ).data
    return responseData
  }
  export const assignHomeValuationToAContact = async (
    id: string,
    userId: string,
  ): Promise<HomeValueAssignRequest> => {
    const responseData = (
      await API.put(propertyApiName, `/homevalue/${id}/assign/${userId}`, {
        response: true,
      })
    ).data
    return responseData
  }
  export const confirmHomeValuation = async (
    id: string,
    form: ValuationConfirmationForm | null,
  ) => {
    const responseData = (
      await API.put(propertyApiName, `/homevalue/${id}/confirm`, {
        response: true,
        body: form,
      })
    ).data
    return responseData
  }
  export const printAnnualReport = async (annualReportId: string) => {
    const responseData = (
      await API.post(propertyApiName, `/homevalue/${annualReportId}/report/print`, {
        response: true,
      })
    ).data
    return responseData
  }
}
