import * as React from 'react'

function SvgRegistered(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.694 1.408c-.64.3-1.195.855-1.493 1.491-.2.43-.201.44-.201 9.125v8.694l.23.463c.295.59.928 1.216 1.493 1.474l.442.202h19.67l.539-.261c.591-.287 1.086-.796 1.414-1.452l.212-.426V3.33l-.203-.435c-.273-.582-.86-1.195-1.414-1.475l-.454-.228-9.835-.024-9.835-.024-.565.265zM22.071 13.05c0 7.76.02 7.507-.606 7.765-.495.204-18.435.204-18.93 0-.625-.258-.606-.005-.606-7.765V6.072H22.07v6.978zM11.169 8.202c-1.39.475-2.074 2.128-1.406 3.403.472.904 1.206 1.345 2.237 1.345 1.004 0 1.781-.464 2.233-1.333.685-1.317-.053-3.025-1.488-3.446-.636-.187-.956-.18-1.576.031zm-2.04 5.862c-.551.146-.804.284-1.23.673-.726.661-.83.95-.87 2.414-.022.814.003 1.338.07 1.46.188.35.647.386 4.901.386s4.713-.036 4.902-.385c.066-.123.091-.647.07-1.46-.041-1.463-.145-1.754-.864-2.409-.778-.708-.795-.711-3.92-.734-1.527-.01-2.903.014-3.059.055z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgRegistered = React.memo(SvgRegistered)
export default MemoSvgRegistered
