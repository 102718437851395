import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { PropertyServices } from 'services/property'

import { PropertyListing } from 'types/Property'
import { CurrencyHelper } from 'utilities/helpers/currencyHelper'
import { DateHelper } from 'utilities/helpers/dateHelper'
import Photo from 'views/shared/components/icons/Photo'
import { TextBody } from 'views/shared/components/ui-form/styled'
import { SoldReportServices } from 'services/soldReport'
import { PropertyType } from 'types'
import { Bed, Bath, Parking } from 'views/shared/components/icons'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'

export type ListingSummaryProps = {
  property: PropertyListing
  imageBaseUrl: string | null
  onClick?: (mls: string) => void
}

export const ListingSummary = ({ property, imageBaseUrl, onClick }: ListingSummaryProps) => {
  const [propertyImages, setPropertyImages] = useState<{ [mls: string]: string[] }>({})

  useEffect(() => {
    const properties = [property]
    if (properties) {
      setPropertyImages(
        properties.reduce(
          (obj, p) => ({
            ...obj,
            [property.mls]: PropertyServices.getPropertyImageURLs(p, imageBaseUrl),
          }),
          {},
        ),
      )
    } else {
      setPropertyImages({})
    }
  }, [property])

  const onListingClick = (p: string) => {
    if (onClick) {
      onClick(p)
    }
  }
  return (
    <>
      <div>
        <ul key={property.mls} className="p-1">
          {property && (
            <li
              onClick={() => onListingClick(property?.mls)}
              aria-hidden="true"
              key={property.mls}
              className="w-full lg:flex lg:items-center border border-primary-soft-color hover:border-primary-color rounded-lg shadow-xl"
            >
              <div className="relative">
                <div className=" h-28 lg:w-28 ">
                  <img
                    className="block h-full w-full object-cover rounded-l-lg"
                    alt=""
                    src={
                      propertyImages[property.mls] && propertyImages[property.mls][0]
                        ? propertyImages[property.mls][0]
                        : '../assets/house.svg'
                    }
                  />
                </div>
                <button
                  type="button"
                  className="flex items-center space-x-1 absolute bottom-0 right-0 cursor-pointer px-2 bg-default-color rounded-lg m-2"
                >
                  <span className="font-sm font-semibold text-white">
                    {propertyImages[property.mls] ? propertyImages[property.mls].length : 0}
                  </span>
                  <Photo color="white" style={{ fontSize: '16px' }} />
                </button>
              </div>
              <div className="p-2 appearance-none w-full text-left">
                {property.opens && (
                  <>
                    <div className="pb-2">
                      <TextBody color="var(--warning-color)" fontSize={12} fontWeight={600}>
                        Open House
                      </TextBody>
                      {property.opens.map((o) => (
                        <TextBody
                          key={o}
                          color="var(--warning-color)"
                          fontSize={12}
                          fontWeight={300}
                        >
                          {o}
                        </TextBody>
                      ))}
                    </div>
                  </>
                )}
                {property.soldDate ? (
                  <>
                    <p className="text-xs">
                      <span className="text-default-color">Listed for</span>{' '}
                      <span className="text-secondary-color font-semibold">
                        $
                        {property.listingPrice &&
                          CurrencyHelper.currencyFormat(property.listingPrice)}
                      </span>
                    </p>
                    <p className="text-warning-color">
                      <span className="font-semibold text-lg">
                        ${property.soldPrice && CurrencyHelper.currencyFormat(property.soldPrice)}
                      </span>
                      {property.saleType === 'Sale' ? (
                        <span className="text-sm"> Sold price</span>
                      ) : (
                        <span className="text-sm"> Lease price</span>
                      )}
                      <span className="text-default-color">
                        ({DateHelper.formatDateToDayMonthYear(property.soldDate) || null})
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <div className="pb-2">
                      <TextBody fontSize={14}>
                        {DateHelper.formatDateToDayMonthYear(property.listDate)}
                      </TextBody>
                    </div>
                    <p className="font-xs">
                      <span className="text-accent-color font-semibold">
                        <span>
                          $
                          {property.listingPrice &&
                            CurrencyHelper.currencyFormat(property.listingPrice)}
                        </span>
                      </span>
                    </p>
                  </>
                )}
                <div className="pb-2">
                  <p className="font-semibold text-sm text-secondary-color">
                    {property.type &&
                      SoldReportServices.getPropertyTypeLabel(property.type as PropertyType)}
                  </p>
                  <p className="text-sm text-secondary-color">
                    <span>
                      {`${property.unit && `#${property.unit} - `}${UnescapeHelper.unescape(
                        property.address,
                      )}`}
                    </span>
                    <span>, {property.municipality}</span>
                  </p>
                </div>
                <div className="flex items-center justify-between space-x-3">
                  <ul className="flex items-baseline space-x-3">
                    <li className="flex items-center space-x-2">
                      <span className="text-sm text-default-color">
                        {property.bedroom}
                        {property.brPlus ? `+${property.brPlus}` : ''}
                      </span>
                      <Bed color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="text-sm text-default-color">{property.bathroom}</span>
                      <Bath color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="text-sm text-default-color">{property.parking}</span>
                      <Parking color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                    </li>
                  </ul>
                  <p className="text-default-color text-sm">{property.mls}</p>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
