import styled from 'styled-components'

export const Ul = styled.ul`
  & li:nth-child(odd) {
    background-color: var(--alpha-color);
    border-radius: 8px;
  }
  & li:nth-child(even) {
    background-color: #fff;
  }
`
