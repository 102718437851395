import React, { useContext } from 'react'
import { StackPageType } from 'utilities/hooks/useStackRouter'
import { useUserStore } from 'store/dashboard/user'
import { Spinner } from 'views/shared/components/spinner'
import { ArrowRight } from 'views/shared/components/icons'
import { TextBody, Title } from 'views/shared/components/ui-form'
import { TabBar, TabBarItem } from 'views/shared/components/tab-bar/TabBar'
import { useHistory } from 'react-router-dom'
import { dashboardPaths } from 'views/dashboard'
import { AuthContext } from 'views/authentication'
import { UserRole } from 'constants/UserConstants'
import { ContainerDetails } from '../../styles'
import { UserInsights } from './insights'
import { UserActivity } from './activity'
import { Notes } from './notes'
import { AgentContactList } from './contactList'
import { UserDetailsPage } from './profileDetails'
import { UserFollowUp } from './followUp'

export type UseProfileStateType = {
  userId: string
}

export const ProfilePage = ({ id, page }: StackPageType) => {
  const { authUser } = useContext(AuthContext)
  const stateIndex = page === 'agent' || !window.location.pathname.includes('agent') ? 0 : 1
  const { userState } = useUserStore(stateIndex, id)
  const history = useHistory()
  const isAgent = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.AGENT) > -1
  const handleCollapse = () => {
    const path = window.location.pathname
    if (path.includes('profile')) {
      history.push(path.split('/profile')[0])
    } else if (path.includes('agent')) {
      history.push(path.split('/agent')[0])
    } else {
      history.push(dashboardPaths.users())
    }
  }
  const displayCollapse = () => {
    const path = window.location.pathname
    return (
      (path.includes('users') &&
        !(path.includes('profile') && path.includes('agent')) &&
        (path.includes('profile') || path.includes('agent'))) ||
      page === 'agent'
    )
  }

  return (
    <>
      <ContainerDetails>
        {displayCollapse() && (
          <button
            className="absolute lg:-left-5 right-3 top-1 bg-white h-10 w-10 rounded-full flex items-center justify-center shadow-lg cursor-pointer"
            type="button"
            onClick={handleCollapse}
          >
            <ArrowRight className="" color="var(--primary-color)" />
          </button>
        )}
        {userState.isLoading && (
          <div className="flex flex-col h-full items-center justify-center">
            <Spinner style={{ fontSize: '80px' }} color="var(--accent-color)" />
          </div>
        )}
        {!userState.isLoading && userState.details && (
          <>
            <div className="h-screen overflow-y-auto">
              <div>
                <Title className="hidden sm:block">
                  {userState.details.firstName} {userState.details.lastName}
                </Title>
                <Title fontSize={32} className="block sm:hidden">
                  {userState.details?.firstName} {userState.details?.lastName}
                </Title>
                <div className="xl:flex xl:space-x-5">
                  {userState.details.phone && <TextBody>{userState.details.phone}</TextBody>}
                  {userState.details.email && <TextBody>{userState.details.email}</TextBody>}
                </div>
              </div>
              {/* User Follow Up */}
              {isAgent && (id ? <UserFollowUp uid={id} /> : '')}
              <TabBar>
                {
                  [
                    userState.isCustomer
                      ? {
                          key: 'insights',
                          label: 'Insights',
                          content: <UserInsights />,
                        }
                      : undefined,
                    userState.isCustomer
                      ? {
                          key: 'activity',
                          label: 'Activity',
                          content: <UserActivity />,
                        }
                      : undefined,
                    isAgent && userState.isCustomer
                      ? ({
                          key: 'notes',
                          label: 'Notes',
                          content: <Notes />,
                        } as TabBarItem)
                      : undefined,
                    userState.isAgent
                      ? {
                          key: 'agentContactList',
                          label: 'Contact List',
                          content: <AgentContactList />,
                        }
                      : undefined,
                    {
                      key: 'profile',
                      label: 'Profile',
                      content: <UserDetailsPage userId={id || ''} stateIndex={stateIndex} />,
                    },
                  ].filter((item) => item !== undefined) as TabBarItem[]
                }
              </TabBar>
            </div>
          </>
        )}
      </ContainerDetails>
    </>
  )
}
