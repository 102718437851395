import { UserServices } from 'services/user'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'
import {
  maxLength,
  minLenth,
  required,
  stateValidationStore,
  useStateValidation,
} from 'utilities/hooks/validationState'
import UserTypes from 'services/user/UserTypes'
import { useUserStore } from './userProfileStore'

const notesInitial = {
  notes: [] as UserTypes.GetNotesResponse,
  isLoading: false,
} as {
  notes: UserTypes.GetNotesResponse
  isLoading: boolean
  error?: string
}
export const UPLOAD_FOLDER = 'uploads/'
export type NotesStateType = typeof notesInitial
const notesAtom = atom(notesInitial)

// form store
const initial = {
  main: {
    note: '',
    id: '',
  },
}
const store = {
  main: stateValidationStore(initial.main),
}
export const useNotesStore = () => {
  const { userState } = useUserStore()
  const { userId } = userState
  // state
  const [notesState, setNotesState] = useAtom(notesAtom)
  // form state
  const noteForm = useStateValidation({
    store: store.main,
    validation: {
      note: [
        required('Required'),
        minLenth(10, 'Note must have at least 10 characters'),
        maxLength(300, 'Name must have maximum 300 characters'),
      ],
    },
  })

  // actions
  const restore = async () => {
    noteForm.setFieldsData({
      note: '',
      id: '',
    })
  }
  const loadNotes = async () => {
    if (!userId) return
    setNotesState({ isLoading: true, notes: [] })
    UserServices.getUserNotes(userId).then((notes) => {
      setNotesState({ notes, isLoading: false })
    })
  }
  const saveNote = async () => {
    if (!noteForm.isValid() || !userState.userId) return
    const { note, id } = noteForm.state
    if (id !== '') {
      await UserServices.updateNote(userState.userId, id, note)
    } else {
      await UserServices.createNote(userState.userId, note)
    }
    restore()
    await loadNotes()
  }
  const newNote = () => {
    noteForm.reset()
    noteForm.setFieldsData(
      {
        note: '',
        id: '',
      },
      true,
    )
  }
  const editNote = (index: number) => {
    noteForm.reset()
    const note = notesState.notes[index]
    noteForm.setFieldsData(
      {
        note: note.note,
        id: note.id,
      },
      true,
    )
  }
  const deleteNote = async (index: number) => {
    if (!userState.userId) return
    const note = notesState.notes[index]
    await UserServices.deleteNote(userState.userId, note.id)
    await loadNotes()
  }

  // start
  useEffect(() => {
    if (userId) loadNotes()
  }, [userId])

  return {
    restore,
    editNote,
    saveNote,
    newNote,
    deleteNote,
    userState,
    notesState,
    noteForm,
  }
}
