import { paths } from 'paths'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AuthContext } from 'views/authentication'
import { SecondaryButton, TextBody, Title } from 'views/shared/components/ui-form'
import { Validate } from 'views/shared/components/icons'
import { PageFooter } from 'views/shared/components/page-footer'
import { Item, ItemLabel } from './about.styles'

export const About = () => {
  const { authUser } = useContext(AuthContext)
  return (
    <>
      <div className="max-w-screen-2xl mx-auto w-full px-5 lg:px-20  mt-10 mb-20">
        <Title className="mb-6">About Castl.</Title>
        <div className="flex flex-col justify-center md:items-start md:flex-row md:space-x-10 md:justify-between mb-12">
          <div className="space-y-2 mb-12 md:w-1/2">
            <p className="font-semibold text-xl text-accent-color">
              We believe that homes are sacred spaces.
            </p>
            <TextBody>
              They protect you from the outside world. Keep your loved ones safe. And strengthen
              your family&#39;s financial future.
            </TextBody>
            <TextBody>
              So you deserve to know everything about your home. And what&#39;s around it. From the
              equity you&#39;ve built to the very value of your neighbourhood — and all the
              properties that reside within.
            </TextBody>
            <TextBody>
              We built Castl. with all this in mind. So you can get the full lay of your land. And
              protect your biggest investment.
            </TextBody>
          </div>
          <div className="max-w-xl md:w-1/2">
            <img
              className="w-full"
              src="../assets/castl-devices-mockup.png"
              alt="Castl. app devices mockup"
            />
          </div>
        </div>
        <div className="mb-12">
          <ul className="grid grid-cols-1 md:grid-cols-3 gap-5 xl:gap-16">
            <Item className="space-y-2">
              <Validate style={{ fontSize: '24px' }} color="var(--accent-color)" />
              <ItemLabel>EASE</ItemLabel>
              <TextBody>
                We&#39;ve built a smooth, intuitive experience — delivering easy access to the
                crucial info that you deserve to know.
              </TextBody>
            </Item>
            <Item className="space-y-2">
              <Validate style={{ fontSize: '24px' }} color="var(--accent-color)" />
              <ItemLabel>KNOWLEDGE</ItemLabel>
              <TextBody>
                We share hard-to-find real estate information like sold-for prices, current property
                values, and overall neighbourhood values.
              </TextBody>
            </Item>
            <Item className="space-y-2">
              <Validate style={{ fontSize: '24px' }} color="var(--accent-color)" />
              <ItemLabel>VALIDATION</ItemLabel>
              <TextBody>
                We&#39;ll help you feel more confident than ever, knowing that you&#39;re making the
                best decisions with your biggest asset.
              </TextBody>
            </Item>
          </ul>
        </div>
        {!authUser && (
          <div className="w-60 mx-auto">
            <SecondaryButton>
              <NavLink to={paths.signIn()}>Sign In</NavLink>
            </SecondaryButton>
          </div>
        )}
      </div>
      <PageFooter />
    </>
  )
}
