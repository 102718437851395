import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { PropertyServices } from 'services/property/PropertyServices'
import { SoldReportServices } from 'services/soldReport'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValuationInfo, HomeValueEstimation, PropertyType } from 'types'
import { CurrencyHelper, DateHelper } from 'utilities'
import { dashboardPaths } from 'views/dashboard/paths'
import { Carousel } from 'views/shared/components/carousel/Carousel'
import { ArrowRight, Bath, Bed, House, Parking } from 'views/shared/components/icons'
import { SecondaryNegativeButton } from 'views/shared/components/ui-form'
import { Config } from 'config'

interface ValuationConfirmationPropertyDetailsProps {
  valuation: HomeValueEstimation
  setShowModal: (show: boolean) => void
}

export const ValuationConfirmationPropertyDetails = ({
  valuation,
  setShowModal,
}: ValuationConfirmationPropertyDetailsProps) => {
  const history = useHistory()
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)
  const [propertyImageState, setPropertyImageState] = useState<string[] | null>(null)
  const { setValuationInfoState } = useHomeValuationStore()
  const isModal = () => window.location.pathname.includes('/property-estimation')

  const handleEditPropertyDetails = () => {
    const propertyDetails: HomeValuationInfo = {
      locationId: valuation?.request?.locationId,
      unit: valuation?.request?.unit || valuation?.lastListing?.unit,
      type: valuation?.request?.type || valuation?.lastListing?.type,
      footage:
        valuation?.request?.footage || (valuation?.lastListing?.footage as number | undefined),
      bedroom: valuation?.request?.bedroom || valuation?.lastListing?.bedroom,
      bathroom: valuation?.request?.bathroom || valuation?.lastListing?.bathroom,
      lotFront:
        valuation?.request?.lotFront || (valuation?.lastListing?.lotFront as number | undefined),
      lotDepth:
        valuation?.request?.lotDepth || (valuation?.lastListing?.lotDepth as number | undefined),
      parking: valuation?.request?.parking || valuation?.lastListing?.parking,
      purchaseDate: valuation?.request?.purchaseDate || valuation?.lastPurchaseDate,
      purchasePrice: valuation?.request?.purchasePrice || valuation?.lastPurchasePrice,
      homeImprovements: valuation?.request?.homeImprovements,
      address: valuation?.displayAddress,
    }
    setValuationInfoState(propertyDetails)
    // setValuation(propertyDetails)
    setShowModal(true)
  }

  const handleCollapse = () => {
    history.push(dashboardPaths.valuations())
  }

  const getSoldPrice = () =>
    valuation?.lastListing?.soldPrice
      ? `$${CurrencyHelper.currencyFormat(
          valuation?.request?.purchasePrice || valuation?.lastListing?.soldPrice,
        )}`
      : 'N/A'

  const getImprovementCost = (cost: number | string) => {
    let result = cost
    if (typeof cost === 'string') {
      result = Number(cost.replace(/[^0-9.-]+/g, ''))
    }
    return `$${CurrencyHelper.currencyFormat(result)}`
  }

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public`)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (valuation.lastListing) {
      const newImages: string[] = PropertyServices.getPropertyImageURLs(
        valuation.lastListing,
        imageBaseUrl,
      )
      setPropertyImageState(newImages)
    } else {
      setPropertyImageState([])
    }
  }, [imageBaseUrl])

  return (
    <>
      {isModal() || (
        <button
          className="absolute -left-7 -top-9 bg-white h-10 w-10 rounded-full flex items-center justify-center shadow-lg cursor-pointer"
          type="button"
          onClick={handleCollapse}
        >
          <ArrowRight color="var(--primary-color)" />{' '}
        </button>
      )}
      <div className="sm:flex space-y-3 sm:space-x-5 my-2">
        <div>
          <div className="w-full rounded-lg">
            {propertyImageState && propertyImageState.length > 0 ? (
              <Carousel
                imageWidth="w-full sm:w-80"
                imageHeight="h-64"
                images={[propertyImageState[0] || '']}
              />
            ) : (
              <div className="flex w-full sm:w-80 h-64 justify-center items-center align-middle bg-alpha-color rounded-lg">
                <House style={{ fontSize: '56px' }} color="var(--default-color)" />
              </div>
            )}
          </div>
          {isModal() || (
            <div className="py-3 hidden sm:block">
              <div className="flex sm:w-80">
                <SecondaryNegativeButton onClick={() => handleEditPropertyDetails()}>
                  Edit property details
                </SecondaryNegativeButton>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full">
          <div className="text-default-color text-sm">
            Last sold price{' '}
            <span className="font-semibold whitespace-nowrap">
              {DateHelper.formatDateToDayMonthYear(valuation?.lastListing?.soldDate)}
            </span>
          </div>
          <div>
            <span className="font-bold text-4xl">{getSoldPrice()}</span>
          </div>
          <div className="font-semibold text-md mt-1">
            {SoldReportServices.getPropertyTypeLabel(valuation?.lastListing?.type as PropertyType)}
          </div>
          <div className="text-primary-color font-semibold">
            {valuation?.lastListing?.unit ? `#${valuation?.lastListing?.unit}` : ''}{' '}
            {valuation?.lastListing?.address}
          </div>
          <div>
            <span>{valuation?.lastListing?.community}</span>,{' '}
            <span>{valuation?.lastListing?.municipality}</span>
          </div>

          <div className="block space-y-2 my-2">
            <div className="flex items-baseline space-x-3">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-default-color">
                  {valuation?.request?.bedroom || valuation?.lastListing?.bedroom}
                  {valuation?.lastListing?.brPlus ? `+${valuation?.lastListing?.brPlus}` : ''}
                </span>
                <Bed color="var(--default-color)" style={{ fontSize: '30px' }} height={24} />{' '}
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-default-color">
                  {valuation?.request?.bathroom || valuation?.lastListing?.bathroom}
                </span>
                <Bath color="var(--default-color)" style={{ fontSize: '30px' }} height={24} />{' '}
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-default-color">
                  {valuation?.request?.parking || valuation?.lastListing?.parking}
                </span>
                <Parking color="var(--default-color)" style={{ fontSize: '30px' }} height={28} />{' '}
              </div>
            </div>
            <>
              {valuation?.lastListing?.type === 'condo' ||
              valuation?.lastListing?.type === 'condoTownhouse' ? (
                <p className="text-default-color text-sm whitespace-nowrap pl-2">
                  {valuation?.lastListing?.footage ? (
                    <span>{valuation?.lastListing?.footage} sqft</span>
                  ) : (
                    '0'
                  )}

                  {!valuation?.lastListing?.footage &&
                    !(valuation?.lastListing?.landFront && valuation?.lastListing?.landDepth) && (
                      <span>
                        {valuation?.lastListing?.lotFront} - {valuation?.lastListing?.lotDepth}
                      </span>
                    )}
                  {!valuation?.lastListing?.footage &&
                    !(valuation?.lastListing?.lotFront && valuation?.lastListing?.lotDepth) && (
                      <span>
                        {valuation?.lastListing?.landFront} - {valuation?.lastListing?.landDepth}
                      </span>
                    )}
                </p>
              ) : (
                <p className="text-default-color text-sm whitespace-nowrap">
                  {valuation?.lastListing?.footage ? (
                    <span>{valuation?.lastListing?.footage} sqft</span>
                  ) : (
                    <span>
                      {valuation?.lastListing?.landFront} x {valuation?.lastListing?.landDepth} feet
                    </span>
                  )}
                </p>
              )}
            </>
          </div>
          <div className="py-3 block sm:hidden">
            <div className="flex sm:w-80">
              <SecondaryNegativeButton onClick={() => handleEditPropertyDetails()}>
                Edit property details
              </SecondaryNegativeButton>
            </div>
          </div>
          <div className="space-y-4 pt-4">
            <div className="flex flex-col">
              {valuation.request?.homeImprovements && (
                <div className="text-md font-semibold">Improvement(s)</div>
              )}
            </div>
            <div className=" space-y-2">
              {valuation.request?.homeImprovements?.map((improvement) => (
                <div key={improvement.area} className=" space-x-4">
                  <div className="flex justify-start text-xl items-center rounded-md bg-gray-200 p-1 px-3 h-12 space-x-4">
                    <div className="font-semibold text-accent-color">
                      {improvement.cost ? getImprovementCost(improvement.cost) : 'N/A'}
                    </div>
                    <div className="">{improvement.completionDate}</div>
                    <div className="font-semibold">{improvement.area}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
