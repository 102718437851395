import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PropertyServices } from 'services/property'
import { SoldReportServices } from 'services/soldReport'
import { useHomeSearchStore } from 'store/homeSearch/homeSearchStore'
import { PropertyType } from 'types'
import { PropertyListing } from 'types/Property'
import { CurrencyHelper, DateHelper } from 'utilities'
import { useNeighbourhoods } from 'views/map/hooks/useNeighbourhoods'
import { Carousel } from 'views/shared/components/carousel/Carousel'
import { Bath, Bed, House, Parking, Photo } from 'views/shared/components/icons'
import { SecondaryNegativeButton, TextBody, Title } from 'views/shared/components/ui-form'
import './HomeSearchGallery.scss'

export type HomeSearchGalleryItemProps = {
  properties?: PropertyListing[] | null
  totalProperties?: number
  imageBaseUrl: string | null
  onClick?: () => void
  sort?: string
}

export const HomeSearchGalleryItems = ({
  properties,
  totalProperties,
  imageBaseUrl,
  onClick,
  sort,
}: HomeSearchGalleryItemProps) => {
  const [propertyImages, setPropertyImages] = useState<string[][]>([])
  const history = useHistory()
  const { neighbourhoods } = useNeighbourhoods()

  const propertiesNeighbourhood = (properties || []).map((p) => {
    const nId = p.neighbour
    const neighbourhood = neighbourhoods.find((item) => nId === item.id)
    const neighbourhoodName = neighbourhood ? neighbourhood.name : ''
    return neighbourhoodName
  })

  useEffect(() => {
    if (properties) {
      const newImages: string[][] = properties.map((p) =>
        PropertyServices.getPropertyImageURLs(p, imageBaseUrl),
      )
      setPropertyImages(newImages)
    } else {
      setPropertyImages([])
    }
  }, [properties, sort])

  const onListingClick = (property: PropertyListing) => {
    // const searchType = param.setLastSearchTypeState(searchType)
    window.open(`/property-details/?=${property?.mls}`, '_blank')
  }
  return (
    <>
      {properties?.length !== 0 && (
        <>
          {/* <div className="flex">
            <MultiCarousel properties={properties} images={propertyImages} />
          </div> */}
          <div className="py-10">
            <ul className="pb-8 flex flex-col items-center sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 w-full">
              {properties?.map((property, index) => (
                <li
                  onClick={() => onListingClick(property)}
                  aria-hidden="true"
                  key={property.mls}
                  className="inline-block bg-white border border-primary-soft-color  cursor-pointer hover:border-primary-color hover:-translate-y-1 rounded-lg text-left overflow-y-auto shadow-xl transform transition-all max-w-xs sm:w-full h-full  mb-0 sm:align-middle"
                >
                  {/* <GalleryItem property={property} images={propertyImages[index]} /> */}
                  {/* {property.mls} */}
                  <div className="w-full h-36 relative">
                    {propertyImages.length > 0 ? (
                      <Carousel
                        images={[propertyImages[index] ? propertyImages[index][0] : '']}
                        imageRounded="rounded-t-lg"
                      />
                    ) : (
                      <div className="w-full h-36 flex items-center justify-center">
                        <House style={{ fontSize: '56px' }} color="var(--alpha-dark-color)" />
                      </div>
                    )}
                    <button
                      type="button"
                      className="flex items-center space-x-1 absolute bottom-0 right-0 cursor-pointer px-2 bg-default-color rounded-lg m-2"
                      onClick={() => history.push(`/property/${property.mls}`)}
                    >
                      <span className="font-sm font-semibold text-white">
                        {propertyImages && propertyImages[index] ? propertyImages[index].length : 0}
                      </span>
                      <Photo color="white" style={{ fontSize: '16px' }} />
                    </button>
                  </div>
                  <div className="p-2 appearance-none w-full text-left">
                    {property.opens && (
                      <>
                        <div className="pb-1">
                          <TextBody color="var(--warning-color)" fontSize={14} fontWeight={600}>
                            Open House
                          </TextBody>
                          {property.opens.map((o) => (
                            <TextBody
                              key={o}
                              color="var(--warning-color)"
                              fontSize={12}
                              fontWeight={300}
                            >
                              {o}
                            </TextBody>
                          ))}
                        </div>
                      </>
                    )}{' '}
                    {property.soldDate ? (
                      <>
                        <div className="pb-1">
                          <TextBody fontSize={14}>
                            {DateHelper.formatDateToDayMonthYear(property.soldDate)}
                          </TextBody>
                        </div>
                        <p className="text-xs">
                          <span className="text-default-color">Listed for</span>{' '}
                          <span className="text-secondary-color font-semibold">
                            $
                            {property.listingPrice &&
                              CurrencyHelper.currencyFormat(property.listingPrice)}
                          </span>
                        </p>
                        <p className="text-warning-color">
                          <span className="font-semibold text-lg">
                            $
                            {property.soldPrice &&
                              CurrencyHelper.currencyFormat(property.soldPrice)}
                          </span>
                          {property.saleType === 'Sale' ? (
                            <span className="text-sm"> Sold price</span>
                          ) : (
                            <span className="text-sm"> Lease price</span>
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="pb-2">
                          <TextBody fontSize={14}>
                            {DateHelper.formatDateToDayMonthYear(property.listDate)}
                          </TextBody>
                        </div>
                        <p className="font-xs">
                          <span className="text-accent-color font-semibold">
                            <span>
                              $
                              {property.listingPrice &&
                                CurrencyHelper.currencyFormat(property.listingPrice)}
                            </span>
                          </span>
                        </p>
                      </>
                    )}
                    <div className="pb-2">
                      <p className="font-semibold text-sm text-secondary-color">
                        {property.type &&
                          SoldReportServices.getPropertyTypeLabel(property.type as PropertyType)}
                      </p>
                      <p className="text-sm text-secondary-color">
                        {property.unit && property.unit && <span>{property.unit} - </span>}
                        <span>{property.address}</span>
                        <br />
                        {propertiesNeighbourhood[index] && (
                          <span>{propertiesNeighbourhood[index]}, </span>
                        )}{' '}
                        <span>{property.municipality}</span>
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <ul className="flex items-baseline space-x-3">
                        <li className="flex items-center space-x-2">
                          <span className="text-sm text-default-color">
                            {property.bedroom}
                            {property.brPlus ? `+${property.brPlus}` : ''}
                          </span>
                          <Bed color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                        </li>
                        <li className="flex items-center space-x-2">
                          <span className="text-sm text-default-color">{property.bathroom}</span>
                          <Bath color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                        </li>
                        <li className="flex items-center space-x-2">
                          <span className="text-sm text-default-color">{property.parking}</span>
                          <Parking color="var(--default-color)" style={{ fontSize: '20px' }} />{' '}
                        </li>
                      </ul>
                      {!(
                        property.footage ||
                        property.landFront ||
                        property.landDepth ||
                        property.lotFront ||
                        property.lotDepth
                      ) ? (
                        <></>
                      ) : (
                        <>
                          {property.type === 'condo' || property.type === 'condoTownhouse' ? (
                            <p className="text-default-color text-sm">
                              {property.footage ? <span>{property.footage}sqft</span> : '0'}

                              {!property.footage && !(property.landFront && property.landDepth) && (
                                <span>
                                  {property.lotFront} - {property.lotDepth}
                                </span>
                              )}
                              {!property.footage && !(property.lotFront && property.lotDepth) && (
                                <span>
                                  {property.landFront} - {property.landDepth}
                                </span>
                              )}
                            </p>
                          ) : (
                            <p className="text-default-color text-sm">
                              {property.footage ? (
                                <span>{property.footage}sqft</span>
                              ) : (
                                <span>
                                  {property.landFront} x {property.landDepth}feet
                                </span>
                              )}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {properties && properties.length !== totalProperties ? (
              onClick && (
                <div className="max-w-xs mx-auto">
                  <SecondaryNegativeButton onClick={onClick}>Load More</SecondaryNegativeButton>
                </div>
              )
            ) : (
              <div className="flex justify-center text-default-color">End of Result</div>
            )}
          </div>
        </>
      )}
      {properties?.length === 0 && (
        <div className="flex justify-center items-center">
          <Title fontSize={32} className="my-20 md:my-40">
            No Result Found
          </Title>
        </div>
      )}
    </>
  )
}
