/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useMemo } from 'react'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { KeyLabel } from 'types'
import { DropdownMultiProps, DropdownSingleProps } from './types'
import { SelectButton, SelectButtonItem, SelectedButtonModal, SelectLabel } from './styles'
import { Check } from '../../icons'

const DropdownFieldErrorItem = ({ value }: { value: string }) => (
  <li className="mt-1 text-warning-color text-sm font-normal">{value}</li>
)

export const DropdownField = <TInput extends string | number | undefined>(
  props: DropdownMultiProps<TInput> | DropdownSingleProps<TInput>,
) => {
  const [show, setShow] = useState<boolean>(false)
  let errorsList: string[] = []
  if (
    props.formik &&
    props.formik.errors !== undefined &&
    props.formik.errors[props.name] !== undefined
  ) {
    const list = props.formik.errors[props.name]
    errorsList = errorsList.concat(list as any)
  }
  const isValid = errorsList.length === 0
  const isTouched = props.formik?.touched[props.name] !== undefined
  const toggleOptions = useCallback(() => {
    setShow(!show)
  }, [show])
  const close = useCallback(() => {
    setShow(false)
  }, [])
  const onValuePick = useCallback(
    (key: TInput) => () => {
      if (props.multi === true) {
        let newValues = props.values || []
        if (newValues.includes(key)) {
          newValues = newValues.filter((item) => item !== key)
        } else {
          newValues.push(key)
        }
        props.onChange && props.onChange(newValues)
      } else {
        props.onChange && props.onChange(key)
        setShow(false)
      }
    },
    [props],
  )

  const selectedItems = useMemo(():
    | { multi: true; items: KeyLabel<TInput>[] }
    | { multi: false; item: KeyLabel<TInput> | undefined } => {
    if (props.multi) {
      if (!props.values || !props.options)
        return {
          multi: true,
          items: [] as KeyLabel<TInput>[],
        }
      const lastIndex: number = props.values.length - 1
      const moreCount: number = props.values.length - 1
      if (props.values.length > 0) {
        return {
          multi: true,
          items: [
            (props.options || []).find((v) => v.key === props.values[lastIndex]),
            moreCount > 0
              ? ({
                  key: '',
                  label: `+ ${moreCount}`,
                } as KeyLabel<TInput>)
              : undefined,
          ].filter((item) => item !== undefined) as KeyLabel<TInput>[],
        }
      }
      return {
        multi: true,
        items:
          (props.options || []).filter(
            (item, index) => props.values.includes(item.key) && index === 0,
          ) || ([] as KeyLabel<TInput>[]),
      }
    }

    return {
      multi: false,
      item: (props.options || []).find((item) => props.value === item.key),
    }
  }, [props, props.multi && props.values])

  const isOptionSelected = (option: KeyLabel<TInput>) => {
    if (props.multi) {
      if (!props.values || props.values.length === 0) return false
      return props.values.includes(option.key)
    }
    return option.key === props.value
  }
  return (
    <label
      htmlFor={props.name}
      className={[
        'block text-sm font-semibold w-full z-50',
        props.formik?.errors[props.name] && props.formik?.touched[props.name]
          ? 'text-warning-color'
          : 'text-secondary-color',
      ].join(' ')}
    >
      <span>{props.label}</span>
      <div className="bg-white rounded-lg z-50">
        {props.CustomButton && !props.multi ? (
          <props.CustomButton
            name={props.name}
            onClick={toggleOptions}
            onBlur={() => props.formik?.setFieldTouched(props.name)}
            className={[
              ' focus:ring-0 z-50',
              !isValid && isTouched ? 'border-warning-color' : 'border-alpha-dark-color',
              props.bgColor,
            ].join(' ')}
            value={
              (selectedItems.multi
                ? selectedItems.items.map((item) => item.label).join(', ')
                : selectedItems.item?.label) ||
              props.placeholder ||
              props.value
            }
          />
        ) : (
          <SelectButton
            onClick={toggleOptions}
            onBlur={() => props.formik?.setFieldTouched(props.name)}
            className={[
              ' focus:ring-0 z-50',
              !isValid && isTouched ? 'border-warning-color' : 'border-alpha-dark-color',
              props.bgColor,
            ].join(' ')}
          >
            {!selectedItems.multi ? (
              <span>{selectedItems.item?.label || props.CustomValue || props.placeholder}</span>
            ) : (
              <>
                {selectedItems.items.length === 1 ? (
                  <span>{selectedItems.items[0]?.label}</span>
                ) : (
                  selectedItems.items.map((item) => (
                    <SelectLabel key={item.key}> {item.label} </SelectLabel>
                  ))
                )}
              </>
            )}
          </SelectButton>
        )}
        {show && (
          <ModalLayer
            onOutsideClick={close}
            show={show}
            responsive={{
              small: 'pinned',
            }}
          >
            <SelectedButtonModal className="max-h-80 scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-lg">
              {(props.options || []).map((item) => (
                <SelectButtonItem
                  className={`
                  ${item.disabled ? '--disabled' : '--enabled'}
                  ${isOptionSelected(item) ? '--selected' : ''}
                `}
                  onClick={onValuePick(item.key)}
                  type="button"
                  key={item.key}
                  value={item.label}
                >
                  <div className="row">
                    <span>{item.label}</span>
                    <Check />
                  </div>
                </SelectButtonItem>
              ))}
            </SelectedButtonModal>
          </ModalLayer>
        )}
      </div>
      {props.customErrorMessage ||
        (!isValid && isTouched && (
          <ul>
            {errorsList.map((errorItem) => (
              <DropdownFieldErrorItem key={errorItem} value={errorItem} />
            ))}
          </ul>
        ))}
    </label>
  )
}
