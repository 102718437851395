import React, { useState } from 'react'
import {
  InputField,
  PrimaryButton,
  RouteLink,
  TextBody,
  Title,
} from 'views/shared/components/ui-form'
import { AuthLayout } from 'views/authentication/components'
import { Validate } from 'views/shared/components/icons'
import { paths, SignUpWaitlistData } from 'views/authentication'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AuthException, AuthServices } from 'services/auth'

export const SignUpWaitlist = () => {
  // const { authUser, setAuthUserContext } = useContext(AuthContext)
  const history = useHistory()
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null)

  const onSubmit = async (
    { email }: SignUpWaitlistData,
    setSubmitting: (value: boolean) => void,
  ) => {
    try {
      setSubmitting(true)
      await AuthServices.signUpWaitlist(email)
      history.replace(paths.signUpWaitlistValidation())
    } catch (error) {
      setResponseErrors([(error as AuthException).message])
    } finally {
      setSubmitting(false)
    }
  }
  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      onSubmit({ email: values.email }, setSubmitting).then(() => {
        setSubmitting(false)
      })
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('Required'),
    }),
  })

  const Content = () => (
    <>
      <div className="flex items-start space-x-5">
        <Validate
          style={{ fontSize: '30px', height: '50px' }}
          color="var(--accent-color)"
          className="flex-shrink-0"
        />
        <div>
          <Title>Follow The Market</Title>
          <TextBody>
            Stay on top of the real estate market in and around your neighbourhood with our monthly
            Sold Reports
          </TextBody>
        </div>
      </div>
      <div className="flex items-start space-x-5 ml-12">
        <div>
          <Title>Instant Home Valuation</Title>
          <TextBody>Coming soon.</TextBody>
        </div>
      </div>
      <div className="flex items-start space-x-5 ml-12">
        <div>
          <Title>Find Your Perfect Home</Title>
          <TextBody>Coming soon.</TextBody>
        </div>
      </div>
    </>
  )
  return (
    <AuthLayout sideContent={<Content />}>
      <div className="space-y-5 mb-10">
        <Title className="relative">
          Join our waitlist
          <span className="font-semibold text-xs absolute bottom-1.5 text-accent-color pt-3 pl-2">
            BETA
          </span>
        </Title>
        <TextBody>
          Castl. is currently under development and is only available by invitation. Please register
          your interest below for a chance to try out our exclusive services early.
        </TextBody>
        <TextBody>
          Already registered?{' '}
          <RouteLink className="font-bold underline" to="/sign-in">
            Sign in
          </RouteLink>
        </TextBody>
      </div>
      <div className="space-y-6">
        <form className="space-y-10" method="POST" onSubmit={formik.handleSubmit}>
          <InputField name="email" label="Email address" type="text" formik={formik} />
          {responseErrors &&
            responseErrors.map((message) => (
              <div key={message} className="mt-1 text-warning-color text-sm">
                {message}
              </div>
            ))}
          <PrimaryButton type="submit" disabled={formik.isSubmitting}>
            Join our waitlist
          </PrimaryButton>
        </form>
      </div>
    </AuthLayout>
  )
}
