import * as React from 'react'

function SvgChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path d="M24.7383 0.132671C24.9031 0.256989 25 0.451459 25 0.6579V5.92106C25 6.14907 24.8819 6.36082 24.688 6.4807C24.494 6.60057 24.2518 6.61147 24.0479 6.5095L21.9128 5.44195L15.0042 14.8627C14.9003 15.0044 14.744 15.0987 14.5702 15.1245C14.3964 15.1502 14.2195 15.1054 14.0789 15L9.33721 11.4437L1.9892 21.0526H24.3421C24.7055 21.0526 25 21.3472 25 21.7105C25 22.0739 24.7055 22.3684 24.3421 22.3684H0.657899C0.407599 22.3684 0.178982 22.2264 0.0680918 22.002C-0.0427983 21.7776 -0.0167494 21.5097 0.135295 21.3109L8.68793 10.1267C8.90718 9.83996 9.31651 9.78344 9.60527 10L14.3364 13.5484L21.18 4.21621C21.3696 3.95766 21.718 3.87344 22.0047 4.01683L23.6842 4.85656V1.53009L19.9176 2.60627C19.5682 2.70609 19.2041 2.50379 19.1043 2.15443C19.0044 1.80506 19.2067 1.44093 19.5561 1.34111L24.1614 0.0253189C24.3599 -0.0313949 24.5735 0.00835304 24.7383 0.132671Z" />
    </svg>
  )
}

const MemoSvgChart = React.memo(SvgChart)
export default MemoSvgChart
