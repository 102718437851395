/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
import { PdfHelper } from 'utilities/helpers/PdfHelper'
import { Pluralize } from '../../helper'
import { propertyTypes } from '../config'
import { PropertyKeyLabel } from '../types/types'

export type PageHeaderProps = {
  neighbourhoodName: string
  monthLabel: string
  propertyKeyLabels: PropertyKeyLabel[]
}
export const addPageHeader = (
  doc: PDFKit.PDFDocument,
  { neighbourhoodName, monthLabel, propertyKeyLabels = [] }: PageHeaderProps,
) => {
  const sortedpropertyKeyLabels: PropertyKeyLabel[] = propertyTypes
    .map((key) => propertyKeyLabels.find((keyLabel) => keyLabel.key === key))
    .filter((v) => v !== undefined) as PropertyKeyLabel[]
  const template = /* svg */ `
  <svg width="1338" height="240" viewBox="0 0 1338 240" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="1338" height="240" fill="white"/>
  <path d="M237.564 161.314C237.991 161.873 238.204 162.571 238.204 163.405C238.204 164.55 237.761 165.437 236.886 166.06C236.004 166.684 234.725 166.998 233.04 166.998H226.486V153.751H232.665C234.223 153.751 235.422 154.057 236.263 154.669C237.105 155.281 237.525 156.122 237.525 157.195C237.525 157.85 237.374 158.433 237.072 158.937C236.773 159.443 236.357 159.833 235.83 160.112C236.556 160.351 237.136 160.751 237.564 161.314ZM228.932 155.683V159.333H232.4C233.255 159.333 233.911 159.18 234.37 158.87C234.826 158.564 235.059 158.111 235.059 157.519C235.059 156.913 234.828 156.456 234.37 156.148C233.913 155.838 233.255 155.685 232.4 155.685H228.932V155.683ZM235.734 163.16C235.734 161.885 234.787 161.245 232.89 161.245H228.934V165.068H232.89C234.785 165.068 235.734 164.433 235.734 163.16Z" fill="#388165"/>
  <path d="M250.211 164.939V167H240.322V153.753H249.951V155.814H242.773V159.262H249.139V161.285H242.773V164.939H250.211V164.939Z" fill="#388165"/>
  <path d="M256.873 155.836H252.499V153.751H263.69V155.836H259.321V166.998H256.873V155.836Z" fill="#388165"/>
  <path d="M272.675 163.934H266.084L264.782 167H262.259L268.191 153.753H270.602L276.557 167H273.993L272.675 163.934ZM271.865 162.004L269.382 156.212L266.912 162.004H271.865Z" fill="#388165"/>
  <path d="M93.4467 148.247C86.1584 148.247 79.7351 146.572 74.3543 143.271C68.9736 139.97 64.8052 135.485 61.9645 129.944C59.1511 124.446 57.722 118.313 57.722 111.713C57.722 104.731 59.2663 98.3799 62.314 92.8371C65.3753 87.2688 69.7409 82.8518 75.2837 79.7117C80.8011 76.5853 87.1268 74.9985 94.089 74.9985C97.2226 74.9985 100.2 75.3437 102.931 76.0224C105.643 76.6951 107.939 77.4836 109.763 78.3662C111.713 79.3116 112.961 80.0902 113.681 80.8159L114.669 81.8064L108.443 94.5709L106.524 92.8626C103.142 89.8539 98.804 88.3907 93.267 88.3907C89.4404 88.3907 85.932 89.3459 82.8394 91.2327C79.7527 93.1136 77.2868 95.8497 75.5121 99.3625C73.7081 102.928 72.7944 107.145 72.7944 111.898C72.7944 116.252 73.6124 120.245 75.2231 123.77C76.8026 127.212 79.1357 129.968 82.1639 131.956C85.1803 133.941 88.8547 134.947 93.0835 134.947C98.064 134.947 102.957 133.496 107.625 130.636L109.692 129.369L113.929 141.624L112.976 142.495C111.411 143.925 108.763 145.221 104.884 146.455C101.137 147.643 97.289 148.247 93.4467 148.247Z" fill="#231F20"/>
  <path d="M142.043 148.524C137.939 148.524 134.093 147.526 130.608 145.559C127.11 143.585 124.277 140.743 122.186 137.107C120.101 133.479 119.042 129.205 119.042 124.401C119.042 119.696 119.962 115.499 121.772 111.927C123.611 108.293 126.282 105.427 129.704 103.403C133.105 101.393 137.135 100.375 141.678 100.375C144.355 100.375 146.909 100.855 149.267 101.803C151.518 102.709 153.463 103.842 155.056 105.174L155.152 101.479H168.434V147.604H155.24L155.142 143.64C154.304 144.372 153.357 145.062 152.305 145.71C149.263 147.575 145.81 148.524 142.043 148.524ZM143.691 112.757C140.36 112.757 137.883 113.779 136.122 115.877C134.277 118.076 133.379 120.896 133.379 124.497C133.379 128.165 134.263 131.019 136.085 133.218C137.818 135.308 140.306 136.328 143.691 136.328C146.655 136.328 149.072 135.33 151.087 133.278C153.109 131.221 154.093 128.559 154.093 125.141V124.497C154.093 122.197 153.615 120.126 152.672 118.335C151.733 116.554 150.497 115.205 148.89 114.214C147.301 113.235 145.599 112.757 143.691 112.757Z" fill="#231F20"/>
  <path d="M196.042 148.524C192.971 148.524 189.752 148.118 186.474 147.318C183.028 146.478 180.587 145.476 179.016 144.252L177.825 143.327L182.649 131.886L184.625 133.277C187.981 135.636 191.716 136.785 196.046 136.785C201.14 136.785 201.14 135.146 201.14 134.608C201.14 134.028 200.966 133.683 200.521 133.373C199.945 132.978 198.647 132.276 195.742 131.207L192.259 129.921C188.328 128.342 185.195 126.427 182.889 124.209C180.365 121.783 179.084 118.568 179.084 114.655C179.084 110.146 180.882 106.563 184.428 104.007C187.774 101.597 192.237 100.375 197.694 100.375C200.328 100.375 202.952 100.669 205.494 101.251C208.163 101.863 210.185 102.646 211.681 103.646L213.061 104.57L207.643 116.574L205.65 114.571C205.258 114.175 204.408 113.577 202.614 112.976C201.081 112.463 199.332 112.202 197.421 112.202C192.874 112.202 192.874 113.649 192.874 114.194C192.874 115.012 193.124 115.511 193.733 115.915C194.75 116.589 196.515 117.366 198.979 118.229C199.035 118.237 199.475 118.37 201.851 119.251C206.093 120.918 209.162 122.783 211.269 124.966C213.516 127.302 214.656 130.297 214.656 133.871C214.656 138.709 212.782 142.442 209.088 144.962C205.625 147.326 201.238 148.524 196.042 148.524Z" fill="#231F20"/>
  <path d="M240.369 147.604H226.486V113.492H219.073V101.479H226.486V84.6701L240.369 81.6006V101.479H250.069V113.492H240.369V147.604Z" fill="#231F20"/>
  <path d="M270.569 76.1028H256.871V147.604H270.569V76.1028Z" fill="#231F20"/>
  <path d="M291.129 148.524C288.78 148.524 286.752 147.7 285.098 146.08C283.431 144.448 282.587 142.414 282.587 140.035C282.587 137.605 283.411 135.546 285.036 133.912C286.662 132.28 288.712 131.452 291.129 131.452C293.546 131.452 295.596 132.28 297.223 133.91C298.849 135.546 299.673 137.607 299.673 140.035C299.673 142.403 298.849 144.433 297.223 146.064C295.596 147.696 293.546 148.524 291.129 148.524Z" fill="#388165"/>



  <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em">
    <tspan x="470" y="136.557">${neighbourhoodName}</tspan>
    <tspan  fill="#018363" xml:space="preserve" font-weight="normal" style="white-space: pre" font-family="Montserrat" font-size="24" letter-spacing="0em">${monthLabel}</tspan>
  </text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="24" letter-spacing="0em"><tspan x="473" y="95.604">Sold Report</tspan></text>
  <rect x="422" y="79" width="4" height="60" fill="#018363"/>
  <text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" letter-spacing="0em" x="473" y="172.019">${sortedpropertyKeyLabels
    .map(
      (item) =>
        `<tspan><tspan font-weight="600"> ${item.count} </tspan>${Pluralize(
          item.label,
          item.count,
        )} </tspan>`,
    )
    .join('')}
  </text>
  </svg>
  `
  PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMin meet' })
  return doc
}
