import { SoldReportData, SoldReportServices } from 'services/soldReport'
import { atom, selector } from 'recoil'
import { MonthStr, PropertyType } from 'types/NeighbourhoodReport'
import { DateHelper } from 'utilities/helpers/dateHelper'
import { propertyTypeList } from './helper'

type ParamsType = {
  neighbourhoodId: string
  propertyType?: PropertyType
  monthStr?: MonthStr
}

export const soldReportDataState = atom({
  key: 'soldReport.soldReportData',
  default: undefined as SoldReportData | undefined,
})

export const paramsState = atom({
  key: 'soldReport.params',
  default: {} as ParamsType,
})

export const soldReportResponseSelector = selector({
  key: 'soldReport.soldReportResponse',
  get: async ({ get }) => {
    const params = get(paramsState)
    return params
      ? SoldReportServices.getSoldReport(
          params.neighbourhoodId,
          params.monthStr || DateHelper.getLastMonthStr(),
        )
      : undefined
  },
})

export const defaultPropertyTypeSelector = selector({
  key: 'soldReport.defaultPropertyType',
  get: ({ get }) => {
    const soldReportData = get(soldReportDataState)
    if (!soldReportData) return {} as PropertyType
    const defaultPropertyType = propertyTypeList.find(
      (type) => soldReportData.reports[type.key as PropertyType].soldNumber > 0,
    )?.key
    return defaultPropertyType as PropertyType
  },
})
