import * as React from 'react'

function SvgDocs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#docs_svg__clip0_312_29)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M6.387 8.637c-1.131 0-2.067.945-2.067 2.134 0 1.19.936 2.134 2.067 2.134 1.13 0 2.067-.945 2.067-2.134 0-1.19-.936-2.134-2.067-2.134zm1.76 4.518a3.01 3.01 0 001.16-2.384c0-1.64-1.297-2.987-2.92-2.987-1.624 0-2.92 1.348-2.92 2.987a3.01 3.01 0 001.19 2.407c-1.76.582-2.97 2.003-3.64 3.38a.427.427 0 10.766.374c.791-1.623 2.334-3.174 4.604-3.174 1.16 0 1.968.33 2.479.647.257.16.442.318.56.432a2.09 2.09 0 01.153.166l.004.005m-1.436-1.853c.471.143.86.333 1.17.525.214.133.39.251.53.371.243-.382.54-.784.95-1.134.32-.271.7-.496 1.166-.65a2.155 2.155 0 01-.6-1.496c0-1.168.924-2.134 2.089-2.134 1.164 0 2.089.966 2.089 2.134 0 .597-.242 1.142-.635 1.532.125.05.247.11.368.178.546.311 1.007.797 1.445 1.471.474.731.623 1.63.292 2.38-.345.777-1.149 1.267-2.313 1.267H11.79c-.02.3-.076.677-.234 1.028a1.81 1.81 0 01-.646.78c-.315.21-.713.325-1.198.325h-7.48a.427.427 0 110-.853h7.48c.347 0 .573-.08.725-.182a.958.958 0 00.34-.42c.166-.367.171-.812.171-1.105 0-.236.191-.427.427-.427h3.324c.915 0 1.357-.364 1.532-.76.189-.424.13-1.018-.227-1.568-.393-.605-.763-.973-1.152-1.195-.386-.22-2.202-1.546-5.004 2.021m-.264-.235zm3.869-5.517c-.672 0-1.236.562-1.236 1.28s.564 1.28 1.236 1.28 1.235-.562 1.235-1.28-.563-1.28-1.235-1.28z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
        <path
          d="M4.216 3.158C4.216 1.414 5.62 0 7.351 0h8.994c.831 0 1.629.333 2.217.925l3.547 3.573a3.17 3.17 0 01.918 2.233v14.111c0 1.744-1.404 3.158-3.135 3.158H7.352a.63.63 0 01-.628-.632.63.63 0 01.627-.631h12.54a1.888 1.888 0 001.882-1.895V6.731c0-.503-.198-.985-.551-1.34l-3.547-3.573a1.874 1.874 0 00-1.33-.555H7.35a1.888 1.888 0 00-1.88 1.895V4.66a.63.63 0 01-.628.632.63.63 0 01-.627-.632V3.158z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
        <path
          d="M3.716 3.158C3.716 1.14 5.34-.5 7.351-.5h8.994c.965 0 1.89.386 2.572 1.073l3.547 3.572a3.67 3.67 0 011.063 2.586v14.111c0 2.017-1.624 3.658-3.635 3.658H7.352a1.13 1.13 0 01-1.128-1.132 1.13 1.13 0 011.127-1.131h12.54c.76 0 1.382-.621 1.382-1.395V6.731c0-.371-.146-.727-.406-.988L17.32 2.17a1.374 1.374 0 00-.975-.407H7.35c-.759 0-1.38.621-1.38 1.395V4.66a1.13 1.13 0 01-1.128 1.132A1.13 1.13 0 013.716 4.66V3.158zM7.351.5a2.647 2.647 0 00-2.635 2.658V4.66c0 .076.06.132.127.132a.13.13 0 00.127-.132V3.158A2.388 2.388 0 017.351.763h8.994c.633 0 1.239.253 1.685.703l3.547 3.573c.446.449.696 1.058.696 1.692v14.111a2.388 2.388 0 01-2.381 2.395H7.352a.13.13 0 00-.128.131c0 .077.06.132.127.132h12.54a2.647 2.647 0 002.636-2.658V6.731a2.67 2.67 0 00-.773-1.881l-3.547-3.573A2.624 2.624 0 0016.345.5H7.35z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="docs_svg__clip0_312_29">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgDocs = React.memo(SvgDocs)
export default MemoSvgDocs
