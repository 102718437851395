/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrMsgColor, PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'
import { FormikErrors, FormikTouched } from 'formik'

export const minEightCharsMetTextColor = (
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined,
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,
): string => {
  if (!touched) {
    return ErrMsgColor.black
  }
  if (errors === PasswordErrorMsgs.minEightCharsMet || errors === PasswordErrorMsgs.required)
    return ErrMsgColor.red
  return ErrMsgColor.green
}

export const minThreeConditionsMetTextColor = (
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined,
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,
): string => {
  if (!touched) {
    return ErrMsgColor.black
  }
  if (
    errors === PasswordErrorMsgs.minEightCharsMet ||
    errors === PasswordErrorMsgs.minThreeConditionsMet ||
    errors === PasswordErrorMsgs.required
  )
    return ErrMsgColor.red
  return ErrMsgColor.green
}
