import { KeyLabel, PropertyType } from 'types'
import pluralize from 'pluralize'
import { SoldReportData } from 'services/soldReport'

export const propertyTypeList: KeyLabel[] = [
  {
    key: 'detatched',
    label: 'Detached',
  },
  {
    key: 'semiDetatched',
    label: 'Semi-Detached',
  },
  {
    key: 'freeholdTownhouse',
    label: 'Townhouse',
  },
  {
    key: 'condo',
    label: 'Condominium',
  },
  {
    key: 'condoTownhouse',
    label: 'Condo Townhouse',
  },
  {
    key: 'multi',
    label: 'Multi-Residential',
  },
]

export const OrderPropertyTypes = (list: KeyLabel[]) => {
  propertyTypeList.filter((type) => list.includes(type))
  return propertyTypeList
}

export const Pluralize = (
  item: KeyLabel | string,
  soldReportData: SoldReportData | number | undefined,
): string => {
  const label = typeof item === 'string' ? item : item.label
  let data
  if (typeof soldReportData === 'number') {
    data = soldReportData
  } else {
    data =
      soldReportData?.reports[label as PropertyType]?.soldNumber !== undefined
        ? soldReportData.reports[label as PropertyType].soldNumber
        : 0
  }
  let pluralized = pluralize(label, data)
  // capitalize every first letter of each words in the label
  const words = pluralized.split(' ')
  if (words.length > 1) {
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ')
  }
  const letterIndex = pluralized.indexOf('-')
  if (letterIndex >= 0) {
    pluralized =
      pluralized.substring(0, letterIndex + 1) +
      pluralized[letterIndex + 1].toUpperCase() +
      pluralized.substring(letterIndex + 2)
  }
  return pluralized
}
