export module CurrencyHelper {
  export const toCurrencyShort = (n: number): string => {
    if (n < 1e3) return n.toString()
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`
    if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`
    if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`
    return n.toString()
  }
  export const toRoundedShort = (price: number): string => {
    if (price >= 1000000) {
      return `$${CurrencyHelper.toCurrencyShort(price)}`
    }
    if (price >= 100000) {
      return `$${CurrencyHelper.toCurrencyShort(Math.round(price / 10000) * 10000)}`
    }
    return `$${CurrencyHelper.currencyFormat(price)}`
  }
  export const getSign = (number: number | undefined) => {
    if (number === undefined) return ``
    // const positive = number > 0
    // const priceDiffSign: -1 | 0 | 1 = (positive && 1) || (!positive && -1) || 0
    return (number < 0 && '-') || (number > 0 && '+') || ``
  }
  export const currencyFormat = (
    number: number | string | undefined,
    withSign = false,
    currencySign = ``,
  ) => {
    const n = Number(number || 0)
    let sign = ``
    if (n < 0 || withSign) {
      sign = getSign(n)
    }
    return `${sign}${currencySign}${Math.abs(n)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  }
  export const isWhole = (value: number): boolean => !(value % 1)
  export const percentageFormat = (value: number, withSign = false, maxFractionDigits = 0) => {
    const sign = value < 0 || withSign ? getSign(value) : ``
    return `${sign}${Math.abs(value).toFixed(maxFractionDigits)}%`
  }
}
