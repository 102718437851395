const iconSizes: number[] = [32, 42, 56, 72, 96, 112, 112, 112, 112]
export const textSizes: number[] = [13, 15, 17, 19, 22, 25, 25, 25, 25]
export const clusterIcons = (color?: string) =>
  iconSizes.map((size) =>
    window.btoa(`
<svg
  width="${size}"
  height="${size}"
  viewBox="0 0 ${size} ${size}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${color || '#417A9F'}" />
</svg>
`),
  )

export const mixedClusterIcons = (color?: string) =>
  iconSizes.map((size) =>
    window.btoa(`
    <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="black"/>
    <circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 5.5}" fill="${
      color || '#417A9F'
    }" stroke="white"/>
    </svg>
  `),
  )
export const soldClusterIcons = (color?: string) =>
  iconSizes.map((size) =>
    window.btoa(`
  <svg
    width="${size}"
    height="${size}"
    viewBox="0 0 ${size} ${size}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${color || 'black'}" />
  </svg>
`),
  )

export const markerIcon = (type?: string, color?: string) => {
  let colorCode
  if (color) {
    colorCode = color
  } else if (type === 'active') {
    colorCode = '#417A9F'
  } else if (type === 'normal') {
    colorCode = '#018363'
  } else {
    colorCode = '#000000'
  }

  return window.btoa(`
    <svg width="61" height="30" viewBox="0 0 61 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="61" height="30" rx="20" fill="${colorCode}"/>
    </svg>
  `)
}

export const miniMapMarkerIcon = () =>
  window.btoa(`
    <svg width="47" height="60" viewBox="0 0 47 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2005_2094)">
    <path d="M19.392 8.05058C20.7745 7.53417 22.0057 7.26837 24.1545 7.02265C24.7902 6.94993 27.214 7.06327 28.0951 7.20692C31.8159 7.81339 34.9823 9.44001 37.6692 12.1254C40.9196 15.374 42.7262 19.6797 42.7303 24.1878C42.7318 25.72 42.627 26.6826 42.2984 28.1587C41.0211 33.8941 36.9845 41.2867 30.6869 49.4239C29.032 51.5622 26.9046 54.1227 26.417 54.5632C25.7724 55.1456 24.976 55.1456 24.3313 54.5632C23.8437 54.1227 21.7164 51.5622 20.0615 49.4239C13.0644 40.3828 8.84445 32.2144 8.11487 26.2988C7.66049 22.6144 8.57123 18.4974 10.5432 15.3219C12.636 11.9518 15.7095 9.42613 19.392 8.05058Z" fill="#417A9F"/>
    </g>
    <circle cx="25.5" cy="24.5" r="7.5" fill="white"/>
    <defs>
    <filter id="filter0_d_2005_2094" x="0" y="0" width="46.7305" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-1"/>
    <feGaussianBlur stdDeviation="3"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2005_2094"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2005_2094" result="shape"/>
    </filter>
    </defs>
    </svg>
`)
