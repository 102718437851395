import { API } from 'aws-amplify'
import moment from 'moment'

import { KeyLabel, MonthStr, NeighbourhoodReport, PropertyType } from 'types'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export type SoldReportNeighbourhood = {
  [propertyType in PropertyType]: NeighbourhoodReport
}

const emptySoldReportNeighbourhood = {
  detatched: {
    month: '',
    neighbourhoodId: '',
    type: 'detatched',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
  semiDetatched: {
    month: '',
    neighbourhoodId: '',
    type: 'semiDetatched',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
  freeholdTownhouse: {
    month: '',
    neighbourhoodId: '',
    type: 'freeholdTownhouse',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
  condoTownhouse: {
    month: '',
    neighbourhoodId: '',
    type: 'condoTownhouse',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
  condo: {
    month: '',
    neighbourhoodId: '',
    type: 'condo',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
  multi: {
    month: '',
    neighbourhoodId: '',
    type: 'multi',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
  others: {
    month: '',
    neighbourhoodId: '',
    type: 'others',
    dom: 0,
    domLastYear: 0,
    soldNumber: 0,
    soldNumberLastYear: 0,
    averagePrice: 0,
    averagePriceLastYear: 0,
    averageListPrice: 0,
    averageListPriceLastYear: 0,
    newListing: 0,
    newListingLastYear: 0,
    soldProperties: [],
    averageSellingToListPrice: 1,
  },
}

export type PropertyDataType =
  | 'averageListPrice'
  | 'soldPrice'
  | 'dom'
  | 'newListing'
  | 'soldNumber'
export type SoldReportDataStatistics = {
  [month in MonthStr]: {
    [propertyType in PropertyType]: {
      [propertyDataType in PropertyDataType]: number
    }
  }
}
export type SoldReportData = {
  time: MonthStr
  reports: SoldReportNeighbourhood
  neighbourhoodStatistics: SoldReportDataStatistics
  communityStatistics: SoldReportDataStatistics
  statistics: SoldReportDataStatistics
}

export module SoldReportServices {
  const propertyTypeMap: KeyLabel[] = [
    {
      key: 'detatched',
      label: 'Detached',
    },
    {
      key: 'semiDetatched',
      label: 'Semi-Detached',
    },
    {
      key: 'condoTownhouse',
      label: 'Condo Townhouse',
    },
    {
      key: 'freeholdTownhouse',
      label: 'Townhouse',
    },
    {
      key: 'condo',
      label: 'Condominium',
    },
    {
      key: 'multi',
      label: 'Multi-Residential',
    },
  ]
  const apiName = 'property'
  export const getSoldReport = async (neighbourhoodId: string, time?: MonthStr) => {
    const responseData = (
      await API.get(apiName, `/soldreports/${neighbourhoodId}`, {
        response: true,
        queryStringParameters: { time: time !== undefined ? time.replace('-', '') : undefined },
      })
    ).data
    const reports = {
      ...emptySoldReportNeighbourhood,
      ...responseData.reports,
    }
    return {
      reports,
      ...responseData,
    } as SoldReportData
  }
  export const getMonthLabel = (monthStr: MonthStr) => {
    const monthIndex = Number(monthStr.substring(5, 7)) - 1
    const year = Number(monthStr.substring(0, 4))
    return `${months[monthIndex]} ${year}`
  }

  // TODO: do some refactor here, we may not need this in the future
  // export const getAvailableMonths = (months?: any): KeyLabel[] => {
  //   if (!months) return []
  //   return Object.keys(months).map((item) => ({
  //     key: item,
  //     label: getMonthLabel(item),
  //   }))
  // }

  export const getAvailableMonths = (): KeyLabel[] => {
    // const monthList: KeyLabel[] = []
    const currDate = new Date()
    return months.map((month, i) => {
      const thisDate = new Date(currDate.getFullYear(), currDate.getMonth() - i)
      const key = moment(thisDate).format('YYYY-MM')
      const label = getMonthLabel(key)
      return {
        key,
        label,
      }
    })
  }

  export const getPropertyTypes = () => propertyTypeMap
  export const getPropertyTypeLabel = (key: PropertyType) => {
    const propertyType = propertyTypeMap.find((item) => item.key === key)
    if (propertyType) {
      return propertyType.label
    }
    return key
  }
}
