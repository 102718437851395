import React from 'react'
import ReactDOM from 'react-dom'
import { Storage, Analytics, Amplify } from 'aws-amplify'
import { RecoilRoot } from 'recoil'

import { AuthContextProvider } from 'views/authentication'
import { AnalyticsContextProvider } from 'views/analytics'

import { App } from 'views/shared/components/ui-layout/app/App'
import { Spinner } from 'views/shared/components/icons'
import { Pluralize } from 'constants/pluralizeConstants'

import CookieConsent from 'react-cookie-consent'
import { reportWebVitals } from './reportWebVitals'
import config from './aws-exports'

import '@aws-amplify/ui/dist/style.css'
import './index.css'

Amplify.configure(config)
Storage.configure({ track: false, level: 'public' })
Pluralize.configure()
Analytics.autoTrack('session', {
  enable: true,
})
Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA',
})
Analytics.autoTrack('event', {
  enable: true,
})

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <AuthContextProvider>
        <AnalyticsContextProvider>
          <React.Suspense
            fallback={
              <div className="w-full h-screen flex items-center justify-center">
                <Spinner style={{ fontSize: '128px' }} color="#018363" />
              </div>
            }
          >
            <App />
            <CookieConsent
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'var(--accent-color)',
                padding: '0 20px',
              }}
              contentStyle={{
                color: 'white',
                fontSize: '16px',
              }}
              buttonStyle={{
                backgroundColor: 'white',
                color: 'var(--primary-color)',
                fontWeight: '600',
                borderRadius: '8px',
                fontSize: '16px',
                height: '48px',
                width: '144px',
              }}
              buttonText="Accept all"
            >
              This website stores data such as cookies to enable essential site functionality, as
              well as marketing, personalization, and analytics. You can consent to the use of such
              technologies by interacting with any link or button outside of this notice or by
              continuing to browse otherwise.
            </CookieConsent>
          </React.Suspense>
        </AnalyticsContextProvider>
      </AuthContextProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
