import * as React from 'react'

function SvgHardarrowdown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.538 12.809A.5.5 0 011 12.5h3.5V1A.5.5 0 015 .5h4a.5.5 0 01.5.5v12a.5.5 0 01-1 0V1.5h-3V13a.5.5 0 01-.5.5H2.207L7 18.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 01-.108-.545z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgHardarrowdown = React.memo(SvgHardarrowdown)
export default MemoSvgHardarrowdown
