import * as React from 'react'
import { SVGProps, memo } from 'react'

const SvgVirtualTour = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`--icon ${props.className || ''}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.842 14.08c1.301.975 3.158.047 3.158-1.58V4.605c0-1.626-1.857-2.554-3.158-1.578l-2.105 1.578V3.29A3.29 3.29 0 0 0 16.447 0H3.29A3.29 3.29 0 0 0 0 3.29v10.526a.658.658 0 0 0 1.316 0V3.29c0-1.09.883-1.974 1.973-1.974h13.158c1.09 0 1.974.884 1.974 1.974V5.92a.658.658 0 0 0 1.053.527l3.158-2.369a.658.658 0 0 1 1.052.526V12.5a.658.658 0 0 1-1.052.527l-3.158-2.369a.658.658 0 0 0-1.053.526v2.632c0 1.09-.884 1.974-1.974 1.974H3.29a.658.658 0 1 0 0 1.316h13.158a3.29 3.29 0 0 0 3.29-3.29V12.5l2.105 1.58Z"
      fill="#417A9F"
    />
  </svg>
)

const Memo = memo(SvgVirtualTour)
export default Memo
