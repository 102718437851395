import styled from 'styled-components'

// Container
export const ContainerRoot = styled.div`
  height: calc(100vh - 40px);
  overflow: auto;
  position: relative;
`
export const PageStack = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 48px;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

// Side Nav
export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 10px 0px 10px;
  border-bottom: 1px solid var(--alpha-dark-color);
  & > .navlink {
    /* width: 140px; */
    padding: 0 20px;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-bottom: 4px solid transparent;
    color: var(--primary-color);
    &:hover {
      border-bottom: 4px solid var(--accent-color);
      outline: none;
    }
    &.--active {
      border-bottom: 4px solid var(--accent-color);
    }
  }
  @media only screen and (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0;
    margin-top: 112px;
    width: 140px;
    border-right: 1px solid var(--alpha-dark-color);
    border-bottom: none;
    & > .navlink {
      border-right: 4px solid transparent;
      border-bottom: none;
      &:hover {
        border-right: 4px solid var(--accent-color);
        border-bottom: none;
      }
      &.--active {
        border-right: 4px solid var(--accent-color);
        border-bottom: none;
      }
    }
  }
`
