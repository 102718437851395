import * as React from 'react'

function SvgShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.111 1.6a2.5 2.5 0 00-2.424 3.114.5.5 0 01-.27.573L6.562 8.552a.5.5 0 01-.587-.118 2.5 2.5 0 100 3.333.5.5 0 01.587-.118l6.855 3.265a.5.5 0 01.27.574A2.504 2.504 0 0016.11 18.6a2.5 2.5 0 10-1.666-4.364.5.5 0 01-.667-.745 3.5 3.5 0 11-1.138 2.16l-6.19-2.948a3.5 3.5 0 110-5.206l6.19-2.948a3.5 3.5 0 111.138 2.16.5.5 0 01.667-.746A2.5 2.5 0 1016.112 1.6z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const ShareIcon = React.memo(SvgShare)
export default ShareIcon
