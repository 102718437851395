import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { paths } from 'paths'

import { PrimaryButton, Title, InputField } from 'views/shared/components/ui-form'

import { passwordErrorMessageBlock } from 'views/shared/components/validations/FormValidationHelper'

import { AuthException, AuthServices } from 'services/auth/AuthServices'
import { PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'
import { AuthContext } from '../../contexts'
import { AuthLayout } from '../../components'

export const SignInNewPassword = () => {
  const { authUser, setAuthUserContext } = useContext(AuthContext)
  const history = useHistory()
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null)

  const authException = async (error: AuthException, username: string) => {
    switch (error.code) {
      case 'UserNotConfirmedException':
        ;(async () => {
          await AuthServices.signUpResendCode(username)
        })()
        break
      default:
        setResponseErrors([(error as AuthException).message])
        break
    }
  }
  const onSubmit = async (
    { password }: { password: string },
    setSubmitting: (state: boolean) => void,
  ) => {
    try {
      setSubmitting(true)
      const response = await AuthServices.SignInNewPassword(authUser?.email || '', password)
      if (response) {
        setAuthUserContext(response)
        history.replace(paths.homepage())
      }
    } catch (error) {
      if (error instanceof AuthException) authException(error, password)
      console.warn(error)
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: { password: '', passwordConfirmation: '' },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(
        {
          password: values.password,
        },
        setSubmitting,
      )
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(PasswordErrorMsgs.required)
        .min(8, PasswordErrorMsgs.minEightCharsMet)
        .matches(
          // /^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[@$!%*?&]\S*)[A-Za-z\d@$!%*?&]{8,}$/,
          /((?=.*[^a-zA-Z\s])(?=.*[a-z])(?=.*[A-Z])|(?=.*[^a-zA-Z0-9\s])(?=.*\d)(?=.*[a-zA-Z])).*$/,
          PasswordErrorMsgs.minThreeConditionsMet,
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(PasswordErrorMsgs.required),
    }),
  })

  useEffect(() => {
    if (authUser && authUser.user && !authUser.user.challengeName) {
      history.replace(paths.homepage())
    }
    return () => {}
  }, [authUser, history])

  return (
    <>
      <AuthLayout>
        <div className="space-y-5 mb-10">
          <Title className="relative">New password</Title>
        </div>
        <div>
          <form className="space-y-10" method="POST" onSubmit={formik.handleSubmit}>
            <div className="space-y-5">
              <InputField
                name="password"
                label="Password"
                type="password"
                formik={formik}
                customErrorMessage={passwordErrorMessageBlock(
                  formik.touched.password,
                  formik.errors.password,
                )}
              />
              <InputField
                name="passwordConfirmation"
                label="Confirm Password"
                type="password"
                formik={formik}
              />
              {responseErrors &&
                responseErrors.map((message) => (
                  <div key={message} className="mt-1 text-warning-color font-semibold text-sm">
                    {message}
                  </div>
                ))}
            </div>
            <PrimaryButton type="submit" disabled={formik.isSubmitting}>
              Update password
            </PrimaryButton>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}
