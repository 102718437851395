import * as React from 'react'

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M6.25 13.125V20a1 1 0 001 1h9.25a1 1 0 001-1v-7a1 1 0 011-1h.87c.92 0 1.351-1.136.664-1.747L12.527 3.58a1 1 0 00-1.315-.012L4 9.75"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgHome = React.memo(SvgHome)
export default MemoSvgHome
