import React, { KeyboardEventHandler, useCallback, useRef, useState, useEffect } from 'react'
import debounce from 'debounce'
import type { KeyLabel } from 'types'
import { useHistory } from 'react-router-dom'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import { AddressSuggestionQueryType } from 'types'
import { HomeValuationServices } from 'services/homeValuation'
import { PropertyServices } from 'services/property'

type AddressFieldProps = {
  label?: string
  displayAddress?: string
  edit?: boolean
  searchBy?: string
  placeholder?: string
  onSelectChanged?: (selection: KeyLabel | null) => void
}
export const AddressField = ({
  label,
  displayAddress,
  edit,
  searchBy,
  placeholder,
  onSelectChanged,
}: AddressFieldProps) => {
  const history = useHistory()
  const [selection, setSelection] = useState<KeyLabel | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<KeyLabel[]>([])
  const [notFound, setNotFound] = useState<boolean>(false)

  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [query, setQuery] = useState<string>('')

  const onFakeInputClick = () => {
    setIsOpen(true)
  }
  const onOutsideClick = () => {
    setIsOpen(false)
  }
  const onEscape = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (history.location.pathname === '/home-valuation/property-address') {
      setQuery('')
    }
  }, [history])

  useEffect(() => {
    if (onSelectChanged) {
      onSelectChanged(selection)
    }
  }, [selection])

  const findAddress = async (e: AddressSuggestionQueryType) =>
    HomeValuationServices.findAddress(e)
      ?.then((results) => ({
        ...results,
        suggestions: (results.suggestions || []).filter((s) => s.matchLevel === 'houseNumber'),
      }))
      .then((results) => {
        setNotFound(results && results.suggestions && results.suggestions.length === 0)
        return results.suggestions || []
      })
      .then((suggestions) =>
        suggestions.map((item) => ({
          key: item.locationId,
          label: `${item.address.houseNumber || ''} ${item.address.street || ''} - ${
            item.address.city || ''
          } - ${item.address.state}`,
        })),
      )
      .then(setOptions)

  const findAddressByMls = async (mls: string) =>
    PropertyServices.addressTextSearch(mls)
      .then((results) => results?.listings || [])
      .then((listings) =>
        listings.map((listing) => ({
          key: listing.mls,
          label: listing.address,
        })),
      )
      .then(setOptions)

  const onSearchInputChange: KeyboardEventHandler<HTMLInputElement> = (e: any) => {
    if (!e) return
    if (e.target.value === '') {
      setQuery('')
    }
    searchBy && searchBy === 'mls'
      ? findAddressByMls(e.target.value)
      : findAddress({ street: e.target.value })
  }

  const onSearchResultClick = (address: KeyLabel<string>) => {
    if (searchBy === 'mls') {
      setQuery(address.key)
    } else {
      setQuery(address.label)
      // setValuationInfoState((prev: any) => ({ ...prev, locationId: address.key }))
    }
    setSelection(address)
    setIsOpen(false)
  }

  const debounceSearchInputChangeHandler = useCallback(debounce(onSearchInputChange, 300), [])

  useEffect(() => {
    if (displayAddress || displayAddress === '') {
      setQuery(displayAddress)
    }
  }, [displayAddress])

  return (
    <>
      {isOpen && (
        <div
          tabIndex={0}
          role="link"
          onKeyUp={(e) => {
            if (e.key === 'Escape') onEscape()
          }}
          onClick={onOutsideClick}
          className="fixed top-0 right-0 bottom-0 left-0 opacity-0"
        >
          &nbsp;
        </div>
      )}
      <p className="font-semibold px-2">{label}</p>
      <div className="relative p-2">
        <div className="">
          <button
            type="button"
            className={[
              'whitespace-nowrap overflow-ellipsis overflow-x-hidden  cursor-text italic block box-border bg-white w-full h-12 text-base text-left text-default-color px-3 focus:outline-none focus:border-primary-soft-color focus:ring-offset-0 focus:drop-shadow-none  focus:shadow-none',
              edit ? 'border-none' : 'border border-primary-soft-color rounded-md ',
            ].join(' ')}
            onClick={onFakeInputClick}
            disabled={edit}
          >
            {query.length > 0 ? query : placeholder || ''}
          </button>
        </div>
        {isOpen && (
          <div className="search absolute flex flex-col text-default-color top-2 left-2 right-2 rounded-md overflow-hidden shadow-sm">
            <div className="relative">
              <input
                defaultValue={query}
                ref={searchInputRef}
                onKeyUp={debounceSearchInputChangeHandler}
                type="text"
                className={[
                  edit ? 'border-none' : 'border border-primary-soft-color rounded-md ',
                  'whitespace-nowrap overflow-ellipsis w-full cursor-text block box-border bg-white  h-12 text-left text-default-color italic text-base focus:border-primary-soft-color focus:outline-none focus:ring-offset-0 focus:drop-shadow-none focus:shadow-none',
                ].join(' ')}
                disabled={edit}
                placeholder={placeholder}
              />
              <div className="bg-white z-20 px-6 max-h-96 mt-0 max-h-auto scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
                {options.length > 0 && (
                  <div className="py-5">
                    <ul className="pb-0">
                      {options.map((item) => (
                        <li key={item.key} className="pb-5">
                          <div className="flex justify-between">
                            <button
                              type="button"
                              className="cursor-pointer text-left font-semibold text-md"
                              onClick={() => onSearchResultClick(item)}
                            >
                              {UnescapeHelper.unescape(item.label)}
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {notFound && (
                  <div className="py-2">
                    <span className="text-default-color font-semibold">Address not found</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
