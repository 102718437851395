import styled from 'styled-components'

interface ButtonItemProps {
  selected?: boolean
}

export const ButtonItem = styled.button<ButtonItemProps>`
  box-sizing: border-box;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 12px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--primary-soft-color);
  cursor: pointer;
  margin-right: 18px;
  margin-bottom: 12px;
  &:hover {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
  }
  &.--selected {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
  }
`
