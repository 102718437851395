import React, { useState, useEffect } from 'react'

import { DateHelper } from 'utilities'
import { ArrowDownSelect, ArrowLeft, ArrowRight } from 'views/shared/components/icons'
import { ModalLayer } from 'views/shared/components/modal-layer'
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from './MonthPickerStyles'
import { MonthPickerProps } from './types'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()
const years = Array.from(new Array(12), (val, index) => currentYear - index)

const isFuture = (year: number, month: number) => year >= currentYear && month > currentMonth

export const MonthPicker = ({ onChange, monthStr, styleType }: MonthPickerProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isYearOpen, setIsYearOpen] = useState(false)
  const toggling = () => setIsOpen(!isOpen)

  let selectedDate = DateHelper.monthStrToYearAndMonth(monthStr)
  if (!selectedDate || Number.isNaN(selectedDate.month) || Number.isNaN(selectedDate.year)) {
    selectedDate = DateHelper.monthStrToYearAndMonth(DateHelper.currentMonthStr())
  }
  const { month, year } = selectedDate
  const monthLabel = months[month - 1]

  const tryChangeTo = (newYear: number, newMonth: number) => {
    if (newYear <= currentYear) {
      onChange &&
        onChange(
          `${newYear}-${
            newYear === currentYear && newMonth > currentMonth + 1 ? currentMonth + 1 : newMonth
          }`,
        )
    }
  }
  const onYearPick = (y: number) => {
    tryChangeTo(y, selectedDate.month)
    setIsYearOpen(false)
  }
  const onMonthPick = (m: number) => {
    tryChangeTo(selectedDate.year, m)
    setIsOpen(false)
  }
  const onOutsideClick = () => {
    setIsOpen(false)
  }
  const onEscape = () => {
    setIsOpen(false)
  }
  const nextYear = () => {
    tryChangeTo(year + 1, month)
  }
  const prevYear = () => {
    tryChangeTo(year - 1, month)
  }

  useEffect(() => {
    const escapeCallback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
      }
    }
    // add escape event
    window.addEventListener('keyup', escapeCallback)
    return () => {
      // remove escape event
      window.removeEventListener('keyup', escapeCallback)
    }
  }, [])

  return (
    <div className="relative">
      {isOpen && (
        <div
          tabIndex={0}
          role="link"
          onKeyUp={(e) => {
            if (e.key === 'Escape') onEscape()
          }}
          onClick={onOutsideClick}
          className="fixed top-0 right-0 bottom-0 left-0 z-20 opacity-0 "
        >
          &nbsp;
        </div>
      )}
      <DropDownContainer>
        {styleType === 'textStyle' ? (
          <button
            type="button"
            className="max-w-min flex items-center border-0 text-primary-color font-semibold underline"
            onClick={toggling}
          >
            {monthLabel}
            <span className="">{year}</span>
            <ArrowDownSelect style={{ fontSize: '18px' }} color="var(--primary-color)" />
          </button>
        ) : (
          <DropDownHeader className="max-w-min flex items-center" onClick={toggling}>
            {monthLabel}
            <span className="pl-4 pr-6">{year}</span>
            <ArrowDownSelect style={{ fontSize: '18px' }} color="var(--primary-color)" />
          </DropDownHeader>
        )}

        <ModalLayer
          onOutsideClick={onOutsideClick}
          show={isOpen}
          responsive={{ small: 'full', medium: 'center', large: 'pinned' }}
        >
          <div className="relative float-left shadow-lg p-6 border border-primary-soft-color rounded-lg bg-white z-50">
            {/* header */}
            <div className="flex bg-white justify-evenly items-center rounded-lg px-6 pb-2  z-50">
              <button
                onClick={() => prevYear()}
                className="text-2xl font-extrabold text-primary-color"
                type="button"
              >
                <ArrowLeft style={{ fontSize: '18px' }} color="var(--primary-color)" />
              </button>
              <button
                className="flex items-center border border-primary-soft-color p-3 rounded-lg"
                type="button"
                onClick={() => {
                  setIsYearOpen(!isYearOpen)
                }}
              >
                <span className="text-default-color text-base pr-6">{year}</span>
                <ArrowDownSelect style={{ fontSize: '18px' }} color="var(--primary-color)" />
              </button>
              <button
                onClick={() => nextYear()}
                className={`text-2xl font-extrabold text-accent-color ${
                  year === currentYear ? 'opacity-10' : ''
                }`}
                type="button"
              >
                <ArrowRight style={{ fontSize: '18px' }} color="var(--primary-color)" />
              </button>
            </div>
            {/* years or month */}
            <div className="relative z-50 ">
              {/* years */}
              {isYearOpen && (
                <div>
                  <DropDownListContainer>
                    <DropDownList>
                      {years.map((y) => (
                        <ListItem
                          className={`${
                            year === y
                              ? 'text-on-accent-color bg-accent-color rounded-lg'
                              : 'text-primary-color'
                          }`}
                          onClick={() => onYearPick(y)}
                          key={y}
                        >
                          {y}
                        </ListItem>
                      ))}
                    </DropDownList>
                  </DropDownListContainer>
                </div>
              )}

              {/* months */}
              {!isYearOpen && (
                <div>
                  <DropDownListContainer>
                    <DropDownList>
                      {months.map((m, i) => (
                        <ListItem
                          className={`${
                            month - 1 === i
                              ? 'text-on-accent-color bg-accent-color rounded-lg'
                              : 'text-primary-color'
                          } ${
                            isFuture(year, i)
                              ? 'text-secondary-color opacity-40 cursor-default '
                              : ''
                          }`}
                          onClick={() => !isFuture(year, i) && onMonthPick(months.indexOf(m) + 1)}
                          key={m}
                        >
                          {m}
                        </ListItem>
                      ))}
                    </DropDownList>
                  </DropDownListContainer>
                </div>
              )}
            </div>
          </div>
        </ModalLayer>
      </DropDownContainer>
    </div>
  )
}
