import * as React from 'react'

function SvgSmiley(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M20.485 3.515A11.922 11.922 0 0012 0a11.922 11.922 0 00-8.485 3.515A11.922 11.922 0 000 12c0 3.205 1.248 6.219 3.515 8.485A11.922 11.922 0 0012 24c3.205 0 6.219-1.248 8.485-3.515A11.921 11.921 0 0024 12c0-3.205-1.248-6.219-3.515-8.485zm-8.485 19C6.202 22.516 1.485 17.799 1.485 12 1.485 6.202 6.202 1.485 12 1.485c5.798 0 10.515 4.717 10.515 10.515 0 5.798-4.717 10.515-10.515 10.515z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
      <path
        d="M15.761 11.455c.956 0 1.733-.777 1.733-1.732s-.777-1.732-1.732-1.732c-.956 0-1.732.777-1.732 1.732s.777 1.732 1.732 1.732zM8.239 11.455c.955 0 1.732-.777 1.732-1.732s-.777-1.732-1.732-1.732-1.732.777-1.732 1.732.777 1.732 1.732 1.732zM12 17.567a5.593 5.593 0 01-5.226-3.647l-1.393.513A7.083 7.083 0 0012 19.052c2.94 0 5.6-1.856 6.619-4.619l-1.393-.513A5.593 5.593 0 0112 17.567z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgSmiley = React.memo(SvgSmiley)
export default MemoSvgSmiley
