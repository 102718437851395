import React, { useEffect, useRef, useState } from 'react'
import { Storage } from 'aws-amplify'
import { XIcon } from '@heroicons/react/outline'
import { useGeolocation } from 'utilities/hooks'
import { Spinner } from 'views/shared/components/spinner'
import './SearchMap.scss'
import { useFiltersStore, useHomeSearchStore } from 'store/homeSearch'
import { Marker, MarkerResponse, PropertyListing, SearchArea } from 'types/Property'
import { PropertyServices } from 'services/property'
import { Config } from 'config'
import { SearchMapController } from './searchMapController'
import { PropertyMapCluster } from './PropertyMapCluster'
import { PropertyMapItem } from './PropertyMapItem'

const geolocationErrorMessages: { [error: number]: string | undefined } = {
  1: 'The geolocation information was denied.',
  2: 'The geolocation failed because one or several internal sources of position returned an internal error.',
  3: 'Timeout',
  4: "Your browser doesn't support geolocation.",
}

export const SearchMap = () => {
  const mapPlaceholderRef = useRef<HTMLDivElement>(null)
  const [geolocation, askGeolocationPermission] = useGeolocation()
  const { setMarkersState, setNumberOfPropertiesFound } = useHomeSearchStore()
  const [selectedProperty, setSelectedProperty] = useState<PropertyListing[] | null>(null)
  const [marker, setMarker] = useState<Marker | null>(null)
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)

  const { filtersState, setFiltersState } = useFiltersStore()

  useEffect(() => {
    const getMarkers = async () => {
      if (
        filtersState.saleType &&
        filtersState.topLeft &&
        filtersState.topLeft.length > 0 &&
        filtersState.bottomRight &&
        filtersState.bottomRight.length > 0
      ) {
        await PropertyServices.getMarkers(filtersState).then((res: MarkerResponse) => {
          SearchMapController.instance().setMarkers(res)
          setMarkersState(res)

          const total =
            res.total === 10000 ? res.markers.reduce((sum, m) => sum + m.count, 0) : res.total
          setNumberOfPropertiesFound(total)
        })
      }
    }
    getMarkers()
    if (filtersState.mapInconsistent) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(filtersState.bottomRight[0], filtersState.topLeft[1]),
        new google.maps.LatLng(filtersState.topLeft[0], filtersState.bottomRight[1]),
      )
      window.setTimeout(() => {
        SearchMapController.instance().fitBounds(bounds)
      }, 100)
      setFiltersState((oldState) => ({
        ...oldState,
        mapInconsistent: false,
      }))
    }
  }, [filtersState])

  useEffect(() => {
    if (mapPlaceholderRef.current) {
      SearchMapController.instance().appendTo(mapPlaceholderRef.current)
      SearchMapController.instance().onBoundsChange((area: SearchArea) => {
        setFiltersState((oldState) => ({
          ...oldState,
          topLeft: area.topLeft,
          bottomRight: area.bottomRight,
        }))
      })
      if (
        !filtersState.topLeft ||
        filtersState.topLeft.length === 0 ||
        !filtersState.bottomRight ||
        filtersState.bottomRight.length === 0
      ) {
        SearchMapController.instance().centerMapTo({ lng: -79.376385, lat: 43.7074 })
        SearchMapController.instance().map?.setZoom(11)
      }
    }
    return () => {}
  }, [mapPlaceholderRef])

  useEffect(() => {
    SearchMapController.instance().onLabelPropertyClick((m: Marker) => {
      SearchMapController.instance().setIconColor(m, '#018363')
      setMarker(m)
    })
  }, [])

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public` as string)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (!marker) return
    ;(async () => {
      const propertyData = await PropertyServices.getPropertyQuickView(marker.ids)
      setSelectedProperty(propertyData)
    })()
  }, [marker])

  useEffect(() => {
    if (mapPlaceholderRef.current) {
      SearchMapController.instance().onGeolocationCenter(() => {
        askGeolocationPermission()
      })
    }
    return () => {}
  }, [])

  useEffect(() => {
    if (geolocation && geolocation.location) {
      SearchMapController.instance().centerMapTo(geolocation.location)
    }
  }, [geolocation])

  // Handle Error Messages
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  useEffect(() => {
    // Check if browser supports geolocation.
    if (!navigator.geolocation) {
      setErrorMessage(geolocationErrorMessages[4])
    } else if (geolocation?.errorCode) {
      setErrorMessage(geolocationErrorMessages[geolocation?.errorCode])
    }
  }, [geolocation])
  const closeHandle = () => {
    setErrorMessage(undefined)
  }
  return (
    <div>
      <div
        className="overflow-x-hidden search-map"
        ref={mapPlaceholderRef}
        style={{ width: '100%' }}
      >
        <div className="flex flex-col justify-center h-96">
          <Spinner className="h-32" alt="Loading" />
        </div>
      </div>
      {/* single Property */}
      {selectedProperty !== null && selectedProperty.length === 1 && (
        <>
          {selectedProperty.map((p) => (
            <PropertyMapItem
              isOpen={selectedProperty !== null && selectedProperty.length === 1}
              onClose={() => {
                SearchMapController.instance().setIconColor(marker!)
                setMarker(null)
                setSelectedProperty(null)
              }}
              key={p.mls}
              property={p}
              imageBaseUrl={imageBaseUrl}
            />
          ))}
        </>
      )}
      {/* cluster Properties */}
      {selectedProperty !== null && selectedProperty.length > 1 && (
        <>
          <PropertyMapCluster
            isOpen={selectedProperty !== null && selectedProperty.length > 0}
            onClose={() => {
              SearchMapController.instance().setIconColor(marker!)
              setMarker(null)
              setSelectedProperty(null)
            }}
            properties={selectedProperty}
            imageBaseUrl={imageBaseUrl}
          />
        </>
      )}
      {/* Display error messages */}
      {errorMessage && (
        <div className="fixed overflow-hidden h-16 bottom-0 left-0 right-0 w-full z-10">
          <div className="error-message-box bg-secondary-color text-on-secondary-color flex flex-row-reverse justify-between items-center absolute bottom-0 left-0 right-0 w-full z-10 text-base font-bold  box-border leading-6 py-3 px-5 sm:px-10">
            <button
              type="button"
              className="bg-secondary-color text-on-secondary-color focus:outline-none h-10 w-10 p-2"
              onClick={() => closeHandle()}
            >
              <span className="sr-only">Close</span>
              <XIcon aria-hidden="true" />
            </button>
            <div className="text-left pr-5">{errorMessage}</div>
          </div>
        </div>
      )}
    </div>
  )
}
