import React, { useState } from 'react'
import { KeyLabel } from 'types'
import { DateHelper } from 'utilities'
import { DropdownField, InputField, ListPicker, MonthPicker } from 'views/shared/components/ui-form'

const optionsSable: KeyLabel[] = [
  {
    key: 'alice',
    label: 'Alice',
  },
  {
    key: 'dormouse',
    label: 'The Dormouse',
  },
  {
    key: 'pat',
    label: 'Pat',
  },
  {
    key: 'rabbit',
    label: 'White Rabbit',
  },
]
export const UiForm = () => {
  const [selected, setSelected] = useState<string>()
  const [month, setMonth] = useState<string>(DateHelper.currentMonthStr())
  return (
    <div>
      <div>
        <h1>Labs and Tests</h1>
      </div>
      <div className="p-4 py-12">
        <InputField name="inputField1" placeholder="Placeholder Test" />
      </div>
      <div className="p-4 py-12">
        selected: {selected}
        <DropdownField
          label="Choose"
          value={selected}
          placeholder="Placeholder"
          onChange={(value) => setSelected(value)}
          options={optionsSable}
          name="dropDown1"
        />
      </div>
      <div className="p-4 py-12">
        selected: {selected}
        <ListPicker
          label="Choose"
          value={selected}
          placeholder="Placeholder"
          onChange={(value) => setSelected(value)}
          options={optionsSable}
          name="dropDown1"
        />
      </div>
      <div className="p-4 py-12">
        sss: {selected}
        <MonthPicker monthStr={month} onChange={(value) => value && setMonth(value)} />
      </div>
    </div>
  )
}
