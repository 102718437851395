import UserTypes from 'services/user/UserTypes'
import { atom } from 'recoil'
import { AsyncStatus } from 'store/types'

export module UserListStore {
  const key = (type: string) => `dashboard.userList.${type}`
  export const defaultQuery: Required<UserTypes.GetUsersParametersType> = {
    q: '',
    from: 0,
    sort: 'averageSessionDuration.desc',
    size: 50,
    roles: undefined,
    agentId: '',
  }
  export const contactsQuery = (agentId: string): Required<UserTypes.GetUsersParametersType> => ({
    q: '',
    from: 0,
    sort: 'averageSessionDuration.desc',
    size: 50,
    roles: undefined,
    agentId,
  })
  export const query = atom<Required<UserTypes.GetUsersParametersType>>({
    key: key('query'),
    default: defaultQuery,
  })
  export const status = atom<AsyncStatus>({
    key: key('status'),
    default: {
      status: 'initial',
    },
  })
  export const list = atom<UserTypes.GetUsersResponse>({
    key: key('list'),
    default: {
      total: 0,
      hits: [],
    },
  })
}
