/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { comparableGallery } from './comparableGallery'
import { addPropertyDetails } from './propertyDetails'

export const addComparables = async (
  doc: PDFKit.PDFDocument,
  data: HomeValuationAnnualReport.Valuation,
  comparables: (
    | HomeValuationAnnualReport.SimilarSold
    | HomeValuationAnnualReport.SimilarAc
    | HomeValuationAnnualReport.PropertyDetails
  )[],
) => {
  for (const property of comparables) {
    await addPropertyDetails(doc, property, data)
    await comparableGallery(doc, property, data)
  }
  return doc
}
