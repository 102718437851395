import React, { useEffect, useRef, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { useGeolocation } from 'utilities/hooks'
import { useNeighbourhoods } from 'views/map/hooks'
import { Spinner } from 'views/shared/components/spinner'
import './MiniMap.scss'
import { PropertyDetails } from 'types/Property'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { MiniMapController } from './miniMapController'

const Button = styled.button`
  background-color: #fff;
  border: 1px solid var(--primary-soft-color);
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  font-weight: 600;
  color: var(--primary-color);
  max-width: 400px;
  &:hover {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #fff;
  }
`

const geolocationErrorMessages: { [error: number]: string | undefined } = {
  1: 'The geolocation information was denied.',
  2: 'The geolocation failed because one or several internal sources of position returned an internal error.',
  3: 'Timeout',
  4: "Your browser doesn't support geolocation.",
}

interface MiniMapProps {
  property: PropertyDetails | null
  neighbourhood?: string
}

export const MiniMap = ({ property, neighbourhood }: MiniMapProps) => {
  const mapPlaceholderRef = useRef<HTMLDivElement>(null)
  const { neighbourhoods } = useNeighbourhoods()
  const [geolocation, askGeolocationPermission] = useGeolocation()
  const history = useHistory()

  const onClickNeighbourhood = () => {
    history.push(`/sold-report/${property?.summary.neighbour}`)
  }

  useEffect(() => {
    if (mapPlaceholderRef.current) {
      MiniMapController.instance().appendTo(mapPlaceholderRef.current)
    }
    return () => {}
  }, [mapPlaceholderRef])

  useEffect(() => {
    if (property && property.summary && property.summary.location) {
      MiniMapController.instance().removeMarker()
      MiniMapController.instance().setMarker(property?.summary.location)
    }
  }, [property])

  useEffect(() => {
    MiniMapController.instance().setNeighbourhoods(neighbourhoods)
  }, [neighbourhoods, askGeolocationPermission])

  // Handle Error Messages
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  useEffect(() => {
    // Check if browser supports geolocation.
    if (!navigator.geolocation) {
      setErrorMessage(geolocationErrorMessages[4])
    } else if (geolocation?.errorCode) {
      setErrorMessage(geolocationErrorMessages[geolocation?.errorCode])
    }
  }, [geolocation])
  const closeHandle = () => {
    setErrorMessage(undefined)
  }

  return (
    <div className="relative">
      <div className="overflow-x-hidden mini-map" ref={mapPlaceholderRef} style={{ width: '100%' }}>
        <div className="flex flex-col justify-center h-96">
          <Spinner className="h-32" alt="Loading" />
        </div>
      </div>
      <div className="absolute left-5 right-5 bottom-5 flex items-center justify-center">
        <Button onClick={onClickNeighbourhood}>View {neighbourhood} Sold Report</Button>
      </div>
      {/* Display error messages */}
      {errorMessage && (
        <div className="fixed overflow-hidden h-16 bottom-0 left-0 right-0 w-full z-10">
          <div className="error-message-box bg-secondary-color text-on-secondary-color flex flex-row-reverse justify-between items-center absolute bottom-0 left-0 right-0 w-full z-10 text-base font-bold  box-border leading-6 py-3 px-5 sm:px-10">
            <button
              type="button"
              className="bg-secondary-color text-on-secondary-color focus:outline-none h-10 w-10 p-2"
              onClick={() => closeHandle()}
            >
              <span className="sr-only">Close</span>
              <XIcon className="" aria-hidden="true" />
            </button>
            <div className="text-left pr-5">{errorMessage}</div>
          </div>
        </div>
      )}
    </div>
  )
}
