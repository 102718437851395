import * as React from 'react'

function SvgToggleList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M.023.566C.057.378.255.138.443.056.56.003 1.12-.007 3.319.002 5.994.013 23.49.015 23.617.11c.071.053.173.155.226.226.095.128.097.175.097 2.916 0 2.74-.002 2.788-.097 2.916a1.338 1.338 0 01-.226.226c-.128.095-17.614.097-20.355.097-2.74 0-2.788-.002-2.915-.097a1.343 1.343 0 01-.227-.226C.026 6.04.023 5.98.01 3.378.002 1.914.008.649.023.566zM.096 9.162c.108-.224.243-.329.502-.391.097-.024 1.338-.04 2.758-.035 2.515.008 19.679.01 19.86.106a.71.71 0 01.293.3l.108.204v5.321l-.108.204a.71.71 0 01-.293.3c-.182.096-17.336.098-19.954.098-2.618 0-2.68-.002-2.861-.098a.71.71 0 01-.294-.3L0 14.667v-2.653c0-2.579.003-2.659.096-2.852zM.023 18.075c.034-.188.232-.428.42-.51.116-.052.676-.062 2.875-.053 2.676.01 19.825.012 19.952.107.071.053.173.155.227.226.095.128.096.175.096 2.916 0 2.74-.001 2.788-.096 2.916a1.34 1.34 0 01-.227.226c-.128.095-17.267.097-20.008.097-2.74 0-2.788-.002-2.915-.097a1.344 1.344 0 01-.227-.226c-.094-.127-.097-.187-.11-2.79-.008-1.463-.002-2.729.013-2.812z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgToggleList = React.memo(SvgToggleList)
export default MemoSvgToggleList
