/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useMultiSectionFormState } from 'utilities/hooks/useMultiSectionFormState'

export const StateForm = () => {
  // console.log('render StateForm')
  const { state, setField, validationState, start, isStarted, isValidStarted, isValid } =
    useMultiSectionFormState({
      formName: 'labs-form1',
      initial: {
        step1: {
          name: '',
          phone: '',
        },
      },
      validation: {
        step1: {
          name: (v) => [
            v.value.length < 2 && 'Name required',
            v.value.length > 9 && ['Max 9', 'sometthi'],
          ],
        },
      },
    })
  // console.clear()
  // console.log(started)
  const gogo = () => {
    // form1.startSection('personal')
    start('step1')
    // form1.addSectionError('personal', 'name')
  }
  return (
    <div>
      <div>
        <NavLink to="./">Back</NavLink>
      </div>

      <div>
        <input
          className="border-2 bg-green-500"
          style={{ backgroundColor: !isValidStarted.step1.name ? 'red' : '' }}
          value={state.step1.name}
          onBlur={() => start('step1', 'name')}
          onChange={(v) => setField('step1', 'name', v.currentTarget.value)}
        />
        {isStarted.step1.section && (
          <ul className="bg-red">
            {validationState.map(({ message, type }) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        )}
        {/* <div>{isValidStarted.step1.section ? 'step1 valid' : 'step INVALID'}</div> */}
      </div>
      <div>
        <button type="button" onClick={gogo}>
          gogo
        </button>
      </div>
      {/* <div>
        <button onClick={() => start('step1', 'name')} type="button">
          start Name
        </button>
        <button onClick={() => start('step1')} type="button">
          start section
        </button>
      </div>
      <div>
        name: {isStarted.step1.name ? 'true' : 'false'} {isValid.step1.name ? 'valid' : 'invalid'}
        <br />
        section: {isStarted.step1.section ? 'true' : 'false'}
      </div> */}
    </div>
  )
}
