import { MyAgent } from './pages'
import { paths } from './paths'

export const routes = {
  myAgent: {
    path: paths.myAgent(),
    exact: true,
    component: MyAgent,
  },
}
