import React, { PropsWithChildren, ReactChild, useEffect } from 'react'
import './AuthLayout.scss'

export type AuthLayoutProps = PropsWithChildren<{
  sideContent?: ReactChild
}>

const images = [
  '../assets/slider-img1.jpg',
  '../assets/slider-img2.jpg',
  '../assets/slider-img3.jpg',
  '../assets/slider-img4.jpg',
  '../assets/slider-img5.jpg',
]
export const AuthLayout = ({ children, sideContent }: AuthLayoutProps) => {
  const [index, setIndex] = React.useState(0)
  useEffect(() => {
    const changeImageOnRefresh = () => {
      if (performance.getEntriesByType('reload')) {
        const setImage = images[Math.floor(Math.random() * images.length)]
        setIndex(images.indexOf(setImage))
      }
    }
    changeImageOnRefresh()
  }, [index])

  return (
    <>
      <div className="auth-layout bg-white flex text-left">
        <div className="flex-1 flex flex-col justify-start py-5 px-4 sm:py-5 sm:px-6 xl:flex-none xl:px-16">
          <div className="mx-auto w-full max-w-sm xl:w-96">{children}</div>
        </div>
        <div className="relative auth-layout_slideshow hidden xl:block w-0 flex-1 xl:space-x-24 space-y-24">
          <div
            className="auth-layout_slideshow-slider"
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            {images.map((items) => (
              <img key={items} className="slide-image" src={items} alt="" />
            ))}
          </div>
          <div className="absolute -top-6 max-w-screen-sm  mx-5">{sideContent}</div>
        </div>
      </div>
    </>
  )
}
