import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useHomeSearchStore } from 'store/homeSearch/homeSearchStore'
import { PropertyDetails } from 'types/Property'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import ArrowLeft from 'views/shared/components/icons/ArrowLeft'
import { Share } from 'views/shared/components/share'
import { SecondaryNegativeButton } from 'views/shared/components/ui-form'

interface PropertyDetailsHeaderProps {
  propertyDetails: PropertyDetails | null
  neighbourhood?: string
}

export const PropertyDetailsHeader = ({
  propertyDetails,
  neighbourhood,
}: PropertyDetailsHeaderProps): ReactElement => {
  const history = useHistory()
  const [hover, setHover] = useState(false)
  const property = propertyDetails?.summary

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const onClickGoBack = () => {
    history.goBack()
  }

  const onClickNeighbourhood = () => {
    history.push(`/sold-report/${property?.neighbour}`)
  }

  return (
    <>
      <div>
        <div className="flex flex-col sm:flex-row sm:items-center xl:items-baseline sm:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:space-x-8 f flex-shrink-0">
            <div className="flex items-center justify-between sm:block">
              {history.length > 1 && (
                <div className="w-22">
                  <SecondaryNegativeButton
                    onClick={onClickGoBack}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex justify-between items-center space-x-1">
                      <ArrowLeft color={hover ? 'white' : 'var(--primary-color)'} />
                      <div className="mr-1">
                        {(history.location.state as any)?.from?.startsWith('/home-search')
                          ? 'Back to results'
                          : 'Back'}
                      </div>
                    </div>
                  </SecondaryNegativeButton>
                </div>
              )}
              <div className="block sm:hidden">
                <Share
                  title="Share this Property Details"
                  text=""
                  url={window.location.href}
                  files={[]}
                />
              </div>
            </div>
            <div className="text-default-color text-sm pt-5 block sm:hidden">
              MLS® {property?.mls}
            </div>
            <div className="flex flex-col items-start space-y-1 sm:pt-5 xl:pt-0 xl:flex-row xl:items-center text-md xl:space-y-0 xl:space-x-2">
              <div className="font-bold text-xl">
                {property?.unit && `Unit #${property.unit} - `}{' '}
                {property?.address && UnescapeHelper.unescape(property?.address)},
              </div>
              <div className="font-bold text-xl">
                <span>
                  {property?.municipality} {propertyDetails?.postalCode}
                </span>
              </div>
              <button
                className="text-sm font-semibold text-primary-color underline border-none"
                onClick={onClickNeighbourhood}
                type="button"
              >
                View {neighbourhood} Sold Report
              </button>
            </div>
          </div>
          <div className="pt-10 md:pt-0 flex-shrink-0">
            <div className="font-normal text-default-color text-sm hidden sm:block sm:pt-5 xl:pt-0 ">
              MLS® {property?.mls}
            </div>
            <div className="hidden sm:flex sm:justify-end pt-2">
              <Share
                title="Share this Property Details"
                text=""
                url={window.location.href}
                files={[]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
