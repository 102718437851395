import { atom, useAtom } from 'jotai'

import { SearchPropertyFilter } from 'types/Property'

export const initialFilters = {
  topLeft: [],
  bottomRight: [],
  showActive: null as number | null,
  showSold: null as number | null,
  address: null as string | null,
  saleType: 'sale' as string,
  minPrice: null as number | null,
  maxPrice: null as number | null,
  minsqft: null as string | null,
  maxsqft: null as string | null,
  minLotFront: null as number | null,
  minLotDepth: null as number | null,
  bath: null as string | null,
  bed: 0 as number,
  types: null as string[] | null,
  parking: null as string | null,
  dom: null as number | null,
  openHouse: false,
  mapInconsistent: false,
} as SearchPropertyFilter

const filterData = atom<SearchPropertyFilter>(initialFilters)

export const useFiltersStore = () => {
  const [filtersState, setFiltersState] = useAtom(filterData)

  return {
    filtersState,
    setFiltersState,
  }
}
