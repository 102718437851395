import * as React from 'react'

function SvgLocation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#location_svg__clip0_235:2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.395.011c-1.075.123-1.69.256-2.382.514A8.811 8.811 0 004.59 4.161c-.986 1.588-1.441 3.646-1.214 5.488.365 2.958 2.475 7.042 5.973 11.563.828 1.07 1.891 2.35 2.135 2.57.322.29.72.29 1.043 0 .244-.22 1.307-1.5 2.135-2.57 3.149-4.069 5.167-7.765 5.806-10.633.164-.738.216-1.219.216-1.985-.003-2.254-.906-4.407-2.531-6.031C16.809 1.22 15.225.407 13.365.103c-.44-.071-1.652-.128-1.97-.092zm1.157 4.339a4.44 4.44 0 013.676 3.187c.125.446.172 1.344.096 1.83-.217 1.388-1.13 2.609-2.42 3.237-.76.371-1.536.51-2.333.42-.619-.07-.933-.16-1.466-.42-1.29-.628-2.203-1.85-2.42-3.238-.076-.485-.029-1.383.096-1.829.587-2.091 2.657-3.474 4.771-3.187z"
          fill="#B0B8BC"
        />
      </g>
      <defs>
        <clipPath id="location_svg__clip0_235:2">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgLocation = React.memo(SvgLocation)
export default MemoSvgLocation
