import React from 'react'
import './InfoCard.scss'

type InfoCardProps = {
  content: string | JSX.Element
  props?: React.SVGProps<SVGSVGElement>
}

export const InfoCard = ({ content, props }: InfoCardProps) => (
  <>
    <div className="info-card relative">
      <svg
        // className="w-6 h-6 cursor-pointer ml-2"
        fill="var(--primary-color)"
        viewBox="0 0 20 20"
        aria-hidden="true"
        width="1em"
        height="1em"
        xmlns="http://www.w3.org/2000/svg"
        className={`--icon ${props?.className || ''}`}
        {...props}
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clipRule="evenodd"
        />
      </svg>
      <span className="info-card__content w-72 md:w-80 text-default-color rounded-lg font-medium bg-white border border-primary-soft-color p-5 text-sm absolute right-0 top-18 shadow-xl z-10">
        {content}
      </span>
    </div>
  </>
)
