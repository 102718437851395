import * as React from 'react'

function SvgArrowrightbold(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M17.21 9.474l-4.26 4.236c-.148.148-.586.603-.81 1.228a2.179 2.179 0 00-.11 1.12c.073.416.279.83.651 1.221.371.39.773.615 1.19.692a1.96 1.96 0 001.121-.14c.617-.26 1.094-.76 1.303-.981l.012-.012 5.039-5.299c.704-.74 1.075-1.627 1.075-2.534 0-.907-.371-1.794-1.075-2.534-.787-.827-1.547-1.613-2.239-2.328C17.9 2.896 16.9 1.862 16.323 1.19c-.219-.255-.7-.77-1.335-1.03a1.952 1.952 0 00-1.123-.126c-.415.08-.814.308-1.184.697-.372.39-.58.806-.649 1.229-.07.418.002.808.13 1.142.244.642.718 1.14.929 1.362l4.12 4.062v.948z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.477 9a2 2 0 012-2h13.999a2 2 0 11.001 4h-14a2 2 0 01-2-2z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgArrowrightbold = React.memo(SvgArrowrightbold)
export default MemoSvgArrowrightbold
