import { StackPageType } from 'utilities/hooks'
import { UserRole } from 'constants/UserConstants'
import {
  Container,
  AnalyticsPage,
  NotFoundPage,
  UserListPage,
  InviteUserPage,
  ImportContactsPage,
} from './pages'
import { paths } from './paths'
import { MyProfilePage } from './pages/myProfile'
import { ProfilePage } from './pages/profile'
import { EmailAnalyticsPage } from './pages/analytics/emailAnalytics'
import { ValuationsPage } from './pages/valuations'
import { ValuationConfirmationPage } from './pages/valuations/components/valuationConfirmation/ValuationConfirmation'

export const routes = {
  dashboard: {
    path: paths.dashboard(),
    exact: false,
    component: Container,
    requiredRoles: [UserRole.ADMIN, UserRole.AGENT],
  },
}

export const dashboardRoutes: { [page: string]: React.ComponentType<StackPageType> } = {
  main: AnalyticsPage,
  'not-found': NotFoundPage,
  users: UserListPage,
  profile: ProfilePage,
  'my-profile': MyProfilePage,
  'invite-user': InviteUserPage,
  'email-analytics': EmailAnalyticsPage,
  agent: ProfilePage,
  'agent-contacts': UserListPage,
  'import-contacts': ImportContactsPage,
  valuations: ValuationsPage,
  confirmation: ValuationConfirmationPage,
}
