/* eslint-disable no-plusplus */
import { Neighbourhood, NeighbourhoodReport, Property, PropertyType } from 'types'
import { CurrencyHelper, DateHelper, PdfHelper } from 'utilities'
import * as moment from 'moment-timezone'

export type TableHeaderProps = {
  propertyType: string
}
const tableHeader = ({
  propertyType,
}: TableHeaderProps) => /* svg */ `<svg width="1338" height="48" viewBox="0 0 1338 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1338" height="48" fill="white"/>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="96" y="40.519">Address</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="500" y="39.019">List Price</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="638" y="39.019">Sold Price</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="846" y="19.019">% To &#10;</tspan><tspan x="846" y="39.019">List</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="940" y="39.019">DOM</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="1026" y="40.019">${
  propertyType !== 'condo' && propertyType !== 'condoTownhouse' ? 'Lot Size' : 'Sqft'
} </tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="1155" y="40.519">Bd</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="1207" y="40.519">Br</tspan></text>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="387" y="19.019">Date &#10;</tspan><tspan x="387" y="39.019">Sold</tspan></text>
</svg>
`

export type TableRowProps = {
  index: number
  property: Property
  propertyType: PropertyType
}
const tableRow = ({ index, property, propertyType }: TableRowProps) => {
  const percentToList = ((property.soldPrice / property.listingPrice) * 100).toFixed(0)
  const soldPriceDiff = (soldPrice: number, listPrice: number): string => {
    const calculatedValue = Math.round((soldPrice - listPrice) / 1000)
    return calculatedValue > 0 ? `+${calculatedValue}k` : `${calculatedValue}k`
  }
  return /* svg */ `
<svg width="1338" height="48" viewBox="0 0 1338 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  ${!(index % 2) ? `<rect x="69" width="1191" height="48" rx="8" fill="#F4F6F6"/>` : ``}
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="96" y="29.736">${
    property.unit ? `#${property.unit} - ${property.address}` : property.address
  } </tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="387" y="28.736">${DateHelper.formatDateToMonthDay(
    moment.tz(property.soldDate, 'America/Toronto').format('MMM D'),
  )}</tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="bold" letter-spacing="0em"><tspan x="500" y="29.736">$${CurrencyHelper.currencyFormat(
    property.listingPrice,
  )}</tspan></text>
  <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="bold" letter-spacing="0em"><tspan x="638" y="29.736">$${CurrencyHelper.currencyFormat(
    property.soldPrice,
  )}</tspan>
  <tspan fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em" x="730" y="29.736">${soldPriceDiff(
    property.soldPrice,
    property.listingPrice,
  )}</tspan>
  </text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="846" y="28.736">${percentToList}%</tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="940" y="28.736">${
    property.dom
  } </tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="1026" y="28.736">${
    propertyType !== 'condo' && propertyType !== 'condoTownhouse'
      ? `${property.lotFront || property.landFront} x ${property.lotDepth || property.landDepth}`
      : property.footage
  }
  </tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="1160.5" y="28.736">${
    property.bedroom
  }</tspan></text>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="1212" y="28.736">${
    property.bathroom
  }</tspan></text>
</svg>
`
}
export type TableProps = {
  neighbourhoodReport: NeighbourhoodReport
  neighbourhood: Neighbourhood
  monthLabel: string
  y?: number
}
export const addTable = (doc: PDFKit.PDFDocument, { neighbourhoodReport, y = 0 }: TableProps) => {
  PdfHelper.addSVG(doc, tableHeader({ propertyType: neighbourhoodReport.type }), 0, y, {
    preserveAspectRatio: 'xMaxYMin meet',
  })
  const soldProperties = [
    ...neighbourhoodReport.soldProperties
      .slice()
      .sort((a, b) => a.closeDate || 0 - (b.closeDate || 0)),
  ]
  const propertyType = neighbourhoodReport.type
  let yPage: number = y
  let iPage = 0
  let i = 0
  const perPage = 15
  const rowHeight = 24
  const total = soldProperties.length
  while (i < total) {
    const properties = soldProperties
    PdfHelper.addSVG(
      doc,
      tableRow({ index: i, property: properties[i], propertyType }),
      0,
      (iPage + 1) * rowHeight + yPage,
      {
        preserveAspectRatio: 'xMaxYMin meet',
      },
    )
    i++
    iPage++
    if (iPage > perPage) {
      iPage = 0
      yPage = 20 // new page top margin
      doc.addPage()
    }
  }
  // check return
  return iPage * rowHeight + yPage
}
