import styled from 'styled-components'

export const DropDownContainer = styled('div')`
  position: relative;
`
export const DropDownHeader = styled('div')`
  background-color: #ffffff;
  box-sizing: border-box;
  font-weight: normal;
  padding: 6px 12px;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  line-height: 125%;
  border: 1px solid var(--primary-soft-color);
  color: var(--secondary-color);
  cursor: pointer;

  &::after {
    content: '';
  }
`
export const DropDownHeaderInput = styled('button')`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 0px 12px;
  cursor: pointer;
  font-size: 16px;
  color: var(--secondary-color);
  background: #ffffff;
  text-align: center;
  border: 1px solid var(--primary-soft-color);
  border-radius: 6px;
  &::after {
    content: '';
  }
`
export const DropDownListContainer = styled('div')`
  width: 240px;
`
export const DropDownList = styled('ul')`
  width: 240px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 0;
  margin: 0;
  background: transparent;
  box-sizing: border-box;
`
export const ListItem = styled('li')`
  list-style: none;
  box-sizing: border-box;
  margin: 4px 8px;
  padding: 8px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
`
export const DayList = styled('ul')`
  overflow: hidden;
  display: block;
  width: 100%;
  padding: 0px 10px;
`
export const DayListItem = styled('li')`
  list-style: none;
  width: ${100 / 7}%;
  display: block;
  float: left;
  height: 30px;
  font-weight: 600;
  background-color: transparent;
  text-align: center;
  line-height: 30px;
  border-radius: 6px;
  margin-bottom: 5px;
  color: var(--primary-color);

  &.Sunday {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--default-color);
  }
  &.--firstDay {
    &.Sunday {
      margin-left: ${(100 / 7) * 0}%;
    }
    &.Monday {
      margin-left: ${(100 / 7) * 1}%;
    }
    &.Tuesday {
      margin-left: ${(100 / 7) * 2}%;
    }
    &.Wednesday {
      margin-left: ${(100 / 7) * 3}%;
    }
    &.Thursday {
      margin-left: ${(100 / 7) * 4}%;
    }
    &.Friday {
      margin-left: ${(100 / 7) * 5}%;
    }
    &.Saturday {
      margin-left: ${(100 / 7) * 6}%;
    }
  }
  &.--enabled {
    cursor: pointer;
    // &:hover {
    //   background-color: var(--accent-color);
    //   color: #fff;
    // }
  }
  &.--disabled {
    cursor: default;
    opacity: 0.4;
    color: var(--secondary-color, #000);
  }

  &.--selected {
    color: #fff;
    background-color: var(--accent-color, rgba(0, 0, 0, 0.2));
  }
`
