import { FollowingServices } from 'services/following'
import { AuthContext } from 'views/authentication/contexts'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Neighbourhood } from 'types'
import { followedNeighbourhoodsState, localFollowedNeighbourhoodIdsState } from '../state'

type UseFollowsProps = {
  isLoading: boolean
  isNeighbourhoodFollowed: (id: string) => boolean
  addFollowedNeighbourhood: (id: string) => void
  removeFollowedNeighbourhood: (id: string) => void
  toggleFollowedNeighbourhood: (id: string) => void
  followedNeighbourhoods: Array<Neighbourhood>
}

let started = false
export const useFollows = (): UseFollowsProps => {
  const [isLoading, setIsLoading] = useState(false)
  const [localFollowedNeighbourhoodIds, setLocalFollowedNeighbourhoodIds] = useRecoilState<
    string[] | null
  >(localFollowedNeighbourhoodIdsState)
  const followedNeighbourhoods = useRecoilValue(followedNeighbourhoodsState)
  const isNeighbourhoodFollowed = useCallback(
    (id: string) => localFollowedNeighbourhoodIds?.includes(id) || false,
    [localFollowedNeighbourhoodIds],
  )
  const { authUser } = useContext(AuthContext)
  useEffect(() => {
    if (started || !authUser) return
    started = true
    setIsLoading(true)
    FollowingServices.getFollowings()
      .then((followings) => {
        authUser
          ? setLocalFollowedNeighbourhoodIds(followings.neighbourhoods)
          : setLocalFollowedNeighbourhoodIds([])
      })
      .catch(() => {
        setLocalFollowedNeighbourhoodIds([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isLoading, setLocalFollowedNeighbourhoodIds, authUser])
  const addFollowedNeighbourhood = useCallback(
    async (id: string) => {
      setIsLoading(true)
      setLocalFollowedNeighbourhoodIds((current) => (current ? [...current, id] : [id]))
      try {
        await FollowingServices.followNeighbourhood(id)
      } catch (e) {
        setLocalFollowedNeighbourhoodIds((current) =>
          current ? current.filter((i) => i !== id) : [],
        )
      } finally {
        setIsLoading(false)
      }
    },
    [setLocalFollowedNeighbourhoodIds],
  )
  const removeFollowedNeighbourhood = useCallback(
    async (id: string) => {
      setIsLoading(true)
      setLocalFollowedNeighbourhoodIds((current) =>
        current ? current.filter((i) => i !== id) : [],
      )
      try {
        await FollowingServices.unfollowNeighbourhood(id)
      } catch (e) {
        setLocalFollowedNeighbourhoodIds((current) =>
          current ? [...current.filter((i) => i !== id), id] : [],
        )
      } finally {
        setIsLoading(false)
      }
    },
    [setLocalFollowedNeighbourhoodIds],
  )
  const toggleFollowedNeighbourhood = useCallback(
    (id: string) => {
      if (isNeighbourhoodFollowed(id)) {
        removeFollowedNeighbourhood(id)
      } else {
        addFollowedNeighbourhood(id)
      }
    },
    [isNeighbourhoodFollowed, removeFollowedNeighbourhood, addFollowedNeighbourhood],
  )

  return {
    isLoading,
    followedNeighbourhoods,
    addFollowedNeighbourhood,
    removeFollowedNeighbourhood,
    toggleFollowedNeighbourhood,
    isNeighbourhoodFollowed,
  }
}
