// based on  https://gist.github.com/carpben/de968e377cbac0ffbdefe1ab56237573
import { MutableRefObject, useRef } from 'react'

export const useFocus = (): [any, () => void] => {
  const htmlElRef: MutableRefObject<any> = useRef(null)
  const setFocus = (): void => {
    htmlElRef?.current?.focus?.()
  }

  return [htmlElRef, setFocus]
}
