/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react'
import UserTypes from 'services/user/UserTypes'
import Moment from 'moment'
import { Neighbourhood } from 'types/Neighbourhood'
import { describeUserFeed } from 'utilities/helpers/userFeedHelper'
import styled from 'styled-components'
import { TextBody } from '../../../../shared/components/ui-form/styled'

type UserFeedType = {
  userFeed: UserTypes.UserFeed
  index: number
  neighbourhoods: Neighbourhood[]
}

const Item = styled.li`
  border-top: 1px solid var(--alpha-color);
  padding: 12px 0px;
  cursor: pointer;
`

export const UserFeed = ({ userFeed, neighbourhoods, index }: UserFeedType) => {
  const feedDescription = describeUserFeed(userFeed, neighbourhoods)
  return (
    <>
      <ul className="divide-y" key={`ufa_${index}`}>
        <Item>
          <div className="flex justify-between space-x-10">
            <p className="font-normal">{Moment(userFeed.time).format('MMM D')}</p>
            <p className="text-primary-color font-semibold md:flex">{feedDescription.title}</p>
            <p className="font-normal">{feedDescription.desc}</p>
          </div>
        </Item>
      </ul>
    </>
  )
}
