/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { GrowthArrow } from 'views/shared/components/icons'
import { ContainerDetails } from 'views/dashboard/styles'
import { HomeValueEstimation } from 'types'
import { HomeValuationServices } from 'services/homeValuation'
import { PropertyServices } from 'services/property'
import { HomeValuationSnapshot } from 'views/homeValuation/pages/property-valuation/components'
import { PropertyListing } from 'types/Property'
import { Spinner } from 'views/shared/components/spinner'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { PropertyDetailsForm } from 'views/homeValuation/pages/property-details/PropertyDetailsForm'
import { SimilarSold } from 'views/homeValuation/SimilarSold'
import { Config } from 'config'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { UpdateValuation } from './UpdateValuation'
import { ValuationConfirmationPropertyDetails } from './ValuationConfirmationPropertyDetails'

export const ValuationConfirmationPage = () => {
  const [valuation, setValuation] = useState<HomeValueEstimation>()
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)
  const [propertyImageState, setPropertyImageState] = useState<string[] | null>(null)
  const [similarProperties, setSimilarProperties] = useState<PropertyListing[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const { estimationState } = useHomeValuationStore()
  const isPathPropertyEstimation = window.location.pathname.includes('/property-estimation')

  useEffect(() => {
    const vid = isPathPropertyEstimation
      ? estimationState?.id
      : window.location.pathname.split('/').pop()
    vid && HomeValuationServices.getHomeValuation(vid).then(setValuation)

    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public`)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (valuation?.lastListing) {
      const newImages: string[] = PropertyServices.getPropertyImageURLs(
        valuation.lastListing,
        imageBaseUrl,
      )
      setPropertyImageState(newImages)
    } else {
      setPropertyImageState([])
    }
  }, [valuation, imageBaseUrl])

  useEffect(() => {
    valuation?.id &&
      !similarProperties &&
      HomeValuationServices.getHomeValuationSimilarProperties(valuation.id).then((res) => {
        try {
          setSimilarProperties(res)
        } catch (e) {
          console.error('Error getting home valuation similar properties', e)
        }
        setIsLoading(false)
      })
  }, [valuation])

  const isStandAlonePage = () => window.location.pathname.includes('/home-valuation/confirmation')

  return (
    <>
      {!valuation && (
        <div className="flex flex-col justify-center h-96">
          <Spinner className="h-32" alt="Loading" />
        </div>
      )}
      {valuation && (
        <ContainerDetails>
          <div
            className={[isStandAlonePage() && 'w-1/2 m-auto', 'w-full flex flex-col relative'].join(
              ' ',
            )}
          >
            <div className="flex items-center space-x-2 pb-5">
              <GrowthArrow color="var(--accent-color)" />
              <div className="text-lg sm:flex sm:space-x-1">
                <span className="text-accent-color font-semibold">
                  Valuation confirmation request
                </span>
                <p>
                  <span className="text-default-color"> from </span>
                  <span className="font-semibold">
                    {valuation?.user?.firstName} {valuation?.user?.lastName}
                  </span>
                </p>
              </div>
            </div>
            {/* Update valuation component */}
            <UpdateValuation valuation={valuation} />

            {/* Property details  component */}
            <ValuationConfirmationPropertyDetails
              valuation={valuation}
              setShowModal={setShowModal}
            />
            {/* Chart */}
            <div className="mt-6 w-full h-full bg-white ">
              <HomeValuationSnapshot valuationState={valuation} />
            </div>

            {/* Similar sold */}
            {/* <div className="xl:max-w-3xl">
              {isLoading ? (
                <div className="flex w-full justify-center items-center">
                  <Spinner style={{ fontSize: '128px' }} color="#018363" />
                </div>
              ) : (
                <SimilarSold property={valuation?.summary} similarProperties={similarProperties} />
              )}
            </div> */}
          </div>
        </ContainerDetails>
      )}
      <ModalLayer
        onOutsideClick={() => setShowModal(false)}
        show={showModal}
        responsive={{
          small: 'center',
        }}
        outsideColor="rgba(0, 0, 0, 0.5)"
      >
        <div className="flex">
          <div className="w-min md:py-5 xl:py-12">
            <button
              className="text-xl font-semibold text-on-primary-color text-right w-full pr-2"
              type="button"
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <div className="space-y-10 max-w-md md:max-w-3xl bg-white w-full rounded-lg border-2 border-primary-color md:border-none md:p-5 xl:p-12">
              {valuation && (
                <PropertyDetailsForm valuationId={valuation.id} setShowModal={setShowModal} />
              )}
            </div>
          </div>
        </div>
      </ModalLayer>
    </>
  )
}
