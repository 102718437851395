import styled from 'styled-components'
import selectButtonBg from './assets/arrow-down.svg'

export const SelectButton = styled.button<{
  width?: string
}>`
  width: ${(p) => p.width || '100%'};
  padding: 6px 36px 6px 12px;
  height: 48px;
  line-height: 125%;
  cursor: pointer;
  text-align: left;
  color: var(--default-color);
  font-style: italic;
  background: transparent;
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  background-image: url(${selectButtonBg});
  background-position: calc(100% - 10px) calc(1em + 4px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-repeat: no-repeat;
  &:focus {
    outline: none !important;
    box-shadow: none;
  }
`
export const SelectButtonItem = styled.button`
  padding: 6px 12px 6px 12px;
  font-size: 16px;
  color: var(--default-color);
  width: 100%;
  cursor: pointer;
  text-align: left;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  background: white;
  & svg {
    /* display: block; */
    /* float: right; */
    height: 22px;
    width: 22px;
    padding: 0 0 0 8px;
    min-width: 18px;
    --default-icon-color: var(--accent-color);
    visibility: hidden;
  }
  &:hover,
  &:focus {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
  }
  &.--disabled {
    opacity: 0.3;
    color: var(--default-color);
    cursor: default;
  }
  &.--selected {
    padding-right: 64px;
    font-weight: bold;
    & svg {
      visibility: visible;
    }
    &:hover,
    &:focus {
      & svg {
        --default-icon-color: var(--on-accent-color);
      }
    }
  }
  & > .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    & > span {
      flex-basis: 100%;
      flex-grow: 1;
    }
  }
  z-index: 10;
`
export const SelectedButtonModal = styled.div`
  font-size: 14px;
  color: var(--default-color);
  padding: 5px;
  box-shadow: 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 50;
`
export const SelectLabel = styled.span`
  margin: 0 3px;
  padding: 6px;
  border-radius: 6px;
  color: var(--on-accent-color);
  background-color: var(--accent-color);
  overflow-y: scroll;
`
