import React, { createContext, useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'

import { AuthServices } from 'services/auth'
import { UserRole } from 'constants/UserConstants'
import { AgentServices } from 'services/agents'
import { AgentProfile } from 'views/agents/types/agents'
import { UserDataDetails } from 'types/User'
import { UserServices } from 'services/user'
import { useHistory } from 'react-router-dom'
import { paths } from 'views/landing-page/paths'
import { AuthUser, AuthUserChallengeName } from '../types'
import { userHasRequiredRoles } from '../helpers'

export type AuthContextProps = {
  authUser: AuthUser | null | undefined
  profileImage: string
  logoImage: string
  agentProfile: AgentProfile | null
  agentHeadshot: string
  agentLogo: string
  refreshState: () => Promise<void>
  setAuthUserContext: (authUser: AuthUser | null) => void
  signOut: () => Promise<void>
  getAuthRequirement: () => AuthUserChallengeName
  reloadProfile: () => Promise<void>
  isLoggedIn: boolean
  isAdmin: boolean
  isAgent: boolean
  reservedPath: null | string
  setReservedPath: (path: string | null) => void
}

export type AuthContextProviderProps = React.PropsWithChildren<unknown>
export const AuthContext = createContext<AuthContextProps>({ authUser: null } as AuthContextProps)
const DEFAULT_HEADSHOT = '/assets/default-profile.jpg'
const DEFAULT_LOGO = '/assets/castl.jpg'

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [authUser, setAuthUser] = useState<AuthUser | null | undefined>(undefined)
  const [agentProfile, setAgentProfile] = useState<AgentProfile | null>(null)
  const [userProfile, setUserProfile] = useState<UserDataDetails | null>(null)
  const [profileImage, setProfileImage] = useState<string>(DEFAULT_HEADSHOT)
  const [logoImage, setLogoImage] = useState<string>(DEFAULT_LOGO)
  const [agentHeadshot, setAgentHeadshot] = useState<string>(DEFAULT_HEADSHOT)
  const [agentLogo, setAgentLogo] = useState<string>(DEFAULT_LOGO)
  const [reservedPath, setReservedPath] = useState<string | null>(null)
  const history = useHistory()

  const refreshState = async () => {
    try {
      setAuthUser(await AuthServices.getAuthUser())
    } catch (error) {
      setAuthUser(null)
    }
    AuthServices.setLocalStorageSkipped('n')
  }
  const signOut = async () => {
    try {
      await AuthServices.signOut()
      setAuthUser(null)
      AuthServices.setLocalStorageSkipped('n')
      history.replace(paths.landingPage())
      setReservedPath(null)
    } catch (error) {
      setAuthUser(null)
    }
  }
  const setAuthUserContext = (newAuthUserState: AuthUser | null) => {
    setAuthUser(newAuthUserState)
  }
  const getAuthRequirement = () => {
    if (authUser && authUser.user && authUser.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return authUser.user.challengeName
    }
    return undefined
  }

  const reloadProfile = async () => {
    if (authUser) {
      await Promise.all([
        AgentServices.getMyAgentProfile().then(setAgentProfile),
        UserServices.getMyProfile().then(setUserProfile),
      ])
    } else {
      setAgentProfile(null)
      setUserProfile(null)
    }
  }

  // initial check
  useEffect(() => {
    refreshState()
    return () => {}
  }, [])

  useEffect(() => {
    const authUserChanged = async () => {
      await reloadProfile()
    }
    authUserChanged()
  }, [authUser])

  useEffect(() => {
    const reloadAgentImages = async () => {
      if (agentProfile) {
        const promiseHeadshot = (
          agentProfile?.headshot
            ? Storage.get(`headshots/${agentProfile.headshot}`)
            : Promise.resolve(DEFAULT_HEADSHOT)
        ).then(setAgentHeadshot)

        const promiseLogo = (
          agentProfile?.logo
            ? Storage.get(`logos/${agentProfile.logo}`)
            : Promise.resolve(DEFAULT_LOGO)
        ).then(setAgentLogo)

        await Promise.all([promiseHeadshot, promiseLogo])
      } else {
        setAgentHeadshot(DEFAULT_HEADSHOT)
        setAgentLogo(DEFAULT_LOGO)
      }
    }

    reloadAgentImages()
  }, [agentProfile])

  useEffect(() => {
    const reloadProfileImages = async () => {
      if (userProfile) {
        const promiseHeadshot = (
          userProfile?.headshot
            ? Storage.get(`headshots/${userProfile.headshot}`)
            : Promise.resolve(DEFAULT_HEADSHOT)
        ).then(setProfileImage)

        const promiseLogo = (
          userProfile?.logo
            ? Storage.get(`logos/${userProfile.logo}`)
            : Promise.resolve(DEFAULT_LOGO)
        ).then(setLogoImage)

        await Promise.all([promiseHeadshot, promiseLogo])
      } else {
        setProfileImage(DEFAULT_HEADSHOT)
        setLogoImage(DEFAULT_LOGO)
      }
    }

    reloadProfileImages()
  }, [userProfile])

  // console.log(
  //   'authUser',
  //   (authUser as any)?.signInUserSession,
  //   (authUser as any)?.signInUserSession?.accessToken?.payload['cognito:groups'],
  // )
  return (
    <AuthContext.Provider
      value={{
        authUser,
        refreshState,
        setAuthUserContext,
        signOut,
        agentProfile,
        profileImage,
        logoImage,
        agentHeadshot,
        agentLogo,
        getAuthRequirement,
        reloadProfile,
        isLoggedIn: authUser?.user !== undefined,
        isAdmin: userHasRequiredRoles(authUser || null, [UserRole.ADMIN]),
        isAgent: userHasRequiredRoles(authUser || null, [UserRole.AGENT]),
        reservedPath,
        setReservedPath,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
