import { UserServices } from 'services/user'
import { atom, useAtom } from 'jotai'
import { FollowUpData, UserData, UserDataDetails } from 'types'
import { useEffect, useState } from 'react'
import { FollowingServices, FollowingType } from 'services/following'
import {
  isCAPhone,
  isUrl,
  maxLength,
  minLenth,
  required,
  stateValidationStore,
  useStateValidation,
  isDateMMddYYYY,
  isFutureDate,
} from 'utilities/hooks/validationState'
import { PreferredContactMethod } from 'constants/UserConstants'
import { useSearchParams } from 'utilities/hooks/useSearchParams'

const userStateInitial = {
  userId: null as string | null,
  isLoading: false,
  error: null as string | null,
} as {
  userId: string | null
  isLoading: boolean
  error?: string
  data?: UserData
  details?: UserDataDetails
  followUp?: FollowUpData
  isCustomer?: boolean
  isAgent?: boolean
  isAdmin?: boolean
  followedNeighbourhoods?: FollowingType
}
export const UPLOAD_FOLDER = 'uploads/'
export type UserStateType = typeof userStateInitial

// form store
const initial = {
  details: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    headshot: undefined as string | undefined,
    address: '',
    dob: '',
    ownershipStats: '',
    purchaseDate: '',
    preferredContactMethod: PreferredContactMethod.EMAIL as PreferredContactMethod | string,
    receiveSoldReport: true,
    receiveEmail: true,
  },
  followup: {
    date: undefined as number | undefined,
    note: undefined as string | undefined,
  },
  agent: {
    website: '',
    jobDescription: '',
    aboutMe: '',
    instagram: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    logo: undefined as string | undefined,
    testimonials: [] as string[],
  },
  testimonial: {
    testimonial: '',
    i: -1,
  },
}
const store = {
  details: stateValidationStore(initial.details),
  followup: stateValidationStore(initial.followup),
  agent: stateValidationStore(initial.agent),
  testimonial: stateValidationStore(initial.testimonial),
}

const userAtomInstance = [
  { id: null as string | null, atom: atom(userStateInitial) },
  { id: null as string | null, atom: atom(userStateInitial) },
]

export const useUserStore = (index: 0 | 1 = 0, userId?: string) => {
  // state
  const [userState, setUserState] = useAtom(userAtomInstance[index].atom)
  const [inviteSent, setInviteSent] = useState<boolean>()
  const [newMessage, setNewMessage] = useState('')

  // form state
  const detailsForm = useStateValidation({
    store: store.details,
    validation: {
      firstName: [
        required('First name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      lastName: [
        required('Last name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      phone: [isCAPhone('Please, enter a valid canadian phone number')],
      dob: [
        isDateMMddYYYY('Please, enter a valid date'),
        isFutureDate('Date cannot be in the future'),
      ],
      purchaseDate: [
        isDateMMddYYYY('Please, enter a valid date'),
        isFutureDate('Date cannot be in the future'),
      ],
      headshot: [],
    },
  })
  const followUpForm = useStateValidation({
    store: store.followup,
    validation: {
      date: [required('Date is required')],
      note: [required('Note is required'), minLenth(10, 'Note must have at least 10 characters')],
    },
  })
  const agentForm = useStateValidation({
    store: store.agent,
    validation: {
      website: [isUrl('Please, enter a valid url')],
      jobDescription: [minLenth(2, 'Title must have at least 2 characters')],
      aboutMe: [
        minLenth(20, 'Title must have at least 20 characters'),
        maxLength(1000, 'Bio must have at most 1000 characters'),
      ],
      instagram: [isUrl('Please, enter a valid url')],
      linkedin: [isUrl('Please, enter a valid url')],
      facebook: [isUrl('Please, enter a valid url')],
      twitter: [isUrl('Please, enter a valid url')],
    },
  })
  const testimonialForm = useStateValidation({
    store: store.testimonial,
    validation: {
      testimonial: [minLenth(10, 'Testimonial must have at least 10 characters')],
    },
  })
  // actions
  const restoreDetails = async (details?: UserDataDetails) => {
    const newStateDetails = details || userState.details
    if (!newStateDetails) return
    detailsForm.setFieldsData(
      {
        email: newStateDetails.email,
        firstName: newStateDetails.firstName,
        lastName: newStateDetails.lastName,
        headshot: newStateDetails.headshot,
        phone: newStateDetails.phone || '',
        address: newStateDetails.address || '',
        dob: newStateDetails.dob || '',
        ownershipStats: newStateDetails.ownershipStats || '',
        purchaseDate: newStateDetails.purchaseDate || '',
        preferredContactMethod: (newStateDetails.preferredContactMethod ||
          PreferredContactMethod.EMAIL) as PreferredContactMethod | string,
        receiveSoldReport:
          newStateDetails.receiveSoldReport === undefined
            ? true
            : newStateDetails.receiveSoldReport,
        receiveEmail:
          newStateDetails.receiveEmail === undefined ? true : newStateDetails.receiveEmail,
      },
      true,
    )
    agentForm.setFieldsData(
      {
        website: newStateDetails.website || '',
        jobDescription: newStateDetails.jobDescription || '',
        aboutMe: newStateDetails.aboutMe || '',
        instagram: newStateDetails.socialMedias?.instagram || '',
        linkedin: newStateDetails.socialMedias?.linkedin || '',
        facebook: newStateDetails.socialMedias?.facebook || '',
        twitter: newStateDetails.socialMedias?.twitter || '',
        logo: newStateDetails.logo,
        testimonials: newStateDetails.testimonials || [],
      },
      true,
    )
    testimonialForm.setFieldsData({
      testimonial: '',
      i: -1,
    })
  }
  const restoreFollowUp = (followUp?: FollowUpData) => {
    followUpForm.setFieldsData(
      {
        note: followUp ? followUp.note : undefined,
        date: followUp ? followUp.date : undefined,
      },
      true,
    )
  }
  const loadUser = async () => {
    if (!userId) return
    if (userState.userId === userId || userState.isLoading) return
    const newUserState: UserStateType = {
      userId,
      isLoading: true,
    }
    setUserState({ ...newUserState })
    // Following Neighbourhoods
    await FollowingServices.getFollowingsByUserId(userId).then((neighbourhoods) => {
      newUserState.followedNeighbourhoods = neighbourhoods
    })
    // User details
    await UserServices.getUserById(userId)
      .then((response) => {
        newUserState.userId = response.id
        newUserState.data = response
        newUserState.details = response.details
        newUserState.isCustomer = response.roles === null
        newUserState.isAgent = !!(response.roles && response.roles[0] === 'agent')
        newUserState.isAdmin = !!(response.roles && response.roles[0] === 'admin')
        newUserState.followUp = response.followUp
      })
      .catch((error) => {
        newUserState.error = error
      })
      .finally(() => {
        newUserState.isLoading = false
        setUserState({ ...newUserState })
      })
    // fill forms
    if (newUserState.details) {
      restoreDetails(newUserState.details)
      restoreFollowUp(newUserState.followUp)
    }
  }

  const archiveUser = async () => {
    if (!userState.userId) return false
    await UserServices.archiveUser(userState.userId)
    return true
  }

  const updateDetails = async (dataDetails?: UserDataDetails) => {
    if (!userState.userId || !detailsForm.isValid() || !agentForm.isValid()) return
    const details = detailsForm.state
    const agentDetails = agentForm.state
    const testimonials = dataDetails ? dataDetails.testimonials : userState.details?.testimonials

    const newDetails = {
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      phone: details.phone,
      headshot: details.headshot?.startsWith(UPLOAD_FOLDER)
        ? details.headshot.substring(UPLOAD_FOLDER.length)
        : details.headshot,
      address: details.address,
      dob: details.dob,
      ownershipStats: details.ownershipStats,
      purchaseDate: details.purchaseDate,
      preferredContactMethod: details.preferredContactMethod,
      receiveSoldReport: details.receiveSoldReport,
      receiveEmail: details.receiveEmail,
      website: agentDetails.website,
      jobDescription: agentDetails.jobDescription,
      aboutMe: agentDetails.aboutMe,
      socialMedias: {
        instagram: agentDetails.instagram,
        linkedin: agentDetails.linkedin,
        facebook: agentDetails.facebook,
        twitter: agentDetails.twitter,
      },
      logo: agentDetails.logo?.startsWith(UPLOAD_FOLDER)
        ? agentDetails.logo.substring(UPLOAD_FOLDER.length)
        : agentDetails.logo,
      testimonials,
    }
    await UserServices.updateProfile(newDetails, userState.userId)
    setUserState({ ...userState, details: newDetails })
  }
  // FollowUp actions
  const saveFollowUp = async () => {
    if (!followUpForm.isValid() || !userState.userId) {
      return
    }
    const newFollowUp = {
      date: followUpForm.state.date,
      note: followUpForm.state.note,
    }
    await UserServices.createFollowUp(userState.userId, newFollowUp)
  }
  const deleteFollowUp = async () => {
    followUpForm.setFieldsData(
      {
        date: undefined,
        note: undefined,
      },
      true,
    )
    await saveFollowUp()
  }

  // Testimonials actions
  const addTestimonial = async () => {
    const { i } = testimonialForm.state
    if (!testimonialForm.isValid()) return
    let newTestimonials = [...(userState?.details?.testimonials || [])]
    if (i > -1) {
      newTestimonials[i] = testimonialForm.state.testimonial
    } else {
      newTestimonials = [...newTestimonials, testimonialForm.state.testimonial]
    }
    const dataDetails = { ...userState.details, testimonials: newTestimonials } as UserDataDetails
    updateDetails(dataDetails)
    testimonialForm.setFieldsData(
      {
        testimonial: '',
        i: -1,
      },
      true,
    )
  }
  const deleteTestimonial = async (i: number) => {
    const newTestimonials = [...(userState?.details?.testimonials || [])]
    newTestimonials.splice(i, 1)
    const dataDetails = { ...userState.details, testimonials: newTestimonials } as UserDataDetails
    updateDetails(dataDetails)
    testimonialForm.setFieldsData(
      {
        testimonial: '',
        i: -1,
      },
      true,
    )
  }
  const editTestimonial = (i: number) => {
    if (!userState?.details?.testimonials) return
    const editedTestimonial = userState?.details?.testimonials[i]
    testimonialForm.setFieldsData(
      {
        testimonial: editedTestimonial,
        i,
      },
      true,
    )
  }
  const resendInvite = async () => {
    if (!userState?.details?.email) return
    await UserServices.resendInvite(userState.details.email)
      .then(() => {
        setInviteSent(true)
        setNewMessage('Invite sent')
      })
      .catch((e) => {
        setNewMessage('Sorry, invite could not be sent')
        return e
      })
  }

  const searchParams = useSearchParams()

  const receiveSoldReportHandler = async (checked: boolean) => {
    const uid = searchParams.get('uid')
    await UserServices.updateSubscriptions(checked, uid || undefined)
    detailsForm.setField('receiveSoldReport', checked, true)
    // detailsForm.setField('receiveEmail', checked, true)
    updateDetails()
  }

  // start
  useEffect(() => {
    if (userId) loadUser()
  }, [userId])

  return {
    updateDetails,
    restoreDetails,
    userState,
    archiveUser,
    detailsForm,
    agentForm,
    testimonialForm,
    addTestimonial,
    deleteTestimonial,
    editTestimonial,
    resendInvite,
    inviteSent,
    newMessage,
    receiveSoldReportHandler,
    deleteFollowUp,
    followUpForm,
    saveFollowUp,
  }
}
