import { SoldReportDataStatistics } from 'views/soldReport'
import { DateHelper } from 'utilities'
import { PropertyType } from 'types'

import { DataSet, DataStatistic } from './types'

interface ChartData {
  labels: string[]
  datasets: DataSet[]
}

interface RawDataSet {
  data: SoldReportDataStatistics
  label: string
  color?: string
}

interface GenerateChartDataProps {
  datasets: RawDataSet[]
  propertyType: PropertyType
  getData: (data: DataStatistic) => number
}

export const generateChartData = ({
  datasets: userDatasets,
  propertyType,
  getData,
}: GenerateChartDataProps): ChartData => {
  const toStatisticsDataArray = (
    statisticsData: SoldReportDataStatistics,
    property: PropertyType,
  ) => Object.keys(statisticsData).map((month) => getData(statisticsData[month][property]))
  return userDatasets.reduce(
    ({ labels, datasets }, { data, label, color }) =>
      ({
        labels: [
          ...Object.keys(data).map((month, index: number) => {
            const yearMonth = DateHelper.strToYearMonthDay(month)
            return [
              `${DateHelper.toMonthShort(month)}`,
              `${index === 0 || index === labels.length - 1 ? yearMonth.year : ''}`,
            ] as unknown as string
          }),
        ],
        datasets: [
          ...datasets,
          {
            label,
            data: toStatisticsDataArray(data, propertyType),
            color,
            fill: false,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            pointRadius: 2,
          },
        ],
      } as ChartData),
    { labels: [], datasets: [] } as ChartData,
  )
}
