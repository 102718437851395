import React, { useEffect, useState } from 'react'
import { PropertyListing } from 'types/Property'
import { DropdownField } from 'views/shared/components/ui-form'
import { Storage } from 'aws-amplify'
import { PropertyServices } from 'services/property/PropertyServices'
import { Config } from 'config'
import { useFiltersStore, useHomeSearchStore } from 'store/homeSearch'
import { HomeSearchGalleryItems } from './HomeSearchGalleryItems'

export const HomeSearchGallery = () => {
  const pageSize = 20
  const [properties, setProperties] = useState<PropertyListing[] | null>(null)
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState(0)
  const [sortBy, setSortBy] = useState<string>('listDate.desc')
  const [timestamp, setTimestamp] = useState(Date.now())
  const { filtersState, setFiltersState } = useFiltersStore()
  const { setNumberOfPropertiesFound } = useHomeSearchStore()

  const onLoadMoreClick = () => {
    setPage(page + 1)
    setTimestamp(Date.now())
  }

  useEffect(() => {
    setProperties(null)
    setPage(0)
    setTimestamp(Date.now())
  }, [filtersState])

  useEffect(() => {
    if (
      !filtersState.topLeft ||
      filtersState.topLeft.length === 0 ||
      !filtersState.bottomRight ||
      filtersState.bottomRight.length === 0
    ) {
      setFiltersState((oldState) => ({
        ...oldState,
        topLeft: [43.93590916755254, -79.72846248413086],
        bottomRight: [43.47801637091681, -79.02430751586914],
      }))
    }

    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public`)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    ;(async () => {
      const filter = filtersState
      filter.from = (properties || []).length
      filter.size = pageSize
      const data = await PropertyServices.getProperties(filter, sortBy)
      setNumberOfPropertiesFound(data.total)
      setTotal(data.total)
      setProperties([...(properties || []), ...data.hits])
    })()
  }, [timestamp])

  const onSortChange = (type: any) => {
    setProperties(null)
    setSortBy(type)
    setPage(0)
    setTimestamp(Date.now())
  }

  return (
    <>
      <div className="mx-2 lg:mx-10 my-8 lg:my-5">
        <div className="flex flex-row items-center pt-0 sm:mt-0 justify-end space-x-3">
          <span className="text-xs hidden sm:block text-secondary-color font-semibold">
            Sort by
          </span>
          <div className="w-54">
            <DropdownField
              placeholder="Sort by"
              options={[
                { key: 'listDate.desc', label: 'Most Recent' },
                { key: 'listingPrice.desc', label: 'Price High to Low' },
                { key: 'listingPrice.asc', label: 'Price Low to High' },
              ]}
              value={sortBy}
              onChange={onSortChange}
              name="sortBy"
            />
          </div>
        </div>
        <div>
          {properties && (
            <HomeSearchGalleryItems
              onClick={onLoadMoreClick}
              sort={sortBy}
              properties={properties as PropertyListing[]}
              totalProperties={total}
              imageBaseUrl={imageBaseUrl}
            />
          )}
        </div>
      </div>
    </>
  )
}
