import React, { useCallback } from 'react'
import { WarningItem } from '../warning-item'
import { ButtonItem } from './styles'
import { ListPickerProps } from './types'

export const ListPicker = ({
  formik,
  name,
  label,
  onChange,
  value,
  customErrorMessage,
  options = [],
}: ListPickerProps) => {
  let errorsList: string[] = []
  // formik
  if (formik && formik.errors !== undefined && formik.errors[name] !== undefined) {
    errorsList = errorsList.concat(formik.errors[name] as any)
  }
  const isValid = errorsList.length === 0
  const isTouched = formik?.touched[name] !== undefined
  // methods
  const onValuePick = useCallback(
    (key: string) => () => {
      onChange && onChange(key)
    },
    [onChange],
  )
  return (
    <label
      htmlFor={name}
      className={[
        'block text-sm font-bold',
        formik?.errors[name] && formik?.touched[name]
          ? 'text-warning-color'
          : 'text-secondary-color',
      ].join(' ')}
    >
      <span>{label}</span>
      <div>
        {options.map((item) => (
          <ButtonItem
            className={`${item.key === value ? '--selected' : ''}`}
            onClick={onValuePick(item.key)}
            type="button"
            key={item.key}
            value={item.label}
          >
            {item.label}
          </ButtonItem>
        ))}
        {customErrorMessage ||
          (!isValid && isTouched && (
            <ul>
              {errorsList.map((errorItem) => (
                <WarningItem key={errorItem}>errorItem</WarningItem>
              ))}
            </ul>
          ))}
      </div>
    </label>
  )
}
