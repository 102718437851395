import React from 'react'
import styled, { StyledComponent } from 'styled-components'

// styles
const ItemWrapper = styled.ul<{ radius?: number; background?: string }>`
  border-radius: ${(p) => p.radius || 8}px;
  border: 1px solid var(--primary-soft-color);
  list-style: none;
  max-width: 220px;
  display: grid;
  grid-column: 4;
  grid-row: 1;
  grid-template-columns: 1fr 1fr;
  /* flex-direction: row; */
  padding: 4px;
  height: 48px;
  background-color: ${(p) => p.background || 'none'};
`
const Item = styled.li<{
  selected: boolean
  radius?: number
}>`
  background-color: ${(p) => (p.selected ? 'var(--default-color)' : 'transparent')};
  color: ${(p) => (p.selected ? 'var(--on-default-color)' : 'var(--primary-color)')};
  text-align: center;
  border-radius: ${(p) => (p.radius ? p.radius - 2 : 6)}px;
  padding: 0 14px;
  order: 0;
  flex: 1;
  align-self: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  height: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  & path {
    fill: ${(p) => (p.selected ? 'var(--on-default-color)' : 'var(--primary-color)')};
  }
`

export type ToggleItemType = {
  value: string
  label: string | React.ReactNode | ((value: string) => React.ReactNode)
}
export type ToggleInputProps = {
  items?: ToggleItemType[]
  value?: string
  onChange?: (value: string) => void
  ItemStyle?: StyledComponent<
    'li',
    any,
    {
      selected: boolean
      radius?: number | undefined
    },
    never
  >
  background?: string
}

export const ToggleInput = ({
  items = [],
  value,
  onChange,
  ItemStyle,
  background,
}: ToggleInputProps) => (
  <>
    <ItemWrapper background={background}>
      {items.map((item) =>
        ItemStyle ? (
          <ItemStyle
            onClick={() => {
              onChange && onChange(item.value)
            }}
            selected={value === item.value}
            key={item.value}
          >
            {typeof item.label === 'function' ? item.label(item.value) : item.label}
          </ItemStyle>
        ) : (
          <Item
            onClick={() => {
              onChange && onChange(item.value)
            }}
            selected={value === item.value}
            key={item.value}
          >
            {typeof item.label === 'function' ? item.label(item.value) : item.label}
          </Item>
        ),
      )}
    </ItemWrapper>
  </>
)
