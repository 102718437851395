/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'
import { AuthContext, userHasRequiredRoles } from 'views/authentication'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { routes } from 'routes'
import { Portal } from 'views/map/pages/Portal'
import { AlertBanner } from 'views/alertBanner'
import { LandingPage } from 'views/landing-page'
import { AppHeader } from '..'
import { AppBodyContainer } from './App.styled'

export const App = () => {
  const { authUser } = useContext(AuthContext)
  const urlPath = window.location.pathname

  return (
    <>
      <AlertBanner />
      <Router>
        {urlPath === '/unsubscribe' ? <></> : <AppHeader />}
        <AppBodyContainer>
          <Switch>
            {Object.entries(routes).map(
              ([key, { component: Component, requiredRoles, ...rest }]) => (
                <Route
                  key={key}
                  {...rest}
                  render={(props) => {
                    if (authUser === undefined) return undefined
                    return userHasRequiredRoles(authUser, requiredRoles) ? (
                      <Component {...props} />
                    ) : (
                      <LandingPage />
                    )
                  }}
                />
              ),
            )}
          </Switch>
        </AppBodyContainer>
      </Router>
    </>
  )
}
