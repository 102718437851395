import { About } from './pages/About'
import { Privacy } from './pages/Privacy/Privacy'
import { paths } from './paths'

export const routes = {
  privacy: {
    path: paths.privacy(),
    exact: true,
    component: Privacy,
  },
  about: {
    path: paths.about(),
    exact: true,
    component: About,
  },
}
