import * as React from 'react'

function SvgArrowleftup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#arrow_left_up_svg__clip0)">
        <path
          d="M7.757 12l-1.414 1.414c-.68.68-1.833.103-1.697-.848l.884-6.187a1 1 0 01.849-.849l6.187-.884c.951-.136 1.528 1.018.848 1.697l-2.828 2.829a1 1 0 000 1.414l7.778 7.778"
          stroke={props.color || 'var(--default-icon-color, #000)'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="arrow_left_up_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgArrowleftup = React.memo(SvgArrowleftup)
export default MemoSvgArrowleftup
