import { useLocation } from 'react-router-dom'

export type StackPageType = {
  page: string
  pageKey: string
  id?: string
}
const getStackPages = (pathname: string, pages: string[]): StackPageType[] =>
  pathname
    .split('/')
    .filter((item) => item !== '' && item !== undefined)
    .map((piece, index, items) =>
      items.length > 1 &&
      pages.findIndex((page) => page.toLowerCase() === piece.toLowerCase()) === -1
        ? { page: items[index - 1], id: piece, pageKey: items[index - 1] + piece }
        : { page: piece, pageKey: `${piece}0` },
    )
    .filter((item, index, items) =>
      items[index + 1] ? item.page !== items[index + 1].page && item.page !== undefined : true,
    )

export const useStackRouter = (pages: string[]) => {
  const location = useLocation()
  const stackPages = getStackPages(location.pathname, pages)
  return { stackPages }
}
