/* eslint-disable */
import SVGtoPDF from 'svg-to-pdfkit'
import { Storage } from 'aws-amplify'

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export type AddSVGOptionsType = {
  width?: number
  height?: number
  preserveAspectRatio?: PreserveAspectRatioType
  useCSS?: boolean
  precision?: number
  assumePt?: boolean
}
export type PreserveAspectRatioType =
  | 'xMinYMin meet'
  | 'xMidYMin meet'
  | 'xMaxYMin meet'
  | 'xMinYMid meet'
  | 'xMidYMid meet'
  | 'xMaxYMid meet'
  | 'xMinYMax meet'
  | 'xMidYMax meet'
  | 'xMaxYMax meet'
  | 'xMinYMin slice'
  | 'xMidYMin slice'
  | 'xMaxYMin slice'
  | 'xMinYMid slice'
  | 'xMidYMid slice'
  | 'xMaxYMid slice'
  | 'xMinYMax slice'
  | 'xMidYMax slice'
  | 'xMaxYMax slice'

export module PdfHelper {
  export const addSVG = (
    doc: PDFKit.PDFDocument & any,
    svg: string,
    x: number,
    y: number,
    options: AddSVGOptionsType = {},
  ) => {
    SVGtoPDF(doc, svg, x, y, options)
  }

  export type CreateFontBuffersProps = { name: string; src: string; family: string }
  export type CreateFontBuffersResult = CreateFontBuffersProps & { buffer: Promise<Buffer> }
  const setAttributeRootTag = (xml: string, name: string, value: string | number) => {
    const removeAttribute = value === undefined || value === 'auto'
    const search = new RegExp(
      `(?<before>.*?<svg.*?)(?<name>\\b${name}\\b)="(?<value>.*?)"(?<after>.*?>.*)`,
      'gsmi',
    ).exec(xml)
    const g = search?.groups || ({} as { [name: string]: string })
    if (!g.name || !g.value) return xml
    return `${g.before || ''}${removeAttribute ? '' : `${name}="${value}"`}${g.after || ''}`
  }
  export const getImage = async (path?: string) => {
    const getPath = async () => {
      if (!path) return undefined
      if (path.startsWith('http') || path.includes(`/assets/`)) return path
      return Storage.get(path)
    }

    const isSVG = path?.toLowerCase().includes('.svg')
    const getContent = async () => {
      const p = await getPath()
      const storageKey = `image-${p}`
      const imageData = localStorage.getItem(storageKey)
      if (imageData !== null) return imageData
      if (!p) return undefined
      const response = await fetch(p)
      let r
      if (isSVG) {
        r = await response.text()
      } else {
        const buffer = Buffer.from(await response.arrayBuffer())
        r = `data:image/jpg;base64,${buffer.toString('base64')}`
      }
      return r
    }
    let content: string | undefined
    try {
      content = await getContent()
    } catch (error) {
      content = undefined
    }
    return (x = 0, y = 0, width: number | 'auto' = 'auto', height: number | 'auto' = 'auto') => {
      if (!content) return ''
      if (isSVG) {
        let r = content
        r = setAttributeRootTag(r, 'x', x)
        r = setAttributeRootTag(r, 'y', y)
        r = setAttributeRootTag(r, 'width', height)
        r = setAttributeRootTag(r, 'height', height)
        return r
      }
      return /* svg */ `<image
          preserveAspectRatio="xMidYMid slice"
          xlink:href="${content}"
          x="${x}" y="${y}"
          ${width ? `width="${width}"` : ''}
          ${height ? `height="${height}"` : ''} />`
    }
  }

  export const createFontBuffers = async (fonts: CreateFontBuffersProps[]) => {
    const promises = fonts.map((source) =>
      fetch(source.src)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => ({ ...source, buffer: Buffer.from(arrayBuffer) })),
    )
    return (await Promise.all(promises)).map((r) => r)
  }

  export const splitInColumns = <T>(items: T[], columns = 1) => {
    const rows = [[]] as T[][]
    const rowIndex = () => rows.length - 1
    const columnIndex = () => rows[rowIndex()].length - 1
    items.forEach((item) => {
      if (columnIndex() >= columns - 1) rows.push([])
      rows[rowIndex()].push(item)
    })
    return rows
  }

  export type ItemConfigType = {
    x: number
    y: number
    w: number
    h: number
    pageSize: number
    columns: number
  }
  export const pageList = <TItem, TItemOutput>(
    items: TItem[],
    config: Partial<ItemConfigType> = {
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      pageSize: 50,
      columns: 1,
    },
    itemRender: (item: TItem, x: number, y: number, index: number) => TItemOutput,
  ): TItemOutput[][] => {
    const c = {
      ...{
        x: 0,
        y: 0,
        w: 100,
        h: 100,
        pageSize: 50,
        columns: 1,
      },
      ...config,
    } as ItemConfigType
    let index = 0
    let pages: TItemOutput[][] = [[]]
    const pageIndex = () => pages.length - 1
    splitInColumns(items, c.columns).forEach((row, rowIndex) =>
      row.forEach((item, columnIndex) => {
        const cellData = {
          x: columnIndex * c.w + c.x,
          y: rowIndex * c.h + c.y,
        }
        pages[pageIndex()].push(itemRender(item, cellData.x, cellData.y, index))
        // Change Index
        index += 1
        if (index >= c.pageSize) {
          index = 0
          pages.push([])
        }
      }),
    )
    return pages
  }

}
