import * as React from 'react'

function SvgPicture(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M3 15V6a1 1 0 011-1h16a1 1 0 011 1v12a1 1 0 01-1 1H5.723a1 1 0 01-.868-1.496l2.39-4.183a1 1 0 011.668-.104l1.116 1.489a1 1 0 001.695-.153l2.382-4.764a1 1 0 011.788 0L18 14"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgPicture = React.memo(SvgPicture)
export default MemoSvgPicture
