import * as React from 'react'

function SvgRange(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path stroke="#807B7B" strokeLinecap="round" strokeDasharray="1 4" d="M8.5.5v174" />
      <circle cx={8} cy={88} r={8} fill="#018363" />
    </svg>
  )
}

const MemoSvgRange = React.memo(SvgRange)
export default MemoSvgRange
