import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'views/authentication'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { DashboardHeader } from 'views/dashboard/components/header'
import { Button, Subtitle } from 'views/dashboard/styles'
import { Trash, Write } from 'views/shared/components/icons'
import { ImageBox } from 'views/shared/components/image-box'
import { ModalLayer } from 'views/shared/components/modal-layer'
import {
  PrimaryButton,
  PrimaryNegativeButton,
  SecondaryButton,
  TextBody,
  WarningButton,
} from 'views/shared/components/ui-form'
import { useMyProfileStore } from '../../../../store/myProfile/myProfileStore'

export type MyProfileSection = 'details' | 'agent' | 'testimonials' | null
export const MyProfilePage = () => {
  const {
    detailsForm,
    agentForm,
    fetchMyProfile,
    saveDetails,
    restoreState,
    saveAgentDetails,
    testimonialForm,
    saveTestimonials,
    myProfileState,
    deleteTestimonial,
    editTestimonial,
  } = useMyProfileStore()
  const [isEditing, setIsEditing] = useState<MyProfileSection>(null)
  const isEditingDetails = isEditing === 'details'
  const isEditingAgent = isEditing === 'agent'
  const isEditingTestimonials = isEditing === 'testimonials'
  const { isLoggedIn, isAgent, profileImage, logoImage, reloadProfile } = useContext(AuthContext)
  const [isModalOpen, setIsModalOpen] = useState({ uploadHeadshot: false, uploadLogo: false })
  const [photo, setPhoto] = useState<string>('')
  const [logo, setLogo] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [testimonialIndex, setTestimonialIndex] = useState<number>()

  useEffect(() => {
    fetchMyProfile()
  }, [])
  useEffect(() => {
    setPhoto(profileImage)
  }, [profileImage])
  useEffect(() => {
    setLogo(logoImage)
  }, [logoImage])

  const toggleSection = (section: MyProfileSection) => {
    if (isEditing === section) {
      setIsEditing(null)
    } else {
      setIsEditing(section)
    }
  }
  const save = async () => {
    if (isEditing === 'details') {
      detailsForm.touch()
      await saveDetails()
      detailsForm.isValid() && setIsEditing(null)
    }
    if (isEditing === 'agent') {
      agentForm.touch()
      await saveAgentDetails()
      agentForm.isValid() && setIsEditing(null)
    }
    if (isEditing === 'testimonials') {
      testimonialForm.touch()
      if (testimonialForm.isValid()) {
        setIsEditing(null)
        await saveTestimonials()
      }
    }
    await reloadProfile()
  }
  const cancelEdit = () => {
    restoreState()
    toggleSection(null)
  }
  const closeDeleteModal = () => {
    setShowModal(false)
  }
  const openDeleteModal = (index: number) => {
    setTestimonialIndex(index)
    setShowModal(true)
  }
  const editTestimonialForm = (index: number) => {
    editTestimonial(index)
    setIsEditing('testimonials')
  }
  const removeTestimonial = (index: number) => {
    deleteTestimonial(index)
    closeDeleteModal()
  }

  return (
    <>
      <DashboardHeader title="My Profile" />
      <div className="flex flex-col lg:flex-row mb-64">
        <div className="w-full lg:w-1/2 p-5 mb-10 space-y-10 h-full">
          <div>
            <div className="flex items-center space-x-5 pb-3">
              <Subtitle>Account details</Subtitle>
              {!isEditingDetails && (
                <button onClick={() => toggleSection('details')} type="button">
                  <Write style={{ fontSize: '24px' }} color="var(--primary-color)" />
                </button>
              )}
            </div>
            <FieldsContainer>
              <li className="flex items-center pl-5 h-16">
                <p className="w-28 font-semibold text-secondary-color">Headshot</p>
                {!isEditingDetails && (
                  <div>
                    <img className="inline-block h-10 w-10  object-cover" src={photo} alt="" />
                  </div>
                )}
                {isEditingDetails && (
                  <div className="flex items-center space-x-5">
                    <img className="inline-block h-10 w-10 object-cover" src={photo} alt="" />
                    <div>
                      <Button
                        type="button"
                        className="text-primary-color font-semibold underline"
                        onClick={() => setIsModalOpen({ uploadHeadshot: true, uploadLogo: false })}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                )}
                <ImageBox
                  isOpen={isModalOpen.uploadHeadshot}
                  value={detailsForm.state.headshot}
                  onChange={(value, path) => {
                    detailsForm.state.headshot = value
                    setPhoto(path)
                    setIsModalOpen({ uploadHeadshot: false, uploadLogo: false })
                  }}
                  onClose={() => setIsModalOpen({ uploadHeadshot: false, uploadLogo: false })}
                />
              </li>
              <InputViewField
                type="readonly"
                edit={isEditingDetails}
                label="Email"
                stateValidation={detailsForm}
                field="email"
                placeholder="Enter email address"
              />
              <InputViewField
                stateValidation={detailsForm}
                field="firstName"
                edit={isEditingDetails}
                label="First Name"
                placeholder="Enter first name"
              />
              <InputViewField
                stateValidation={detailsForm}
                field="lastName"
                edit={isEditingDetails}
                label="Last Name"
                placeholder="Enter last name"
              />
              {isLoggedIn && isAgent && (
                <InputViewField
                  stateValidation={detailsForm}
                  field="phone"
                  edit={isEditingDetails}
                  mask="phone"
                  label="Phone"
                  placeholder="XXX-XXX-XXXX"
                />
              )}
            </FieldsContainer>
            {isEditingDetails && (
              <div className="flex space-x-3 my-5">
                <PrimaryButton onClick={save} type="button">
                  Save
                </PrimaryButton>
                <PrimaryNegativeButton onClick={() => cancelEdit()} type="button">
                  Cancel
                </PrimaryNegativeButton>
              </div>
            )}
          </div>
          {isLoggedIn && isAgent && (
            <div>
              <div className="flex items-center space-x-5 pb-3">
                <Subtitle>Agent details</Subtitle>
                {!isEditingDetails && (
                  <button onClick={() => toggleSection('agent')} type="button">
                    <Write style={{ fontSize: '24px' }} color="var(--primary-color)" />
                  </button>
                )}
              </div>
              <li className="flex items-center pl-5 h-16">
                <p className="w-28 font-semibold text-secondary-color">Logo</p>
                {!isEditingAgent && (
                  <div>
                    <img className="inline-block h-10 w-10 object-cover" src={logo} alt="" />
                  </div>
                )}
                {isEditingAgent && (
                  <div className="flex items-center space-x-5">
                    <img
                      className="inline-block h-10 w-10 object-cover"
                      object-fit="contain"
                      src={logo}
                      alt=""
                    />
                    <div>
                      <Button
                        type="button"
                        className="text-primary-color font-semibold underline"
                        onClick={() => setIsModalOpen({ uploadLogo: true, uploadHeadshot: false })}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                )}
                <ImageBox
                  isOpen={isModalOpen.uploadLogo}
                  value={agentForm.state.logo}
                  onChange={(value, path) => {
                    console.warn(`SSSet logo: ${value} === ${path}`)
                    agentForm.state.logo = value
                    setLogo(path)
                    setIsModalOpen({ uploadLogo: false, uploadHeadshot: false })
                  }}
                  onClose={() => setIsModalOpen({ uploadLogo: false, uploadHeadshot: false })}
                />
              </li>
              <FieldsContainer>
                <InputViewField
                  stateValidation={agentForm}
                  field="website"
                  edit={isEditingAgent}
                  label="Website"
                  placeholder="Enter website"
                />
                {/* Logo field here */}
                <InputViewField
                  stateValidation={agentForm}
                  field="jobDescription"
                  edit={isEditingAgent}
                  label="Title"
                  placeholder="Enter position"
                />
                <InputViewField
                  stateValidation={agentForm}
                  field="aboutMe"
                  type="textarea"
                  edit={isEditingAgent}
                  label="Bio"
                  placeholder="Enter full bio"
                />
                <InputViewField
                  stateValidation={agentForm}
                  field="instagram"
                  edit={isEditingAgent}
                  label="Instagram"
                  placeholder="Instagram profile name"
                />
                <InputViewField
                  stateValidation={agentForm}
                  field="linkedin"
                  edit={isEditingAgent}
                  label="LinkedIn"
                  placeholder="LinkedIn profile name"
                />
                <InputViewField
                  stateValidation={agentForm}
                  field="facebook"
                  edit={isEditingAgent}
                  label="Facebook"
                  placeholder="Facebook profile name"
                />
                <InputViewField
                  stateValidation={agentForm}
                  field="twitter"
                  edit={isEditingAgent}
                  label="Twitter"
                  placeholder="Twitter profile name"
                />
              </FieldsContainer>
              {isEditingAgent && (
                <div className="flex space-x-3 my-5">
                  <PrimaryButton onClick={save} type="button">
                    Save
                  </PrimaryButton>
                  <PrimaryNegativeButton onClick={() => cancelEdit()} type="button">
                    Cancel
                  </PrimaryNegativeButton>
                </div>
              )}
            </div>
          )}
        </div>
        {isLoggedIn && isAgent && (
          <div className="w-full lg:w-1/2 p-5 mb-10">
            <Subtitle className="pb-3">Testimonials</Subtitle>
            <FieldsContainer>
              {/* List of testimonials */}
              <li>
                <ul className="bg-white rounded-lg">
                  {(myProfileState?.testimonials || []).map((testimonial, index) => (
                    <li key={testimonial} className="flex justify-between items-start p-5">
                      <TextBody>{testimonial}</TextBody>
                      <div className="flex space-x-5 items-center pl-3">
                        <button
                          className="underline text-primary-color font-semibold"
                          type="button"
                          onClick={() => {
                            editTestimonialForm(index)
                          }}
                        >
                          <Write style={{ fontSize: '24px' }} color="var(--primary-color)" />
                        </button>
                        <button
                          className="underline text-warning-color font-semibold"
                          type="button"
                          onClick={() => openDeleteModal(index)}
                        >
                          <Trash style={{ fontSize: '24px' }} color="var(--warning-color)" />
                        </button>

                        <ModalLayer
                          onOutsideClick={closeDeleteModal}
                          show={showModal}
                          responsive={{
                            small: 'full',
                          }}
                          outsideColor="rgba(0, 0, 0, 0.5)"
                        >
                          <div className="max-w-sm bg-white rounded-lg">
                            <button
                              className="text-xl font-semibold text-primary-color text-right w-full pr-2"
                              type="button"
                              onClick={closeDeleteModal}
                            >
                              x
                            </button>
                            <div className="px-5 pt-0 pb-5">
                              <TextBody className="pb-5">
                                Are you sure you want to permanently remove this testimonial?
                              </TextBody>
                              <WarningButton
                                onClick={() => removeTestimonial(testimonialIndex || 0)}
                                className="bg-white"
                              >
                                Delete
                              </WarningButton>
                            </div>
                          </div>
                        </ModalLayer>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            </FieldsContainer>
            {/* Add testimonial */}
            <div>
              {isEditingTestimonials && (
                <ul>
                  <InputViewField
                    stateValidation={testimonialForm}
                    field="testimonial"
                    edit
                    type="textarea"
                    placeholder="Add testimonial"
                  />
                </ul>
              )}
              {isEditingTestimonials && (
                <>
                  <div className="flex space-x-5 items-center justify-end mb-5">
                    <button
                      className="underline text-primary-color font-semibold"
                      onClick={save}
                      type="button"
                    >
                      save
                    </button>
                    <button
                      className="underline text-warning-color font-semibold"
                      onClick={() => cancelEdit()}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
            {!isEditingTestimonials && (
              <div className="max-w-xs mx-auto">
                <SecondaryButton
                  onClick={() => setIsEditing('testimonials')}
                  className="mt-5"
                  type="button"
                >
                  Add Testimonial
                </SecondaryButton>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
