import React from 'react'
import ImageViewer from 'react-simple-image-viewer'
import { ModalLayer } from '../modal-layer'

export interface FullImageViewerProps {
  isViewerOpen: boolean
  setIsViewerOpen: (isOpen: boolean) => void
  currentImage: number | null
  setCurrentImage: (index: number) => void
  images?: string[]
}

export const FullImageViewer = ({
  isViewerOpen,
  setIsViewerOpen,
  currentImage,
  setCurrentImage,
  images,
}: FullImageViewerProps) => {
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <>
      {images && (
        <ModalLayer
          onOutsideClick={() => setIsViewerOpen(false)}
          show={isViewerOpen}
          responsive={{
            small: 'center',
          }}
          outsideColor="rgba(0, 0, 0, 0.5)"
        >
          {currentImage && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
              disableScroll
              onClose={closeImageViewer}
              closeOnClickOutside
            />
          )}
          {currentImage === 0 && (
            <ImageViewer
              src={images}
              backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
              disableScroll
              onClose={closeImageViewer}
              closeOnClickOutside
            />
          )}
        </ModalLayer>
      )}
    </>
  )
}
