import { atom, useAtom } from 'jotai'
import { UserServices } from 'services/user'
import { UserDataDetails } from 'types'
import {
  isCAPhone,
  isDateMMddYYYY,
  isFutureDate,
  isUrl,
  maxLength,
  minLenth,
  required,
  stateValidationStore,
  useStateValidation,
} from 'utilities/hooks/validationState'
import { UPLOAD_FOLDER } from 'views/shared/components/image-box'

const initial = {
  details: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    headshot: undefined as string | undefined,
  },
  agent: {
    website: '',
    jobDescription: '',
    aboutMe: '',
    instagram: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    logo: undefined as string | undefined,
  },
  client: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    headshot: undefined as string | undefined,
    address: '',
    dob: '',
  },
  testimonial: {
    testimonial: '',
    index: -1,
  },
}
const store = {
  details: stateValidationStore(initial.details),
  agent: stateValidationStore(initial.agent),
  client: stateValidationStore(initial.client),
  testimonial: stateValidationStore(initial.testimonial),
}
const myProfile = atom<Partial<UserDataDetails> | null>(null)
const loading = atom<boolean>(false)

export const useMyProfileStore = () => {
  // states
  const [myProfileState, setMyProfileState] = useAtom(myProfile)
  const [isLoading, setIsLoading] = useAtom(loading)
  // formStates
  const detailsForm = useStateValidation({
    store: store.details,
    validation: {
      firstName: [
        required('First name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      lastName: [
        required('Last name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      phone: [
        required('Phone is required'),
        isCAPhone('Please, enter a valid canadian phone number'),
      ],
      headshot: [],
    },
  })
  const agentForm = useStateValidation({
    store: store.agent,
    validation: {
      website: [isUrl('Please, enter a valid url')],
      jobDescription: [minLenth(2, 'Title must have at least 2 characters')],
      aboutMe: [
        minLenth(20, 'Title must have at least 20 characters'),
        maxLength(1000, 'Bio must have at most 1000 characters'),
      ],
      instagram: [isUrl('Please, enter a valid url')],
      linkedin: [isUrl('Please, enter a valid url')],
      facebook: [isUrl('Please, enter a valid url')],
      twitter: [isUrl('Please, enter a valid url')],
    },
  })
  const clientForm = useStateValidation({
    store: store.client,
    validation: {
      address: [minLenth(6, 'Address must have at least 6 characters')],
      firstName: [
        required('First name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      lastName: [
        required('Last name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],

      dob: [isDateMMddYYYY('Date is invalid'), isFutureDate('Date cannot be in the future')],
      phone: [isCAPhone('Please, enter a valid canadian phone number')],
    },
  })
  const testimonialForm = useStateValidation({
    store: store.testimonial,
    validation: {
      testimonial: [
        required('Testimonial is required'),
        minLenth(10, 'Testimonial must have at least 10 characters'),
      ],
    },
  })

  // actions
  const restoreState = async (data: Partial<UserDataDetails> | null = myProfileState) => {
    if (!data) return
    detailsForm.setFieldsData({
      email: data.email || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      phone: data.phone || '',
      headshot: data.headshot,
    })
    agentForm.setFieldsData({
      website: data.website || '',
      jobDescription: data.jobDescription || '',
      aboutMe: data.aboutMe || '',
      instagram: data.socialMedias?.instagram || '',
      linkedin: data.socialMedias?.linkedin || '',
      facebook: data.socialMedias?.facebook || '',
      twitter: data.socialMedias?.twitter || '',
      logo: data.logo,
    })
    clientForm.setFieldsData({
      email: data.email || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      phone: data.phone || '',
      headshot: data.headshot,
      address: data.address || '',
      dob: data.dob || '',
    })
    testimonialForm.setFieldsData({
      testimonial: '',
      index: -1,
    })
  }
  const fetchMyProfile = async () => {
    if (myProfileState) return
    setIsLoading(true)
    await UserServices.getMyProfile()
      .then((response) => {
        restoreState(response)
        setMyProfileState(response as any)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }
  const update = async (newProfileState: Partial<UserDataDetails>) => {
    setIsLoading(false)
    await UserServices.updateMyProfile(newProfileState as UserDataDetails)
      .then(() => {
        setMyProfileState(newProfileState)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }
  const saveDetails = async () => {
    if (!detailsForm.isValid()) return
    const newProfileState = {
      ...(myProfileState || {}),
      firstName: detailsForm.state.firstName,
      lastName: detailsForm.state.lastName,
      phone: detailsForm.state.phone,
      headshot: detailsForm.state.headshot?.startsWith(UPLOAD_FOLDER)
        ? detailsForm.state.headshot.substring(UPLOAD_FOLDER.length)
        : detailsForm.state.headshot,
    }
    await update(newProfileState)
  }
  const saveAgentDetails = async () => {
    if (!agentForm.isValid()) return
    const newProfileState: Partial<UserDataDetails> = {
      ...(myProfileState || {}),
      website: agentForm.state.website,
      jobDescription: agentForm.state.jobDescription,
      aboutMe: agentForm.state.aboutMe,
      socialMedias: {
        instagram: agentForm.state.instagram,
        linkedin: agentForm.state.linkedin,
        facebook: agentForm.state.facebook,
        twitter: agentForm.state.twitter,
      },
      logo: agentForm.state.logo?.startsWith(UPLOAD_FOLDER)
        ? agentForm.state.logo.substring(UPLOAD_FOLDER.length)
        : agentForm.state.logo,
    }
    await update(newProfileState)
  }
  const saveClientDetails = async () => {
    if (!clientForm.isValid()) return
    const newProfileState: Partial<UserDataDetails> = {
      ...(myProfileState || {}),
      firstName: clientForm.state.firstName,
      lastName: clientForm.state.lastName,
      phone: clientForm.state.phone,
      address: clientForm.state.address,
      dob: clientForm.state.dob,
    }
    await update(newProfileState)
  }
  const saveTestimonials = async () => {
    const { index } = testimonialForm.state
    if (!testimonialForm.isValid()) return
    const newTestimonials = [...(myProfileState?.testimonials || [])]
    if (index > -1) {
      newTestimonials[index] = testimonialForm.state.testimonial
    } else {
      newTestimonials.push(testimonialForm.state.testimonial)
    }
    const newProfileState: Partial<UserDataDetails> = {
      ...(myProfileState || {}),
      testimonials: newTestimonials,
    }
    testimonialForm.setFieldsData({
      testimonial: '',
      index: -1,
    })
    await update(newProfileState)
  }
  const deleteTestimonial = async (index: number) => {
    const newTestimonials = [...(myProfileState?.testimonials || [])]
    newTestimonials.splice(index, 1)
    const newProfileState: Partial<UserDataDetails> = {
      ...(myProfileState || {}),
      testimonials: newTestimonials,
    }
    await update(newProfileState)
  }
  const editTestimonial = (index: number) => {
    if (!myProfileState?.testimonials) return
    const editedTestimonial = myProfileState.testimonials[index]
    testimonialForm.setFieldsData(
      {
        testimonial: editedTestimonial,
        index,
      },
      true,
    )
  }

  return {
    myProfileState,
    detailsForm,
    agentForm,
    fetchMyProfile,
    saveDetails,
    saveAgentDetails,
    saveClientDetails,
    saveTestimonials,
    testimonialForm,
    restoreState,
    deleteTestimonial,
    editTestimonial,
    isLoading,
  }
}
