import React from 'react'

import { Star } from 'views/shared/components/icons'
import Chart from 'views/shared/components/icons/Chart'

import { Neighbourhood } from 'types'
import { useFollows } from 'views/follows/hooks'

interface NeighbourhoodListSectionProps {
  list: Neighbourhood[]
  onClick?: (neighbourhood: Neighbourhood) => void
}

export const NeighbourhoodListSection = ({
  list,
  onClick = (neighbourhood: Neighbourhood) => console.warn(neighbourhood),
}: NeighbourhoodListSectionProps) => {
  const { isNeighbourhoodFollowed } = useFollows()

  return (
    <div className="md:mr-8">
      {list.map((neighbourhood, index) => (
        <>
          <button
            type="button"
            className={[
              index % 2 === 0 ? 'bg-alpha-color' : '',
              'flex items-center justify-between text-left py-2.5 px-6 rounded-lg text-xl font-semibold w-full text-primary-color',
            ].join(' ')}
            key={neighbourhood.id}
            tabIndex={index}
            onClick={() => onClick(neighbourhood)}
          >
            <div className="flex">
              {isNeighbourhoodFollowed(neighbourhood.id) && (
                <span className="mr-3">
                  <Star
                    style={{ fontSize: '24px' }}
                    fill="var(--primary-color)"
                    color="var(--primary-color)"
                  />
                </span>
              )}
              <p>{neighbourhood.name}</p>
            </div>

            <div className="ml-auto">
              <Chart
                style={{ fontSize: '24px', fontWeight: 'lighter' }}
                fill="var(--primary-color)"
                color="var(--primary-color)"
              />
            </div>
          </button>
        </>
      ))}
    </div>
  )
}
