import React, { useContext } from 'react'
import { AuthContext, paths } from 'views/authentication'
import { A, Subtitle } from 'views/shared/components/ui-form'

export type AgentDetailsProps = React.PropsWithChildren<{
  name?: string
  phone?: string
  email?: string
}>
export const UserAgentDetails = ({ name, phone, email }: AgentDetailsProps) => {
  const { agentLogo, agentHeadshot } = useContext(AuthContext)

  return (
    <>
      <div>
        <div className="mb-5">
          <Subtitle className="hidden sm:block">Your Agent Details</Subtitle>
        </div>
        <div className="sm:flex sm:items-center w-full mb-5">
          <div className="mr-5 flex space-x-1 mb-5 sm:mb-0">
            {agentLogo && <img className="filter h-24 w-24 grayscale" src={agentLogo} alt="" />}
            {!agentLogo && (
              <img className="filter h-24 w-24 grayscale" src="../assets/castl.svg" alt="" />
            )}
            {agentHeadshot && (
              <div className="h-24 w-30 ">
                <img className="filter h-24 w-30 grayscale" src={agentHeadshot} alt="" />
              </div>
            )}
          </div>
          <div className="space-y-1">
            <p className="capitalize font-semibold text-secondary-color text-xl">{name}</p>
            <p>
              <A href={`tel:+1${phone}`}>{phone}</A>
            </p>
            <p className="overflow-hidden overflow-ellipsis">
              <A href={`mailto:${email}`}>{email}</A>
            </p>
          </div>
        </div>
        <div>
          <A className="underline text-center block" href={paths.agentDetails()}>
            More about <br />
            {name}
          </A>
        </div>
      </div>
    </>
  )
}
