import styled from 'styled-components'

// styles
export const Subtitle = styled.h3<{
  fontWeight?: number
}>`
  font-weight: ${(p) => p.fontWeight || 600};
  font-size: 20px;
  line-height: 150%;
  color: var(--accent-color);
`
export const StatsNumber = styled.p<{
  color?: string
}>`
  font-weight: 600;
  font-size: 42px;
  line-height: 51px;
  color: ${(p) => p.color || 'var(--accent-color)'};
`
export const StatsTitle = styled.p<{
  fontWeight?: number
}>`
  font-style: normal;
  font-weight: ${(p) => p.fontWeight || 400};
  font-size: 16px;
  line-height: 150%;
  color: var(--secondary-color);
`
export const StatsSubtitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: var(--default-color);
`
export const SVGChart = styled.svg`
  transform: rotate(-90deg);
  background: var(--primary-soft-color);
  border-radius: 50%;
  display: block;
  & circle {
    fill: none;
    stroke: var(--primary-soft-color);
    stroke-width: 50;
    stroke-dasharray: 0 158;
    transition: stroke-dasharray 0.3s ease;
  }
`
export const Button = styled.button<{
  selected: boolean
}>`
  border: ${(p) => (p.selected ? '1px solid var(--accent-color)' : '1px solid transparent')};
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &.--selected {
    color: var(--on-primary-color);
    border-color: transparent;
  }
  &.--disabled {
    background-color: transparent;
    color: var(--primary-color);
    opacity: 0.5;
    border: 0;
    cursor: default;
  }
`
