import styled from 'styled-components'

export const FilterContainer = styled.div`
  display: block;
  position: absolute;
  right: 0;
  z-index: 2;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  height: calc(100% - 190px);
  overflow: hidden;
  overflow-y: scroll;
  @media screen and (min-width: 600px) {
    max-width: 320px;
    width: 100%;
    padding: 32px;
  }
`

export const PriceInput = styled.input`
  height: 100%;
  border-width: 1px;
  border-color: var(--primary-soft-color);
  border-radius: 6px;
  padding: 0 0.5rem;
  font-style: italic;
`
export const CloseFilters = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  background-color: #ffffff;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 48px;
  padding: 13px;
  height: 48px;
  border-radius: 8px;
`
export const CapsuleButton = styled.button<{
  color: string
  roundDirection: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  line-height: 125%;
  cursor: pointer;
  text-align: left;
  color: #ffffff;
  background-color: ${(p) => p.color};
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: ${(p) => (p.roundDirection === 'left' ? '8px 0px 0px 8px' : '0px 8px 8px 0px')};
  font-size: 15px;
  padding: 0 5px;
  &:focus {
    outline: none !important;
    border-color: var(--primary-color);
    box-shadow: none;
  }
`
export const SoldButton = styled.button`
  width: 100%;
  padding: 6px 10px 6px 10px;
  height: 48px;
  line-height: 125%;
  background-color: #000000;
  cursor: pointer;
  text-align: left;
  color: #ffffff;
  border: 1px solid var(--primary-soft-color);
  box-sizing: border-box;
  border-radius: 0px 8px 8px 0px;
  font-size: 16px;
  &:focus {
    outline: none !important;
    border-color: var(--primary-color);
    box-shadow: none;
  }
`
