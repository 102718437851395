import React, { ReactElement } from 'react'
import { PropertyDetails } from 'types/Property'
import { CurrencyHelper, TIME_ZONE_NAME } from 'utilities'
import moment from 'moment-timezone'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import { MetricHelper } from 'utilities/helpers/metricHelper'
import { PropertyEventLabel } from '../listing-history'

interface PropertyDetailsProps {
  property: PropertyDetails | null
}

const chunk = (arr: any[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  )

const titleCase = (str: string): string => {
  const sentence = str.toLowerCase()
  const titleCaseSentence =
    sentence.charAt(0).toUpperCase() + sentence.substring(1, sentence.length)
  return titleCaseSentence
}

const list = (strings: string[]): ReactElement => (
  <>
    {strings.map((str) => (
      <p key="{str}">{str}</p>
    ))}
  </>
)

export const PropertyDetailsInfo = ({ property }: PropertyDetailsProps): ReactElement => {
  const keyValues = []

  keyValues.push([
    'Bedroom',
    `${property?.summary.bedroom}${property?.summary.brPlus ? `+${property.summary.brPlus}` : ''}`,
  ])

  keyValues.push(['Bathroom', property?.summary.bathroom])

  keyValues.push([
    'Status',
    PropertyEventLabel.find((event) => event.key === property?.summary.status)?.label,
  ])

  keyValues.push([
    'Property type',
    [property?.summary?.type, property?.style].filter((value) => value).join(', '),
  ])

  if (property?.summary?.landFront && property?.summary?.landDepth) {
    keyValues.push([
      'Lot size',
      `${property?.summary?.landFront} x ${property?.summary?.landDepth} ft`,
    ])
  }

  if (property?.summary?.footage) {
    keyValues.push(['Interior size', `${property.summary.footage} sqft`])
  }

  if (property?.maintenance) {
    keyValues.push(['Maintenance', `$${CurrencyHelper.currencyFormat(property.maintenance)}`])
  }

  if (property?.summary?.tax) {
    keyValues.push([
      'Taxes',
      `$${CurrencyHelper.currencyFormat(property.summary.tax)} / ${
        property?.summary?.taxYear || 'y'
      }`,
    ])
  } else {
    keyValues.push(['Taxes', '-'])
  }

  if (property?.summary?.status === 'forSale' || property?.summary?.status === 'forLease') {
    // active listing
    keyValues.push([
      'Days on Market',
      `${moment.tz(TIME_ZONE_NAME).startOf('day').diff(moment(property.summary.listDate), 'days')}`,
    ])
  } else if (property?.summary?.dom !== undefined) {
    keyValues.push(['Days on Market', `${property.summary.dom}`])
  }

  if (property?.basement) {
    keyValues.push(['Basement', property?.basement])
  }

  if (property?.exposure) {
    keyValues.push([
      'Exposure',
      titleCase(
        property.exposure
          .toUpperCase()
          .replace('N', 'north')
          .replace('E', 'east')
          .replace('S', 'south')
          .replace('W', 'west'),
      ),
    ])
  }

  if (property?.includedutility && property.includedutility.length > 0) {
    keyValues.push(['Included Utility', property.includedutility.join(', ')])
  }

  if (property?.buildingAge) {
    keyValues.push(['Building Age', property.buildingAge])
  }

  if (property?.heating) {
    const fuel = property.fuel ? `-${property.fuel}` : ''
    keyValues.push(['Heating', `${property.heating}${fuel}`])
  }

  if (property?.cooling) {
    keyValues.push(['Cooling', property.cooling])
  }

  if (property?.exteriorFinish) {
    keyValues.push(['Construction', property.exteriorFinish])
  }

  if (property?.poolType) {
    keyValues.push(['Pool', property.poolType])
  }

  if (property?.locker) {
    keyValues.push(['Locker', property.locker])
  }

  if (property?.pets) {
    keyValues.push(['Pets', property.pets])
  }

  if (property?.summary?.parking || property?.summary?.totalParking) {
    const parkingKeyValues: any[] = []
    if (property?.driveWay) {
      parkingKeyValues.push(['Driveway', property.driveWay])
    }
    if (property?.garageType) {
      parkingKeyValues.push(['Type', property.garageType])
    }
    if (property?.garageSpace) {
      parkingKeyValues.push(['Garage space', property.garageSpace])
    }

    if (property?.summary?.parking) {
      parkingKeyValues.push(['Parking', property.summary.parking])
    }

    if (property?.summary?.totalParking) {
      parkingKeyValues.push(['Total space', property.summary.totalParking])
    }

    keyValues.push(['Parking', parkingKeyValues.map((kv) => `${kv[0]}: ${kv[1]}`)])
  }
  if (property?.amenities && property.amenities.length > 0) {
    keyValues.push(['Amenities', property.amenities])
  }

  if (property?.features && property.features.length > 0) {
    keyValues.push(['Features', property.features])
  }

  const rows = chunk(keyValues, 4)

  return (
    <>
      <div className="lg:pt-10">
        {rows.map((row, index) => (
          <div
            className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 gap-x-5"
            key={`r${index + 1}`}
          >
            {row.map((keyValuePair) => (
              <div className="py-3" key={keyValuePair[0]}>
                <div>
                  <div className="font-semibold text-secondary-color text-sm">
                    {keyValuePair[0]}
                  </div>
                  <div className="font-normal text-secondary-color text-base">
                    {Array.isArray(keyValuePair[1]) ? list(keyValuePair[1]) : keyValuePair[1]}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="font-normal text-secondary-color text-base py-4">
        {property?.description && UnescapeHelper.unescape(property.description)}
      </div>
      {property?.rooms.map((room) => (
        <div key={room.name} className="font-semibold text-secondary-color text-base py-2">
          {room.name}
          <span className="inline font-normal text-default-color text-base px-3 md:px-2">
            {MetricHelper.meterToFeet(room.length)} x {MetricHelper.meterToFeet(room.width)} ft
          </span>
          <div className="font-normal text-secondary-color text-sm">
            {room?.description && UnescapeHelper.unescape(room.description)}
          </div>
        </div>
      ))}
      <div className="font-normal text-default-color text-sm py-4">
        Listed by{' '}
        <span className="font-normal text-secondary-color text-sm">
          {property?.listedBy && UnescapeHelper.unescape(property.listedBy)}
        </span>
      </div>
    </>
  )
}
