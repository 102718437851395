import { PreferredContactMethod } from 'constants/UserConstants'
import { atom, useAtom } from 'jotai'
import { UserServices } from 'services/user'
import { UserDataDetails } from 'types'
import { useSearchParams } from 'utilities/hooks/useSearchParams'
import {
  isCAPhone,
  isDateMMddYYYY,
  isFutureDate,
  isPassword,
  minLenth,
  required,
  stateValidationStore,
  useStateValidation,
} from 'utilities/hooks/validationState'
import { UPLOAD_FOLDER } from 'views/shared/components/image-box'

const initial = {
  details: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    headshot: undefined as string | undefined,
    address: '',
    dob: '',
    ownershipStats: '',
    purchaseDate: '',
    preferredContactMethod: PreferredContactMethod.EMAIL as PreferredContactMethod | string,
    receiveEmail: true,
    receiveSoldReport: true,
  },
  passwordDetails: {
    password: '',
    newPassword: '',
  },
}
const store = {
  details: stateValidationStore(initial.details),
  passwordDetails: stateValidationStore(initial.passwordDetails),
}
const clientAccountProfile = atom<Partial<UserDataDetails> | null>(null)
const loading = atom<boolean>(false)

export const useClientAccountProfileStore = () => {
  // states
  const [clientAccountState, setClientAccountProfileState] = useAtom(clientAccountProfile)
  const [isLoading, setIsLoading] = useAtom(loading)
  // forms
  const detailsForm = useStateValidation({
    store: store.details,
    validation: {
      firstName: [
        required('First name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      lastName: [
        required('Last name is required'),
        minLenth(2, 'Name must have at least 2 characters'),
      ],
      phone: [isCAPhone('Please, enter a valid canadian phone number')],
      address: [minLenth(6, 'Address must have at least 6 characters')],
      dob: [
        isDateMMddYYYY('Please, enter a valid date'),
        isFutureDate('Date cannot be in the future'),
      ],
      purchaseDate: [
        isDateMMddYYYY('Please, enter a valid date'),
        isFutureDate('Date cannot be in the future'),
      ],
    },
  })

  const passwordDetails = useStateValidation({
    store: store.passwordDetails,
    validation: {
      password: [required('Password is required')],
      newPassword: [
        required('New password is required'),
        isPassword(
          'Must have a minimum of 8 characters and  contain at least one number, one uppercase and lowercase letter and one special character, please do not use any of this characters ~ ` \' " or whitespace.',
        ),
      ],
    },
  })

  // actions
  const restoreState = async (data: Partial<UserDataDetails> | null = clientAccountState) => {
    if (!data) return

    detailsForm.setFieldsData(
      {
        email: data.email || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        phone: data.phone || '',
        headshot: data.headshot,
        address: data.address || '',
        dob: data.dob || '',
        ownershipStats: data.ownershipStats || '',
        purchaseDate: data.purchaseDate || '',
        preferredContactMethod: data.preferredContactMethod || PreferredContactMethod.EMAIL,
        receiveEmail: data.receiveEmail === undefined ? true : data.receiveEmail,
        receiveSoldReport: data.receiveSoldReport === undefined ? true : data.receiveSoldReport,
      },
      true,
    )
  }
  const fetchMyProfile = async () => {
    if (clientAccountState) return
    setIsLoading(true)
    await UserServices.getMyProfile()
      .then((response) => {
        restoreState(response)
        setClientAccountProfileState(response)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }
  const update = async (newClientAccountState: Partial<UserDataDetails>) => {
    setIsLoading(true)
    await UserServices.updateMyProfile(newClientAccountState as UserDataDetails)
      .then(() => {
        setClientAccountProfileState(newClientAccountState)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }
  const saveDetails = async () => {
    if (!detailsForm.isValid()) return
    const newClientAccountState = {
      ...(clientAccountState || {}),
      firstName: detailsForm.state.firstName,
      lastName: detailsForm.state.lastName,
      phone: detailsForm.state.phone,
      headshot: detailsForm.state.headshot?.startsWith(UPLOAD_FOLDER)
        ? detailsForm.state.headshot.substring(UPLOAD_FOLDER.length)
        : detailsForm.state.headshot,
      address: detailsForm.state.address,
      dob: detailsForm.state.dob,
      ownershipStats: detailsForm.state.ownershipStats,
      purchaseDate: detailsForm.state.purchaseDate,
      preferredContactMethod: detailsForm.state.preferredContactMethod,
      receiveSoldReport: detailsForm.state.receiveSoldReport,
      receiveEmail: detailsForm.state.receiveEmail,
    }
    await update(newClientAccountState)
  }

  const searchParams = useSearchParams()

  const receiveSoldReportHandler = async (checked: boolean) => {
    const uid = searchParams.get('uid')
    await UserServices.updateSubscriptions(checked, uid || undefined)
    detailsForm.setField('receiveSoldReport', checked, true)
    saveDetails()
  }

  return {
    clientAccountState,
    detailsForm,
    fetchMyProfile,
    saveDetails,
    restoreState,
    isLoading,
    passwordDetails,
    receiveSoldReportHandler,
  }
}
