import React, { useEffect, useState } from 'react'
import { PropertyListing } from 'types/Property'
import { Storage } from 'aws-amplify'
import { PropertyServices } from 'services/property'
import { MultiCarousel } from 'views/propertyDetails/pages/similar-homes'
import { Config } from 'config'

export type SimilarSoldProps = {
  property?: PropertyListing
  similarProperties?: PropertyListing[] | null
}

export function SimilarSold({ property, similarProperties }: SimilarSoldProps) {
  const [imageBaseUrl, setImageBaseUrl] = useState<string>('')
  const [propertyImages, setPropertyImages] = useState<string[]>([])

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public` as string)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (property && imageBaseUrl) {
      setPropertyImages(PropertyServices.getPropertyImageURLs(property, imageBaseUrl))
    }
  }, [property, imageBaseUrl])

  return (
    <>
      <h1 className="my-5 md:my-10 mx-5 md:mx-20 text-2xl md:text-4xl font-semibold">
        Similar Sold Properties
      </h1>
      <div>
        {imageBaseUrl && (
          <MultiCarousel
            properties={similarProperties}
            imageBaseUrl={imageBaseUrl}
            parentPage="SimilarSold"
          />
        )}
      </div>
    </>
  )
}
