import styled from 'styled-components'

export const HomeValuationsContainer = styled.div<{
  height?: number
}>`
  height: 100%;
  @media screen and (min-width: 1024px) {
    height: calc(${(p) => p.height || 100}vh - 56px);
    background-image: url('/assets/living-room.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`

export const HomeValuationStep4Container = styled.div`
  height: 100%;
  @media screen and (min-width: 1024px) {
    width: 80vw;
    height: calc(100vh - 56px);
    margin: auto;
  }
`

export const ChartContainer = styled.div`
  margin: auto;
  height: auto;
  width: 60vw;
  @media screen and (min-height: 1100px) {
    margin: auto;
    height: 22vh;
  }
`

export const ModalContainer = styled.div`
  @media screen and (min-width: 1024px) {
    overflow-y: auto;
  }
`
