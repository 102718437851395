import { selector } from 'recoil'
import { NeighbourhoodServices } from '../../services/neighbourhood'

export const neighbourhoodsState = selector({
  key: 'map.neighbourhoods',
  get: async () => NeighbourhoodServices.getNeighbourhoods(),
})

export const communitiesState = selector({
  key: 'map.communities',
  get: ({ get }) =>
    get(neighbourhoodsState)
      .reduce(
        (unique, { community }) => (unique.includes(community) ? unique : [...unique, community]),
        [] as string[],
      )
      .sort((a, b) => (a > b ? 1 : -1)),
})
