import {
  SignInGuided,
  SignUp,
  SignUpVerification,
  Profile,
  ResetPassword,
  ResetPasswordVerification,
  SignUpWaitlist,
  SignUpWaitlistValidation,
} from './pages'
import { AgentDetailsPage } from './pages/AgentDetails'
import { SignInNewPassword } from './pages/SignIn/SignInNewPassword'
import { paths } from './paths'

export const routes = {
  signIn: {
    path: paths.signIn(),
    exact: true,
    component: SignInGuided,
  },
  signInNewPassword: {
    path: paths.signInNewPassword(),
    exact: true,
    component: SignInNewPassword,
  },
  signUp: {
    path: paths.signUp(),
    exact: true,
    component: SignUp,
  },
  signUpVerification: {
    path: paths.signUpVerification(),
    exact: true,
    component: SignUpVerification,
  },
  resetPassword: {
    path: paths.resetPassword(),
    exact: true,
    component: ResetPassword,
  },
  resetPasswordVerification: {
    path: paths.resetPasswordVerification(),
    exact: true,
    component: ResetPasswordVerification,
  },
  profile: {
    path: paths.profile(),
    exact: true,
    component: Profile,
  },
  agentDetails: {
    path: paths.agentDetails(),
    exact: true,
    component: AgentDetailsPage,
  },
  signUpWaitlist: {
    path: paths.signUpWaitlist(),
    exact: true,
    component: SignUpWaitlist,
  },
  signUpWaitlistValidation: {
    path: paths.signUpWaitlistValidation(),
    exact: true,
    component: SignUpWaitlistValidation,
  },
}
