import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { HomeValuationServices } from 'services/homeValuation/HomeValuationServices'
import { useValuationsStore } from 'store/dashboard/valuations'
import { useHomeValuationStore } from 'store/homeValuation/HomeValuationStore'
import { HomeValueEstimation, ValuationConfirmationForm } from 'types'
import { AlertInfo, alertsState } from 'views/alertBanner/state'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { SecondaryButton, UnderlinedPrimaryButton } from 'views/shared/components/ui-form'

interface UpdateValuationProps {
  valuation: HomeValueEstimation
}

export const UpdateValuation = ({ valuation }: UpdateValuationProps) => {
  const [isExtended, setIsExtended] = useState<boolean>(false)
  const [updateForm, setUpdateForm] = useState<ValuationConfirmationForm>()
  const {
    valuationConfirmationState,
    updateValuationConfirmation,
    resetValuationConfirmation,
    setEstimationState,
  } = useHomeValuationStore()
  const { fetchValuations } = useValuationsStore()
  const history = useHistory()
  const isModal = () => window.location.pathname.includes('/property-estimation')
  const alerts = useRecoilValue(alertsState)
  const setAlerts = useSetRecoilState(alertsState)

  const handleUpdate = () => {
    valuation?.id &&
      HomeValuationServices.confirmHomeValuation(valuation.id, valuationConfirmationState).then(
        (res) => {
          try {
            setEstimationState(res)
            fetchValuations()
            resetValuationConfirmation()
            if (isModal()) {
              setAlerts([
                ...alerts,
                {
                  id: alerts.length.toString(),
                  type: 'success',
                  message: 'Valuation successfully updated.',
                } as AlertInfo,
              ])
            } else history.replace('/dashboard/valuations')
          } catch (error) {
            isModal() &&
              setAlerts([
                ...alerts,
                {
                  id: alerts.length.toString(),
                  type: 'error',
                  message: 'Valuation update failed.',
                } as AlertInfo,
              ])
            console.log(error)
          }
        },
      )
  }

  useEffect(() => {
    console.log(updateForm)
  }, [updateForm])

  return (
    <>
      {!isExtended ? (
        <div className="bg-alpha-color px-5 py-5 md:px-10 rounded-lg">
          <div className="flex space-x-3">
            <div className="font-semibold">Your Valuation</div>
            <button
              type="button"
              className="text-primary-color font-semibold border-none underline"
              onClick={() => setIsExtended(true)}
            >
              Add Notes
            </button>
          </div>
          <div className="xl:flex xl:items-center xl:space-x-3">
            <div className="-mx-3 flex-1">
              <InputViewField
                value={valuationConfirmationState?.confirmedValue || ''}
                placeholder="Enter Your Valuation"
                edit
                field="value"
                onChange={(v) => updateValuationConfirmation('confirmedValue', +v)}
              />
            </div>
            <div className="max-w-xs mx-auto">
              <SecondaryButton color="rgb(156 163 175)" onClick={handleUpdate}>
                Send Updated Valuation
              </SecondaryButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-alpha-color px-5 py-5 md:px-10 rounded-lg">
          <div className="xl:flex xl:items-center xl:space-x-3">
            <div className="flex items-center space-x-3">
              <div className="font-semibold">Your Valuation</div>
              <UnderlinedPrimaryButton
                type="button"
                className="text-primary-color font-semibold border-none underline"
                onClick={() => setIsExtended(false)}
              >
                Hide Notes
              </UnderlinedPrimaryButton>
            </div>
            <div className="flex-1">
              <InputViewField
                value={valuationConfirmationState?.confirmedValue}
                edit
                field="value"
                type="number"
                onChange={(v) => updateValuationConfirmation('confirmedValue', +v)}
              />
            </div>
          </div>
          <div className="xl:flex">
            <div className="flex flex-nowrap font-semibold text-sm py-1 w-80">
              Add Notes for {valuation?.user?.firstName}
            </div>
            <div className="w-full">
              <InputViewField
                edit
                value={valuationConfirmationState?.notes}
                type="textarea"
                onChange={(v) => updateValuationConfirmation('notes', v)}
              />
            </div>
          </div>
          <div className="xl:flex">
            <div className="flex flex-nowrap font-semibold text-sm py-1 w-80">
              Add Private Notes
            </div>
            <div className="w-full">
              <InputViewField
                edit
                value={valuationConfirmationState?.privateNotes}
                type="textarea"
                onChange={(v) => updateValuationConfirmation('privateNotes', v)}
              />
            </div>
          </div>
          <div className="flex justify-end w-full pt-3">
            <div className="w-80">
              <SecondaryButton color="black" onClick={handleUpdate}>
                Send Updated Valuation
              </SecondaryButton>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

// ----- LEGACY
// ----EXTENDED FALSE
// <div className="flex flex-col font-semibold rounded-lg bg-gray-200 my-3 px-10 py-4 w-full">
//   <div className="flex flex-row items-center space-x-10">
//     <div className="font-semibold">Your Valuation</div>
//     <button
//       type="button"
//       className="text-primary-color font-semibold border-none underline"
//       onClick={() => setIsExtended(true)}
//     >
//       Add Notes
//     </button>
//   </div>
//   <div className="flex flex-col sm:flex-row md:space-x-5 items-center">
//     <div className="w-full sm:w-80 -ml-3">
//       <InputViewField
//         value={valuationConfirmationState?.confirmedValue}
//         placeholder="Enter Your Valuation"
//         edit
//         field="value"
//         onChange={(v) => updateValuationConfirmation('confirmedValue', +v)}
//       />
//     </div>
//     <div>
//       <SecondaryButton color="rgb(156 163 175)" onClick={handleUpdate}>
//         Send Updated Valuation
//       </SecondaryButton>
//     </div>
//   </div>
// </div>
// ----EXTENDED TRUE
// <div className="flex flex-col font-semibold rounded-lg bg-gray-200 my-3 px-10 py-4 w-full">
//   <div className="flex space-x-3">
//     <div className="flex py-1 space-x-8">
//       <div className="">Your Valuation</div>
//       <button
//         type="button"
//         className="text-primary-color font-semibold border-none underline flex items-start"
//         onClick={() => setIsExtended(false)}
//       >
//         Hide Notes
//       </button>
//     </div>
//     <InputViewField
//       value={valuationConfirmationState?.confirmedValue}
//       edit
//       field="value"
//       type="number"
//       onChange={(v) => updateValuationConfirmation('confirmedValue', +v)}
//     />
//   </div>
//   <div className="flex">
//     <div className="flex flex-nowrap py-1 w-80">
//       Add Notes for {valuation?.user?.firstName}
//     </div>
//     <div className="w-full">
//       <InputViewField
//         edit
//         value={valuationConfirmationState?.notes}
//         type="textarea"
//         onChange={(v) => updateValuationConfirmation('notes', v)}
//       />
//     </div>
//   </div>
//   <div className="flex">
//     <div className="flex py-1 w-80">Add Private Notes</div>
//     <div className="w-full">
//       <InputViewField
//         edit
//         value={valuationConfirmationState?.privateNotes}
//         type="textarea"
//         onChange={(v) => updateValuationConfirmation('privateNotes', v)}
//       />
//     </div>
//   </div>
//   <div className="flex justify-end w-full">
//     <div className="w-80">
//       <SecondaryButton color="black" onClick={handleUpdate}>
//         Send Updated Valuation
//       </SecondaryButton>
//     </div>
//   </div>
// </div>
