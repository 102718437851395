import { AuthServices } from 'services/auth'
import { API } from 'aws-amplify'

export type FollowingType = {
  neighbourhoods: string[]
  properties: string[]
}

export module FollowingServices {
  const apiName = 'user'
  // list followings
  export const getFollowings = async (): Promise<FollowingType> => {
    const user = await AuthServices.getUserInfo().catch(() => {})
    if (!user)
      return {
        neighbourhoods: [],
        properties: [],
      }
    try {
      const responseData = (
        await API.get(apiName, '/followings', {
          response: true,
        })
      ).data as {
        neighbourhoods: { [id: string]: string }
        properties: { [id: string]: string }
      }
      return {
        neighbourhoods: Object.keys(responseData.neighbourhoods).map((key) => key),
        properties: Object.keys(responseData.properties).map((key) => key),
      } as FollowingType
    } catch (error) {
      return {
        neighbourhoods: [],
        properties: [],
      }
    }
  }
  // Follow
  export const followNeighbourhood = async (id: string): Promise<string[]> => {
    const responseData = (
      await API.put(apiName, `/followings/neighbourhoods/${id}`, {
        response: true,
      })
    ).data as {
      neighbourhoods: { [id: string]: string }
    }
    // return array following ids
    return Object.keys(responseData.neighbourhoods).map((key) => key)
  }
  export const followProperty = async (id: string): Promise<string[]> => {
    const responseData = (
      await API.put(apiName, `/followings/properties/${id}`, {
        response: true,
      })
    ).data as {
      properties: { [id: string]: string }
    }
    // return array following ids
    return Object.keys(responseData.properties).map((key) => key)
  }
  // Unfollow
  export const unfollowNeighbourhood = async (id: string) => {
    const responseData = (
      await API.del(apiName, `/followings/neighbourhoods/${id}`, {
        response: true,
      })
    ).data as {
      neighbourhoods: { [id: string]: string }
    }
    // return array following ids
    return Object.keys(responseData.neighbourhoods).map((key) => key)
  }
  export const unfollowProperty = async (id: string) => {
    const responseData = (
      await API.del(apiName, `/followings/properties/${id}`, {
        response: true,
      })
    ).data as {
      properties: { [id: string]: string }
    }
    // return array following ids
    return Object.keys(responseData.properties).map((key) => key)
  }

  export const getFollowingsByUserId = async (userId: string): Promise<FollowingType> => {
    try {
      const responseData = (
        await API.get(apiName, `/followings/${userId}`, {
          response: true,
        })
      ).data as {
        neighbourhoods: { [id: string]: string }
        properties: { [id: string]: string }
      }
      return {
        neighbourhoods: Object.keys(responseData.neighbourhoods).map((key) => key),
        properties: Object.keys(responseData.properties).map((key) => key),
      } as FollowingType
    } catch (error) {
      return {
        neighbourhoods: [],
        properties: [],
      }
    }
  }
}
