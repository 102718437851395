/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
import { SoldReportData } from 'views/soldReport'
import { Neighbourhood, NeighbourhoodReport } from 'types'
import { addPageHeader } from './pageHeader'
import { PropertyKeyLabel } from '../types/types'
import { addPropertyTypeSnapshot } from './snapshot'
import { addTable } from './table'
import { addNeighborhoodCharts } from './chartSection'

export type PropertyTypeProps = {
  neighbourhoodReport: NeighbourhoodReport
  neighbourhood: Neighbourhood
  monthLabel: string
  propertyKeyLabels: PropertyKeyLabel[]
  soldReportData: SoldReportData
}
export const addPropertyType = (
  doc: PDFKit.PDFDocument,
  {
    soldReportData,
    neighbourhoodReport,
    neighbourhood,
    monthLabel,
    propertyKeyLabels,
  }: PropertyTypeProps,
) => {
  const propertyKeyLabel: PropertyKeyLabel = propertyKeyLabels.find(
    (v) => v.key === neighbourhoodReport.type,
  ) as PropertyKeyLabel
  doc.addPage()
  // PdfHelper.addSVG(doc, template, 0, 0, { preserveAspectRatio: 'xMaxYMax slice' })
  addPageHeader(doc, {
    neighbourhoodName: neighbourhood.name,
    monthLabel,
    propertyKeyLabels,
  })
  // Add snapshot
  addPropertyTypeSnapshot(doc, {
    neighbourhoodReport,
    neighbourhood,
    monthLabel,
    propertyKeyLabels,
  })
  // Add sold property table
  let dy = 0
  if (neighbourhoodReport.soldNumber > 0) {
    dy = addTable(doc, {
      neighbourhoodReport,
      neighbourhood,
      monthLabel,
      y: 280,
    })
  } else {
    dy = 280
  }
  addNeighborhoodCharts(doc, {
    neighbourhoodReport,
    neighbourhood,
    monthLabel,
    propertyKeyLabel,
    soldReportData,
    y: dy + 20,
  })
  return doc
}
