import moment from 'moment-timezone'
import { MonthStr } from 'types'

export const TIME_ZONE_NAME = 'America/Toronto'

export type MonthYearType = {
  year: number
  month: number
}
export type DayMonthYearType = {
  year: number
  month: number
  day: number
}
export module DateHelper {
  export const today = new Date()
  export const getMonthLabel = (date: Date | number = Date.now(), short = true) =>
    new Intl.DateTimeFormat('en', { month: short ? 'short' : 'long' }).format(new Date(date))

  export const currYear = () => {
    const d = new Date()
    return new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  }

  // ex) 2022-2 -> 2022/02/01
  export const formatMonthStrToDate = (date: MonthStr | string) => {
    const d = date.split('-')
    const y = d[0]
    let m = d[1]
    if (+m < 10) {
      m = `0${m}`
    }
    return new Date(`${y}/${m}/01`)
  }
  // Format date to: Jun 30, 2020
  export const formatDateStr = (date: MonthStr | number) => {
    const d = new Date(date)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${month} ${day}, ${year}`
  }
  export const formatDateToYearMonthDayStr = (date?: number) => {
    const d = date ? new Date(date) : Date.now()
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${year}-${month}-${day}` as string
  }
  export const formatYearMonthDayStrToDate = (date: string) => {
    const newDate = DateHelper.strToYearMonthDay(date)
    return new Date(newDate.year, newDate.month - 1, newDate.day).getTime()
  }

  // Format date to: Month Year
  export const formatDateToMonthStr = (date: MonthStr) => {
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      moment(formatMonthStrToDate(date)).toDate(),
    )
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      moment(formatMonthStrToDate(date)).toDate(),
    )
    return `${month} ${year}`
  }

  export const formatDateToFull = (date: Date | number | string) => {
    const d = new Date(date)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${month} ${day}, ${year}`
  }

  // Format: October 10, 2022
  export const formatDateToLong = (date: Date | number | string) => {
    const d = new Date(date)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${month} ${day}, ${year}`
  }

  export const toMonthYearShort = (yearmonth: string) => moment(yearmonth).format('MMM YYYY')

  // input: yearmonth = "2022-03" | output: "Mar2022"
  export const toMonthShortYear = (yearmonth: string) => moment(yearmonth).format('MMMYYYY')

  // input: yearmonth = "2022-03" | output: "Mar"
  export const toMonthShort = (yearmonth: string) =>
    new Intl.DateTimeFormat('en', { month: 'short' }).format(moment(yearmonth).toDate())

  // input: yearmonth = "2022-03" | output: "March"
  export const toMonthLong = (yearmonth: string) =>
    new Intl.DateTimeFormat('en', { month: 'long' }).format(moment(yearmonth).toDate())

  // Format date to: 2020-07-30
  export const formatDateToDayMonthYear = (date: any) =>
    moment.tz(date, TIME_ZONE_NAME).format('YYYY-MM-DD')

  // Format date to: 07-30
  export const formatDateToMonthDay = (date: MonthStr) => {
    const d = moment(date).toDate()
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${month}-${day}`
  }
  // Format date to: 11/2022
  export const formatDateToMonthYear = (date: MonthStr) => {
    const d = moment(date).toDate()
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    return `${month}/${year}`
  }

  // Format date to: Jul 30
  export const formatDateToShortMonthDay = (date: MonthStr) => {
    const d = moment(date).toDate()
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${month} ${day}`
  }

  export const monthStrToYearAndMonth = (monthStr: MonthStr) => {
    const s = monthStr.split('-')
    return {
      year: Number(s[0]),
      month: Number(s[1]),
    } as MonthYearType
  }
  export const strToYearMonthDay = (str: string) => {
    const s = str.split('-')
    return {
      year: Number(s[0]),
      month: Number(s[1]),
      day: Number(s[2]),
    } as DayMonthYearType
  }
  export const dateToYearMonthDay = (date: Date | number) => {
    const d = new Date(date)
    return {
      year: d.getFullYear(),
      month: d.getMonth(),
      day: d.getDate(),
    } as DayMonthYearType
  }
  export const currentMonthStr = () => `${today.getFullYear()}-${today.getMonth() + 1}`

  export const getLastMonthStr = () => {
    const date = new Date()
    const month = date.getMonth()
    const year = date.getFullYear()
    return month === 0 ? (`${year - 1}-${12}` as MonthStr) : (`${year}-${month}` as MonthStr)
  }
  export const getCurrentMonthYear = () => {
    const currentDate = new Date()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    return `${year}-${month}`
  }
  // 2022-8-23
  export const currentDateStr = () =>
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

  export const isCurrentMonth = (date: MonthStr) => {
    const d = DateHelper.monthStrToYearAndMonth(date)
    return d.year === today.getFullYear() && d.month === today.getMonth() + 1
  }

  export const monthStrToShortMonthLabel = (monthYear: MonthYearType) => {
    if (!monthYear || Number.isNaN(monthYear.month) || Number.isNaN(monthYear.year)) return `--`
    const date = new Date(monthYear.year, monthYear.month - 1)
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
    const year = date.getFullYear()
    return `${month} ${year}`
  }
  export const monthStrToShortMonthLongLabel = (monthYear: MonthYearType) => {
    if (!monthYear || Number.isNaN(monthYear.month) || Number.isNaN(monthYear.year)) return `--`
    const date = new Date(monthYear.year, monthYear.month - 1)
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
    const year = date.getFullYear()
    return `${month} ${year}`
  }
  export const daysCountInMonth = (year: number, month: number) =>
    new Date(year, month, 0).getDate()
  export const daysInMonth = (month: number, year: number) => {
    const days: number[] = []
    const max = daysCountInMonth(month, year)
    for (let i = 1; i <= max; i += 1) {
      days.push(i)
    }
    return days
  }
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  export const getDayOfWeek = (year: number, month: number, day: number) => {
    const d = new Date(year, month - 1, day)
    return daysOfWeek[d.getDay()] as string
  }
  // Format date YYYYMM to YY/MM
  export const formatYearMonth = (date: string) => {
    const year = date.substring(0, 4)
    const month = date.substring(4)
    return `${year}/${month}`
  }
  export const isFuture = (year: number, month: number, day?: number) => {
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth() + 1
    const currentDate = today.getDate()
    if (year < currentYear) return false
    if (year === currentYear && month < currentMonth) return false
    if (year > currentYear) return true
    if (year === currentYear && month > currentMonth) return true
    if (day === undefined) return false
    return day > currentDate
  }
}
