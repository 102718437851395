import * as React from 'react'

function SvgTime(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M12 21a9 9 0 115-1.515L12 13V7"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgTime = React.memo(SvgTime)
export default MemoSvgTime
