import IMask from 'imask'

export module MaskHelper {
  export const date = (inputElement: HTMLInputElement) => {
    const mask = IMask(inputElement, {
      mask: '00/00/0000',
    })
    return mask
  }
  export const phone = (inputElement: HTMLInputElement) => {
    const mask = IMask(inputElement, {
      mask: '000-000-0000',
    })
    return mask
  }
  export const number = (inputElement: HTMLInputElement) => {
    const mask = IMask(inputElement, {
      mask: Number,
      thousandsSeparator: ' ',
    })
    return mask
  }
  //! Legacy: Remove bellow in next major release
  export const monthYearMask = (e: any) => {
    const input = e.target
    // TODO: 'charCode' is deprecated.
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault()
    }
    const len = input.value.length

    if (len !== 1) {
      if (e.charCode === 47) {
        e.preventDefault()
      }
    }

    if (len === 2) {
      input.value += '/'
    }
  }
  //! Legacy: Remove bellow in next major release
  export const maskPhoneNumber = (phoneNumber: string) => {
    phoneNumber.replace(/[^\d]/g, '')
    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }
    return null
  }
  //! Legacy: Remove bellow in next major release
  export module Mask {
    export const phoneNumber = [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  }
}
