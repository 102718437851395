import { UserRole } from 'constants/UserConstants'
import { HomeSearch, Portal } from './pages'
import { paths } from './paths'

export const routes = {
  portal: {
    path: paths.portal(),
    exact: false,
    component: Portal,
    requiredRoles: [UserRole.ADMIN, UserRole.AGENT, UserRole.CLIENT],
  },
  homeSearch: {
    path: paths.homeSearch(':mapType', ':saleType'),
    exact: false,
    component: HomeSearch,
    requiredRoles: [UserRole.ADMIN, UserRole.AGENT, UserRole.CLIENT],
  },
}
