import React, { useState } from 'react'
import { Carousel } from 'views/shared/components/carousel/Carousel'
import { House } from 'views/shared/components/icons'
import { MultiImageCarousel } from './MultiImageCarousel'

interface ImageSliderProps {
  images: string[]
  virtualTour?: string
}

export const PropertyDetailsImageSlider = ({ images, virtualTour }: ImageSliderProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = React.useState<number | null>(
    images && images.length > 0 ? 0 : null,
  )

  return (
    <>
      <div className="inline-block bg-white text-left overflow-y-auto transform transition-all w-full sm:align-middle z-50">
        <div className="relative w-full h-full">
          {images.length > 0 ? (
            <Carousel
              images={images}
              imageHeight="h-96"
              imageViewer={{ isViewerOpen, setIsViewerOpen, currentImage, setCurrentImage }}
              imageRounded="rounded-none"
            />
          ) : (
            <div className="flex justify-center items-center align-middle h-96 bg-alpha-color mb-2">
              <House style={{ fontSize: '56px' }} color="var(--default-color)" />
            </div>
          )}
          <div className="relative flex h-24 w-full overflow-x-auto box-border justify-start">
            <MultiImageCarousel
              images={images}
              virtualTour={virtualTour}
              imageViewer={{ isViewerOpen, setIsViewerOpen, currentImage, setCurrentImage }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
