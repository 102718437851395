/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'
import type { UserTypes } from 'services/user/UserTypes'
import { UserServices } from 'services/user'
import { useHistory } from 'react-router-dom'
import { dashboardPaths } from 'views/dashboard'
import { KeyLabel } from 'types'
import { DropdownField, Input } from 'views/shared/components/ui-form'
import { Time } from 'views/shared/components/icons'
import Moment from 'moment'
import { DateHelper } from 'utilities'
import { useUserStore } from 'store/dashboard/user'
import { Item, ListWrap } from './AgentContactList.styles'

type AgentContactListStateType = {
  agentId?: string
} & UserTypes.GetUsersResponse
type AgentContactListFilterType = {
  search: string
  sortBy: string
}

const agentContactListState = atom<AgentContactListStateType>({
  key: 'dashboard-agentContactListState',
  default: { agentId: undefined, total: 0, hits: [] },
})
const agentContactListFilter = atom<AgentContactListFilterType>({
  key: 'dashboard-agentContactListFilter',
  default: {
    search: '',
    sortBy: 'averageSessionDuration.desc',
  },
})
const filteredAgentContactList = selector({
  key: 'dashboard-filteredAgentContactList',
  get: ({ get }) => {
    const filter = get(agentContactListFilter)
    const state = get(agentContactListState)
    const newState = { ...state }
    newState.hits = state.hits.filter(
      (item) =>
        item.details &&
        `${item.details.firstName} ${item.details.lastName}`.trim() &&
        (`${item.details.firstName} ${item.details.lastName}`.trim() as string)
          .toLocaleLowerCase()
          .includes(filter.search.toLowerCase()),
    )
    return newState
  },
})
const sortOptions = [
  { key: 'firstName', label: 'First name' },
  { key: 'lastName', label: 'Last name' },
  { key: 'email', label: 'Email' },
  { key: 'phone', label: 'Phone' },
  { key: 'logins.desc', label: 'Number of Logins' },
  { key: 'averageSessionDuration.desc', label: 'Average time on site' },
  { key: 'totalReportOpen.desc', label: 'Number of sold reports open' },
  { key: 'lastLoginAt.desc', label: 'Last login' },
] as KeyLabel<string>[]

const idToQuery = (id?: string) =>
  id ? (JSON.parse(atob(id)) as Partial<UserTypes.GetUsersParametersType>) : undefined
export const useAgentContactList = (agentId?: string) => {
  const [contactList, setContactList] = useRecoilState(agentContactListState)
  const [filter, setFilter] = useRecoilState(agentContactListFilter)
  const filteredContactList = useRecoilValue(filteredAgentContactList)

  const retrieveUser = async (aid?: string, sort?: string) => {
    setContactList({
      hits: [],
      total: 0,
      agentId: aid,
    })
    if (aid !== undefined) {
      const response = await UserServices.getUsersByAgentId({ agentId: aid, sort })
      setContactList({
        hits: response.hits,
        total: response.total,
        agentId: aid,
      })
    }
  }
  useEffect(() => {
    if (agentId !== contactList.agentId) {
      setFilter({ search: '', sortBy: 'averageSessionDuration.desc' })
    }
  }, [agentId])

  useEffect(() => {
    agentContactListFilter.key
    const getUsers = async () => {
      await retrieveUser(agentId, filter.sortBy)
    }
    getUsers()
  }, [filter.sortBy])

  return { contactList: filteredContactList, filter, setFilter }
}

export const AgentContactList = () => {
  const { userState } = useUserStore()
  const agentId = userState.userId || undefined
  const { contactList, filter, setFilter } = useAgentContactList(agentId)
  const sortByHandler: (key: string) => void = (key: string) => {
    setFilter({ ...filter, ...{ sortBy: key } })
  }
  const searchHandler: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    setFilter({ ...filter, ...{ search: e.currentTarget.value } })
  }
  const history = useHistory()

  const userItemClickHandler: (userId: string) => React.MouseEventHandler<HTMLLIElement> =
    (userId) => () => {
      window.location.pathname.includes('users') && window.location.pathname.includes('agent')
        ? history.push(dashboardPaths.agentContacts(userId, window.location.pathname))
        : history.push(dashboardPaths.profile(userId))
    }

  return (
    <>
      <div className="mt-10 mx-0">
        <div className="flex flex-col space-y-5 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-5">
          <div className="sm:w-3/5">
            <Input
              onKeyUp={searchHandler}
              name="search"
              id="search"
              placeholder="Search contacts"
            />
          </div>
          <div className="sm:w-2/5">
            <DropdownField
              onChange={sortByHandler}
              name="agentSortBy"
              value={filter.sortBy}
              options={sortOptions}
            />
          </div>
        </div>
        <ListWrap>
          {contactList.hits.map((user) => (
            <Item onClick={userItemClickHandler(user.id)} key={user.id}>
              <div className="flex justify-end">
                <p className="py-0.5 px-4 bg-accent-color rounded-t-none rounded-bl-lg text-white text-xs">
                  <span>Last login </span>
                  <span className="font-semibold">
                    {(user.lastLoginAt && DateHelper.formatDateToFull(user.lastLoginAt)) || '---'}
                  </span>
                </p>
              </div>
              <div className="flex items-center justify-between p-2">
                <p className="text-xl font-semibold text-secondary-color">
                  {`${user.details.firstName || ''} ${user.details.lastName || ''}`.trim()}
                </p>
                <p className="flex items-center text-default-color text-xs italic px-4">
                  <Time style={{ fontStyle: '12px' }} />
                  {Moment.duration(user.averageSessionDuration || '---')
                    .locale('en')
                    .humanize(false)}
                </p>
              </div>
              <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between text-default-color text-sm px-2 pt-0 pb-2">
                <p>{user.details.phone || 'XXX-XXX-XXXX'}</p>
                <p>{user.details.email}</p>
              </div>
            </Item>
          ))}
        </ListWrap>
      </div>
    </>
  )
}
