import React, { useContext, useState } from 'react'
import { DashboardHeader } from 'views/dashboard/components/header'
import { AuthContext } from 'views/authentication'
import { UserRole } from 'constants/UserConstants'
import { MonthPicker, SecondaryNegativeButton, Title } from 'views/shared/components/ui-form'
import { StackPageType } from 'utilities/hooks'
import { DateHelper } from 'utilities'
import { SoldReportEmail } from './SoldReportEmail'
import { WebAnalytics } from './WebAnalytics'
import { UsersAnalytics } from './UsersAnalytics'

export const Analytics2 = ({ page, id }: StackPageType) => (
  <div>
    <div>
      Analitcs - {page}:{id}
    </div>
  </div>
)

export const AnalyticsPage = () => {
  const { authUser } = useContext(AuthContext)
  const [month, setMonth] = useState<string>(DateHelper.currentMonthStr())

  const isAdmin = authUser && authUser?.roles && authUser.roles.indexOf(UserRole.ADMIN) > -1

  const googleAnalytics = () =>
    window.open(
      'https://analytics.google.com/analytics/web/#/p314170955/reports/intelligenthome',
      '_blank',
    )
  return (
    <>
      <div className="h-screen mb-28">
        <DashboardHeader title="Platform Analytics" />
        <div className="mx-5 pb-10">
          <div>
            <UsersAnalytics />
          </div>
          <div className="mb-10">
            <div className="sm:flex sm:items-center sm:space-x-10 mb-5 space-y-2">
              <Title>Monthly data</Title>
              <MonthPicker monthStr={month} onChange={(value) => value && setMonth(value)} />
            </div>
            <div>
              <SoldReportEmail month={month} isAgent={false} />
            </div>
            <div>
              <WebAnalytics month={month} />
            </div>
          </div>
          <div className="w-44">
            {isAdmin && (
              <SecondaryNegativeButton onClick={googleAnalytics}>
                Google Analytics
              </SecondaryNegativeButton>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
