/* eslint-disable no-plusplus */
/* eslint-disable no-continue */

import { DateHelper } from 'utilities'

const getLabelsY = (values: number[], max: number, height = 500) => {
  const minDist = 16
  const r: number[] = values.map((v) => height - (height / max) * v)
  for (let i = 0; i < values.length; i++) {
    const actual = r[i]
    for (let x = 0; x < values.length; x++) {
      const next: number = r[x]
      if (x === i) continue
      if (Math.abs(next - actual) >= minDist) continue
      if (next - actual < 0) {
        r[x] = next - minDist + (next - actual)
      } else {
        r[x] = next + minDist - (next - actual)
      }
    }
  }
  return r
}
export type RenderChartProps = {
  cols?: string[]
  rowsCount?: number
  data?: {
    values: number[]
    color: string
  }[]
  rowLabel?: (value: number) => string
  colLabel?: (value: string) => string
}
export const renderChart = ({
  data = [],
  rowsCount = 6,
  cols = [],
  rowLabel = (val: number) => val.toString(),
  colLabel = (val: string) =>
    DateHelper.monthStrToShortMonthLabel(DateHelper.strToYearMonthDay(val)),
}: RenderChartProps) => {
  const max: number = Math.max(...data.map((v) => v.values).flat(1))
  const rowsY = [...Array(rowsCount)].map((_, i) => (500 / rowsCount) * i + 10)
  const rowsValues = [...Array(rowsCount)].map((_, i) => max + (max / rowsCount) * i * -1)
  // calculate values Y
  const avarages = data.map(
    (item) => item.values.reduce((previousValue, value) => previousValue + value, 0) / cols.length,
  )
  const avarageY = avarages.map((v) => 500 - (500 / max) * v)
  const labelY = getLabelsY(avarages, max).map((v, i) => v - avarageY[i])

  return /* svg */ `
  <svg width="1340" height="571" viewBox="0 0 1340 571" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line x1="200" y1="510" x2="1200" y2="510" stroke="#C4C4C4"/>
  ${rowsY
    .map(
      (rowY, i) => /* svg */ `
     <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="146" y="${
       rowY + 5
     }">${
        rowsValues[1] > 0 && !Number.isNaN(rowsValues[1]) ? rowLabel(rowsValues[i]) : '--'
      }</tspan></text>
     <line x1="200" y1="${rowY} " x2="1200" y2="${rowY}" stroke="#C4C4C4"/>
     `,
    )
    .join('')}
  <line x1="200" y1="10" x2="1200" y2="10" stroke="#C4C4C4"/>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat"  font-size="14" font-weight="600" letter-spacing="0em"><tspan x="148.201" y="515.019"></tspan></text>
  ${data
    .map(
      (dataItem, dataItemIndex) => /* svg */ `
    <g transform="translate(200, ${avarageY[dataItemIndex] + 10})">
      <rect display="none" width="200" height="24" fill="white" y="-24"/>
      <text  fill="${
        dataItem.color
      }" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="0" y="${
        labelY[dataItemIndex]
      }">Average: </tspan><tspan font-weight="bold">${rowLabel(
        avarages[dataItemIndex],
      )}</tspan></text>
      <line
        x1="0" x2="1000" 
        y1="0"
        y2="0"
        stroke-dasharray="2,2"
        stroke="${dataItem.color}"
        style="stroke-width:1;" />
    </g>
    ${cols.map((col, i) => {
      const x1 = (1000 / (cols.length - 1)) * i + 200
      const x2 = (1000 / (cols.length - 1)) * (i + 1) + 200
      const y1 = 500 - (500 / max) * dataItem.values[i] + 10
      const y2 = 500 - (500 / max) * dataItem.values[i + 1] + 10
      return /* svg */ `
      ${
        i < dataItem.values.length - 1
          ? /* svg */ `<line
      x1="${x1}" x2="${x2}" 
      y1="${y1}"
      y2="${y2}"
      stroke="${dataItem.color}"
      style="stroke-width:${dataItemIndex === 0 ? '2' : '0'}} " />`
          : ``
      }`
    })}
  `,
    )
    .join('')}
    ${cols.map(
      (col, i) => /* svg */ `<text
    fill="#62727A"
    xml:space="preserve"
    style="white-space: pre"
    font-family="Montserrat"
    font-size="14"
    font-weight="600"
    letter-spacing="0em"
    text-anchor="middle"
    x="${(1000 / (cols.length - 1)) * i + 200}"><tspan y="536">${colLabel(col)}</tspan></text>`,
    )}
  </svg>
`
}
