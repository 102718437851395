import React, { useEffect } from 'react'
import { ModalLayer } from '../modal-layer'
import { TextBody } from '../ui-form'

export type FieldModalProps = React.PropsWithChildren<{
  title?: string
  message?: string
  icon_src?: string
  isOpen?: boolean
  onClose?: () => void

  renderTitle?: (title: string) => JSX.Element
}>

export const FieldModal = ({
  title,
  message,
  icon_src,
  isOpen,
  onClose,
  children,
  renderTitle = (titleProp) => (
    <h2 className="text-secondary-color text-lg font-normal pb-4">{titleProp}</h2>
  ),
}: FieldModalProps) => {
  useEffect(() => {
    const escapeCallback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose && onClose()
      }
    }
    // add escape event
    window.addEventListener('keyup', escapeCallback)
    return () => {
      // remove escape event
      window.removeEventListener('keyup', escapeCallback)
    }
  }, [onClose])

  return (
    <ModalLayer
      className="p-5 box-border overflow-hidden"
      show={isOpen || false}
      onOutsideClick={() => onClose && onClose()}
      outsideColor="rgba(0,0,0,0.3)"
      responsive={{
        small: 'full',
        medium: 'center',
      }}
    >
      <div className="bg-white rounded-lg p-6 py-8 w-full box-border sm:max-w-md relative">
        <button
          type="button"
          className="text-2xl text-primary-color font-medium absolute right-5 top-2 z-10"
          onClick={() => onClose && onClose()}
        >
          x
        </button>
        <div className="flex items-start pb-0">
          {icon_src && <img className="h-10 w-10 inline-block mr-4" src={icon_src} alt="Icon" />}
          {title && renderTitle(title)}
        </div>
        {message && <TextBody>{message}</TextBody>}
        <div className="mt-4">{children}</div>
      </div>
    </ModalLayer>
  )
}
