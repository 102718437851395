import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { CurrencyHelper } from 'utilities/helpers/currencyHelper'
import { Carousel } from 'views/shared/components/carousel/Carousel'
import { Bath, Bed, House, Parking } from 'views/shared/components/icons'
import { PropertyServices } from 'services/property/PropertyServices'
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { Config } from 'config'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'

interface ComparablePropertyItemProps {
  property: HomeValuationAnnualReport.PropertyDetails
  handleRemoveListing: (mls: string) => void
}

export const ComparablePropertyItem = ({
  property,
  handleRemoveListing,
}: ComparablePropertyItemProps) => {
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)
  const [propertyImageState, setPropertyImageState] = useState<string[] | null>(null)

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public`)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (property.summary) {
      const newImages: string[] = PropertyServices.getPropertyImageURLs(
        property.summary,
        imageBaseUrl,
      )
      setPropertyImageState(newImages)
    } else {
      setPropertyImageState([])
    }
  }, [imageBaseUrl])

  const onClickComparableProperty = (mls: string) => {
    window.open(`/property-details/?=${mls}`, '_blank')
  }

  return (
    <>
      <div className="relative box-border lg:h-44 w-72 sm:max-w-sm lg:max-w-xl sm:w-full flex flex-col lg:flex-row lg:items-center bg-alpha-color rounded-l-xl rounded-br-xl">
        <div className=" h-full">
          {propertyImageState && propertyImageState.length > 0 ? (
            <Carousel
              images={[propertyImageState[0] || '']}
              imageHeight="h-full lg:h-44"
              imageWidth="w-full lg:w-44"
              imageRounded="rounded-t-lg lg:rounded-l-lg lg:rounded-t-none"
            />
          ) : (
            <div className="flex w-32 h-32 justify-center items-center align-middle">
              <House style={{ fontSize: '56px' }} color="var(--default-color)" />
            </div>
          )}
        </div>
        <button
          onClick={() => handleRemoveListing(property.summary.mls)}
          type="button"
          className="bg-alpha-color h-8 w-8 text-xl text-primary-color hover:text-secondary-color font-semibold rounded-r-lg absolute top-0 -right-8"
        >
          x
        </button>
        <div
          className="p-3 space-y-1 cursor-pointer hover:underline flex-1"
          onClick={() => onClickComparableProperty(property.summary.mls)}
          aria-hidden="true"
        >
          <div className="text-2xl">{`${
            property.summary.unit && `${property.summary.unit} - `
          }${UnescapeHelper.unescape(property.summary.address)}, ${property.summary.area}`}</div>
          <div className="flex flex-col space-y-1 xl:flex-row xl:space-x-10">
            <ul className="flex space-x-3">
              <li className="flex items-center space-x-2">
                <span className="text-sm text-default-color">
                  {property.summary.bedroom}
                  {property.summary.brPlus ? `+${property.summary.brPlus}` : ''}
                </span>
                <Bed color="var(--default-color)" style={{ fontSize: '30px' }} />{' '}
              </li>
              <li className="flex items-center space-x-2">
                <span className="text-sm text-default-color">{property.summary.bathroom}</span>
                <Bath color="var(--default-color)" style={{ fontSize: '30px' }} />{' '}
              </li>
              <li className="flex items-center space-x-2">
                <span className="text-sm text-default-color">{property.summary.parking}</span>
                <Parking color="var(--default-color)" style={{ fontSize: '30px' }} />{' '}
              </li>
            </ul>
            <div>
              <div className="flex space-x-2 items-center">
                <div className="whitespace-nowrap">Listed For</div>
                <div className="font-bold">
                  $
                  {property.summary.listingPrice &&
                    CurrencyHelper.currencyFormat(property.summary.listingPrice)}
                </div>
              </div>
              <div className="flex space-x-2 items-center">
                <div className="whitespace-nowrap">Sold For</div>
                <div className="text-accent-color font-bold">
                  $
                  {property.summary.soldPrice &&
                    CurrencyHelper.currencyFormat(property.summary.soldPrice)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
