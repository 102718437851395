import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'debounce'
import { useHistory, useParams } from 'react-router-dom'
import { Neighbourhood, Listing } from 'types'
import { useNeighbourhoods } from 'views/map/hooks'
import { PropertyServices } from 'services/property/PropertyServices'
import { SearchMapController } from 'views/map/components/SearchMap/searchMapController'
import { useHomeSearchStore } from 'store/homeSearch/homeSearchStore'
import { PropertyListing, PropertyLocation, PropertyNeighbourhood } from 'types/Property'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import { Storage } from 'aws-amplify'
import { Config } from 'config'
import { useFiltersStore } from 'store/homeSearch'
import { Search } from '../icons'
import { ListingSummary } from '../listing-summary/ListingSummary'

export type AddressSearchType = {
  title: string
  page?: 'home-search' | 'neighbourhood'
}

export type QueryPropertyResultsType = {
  listings: PropertyListing[]
  locations: PropertyLocation[]
  neighbourhoods: PropertyNeighbourhood[]
}

export const AddressSearch = ({ title }: AddressSearchType) => {
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState<string>('')
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const history = useHistory()

  const { neighbourhoods, isLoading: isNeighbourhoodLoading } = useNeighbourhoods()
  const [queryNeighbourhoodsResults, setQueryNeighbourhoodsResults] = useState<Neighbourhood[]>([])
  const [queryPropertyResults, setQueryPropertyResults] = useState<QueryPropertyResultsType>()
  const [locationInfoResults, setLocationInfoResults] = useState<any>()
  const [querySoldResults] = useState<Listing[]>([])
  const [queryActiveResults] = useState<Listing[]>([])
  const [page] = useState(window.location.pathname.split('/')[1])
  const { filterForm } = useHomeSearchStore()
  const param = useParams<{ saleType: string; mapType: string }>()
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>(null)
  const { setFiltersState } = useFiltersStore()

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public` as string)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    const escapeCallback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
      }
    }
    // add escape event
    window.addEventListener('keyup', escapeCallback)
    return () => {
      // remove escape event
      window.removeEventListener('keyup', escapeCallback)
    }
  }, [])
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [isOpen])
  useEffect(() => {
    if (locationInfoResults) {
      const mapView = locationInfoResults.Response.View[0].Result[0].Location.MapView

      if (param.mapType === 'map') {
        const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(mapView.BottomRight.Latitude, mapView.TopLeft.Longitude),
          new google.maps.LatLng(mapView.TopLeft.Latitude, mapView.BottomRight.Longitude),
        )
        SearchMapController.instance().fitBounds(bounds)
      } else {
        const topLeft = [mapView.TopLeft.Latitude, mapView.TopLeft.Longitude]
        const bottomRight = [mapView.BottomRight.Latitude, mapView.BottomRight.Longitude]
        setFiltersState((oldState) => ({
          ...oldState,
          topLeft,
          bottomRight,
          mapInconsistent: true,
        }))
      }
    }
  }, [locationInfoResults])
  // handle events
  const onFakeInputClick = () => {
    setIsOpen(true)
  }
  const onOutsideClick = () => {
    setIsOpen(false)
  }
  const onEscape = () => {
    setIsOpen(false)
  }
  const onSearchInputChange: KeyboardEventHandler<HTMLInputElement> = (e: any) => {
    if (page === 'home-search') {
      PropertyServices.addressTextSearch(e.target.value).then(setQueryPropertyResults)
      setQuery(e.target.value)
    } else {
      PropertyServices.addressTextSearch(e.target.value).then(setQueryPropertyResults)
      setQuery(e.currentTarget.value)
    }
  }
  const onSearchResultClick = (mls: string) => {
    // SearchMapController.instance().setCenter(loc, zoom)
    history.push(`/property-details/?=${mls}`, { from: window.location.pathname })
    setIsOpen(false)
  }

  const onSearchResultLocationClick = (locationId: string) => {
    PropertyServices.getLoctionInfo(locationId).then(setLocationInfoResults)
    setIsOpen(false)
  }

  const onSearchResultNeighbourhoodClick = (neighbourhoodInfo: any) => {
    if (param.mapType === 'map') {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(neighbourhoodInfo.bottomRight.lat, neighbourhoodInfo.topLeft.lon),
        new google.maps.LatLng(neighbourhoodInfo.topLeft.lat, neighbourhoodInfo.bottomRight.lon),
      )
      SearchMapController.instance().fitBounds(bounds)
    } else {
      const topLeft = [neighbourhoodInfo.topLeft.lat, neighbourhoodInfo.topLeft.lon]
      const bottomRight = [neighbourhoodInfo.bottomRight.lat, neighbourhoodInfo.bottomRight.lon]
      setFiltersState((oldState) => ({
        ...oldState,
        topLeft,
        bottomRight,
        mapInconsistent: true,
      }))
    }
    setQuery(neighbourhoodInfo.name)
    setIsOpen(false)
  }

  // TODO: should be improved
  const renderLocationLabel = (item: any) => {
    const labels: string[] = item.label.split(',')

    return labels.slice(2).reverse().join(',')
  }

  const debounceSearchInputChangeHandler = useCallback(debounce(onSearchInputChange, 300), [])

  const getListingValue = (item: PropertyListing) =>
    `${item.unit} #${item.unit} ${item.address}, ${item.municipality} [${item.mls}]`

  const getLocationValue = (item: any) =>
    `${renderLocationLabel(item)}  [${item.matchLevel
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()}]`

  // get query results
  useEffect(() => {
    let neighbourhoodsResults: Neighbourhood[] = []
    // const soldListingResults: Listing[] = []
    // const activeListingResults: Listing[] = []
    if (query.length > 0 && !isNeighbourhoodLoading) {
      neighbourhoodsResults = neighbourhoods
        .filter((value) => value.name.toLowerCase().includes(query.toLowerCase()))
        .slice(0, 10)
    } else {
      neighbourhoodsResults = []
    }
    setQueryNeighbourhoodsResults(neighbourhoodsResults)
    // TODO: add soldListing and activeListing query here
  }, [isNeighbourhoodLoading, neighbourhoods, query, setQueryNeighbourhoodsResults])

  useEffect(() => {})
  return (
    <>
      {isOpen && (
        <div
          tabIndex={0}
          role="link"
          onKeyUp={(e) => {
            if (e.key === 'Escape') onEscape()
          }}
          onClick={onOutsideClick}
          className="fixed top-0 right-0 bottom-0 left-0 opacity-0"
        >
          &nbsp;
        </div>
      )}
      <div className="relative">
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <Search
              style={{
                fontSize: '24px',
              }}
              color="var(--primary-color)"
              aria-hidden="true"
            />{' '}
          </div>
          <button
            type="button"
            className="cursor-text italic block box-border bg-white w-full h-12 text-base text-left text-default-color border border-primary-soft-color rounded-md  pl-10 pr-3 focus:outline-none focus:border-primary-soft-color focus:ring-offset-0 focus:drop-shadow-none  focus:shadow-none"
            onClick={onFakeInputClick}
          >
            {query.length > 0 ? query : title}
          </button>
        </div>
        {isOpen && (
          <div
            className="search
            absolute flex flex-col text-default-color top-0 left-0 right-0  rounded-md 
           overflow-hidden shadow-sm"
          >
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <Search
                  style={{
                    fontSize: '24px',
                  }}
                  color="var(--primary-color)"
                  aria-hidden="true"
                />
              </div>
              <input
                defaultValue={query}
                ref={searchInputRef}
                onKeyUp={
                  page === 'home-search' ? debounceSearchInputChangeHandler : onSearchInputChange
                }
                type="text"
                className="cursor-text block box-border w-full h-12 text-left text-default-color italic border border-primary-soft-color rounded-md py-0 pl-10 pr-3 text-base focus:border-primary-soft-color focus:outline-none focus:ring-offset-0 focus:drop-shadow-none focus:shadow-none"
              />
            </div>

            {/* home-search */}
            {page === 'home-search' && (
              <div className="bg-white z-20 px-6 max-h-96 mt-0 max-h-auto scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
                {queryPropertyResults && queryPropertyResults.listings.length > 0 && (
                  <div className="py-5">
                    <ul className="pb-0 border-b border-alpha-dark-color">
                      <li key="listingHeader" className="pb-5">
                        <div className="uppercase font-semibold text-secondary-color text-xl">
                          Listings
                        </div>
                      </li>
                      {queryPropertyResults.listings.map((item) => (
                        <li key={item.mls} className="pb-5">
                          <ListingSummary
                            property={item}
                            imageBaseUrl={imageBaseUrl}
                            onClick={onSearchResultClick}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {queryPropertyResults && queryPropertyResults.locations.length > 0 && (
                  <div className="py-5">
                    <ul className="border-b border-alpha-dark-color">
                      <li key="listingHeader" className="pb-5">
                        <div className="uppercase font-semibold text-secondary-color text-xl">
                          Locations
                        </div>
                      </li>
                      {queryPropertyResults.locations.map((item: any) => (
                        <li key={item.mls} className="pb-5">
                          <div className="flex justify-between">
                            <button
                              type="button"
                              className="cursor-pointer text-left text-primary-color font-semibold text-xl"
                              onClick={() => onSearchResultLocationClick(item.locationId)}
                            >
                              {getLocationValue(item)}
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {queryPropertyResults && queryPropertyResults.neighbourhoods.length > 0 && (
                  <div className="py-5">
                    <ul className="border-b border-alpha-dark-color">
                      <li key="listingHeader" className="pb-5">
                        <div className="uppercase font-semibold text-secondary-color text-xl">
                          Neighbourhoods
                        </div>
                      </li>
                      {queryPropertyResults.neighbourhoods.map((item: any) => (
                        <li key={item.mls} className="pb-5">
                          <div className="flex justify-between">
                            <button
                              type="button"
                              className="cursor-pointer text-left text-primary-color font-semibold text-xl"
                              onClick={() => onSearchResultNeighbourhoodClick(item)}
                            >
                              {item.name}
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            {/* neighbourhood */}
            {page === 'neighbourhood' && (
              <div className="bg-white z-20 mt-0 max-h-52 scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
                {queryNeighbourhoodsResults.length > 0 && (
                  <div className="p-6">
                    <ul className="pb-0">
                      {queryNeighbourhoodsResults.map((item) => (
                        <li key={item.id} className="pb-5">
                          <div className="flex justify-between">
                            <button
                              type="button"
                              onClick={() => history.push(`/sold-report/${item.id}`)}
                              className="cursor-pointer text-left text-primary-color font-semibold text-xl"
                            >
                              {item.name}
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {/* sold listing */}
                {querySoldResults.length > 0 && (
                  <div className="px-6">
                    <hr className="border-alpha-dark-color" />
                    <p className="font-semibold text-xl tracking-wider text-left text-secondary-color pb-7 uppercase">
                      SOLD LISTING
                    </p>
                    <ul className="">
                      {querySoldResults.map((item) => (
                        <li key={item.id} className="flex justify-between pb-7">
                          <div className="text-left text-gray-dark font-normal text-base">
                            <p className="font-semibold">
                              {item.street} - {item.regionName} - {item.neighbourhoodName}
                            </p>
                            <p>{item.description}</p>
                            <p>Listing Date: {item.listingDate}, Listed P times</p>
                          </div>
                          <div className="text-left text-secondary-color font-normal text-base">
                            <p className="text-accent-color font-semibold">{item.price}</p>
                            <p>
                              Detached <span className="font-semibold">Sold</span>
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <hr className="border-alpha-dark-color" />
                  </div>
                )}
                {/* active listing */}
                {querySoldResults.length > 0 && (
                  <div className="px-6 py-6">
                    <p className="font-semibold text-xl tracking-wider text-left text-secondary-color pb-7 uppercase">
                      ACTIVE LISTING
                    </p>
                    <ul className="">
                      {queryActiveResults.map((item) => (
                        <li key={item.id} className="flex justify-between pb-7">
                          <div className="text-left text-secondary-color font-normal text-base">
                            <p className="font-semibold">
                              {item.street} - {item.regionName} - {item.neighbourhoodName}
                            </p>
                            <p>{item.description}</p>
                            <p>Listing Date: {item.listingDate}, Listed P times</p>
                          </div>
                          <div className="text-left text-secondary-color font-normal text-base">
                            <p className="text-accent-color font-semibold">{item.price}</p>
                            <p className="uppercase">FOR SALE</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
