import * as React from 'react'

function SvgArrowDownSelect(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path d="M8 9L.206 0h15.588L8 9z" fill={props.color || 'var(--default-icon-color, #000)'} />
    </svg>
  )
}

const ArrowDownSelect = React.memo(SvgArrowDownSelect)
export default ArrowDownSelect
