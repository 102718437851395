import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'
import { useHistory } from 'react-router-dom'

import {
  PrimaryButton,
  TextBody,
  Title,
  InputField,
  TextBodyDark,
} from 'views/shared/components/ui-form'
import { AuthException, AuthServices } from 'services/auth'
import { paths } from '../../paths'
import { AuthLayout } from '../../components'

export const ResetPasswordVerification = () => {
  const history = useHistory()
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null)
  const [email] = useState(AuthServices.getLocalStorageEmail())

  const onSubmit = async (
    { code, password }: { code: string; password: string },
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true)
      if (!email) {
        history.replace(paths.resetPassword())
      } else {
        await AuthServices.forgotPasswordSubmit(email, code, password)
        history.replace(paths.signIn())
      }
    } catch (error) {
      setResponseErrors([(error as AuthException).message])
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: { verificationCode: '', newPassword: '', confirmPassword: '' },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit({ code: values.verificationCode, password: values.newPassword }, setSubmitting)
    },
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string().required(PasswordErrorMsgs.required),
      newPassword: Yup.string()
        .required(PasswordErrorMsgs.required)
        .min(8, PasswordErrorMsgs.minEightCharsMet)
        .matches(
          /^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]\S*)[A-Za-z\d\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]{8,}$/,
          PasswordErrorMsgs.minThreeConditionsMet,
        ),
      confirmPassword: Yup.string()
        .required(PasswordErrorMsgs.required)
        .oneOf([Yup.ref('newPassword'), null], PasswordErrorMsgs.passwordsNotMatch),
    }),
  })

  const resendVerificationCode = async () => {
    try {
      if (email) {
        await AuthServices.forgotPassword(email)
        const confirmationCodeSentMsg =
          'The confirmation code is sent. Please check your email inbox'
        const errlist = responseErrors
          ? responseErrors.concat(confirmationCodeSentMsg)
          : [confirmationCodeSentMsg]
        setResponseErrors(errlist)
      }
    } catch (error) {
      setResponseErrors(responseErrors)
    }
  }

  return (
    <AuthLayout>
      <div className="mb-5">
        <Title>Reset password</Title>
      </div>
      <div className="mb-5 space-y-5">
        <TextBody fontWeight={600}>
          We&#39;ve sent a verification code to <br />
          <strong className="block w-72 overflow-hidden overflow-ellipsis sm:w-full sm:overflow-auto">
            {email}
          </strong>
        </TextBody>
        <TextBody fontWeight={600}>Enter the code from the email in the field below:</TextBody>
      </div>
      <div>
        <form className="space-y-5" onSubmit={formik.handleSubmit}>
          <div>
            <InputField
              name="verificationCode"
              label="Verification Code"
              type="text"
              formik={formik}
            />
          </div>
          {/* <TextBodyDark fontWeight={600}>{PasswordErrorMsgs.minThreeConditionsMet}</TextBodyDark> */}

          <div className="space-y-1">
            <InputField name="newPassword" label="New Password" type="password" formik={formik} />
          </div>
          <div className="space-y-1">
            <InputField
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              formik={formik}
            />
          </div>
          {responseErrors && (
            <div className="p-2">
              {responseErrors.map((message) => (
                <div
                  key={message}
                  className="mt-1 bg-blue-200 text-warning-color font-semibold text-sm"
                >
                  {message}
                </div>
              ))}
            </div>
          )}
          <div className="space-y-10">
            <div className="text-sm text-right">
              <button
                type="button"
                onClick={resendVerificationCode}
                className="font-semibold text-primary-color cursor-pointer underline"
              >
                Resend code
              </button>
            </div>
            <PrimaryButton type="submit" disabled={formik.isSubmitting}>
              Confirm
            </PrimaryButton>
          </div>
        </form>
      </div>
    </AuthLayout>
  )
}
