import * as React from 'react'

function SvgThumbDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M14.364 4.264c-2.46-1.614-5.979-1.345-7.687-1.009-3.074 1.009-4.611 6.555-3.074 8.068s5.807 1.68 7.686 1.513c-3.074 1.008-3.757 3.862-4.1 5.547-.409 2.017.513 2.353 1.026 2.52 1.191.392 1.708-.503 2.05-1.008.82-2.017 1.707-3.025 2.561-3.53 2.562-1.512 2.733-2.857 3.075-4.033H19a2 2 0 002-2V6.264a2 2 0 00-2-2h-2.074V10"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgThumbDown = React.memo(SvgThumbDown)
export default MemoSvgThumbDown
