import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import { DropdownField } from 'views/shared/components/ui-form'
import { HomeValueEstimation } from 'types'
import { HomeValuationServices } from 'services/homeValuation/HomeValuationServices'

interface ReceiveHomeValuationToggleProps {
  valuation: HomeValueEstimation
}

interface ReceiveUpdatesButtonProps {
  value: any
  onClick: () => void
}

export const ReceiveHomeValuationToggle = ({ valuation }: ReceiveHomeValuationToggleProps) => {
  const [frequency, setFrequency] = useState<string>(valuation.receiveUpdates || 'monthly')
  const [receiveUpdatesToggle, setReceiveUpdatesToggle] = useState(true)

  const handleFrequencyChange = (value: string) => {
    setFrequency(value)
    if (receiveUpdatesToggle) {
      valuation.id && HomeValuationServices.updateHomeValueNotification(valuation.id, value)
    }
  }

  const onToggleChange = () => {
    valuation.id &&
      HomeValuationServices.updateHomeValueNotification(
        valuation.id,
        receiveUpdatesToggle ? 'none' : frequency,
      ).then(() => setReceiveUpdatesToggle((prev) => !prev))
  }

  const ReceiveUpdatesButton: React.FC<ReceiveUpdatesButtonProps> = ({ onClick, value }) => (
    <button
      type="button"
      className="border-none bg-gray-300 text-primary-color font-semibold underline"
      onClick={onClick}
      value={value}
      aria-label="button"
    >
      {value}
    </button>
  )

  return (
    <>
      <div className="flex justify-between items-center py-4 px-5 bg-gray-300 rounded-lg text-xs w-full md:w-80">
        <div className="font-semibold whitespace-nowrap">Receive Updates</div>
        <div className="flex items-center">
          <div className="ml-10 mr-4">
            <DropdownField
              value={frequency}
              onChange={handleFrequencyChange}
              options={[
                { key: 'weekly', label: 'Weekly' },
                { key: 'monthly', label: 'Monthly' },
                { key: 'yearly', label: 'Yearly' },
              ]}
              name="frequencyUpdate"
              CustomButton={ReceiveUpdatesButton}
            />
          </div>

          <Switch
            checked={receiveUpdatesToggle}
            onChange={onToggleChange}
            className={[
              receiveUpdatesToggle && 'bg-primary-color',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-primary-color rounded-full cursor-pointer transition-colors ease-in-out duration-200',
            ].join(' ')}
          >
            <span
              aria-hidden="true"
              className={[
                receiveUpdatesToggle ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ].join(' ')}
            />
          </Switch>
        </div>
      </div>
    </>
  )
}
