import * as React from 'react'

function SvgVisitors(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g
        clipPath="url(#visitors_svg__clip0_231:41)"
        fill={props.color || 'var(--default-icon-color, #000)'}
      >
        <path d="M7.376 5.052C7.224 2.945 8.051 1.42 9.872.45c1.645-.877 4.187-.425 5.454.97.976 1.073 1.293 2.022 1.195 3.569-.099 1.55-.703 2.59-2.121 3.65-.967.722-3.887.687-4.933-.06-1.357-.968-1.982-2.02-2.09-3.526zM5.218 15.115c.89-1.96 2.464-3.265 4.592-3.803.479-.12 1.732-.17 2.786-.11 3.022.173 4.962 1.42 6.077 3.913.416.93.536 1.698.616 3.953.114 3.215-.14 4.377-1.031 4.715-.313.12-3.245.217-6.515.217H5.798l-.56-.597c-.538-.575-.559-.719-.551-3.865.006-2.896.067-3.401.53-4.423z" />
      </g>
      <defs>
        <clipPath id="visitors_svg__clip0_231:41">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgVisitors = React.memo(SvgVisitors)
export default MemoSvgVisitors
