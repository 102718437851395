import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Label,
  PrimaryNegativeButton,
  SecondaryButton,
  TextBody,
  TextBodyWarning,
  Title,
} from 'views/shared/components/ui-form'
import { Storage } from 'aws-amplify'
import { UserServices } from 'services/user'
import { useAgentListStore } from 'store/dashboard/user/useAgentListStore'
import { Spinner } from 'views/shared/components/icons'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { AuthContext } from 'views/authentication'
import { dashboardPaths } from 'views/dashboard/paths'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AlertInfo, alertsState } from 'views/alertBanner/state'
import { useInviteUserStore } from '../../../../store/dashboard/inviteUser/inviteUserStore'

export const ImportContactsPage = () => {
  const { userForm } = useInviteUserStore()
  const { agentListState, fetchAgentList } = useAgentListStore()
  const [file, setFile] = useState<File | undefined>()
  const [wrongFile, setWrongFile] = useState<File | undefined>()
  const [selectedAgentId, setSelectedAgentId] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [typeError, setTypeError] = useState<boolean>(false)
  const { isAgent, isAdmin, authUser } = useContext(AuthContext)
  const alerts = useRecoilValue(alertsState)
  const setAlerts = useSetRecoilState(alertsState)

  useEffect(() => {
    fetchAgentList()
  }, [agentListState])

  useEffect(() => {
    authUser && isAgent && setSelectedAgentId(authUser.sub)
    userForm.reset()
  }, [authUser, isAgent])

  const fileInput = React.createRef<HTMLInputElement>()
  const history = useHistory()

  const backToUserList = () => {
    const path = isAgent ? dashboardPaths.agent() : dashboardPaths.users()
    history.push(path)
  }

  const onProcessFile: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault()
    const uploadedFiles = e.target.files
    if (uploadedFiles === null) return
    try {
      if (uploadedFiles[0].type === 'text/csv') {
        setTypeError(false)
        setFile(uploadedFiles[0])
      } else {
        setTypeError(true)
        setWrongFile(uploadedFiles[0])
      }
    } catch (err) {
      console.warn(err)
    }
  }

  const onClickSendFile = () => {
    if (!file) return
    const SES_IDENTITY_VERIFICATION_ERROR = 'SES_IDENTITY_VERIFICATION_ERROR'
    const reader = new FileReader()
    reader.readAsText(file!)
    reader.onload = () => {
      Storage.put('uploads/contacts.csv', file, { contentType: 'text/csv' })
        .then(() => {
          setLoading(true)
          const inviteClients = selectedAgentId
            ? UserServices.sesIdentityVerification(selectedAgentId)
                .catch(() => {
                  throw new Error(SES_IDENTITY_VERIFICATION_ERROR)
                })
                .then(() => UserServices.importClientsForAgent(selectedAgentId))
            : UserServices.importClients()
          return inviteClients.then(() => {
            selectedAgentId && fileInput
            setAlerts([
              ...alerts,
              {
                id: alerts.length.toString(),
                type: 'success',
                message: 'Successfully imported.',
              } as AlertInfo,
            ])
            backToUserList()
          })
        })
        .catch((error) => {
          setAlerts([
            ...alerts,
            {
              id: alerts.length.toString(),
              type: 'error',
              message:
                error.message === SES_IDENTITY_VERIFICATION_ERROR
                  ? 'Import failed. Agent email is not verified by Amazon AWS. Please check email from Amazon Web Services.'
                  : 'Import failed.',
            } as AlertInfo,
          ])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  if (isAgent && authUser?.sub) {
    userForm.setField('agent', authUser.sub as string)
  }
  return (
    <>
      {loading ? (
        <div className="absolute h-full w-full opacity-50 bg-white flex align-middle justify-center mt-24">
          <Spinner style={{ fontSize: '80px' }} color="var(--accent-color)" />
        </div>
      ) : (
        <div className="h-full bg-white px-5 mb-20">
          <Title>Import Contacts</Title>

          <TextBody className="pt-4 mb-12">
            Import a contact list by uploading a csv file. You can also import contacts to a
            specific agent by selecting an agent from the drop-down.
          </TextBody>
          <div className="mb-5">
            <FieldsContainer>
              {isAdmin && (
                <InputViewField
                  label="Agent"
                  type="dropdown"
                  stateValidation={userForm as any}
                  field="agent"
                  edit
                  options={agentListState.agentList || []}
                  placeholder="Select"
                  value={userForm.state.agent}
                  onChange={(value) => setSelectedAgentId(value as string)}
                />
              )}
              <li className="p-5">
                <div className="flex items-center space-x-10">
                  <Label className="w-14">File</Label>
                  <button
                    className="text-primary-color font-semibold underline"
                    type="button"
                    onClick={() => fileInput.current?.click()}
                  >
                    Upload
                  </button>
                  {file && <span>{file.name}</span>}
                  <input onChange={onProcessFile} type="file" ref={fileInput} hidden />
                </div>
              </li>
              {typeError && (
                <div className="text-xs px-5">
                  <TextBodyWarning fontSize={14}>
                    Sorry, the file &quot;{wrongFile?.name}&quot; cannot be uploaded. Be sure to
                    select a csv file.
                  </TextBodyWarning>
                </div>
              )}
            </FieldsContainer>
          </div>
          <div className="w-60 mx-auto">
            <SecondaryButton onClick={onClickSendFile}>Send Invite</SecondaryButton>
            <PrimaryNegativeButton className="my-2" onClick={backToUserList}>
              Cancel
            </PrimaryNegativeButton>
          </div>
        </div>
      )}
    </>
  )
}
