import { KeyLabel } from 'types'

export enum UserRole {
  ADMIN = 'admin',
  AGENT = 'agent',
  CLIENT = 'client',
}
export enum PreferredContactMethod {
  EMAIL = 'email',
  SMS = 'SMS',
  PHONE = 'phone',
}

export const rolesKeyLabel: KeyLabel[] = [
  {
    key: `client`,
    label: 'Contact',
  },
  {
    key: 'agent',
    label: 'Agent',
  },
  {
    key: 'admin',
    label: 'Admin',
  },
]
export const contactPreferenceKeyLabel: KeyLabel[] = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'sms',
    label: 'SMS',
  },
]
export const homeStatusKeyLabel: KeyLabel[] = [
  {
    key: 'owner',
    label: 'Owner',
  },
  {
    key: 'lessee',
    label: 'Lessee',
  },
]
