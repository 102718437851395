/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect } from 'react'
import {
  atom,
  RecoilState,
  RecoilValueReadOnly,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import type { MultiSectionForm, Commons } from './useMultiSectionFormState.types'

module MultiSection {
  const formState: {
    [formName: string]: RecoilState<MultiSectionForm.State<any>>
  } = {}
  const formStateValidation: {
    [formName: string]: RecoilState<Commons.ValidationResultData[]>
  } = {}
  const formStatusState: {
    [formName: string]: RecoilValueReadOnly<MultiSectionForm.BooleanMap>
  } = {}
  const formStartedState: {
    [formName: string]: RecoilState<MultiSectionForm.BooleanMap>
  } = {}
  const formStartedAndStatusState: {
    [formName: string]: RecoilValueReadOnly<MultiSectionForm.BooleanMap>
  } = {}
  const getFormState = <TState>(formName: string, initial: MultiSectionForm.State<TState>) => {
    if (!formState[formName])
      formState[formName] = atom({
        key: formName,
        default: initial,
      })
    return formState[formName] as RecoilState<MultiSectionForm.State<TState>>
  }
  const getFormValidationState = (formName: string) => {
    const key = `${formName}Validation`
    if (!formStateValidation[key])
      formStateValidation[key] = atom<Commons.ValidationResultData[]>({
        key,
        default: [],
      })
    return formStateValidation[key] as RecoilState<Commons.ValidationResultData[]>
  }
  const getStatusState = <TState>(formName: string, initial: MultiSectionForm.State<TState>) => {
    const key = `${formName}Status`
    if (!formStatusState[key]) {
      formStatusState[key] = selector<MultiSectionForm.BooleanMap>({
        key,
        get: ({ get }) => {
          const errors = get(getFormValidationState(formName))
          const initialBooleanMap = {
            form: true,
          } as MultiSectionForm.BooleanMap<TState> | any
          const sectionNames = Object.keys(initial) as (keyof typeof initial)[]
          sectionNames.forEach((sectionName) => {
            initialBooleanMap[sectionName] = {
              section: true,
            }
            const fieldNames = Object.keys(
              initial[sectionName],
            ) as (keyof typeof initial[typeof sectionName])[]
            fieldNames.forEach((fieldName) => {
              initialBooleanMap[sectionName][fieldName] = true
            })
          })
          if (errors.length > 0) initialBooleanMap.form = false
          errors.forEach((error) => {
            if (error.type === 'section') {
              initialBooleanMap[error.sectionName].section = false
            } else if (error.type === 'field') {
              initialBooleanMap[error.sectionName].section = false
              initialBooleanMap[error.sectionName][error.fieldName] = false
            }
          })
          return initialBooleanMap
        },
      })
    }
    return formStatusState[key] as RecoilValueReadOnly<MultiSectionForm.BooleanMap<TState>>
  }

  const getFormStartedStateInitialValue = <TState>(initial: MultiSectionForm.State<TState>) => {
    const initialBooleanMap = {
      form: false,
    } as MultiSectionForm.BooleanMap<TState> | any
    const sectionNames = Object.keys(initial) as (keyof typeof initial)[]
    sectionNames.forEach((sectionName) => {
      initialBooleanMap[sectionName] = {
        section: false,
      }
      const fieldNames = Object.keys(
        initial[sectionName],
      ) as (keyof typeof initial[typeof sectionName])[]
      fieldNames.forEach((fieldName) => {
        initialBooleanMap[sectionName][fieldName] = false
      })
    })
    return initialBooleanMap
  }
  const getFormStartedState = <TState>(
    formName: string,
    initial: MultiSectionForm.State<TState>,
  ) => {
    const key = `${formName}Started`
    if (!formStartedState[key]) {
      formStartedState[key] = atom<MultiSectionForm.BooleanMap>({
        key,
        default: getFormStartedStateInitialValue(initial),
      })
    }
    return formStartedState[key] as RecoilState<MultiSectionForm.BooleanMap<TState>>
  }
  const getStartedAndStatusState = <TState>(
    formName: string,
    initial: MultiSectionForm.State<TState>,
  ) => {
    const key = `${formName}-StartedAndStatus`
    if (!formStartedAndStatusState[key]) {
      formStartedAndStatusState[key] = selector<MultiSectionForm.BooleanMap>({
        key,
        get: ({ get }) => {
          // const errors = get(getFormValidationState(formName))
          const status = get(getStatusState(formName, initial))
          const started = get(getFormStartedState(formName, initial))
          const result = {} as MultiSectionForm.BooleanMap<TState> | any
          const sectionNames = Object.keys(status) as (keyof MultiSectionForm.BooleanMap<TState>)[]
          sectionNames.forEach((sectionName) => {
            if (sectionName === 'form') {
              result.form = status.form && started.form
            } else {
              result[sectionName] = {
                section: false,
              } as any
              const fieldNames = Object.keys(
                status[sectionName],
              ) as (keyof typeof status[typeof sectionName])[]
              fieldNames.forEach((fieldName) => {
                result[sectionName][fieldName] =
                  status[sectionName][fieldName] || !started[sectionName][fieldName]
              })
            }
          })
          return result as MultiSectionForm.BooleanMap<TState>
        },
      })
    }
    return formStartedAndStatusState[key] as RecoilValueReadOnly<
      MultiSectionForm.BooleanMap<TState>
    >
  }

  export const useMultiSectionFormState = <TState>(setup: {
    formName: string
    initial: MultiSectionForm.State<TState>
    validation?: Partial<MultiSectionForm.Validation<TState>>
  }) => {
    // types
    type ValidationType = MultiSectionForm.Validation<TState>
    // states
    const { formName, initial, validation } = setup
    const [state, setState] = useRecoilState(getFormState(formName, initial))
    const [validationState, setValidationState] = useRecoilState(getFormValidationState(formName))
    const [started, setStarted] = useRecoilState(getFormStartedState(formName, initial))
    // started ans status
    const isValid = useRecoilValue(getStatusState(formName, initial))
    const isValidStarted = useRecoilValue(getStartedAndStatusState(formName, initial))

    // Render
    // Main Validation Function
    useEffect(() => {
      if (!validation) return
      const sectionNames = Object.keys(validation) as (keyof ValidationType)[]
      const results: Commons.ValidationResultData[] = []
      // const newStatus = { ...status }

      // validate form
      sectionNames.forEach((sectionName) => {
        if (!validation[sectionName]) return
        if (sectionName === 'form') {
          // Form Validation Function
          const formValidationFunc = validation.form

          if (!formValidationFunc) return
          let formValidationMessage = formValidationFunc({ form: state })
          if (!formValidationMessage || typeof formValidationMessage === 'boolean') return
          if (typeof formValidationMessage === 'string')
            formValidationMessage = [formValidationMessage]
          formValidationMessage.flat().forEach((formValidationResult) => {
            if (typeof formValidationResult === 'string')
              results.push({
                type: 'form',
                message: formValidationResult,
              })
          })
        } else {
          // Section Object
          const sectionValidations = validation[sectionName] as MultiSectionForm.ValidationSection<
            TState,
            typeof sectionName
          >
          if (!sectionValidations) return
          const fieldNames = Object.keys(sectionValidations) as (keyof typeof sectionValidations)[]
          if (!fieldNames) return
          fieldNames.forEach((fieldName) => {
            if (!sectionValidations[fieldName]) return
            if (fieldName === 'section') {
              // Section Validation Function
              const sectionValidationFunction = sectionValidations[
                fieldName
              ] as MultiSectionForm.SectionHandler<TState, typeof sectionName>
              let sectionValidationMessages = sectionValidationFunction({
                form: state,
                sectionName,
                section: state[sectionName],
              })
              if (!sectionValidationMessages || typeof sectionValidationMessages === 'boolean')
                return
              if (typeof sectionValidationMessages === 'string')
                sectionValidationMessages = [sectionValidationMessages]
              sectionValidationMessages.flat().forEach((sectionMessage) => {
                if (!sectionMessage || typeof sectionMessage === 'boolean') return
                results.push({
                  type: 'section',
                  message: sectionMessage,
                  sectionName: sectionName as string,
                })
              })
            } else {
              // Field Validation function
              const fieldValidationFunction = sectionValidations[
                fieldName
              ] as MultiSectionForm.FieldHandler<TState, typeof sectionName, typeof fieldName>

              let fieldValidationMessages = fieldValidationFunction({
                form: state,
                sectionName,
                section: state[sectionName],
                fieldName,
                value: state[sectionName][fieldName],
              })
              if (!fieldValidationMessages || typeof fieldValidationMessages === 'boolean') return
              if (typeof fieldValidationMessages === 'string')
                fieldValidationMessages = [fieldValidationMessages]
              fieldValidationMessages.flat().forEach((fieldMessage) => {
                if (typeof fieldMessage === 'string')
                  results.push({
                    type: 'field',
                    fieldName: fieldName as string,
                    message: fieldMessage,
                    sectionName: sectionName as string,
                  })
              })
            }
          })
        }
      })
      setValidationState([...results])
      // setStatus(newStatus)
    }, [state])

    // actions
    let tempState: any
    useEffect(() => {
      // update tempState to handel setField in the same iteration
      tempState = state
    }, [state])
    const setField = useCallback(
      async <SectionName extends keyof TState, FieldName extends keyof TState[SectionName]>(
        sectionName: SectionName,
        fieldName: FieldName,
        value: TState[SectionName][FieldName],
      ) => {
        const section = { ...((tempState || state)[sectionName] || {}) }
        section[fieldName] = value
        tempState = { ...(tempState || state) }
        tempState[sectionName] = { ...section }
        setState(tempState)
      },
      [state],
    )

    const setSection = useCallback(
      <SectionName extends keyof TState, Section extends TState[SectionName]>(
        sectionName: SectionName,
        sectionData: Partial<Section>,
      ) => {
        const section = { ...((tempState || state)[sectionName] || {}), ...sectionData }
        tempState = { ...(tempState || state) }
        tempState[sectionName] = { ...section }
        setState(tempState)
      },
      [state],
    )

    const reset = useCallback(() => {
      setState(initial)
      setValidationState([])
      setStarted(getFormStartedStateInitialValue(initial))
    }, [initial])

    const start = useCallback(
      <SectionName extends keyof TState, FieldName extends keyof TState[SectionName]>(
        sectionName?: SectionName,
        fieldName?: FieldName,
      ) => {
        const touchMap: (child: any) => any | boolean = (child) => {
          if (typeof child === 'boolean') {
            return true
          }
          const s: any = {}
          Object.keys(child).forEach((k) => {
            s[k] = touchMap(child[k])
          })
          return s
        }
        if (!sectionName && !fieldName) {
          setStarted(touchMap(started))
        } else if (sectionName && !fieldName) {
          const newStarted = {} as any
          newStarted[sectionName] = touchMap(started[sectionName])
          setStarted({ ...started, ...newStarted })
        } else {
          const newStarted = {} as any
          const newSection = { ...started[sectionName] } as any
          newSection[fieldName] = true
          newStarted[sectionName] = { ...started[sectionName], ...newSection }
          setStarted({ ...started, ...newStarted })
        }
      },
      [started],
    )

    return {
      state,
      setField,
      setSection,
      validationState,
      isValid,
      start,
      isStarted: started,
      isValidStarted,
      reset,
    }
  }
}

export const { useMultiSectionFormState } = MultiSection
