import * as React from 'react'

function SvgToggleMap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.077.011c-1.074.123-1.69.256-2.381.514a8.811 8.811 0 00-4.424 3.636C.286 5.749-.17 7.807.057 9.649c.365 2.958 2.475 7.042 5.974 11.563.827 1.07 1.89 2.35 2.135 2.57.322.29.72.29 1.043 0 .243-.22 1.307-1.5 2.134-2.57 3.15-4.069 5.168-7.765 5.806-10.633.165-.738.217-1.219.216-1.985-.002-2.254-.905-4.407-2.53-6.031C13.49 1.22 11.908.407 10.047.103c-.44-.071-1.652-.128-1.97-.092zM9.235 4.35a4.44 4.44 0 013.675 3.187c.125.446.173 1.344.097 1.83-.217 1.388-1.13 2.609-2.42 3.237-.76.371-1.537.51-2.334.42-.618-.07-.932-.16-1.466-.42-1.29-.628-2.202-1.85-2.42-3.238-.075-.485-.028-1.383.097-1.829.587-2.091 2.656-3.474 4.77-3.187z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgToggleMap = React.memo(SvgToggleMap)
export default MemoSvgToggleMap
