import React from 'react'
import { TextBody } from 'views/shared/components/ui-form'

export type PartialDataAlertType = React.HTMLProps<HTMLDivElement>

export const PartialDataAlert = (props: PartialDataAlertType) => {
  const { className } = props
  const newProps = {
    ...props,
    className: undefined,
  }
  return (
    <div
      {...newProps}
      className={['bg-alpha-color p-6 text-sm leading-5 mb-5 rounded-lg', className].join(' ')}
    >
      <TextBody className="mb-2">
        <span className="font-semibold">
          <span className="bg-warning-color text-on-warning-color px-3 py-0.5 leading-6 rounded-lg mr-2">
            Month to date
          </span>
        </span>
      </TextBody>
      <TextBody className="text-default-color">
        Below is the data for this month so far. Full report will be available at the end of the
        month.
      </TextBody>
    </div>
  )
}
