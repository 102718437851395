import { RoutesType } from 'types'

import { routes as agentRoutes } from 'views/agents'
import { routes as authenticationRoutes } from 'views/authentication'
import { routes as dashboardRoutes } from 'views/dashboard'
import { routes as followsRoutes } from 'views/follows'
import { routes as homeValuationRoutes } from 'views/homeValuation'
import { routes as mapRoutes } from 'views/map'
import { routes as soldReportRoutes } from 'views/soldReport'
import { routes as labsRoutes } from 'views/labs'
import { routes as propertyDetailsRoutes } from 'views/propertyDetails'
import { routes as valuationAnnualReportRoutes } from 'views/dashboard/pages/valuations/ValuationAnnualReport/routes'
import { routes as emailRedirectRoutes } from 'views/emailredirect'
import { routes as footerRoutes } from './views/footer'
import { routes as unsubscribeRoutes } from './views/unsubscribe/routes'
import { routes as landingPageRoutes } from './views/landing-page/routes'

export const routes: RoutesType = {
  ...agentRoutes,
  ...authenticationRoutes,
  ...dashboardRoutes,
  ...followsRoutes,
  ...homeValuationRoutes,
  ...mapRoutes,
  ...labsRoutes,
  ...soldReportRoutes,
  ...footerRoutes,
  ...unsubscribeRoutes,
  ...propertyDetailsRoutes,
  ...landingPageRoutes,
  ...valuationAnnualReportRoutes,
  ...emailRedirectRoutes,
  homepage: {
    path: '/',
    exact: true,
    component: landingPageRoutes.landingPage.component,
  },
}
