/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react'
import UserTypes from 'services/user/UserTypes'
import styled from 'styled-components'
import { describeUserFeed } from 'utilities/helpers/userFeedHelper'

type UserFeedAggregationType = {
  userFeedAggregation: UserTypes.UserFeedAggregation
  index: number
}

const Item = styled.li`
  border-top: 1px solid var(--alpha-color);
  padding: 12px 0px;
  cursor: pointer;
`
const toUserFeedTitle = (feedType: string): string =>
  describeUserFeed({ feedType, time: 0, uid: '' } as UserTypes.UserFeed, []).title

export const UserFeedAggregation = ({ userFeedAggregation, index }: UserFeedAggregationType) => (
  <>
    <ul className="divide-y" key={`ufa_${index}`}>
      <Item>
        <div className="flex justify-between space-x-10">
          <p className="text-primary-color font-semibold">
            {toUserFeedTitle(userFeedAggregation.feedType)}
          </p>{' '}
          <p className="font-normal">{userFeedAggregation.count}</p>
        </div>
      </Item>
    </ul>
  </>
)
