export enum ErrMsgColor {
  black = 'text-secondary-color',
  red = 'text-warning-color',
  green = 'text-accent-color',
}

interface PasswordErrorMsgsTypes {
  required: string
  minThreeCharsMet: string
  minEightCharsMet: string
  minThreeConditionsMet: string
  passwordsNotMatch: string
  invalidPostalZipCode: string
  invalidPhoneNumber: string
  invalidZipCode: string
  invalidPostalCode: string
  alreadyExists: string
}

export const PasswordErrorMsgs: PasswordErrorMsgsTypes = {
  required: 'Required',
  minThreeCharsMet: 'Minimum of 3 characters.',
  minEightCharsMet: 'Passwords must consist of at lease 8 characters.',
  minThreeConditionsMet:
    'Must have a minimum of 8 characters and  contain at least one number, one uppercase and lowercase letter and one special character, please do not use any of this characters ~ ` \' " or whitespace.',
  passwordsNotMatch: 'Passwords does not match',
  invalidPostalZipCode: 'Invalid postal/zip code',
  invalidPhoneNumber: 'Invalid phone number',
  invalidZipCode: 'Invalid zip code',
  invalidPostalCode: 'Invalid postal code',
  alreadyExists: 'Already exists',
}
