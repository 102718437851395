import React, { ReactElement } from 'react'
import { RouteLink, Title } from 'views/shared/components/ui-form'

export const MyAgent = (): ReactElement => (
  <>
    <div className="text-center mt-10">
      <Title className="pb-6">Coming Soon</Title>
      <RouteLink to="/profile">Go back to your profile</RouteLink>
    </div>
  </>
)
