import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin: 10px;
`
export const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 40px;
  & > .pages {
    font-size: 16px;
    font-weight: bold;
    height: 24px;
  }
  & > .total {
    font-size: 12px;
    font-weight: normal;
    height: 16px;
  }
`
export const ArrowButton = styled.button`
  display: inline-block;
  padding: 8px 16px;
  height: 40px;
  &.--disabled {
    opacity: 0.2;
    cursor: default;
  }
`
