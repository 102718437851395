import { breakPoints } from '../ui-layout/breakPoints'
import { breakPointKey } from './const'
import {
  ModalLayerOffset,
  ModalLayerPropsBoxStyle,
  ModalLayerPropsPosition,
  ResponsiveBoxStyle,
} from './types'

export module ModalLayerHelper {
  export const getInitialOffset = () => ({ top: 0, left: 0, width: 0 })
  export const getScrollTop = () => window.pageYOffset || document.documentElement.scrollTop
  export const getScrollLeft = () => window.pageXOffset || document.documentElement.scrollLeft
  export const getLayerOffset = (
    el: HTMLDivElement | null,
    position?: ModalLayerPropsPosition,
  ): ModalLayerOffset => {
    if (el === null) return getInitialOffset()
    const bcRect = el.getBoundingClientRect()
    const scrollLeft = getScrollLeft()
    const scrollTop = getScrollTop()
    if (position === 'fixed') {
      return {
        top: bcRect.top,
        left: bcRect.left,
        width: bcRect.width,
      }
    }
    return {
      top: bcRect.top + scrollTop,
      left: bcRect.left + scrollLeft,
      width: bcRect.width,
    }
  }
  export const getResponsiveKey = () => {
    if (document.body.clientWidth > breakPoints[2]) return breakPointKey.large
    if (document.body.clientWidth > breakPoints[1]) return breakPointKey.medium
    return breakPointKey.small
  }
  export const getFullBoxStyle = (responsiveBoxStyle: ResponsiveBoxStyle) => ({
    small: responsiveBoxStyle.small,
    medium: responsiveBoxStyle.medium || responsiveBoxStyle.small,
    large: responsiveBoxStyle.large || responsiveBoxStyle.medium || responsiveBoxStyle.small,
  })
  export const getBoxStyle = (responsiveBoxStyle: ResponsiveBoxStyle) =>
    (getFullBoxStyle(responsiveBoxStyle) as { [key: string]: ModalLayerPropsBoxStyle })[
      getResponsiveKey()
    ]
}
