import { HomeValuationAnnualReport } from 'types'
import { PdfHelper } from 'utilities/helpers/PdfHelper'
import { urlHelper } from 'utilities/helpers/urlHelper'

export type FooterProps = {
  firstName: string
  lastName: string
  phone: string
  website: string
  email: string
  logo: string
}
let footerCache: string | undefined
export const footer = async (data: HomeValuationAnnualReport.Valuation) => {
  if (footerCache) return footerCache
  const { firstName, lastName, phone, email, website, logo } = data.agent
  const img = {
    logo: await PdfHelper.getImage(logo ? `logos/${logo}` : '/assets/castl.svg'),
  }
  const template = /* svg */ `
    <text xml:space="preserve" style="white-space: pre" font-family="Montserrat" letter-spacing="0em" font-size="7" fill="#62727A">
    <tspan x="25" y="719.51">Prepared with care by</tspan>
  </text>
  <text xml:space="preserve" style="white-space: pre" font-family="Montserrat" letter-spacing="0em">
    <tspan x="25" y="732.585" fill="black" font-size="10" font-weight="bold">${firstName} ${lastName}</tspan>
    <tspan y="732.51" fill="black" font-size="7">${phone}</tspan>
    <tspan y="732.51" fill="black" font-size="7">${urlHelper.formateURL(website)}</tspan>
    <tspan y="732.51" fill="#417A9F" font-size="7">${email}</tspan>
  </text>
  ${img.logo(500, 694, 40, 40)}
    `
  footerCache = template
  return template
}
