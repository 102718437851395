// Price Ranges for Sale
export const forSaleMarks = {
  0: {
    base: 0,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: 'Min',
  },
  10: {
    base: 200000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '$200k',
  },
  20: {
    base: 500000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '$500k',
  },
  30: {
    base: 1000000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '$1M',
  },
  40: {
    base: 2000000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '$2M',
  },
  50: {
    base: 4000000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '$4M',
  },
  60: {
    base: 20000000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: 'Max', // '$20M'
  },
}

// Price Ranges for Lease:
export const forLeaseMarks = {
  0: {
    base: 0,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: 'Min',
  },
  10: {
    base: 1000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '1k',
  },
  20: {
    base: 2500,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '2.5k',
  },
  30: {
    base: 5000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '5k',
  },
  40: {
    base: 10000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '10K', // '10k'
  },
  50: {
    base: 15000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: '15k', // '10k'
  },
  60: {
    base: 25000,
    style: {
      color: 'var(--default-color)',
      fontSize: '14px',
      paddingTop: '4px',
    },
    label: 'Max', // '25k'
  },
}

export const markValueConvert = (value: number | undefined, marks: any) => {
  if (value === undefined) return undefined
  if (value === 0) return 0
  if (value >= 20000000) return 60
  let r: number | undefined
  Object.keys(marks).forEach((key) => {
    const { base } = marks[key]
    if (value <= +key && r === undefined) {
      const baseDiff = base - marks[+key - 10].base
      const unit = baseDiff / 10
      r = base - unit * (+key - value)
    }
  })
  if (Number.isNaN(r) || r === undefined) return undefined
  return Math.round(r)
}
export const invertMarkValueConvert = (value: number, marks: any) => {
  if (value === 0) return 0
  if (value >= 20000000) return 60
  let r: number | undefined
  Object.keys(marks).forEach((key) => {
    const { base } = marks[key]
    if (value <= base && r === undefined) {
      const baseDiff = base - marks[+key - 10].base
      const unit = baseDiff / 10
      r = (value - marks[+key - 10].base) / unit + +key - 10
    }
  })
  if (Number.isNaN(r) || r === undefined) return 0
  return r
}

export const marksMax = (marks: any) => (Object.keys(marks).length - 1) * 10
