import React, { PropsWithChildren, ReactChild } from 'react'
import styled from 'styled-components'
import { HomeValuationsContainer } from './styles'

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 132px);
    min-height: calc(100vh - 250px);
    overflow-y: auto;
  }
`

export type HomeValuationsProps = PropsWithChildren<{
  content?: ReactChild
}>

export const HomeValuationsLayout = ({ children, content }: HomeValuationsProps) => (
  <>
    <HomeValuationsContainer className="w-full p-5 md:p-10">
      <div className="max-w-screen-2xl mx-auto lg:flex md:justify-evenly space-y-5 md:space-y-0">
        <div className="max-w-lg md:py-5 xl:py-12">{content}</div>
        <Wrapper className="lg:max-w-3xl bg-white w-full md:rounded-lg md:p-5 xl:p-12 scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
          {children}
        </Wrapper>
      </div>
    </HomeValuationsContainer>
  </>
)
