import styled from 'styled-components'

export const H3 = styled.h3<{
  maxWidth?: number
}>`
  color: var(--secondary-color);
  font-weight: 600;
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : null)}px;
`
export const Item = styled.li`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
export const ItemLabel = styled.li`
  font-size: 24px;
  color: var(--accent-color);
  font-weight: 600;
  text-align: center;
`
export const A = styled.a`
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding: 14px;
  height: 48px;
  border-radius: 8px;
  text-transform: capitalize;
  &:hover {
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
  }
`
