import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { passwordErrorMessageBlock } from 'views/shared/components/validations/FormValidationHelper'
import { PasswordErrorMsgs } from 'constants/passwordErrorMessageConstants'
import { paths } from 'paths'

import {
  InputField,
  PrimaryButton,
  RouteLink,
  TextBody,
  Title,
} from 'views/shared/components/ui-form'
import { Validate } from 'views/shared/components/icons'
import { AuthException, AuthServices } from 'services/auth'
import { SignUpData } from '../../types'
import { AuthContext } from '../../contexts'
import { AuthLayout } from '../../components'
import { SideContent } from '../components'

export const SignUp = () => {
  const { authUser, setAuthUserContext } = useContext(AuthContext)
  const history = useHistory()
  const [responseErrors, setResponseErrors] = useState<string[] | null>(null)

  const onSubmit = async (
    { email, name, password }: SignUpData,
    setSubmitting: (value: boolean) => void,
  ) => {
    try {
      setSubmitting(true)
      AuthServices.setLocalStorageEmail(email)
      const response = await AuthServices.signUp({
        name,
        email,
        password,
      })
      setAuthUserContext(response)
      if (!response.emailVerified) {
        // if email is not verified
        history.push(paths.signUpVerification())
      } else {
        history.push(paths.homepage())
      }
    } catch (error) {
      setResponseErrors([(error as AuthException).message])
    } finally {
      // SGCSTLR2-182
      setTimeout(() => {
        setSubmitting(false)
      }, 1000)
    }
  }

  const formik = useFormik({
    initialValues: { fullName: '', email: '', password: '', passwordConfirmation: '' },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(
        {
          email: values.email,
          name: values.fullName,
          password: values.password,
        },
        setSubmitting,
      )
    },
    validationSchema: Yup.object().shape({
      // fullName: Yup.string().required(PasswordErrorMsgs.required),
      email: Yup.string().email().required(PasswordErrorMsgs.required),
      password: Yup.string()
        .required(PasswordErrorMsgs.required)
        .min(8, PasswordErrorMsgs.minEightCharsMet)
        .matches(
          /^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[@$!%*?&]\S*)[A-Za-z\d@$!%*?&]{8,}$/,
          PasswordErrorMsgs.minThreeConditionsMet,
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(PasswordErrorMsgs.required),
    }),
  })
  useEffect(() => {
    if (authUser) {
      history.replace(paths.homepage())
    }
    return () => {}
  }, [authUser, history])

  return (
    <>
      <AuthLayout sideContent={<SideContent />}>
        <div className="space-y-5 mb-10">
          <Title>
            Create your
            <br /> account
          </Title>
          <TextBody>
            TRREB (Toronto Regional Real Estate Board) requires citizens to create accounts in order
            to see sold prices.
          </TextBody>
          <TextBody>
            Already registered?{' '}
            <RouteLink className="font-bold underline" to="/sign-in">
              Sign in
            </RouteLink>
          </TextBody>
        </div>
        <div className="space-y-10">
          <form className="space-y-10" onSubmit={formik.handleSubmit}>
            <div className="space-y-5">
              {/* <InputField name="fullName" label="Full Name" type="text" formik={formik} /> */}
              <InputField name="email" label="Email" type="text" formik={formik} />
              <InputField
                name="password"
                label="Password"
                type="password"
                formik={formik}
                customErrorMessage={passwordErrorMessageBlock(
                  formik.touched.password,
                  formik.errors.password,
                )}
              />
              <InputField
                name="passwordConfirmation"
                label="Confirm Password"
                type="password"
                formik={formik}
              />
              {responseErrors &&
                responseErrors.map((message) => (
                  <div key={message} className="mt-1 text-warning-color text-sm">
                    {message}
                  </div>
                ))}
            </div>
            <PrimaryButton type="submit" disabled={formik.isSubmitting}>
              Sign up
            </PrimaryButton>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}
