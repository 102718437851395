import { Loader } from '@googlemaps/js-api-loader'
import debounce from 'debounce'
import { Config } from 'config'
import { FollowingType } from 'services/following'
import { Neighbourhood, NeighbourhoodMapDisplay } from 'types'
import { convertToNeighbourhoodDisplay } from './neighbourhoodHelpers'
import { neighbourhoodMapOptions } from './neighbourhoodMapOptions'

export class MapController {
  private loader = new Loader({
    apiKey: Config.getGMapKey(),
    version: 'weekly',
    libraries: ['places'],
  })

  private map: google.maps.Map | null = null

  private mapContainer: HTMLDivElement = document.createElement('div')

  private mapContainerParent: HTMLElement | null = null

  private onMapLoaded: (() => void) | null = null

  private neighbourhoods: NeighbourhoodMapDisplay[] = []

  private following: FollowingType = {
    neighbourhoods: [],
    properties: [],
  }

  private static singleton: MapController

  public static instance(): MapController {
    if (!MapController.singleton) {
      MapController.singleton = new MapController()
    }
    return MapController.singleton as MapController
  }

  private constructor() {
    this.loader.load().then(() => {
      // construct map
      this.map = new google.maps.Map(this.mapContainer, neighbourhoodMapOptions)
      // geolocation button
      const locationButton = document.createElement('button')
      locationButton.innerHTML = '<img src="/assets/geolocation-button.svg" alt="Location" />'
      locationButton.classList.add('geolocation-button')
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton)
      locationButton.addEventListener('click', () => {
        this.onGeolocationCenterCallback && this.onGeolocationCenterCallback()
      })
      // Re-center button
      const centerButton = document.createElement('button')
      centerButton.innerHTML = '<button>Centre Map</button>'
      centerButton.classList.add('center-button')
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerButton)
      centerButton.addEventListener('click', () => {
        neighbourhoodMapOptions.center && this.centerMapTo(neighbourhoodMapOptions.center)
      })
      // bound change
      this.map.addListener('bounds_changed', debounce(this.boundsChanged.bind(this)))
      // setMap for existing items
      this.bindMapItems()
      this.mapLoaded()
      this.render()
    })
  }

  public appendTo(node: HTMLElement) {
    this.mapContainerParent = node
    if (this.map) this.appendContainer()
    return this
  }

  private onLabelClickCallback: ((neighbourhood: NeighbourhoodMapDisplay) => void) | null = null

  public onLabelClick(callback: (neighbourhood: NeighbourhoodMapDisplay) => void) {
    this.onLabelClickCallback = callback
    return this
  }

  private onPolygonClickCallback: ((neighbourhood: NeighbourhoodMapDisplay) => void) | null = null

  public onPolygonClick(callback: (neighbourhood: NeighbourhoodMapDisplay) => void) {
    this.onPolygonClickCallback = callback
    return this
  }

  private onGeolocationCenterCallback: (() => void) | null = null

  public onGeolocationCenter(callback: () => void) {
    this.onGeolocationCenterCallback = callback
    return this
  }

  public centerMapTo(position: google.maps.LatLng | google.maps.LatLngLiteral) {
    if (this.map) {
      this.map.setCenter(position)
      this.map.setZoom(14)
    }
    return this
  }

  private appendContainer() {
    if (!this.mapContainerParent) return
    // append mapContainer to parent
    while (this.mapContainerParent.firstChild) {
      this.mapContainerParent.removeChild(this.mapContainerParent.firstChild)
    }
    this.mapContainerParent.appendChild(this.mapContainer)
  }

  private mapLoaded() {
    this.appendContainer()
  }

  private isFollowing(id: string) {
    if (this.following.neighbourhoods.findIndex((itemId) => itemId === id) > -1) {
      return true
    }
    return false
  }

  public setNeighbourhoods(neighbourhoods: Neighbourhood[]) {
    // remove all neighboards before add new
    this.neighbourhoods.forEach((n) => {
      n.polygon?.setMap(null)
      n.label?.setMap(null)
    })
    // set new neighbourhoods
    this.loader.load().then(() => {
      this.neighbourhoods = neighbourhoods.map((n) => {
        const r = convertToNeighbourhoodDisplay(n, this.isFollowing(n.id))
        return r
      })
      this.bindMapItems()
      this.render()
    })
    return this
  }

  public setFollowing(following: FollowingType) {
    this.following = {
      neighbourhoods: [...following.neighbourhoods],
      properties: [...following.properties],
    }
    this.render()
    return this
  }

  private bindMapItems() {
    this.neighbourhoods.forEach((n) => {
      n.label?.addListener('click', () => {
        this.onLabelClickCallback && this.onLabelClickCallback(n)
      })
      n.polygon?.addListener('click', () => {
        this.onPolygonClickCallback && this.onPolygonClickCallback(n)
      })
      if (!n.polygon?.getMap()) n.polygon?.setMap(this.map)
      if (!n.label?.getMap()) n.label?.setMap(this.map)
    })
  }

  private boundsChanged() {
    this.render()
  }

  public getNeighbourhoods() {
    return this.neighbourhoods
  }

  private render() {
    if (!this.map) return
    const bounds = this.map.getBounds()
    const zoom = this.map.getZoom()
    if (!bounds || !zoom) return
    const minLabelZoom = 14
    const minPolygonZoom = 12
    this.neighbourhoods.forEach((n) => {
      if (n.polygonCenter) {
        if (bounds?.contains(n.polygonCenter)) {
          n.polygon?.setVisible(zoom >= minPolygonZoom)
          n.label?.setVisible(zoom >= minLabelZoom)
          const l = n.label?.getLabel()
          if (l && l.className) {
            const isFollowing = this.isFollowing(n.id)
            if (
              (l.className.indexOf('--favorite') > 0 && isFollowing === false) ||
              (l.className.indexOf('--favorite') === -1 && isFollowing === true)
            ) {
              l.className = `neighbourhood-marker ${isFollowing ? '--favorite' : ''}`
              // the code bellow forces render update when following state changes
              n.label?.setVisible(false)
              setTimeout(() => {
                n.label?.setVisible(true)
              }, 10)
            }
          }
        } else {
          n.polygon?.setVisible(false)
          n.label?.setVisible(false)
        }
      }
    })
  }
}
