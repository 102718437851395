export const required = (message: string) => (value: string | number | null | undefined) => {
  if (value === null || value === undefined || value.toString() === '') {
    return message
  }
  return true
}
export const minAmount =
  (amount: number, message: string) => (value: number | null | undefined) => {
    if (value === null || value === undefined) return true
    if (value < amount) return message
    return true
  }
export const maxAmount =
  (amount: number, message: string) => (value: number | null | undefined) => {
    if (value === null || value === undefined) return true
    if (value > amount) return message
    return true
  }
export const isCAPhone = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  const digits = value.match(/\d/g)
  if (digits === null || digits.length !== 10) {
    return message
  }
  return true
}
export const isCAPostalCode = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  if (!/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$]+$/.test(value)) {
    return message
  }
  return true
}
export const isEmail = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return message
  }
  return true
}
export const isDateMMddYYYY = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  const digits = value.match(/\d/g)
  if (digits === null || digits.length < 8 || digits.length > 10) {
    return message
  }
  const pieces = value.split('/').map((p) => parseInt(p, 10))
  const newDate = new Date(pieces[2], pieces[0] - 1, pieces[1])
  if (Number.isNaN(newDate.getTime())) {
    return message
  }
  if (!/^(0[1-9]|1[012])[/ /.](0[1-9]|[12][0-9]|3[01])[/ /.](19|20)\d\d$/.test(value)) {
    return message
  }
  return true
}
export const isFutureDate = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  const today = new Date()
  const valueToDate = new Date(value)
  if (valueToDate > today) {
    return message
  }
  return true
}

export const maxLength =
  (length: number, message: string) => (value: string | null | undefined) => {
    if (value === null || value === undefined || value === '') return true
    if (value.length > length) return message
    return true
  }
export const minLenth = (length: number, message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  if (value.length < length) return message
  return true
}
export const isUrl = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  if (
    !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value,
    )
  ) {
    return message
  }
  return true
}

// 'Must have a minimum of 8 characters and  contain at least one number, one uppercase and lowercase letter and one special character, please do not use any of this characters ~ ` ' " or whitespace.',
export const isPassword = (message: string) => (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') return true
  if (
    !/^(?=.*[a-z]\S*)(?=.*[A-Z]\S*)(?=.*\d\S*)(?=.*[\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]\S*)[A-Za-z\d\\!@#$%/&*()_\-+={}|;:.,?^<>[\]]{8,}$/i.test(
      value,
    )
  ) {
    return message
  }
  return true
}
