import { Loader } from '@googlemaps/js-api-loader'
import { Config } from 'config'
import { FollowingType } from 'services/following'
import { Neighbourhood, NeighbourhoodMapDisplay } from 'types'
import { Location } from 'types/Property'
import { neighbourhoodMapOptions } from '../Map/neighbourhoodMapOptions'
import { convertToNeighbourhoodDisplay } from '../Map/neighbourhoodHelpers'
import { miniMapMarkerIcon } from '../SearchMap/markerIcons'

export class MiniMapController {
  private loader = new Loader({
    apiKey: Config.getGMapKey(),
    version: 'weekly',
    libraries: ['places'],
  })

  private map: google.maps.Map | null = null

  private mapContainer: HTMLDivElement = document.createElement('div')

  private mapContainerParent: HTMLElement | null = null

  private gMarker: google.maps.Marker | null = null

  private onMapLoaded: (() => void) | null = null

  private neighbourhoods: NeighbourhoodMapDisplay[] = []

  private following: FollowingType = {
    neighbourhoods: [],
    properties: [],
  }

  private static singleton: MiniMapController

  public static instance(): MiniMapController {
    if (!MiniMapController.singleton) {
      MiniMapController.singleton = new MiniMapController()
    }
    return MiniMapController.singleton as MiniMapController
  }

  private constructor() {
    this.loader.load().then(() => {
      // construct map
      this.map = new google.maps.Map(this.mapContainer, neighbourhoodMapOptions)
      this.mapLoaded()
    })
  }

  public appendTo(node: HTMLElement) {
    this.mapContainerParent = node
    if (this.map) this.appendContainer()
    return this
  }

  private appendContainer() {
    if (!this.mapContainerParent) return
    // append mapContainer to parent
    while (this.mapContainerParent.firstChild) {
      this.mapContainerParent.removeChild(this.mapContainerParent.firstChild)
    }
    this.mapContainerParent.appendChild(this.mapContainer)
  }

  private mapLoaded() {
    this.appendContainer()
  }

  private isFollowing(id: string) {
    if (this.following.neighbourhoods.findIndex((itemId) => itemId === id) > -1) {
      return true
    }
    return false
  }

  public setNeighbourhoods(neighbourhoods: Neighbourhood[]) {
    // remove all neighboards before add new
    this.neighbourhoods.forEach((n) => {
      n.polygon?.setMap(null)
    })
    // set new neighbourhoods
    this.loader.load().then(() => {
      this.neighbourhoods = neighbourhoods.map((n) => {
        const r = convertToNeighbourhoodDisplay(n, this.isFollowing(n.id))
        return r
      })
      this.bindMapItems()
    })
    return this
  }

  private bindMapItems() {
    this.neighbourhoods.forEach((n) => {
      if (!n.polygon?.getMap()) n.polygon?.setMap(this.map)
    })
  }

  public getNeighbourhoods() {
    return this.neighbourhoods
  }

  public centerMapTo(position: google.maps.LatLng | google.maps.LatLngLiteral) {
    if (this.map) {
      this.map.setCenter(position)
      this.map.setZoom(14)
    }
    return this
  }

  private getPosition(loc: Location) {
    return { lat: loc.lat, lng: loc.lon }
  }

  public setMarker(location: Location) {
    this.loader.load().then(() => {
      this.gMarker = new google.maps.Marker({
        position: this.getPosition(location),
        // label: this.createLabel(marker),
        icon: {
          url: `data:image/svg+xml;base64,${miniMapMarkerIcon()}`,
          // scaledSize: new google.maps.Size(55, 55),
        },
        map: this.map,
      })
      this.centerMapTo(this.getPosition(location))
    })
  }

  public removeMarker() {
    this.gMarker?.setMap(null)
  }
}
