/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_mobile_analytics_app_id": "323ab58d5c274b589b6f413874be568a",
    "aws_mobile_analytics_app_region": "ca-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "323ab58d5c274b589b6f413874be568a",
            "region": "ca-central-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "homeValue",
            "endpoint": "https://1jmldlody7.execute-api.ca-central-1.amazonaws.com/master",
            "region": "ca-central-1"
        },
        {
            "name": "property",
            "endpoint": "https://9g3gm2rmug.execute-api.ca-central-1.amazonaws.com/master",
            "region": "ca-central-1"
        },
        {
            "name": "user",
            "endpoint": "https://94gfs0v9m8.execute-api.ca-central-1.amazonaws.com/master",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ca-central-1:c2e3f7d5-504c-4e08-9473-6ed7a71fdc90",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_b5XOSXyKZ",
    "aws_user_pools_web_client_id": "6piaddhhk71ioratr8qq46umh0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "addressBook-master",
            "region": "ca-central-1"
        },
        {
            "tableName": "analy-master",
            "region": "ca-central-1"
        },
        {
            "tableName": "homeValue-master",
            "region": "ca-central-1"
        },
        {
            "tableName": "listings-master",
            "region": "ca-central-1"
        },
        {
            "tableName": "user-master",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "sage-neighbourhoods95949-master",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
