import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Neighbourhood } from 'types'
import { DropdownField, Title } from 'views/shared/components/ui-form'
import { AuthContext } from 'views/authentication'
import { useNeighbourhoods } from 'views/map/hooks'
import { Spinner } from 'views/shared/components/spinner'
import { buildBrowseList } from './buildBrowseList'
import { NeighbourhoodListSectionHeader } from './NeighbourhoodListSectionHeader'
import { NeighbourhoodListSection } from './NeighbourhoodListSection'
import { FollowingNeighbourhoodList } from './FollowingNeighbourhoodList'

const defaultCommunity = 'Toronto'

export const NeighbourhoodList = (): ReactElement => {
  const { neighbourhoods, communities, isLoading: isNeighbourhoodLoading } = useNeighbourhoods()
  const [selectedCommunity, setSelectedCommunity] = useState<string>('')
  const [sortedCommunities, setSortedCommunities] = useState<string[]>(communities)
  const [column1, setColumn1] = useState<Record<string, Neighbourhood[]>>({})
  const [column2, setColumn2] = useState<Record<string, Neighbourhood[]>>({})
  const [column3, setColumn3] = useState<Record<string, Neighbourhood[]>>({})
  const history = useHistory()

  useEffect(() => {
    const sortCommunities = communities.reduce((acc: any, element: string) => {
      if (element === defaultCommunity) return [element, ...acc]
      return [...acc, element]
    }, [])
    setSortedCommunities(sortCommunities)
    setSelectedCommunity((s) =>
      communities?.length && communities.includes(defaultCommunity) ? defaultCommunity : s,
    )
  }, [communities])

  // filter by selected Community
  useEffect(() => {
    const filterNeighbourhoods = async () => {
      const filteredList =
        (await neighbourhoods?.filter(({ community }) => community === selectedCommunity)) || []
      const { column1: col1, column2: col2, column3: col3 } = await buildBrowseList(filteredList)
      setColumn1(col1)
      setColumn2(col2)
      setColumn3(col3)
    }
    filterNeighbourhoods()
  }, [selectedCommunity, neighbourhoods])

  const redirectToSoldReport = ({ id }: Neighbourhood) => history.push(`/sold-report/${id}`)

  const { authUser } = useContext(AuthContext)

  return (
    <>
      <div className="px-5 lg:px-20 py-10 max-w-screen-2xl mx-auto mb-20">
        <div>
          <Title className="hidden sm:block">Neighbourhood Sold Reports</Title>
          <Title fontSize={32} className="block sm:hidden">
            Neighbourhood Sold Reports
          </Title>
        </div>
        {authUser ? <FollowingNeighbourhoodList /> : <></>}

        <div className="mt-10">
          <div className="text-2xl text-default-color mb-5">
            Select a neighbourhood below to access it&#39;s monthly Sold Report.
          </div>
          <ul className="hidden lg:flex space-x-3">
            {sortedCommunities.length &&
              sortedCommunities.map((community) => (
                <li key={community}>
                  <button
                    type="button"
                    onClick={() => setSelectedCommunity(community)}
                    className={[
                      `${
                        selectedCommunity && selectedCommunity === community
                          ? 'bg-default-color text-on-default-color font-semibold border border-default-color'
                          : 'text-primary-color border border-primary-soft-color'
                      }`,
                      ' rounded-lg px-8 py-3 text-base leading-4 h-12',
                    ].join(' ')}
                  >
                    {community}
                  </button>
                </li>
              ))}
          </ul>
          <div className="block lg:hidden max-w-xs py-4">
            <DropdownField
              value={selectedCommunity}
              onChange={(key) => setSelectedCommunity(key)}
              name=""
              options={communities.map((item) => ({ key: item, label: item }))}
            />
          </div>
        </div>

        {isNeighbourhoodLoading && (
          <div className="flex flex-col justify-center h-96">
            <Spinner className="h-32" alt="Loading" />
          </div>
        )}
        {isNeighbourhoodLoading || (
          <div className="grid md:grid-cols-3 mb-24">
            <div className="">
              {Object.keys(column1).map((letter) => (
                <div key={`col1-${letter}`}>
                  <NeighbourhoodListSectionHeader title={letter} />
                  <NeighbourhoodListSection list={column1[letter]} onClick={redirectToSoldReport} />
                </div>
              ))}
            </div>
            <div className="">
              {Object.keys(column2).map((letter) => (
                <div key={`col2-${letter}`}>
                  <NeighbourhoodListSectionHeader title={letter} />
                  <NeighbourhoodListSection list={column2[letter]} onClick={redirectToSoldReport} />
                </div>
              ))}
            </div>
            <div className="">
              {Object.keys(column3).map((letter) => (
                <div key={`col3-${letter}`}>
                  <NeighbourhoodListSectionHeader title={letter} />
                  <NeighbourhoodListSection list={column3[letter]} onClick={redirectToSoldReport} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
