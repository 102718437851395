import styled from 'styled-components'

export const AlertBannerContainer = styled.div`
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 20px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 8px 8px;
  padding: 20px 0;
  @media screen and (min-width: 1244px) {
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
    padding: 28px;
  }
`
