import React, { useState } from 'react'
import { useFeedbackState } from 'store/feedback'
import { ArrowRight, Smiley, Message, ThumbDown, ThumbUp } from '../icons'
import { PrimaryButton, Textarea, TextBody, Title } from '../ui-form'
import { Container, Button, CloseButton, FeedbackButtons } from './feedback.styles'

export const Feedback = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [error, setError] = useState(false)

  const formState = useFeedbackState()

  const handleClose = () => {
    formState.reset()
    setIsOpen(false)
    setShowConfirmation(false)
  }
  const handleSubmit = () => {
    try {
      formState.saveFeedback()
      setShowConfirmation(true)
    } catch (e) {
      console.error('Error sending feedback', e)
      setError(true)
    }
  }

  const validateFeedback = formState.state.feedback.rate === undefined
  return (
    <>
      <div className="relative">
        <Button onClick={() => setIsOpen(true)}>
          <span className="hidden sm:block">Feedback</span>
          <span className="flex items-center justify-center align-middle sm:hidden">
            <Message color="var(--primary-color)" className="text-xl -mt-3" />
          </span>
        </Button>

        {isOpen && !showConfirmation && (
          <Container>
            <CloseButton type="button" onClick={() => handleClose()}>
              <ArrowRight style={{ fontSize: '14px' }} color="var(--primary-color)" />
            </CloseButton>
            <div className="space-y-5">
              <div className="space-y-2 mt-3">
                <Title>Your opinion matters to us (for real).</Title>
                <TextBody>
                  The idea behind Castl. is to serve your needs in the best way possible, by putting
                  you at the core of the platform - meaning your feedback is gold to us. So please,
                  feel free to let us know what you think and any ideas that would make the site
                  better.
                </TextBody>
              </div>
              <div>
                <p className="font-semibold text-secondary-color leading-relaxed">
                  Were you satisfied with your experience with us?
                </p>
                <div>
                  <div className="flex">
                    <FeedbackButtons
                      type="button"
                      name="negative"
                      selected={formState.state.feedback.rate === 'negative'}
                      onClick={() => formState.setField('feedback', 'rate', 'negative')}
                    >
                      <ThumbDown style={{ fontSize: '48px' }} />
                    </FeedbackButtons>
                    <FeedbackButtons
                      type="button"
                      name="positive"
                      selected={formState.state.feedback.rate === 'positive'}
                      onClick={() => formState.setField('feedback', 'rate', 'positive')}
                    >
                      <ThumbUp style={{ fontSize: '48px' }} />
                    </FeedbackButtons>
                  </div>
                </div>
              </div>
              <div>
                <p className="font-semibold text-secondary-color leading-relaxed">
                  Any additional feedback, ideas, comments?
                </p>
                <Textarea
                  value={formState.state.feedback.description || ''}
                  onChange={(e) =>
                    formState.setField('feedback', 'description', e.currentTarget.value)
                  }
                  name="message"
                  height={148}
                  placeholder="What you liked, what you liked less, what you would like to see..."
                />
              </div>
              <div className="max-w-xs mx-auto">
                <PrimaryButton disabled={validateFeedback} onClick={() => handleSubmit()}>
                  Send your feedback
                </PrimaryButton>
              </div>
            </div>
          </Container>
        )}
        {isOpen && showConfirmation && (
          <Container>
            <CloseButton type="button" onClick={() => handleClose()}>
              <ArrowRight style={{ fontSize: '14px' }} color="var(--primary-color)" />
            </CloseButton>
            <div className="flex flex-col align-middle justify-center items-center h-full text-center space-y-5">
              {error === false ? (
                <>
                  <Smiley color="var(--secondary-color)" style={{ fontSize: '64px' }} />
                  <div>
                    <Title className="hidden sm:block">We&#39;ve received your feedback!</Title>
                    <Title className="block sm:hidden" fontSize={32}>
                      We&#39;ve received your feedback!
                    </Title>
                    <TextBody>
                      Thank you for taking the time to share your thoughts. Your feedback will help
                      us improve our product and bring you an even better experience.
                    </TextBody>
                  </div>
                </>
              ) : (
                <div>
                  <Title className="hidden sm:block">
                    Sorry, it was not possible to send your feedback this time.
                  </Title>
                  <Title className="block sm:hidden" fontSize={32}>
                    Sorry, it was not possible to send your feedback this time.
                  </Title>
                  <TextBody>
                    We&#39;re working to fix this issue. Please, try again later. Your input is
                    valuable to us.
                  </TextBody>
                </div>
              )}
              <div className="w-52 mx-auto">
                <PrimaryButton onClick={() => handleClose()}>Ok</PrimaryButton>
              </div>
            </div>
          </Container>
        )}
      </div>
    </>
  )
}
