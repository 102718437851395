import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeft, ArrowRight } from '../icons'
import { FullImageViewerProps, FullImageViewer } from '../full-image-viewer/FullImageViewer'
import { CarouselWrapper } from './styles'

export type CarouselProps = {
  images: string[]
  imageHeight?: string
  imageWidth?: string
  imageRounded?: string
  imageViewer?: FullImageViewerProps
}

export const Empty = () => <div>no images</div>

export const Carousel = ({
  images,
  imageHeight,
  imageWidth,
  imageRounded,
  imageViewer,
}: CarouselProps) => {
  const history = useHistory()
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = React.useState<number | null>(
    images && images.length >= 0 ? 0 : null,
  )

  const openImageViewer = (index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const imageRefs = images.reduce((acc: any, val: any, i: any) => {
    acc[i] = React.createRef()
    return acc
  }, {})

  useEffect(() => {
    if (typeof imageViewer?.currentImage === 'number') {
      imageRefs[imageViewer.currentImage].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    } else {
      setCurrentImage(0)
    }
  }, [imageViewer])

  useEffect(() => {
    if (currentImage !== null) {
      imageViewer?.setCurrentImage(currentImage)
    }
  }, [currentImage])

  useEffect(() => {
    imageViewer?.setIsViewerOpen(isViewerOpen)
  }, [isViewerOpen])

  if (imageViewer) {
    if (images === undefined) return <Empty />
  } else if (currentImage === null || images === undefined) return <Empty />

  const scrollImage = (i: any) => {
    setCurrentImage(i)
    imageRefs[i].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    })
  }

  const totalImages = images.length

  const nextImage = () => {
    const currImage = imageViewer ? imageViewer.currentImage : currentImage
    if (currImage! >= totalImages - 1) {
      scrollImage(0)
      setCurrentImage(0)
    } else {
      scrollImage(currImage! + 1)
      setCurrentImage(currImage! + 1)
    }
  }

  const prevImage = () => {
    const currImage = imageViewer ? imageViewer.currentImage : currentImage
    if (currImage === 0) {
      scrollImage(totalImages - 1)
      setCurrentImage(totalImages - 1)
    } else {
      scrollImage(currImage! - 1)
      setCurrentImage(currImage! - 1)
    }
  }

  const arrowStyle = 'absolute z-10 h-10 w-10 flex items-center justify-center'
  const imageStyle = `${imageWidth || 'w-full'} flex-shrink-0 ${imageHeight || 'max-h-36'}`

  const sliderControl = (isLeft?: boolean) => (
    <button
      type="button"
      onClick={isLeft ? prevImage : nextImage}
      className={`${arrowStyle} ${isLeft ? 'left-2' : 'right-2'}`}
      style={{ top: '40%', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '50%' }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ? (
          <ArrowLeft color="white" style={{ fontSize: '24px' }} />
        ) : (
          <ArrowRight color="white" style={{ fontSize: '24px' }} />
        )}
      </span>
    </button>
  )

  const onImageClick = (imageIndex: number | null) => {
    if (window.location.pathname.includes('/property-details')) {
      openImageViewer(imageIndex || 0)
      scrollImage(imageIndex)
    }
  }

  return (
    <>
      <div className="relative w-full">
        <CarouselWrapper
          className={`overflow-hidden ${imageRounded || 'rounded-lg'} w-full h-full`}
        >
          {totalImages > 1 && sliderControl(true)}
          {images.map((img, i) => (
            <div className={imageStyle} key={img} ref={imageRefs[i]}>
              <img
                alt=""
                src={img}
                className="w-full h-full object-cover"
                onClick={() => {
                  onImageClick(imageViewer ? imageViewer.currentImage : currentImage)
                }}
                aria-hidden="true"
              />
            </div>
          ))}
          {totalImages > 1 && sliderControl()}
        </CarouselWrapper>
      </div>

      {isViewerOpen && (
        <FullImageViewer
          isViewerOpen={imageViewer ? imageViewer.isViewerOpen : isViewerOpen}
          setIsViewerOpen={setIsViewerOpen}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          images={images}
        />
      )}
    </>
  )
}
