import * as React from 'react'

function SvgPages(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21a3 3 0 003 3h18a3 3 0 003-3V7.5H0V21zM9.75 4.5A.75.75 0 119.748 3a.75.75 0 010 1.5zm-3 0a.75.75 0 110-1.5.75.75 0 010 1.5zm-3 0a.75.75 0 110-1.5.75.75 0 010 1.5zM21 0H3a3 3 0 00-3 3v3h24V3a3 3 0 00-3-3z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgPages = React.memo(SvgPages)
export default MemoSvgPages
