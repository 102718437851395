import React, { useState } from 'react'
import { DashboardHeader } from 'views/dashboard/components/header'
import { MonthPicker } from 'views/shared/components/ui-form'
import { DateHelper } from 'utilities'
import { SoldReportEmail } from '../SoldReportEmail'

export const EmailAnalyticsPage = () => {
  const [month, setMonth] = useState<string>(DateHelper.currentMonthStr())

  return (
    <>
      <DashboardHeader title="Email Analytics" />
      <div className="mx-5 mt-10 mb-28">
        <MonthPicker monthStr={month} onChange={(value) => value && setMonth(value)} />
        <div>
          <SoldReportEmail month={month} isAgent />
        </div>
      </div>
    </>
  )
}
