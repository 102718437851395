import { paths } from 'paths'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserServices } from 'services/user'
import { useSearchParams } from 'utilities/hooks/useSearchParams'

import {
  A,
  PrimaryButton,
  PrimaryNegativeButton,
  TextBody,
  Title,
} from 'views/shared/components/ui-form'

export const Unsubscribe = () => {
  const [unsubscribe, setUnsubscribe] = useState<boolean>(false)
  const history = useHistory()
  const handleCancel = () => {
    history.push('/')
  }
  const handleUnsubscribe = async () => {
    setUnsubscribe(true)
  }
  const searchParams = useSearchParams()

  useEffect(() => {
    async function disableUser() {
      console.warn(`unsubscribe:${unsubscribe}`)
      if (unsubscribe) {
        const uid = searchParams.get('uid')
        await UserServices.updateSubscriptions(false, uid || undefined)
      }
    }
    disableUser()
  }, [unsubscribe])

  return (
    <>
      <div className="max-w-2xl mx-auto px-5 flex flex-col justify-center">
        <div className="bg-alpha-color p-8 rounded-lg">
          {unsubscribe === false && (
            <>
              <Title fontSize={32} className="block sm:hidden">
                Unsubscribe
              </Title>
              <Title className="hidden sm:block">Unsubscribe</Title>
              <TextBody className="mt-3 mb-5">
                If you unsubscribe you will no longer receive our sold report emails. Are you sure
                you want to unsubscribe?
              </TextBody>
              <div className="max-w-xl flex space-x-3">
                <PrimaryNegativeButton type="button" onClick={() => handleCancel()}>
                  Cancel
                </PrimaryNegativeButton>
                <PrimaryButton type="button" onClick={() => handleUnsubscribe()}>
                  Unsubscribe
                </PrimaryButton>
              </div>
            </>
          )}
          {unsubscribe === true && (
            <>
              <Title fontSize={32} className="block sm:hidden">
                We&#39;re sorry to see you go!
              </Title>
              <Title className="hidden sm:block">We&#39;re sorry to see you go!</Title>

              <TextBody className="mt-3">
                You&#39;ve been unsubscribed from our monthly sold report and won&#39;t receive any
                further emails from us. If you change your mind you can always re-subscribe on your
                account settings at{' '}
                <A className="underline" href={paths.signIn()}>
                  yourcastl.com
                </A>
                .
              </TextBody>
            </>
          )}
        </div>
      </div>
    </>
  )
}
