import * as React from 'react'

function SvgArrowright(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.464 15.35a1.58 1.58 0 010-2.237L5.68 7.906.464 2.7a1.58 1.58 0 010-2.236 1.586 1.586 0 012.24 0L9.04 6.788a1.58 1.58 0 010 2.236L2.704 15.35a1.586 1.586 0 01-2.24 0z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgArrowright = React.memo(SvgArrowright)
export default MemoSvgArrowright
