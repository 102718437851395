import React from 'react'

import { Title } from 'views/shared/components/ui-form'
import { Validate } from 'views/shared/components/icons'

export const SideContent = () => (
  <>
    <div className="bg-white bg-opacity-70 space-y-10 rounded-lg p-5 shadow-xl">
      <div className="flex items-start space-x-3">
        <Validate
          style={{ fontSize: '30px', height: '50px' }}
          color="var(--accent-color)"
          className="flex-shrink-0"
        />
        <div>
          <Title color="var(--secondary-color)">Follow The Market</Title>
          <p className="text-secondary-color text-lg  font-medium">
            Stay on top of the real estate market in and around your neighborhood with our monthly
            Sold Reports
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-3">
        <Validate
          style={{ fontSize: '30px', height: '50px' }}
          color="var(--accent-color)"
          className="flex-shrink-0 mt-6"
        />
        <div>
          <p className="text-accent-color font-semibold text-sm">Coming soon</p>
          <Title color="var(--secondary-color)">Real-time Home Valuation </Title>
          <p className="text-secondary-color text-lg  font-medium">
            Keep track of the value of your current property at any time, and request an agent to
            estimate the added value of your home improvements
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-3">
        <Validate
          style={{ fontSize: '30px', height: '50px' }}
          color="var(--accent-color)"
          className="flex-shrink-0 mt-6"
        />
        <div>
          <p className="text-accent-color font-semibold text-sm">Coming soon</p>
          <Title color="var(--secondary-color)">Find Your Perfect Home</Title>
          <p className="text-secondary-color text-lg font-medium">
            Find the property of your dreams
          </p>
        </div>
      </div>
    </div>
  </>
)
