import React from 'react'
import styled from 'styled-components'
import { KeyLabel } from 'types'
import { ListSelector } from '../styled'

// styles
const Item = styled.li<{
  selected: boolean
}>`
  background-color: ${(p) => (p.selected ? 'var(--default-color)' : 'transparent')};
  color: ${(p) => (p.selected ? 'var(--on-default-color)' : 'var(--primary-color)')};
  border-radius: 6px;
  list-style: none;
`

export type CountInputItemType = {
  value: number
  label: string
}
export type CountInputProps<TInput extends number | string = number> = {
  items: KeyLabel<TInput>[]
  // items: CountInputItemType[]
  value?: TInput
  onChange?: (value: TInput) => void
}

export const CountInput = <TInput extends number | string = number>({
  items = [],
  value,
  onChange,
}: CountInputProps<TInput>) => (
  <>
    <ListSelector>
      {items.map((item) => (
        <Item
          onClick={() => {
            onChange && onChange(item.key)
          }}
          selected={value === item.key}
          key={item.key}
        >
          {item.label}
        </Item>
      ))}
    </ListSelector>
  </>
)
