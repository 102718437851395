import { useMultiSectionFormState } from 'utilities/hooks/useMultiSectionFormState'
import { useCallback } from 'react'
import { FeedbackServices } from 'views/shared/components/feedback/FeedbackService'
import { ClientFeedback } from 'types'

export const useFeedbackState = () => {
  const { state, setField, setSection } = useMultiSectionFormState({
    formName: 'feedback-modal',
    initial: {
      feedback: {
        rate: undefined,
        description: '',
      } as ClientFeedback,
    },
  })

  const reset = useCallback(() => {
    setField('feedback', 'rate', undefined)
    setField('feedback', 'description', '')
  }, [state])

  const saveFeedback = useCallback(async () => {
    await FeedbackServices.AddFeedback({
      rate: state.feedback.rate,
      description: state.feedback.description,
    }).catch((error) => {
      console.warn('error sending feedback', error)
    })
    reset()
  }, [state])

  return {
    state,
    setField,
    setSection,
    reset,
    saveFeedback,
  }
}
