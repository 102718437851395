import * as React from 'react'

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M2.561 8.173l4.858-.721A2.111 2.111 0 008.96 6.4l2.974-5.356c.774-1.393 2.816-1.393 3.59 0l2.965 5.341c.314.566.892.96 1.563 1.054l5.19.719c1.737.24 2.41 2.417 1 3.537l-3.756 2.98a1.98 1.98 0 00-.683 2.13l1.703 5.617c.288.95-.154 1.798-.828 2.237a2.05 2.05 0 01-2.399-.099l-5.223-4.052a2.18 2.18 0 00-2.654 0l-5.223 4.052a2.05 2.05 0 01-2.4.099c-.673-.439-1.115-1.288-.827-2.237l1.706-5.627a1.98 1.98 0 00-.674-2.124l-2.738-2.197C-.5 10 2 8.173 2.561 8.173z"
        // fill="#417A9F"
        stroke={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgStar = React.memo(SvgStar)
export default MemoSvgStar
