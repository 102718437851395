/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Star } from 'views/shared/components/icons'
import styled from 'styled-components'
import { useNeighbourhoods } from 'views/map/hooks'
import Moment from 'moment'
import { FollowingServices, FollowingType } from 'services/following'
import { UserServices } from 'services/user'
import { MonthPicker, SecondaryNegativeButton } from 'views/shared/components/ui-form'
import UserTypes from 'services/user/UserTypes'
import { DateHelper } from 'utilities/helpers/dateHelper'
import { Spinner } from 'views/shared/components/spinner'
import { useUserStore } from 'store/dashboard/user'
import { UserFeed } from '../userFeed'
import { UserFeedAggregation } from '../userFeedAggregation'

const GrayContainer = styled.div`
  background: #f4f6f6;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  padding: 14px 20px;
  margin-bottom: 32px;
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--accent-color);
  margin-bottom: 8px;
`

type UserActivityProps = {
  userId: string
}
export const UserActivity = () => {
  const path = window.location.pathname
  const userIndex = path.includes('agent') && path.includes('profile') ? 1 : 0
  const { userState } = useUserStore(userIndex)
  const [month, setMonth] = useState<string>(DateHelper.currentMonthStr())
  const [userFeedsResponse, setUserFeedsResponse] = useState<UserTypes.GetUserFeedsResponse>()
  const { neighbourhoods, isLoading: neighbourhoodIsLoading } = useNeighbourhoods()
  const [loadingFeeds, setLoadingFeeds] = useState<boolean>(false)
  const findNeighbourhoodName = (nid?: string) =>
    neighbourhoods.find((neighbourhood) => neighbourhood.id === nid)?.name || ''

  useEffect(() => {
    async function getActivities() {
      if (!userState.userId) return
      setLoadingFeeds(true)
      const res = await UserServices.getUserFeeds(userState.userId, month)
      setUserFeedsResponse(res)
      setLoadingFeeds(false)
    }
    getActivities()
  }, [month, userState.userId])

  // // Loading
  if (neighbourhoodIsLoading || !userFeedsResponse || loadingFeeds) {
    return (
      <>
        <GrayContainer>
          <MonthPicker monthStr={month} onChange={(value) => value && setMonth(value)} />
        </GrayContainer>
        <div>
          <div className="flex flex-col justify-center h-96">
            <Spinner className="h-32" alt="Loading" />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <GrayContainer>
        <MonthPicker monthStr={month} onChange={(value) => value && setMonth(value)} />
      </GrayContainer>
      <div>
        <Title>Aggregation</Title>
        <ul className="space-y-3 mb-5">
          <li className="flex items-start space-x-5">
            <ul>
              {userFeedsResponse?.aggregations?.map((item, i) => (
                <UserFeedAggregation key="aggs_%i" userFeedAggregation={item} index={i} />
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <div>
        <Title>Activity history</Title>
        <ul className="space-y-3 mb-5">
          <li className="flex items-start space-x-5">
            <ul>
              {userFeedsResponse?.hits.map((item, i) => (
                <UserFeed
                  key="feeds_%i"
                  userFeed={item}
                  index={i}
                  neighbourhoods={neighbourhoods}
                />
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </>
  )
}
