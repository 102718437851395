import { Storage } from 'aws-amplify'
import { UserServices } from 'services/user'

export module ImageServices {
  export const getProfileImage = async (userId: string) => {
    // TODO: don't call the API for headshot, it should be getting the value from state
    const headshot = await UserServices.getProfileById(userId).then(
      (profile) => profile.headshot || 'undefined',
    )

    let imagePath = `/default-profile.jpg`
    try {
      imagePath = (await Storage.get(`headshots/${headshot}`)) as string
    } catch (error) {
      console.warn(error)
    }
    return imagePath
  }
  export const removeProfileImage = async (userId: string) => {
    const imagePath = `/default-profile.jpg`
    try {
      await Storage.remove(`${userId}-profile.jpg`)
    } catch (error) {
      console.warn(error)
    }
    return imagePath
  }
}
