import React, { useState, useRef, useEffect } from 'react'
import { isDesktop } from 'react-device-detect'
import { useSwipeable } from 'react-swipeable'
import { PropertyServices } from 'services/property/PropertyServices'
import styled from 'styled-components'
import { PropertyListing } from 'types/Property'
import { GalleryItem } from 'views/map/pages/HomeSearch/HomeSearchGallery/GalleryItem'
import { ArrowLeft, ArrowRight } from 'views/shared/components/icons'

interface MultiCarouselProps {
  properties?: PropertyListing[] | null
  imageBaseUrl: string
  parentPage?: string
}

export const MultiCarousel = ({ properties, imageBaseUrl, parentPage }: MultiCarouselProps) => {
  const carousel = useRef<any>(null)
  const maxScrollWidth = useRef(0)
  const [currIndex, setCurrIndex] = useState(0)
  const [propertyImages, setPropertyImages] = useState<string[][]>([])
  const [disablePrev, setDisablePrev] = useState<boolean>(false)
  const [disableNext, setDisableNext] = useState<boolean>(false)

  const CarouselContainer = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
  `

  useEffect(() => {
    if (properties) {
      const newImages: string[][] = properties.map((p) =>
        PropertyServices.getPropertyImageURLs(p, imageBaseUrl),
      )
      setPropertyImages(newImages)
    } else {
      setPropertyImages([])
    }
  }, [properties])

  const slidePrev = () => {
    if (currIndex > 0) {
      setCurrIndex((prevState) => prevState - 1)
    }
  }

  const slideNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currIndex <= maxScrollWidth.current
    ) {
      setCurrIndex((prevState) => prevState + 1)
    }
  }

  useEffect(() => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currIndex
    }
  }, [currIndex])

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => slidePrev(),
    onSwipedRight: () => slideNext(),
  })

  const myRef = React.useRef()

  const refPassThrough = (el: any) => {
    handlers.ref(el)
    myRef.current = el
  }

  return (
    <div {...handlers} ref={refPassThrough} className="w-full">
      <div className="relative">
        {isDesktop ? (
          <div className="hidden sm:flex justify-between absolute w-full h-full">
            <button
              type="button"
              onClick={slidePrev}
              className={[
                'w-10 h-full opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0',
                disablePrev || 'transition-all ease-in-out duration-300',
              ].join('')}
              disabled={disablePrev}
            >
              <ArrowLeft color="var(--primary-color)" style={{ fontSize: '24px' }} />
              <span className="sr-only">Prev</span>
            </button>
            <button
              type="button"
              onClick={slideNext}
              className="w-10 h-full opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
              disabled={disableNext}
            >
              <ArrowRight color="var(--primary-color)" style={{ fontSize: '24px' }} />
              <span className="sr-only">Next</span>
            </button>
          </div>
        ) : (
          <></>
        )}
        {carousel && (
          <CarouselContainer
            ref={carousel}
            className={[
              parentPage !== 'SimilarSold'
                ? 'flex mx-12 overflow-hidden z-0'
                : 'grid grid-flow-row sm:grid-flow-col overflow-x-hidden w-full gap-2',
            ].join(' ')}
          >
            {parentPage !== 'SimilarSold'
              ? properties?.map((property, index) => (
                  <div key={property.mls} className="relative flex w-full sm:w-64 sm:mx-5">
                    <GalleryItem
                      key={property.mls}
                      property={property}
                      images={propertyImages[index]}
                    />
                  </div>
                ))
              : properties?.map((property, index) => (
                  <div
                    key={property.mls}
                    className="relative flex justify-center w-full sm:w-64 sm:mx-5"
                  >
                    <GalleryItem
                      key={property.mls}
                      property={property}
                      images={propertyImages[index]}
                      parentPage="SimilarSold"
                    />
                  </div>
                ))}
          </CarouselContainer>
        )}
      </div>
    </div>
  )
}
