import * as React from 'react'

function SvgBed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M23.993 18.314c-.14.34-.279.679-.593.907-.49.355-1.022.425-1.57.182-.54-.237-.856-.672-.885-1.27-.026-.525-.014-1.05-.017-1.578v-.314H3.195c0 .34.002.67 0 .998-.005.314.005.628-.029.94-.084.804-.717 1.35-1.55 1.356-.84.008-1.497-.544-1.6-1.343a2.145 2.145 0 01-.015-.25c0-4.108-.002-8.216 0-12.327 0-.672.286-1.186.91-1.457.626-.27 1.23-.196 1.766.245.412.338.523.806.523 1.315V12.464H24s-.007 2.526-.007 5.85z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
      <path
        d="M16.758 7.39c1.725 0 3.453-.005 5.178.003.95.002 1.675.487 1.94 1.276.45 1.342-.461 2.637-1.916 2.645-3.47.019-6.942.021-10.412 0-1.423-.01-2.351-1.31-1.917-2.606.238-.708.929-1.253 1.67-1.303.276-.02.555-.015.83-.015h4.627zM8.767 9.104a2.216 2.216 0 01-2.191 2.212c-1.205.017-2.22-.998-2.212-2.22a2.206 2.206 0 012.205-2.195 2.211 2.211 0 012.198 2.203z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgBedFilters = React.memo(SvgBed)
export default MemoSvgBedFilters
