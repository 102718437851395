import React from 'react'

export const SideContent = () => (
  <>
    <div>
      <p className="text-secondary-color lg:text-white font-semibold text-5xl lg:leading-tight text-4xl xl:text-6xl">
        Get a real-time valuation of
        <br />
        your home
      </p>
    </div>
  </>
)
