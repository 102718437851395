/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import {
  isCAPhone,
  maxLength,
  minAmount,
  required,
  stateValidationStore,
  useStateValidation,
} from 'utilities/hooks/validationState'

const initial = {
  name: '',
  age: 1,
  phone: '',
}
const section1Store = stateValidationStore(initial)
const store2 = stateValidationStore(initial)

export const NewStateForm = () => {
  const section1 = useStateValidation({
    store: section1Store,
    validation: {
      name: [required('Name is required'), maxLength(10, 'Name is too long')],
      age: [required('Age is required'), minAmount(9, 'Age must be equal or greater than 9')],
      // phone: [
      //   required('Phone is required'),
      //   isCAPhone('Phone must be a valid Canadian phone number'),
      // ],
    },
  })

  const section2 = useStateValidation({
    store: store2,
    validation: {
      name: [required('Name is required'), maxLength(10, 'Name is too long')],
      age: [required('Age is required'), minAmount(9, 'Age must be equal or greater than 9')],
      phone: [
        required('Phone is required'),
        isCAPhone('Phone must be a valid Canadian phone number'),
      ],
    },
  })

  const submit = (e: any) => {
    // setState({ { name: 's' })
    console.log('submit', e)
    section1.addMessage('$state', `created`, 'error')
    section1.touch()
    section1.commit()
  }
  // commit()

  return (
    <div>
      <div>
        <NavLink to="./">Back</NavLink>
      </div>
      <h1>section 1 - {section1.valid.$state ? 'valid' : 'not valid'}</h1>
      <div>
        name: {section1.state.name} - {section1.valid.name ? 'valid' : 'not valid'} -{' '}
        {section1.touched.name ? 'touched' : 'not touched'}
        <input
          value={section1.getField('name')}
          onChange={(e) => section1.setField('name', e.currentTarget.value, true)}
        />
        {/* name: {getField('name')} */}
      </div>
      <div>
        age:
        <input
          value={section1.getField('age')}
          onChange={(e) => section1.setField('age', Number(e.currentTarget.value), true)}
        />
        {/* age: {state.age} */}
        {/* age: {getField('age')} */}
      </div>
      <div>
        Phone:
        <input
          value={section1.getField('phone')}
          onChange={(e) => section1.setField('phone', e.currentTarget.value, true)}
        />
      </div>
      <hr />
      <ul>
        {section1.getMessages('$state').map((m) => (
          <li onClick={() => document.getElementById(m.field)?.focus()} key={m.message}>
            {m.message}
          </li>
        ))}
      </ul>
      <div>
        <button type="button" onClick={submit}>
          Submit
        </button>
      </div>
      <hr />
      <ul>
        {section2.getMessages('$state').map((m) => (
          <li onClick={() => document.getElementById(m.field)?.focus()} key={m.message}>
            {m.message}
          </li>
        ))}
      </ul>
    </div>
  )
}
