import { LandingPage } from './LandingPage'
import { paths } from './paths'

export const routes = {
  landingPage: {
    path: paths.landingPage(),
    exact: false,
    component: LandingPage,
  },
}
