import { atom, useAtom } from 'jotai'
import { useState } from 'react'
import { HomeValuationServices } from 'services/homeValuation'
import { GetContactHomeValuations } from 'types'

const isLoadingAtom = atom<boolean>(true)
const valuationsAtom = atom<GetContactHomeValuations | null>(null)

export const useValuationsStore = () => {
  const [valuationsState, setValuationsState] = useAtom(valuationsAtom)
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom)
  const [error, setError] = useState<string | null>(null)

  const fetchValuations = async () => {
    await HomeValuationServices.getContactsHomeValuations()
      .then((res) => {
        setValuationsState(res)
        setError(null)
      })
      .catch((e) => {
        setError(e.message || 'Something went wrong fetching the contact valuations.')
        setValuationsState(null)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    valuationsState,
    fetchValuations,
    isLoading,
    error,
  }
}
