/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { KeyLabel } from 'types'
import { DropdownField } from 'views/shared/components/ui-form'

type Option = Record<string, any>
interface OptionsChooserProps {
  options: Option[]
  idField?: string
  displayField?: string
  onChange: (selected: Option) => void
  selected: Option | undefined
}

export const OptionChooser = ({
  options,
  onChange,
  idField = 'id',
  displayField = 'name',
  selected,
}: OptionsChooserProps) => {
  const selectedKey = selected ? (selected[idField] as string) : undefined
  const onDropdownChange = useCallback(
    (key: string) => {
      const selectedOption = options.find((option) => option[idField] === key)
      selectedOption && onChange(selectedOption)
    },
    [onChange, idField, options],
  )
  return (
    <>
      <ul className="text-sm text-primary-color font-medium hidden md:flex">
        {options.map((option) => (
          <li key={option[idField]} className="mb-2 mr-2 md:mb-0">
            <button
              type="button"
              onClick={() => option && onChange(option)}
              className={[
                `${
                  selected &&
                  selected[idField] === option[idField] &&
                  'bg-default-color border border-default-color text-on-default-color '
                }`,
                'border border-primary-soft-color rounded-lg px-10 py-3 h-12 font-medium outline-none',
              ].join(' ')}
            >
              {option[displayField]}
            </button>
          </li>
        ))}
      </ul>
      <div className="block md:hidden w-full">
        <DropdownField
          name="graphType"
          options={options.map(
            (item) => item && ({ key: item[idField], label: item[displayField] } as KeyLabel),
          )}
          onChange={(key) => onDropdownChange(key)}
          value={selectedKey}
        />
      </div>
    </>
  )
}
