import * as React from 'react'

function SvgPeople(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M8.843 12c1.614 0 2.922-1.343 2.922-3s-1.308-3-2.922-3C7.23 6 5.922 7.343 5.922 9s1.308 3 2.921 3zm0 0c2.922 0 4.146 1.667 4.146 1.667C13.713 12.5 14.48 11 17.121 11m-8.278 1C5.922 12 3.973 14 3 16m14.121-5c1.076 0 1.948-.895 1.948-2s-.872-2-1.948-2c-1.075 0-1.948.895-1.948 2s.873 2 1.948 2zm0 0c1.461 0 2.435.5 3.409 2 .974 1.5.487 3.5-1.948 3.5h-3.896c0 .667 0 2.5-1.947 2.5H3.974"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgPeople = React.memo(SvgPeople)
export default MemoSvgPeople
