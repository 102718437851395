import type PDFDocumentType from 'pdfkit'
// import { AddSVGOptionsType, PdfHelper } from 'views/shared/utilities'
// import { AddSVGOptionsType } from './util/addSvg'

declare const PDFDocument: typeof PDFDocumentType
// settings - LETTER size
export const size = {
  w: 612,
  h: 792,
}
export const propertyTypes = [
  'detatched',
  'semiDetatched',
  'freeholdTownhouse',
  'condo',
  'condoTownhouse',
  'multi',
]

// doc
const newDoc = async () => {
  await fetch('/fonts/Montserrat-Regular.ttf')
  const doc = new PDFDocument({
    margin: 0,
    layout: 'portrait',
    size: 'LETTER',
    bufferPages: true,
  } as PDFKit.PDFDocumentOptions)
  doc.registerFont('Montserrat', '/fonts/Montserrat-Regular.ttf')
  return doc
}
export { newDoc }
