import { UserServices } from 'services/user'
import UserTypes from 'services/user/UserTypes'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { UserListStore } from './userListStore'

export const useUserListState = () => {
  const [userListQuery, setQueryUserList] = useRecoilState(UserListStore.query)
  const [userList, setUserList] = useRecoilState(UserListStore.list)
  const [userListStatus, setUserListStatus] = useRecoilState(UserListStore.status)
  // Actions:
  const fetchUserList = useCallback(
    (query: Partial<UserTypes.GetUsersParametersType> = {}, agentId?: string) => {
      const defaultQuery = agentId
        ? UserListStore.contactsQuery(agentId)
        : UserListStore.defaultQuery
      const q = { ...defaultQuery, ...query }
      setQueryUserList(q)
      setUserListStatus({ status: 'loading' })
      UserServices.getUsers(q)
        .then((result) => {
          setUserList(result)
          setUserListStatus({ status: 'ready' })
        })
        .catch(() => {
          setUserListStatus({ status: 'error', error: 'Fail to load contacts' })
        })
    },
    [userListQuery],
  )

  return { fetchUserList, userListQuery, userList, userListStatus }
}
