import * as React from 'react'

function SvgDuration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#duration_svg__clip0_231:56)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.096.015c-1.779.17-3.101.547-4.6 1.308-1.773.903-3.365 2.33-4.526 4.058C1.479 6.113.878 7.348.59 8.22a13.573 13.573 0 00-.531 2.3c-.079.552-.079 2.404 0 2.956.197 1.382.562 2.593 1.133 3.754.645 1.314 1.278 2.197 2.322 3.241 1.033 1.033 1.93 1.678 3.194 2.298.882.433 1.452.644 2.347.868 2.347.589 4.7.476 6.994-.335.695-.246 1.908-.854 2.532-1.27a12.77 12.77 0 003.609-3.699c.338-.532.881-1.654 1.103-2.279 1.102-3.106.91-6.44-.538-9.354a11.596 11.596 0 00-2.15-3.043c-1.014-1.049-2.008-1.773-3.338-2.43A11.485 11.485 0 0013.044.032c-.479-.044-1.566-.054-1.948-.017zm1.358 5.096c.11.058.267.198.348.31l.148.204.012 3.221.013 3.222 2.455 2.464c2.105 2.112 2.463 2.491 2.51 2.657.084.296.066.493-.069.763a.971.971 0 01-1.118.519c-.23-.053-.317-.135-2.903-2.713-1.494-1.488-2.707-2.735-2.758-2.833-.089-.17-.091-.288-.079-3.739l.013-3.563.14-.2a1.022 1.022 0 011.288-.312z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="duration_svg__clip0_231:56">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgDuration = React.memo(SvgDuration)
export default MemoSvgDuration
