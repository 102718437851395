/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import { DatePicker } from 'views/shared/components/ui-form/month-picker/DatePicker'
import { DateHelper } from 'utilities'
import { Input } from 'views/shared/components/ui-form'
import { InputViewField } from 'views/shared/components/formView/InputViewField'
import { FieldsContainer } from 'views/shared/components/formView/fieldsContainer'
import { useUserStore } from 'store/dashboard/user'

export type UserFollowUpProps = {
  uid: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
export const UserFollowUp = ({ uid }: UserFollowUpProps) => {
  const { followUpForm, saveFollowUp, deleteFollowUp } = useUserStore()
  const [message, setMessage] = useState('')

  const saveFollowUpHandler = () => {
    followUpForm.touch()
    if (!followUpForm.isValid()) return
    saveFollowUp().then(() => {
      setMessage('Follow up saved')
      setTimeout(() => {
        setMessage && setMessage('')
      }, 2500)
    })
  }
  const toggleFollowUp = (checked: boolean) => {
    if (checked) {
      followUpForm.setFieldsData(
        {
          date: Date.now(),
          note: '',
        },
        true,
      )
    } else {
      followUpForm.setFieldsData(
        {
          date: undefined,
          note: undefined,
        },
        true,
      )
      deleteFollowUp()
    }
  }
  const hasFollowUp = followUpForm.state.note !== undefined && followUpForm.state.date !== undefined
  return (
    <>
      <div className="space-y-3 mt-3">
        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={hasFollowUp}
            onChange={toggleFollowUp}
            className={classNames(
              hasFollowUp ? 'bg-accent-color' : 'bg-alpha-color',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                hasFollowUp ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              )}
            />
          </Switch>
          {hasFollowUp === true ? (
            <>
              <Switch.Label as="span" className="ml-3">
                <span className="text-base font-medium text-secondary-color">
                  Follow-up scheduled on{' '}
                </span>
              </Switch.Label>
              <DatePicker
                styleType="textStyle"
                type="future"
                value={DateHelper.formatDateToYearMonthDayStr(followUpForm.state.date)}
                onChange={(value) => {
                  followUpForm.setField(
                    'date',
                    value ? DateHelper.formatYearMonthDayStrToDate(value) : undefined,
                    true,
                  )
                }}
                name="followUpDate"
              />
            </>
          ) : (
            <Switch.Label as="span" className="ml-3">
              <span className="text-base text-default-color">No follow-up scheduled</span>
            </Switch.Label>
          )}
        </Switch.Group>
        {hasFollowUp === true && (
          <>
            {/* <Input
              value={followUpForm.state.note}
              maxLength={100}
              onChange={(e) => followUpForm.setField('note', e.currentTarget.value, true)}
              className="mt-0"
              type="followUpMessage"
              placeholder="Reason for following up"
            /> */}
            <FieldsContainer>
              <InputViewField
                stateValidation={followUpForm}
                field="note"
                maxLength={100}
                edit
                placeholder="Reason for following up"
              />
            </FieldsContainer>
            {message && <p className="text-accent-color text-xs">{message}</p>}
            <button
              className="underline text-accent-color font-semibold text-xs"
              type="button"
              onClick={saveFollowUpHandler}
            >
              save
            </button>
          </>
        )}
      </div>
    </>
  )
}
