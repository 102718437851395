/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'
import { paths } from 'paths'
import { AuthContext, AuthUser } from 'views/authentication'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PrimaryButton, TextBody, Title } from 'views/shared/components/ui-form'
import { AuthServices } from 'services/auth'

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Content = styled.div`
  max-height: 400px;
  width: 100%;
  background-color: #fff;
  box-shadow: -6px 0px 16px rgba(0, 0, 0, 0.25);
`

export const TableModal = () => {
  const [isOpen, setIsOpen] = useState(true)
  const { authUser } = useContext(AuthContext)
  const isSkipped = AuthServices.getLocalStorageSkipped()

  return (
    <>
      {isOpen && isSkipped !== 'y' && (
        <Container className={authUser ? 'hidden' : 'sm:max-w-2xl sm:mx-auto'}>
          <Content className="space-y-10 p-5 sm:p-10">
            <div>
              <Title className="mb-1" fontSize={24} color="var(--warning-color)">
                Sign in to see all the properties that sold in the month.{' '}
              </Title>
              <TextBody>
                TRREB (Toronto Regional Real Estate Board) requires users to create accounts in
                order to see sold prices.
              </TextBody>
            </div>
            <div className="w-full text-center flex flex-col items-center justify-center align-middle mt-3">
              <div className="w-80">
                <PrimaryButton className="mb-3">
                  <Link to={paths.signIn()}>Sign in</Link>
                </PrimaryButton>
              </div>
              <button
                className="font-semibold text-primary-color underline"
                type="button"
                onClick={() => {
                  setIsOpen(false)
                  AuthServices.setLocalStorageSkipped('y')
                }}
              >
                Skip for now
              </button>
            </div>
          </Content>
        </Container>
      )}
    </>
  )
}
