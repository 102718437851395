import blobStream from 'blob-stream'
import { HomeValuationAnnualReport, Neighbourhood } from 'types'
import { addAgent } from './agent'
import { addBackcover } from './backcover'
import { addChart } from './chart'
import { newDoc } from './config'
import { addCover } from './cover'
import { addPropertyDetails, addPropertyGallery } from './property'
import { addComparables } from './property/comparable'
import { addComparableProperties } from './property/comparableProperties'
import { addComparableSoldProperties } from './property/comparableSoldProperties'

// settings - LETTER size
export const size = {
  w: 612,
  h: 792,
}
export const propertyTypes = [
  'detatched',
  'semiDetatched',
  'freeholdTownhouse',
  'condo',
  'condoTownhouse',
  'multi',
]

export type DocInfoType = { url: string } | undefined

export class PdfAnnualReport {
  public static async generate(
    data: HomeValuationAnnualReport.Valuation,
    neighbourhoods: Neighbourhood[],
  ) {
    return new Promise<DocInfoType>((resolve) => {
      newDoc().then(async (doc) => {
        const stream = doc.pipe(blobStream())
        let result: DocInfoType

        // generate pages doc
        await addCover(doc, data)
        await addAgent(doc, data)
        await addChart(doc, data)
        await addPropertyDetails(doc, data.propertyDetails, data)
        await addPropertyGallery(doc, data.propertyDetails, data)
        await addComparableProperties(doc, data, neighbourhoods)
        await addComparableSoldProperties(doc, data)
        await addComparables(doc, data, data.similarSolds)
        await addBackcover(doc, data)

        // stream
        stream.on('finish', () => {
          stream.toBlobURL('application/pdf')
          const url = stream.toBlobURL('application/pdf')
          result = {
            url,
          }
          resolve(result)
        })
        stream.on('error', () => {
          resolve(undefined)
        })
        // end
        doc.end()
      })
    })
  }
}
