import { AuthUser } from 'views/authentication'
import { AuthContext } from 'views/authentication/contexts/AuthContext'
import React, { PropsWithChildren, ReactElement, useContext } from 'react'
import { CurrencyHelper, DateHelper } from 'utilities'
import * as moment from 'moment-timezone'
import { MonthStr, NeighbourhoodReport, Property } from 'types'
import './PropertyTable.scss'
import { TableModal } from 'views/soldReport/components/TableModal'
import { MockSoldReportDetails } from 'constants/mockSoldReportDetails'
import { useHistory } from 'react-router-dom'

export type PropertyTableProps = PropsWithChildren<{
  neighbourhoodReport?: NeighbourhoodReport
  mockTableSize: number
  selectedMonthStr: MonthStr
}>

let mockProperties: Property[] | undefined
const getMockPropertiesList = () => {
  if (!mockProperties) {
    mockProperties = MockSoldReportDetails(17)
  }
  return mockProperties
}
const getSoldPropertiesList = (
  neighbourhoodReport: NeighbourhoodReport | undefined,
  authUser: AuthUser | null | undefined,
  mockTableSize: number,
) => {
  if (authUser) {
    return neighbourhoodReport && neighbourhoodReport.soldProperties
      ? neighbourhoodReport.soldProperties
      : []
  }
  return getMockPropertiesList().slice(0, mockTableSize)
}

const percentToList = (soldPrice: number, listPrice: number): string =>
  ((soldPrice / listPrice) * 100).toFixed(0)

const soldPriceDiff = (soldPrice: number, listPrice: number): string => {
  const calculatedValue = Math.round((soldPrice - listPrice) / 1000)
  return calculatedValue > 0 ? `+${calculatedValue}k` : `${calculatedValue}k`
}

const decodeEntity = (inputStr: string) => {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = inputStr
  return textarea.value
}

export const PropertyTable = ({
  neighbourhoodReport,
  mockTableSize,
  selectedMonthStr,
}: PropertyTableProps): ReactElement => {
  const { authUser } = useContext(AuthContext)
  const history = useHistory()
  const soldPropertiesList = getSoldPropertiesList(
    neighbourhoodReport,
    authUser || null,
    mockTableSize,
  )
    .slice()
    .sort((a, b) => (a.closeDate || 0) - (b.closeDate || 0))

  return (
    <>
      <div className="relative">
        {soldPropertiesList.length > 0 ? (
          <>
            <div className="sold-report my-10">
              <div>
                <div className="overflow-x-auto">
                  <div className="py-2 inline-block min-w-full relative">
                    <table className="sold-report__table whitespace-nowrap min-w-full">
                      <thead>
                        <tr className="text-secondary-color text-sm tracking-wider align-bottom">
                          <th scope="col" className="pl-6 x-6 pt-3 font-semibold">
                            Address
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            Date
                            <br />
                            Sold
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            List Price
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            Sold Price
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            % To
                            <br />
                            List
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            DOM
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            {neighbourhoodReport?.type !== 'condo' &&
                            neighbourhoodReport?.type !== 'condoTownhouse' ? (
                              <span>Lot Size</span>
                            ) : (
                              <span>Sqft</span>
                            )}
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            Bd
                          </th>
                          <th scope="col" className="px-6 pt-3 font-semibold">
                            Br
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className={
                          authUser
                            ? 'whitespace-nowrap text-base font-normal text-default-color'
                            : 'sold-report__disable-select sold-report__blur'
                        }
                      >
                        {soldPropertiesList.map((property: Property, index: number) => (
                          <tr
                            key={property.mls}
                            className={[
                              index % 2 === 0 ? 'sold-report__table__odd-row' : '',
                              'cursor-pointer hover:underline',
                            ].join(' ')}
                            onClick={() => history.push(`/property-details/?=${property?.mls}`)}
                          >
                            <td className="px-6 py-4 font-semibold tracking-wider text-secondary-color">
                              {property.unit
                                ? `#${decodeEntity(property.unit)} - ${property.address}`
                                : property.address}
                            </td>
                            <td className="px-6 py-4 text-default-color">
                              {moment.tz(property.soldDate, 'America/Toronto').format('MM-DD')}
                            </td>
                            <td className="px-6 py-4 font-extrabold">
                              ${CurrencyHelper.currencyFormat(property.listingPrice)}
                            </td>
                            <td className="px-6 py-4">
                              <span className="font-bold pr-2 text-secondary-color">
                                ${CurrencyHelper.currencyFormat(property.soldPrice)}
                              </span>
                              <span className="font-bold text-accent-color">
                                {soldPriceDiff(property.soldPrice, property.listingPrice)}
                              </span>
                            </td>
                            <td className="px-6 py-4">
                              {percentToList(property.soldPrice, property.listingPrice)}%
                            </td>
                            <td className="px-6 py-4">{property.dom}</td>
                            <td className="px-6 py-4">
                              {neighbourhoodReport?.type !== 'condo' &&
                              neighbourhoodReport?.type !== 'condoTownhouse' ? (
                                <span>
                                  {`${property.lotFront || property.landFront} x ${
                                    property.lotDepth || property.landDepth
                                  }`}{' '}
                                </span>
                              ) : (
                                <span>{property.footage}</span>
                              )}
                            </td>
                            <td className="px-6 py-4">{property.bedroom}</td>
                            <td className="px-6 py-4">{property.bathroom}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {!authUser && <TableModal />}
            </div>
          </>
        ) : (
          <>
            <div className="overflow-x-auto">
              <div className="py-2 inline-block min-w-full relative text-center">
                <table className="sold-report__table whitespace-nowrap min-w-full">
                  <thead>
                    <tr className="text-secondary-color text-sm tracking-wider align-bottom">
                      <th scope="col" className="pl-6 x-6 pt-3 font-semibold">
                        Address
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        Date
                        <br />
                        Sold
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        List Price
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        Sold Price
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        % To
                        <br />
                        List
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        DOM
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        {neighbourhoodReport?.type !== 'condo' &&
                        neighbourhoodReport?.type !== 'condoTownhouse' ? (
                          <span>Lot Size</span>
                        ) : (
                          <span>Sqft</span>
                        )}
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        Bd
                      </th>
                      <th scope="col" className="px-6 pt-3 font-semibold">
                        Br
                      </th>
                    </tr>
                  </thead>
                </table>
                <p className="text-default-color">
                  No recorded sales to date for {DateHelper.formatDateToMonthStr(selectedMonthStr)}.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
