import * as React from 'react'

function SvgValidate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.73.27a.92.92 0 00-1.3 0L10.278 13.42l-5.41-5.409a2.76 2.76 0 00-3.903 0l-.158.158a2.76 2.76 0 000 3.904l8.82 8.82a.92.92 0 001.301 0L23.81 8.01a.92.92 0 10-1.3-1.301L10.278 18.94l-8.17-8.169a.92.92 0 010-1.3l.158-.159a.92.92 0 011.302 0l6.059 6.06a.92.92 0 001.301 0L24.73 1.57a.92.92 0 000-1.302z"
        stroke={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgValidate = React.memo(SvgValidate)
export default MemoSvgValidate
