import { API } from 'aws-amplify'
import { AgentProfile } from 'views/agents/types/agents'

export module AgentServices {
  const apiName = 'user'
  export const getMyAgentProfile = async () => {
    const responseData = (
      await API.get(apiName, `/profiles/myagent`, {
        response: true,
      })
    ).data as AgentProfile
    return responseData
  }
}
