import { CurrencyHelper } from 'utilities'
import { ChartTypeId } from './types'

interface ChartDataset {
  data: number[]
  color?: string
  label: string
}
interface GenerateAnnotationsProps {
  datasets: ChartDataset[]
  chartId: ChartTypeId
}
const font =
  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
const calculateAverageIgnoreZero = (data: number[]) =>
  data.reduce((a, b) => a + b, 0) / data.filter((a) => a > 0).length
export const generateAnnotations = ({ datasets, chartId }: GenerateAnnotationsProps) => {
  const baseLine = {
    type: 'line',
    borderColor: '#6B7280',
    borderDash: [2, 8],
    borderWidth: 2,
    label: {
      enabled: true,
      position: 'start',
      xAdjust: 10,
      yAdjust: -10,
      backgroundColor: 'rgba(0,0,0,0)',
      color: '#6B7280',
      font: {
        family: font,
        weight: 'bold',
      },
    },
  }

  return datasets.reduce((annotations, { color: userColor, data, label }) => {
    const average = calculateAverageIgnoreZero(data)
    const color = userColor || '#CCCCCC'
    const averageLabel = () => {
      if (chartId === 'snlr') {
        return `${average.toFixed(1)}`
      }
      if (chartId === 'price') {
        return `$${CurrencyHelper.currencyFormat(average)}`
      }
      return Math.round(average)
    }
    return {
      ...annotations,
      ...(average > 0 && Number.isFinite(average)
        ? {
            [`avg${label}`]: {
              ...baseLine,
              label: {
                ...baseLine.label,
                content: `Average: ${averageLabel()}`,
                color,
              },
              color,
              yMin: average,
              yMax: average,
            },
          }
        : {}),
    }
  }, {})
}
