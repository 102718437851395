import { HomeValuationAnnualReport } from 'types/HomeValuationAnualReport'
import { CurrencyHelper } from 'utilities'
import { ChartHelper } from './chartHelper'

export const chartBody = (data: HomeValuationAnnualReport.Valuation) => {
  const { chartSize, getChartPositionData } = ChartHelper
  const chartData = getChartPositionData(data.chart)
  const items = chartData.values
  const labelsBlock = chartData.labels.reduce((pv, label) => {
    const { x } = label
    return /* svg s */ `${pv}
    <text
      text-anchor="middle"
      fill="#417A9F" style="white-space: pre" font-family="Montserrat" font-size="10" font-weight="bold" letter-spacing="0em">
    <tspan x="${x}" width="${label.w}" y="282">${label.month}</tspan>
    ${label.showYear ? `<tspan x="${x}" y="294">${label.year}</tspan>` : ``}
    </text>`
  }, ``)

  const pathD = items.reduce(
    (pv, cv) => `${pv} L ${cv.x} ${cv.y} `,
    `M ${items[0].x} ${items[0].y} `,
  )

  const pathChart = `
    <path d="${pathD}" fill="transparent" stroke="#018363" stroke-width="2" />
    <path d="${pathD} L ${chartSize.w} ${chartSize.h} L 0 ${chartSize.h} Z" fill="url(#chartFill)" stroke="transparent" stroke-width="0" />
  `
  const edgeItems = [items[0], items[items.length - 1]]
  const edgeCircles = edgeItems.reduce(
    (pv, v) => `
      ${pv}<circle cx="${v.x}" cy="${v.y}" r="4.5" stroke="white"  fill="black"/>`,
    ``,
  )
  const edgeAmmount = edgeItems
    .map((v, i) => {
      const attributes: { align: string; fontSize: number; x: number } =
        i === 0
          ? {
              align: 'start',
              fontSize: 17,
              x: v.x,
            }
          : {
              align: 'end',
              fontSize: 24,
              x: v.x,
            }
      return `
      <text
      fill="black"
      text-anchor="${attributes.align}"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Montserrat"
      font-size="${attributes.fontSize}"
      font-weight="bold"
      letter-spacing="0em"><tspan text-align="${attributes.align}" x="${
        attributes.x
      }" y="27.0945">$${CurrencyHelper.currencyFormat(v.value)}</tspan></text>
    `
    })
    .reduce((pv, v) => `${pv}${v}`, ``)

  const lines = Array.from({ length: 4 }).reduce((pv, v, i) => {
    const b: number = chartSize.h / 4
    const y: number = chartSize.h - b * i
    const { w } = chartSize
    const { max, min } = chartData.limits
    const startAmount = (max - min) / 4
    const amountLabel = CurrencyHelper.toCurrencyShort(startAmount * i + min)
    return `${pv}
      <text fill="#807B7B" style="white-space: pre" font-family="Montserrat" font-size="10" letter-spacing="0em" x="5" y="${y}">${amountLabel}</text>    
      <line x1="0" x2="${w}" y1="${y}" y2="${y}" stroke="#C4C4C4" stroke-width="0.5"/>`
  }, ``)

  const template = `
    <svg x="21" y="364">
      <defs>
        <linearGradient id="chartFill" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#018363; stop-opacity: 0.36; " />
          <stop offset="50%" style="stop-color:#018363; stop-opacity: 0.1; " />
          <stop offset="100%" style="stop-color:#018363; stop-opacity: 0.05; " />
        </linearGradient>
      </defs>

      <!-- borders -->
      ${edgeAmmount}
      <g transform="translate(0,45)" x="0" y="45">
      <line x1="0" y1="0" x2="0" y2="${chartSize.h}" stroke="black" stroke-linecap="round" stroke-dasharray="0.5 3"/>
      <line x1="${chartSize.w}" y1="0" x2="${chartSize.w}" y2="${chartSize.h}" stroke="black" stroke-linecap="round" stroke-dasharray="0.5 3"/>
        ${lines}
        ${pathChart}
        ${edgeCircles}
      </g>
      ${labelsBlock}
    </svg>`
  return template
}
