export type AgentProps = {
  firstName: string
  lastName: string
  phone: string
  email: string
  headshot: string
  logo: string
}
export const agent = ({
  firstName,
  lastName,
  phone,
  email,
  headshot,
  logo,
}: AgentProps) => /* svg */ `
  <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="73.5" y="1616.83">Brought to you by</tspan></text>
  <line x1="73.5" y1="1631.59" x2="1264.5" y2="1631.59" stroke="#C4C4C4"/>
  <image xlink:href="${logo}" x="73.5" y="1648.09" width="64" height="64"/> 
  <image xlink:href="${headshot}" x="146.667" y="1648.09" width="64" height="64"/>
  <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em"><tspan x="235.5" y="1671.83">Your SAGE agent</tspan></text>
  <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="234.5" y="1711.65">${firstName} ${lastName}</tspan></text>
  <text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="20" font-weight="600" letter-spacing="0em"><tspan x="743.902" y="1710.26">${phone}</tspan></text>
  <text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="20" font-weight="600" letter-spacing="0em"><tspan x="936.18" y="1710.26">${email}</tspan></text>
  `
