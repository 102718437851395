import * as React from 'react'

function SvgProfile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        d="M12.152 13c4.586 0 6.746 2.828 7.918 5.45.55 1.23-.439 2.55-1.787 2.55H6.745m5.407-8c-1.475 0-4.916-1-4.916-5 0-3 1.967-5 4.916-5 2.95 0 4.915 2 4.915 5 0 2-1.376 5-4.915 5zm0 0c-4.91 0-7.04 3.24-8.152 6"
        stroke={props.color || 'var(--default-icon-color, #000)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgProfile = React.memo(SvgProfile)
export default MemoSvgProfile
