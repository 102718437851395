/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren, ReactElement, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { KeyLabel, MonthStr, NeighbourhoodReport, PropertyType } from 'types'

import { DateHelper, MonthYearType } from 'utilities'
import { InfoCard } from 'views/shared/components/info-card/InfoCard'
import {
  avgSellingPriceDiffSelector,
  avgSellingToListPriceDiffSelector,
  avgSellingToListPriceSelector,
  daysOnMarketDiffSelector,
  neighbourhoodReportState,
  salesToNewListingsRateDiffSelector,
  salesToNewListingsRateSelector,
  soldNumberDiffSelector,
} from './snapshotState'

export type SnapshotProps = PropsWithChildren<{
  neighbourhoodReport?: NeighbourhoodReport
  availableMonthsForSelectedPropertyType: KeyLabel[]
  selectedPropertyType: PropertyType
  selectedMonthStr: MonthStr
}>
export const percentageFormat = (number: number) => {
  if (!Number.isFinite(number) || Number.isNaN(number)) {
    return `--`
  }
  return `${number > 0 ? '+' : ''}${number}%`
}
export const numbersFormat = (number: number) => {
  if (!Number.isFinite(number) || Number.isNaN(number)) {
    return `--`
  }
  return `${number > 0 ? '+' : ''}${number}`
}
export const getSalesToNewListingsRate = (rate: number) => {
  if (!Number.isFinite(rate)) return 0
  return Number.isNaN(rate) ? '--' : rate
}
export const Snapshot = ({
  neighbourhoodReport,
  selectedMonthStr,
}: SnapshotProps): ReactElement => {
  const [report, setReport] = useRecoilState(neighbourhoodReportState)
  const avgSellingPriceDiff = useRecoilValue<number>(avgSellingPriceDiffSelector)
  const avgSellingToListPrice = useRecoilValue<number>(avgSellingToListPriceSelector)
  const avgSellingToListPriceDiff = useRecoilValue<number>(avgSellingToListPriceDiffSelector)
  const soldNumberDiff = useRecoilValue<number>(soldNumberDiffSelector)
  const daysOnMarketDiff = useRecoilValue<number>(daysOnMarketDiffSelector)
  const salesToNewListingsRate = useRecoilValue<number>(salesToNewListingsRateSelector)
  const salesToNewListingsRateDiff = useRecoilValue<number>(salesToNewListingsRateDiffSelector)

  useEffect(() => {
    neighbourhoodReport && setReport(neighbourhoodReport)
  }, [neighbourhoodReport, setReport])

  if (!neighbourhoodReport) {
    return <></>
  }

  const cadFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  const currentMonth = DateHelper.monthStrToYearAndMonth(
    selectedMonthStr || DateHelper.currentMonthStr(),
  )
  const lastYearMonth: MonthYearType = { year: currentMonth.year - 1, month: currentMonth.month }
  const lastYearMonthLabel = DateHelper.monthStrToShortMonthLabel(lastYearMonth)

  return (
    <>
      <div>
        <ul className="sold-report-snapshot grid grid-cols-2 md:grid-cols-3 xl:grid-flow-col xl:flex xl:justify-between gap-10">
          {/* Average Price */}
          <li className="">
            <p className="font-semibold text-sm  text-secondary-color pb-4">
              <br />
              Average Selling Price
            </p>
            <p className="text-2xl sm:text-5xl font-semibold text-accent-color">
              {Number.isNaN(neighbourhoodReport.averagePrice)
                ? '--'
                : cadFormat.format(neighbourhoodReport.averagePrice)}
              <span className="block font-bold text-secondary-color text-base">
                {percentageFormat(avgSellingPriceDiff)}
                <span className="text-default-color text-sm font-normal pl-2">
                  vs {lastYearMonthLabel}
                </span>
              </span>
            </p>
          </li>
          {/* Sold Listings */}
          <li>
            <p className="font-semibold text-sm  text-secondary-color pb-4">
              Number of
              <br />
              Sold Listings
            </p>
            <p className="text-2xl sm:text-5xl font-semibold text-accent-color">
              {Number.isNaN(neighbourhoodReport.soldProperties.length)
                ? '--'
                : neighbourhoodReport.soldProperties.length}
              <span className="block font-bold text-secondary-color text-base">
                {percentageFormat(soldNumberDiff)}
                <span className="text-default-color text-sm font-normal pl-2">
                  vs {lastYearMonthLabel}
                </span>
              </span>
            </p>
          </li>
          {/* Average Selling To List Price */}
          <li>
            <p className="font-semibold text-sm text-secondary-color pb-4">
              Average Selling Price
              <br />
              To List Price
            </p>
            <p className="text-2xl sm:text-5xl font-semibold text-accent-color">
              {Number.isNaN(avgSellingToListPrice) ? '--' : avgSellingToListPrice}%
              <span className="block font-bold text-secondary-color text-base">
                {percentageFormat(avgSellingToListPriceDiff)}
                <span className="text-default-color text-sm font-normal pl-2">
                  vs {lastYearMonthLabel}
                </span>
              </span>
            </p>
          </li>
          {/* DOM */}
          <li>
            <p className="font-semibold text-sm text-secondary-color pb-4">
              <br />
              Days On Market
            </p>
            <p className="text-2xl sm:text-5xl font-semibold text-accent-color">
              {Number.isNaN(neighbourhoodReport.dom) ? '--' : Math.round(neighbourhoodReport.dom)}
              <span className="block font-bold text-secondary-color text-base">
                {numbersFormat(daysOnMarketDiff)}
                <span className="text-default-color text-sm font-normal pl-2">
                  vs {lastYearMonthLabel}
                </span>
              </span>
            </p>
          </li>
          {/* SNLR */}
          <li className="sales-rate">
            <div className="relative font-semibold text-sm text-secondary-color pb-4">
              <span>
                Sales-To-New <br />
                Listings Rate
              </span>
              <InfoCard
                content="In a seller&#39;s market, the sales-to-listing ratio is generally at 60% or more,
                  which translates to six or more sales for every ten new listings. In a balanced
                  market, the ratio is between 40% and 60%, and in a buyer&#39;s market, you&#39;re
                  looking at fewer than four sales for every ten new listings."
              />
            </div>
            <div className="text-2xl sm:text-5xl font-semibold text-accent-color">
              {getSalesToNewListingsRate(salesToNewListingsRate)}%
              <span className="block font-bold text-secondary-color text-base">
                {percentageFormat(salesToNewListingsRateDiff)}
                <span className="text-default-color text-sm font-normal pl-2">
                  in {lastYearMonthLabel}
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}
