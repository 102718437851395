import styled from 'styled-components'

export const Root = styled.div`
  display: block;
  margin: 0;
`
export const PortalBar = styled.div`
  display: flex;
  flex-direction: row;
  /* height: 56px; */
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid var(--primary-soft-color);
  margin: 0;
  padding: 8px 8px;
  @media (min-width: 1024px) {
    padding: 8px 40px;
  }
`
export const Content = styled.div`
  display: block;
  height: calc(100vh - 152px);
  @media (min-width: 1024px) {
    height: calc(100vh - 192px);
  }
`
