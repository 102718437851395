import styled from 'styled-components'

export const AppBodyContainer = styled.div`
  min-height: calc(100vh - 56px);
  position: relative;
  display: flex;
  flex-direction: column;
  & > * {
    flex-grow: 1;
  }
`
