import { size } from '../config'

export module LayoutUtility {
  export const sumYHeight = (doc: PDFKit.PDFDocument, y: number, h: number) => {
    const pageSize = size.h
    if (y + h > pageSize) {
      doc.addPage()
      return { begin: 0, end: h }
    }
    return { begin: y, end: y + h }
  }
}
