import { SoldReportData } from 'views/soldReport'
import { CurrencyHelper } from 'utilities'
import { PdfHelper } from 'utilities/helpers/PdfHelper'
import { Neighbourhood, NeighbourhoodReport } from 'types'
import { chartHeader, renderChart } from '../chart'
import { PropertyKeyLabel } from '../types/types'
import { LayoutUtility } from '../utilities/layoutUtility'

export type NeighborhoodChartProps = {
  neighbourhoodReport: NeighbourhoodReport
  neighbourhood: Neighbourhood
  monthLabel: string
  propertyKeyLabel: PropertyKeyLabel
  soldReportData: SoldReportData
  y?: number
}
export const addNeighborhoodCharts = (
  doc: PDFKit.PDFDocument,
  {
    soldReportData,
    neighbourhood,
    neighbourhoodReport,
    propertyKeyLabel,
    y = 0,
  }: NeighborhoodChartProps,
) => {
  const cols = Object.keys(soldReportData.statistics)
  const statistics = cols.map((col) => soldReportData.statistics[col][neighbourhoodReport.type])
  const neighbourhoodStatistics = cols.map(
    (col) => soldReportData.neighbourhoodStatistics[col][neighbourhoodReport.type],
  )
  const communityStatistics = cols.map(
    (col) => soldReportData.communityStatistics[col][neighbourhoodReport.type],
  )
  const price = {
    statistics: statistics.map((v) => v.soldPrice),
    neighbourhood: neighbourhoodStatistics.map((v) => v.soldPrice),
    community: communityStatistics.map((v) => v.soldPrice),
  }
  const dom = {
    statistics: statistics.map((v) => v.dom),
    neighbourhood: neighbourhoodStatistics.map((v) => v.dom),
    community: communityStatistics.map((v) => v.dom),
  }
  const newListing = {
    statistics: statistics.map((v) => v.soldNumber / v.newListing || 0),
    neighbourhood: neighbourhoodStatistics.map((v) =>
      v.newListing !== 0 ? v.soldNumber / v.newListing : 0,
    ),
    community: communityStatistics.map((v) => v.soldNumber / v.newListing || 0),
    // statistics: statistics.map((v) => v.averageListPrice / v.averagePrice || 0),
    // neighbourhood: neighbourhoodStatistics.map((v) => v.averageListPrice / v.averagePrice || 0),
    // community: communityStatistics.map((v) => v.averageListPrice / v.averagePrice || 0),
  }
  const chartHeight = 360
  let pageY = LayoutUtility.sumYHeight(doc, y, chartHeight)
  PdfHelper.addSVG(
    doc,
    chartHeader({
      chartName: 'Price',
      propertyLabel: propertyKeyLabel.label,
      neighbourhoodName: neighbourhood.name,
      communityName: neighbourhood.community,
    }),
    0,
    pageY.begin,
    { preserveAspectRatio: 'xMaxYMin meet' },
  )
  PdfHelper.addSVG(
    doc,
    renderChart({
      cols,
      data: [
        {
          values: price.statistics,
          color: '#000000',
        },
        {
          values: price.neighbourhood,
          color: '#018363',
        },
        {
          values: price.community,
          color: '#C2C2C2',
        },
      ],
      rowLabel: (v) => CurrencyHelper.toCurrencyShort(v),
    }),
    0,
    pageY.begin + 80,
    { preserveAspectRatio: 'xMaxYMin meet' },
  )
  pageY = LayoutUtility.sumYHeight(doc, pageY.end, chartHeight)
  // Chart - Days on Market
  PdfHelper.addSVG(
    doc,
    chartHeader({
      chartName: 'Days on Market',
      propertyLabel: propertyKeyLabel.label,
      neighbourhoodName: neighbourhood.name,
      communityName: neighbourhood.community,
    }),
    0,
    pageY.begin,
    { preserveAspectRatio: 'xMaxYMin meet' },
  )
  PdfHelper.addSVG(
    doc,
    renderChart({
      cols,
      data: [
        {
          values: dom.statistics,
          color: '#000000',
        },
        {
          values: dom.neighbourhood,
          color: '#018363',
        },
        {
          values: dom.community,
          color: '#C2C2C2',
        },
      ],
      rowLabel: (v) => Math.round(v).toString(),
    }),
    0,
    pageY.begin + 80,
    { preserveAspectRatio: 'xMaxYMin meet' },
  )
  pageY = LayoutUtility.sumYHeight(doc, pageY.end, chartHeight)
  // Chart - Sales-To-New Listings rate
  PdfHelper.addSVG(
    doc,
    chartHeader({
      chartName: 'Sales-To-New Listings Rate',
      propertyLabel: propertyKeyLabel.label,
      neighbourhoodName: neighbourhood.name,
      communityName: neighbourhood.community,
    }),
    0,
    pageY.begin,
    { preserveAspectRatio: 'xMaxYMin meet' },
  )

  PdfHelper.addSVG(
    doc,
    renderChart({
      cols,
      data: [
        {
          values: newListing.statistics,
          color: '#000000',
        },
        {
          values: newListing.neighbourhood,
          color: '#018363',
        },
        {
          values: newListing.community,
          color: '#C2C2C2',
        },
      ],
      rowLabel: (v) => v.toFixed(2),
    }),
    0,
    pageY.begin + 80,
    { preserveAspectRatio: 'xMaxYMin meet' },
  )
  return doc
}
