import * as React from 'react'

function SvgMobile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#mobile_svg__clip0_231:50)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.095.237c-.715.41-.705 2.085-.705 11.677 0 6.82.084 10.912.23 11.185.395.738 1.726.901 7.38.901s6.985-.163 7.38-.901c.146-.273.23-4.374.23-11.21 0-10.274-.02-10.807-.433-11.318l-.435-.536-6.21-.03C9.116-.013 6.04.02 5.698.075c-.243.04-.442.068-.603.16zm8.499 19.914c.256.806-.28 1.153-1.663 1.079-1.4-.076-1.958-.54-1.423-1.184.21-.253.691-.36 1.618-.36 1.13 0 1.341.067 1.468.465z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="mobile_svg__clip0_231:50">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgMobile = React.memo(SvgMobile)
export default MemoSvgMobile
