import React, { useState, useRef, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'
import { FullImageViewerProps } from 'views/shared/components/full-image-viewer/FullImageViewer'
import { ArrowLeft, ArrowRight, VirtualTour } from 'views/shared/components/icons'

interface MultiImageCarouselProps {
  images: string[]
  imageViewer: FullImageViewerProps
  virtualTour?: string
}

export const MultiImageCarousel = ({
  images,
  virtualTour,
  imageViewer,
}: MultiImageCarouselProps) => {
  const carousel = useRef<HTMLDivElement>(null)
  const maxScrollWidth = useRef(0)
  const [currIndex, setCurrIndex] = useState(0)

  const CarouselContainer = styled.div`
    overflow-y: hidden;
  `

  const slidePrev = () => {
    if (currIndex > 0) {
      setCurrIndex((prevState) => prevState - 1)
    }
  }

  const slideNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currIndex <=
        carousel.current.scrollWidth - carousel.current.offsetWidth
    ) {
      setCurrIndex((prevState) => prevState + 1)
    }
  }

  useEffect(() => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currIndex
    }
  }, [currIndex])

  // useEffect(() => {
  //   if (carousel.current !== null) {
  //     maxScrollWidth.current = carousel.current
  //       ? carousel.current.scrollWidth - carousel.current.offsetWidth
  //       : 0
  //   }
  // }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => slidePrev(),
    onSwipedRight: () => slideNext(),
  })

  const myRef = React.useRef()

  const refPassThrough = (el: any) => {
    handlers.ref(el)
    myRef.current = el
  }

  const arrowStyle = 'z-10 h-10 w-10 mx-auto'
  const sliderControl = (isLeft?: boolean) => (
    <button
      type="button"
      onClick={isLeft ? slidePrev : slideNext}
      className={[`${arrowStyle} ${isLeft ? 'fixed left-2' : 'fixed right-2'}`].join('')}
    >
      <span className="" role="img" aria-label={`Arrow ${isLeft ? 'p-auto' : 'p-auto'}`}>
        {isLeft ? (
          <ArrowLeft color="white" style={{ fontSize: '24px' }} />
        ) : (
          <ArrowRight color="white" style={{ fontSize: '24px' }} />
        )}
      </span>
    </button>
  )

  const openImageViewer = (index: number) => {
    imageViewer.setCurrentImage(index)
    imageViewer.setIsViewerOpen(true)
  }

  return (
    <>
      <div className="w-full">
        <div {...handlers} ref={refPassThrough} className="mx-auto w-full">
          <div className="flex justify-between items-center absolute h-full">
            {sliderControl(true)}
            {sliderControl()}
          </div>
          <div className="absolute w-full overflow-hidden">
            {carousel && (
              <CarouselContainer
                ref={carousel}
                className="grid grid-flow-col overflow-x-hidden w-full gap-2"
              >
                {virtualTour && virtualTour !== undefined && (
                  <div className="flex flex-col justify-center items-center bg-alpha-color w-48">
                    <VirtualTour width="2em" height="2em" />
                    <button
                      type="button"
                      className="pt-2 text-sm whitespace-nowrap font-semibold text-primary-color"
                      onClick={() => window.open(virtualTour, '_blank', 'noopener,noreferrer')}
                    >
                      Virtual Tour
                    </button>
                  </div>
                )}
                {images.map((img, i) => (
                  <div className="relative flex w-24 h-24" key={img}>
                    <img
                      alt=""
                      src={img}
                      className="w-full h-full aspect-square object-cover"
                      aria-hidden="true"
                      onClick={() => imageViewer.setCurrentImage(i)}
                      // onClick={() => openImageViewer(i)}
                    />
                  </div>
                ))}
              </CarouselContainer>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
