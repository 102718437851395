import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { appRootId, modalRootId } from './const'
import { ModalLayerHelper } from './modal-layer-helper'
import { BackgroundLayer } from './ModalLayer.styled'
import { ModalLayerOffset, ModalLayerProps } from './types'

export const ModalLayer = (props: ModalLayerProps) => {
  const [offset, setOffset] = useState<ModalLayerOffset>({ left: -1, top: -1, width: 0 })
  const [scrollY, setScrollY] = useState<number | null>(null)
  const { show, className, children, position, style, responsive, onOutsideClick, outsideColor } =
    props
  const appRootRef = React.useRef(document.getElementById(appRootId))
  const modalRootRef = React.useRef(document.getElementById(modalRootId))
  const modalLayerRef = useRef<HTMLDivElement>(null)
  const pinRef = useRef<HTMLDivElement>(null)
  const responsiveKey = ModalLayerHelper.getBoxStyle(responsive)
  const onResize = useCallback(() => {
    setOffset(ModalLayerHelper.getLayerOffset(pinRef.current, position))
    if (!appRootRef.current) return
    if (!appRootRef.current) return
    if (show) {
      scrollY === null && setScrollY(ModalLayerHelper.getScrollTop())
      if (responsiveKey === 'full' && scrollY !== null) {
        appRootRef.current.style.position = 'fixed'
        appRootRef.current.style.top = `-${scrollY}px`
        appRootRef.current.style.width = '100%'
        window.scrollTo(0, 0)
      }
    } else {
      document.body.style.height = '20000px'
      appRootRef.current.removeAttribute('style')
      scrollY !== null && window.scrollTo(0, scrollY)
      document.body.style.height = 'auto'
      setScrollY(null)
    }
  }, [position, responsiveKey, scrollY, show])
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])
  const bgClickCallback: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    if (e.currentTarget === e.target) {
      onOutsideClick && onOutsideClick()
    }
  }
  const bgKeyPressCallback = useCallback(
    (e) => {
      if (e.key === 'Escape' && onOutsideClick) {
        onOutsideClick && onOutsideClick()
      }
    },
    [onOutsideClick],
  )
  const modalLayerPortal =
    modalRootRef.current &&
    ReactDOM.createPortal(
      <div
        style={{
          position: position || 'absolute',
          boxSizing: 'border-box',
          overflow: 'visible',
          marginTop: responsiveKey === 'center' ? `${ModalLayerHelper.getScrollTop()}px` : '0',
          top: '0',
          left: '0',
          width: '100vw',
          minHeight: '100vh',
          display: show ? undefined : 'none',
        }}
        ref={modalLayerRef}
      >
        <BackgroundLayer
          style={{ backgroundColor: outsideColor }}
          onClick={bgClickCallback}
          className={`--modal-layer-bg --${responsiveKey}`}
        />
        <div
          role="button"
          tabIndex={-1}
          onKeyPress={bgKeyPressCallback}
          onClick={bgClickCallback}
          className={[className, '--modal-layer-holder'].join(' ')}
          style={
            (responsiveKey === 'pinned' && {
              overflow: 'visible',
              width: `min-content`,
              position: 'relative',
              left: `${offset.left}px`,
              top: `${offset.top}px`,
              zIndex: '50',
              ...style,
            }) ||
            (responsiveKey === 'full' && {
              position: 'relative',
              minHeight: '100vh',
              height: '100vh',
              width: '100vw',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'stretch',
              alignItems: 'stretch',
              justifyItems: 'stretch',
              alignContent: 'stretch',
              flexFlow: 'wrap',
            }) ||
            (responsiveKey === 'center' && {
              position: 'relative',
              minHeight: '100vh',
              height: 'auto',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              flexFlow: 'wrap',
            }) ||
            (responsiveKey === 'pinned-right' && {
              overflow: 'visible',
              width: `min-content`,
              position: 'absolute',
              right: `${offset.width}px`,
              top: `${offset.top}px`,
              zIndex: '50',
              ...style,
            }) ||
            {}
          }
        >
          {children}
        </div>
      </div>,
      modalRootRef.current,
    )
  return (
    <>
      <div style={{ visibility: 'hidden', height: '0', display: 'block' }} ref={pinRef} />
      {modalLayerPortal}
    </>
  )
}
