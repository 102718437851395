import type UserTypes from 'services/user/UserTypes'

export const paths = {
  dashboard: () => '/dashboard',
}
export const dashboardPaths = {
  main: () => '/dashboard/analytics',
  emailAnalytics: () => '/dashboard/email-analytics',
  users: (query?: Partial<UserTypes.GetUsersParametersType>) =>
    query ? `/dashboard/users/${btoa(JSON.stringify(query))}` : `/dashboard/users/`,
  profile: (uid: string, userListQuery?: Partial<UserTypes.GetUsersParametersType>) =>
    userListQuery
      ? `/dashboard/users/${btoa(JSON.stringify(userListQuery))}/profile/${uid}`
      : `/dashboard/users/profile/${uid}`,
  myProfile: () => '/dashboard/my-profile',
  inviteUser: (userListQuery?: Partial<UserTypes.GetUsersParametersType>) =>
    userListQuery
      ? `/dashboard/users/${btoa(JSON.stringify(userListQuery))}/invite-user`
      : `/dashboard/users/invite-user`,
  agent: (uid?: string, userListQuery?: Partial<UserTypes.GetUsersParametersType>) => {
    if (uid) {
      if (userListQuery) {
        return `/dashboard/users/${btoa(JSON.stringify(userListQuery))}/agent/${uid}`
      }
      return `/dashboard/users/agent/${uid}`
    }
    return `/dashboard/users/agent-contacts`
  },
  agentContacts: (uid: string, path: string) => `${path}/profile/${uid}`,
  soldReportTemplates: () => '/dashboard/sold-report-templates',
  soldReportTemplateForm: () => '/dashboard/sold-report-template-form',
  importContacts: () => '/dashboard/users/import-contacts',
  valuations: () => '/dashboard/valuations',
  confirmation: (vid: string) => `/dashboard/valuations/confirmation/${vid}`,
}
