import React, { ReactElement, useEffect, useState } from 'react'
import { PropertyServices } from 'services/property'
import { Storage } from 'aws-amplify'
import { useSearchParams } from 'utilities/hooks/useSearchParams'
import { PropertyDetails } from 'types/Property'
import { MiniMap } from 'views/map/components/MiniMap'
import { useRecoilValue } from 'recoil'
import { neighbourhoodsState } from 'views/map/state'
import styled from 'styled-components'
import { TextBody } from 'views/shared/components/ui-form/styled'
import { Config } from 'config'
import { PageFooter } from 'views/shared/components/page-footer'
import { Spinner } from 'views/shared/components/spinner'
import { ModalLayer } from 'views/shared/components/modal-layer'
import { PropertyDetailsHeader, PropertyDetailsHeaderInfo } from '../header'
import { PropertyDetailsImageSlider } from '../image-slider/imageSlider'
import { PropertyDetailsInfo } from '../info'
import { ListingHistory } from '../listing-history/listingHistory'
import { BookAShowing } from '../book-a-showing/book-a-showing'
import { SimilarHomes } from '../similar-homes'

const PropertyPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const Container = (): ReactElement => {
  const [property, setProperty] = useState<PropertyDetails | null>(null)
  const [propertyImages, setPropertyImages] = useState<string[]>([])
  const [imageBaseUrl, setImageBaseUrl] = useState<string | null>()
  const searchParams = useSearchParams()
  const [neighbourhoodName, setNeighbourhoodName] = useState<string>()
  const neighbourhoods = useRecoilValue(neighbourhoodsState)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchProperties = async () => {
      setIsLoading(true)
      const propertyDetails = await PropertyServices.getPropertyDetailsByMls(
        searchParams.get('')!.toString(),
      ).finally(() => setIsLoading(false))
      setProperty(propertyDetails)
    }

    fetchProperties()
  }, [searchParams])

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [isLoading])

  useEffect(() => {
    const retrieveImageBaseUrl = async () => {
      const imageFolderUrl = await Storage.get('p/', {
        download: false,
        bucket: Config.getPhotoBucketName(),
      })
      const regexResponse = /(^https:\/\/.*\/)public\/.*\/.*\.*$/.exec(imageFolderUrl) || []
      setImageBaseUrl(`${regexResponse[1]}public` as string)
    }
    retrieveImageBaseUrl()
  }, [])

  useEffect(() => {
    if (property && imageBaseUrl) {
      setPropertyImages(PropertyServices.getPropertyImageURLs(property.summary, imageBaseUrl))
    }
  }, [property, imageBaseUrl])

  useEffect(() => {
    const fetchNeighbourhoodName = () => {
      const name = neighbourhoods.find(
        (neighbourhood) => neighbourhood.id === property?.summary.neighbour,
      )?.name
      setNeighbourhoodName(name)
    }
    fetchNeighbourhoodName()
  }, [property])

  return (
    <>
      {(!property || isLoading) && (
        <ModalLayer
          show={isLoading}
          responsive={{
            small: 'center',
          }}
          outsideColor="rgba(0, 0, 0, 0.5)"
        >
          <div className="max-w-sm bg-white rounded-lg">
            <div className="flex flex-col justify-center h-36">
              <Spinner className="h-36" />
            </div>
          </div>
        </ModalLayer>
      )}
      {property && (
        <>
          <div className="p-5 xl:px-20 max-w-screen-2xl mx-auto w-full">
            <div className="my-6">
              <PropertyDetailsHeader propertyDetails={property} neighbourhood={neighbourhoodName} />
            </div>
            <div className="my-6">
              {property?.summary?.opens && property.summary.opens.length > 0 && (
                <div className="z-10 inset-0 overflow-y-auto font-bold">
                  <TextBody color="var(--warning-color)" fontSize={12} fontWeight={600}>
                    Open House
                  </TextBody>
                  {property.summary.opens.map((o) => (
                    <TextBody key={o} color="var(--warning-color)" fontSize={18} fontWeight={600}>
                      {o}
                    </TextBody>
                  ))}
                </div>
              )}
            </div>
            <PropertyPageContainer className="space-y-10 lg:space-x-10">
              <div>
                <PropertyDetailsHeaderInfo property={property?.summary} />
                <div>
                  <PropertyDetailsImageSlider
                    images={propertyImages}
                    virtualTour={property?.virtualTour}
                  />
                </div>
                <div className="hidden lg:block">
                  <PropertyDetailsInfo property={property} />
                </div>
              </div>
              <div className="space-y-10">
                <div className="">
                  <ListingHistory histories={property?.histories} current={property?.summary.mls} />
                </div>
                <div className="">
                  <MiniMap property={property} neighbourhood={neighbourhoodName} />
                </div>
                <div className="hidden lg:block">
                  <BookAShowing mls={property?.summary.mls} />
                </div>
              </div>
              <div className="block lg:hidden">
                <PropertyDetailsInfo property={property} />
              </div>
              <div className="block lg:hidden">
                <BookAShowing mls={property?.summary.mls} />
              </div>
            </PropertyPageContainer>
            <div className="my-6">
              {property && imageBaseUrl && (
                <SimilarHomes
                  similarActive={property?.similarActive}
                  similarSold={property.similarSold}
                  imageBaseUrl={imageBaseUrl}
                  propertyType={property.summary.oriType}
                  neighbourhood={neighbourhoodName}
                  saleType={property.summary.saleType}
                />
              )}
            </div>
          </div>
          <PageFooter />
        </>
      )}
    </>
  )
}
