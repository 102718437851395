import React, { FC, createContext, useContext } from 'react'

import { AuthContext } from 'views/authentication'
import { WithChildren } from 'types'

type AnalyticsContextProps = {
  analytics: any
}
type AnalyticsContextProviderProps = WithChildren<unknown>

export const AnalyticsContext = createContext<AnalyticsContextProps>({} as AnalyticsContextProps)

// adding this function to avoid AWS Pinpoint to close session when the webpage (tab) is invisible
const getVisibilityChangeName = (): string | undefined => {
  if (typeof document.hidden !== 'undefined') {
    return 'visibilitychange'
    // @ts-ignore
    // eslint-disable-next-line no-else-return
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange'
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
  return undefined
}

const stopEventPropagation = (e: Event): void => {
  e.stopPropagation()
}

const visibilityChangeName = getVisibilityChangeName()

export const AnalyticsContextProvider: FC<AnalyticsContextProviderProps> = ({ children }) => {
  const { authUser } = useContext(AuthContext)
  const analytics = {
    user: authUser,
  }

  React.useEffect(() => {
    if (visibilityChangeName) {
      window.addEventListener(visibilityChangeName, stopEventPropagation, true)
    }
    return () => {
      if (visibilityChangeName) {
        window.removeEventListener(visibilityChangeName, stopEventPropagation, true)
      }
    }
  }, [])

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
