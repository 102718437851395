/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
import { SoldReportServices } from 'views/soldReport'
import { DateHelper, MonthYearType } from 'utilities'
import { PdfHelper } from 'utilities/helpers/PdfHelper'
import { Neighbourhood, NeighbourhoodReport } from 'types'
import { PropertyKeyLabel } from '../types/types'
import { Pluralize } from '../../helper'

export type PropertyTypeSnapshotProps = {
  neighbourhoodReport: NeighbourhoodReport
  neighbourhood: Neighbourhood
  monthLabel: string
  propertyKeyLabels: PropertyKeyLabel[]
}

export const addPropertyTypeSnapshot = (
  doc: PDFKit.PDFDocument,
  { neighbourhoodReport }: PropertyTypeSnapshotProps,
) => {
  const currentMonth = DateHelper.monthStrToYearAndMonth(neighbourhoodReport.month)
  const lastYearMonth: MonthYearType = { year: currentMonth.year - 1, month: currentMonth.month }
  const lastYearMonthLabel = DateHelper.monthStrToShortMonthLabel(lastYearMonth)
  const cadFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })

  const avgSellingPriceDiff = Math.round(
    (neighbourhoodReport.averagePrice / neighbourhoodReport.averagePriceLastYear - 1) * 100,
  )
  const soldNumberDiff = Math.round(
    (neighbourhoodReport.soldNumber / neighbourhoodReport.soldNumberLastYear) * 100,
  )
  const avgSellingToListPriceDiff = Math.round(
    (neighbourhoodReport.averageListPrice / neighbourhoodReport.averageListPriceLastYear) * 100,
  )
  const daysOnMarketDiff = Math.round(neighbourhoodReport.dom - neighbourhoodReport.domLastYear)
  const salesToNewListingsRateDiff = Math.round(
    (neighbourhoodReport.soldNumberLastYear / neighbourhoodReport.newListingLastYear) * 100,
  )

  const template = /* svg */ `
  <svg width="1338" height="340" viewBox="0 0 1338 340" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- name -->
    <text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" letter-spacing="0em"><tspan x="140" y="83.557">${Pluralize(
      SoldReportServices.getPropertyTypeLabel(neighbourhoodReport.type),
      neighbourhoodReport.soldNumber,
    )} </tspan></text>
    <text fill="#417A9F" xml:space="preserve" style="white-space: pre;" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"> <tspan x="70.834" y="84.057">${
      neighbourhoodReport.soldNumber
    } </tspan></text>
    <line x1="75" y1="108.5" x2="1266" y2="108.5" stroke="#C4C4C4"/>

    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="861" y="191.736">Days On Market</tspan></text>
    <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="861" y="247.557">${Math.round(
      neighbourhoodReport.dom,
    )}</tspan></text>
    
    <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="573" y="247.557">${Math.round(
      neighbourhoodReport.averageSellingToListPrice * 100,
    )}%</tspan></text>
    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="573" y="171.736">Average Selling &#10;</tspan><tspan x="573" y="195.736">To List Price</tspan></text>
    
    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="1098" y="171.736">Sales-to-New&#x2028;</tspan><tspan x="1098" y="195.736">Listings Rate</tspan></text>
    <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="1098" y="247.557">${
      neighbourhoodReport.newListing === 0
        ? 0
        : Math.round((neighbourhoodReport.soldNumber / neighbourhoodReport.newListing) * 100) || '0'
    }%</tspan></text>
    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="78" y="191.736">Average Selling Price</tspan></text>
    <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="75" y="247.557">${cadFormat.format(
      neighbourhoodReport.averagePrice,
    )}</tspan></text>
    
    <text fill="#018363" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="375" y="247.557">${
      neighbourhoodReport.soldNumber
    }</tspan></text>
    <text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="375" y="191.736">Sold Listings</tspan></text>

    <!-- compare last year -->
    <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" letter-spacing="0em"><tspan x="75" y="272.019">${
      avgSellingPriceDiff > 0 && Number.isFinite(avgSellingPriceDiff)
        ? `${avgSellingPriceDiff}%`
        : '--'
    } vs ${lastYearMonthLabel}</tspan></text>
    <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" letter-spacing="0em"><tspan x="375" y="272.019">${
      soldNumberDiff > 0 && Number.isFinite(soldNumberDiff) ? `${soldNumberDiff}%` : '--'
    } vs ${lastYearMonthLabel}</tspan></text>
    <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" letter-spacing="0em"><tspan x="573" y="272.019">${
      avgSellingToListPriceDiff > 0 && Number.isFinite(avgSellingToListPriceDiff)
        ? `${avgSellingToListPriceDiff}%`
        : '--'
    } vs ${lastYearMonthLabel}</tspan></text>
    <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" letter-spacing="0em"><tspan x="861" y="272.019">${
      (daysOnMarketDiff > 0 ? `+${daysOnMarketDiff}` : daysOnMarketDiff) ||
      (Number.isNaN(daysOnMarketDiff) ? '--' : daysOnMarketDiff)
    } vs ${lastYearMonthLabel}</tspan></text>
    <text fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="14" letter-spacing="0em"><tspan x="1098" y="272.019">${
      salesToNewListingsRateDiff > 0 && Number.isFinite(salesToNewListingsRateDiff)
        ? `${salesToNewListingsRateDiff}%`
        : '--'
    } in ${lastYearMonthLabel}</tspan></text>
  </svg>
  `
  PdfHelper.addSVG(doc, template, 0, 100, { preserveAspectRatio: 'xMaxYMin meet' })
  return doc
}
