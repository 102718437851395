import { paths } from 'paths'
import React from 'react'
import { RouteLink, Title } from 'views/shared/components/ui-form'

export const SignUpWaitlistValidation = () => (
  <>
    <div className="max-w-2xl mx-auto pt-10 text-center">
      <Title className="pb-6">Thank you for joining our waitlist</Title>
      <RouteLink className="underline" to={paths.homepage()}>
        Back to homepage
      </RouteLink>
    </div>
  </>
)
