import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'debounce'
import { Switch } from '@headlessui/react'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'paths'
import { AddressSearch } from 'views/shared/components/address-search/AddressSearch'
import {
  PrimaryButton,
  PrimaryNegativeButton,
  DropdownField,
} from 'views/shared/components/ui-form'
import Filters from 'views/shared/components/icons/Filters'
import { useFiltersStore, useHomeSearchStore, initialFilters } from 'store/homeSearch'
import {
  RangeSelector,
  RangeSelectorValueType,
} from 'views/shared/components/ui-form/range-selector'
import { PropertyServices } from 'services/property'
import styled from 'styled-components'
import { FilterContainer } from './SearchBar.styled'
import { HomeSearchFilterView } from '../FilterView'
import { CapsuleButtonComponent } from './CapsuleButton'
import { PropertyTypeField } from '../Shared/PropertyTypeField'

const PropertiesResult = styled.div`
  z-index: 1;
`

export const SearchBar = () => {
  const history = useHistory()
  const params = useParams<any>()
  const [propertyTypeRef, setPropertyTypeRef] = useState<HTMLElement | null>(null)
  const [showActiveRef, setShowActiveRef] = useState<HTMLElement | null>(null)

  const {
    // filterForm,
    markersState,
    propertiesState,
    numberOfPropertiesFound,
  } = useHomeSearchStore()
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const { filtersState, setFiltersState } = useFiltersStore()
  const [filterCount, setFilterCount] = useState<number>(0)

  const onToggleChange = () => {
    const saleType = filtersState.saleType === 'sale' ? 'lease' : 'sale'
    setFiltersState((oldState) => ({
      ...oldState,
      saleType,
      minPrice: undefined,
      maxPrice: undefined,
    }))
    history.replace(paths.homeSearch(params.mapType, saleType))
  }

  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const toggleFilter = useCallback(() => {
    if (!isFilterOpen) {
      setIsFilterOpen(true)
    }
  }, [isFilterOpen])
  const resetFilters = useCallback(() => {
    setFiltersState((oldState) => ({
      ...initialFilters,
      saleType: oldState.saleType,
      topLeft: oldState.topLeft,
      bottomRight: oldState.bottomRight,
    }))
    setIsFilterOpen(false)
  }, [isFilterOpen])

  useEffect(() => {
    const escapeCallback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsFilterOpen(false)
      }
    }
    window.addEventListener('keyup', escapeCallback)
    return () => {
      window.removeEventListener('keyup', escapeCallback)
    }
  }, [])

  useEffect(() => {
    const cacheQuickViews = async () => {
      const ids = (markersState?.markers || [])
        .filter((marker) => marker.count === 1)
        .flatMap((marker) => marker.ids)
      await PropertyServices.getPropertyQuickView(ids)
    }
    cacheQuickViews()
  }, [markersState])

  useEffect(() => {
    const propertyTypeHidden =
      propertyTypeRef && window.getComputedStyle(propertyTypeRef).display === 'none'
    const showActiveHidden =
      showActiveRef && window.getComputedStyle(showActiveRef).display === 'none'

    const { types } = filtersState
    const filterDiffs = [
      initialFilters.bath !== (filtersState.bath || null),
      initialFilters.parking !== (filtersState.parking || null),
      initialFilters.minLotFront !==
        (filtersState.minLotFront === 0 ? 0 : filtersState.minLotFront || null),
      initialFilters.minLotDepth !==
        (filtersState.minLotDepth === 0 ? 0 : filtersState.minLotDepth || null),
      initialFilters.minsqft !== (filtersState.minsqft || null),
      initialFilters.maxsqft !== (filtersState.maxsqft || null),
      initialFilters.openHouse !== filtersState.openHouse,
      propertyTypeHidden && types !== null && !(types?.length === 1 && types[0] === 'all'),
      showActiveHidden &&
        initialFilters.showActive !==
          (filtersState.showActive === 0 ? 0 : filtersState.showActive || null),
      showActiveHidden && initialFilters.showSold !== (filtersState.showSold || null),
    ].filter((f) => f)
    setFilterCount(filterDiffs.length)
  }, [filtersState, dimensions])

  const handlePriceChange = debounce(async (priceRange: RangeSelectorValueType) => {
    setFiltersState((oldState) => ({
      ...oldState,
      minPrice: priceRange.min,
      maxPrice: priceRange.max === 0 ? undefined : priceRange.max,
    }))
  })
  const handleActiveDaysChange = (days: string | undefined) => {
    setFiltersState((oldState) => ({
      ...oldState,
      showActive: days ? Number(days) : undefined,
    }))
  }
  const handleSoldDaysChange = (days: string | undefined) => {
    setFiltersState((oldState) => ({
      ...oldState,
      showSold: days ? Number(days) : undefined,
    }))
  }
  const handleBedChange = (bed: string) => {
    setFiltersState((oldState) => ({
      ...oldState,
      bed: Number(bed),
    }))
  }
  const getBedLabel = () => `${filtersState.bed}+ bd`

  return (
    <>
      <div className="p-2 lg:px-10 bg-alpha-color border-b border-primary-soft-color box-border">
        <div className="lg:flex items-center justify-between space-y-3 lg:space-y-0 lg:space-x-3">
          <div className="space-y-3 lg:space-y-0 lg:flex md:items-center w-full">
            <div className="flex min-w-min">
              <Switch
                checked={filtersState.saleType !== 'sale'}
                onChange={onToggleChange}
                className={[
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-primary-color rounded-full bg-primary-color cursor-pointer transition-colors ease-in-out duration-200',
                ].join(' ')}
              >
                <span
                  aria-hidden="true"
                  className={[
                    filtersState.saleType === 'lease' ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ].join(' ')}
                />
              </Switch>
              <div className="w-20 mx-3 font-semibold">
                {filtersState.saleType === 'sale' ? 'For Sale' : 'For Lease'}
              </div>
            </div>
            <div className="flex-grow">
              <AddressSearch title="Address, Neighbourhood, or MLS #" />
            </div>
          </div>
          <div className="flex items-center justify-between space-x-3">
            <div className="flex-1 lg:w-64  whitespace-nowrap">
              <RangeSelector
                value={{
                  max: filtersState.maxPrice,
                  min: filtersState.minPrice,
                }}
                range={{
                  min: 0,
                  max: filtersState.saleType === 'sale' ? 20000000 : 25000,
                }}
                type={filtersState.saleType}
                onChange={handlePriceChange}
              />
            </div>
            <div ref={setPropertyTypeRef} className="hidden 2xl:block whitespace-nowrap">
              <PropertyTypeField />
            </div>
            <div>
              <DropdownField
                value={filtersState.bed?.toString()}
                onChange={handleBedChange}
                options={[
                  { key: '0', label: '0+ bd' },
                  { key: '1', label: '1+ bd' },
                  { key: '2', label: '2+ bd' },
                  { key: '3', label: '3+ bd' },
                  { key: '4', label: '4+ bd' },
                ]}
                name="bedUpdate"
                CustomValue={getBedLabel()}
              />
            </div>
            <div ref={setShowActiveRef} className="hidden xl:flex">
              {/* show active */}
              <div className="w-32">
                <DropdownField
                  value={
                    filtersState.showActive?.toString() === undefined
                      ? 'All'
                      : filtersState.showActive?.toString()
                  }
                  onChange={handleActiveDaysChange}
                  options={[
                    { key: undefined, label: 'All' },
                    { key: '0', label: 'Hide' },
                    { key: '1', label: '1 d' },
                    { key: '3', label: '3 d' },
                    { key: '7', label: '7 d' },
                    { key: '30', label: '30 d' },
                    { key: '60', label: '60 d' },
                  ]}
                  name="Active"
                  CustomButton={CapsuleButtonComponent}
                />
              </div>
              {/* show sold */}
              <div className="w-32">
                <DropdownField
                  value={
                    filtersState.showSold?.toString() === undefined
                      ? 'Hide'
                      : filtersState.showSold?.toString()
                  }
                  onChange={handleSoldDaysChange}
                  options={[
                    { key: undefined, label: 'Hide' },
                    { key: '1', label: '1 d' },
                    { key: '7', label: '7 d' },
                    { key: '30', label: '30 d' },
                    { key: '60', label: '60 d' },
                    { key: '91', label: '3 m' },
                    { key: '182', label: '6 m' },
                    { key: '365', label: '1 y' },
                  ]}
                  name={filtersState.saleType === 'sale' ? 'Sold' : 'Leased'}
                  CustomButton={CapsuleButtonComponent}
                />
              </div>
            </div>
            <div>
              <div className="flex align-middle items-center h-12 w-12">
                <button
                  className=" text-primary-color font-semibold text-xs appearance-none flex flex-col items-center"
                  type="button"
                  onClick={() => toggleFilter()}
                >
                  <Filters style={{ fontSize: '18px' }} color="var(--primary-color)" />
                  <span>
                    {filterCount !== 0 && (
                      <span className="text-secondary-color">{filterCount} </span>
                    )}
                    Filters
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {numberOfPropertiesFound !== null && (
        <PropertiesResult className="flex justify-center relative">
          <div className="absolute bg-accent-color text-sm text-white px-8 py-1 rounded-b-lg">
            <span className="font-bold">
              {numberOfPropertiesFound === 10000 ? 'More than ' : ''}
            </span>
            <span className="font-bold">{numberOfPropertiesFound}</span>{' '}
            <span className="font-medium">properties found</span>
          </div>
        </PropertiesResult>
      )}
      {params.mapType === 'gallery' && propertiesState && (
        <PropertiesResult className="flex justify-center relative">
          <div className="absolute bg-accent-color text-sm text-white px-8 py-1 rounded-b-lg">
            <span className="font-bold">{propertiesState.total}</span>{' '}
            <span className="font-medium">Properties found</span>
          </div>
        </PropertiesResult>
      )}
      {isFilterOpen && (
        <>
          <div>
            <FilterContainer className="scrollbar scrollbar-track-transparent scrollbar-thumb-primary-color scrollbar-thin scrollbar-thumb-rounded-md">
              <HomeSearchFilterView />
              <div className="space-y-3 px-2">
                <PrimaryNegativeButton onClick={resetFilters}>Reset</PrimaryNegativeButton>
                <PrimaryButton
                  onClick={() => {
                    setIsFilterOpen(false)
                  }}
                >
                  Close
                </PrimaryButton>
              </div>
            </FilterContainer>
          </div>
        </>
      )}
    </>
  )
}
