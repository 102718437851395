import React, { useState } from 'react'
import { NeighbourhoodServices } from 'services/neighbourhood'
import HomeValuationAnnualReport from 'types/HomeValuationAnualReport'
import { PrimaryButton } from 'views/shared/components/ui-form'
import { DocInfoType, PdfAnnualReport } from './pdf'

const getAnnualReportData = async (): Promise<HomeValuationAnnualReport.Valuation[]> => {
  const r = (await fetch('/assets/report-data3.json').then((v) =>
    v.json(),
  )) as HomeValuationAnnualReport.Valuation[]
  if (!r) {
    return [] as HomeValuationAnnualReport.Valuation[]
  }
  return r
}

export const ValuationAnnualReport = () => {
  const [docs, setDocs] = useState<NonNullable<DocInfoType>[]>([])
  const [url, setUrl] = useState(`about:blank`)
  const [loading, setLoading] = useState(false)

  const generateAnnualReports = async () => {
    setLoading(true)

    const [response, neighbourhoods] = await Promise.all([
      getAnnualReportData(),
      NeighbourhoodServices.getNeighbourhoods(),
    ])

    const resultPromises = response.map(async (report) =>
      PdfAnnualReport.generate(report, neighbourhoods),
    )
    const results = (await Promise.all(resultPromises)).filter(
      (value) => value !== undefined,
    ) as NonNullable<DocInfoType>[]

    setDocs(results)
    setUrl(results[0].url)
    setLoading(false)
  }

  return (
    <>
      <div className="max-w-7xl mx-auto p-16 text-center">
        <PrimaryButton type="button" onClick={() => generateAnnualReports()}>
          Generate
        </PrimaryButton>
        {loading ? (
          <div className="text-center">loading</div>
        ) : (
          <iframe src={url} title="pdf" style={{ height: `70vh`, width: `80vw` }} />
        )}
      </div>
    </>
  )
}
