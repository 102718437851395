import * as React from 'react'

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.934 3.452c-.628.203-.648.222-4.473 4.105L9.45 14.675l-3.555-3.607c-1.748-1.722-2.029-1.992-2.402-2.17-.948-.45-1.957-.308-2.709.383a2.48 2.48 0 00-.748 2.213c.147.811.055.706 3.457 4.102 4.335 4.396 4.604 4.657 4.97 4.82.465.205 1.109.268 1.539.15.727-.201-.428.958 8.882-8.511C24.371 6.469 23.82 7.07 23.967 6.2c.203-1.204-.54-2.376-1.741-2.746-.338-.105-.97-.105-1.292-.002z"
        fill={props.color || 'var(--default-icon-color, #000)'}
      />
    </svg>
  )
}

const MemoSvgCheck = React.memo(SvgCheck)
export default MemoSvgCheck
