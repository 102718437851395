import * as React from 'react'

function SvgLogins(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`--icon ${props.className || ''}`}
      {...props}
    >
      <g clipPath="url(#logins_svg__clip0_231:45)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.682.364c-1.23.667-1.772 1.822-1.773 3.787-.001 1.775.27 2.365 1.092 2.365.794 0 1.092-.598 1.092-2.186 0-2.21-.05-2.186 4.372-2.186 3.058 0 3.742.059 4.028.344.301.301.343 1.474.343 9.573 0 6.887-.067 9.296-.262 9.492-.184.183-1.39.262-4.028.262-4.513 0-4.453.03-4.453-2.185 0-1.578-.299-2.186-1.074-2.186-.79 0-1.111.703-1.108 2.421.005 1.933.547 3.095 1.742 3.73.665.355 1.29.406 4.917.405 4.642 0 5.13-.13 6.016-1.582.416-.682.434-1.118.435-10.296 0-10.591.003-10.571-1.392-11.567-.644-.459-.931-.49-4.946-.54-3.78-.046-4.345-.006-5 .349zM6.898 6.915c-.199.22-.362.712-.362 1.093v.693H3.62c-3.751 0-3.64-.101-3.64 3.335 0 2.013.07 2.606.343 2.879.278.278.902.343 3.278.343h2.935v.75c0 .908.538 1.51 1.23 1.377.724-.138 7.257-4.565 7.412-5.023.072-.21.073-.554.003-.765-.147-.442-7.067-5.081-7.579-5.081-.188 0-.505.18-.704.4z"
          fill={props.color || 'var(--default-icon-color, #000)'}
        />
      </g>
      <defs>
        <clipPath id="logins_svg__clip0_231:45">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgLogins = React.memo(SvgLogins)
export default MemoSvgLogins
