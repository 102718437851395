import { atom, selector } from 'recoil'
import { Neighbourhood } from 'types'
import { neighbourhoodsState } from 'views/map/state'

export const localFollowedNeighbourhoodIdsState = atom<string[] | null>({
  key: 'follows.localNeighbourhoodIds',
  default: null,
})

export const followedNeighbourhoodsState = selector({
  key: 'follows.neighbourhoods',
  get: ({ get }) =>
    [...(get(localFollowedNeighbourhoodIdsState) || [])]
      .map((id) => get(neighbourhoodsState).find((neighbourhood) => neighbourhood.id === id))
      .filter((neighbourhood) => neighbourhood) as Neighbourhood[],
})
