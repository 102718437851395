/* eslint-disable @typescript-eslint/no-unused-vars */

import { size } from '../config'

/* eslint-disable no-useless-concat */
export type ChartHeaderProps = {
  propertyLabel: string
  chartName: string
  neighbourhoodName: string
  communityName: string
}
export const chartHeader = ({
  chartName,
  propertyLabel,
  neighbourhoodName,
  communityName,
}: ChartHeaderProps) => /* svg */ `<svg width="1341" height="120" viewBox="0 0 1341 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1340" height="120" transform="translate(0.396973)" fill="white"/>
<rect x="0.396973" width="1340" height="120" fill="white"/>
<text fill="#28292E" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="42" font-weight="600" letter-spacing="0em"><tspan x="75.897" y="78.9933">${chartName}</tspan></text>
<line x1="76.897" y1="102.936" x2="1266.9" y2="102.936" stroke="#C4C4C4"/>
<text fill="#417A9F" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="24" letter-spacing="0em"><tspan x="75.897" y="38.0403">${propertyLabel} </tspan></text>

<text text-anchor="end" fill="#62727A" xml:space="preserve" style="white-space: pre" font-family="Montserrat" font-size="16" letter-spacing="0em" x="1270"  y="74">
<tspan  fill="#018363" font-size="50" dy="10">\u2022</tspan>
<tspan dy="-8">${neighbourhoodName}</tspan>
<tspan  fill="#6B7280" font-size="50" dy="10" dx="20">\u2022</tspan>
<tspan dy="-8">${communityName}</tspan>
<tspan  fill="#000" font-size="50" dy="10" dx="20">\u2022</tspan>
<tspan  dy="-8">GTA</tspan>
</text>
</svg
`
