/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { AnalyticsServices } from 'services/analytics'
import { GetEmailAnalyticsResponse } from 'views/dashboard/types/analytics'
import { ArrowRight } from 'views/shared/components/icons'
import styled from 'styled-components'
import { TextBody } from 'views/shared/components/ui-form/styled/text.styled'
import { JS } from 'aws-amplify'
import { StatsNumber, StatsTitle, Button, Subtitle } from './styles'

const ListWrap = styled.div`
  position: absolute;
  width: 334px;
  max-height: 100%;
  right: 0;
  top: 266px;
  background: #ffffff;
  box-shadow: -6px 0px 16px rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
  z-index: 1;
  border-radius: 8px 0px 0px 8px;
`
const Item = styled.li`
  border-top: 1px solid var(--alpha-color);
  padding: 12px 0px;
  /* cursor: pointer; */
`

type SoldReportEmailProps = {
  month: string
  isAgent: boolean
}

export const SoldReportEmail = ({ month, isAgent }: SoldReportEmailProps) => {
  const [analytics, setAnalytics] = useState<GetEmailAnalyticsResponse[]>()

  useEffect(() => {
    const fetchAnalytics = async () => {
      await AnalyticsServices.getEmailAnalytics(month, isAgent)
        .then((res) => {
          setAnalytics(res)
        })
        .catch((e) => {
          console.error(e)
        })
    }
    fetchAnalytics()
  }, [month])

  const sentAggs = analytics?.find((a) => a.feedType === '_email.send')
  const openAggs = analytics?.find((a) => a.feedType === '_email.open')
  const clickAggs = analytics?.find((a) => a.feedType === '_email.click')
  const unsubscribeAggs = analytics?.find((a) => a.feedType === '_email.unsubscribe')
  // TODO: show the spam rate (complaintAggs)
  // const complaintAggs = analytics?.find((a) => a.feedType === '_email.complaint')

  // Open Rate Pie Chart Mock
  const totalEmailsSent = sentAggs?.count || 0
  const totalEmailsOpen = openAggs?.count || 0
  const openRate =
    totalEmailsSent === 0 ? 0 : ((totalEmailsOpen / totalEmailsSent) * 100).toFixed(1)
  const openRateSlice = totalEmailsSent === 0 ? 0 : (totalEmailsOpen / totalEmailsSent) * 360

  // Click Through Pie Chart Mock
  const totalClickThrough = clickAggs?.count || 0
  const clickThroughRate =
    totalEmailsSent === 0 ? 0 : ((totalClickThrough / totalEmailsSent) * 100).toFixed(1)
  const clickThroughSlice = totalEmailsSent === 0 ? 0 : (totalClickThrough / totalEmailsSent) * 360

  // Unsubscribes Pie Chart Mock
  const totalUnsubscribeRate = unsubscribeAggs?.count || 0
  const unsubscribeRate =
    totalEmailsSent === 0 ? 0 : ((totalUnsubscribeRate / totalEmailsSent) * 100).toFixed(1)
  const unsubscribeSlice =
    totalEmailsSent === 0 ? 0 : (totalUnsubscribeRate / totalEmailsSent) * 360

  // --- CHARTS --- //
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        displayColors: false,
      },
    },
  }
  // Open Rate Chart
  const openRateData = {
    labels: [],
    type: 'pie',
    datasets: [
      {
        data: [openRate],
        backgroundColor: '#417A9F',
        borderWidth: 0,
        radius: '100%',
        circumference: openRateSlice,
      },
    ],
  }
  // Click Through Chart
  const clickThroughRateData = {
    labels: [],
    type: 'pie',
    datasets: [
      {
        data: [clickThroughRate],
        backgroundColor: '#417A9F',
        borderWidth: 0,
        radius: '100%',
        circumference: clickThroughSlice,
      },
    ],
  }
  // Click Through Chart
  const unsubscribeRateData = {
    labels: [],
    type: 'pie',
    datasets: [
      {
        data: [unsubscribeRate],
        backgroundColor: '#417A9F',
        borderWidth: 0,
        radius: '100%',
        circumference: unsubscribeSlice,
      },
    ],
  }

  const [isOpenRateOpen, setIsOpenRateOpen] = useState<boolean>(false)
  const [selectedOpenRate, setSelectedOpenRate] = useState(false)

  const [isClickThroughtOpen, setIsClickThroughtOpen] = useState<boolean>(false)
  const [selectedClickThrought, setSelectedClickThrought] = useState(false)

  const [isUnsubscribesOpen, setIsUnsubscribesOpen] = useState<boolean>(false)
  const [selectedUnsubscribes, setSelectedUnsubscribes] = useState(false)

  return (
    <>
      <div className="mb-6 relative">
        <div className="flex items-baseline space-x-5  pr-10">
          <Subtitle className="flex-shrink-0">Email</Subtitle>
          <p className="h-px w-full border border-dashed border-alpha-darker-color" />
        </div>
        <TextBody>
          <span className="font-semibold">{totalEmailsSent}</span> sent
        </TextBody>
      </div>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <li>
          <Button
            className="p-5"
            selected={isOpenRateOpen}
            onClick={() => {
              setIsOpenRateOpen(true)
              setIsClickThroughtOpen(false)
              setIsUnsubscribesOpen(false)
            }}
          >
            <div className={['h-16 w-16', totalEmailsOpen > 0 ? ' block' : ' hidden'].join('')}>
              <Pie data={openRateData} options={options} />
            </div>
            <div>
              <StatsNumber color="var(--primary-color)">{openRate}%</StatsNumber>
              <StatsTitle>Open rate</StatsTitle>
            </div>
          </Button>
          {isOpenRateOpen ? (
            <ListWrap>
              <button
                className="absolute -left-5 top-4 bg-white h-10 w-10 rounded-full flex items-center justify-center shadow-lg cursor-pointer"
                type="button"
                onClick={() => setIsOpenRateOpen(false)}
              >
                <ArrowRight color="var(--primary-color)" />
              </button>
              <div className="flex items-center justify-center space-x-5 mb-10 mt-10">
                <div className={['h-16 w-16', totalEmailsOpen > 0 ? ' block' : ' hidden'].join('')}>
                  <Pie data={openRateData} options={options} />
                </div>
                <div>
                  <StatsNumber color="var(--primary-color)">{openRate}%</StatsNumber>
                  <StatsTitle>Open rate</StatsTitle>
                </div>
              </div>
              <div className="px-5 overflow-y-scroll max-h-96">
                {openAggs?.userAndCounts.map((item) => (
                  <>
                    <ul className="divide-y" key={item.uid}>
                      <Item>
                        <div className="flex justify-between">
                          <p className="text-accent-color font-semibold">{item.name}</p>{' '}
                          <p className="text-xs font-semibold">{item.count}</p>
                        </div>
                        <TextBody fontSize={12}>{item.email}</TextBody>
                      </Item>
                    </ul>
                  </>
                ))}
              </div>
            </ListWrap>
          ) : (
            ''
          )}
        </li>
        <li>
          <Button
            selected={isClickThroughtOpen}
            onClick={() => {
              setIsClickThroughtOpen(true)
              setIsOpenRateOpen(false)
              setIsUnsubscribesOpen(false)
            }}
            className="p-5"
          >
            <div className={['h-16 w-16', totalClickThrough > 0 ? ' block' : ' hidden'].join('')}>
              <Pie data={clickThroughRateData} options={options} />
            </div>
            <div>
              <StatsNumber color="var(--primary-color)">{clickThroughRate}%</StatsNumber>
              <StatsTitle>Click through</StatsTitle>
            </div>
          </Button>
          {isClickThroughtOpen ? (
            <ListWrap>
              <button
                className="absolute -left-5 top-4 bg-white h-10 w-10 rounded-full flex items-center justify-center shadow-lg cursor-pointer"
                type="button"
                onClick={() => setIsClickThroughtOpen(false)}
              >
                <ArrowRight color="var(--primary-color)" />
              </button>
              <div className="flex items-center justify-center space-x-5 mb-10 mt-10">
                <div
                  className={['h-16 w-16', totalClickThrough > 0 ? ' block' : ' hidden'].join('')}
                >
                  <Pie data={clickThroughRateData} options={options} />
                </div>
                <div>
                  <StatsNumber color="var(--primary-color)">{clickThroughRate}%</StatsNumber>
                  <StatsTitle>Click through</StatsTitle>
                </div>
              </div>
              <div className="px-5">
                {clickAggs?.userAndCounts.map((item) => (
                  <>
                    <ul className="divide-y" key={item.uid}>
                      <Item>
                        <div className="flex justify-between">
                          <p className="text-accent-color font-semibold">{item.name}</p>{' '}
                          <p className="text-xs font-semibold">{item.count}</p>
                        </div>
                        <TextBody fontSize={12}>{item.email}</TextBody>
                      </Item>
                    </ul>
                  </>
                ))}
              </div>
            </ListWrap>
          ) : (
            ''
          )}
        </li>
        <li>
          <Button
            selected={isUnsubscribesOpen}
            onClick={() => {
              setIsUnsubscribesOpen(true)
              setIsClickThroughtOpen(false)
              setIsOpenRateOpen(false)
            }}
            className="p-5"
          >
            <div
              className={['h-16 w-16', totalUnsubscribeRate > 0 ? ' block' : ' hidden'].join('')}
            >
              <Pie data={unsubscribeRateData} options={options} />
            </div>

            <div>
              <StatsNumber color="var(--primary-color)">{unsubscribeRate}%</StatsNumber>
              <StatsTitle>Unsubscribes</StatsTitle>
            </div>
          </Button>
          {isUnsubscribesOpen ? (
            <ListWrap className="absolute top-44 right-10 w-96">
              <button
                className="absolute -left-5 top-4 bg-white h-10 w-10 rounded-full flex items-center justify-center shadow-lg cursor-pointer"
                type="button"
                onClick={() => setIsUnsubscribesOpen(false)}
              >
                <ArrowRight color="var(--primary-color)" />
              </button>
              <div className="flex items-center justify-center space-x-5 mb-10 mt-10">
                <div
                  className={['h-16 w-16', totalUnsubscribeRate > 0 ? ' block' : ' hidden'].join(
                    '',
                  )}
                >
                  <Pie data={unsubscribeRateData} options={options} />
                </div>
                <div>
                  <StatsNumber color="var(--primary-color)">{unsubscribeRate}%</StatsNumber>
                  <StatsTitle>Unsubscribes</StatsTitle>
                </div>
              </div>
              <div className="px-5">
                {unsubscribeAggs?.userAndCounts.map((item) => (
                  <>
                    <ul className="divide-y" key={item.uid}>
                      <Item>
                        <div className="flex justify-between">
                          <p className="text-accent-color font-semibold">{item.name}</p>{' '}
                          <p className="text-xs font-semibold">{item.count}</p>
                        </div>
                        <TextBody fontSize={12}>{item.email}</TextBody>
                      </Item>
                    </ul>
                  </>
                ))}
              </div>
            </ListWrap>
          ) : (
            ''
          )}
        </li>
      </ul>
    </>
  )
}
