import React from 'react'
import { useFiltersStore, useHomeSearchStore } from 'store/homeSearch'
import { DropdownField } from 'views/shared/components/ui-form'

interface PropertyTypeFieldProps {
  isExtendedFilter?: boolean
}

export const PropertyTypeField = ({ isExtendedFilter }: PropertyTypeFieldProps) => {
  const { filtersState, setFiltersState } = useFiltersStore()

  const handlePropertyTypeChange = (types: string[]) => {
    let typesUpdate: string[] = types || []
    if (types.length === 0 || types[types.length - 1].includes('all')) typesUpdate = ['all']
    else if (types.length > 0 && types.includes('all'))
      typesUpdate = typesUpdate.filter((type) => type !== 'all')
    setFiltersState((oldState) => ({
      ...oldState,
      types: typesUpdate,
    }))
  }

  return (
    <>
      <DropdownField
        multi
        values={filtersState.types || ['all']}
        onChange={handlePropertyTypeChange}
        options={[
          { key: 'all', label: 'All Property Types' },
          { key: 'detatched', label: 'Detached' },
          { key: 'semiDetatched', label: 'Semi-Detached' },
          { key: 'freeholdTownhouse', label: 'Townhouse' },
          { key: 'condo', label: 'Condominium' },
          { key: 'condoTownhouse', label: 'Condo Townhouse' },
          { key: 'multi', label: 'Multi-Residential' },
        ]}
        name="propertyTypeUpdate"
      />
    </>
  )
}
