import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { KeyLabel } from 'types'
import { UnescapeHelper } from 'utilities/helpers/unescapeHelper'
import { ModalLayer } from '../../modal-layer'
import { Input } from '../styled'

export const SelectListItem = styled.li`
  display: block;
  padding: 5px;
  font-size: 14px;
  color: var(--default-color);
  cursor: pointer;
  border-radius: 8px;
  text-transform: capitalize;
  &:hover {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
  }
`

export type InputAutocompleteProps<
  TInput extends string | number | undefined = string | number | undefined,
> = {
  options?: KeyLabel<TInput>[]
  value?: TInput
  search?: string
  onSearch?: (valueSearch: string) => void
  onPick?: (valueKey: TInput | undefined) => void
  placeholder?: string
  labelParser?: (
    valueKey?: TInput,
    search?: string,
    item?: KeyLabel<TInput>,
    options?: KeyLabel<TInput>[],
  ) => string
}
const defaultLabelParser = <
  TInput extends string | number | undefined = string | number | undefined,
>(
  _: TInput,
  search: string | undefined,
  item: KeyLabel<TInput> | undefined,
) => {
  if (item) return item.label
  return search || ''
}
export const InputAutocomplete = <
  TInput extends string | number | undefined = string | number | undefined,
>({
  options,
  value,
  search,
  onSearch,
  onPick,
  placeholder,
  labelParser,
}: InputAutocompleteProps<TInput>) => {
  const [show, setShow] = useState<boolean>(false)
  const showDropDown = () => (show && options && options?.length > 0) || false

  const close = useCallback(() => {
    setShow(false)
  }, [])
  const open = useCallback(() => {
    if (options && options?.length > -1) setShow(true)
  }, [])

  const callAutocomplete = async (valueKey: TInput) => {
    const getAutocomplete = () => labelParser || defaultLabelParser
    const getItem = () =>
      valueKey && options ? options.find((option) => option.key === valueKey) : undefined
    const r = getAutocomplete()(valueKey, search, getItem(), options)
    return r
  }

  const handleValueSelected = (key: TInput) => {
    onPick && onPick(key)
    callAutocomplete(key).then((label) => {
      onSearch && onSearch(label)
      close()
    })
  }

  const onInput = (
    e: React.ClipboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
  ) => {
    open()
    onSearch && onSearch(e.currentTarget.value)
  }

  useEffect(() => {
    if (!value) return
    callAutocomplete(value).then((label) => onSearch && onSearch(label))
  }, [])

  return (
    <>
      <div className="w-full">
        <Input
          onFocus={() => open()}
          placeholder={placeholder}
          type="search"
          value={search}
          onInput={onInput}
        />
        <ModalLayer
          show={showDropDown()}
          className="z-50"
          onOutsideClick={close}
          responsive={{
            small: 'pinned',
          }}
        >
          <ul className="bg-white min-w-max border border-primary-soft-color rounded-lg shadow-lg p-3">
            {(options || []).map((item) => (
              <SelectListItem
                className="text-sm text-default-color cursor-pointer hover:bg-light-400"
                key={item.key}
                aria-hidden
                onClick={() => handleValueSelected(item.key)}
              >
                {UnescapeHelper.unescape(item.label)}
              </SelectListItem>
            ))}
          </ul>
        </ModalLayer>
      </div>
    </>
  )
}
