import React from 'react'
import { useParams } from 'react-router-dom'
import { PageFooter } from 'views/shared/components/page-footer'
import { PropertyAddress } from './property-address/PropertyAddress'
import { PropertyDetails } from './property-details/PropertyDetails'
import { PropertyEstimation } from './property-estimation/PropertyEstimation'
import { PropertyValuation } from './property-valuation/HomeValuationPropertyValuation'

export const HomeValuation = () => {
  const param = useParams<{ page: string }>()

  return (
    <>
      <div>
        {param.page === 'property-address' && <PropertyAddress />}
        {param.page === 'property-details' && <PropertyDetails />}
        {param.page === 'property-estimation' && <PropertyEstimation />}
        {param.page === 'property-valuation' && <PropertyValuation />}
      </div>
      <PageFooter />
    </>
  )
}
