import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { PartialDataAlert } from 'views/soldReport/components/CurrentMonthAlert'
import { MonthStr, NeighbourhoodReport, PropertyType } from 'types'
import { KeyLabel } from 'types/KeyLabel'
import { PageHeader } from 'views/shared/components/page-header'
import { MonthPicker, PrimaryButton, Title } from 'views/shared/components/ui-form'
import { paths } from 'views/soldReport/paths'
import { DateHelper } from 'utilities'
import { SelectionButtons } from 'views/shared/components/selection-buttons'
import { FollowNeighbourhood } from 'views/soldReport/components/follow-neighbourhood/FollowNeighbourhood'
import { PageFooter } from 'views/shared/components/page-footer'
import { DownloadPdf } from 'views/shared/components/download-pdf/DownloadPdf'
import { useNeighbourhoods } from 'views/map/hooks'
import { Spinner } from 'views/shared/components/spinner/Spinner'
import { SoldReportData, SoldReportServices } from 'services/soldReport'
import { AgentServices } from 'services/agents'
import { Share } from 'views/shared/components/share'
import { UserServices } from 'services/user'
import { Analytics } from 'aws-amplify'
import { PropertyTable } from './PropertyTable/PropertyTable'
import { MarketChart } from './MarketChart'
import { Snapshot } from './Snapshot/Snapshot'
import { generateSoldReportPdf } from './pdf'
import {
  defaultPropertyTypeSelector,
  paramsState,
  soldReportDataState,
  soldReportResponseSelector,
} from './soldReportState'
import { OrderPropertyTypes, Pluralize } from './helper'

type ParamsType = {
  neighbourhoodId: string
  propertyType?: PropertyType
  monthStr?: MonthStr
}
const initialPropertyType: PropertyType = 'detatched'
export const SoldReport = () => {
  const history = useHistory()
  const { neighbourhoods, isLoading: neighbourhoodIsLoading } = useNeighbourhoods()

  const [soldReportData, setSoldReportData] = useRecoilState<SoldReportData | undefined>(
    soldReportDataState,
  )

  const [selectedPropertyType, setSelectedPropertyType] =
    useState<PropertyType>(initialPropertyType)
  const [selectedNeighbourhoodReport, setSelectedNeighbourhoodReport] = useState<
    NeighbourhoodReport | undefined
  >(undefined)
  const [availableMonthsForSelectedPropertyType, setAvailableMonthsForSelectedPropertyType] =
    useState<KeyLabel[]>([])
  const availablePropertyTypes = OrderPropertyTypes(SoldReportServices.getPropertyTypes())
  const [params, setParams] = useRecoilState<ParamsType>(paramsState)
  setParams(useParams<ParamsType>())
  const selectedMonthStr = (params.monthStr as MonthStr) || DateHelper.getLastMonthStr()
  const selectedNeighbourhood = neighbourhoods.find(
    (neighbourhood) => neighbourhood.id === params.neighbourhoodId,
  )
  const { state, contents: soldReportResponse } = useRecoilValueLoadable(soldReportResponseSelector)
  const defaultPropertyType = useRecoilValue(defaultPropertyTypeSelector)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  useEffect(() => {
    if (selectedMonthStr && selectedNeighbourhood) {
      const email = query.get('email')
      const emailReportMonth = query.get('month')
      const emailUser = query.get('userId')
      // const paramValue = query.get('value');
      UserServices.getIdentity().then((identity) =>
        Analytics.record({
          attributes: {
            ...identity,
            time: selectedMonthStr,
            nid: selectedNeighbourhood.id,
            email,
            emailReportMonth,
            emailUser,
          },
          name: 'openSoldReport',
        }),
      )
    }
  }, [selectedMonthStr, selectedNeighbourhood])

  useEffect(() => {
    state === 'hasValue' && setSoldReportData(soldReportResponse)
    return () => {}
  }, [params, state, soldReportResponse, setSoldReportData])

  useEffect(() => {
    if (soldReportData) {
      setSelectedPropertyType(params.propertyType || defaultPropertyType || initialPropertyType)
    }
  }, [params, soldReportData, defaultPropertyType, setSelectedPropertyType])

  useEffect(() => {
    if (soldReportData && selectedPropertyType && selectedMonthStr) {
      setSelectedNeighbourhoodReport(soldReportData.reports[selectedPropertyType])
      setAvailableMonthsForSelectedPropertyType(SoldReportServices.getAvailableMonths())
    }
  }, [soldReportData, selectedPropertyType, selectedMonthStr])
  const selectedPropertyTypeSize =
    selectedPropertyType &&
    soldReportData &&
    soldReportData.reports &&
    soldReportData.reports[selectedPropertyType]
      ? soldReportData.reports[selectedPropertyType].soldNumber
      : 0

  const downloadPDF = useCallback(async () => {
    if (!selectedNeighbourhood || !soldReportData) return
    await UserServices.getIdentity().then((identity) => {
      Analytics.record({
        attributes: {
          ...identity,
          time: selectedMonthStr,
          nid: selectedNeighbourhood.id,
        },
        name: 'downloadSoldReport',
      })
    })
    // TODO: deal with the situation that there is no agent. In this case, API returns 404
    const myAgent = await AgentServices.getMyAgentProfile()

    generateSoldReportPdf({
      neighbourhood: selectedNeighbourhood,
      soldReportData,
      agentProfile: myAgent,
    })
  }, [selectedNeighbourhood, soldReportData])

  // Loading
  if (neighbourhoodIsLoading || !selectedNeighbourhood) {
    return (
      <div>
        <div className="flex flex-col justify-center h-96">
          <Spinner className="h-32" alt="Loading" />
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="p-5 xl:px-20 max-w-screen-2xl mx-auto w-full mb-20">
        {/* HEADER */}
        <PageHeader
          className="mb-0"
          append={
            <>
              <div className="flex flex-row  md:space-x-3 ">
                <div className="block pr-2">
                  <DownloadPdf onClick={downloadPDF} />
                </div>
                <Share
                  title="Share this Sold Report"
                  text={`The sold report for ${selectedNeighbourhood.name} for ${selectedMonthStr}`}
                  url={window.location.href}
                  files={[]}
                />
              </div>
            </>
          }
        >
          <div className="flex sm:items-center mb-6 sm:mb-0">
            <FollowNeighbourhood neighbourhoodId={selectedNeighbourhood.id} />
            <div className="pr-5">
              <Title className="hidden sm:block">{selectedNeighbourhood.name}</Title>
              <Title fontSize={32} className="block sm:hidden">
                {selectedNeighbourhood.name}
              </Title>
            </div>
          </div>
          <div className="mr-6">
            <MonthPicker
              monthStr={selectedMonthStr}
              onChange={(monthStr?: MonthStr) => {
                if (monthStr) {
                  history.replace(
                    paths.soldReport({
                      neighbourhoodId: selectedNeighbourhood.id,
                      monthStr,
                      propertyType: selectedPropertyType,
                    }),
                  )
                }
              }}
            />
          </div>
        </PageHeader>
        {/* Partial data alert */}
        {selectedMonthStr === DateHelper.getCurrentMonthYear() && (
          <PartialDataAlert className="mt-8 py-4 max-w-4xl" />
        )}
        {/* CATEGORIES */}
        <div className="relative py-6">
          <SelectionButtons
            options={availablePropertyTypes.map((item) => ({
              value: item.key,
              label: (
                <>
                  <strong className="text-xl">
                    {soldReportData?.reports[item.key as PropertyType]?.soldNumber
                      ? soldReportData.reports[item.key as PropertyType].soldNumber
                      : '0'}
                    &nbsp;
                  </strong>
                  {Pluralize(item, soldReportData?.reports[item.key as PropertyType].soldNumber)}
                </>
              ),
              disabled:
                soldReportData &&
                soldReportData.reports &&
                soldReportData.reports[item.key as PropertyType] &&
                soldReportData.reports[item.key as PropertyType].soldNumber
                  ? soldReportData.reports[item.key as PropertyType].soldNumber === 0
                  : true,
            }))}
            onChange={(value) => {
              history.replace(
                paths.soldReport({
                  neighbourhoodId: selectedNeighbourhood.id,
                  monthStr: selectedMonthStr,
                  propertyType: value,
                }),
              )
            }}
            value={selectedPropertyType}
          />
        </div>
        {/* SNAPSHOT */}
        <div className="pt-8">
          <Snapshot
            availableMonthsForSelectedPropertyType={availableMonthsForSelectedPropertyType}
            neighbourhoodReport={selectedNeighbourhoodReport}
            selectedPropertyType={selectedPropertyType}
            selectedMonthStr={selectedMonthStr}
          />
        </div>
        {/* TABLE */}
        <div className="pt-5">
          <PropertyTable
            mockTableSize={selectedPropertyTypeSize}
            neighbourhoodReport={selectedNeighbourhoodReport}
            selectedMonthStr={selectedMonthStr}
          />
        </div>
        {/* CHART */}
        <div className="pt-10 pb-16">
          {soldReportData &&
            soldReportData.communityStatistics &&
            soldReportData.neighbourhoodStatistics &&
            soldReportData.statistics &&
            selectedNeighbourhood && (
              <MarketChart
                communityName={selectedNeighbourhood.community}
                neighbourhoodName={selectedNeighbourhood.name}
                neighbourhoodStatistics={soldReportData.neighbourhoodStatistics}
                communityStatistics={soldReportData.communityStatistics}
                statistics={soldReportData.statistics}
                propertyType={selectedPropertyType}
                yearMonth={selectedMonthStr}
              />
            )}
        </div>
        <div className="pb-16 w-80 mx-auto">
          <PrimaryButton>
            <Link to="/neighbourhood/map">Select Another Neighbourhood</Link>
          </PrimaryButton>
        </div>
      </div>
      <PageFooter />
    </>
  )
}
